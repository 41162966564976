import React from 'react';
import { LoaderStyle1, LoaderStyle2 } from './Loader.styled';
import { dim } from '../../styles/theme';

export const Loader1 = () => {
	return <LoaderStyle1 />;
};

export const Loader2 = ({style,loaderStyle}) => {
	return (
		<LoaderStyle2 style={loaderStyle} >
			<img
				src={process.env.PUBLIC_URL + '/assets/icons/spinner2.gif'}
				style={{ height: `${dim._40px}`, width: `${dim._40px}`,...style }}
				alt=''
			/>
		</LoaderStyle2>
	);
};
