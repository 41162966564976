import React from "react";
import { TableRowsWrapper } from "./TableRows.styled";
import { itr_documents_constant } from "../constant";
import ActionButton from "../Components/ActionButton";
import { useDispatch } from "react-redux";
import { ITRDocumentsAction } from "../store/action";
import AddedFile from "../Components/AddedFile";

const documentStatus = {
  VERIFIED: "VERIFIED",
  NOT_REQUIRED: "NOT_REQUIRED",
  REJECTED: "REJECTED",
  REQUESTED: "REQUESTED",
  IN_REVIEW: "IN_REVIEW",
  NOT_INITIATED: "NOT_INITIATED",
};

const TableRows = ({
  docName,
  docId,
  docLink,
  docStatus,
  fileName,
  password,
  downloadFile,
  docProp,
  handleDocVerification,
}) => {
  const dispatch = useDispatch();
  const handleReject = (id) => {
    dispatch({
      type: ITRDocumentsAction.REJECT_POPUP_STATUS,
      data: {
        active: true,
        document_id: id,
      },
    });
  };

  return (
    <TableRowsWrapper>
      <div className="docs_item">
        <span>{docName}</span>
      </div>
      <div className="docs_item">
        <span>{fileName}</span>
      </div>
      <div className="docs_item doc_detail">
        {docName === "PREFILLED JSON" ? (
          <img src={itr_documents_constant.json_icon} alt="" />
        ) : (
          <img src={itr_documents_constant.pdf_icon} alt="" />
        )}
        <AddedFile
          docProp={docProp}
          docId={docId}
          link={docLink}
          fileName={fileName}
          downloadFile={downloadFile}
          password={password}
          docName={docName}
        />
      </div>
  {  docId&&  <div className="docs_item docs_item_btns">
        {docStatus === documentStatus.VERIFIED ? (
          <span style={{ color: "#15be53" }}>Approved</span>
        ) : docStatus === documentStatus.REJECTED ? (
          <span style={{ color: "#C69A0B" }}>Rejected</span>
        ) : (
          <>
            <ActionButton
              name="approve"
              handleClick={() => {
                console.log(docId);
                handleDocVerification(docId, "", "VERIFIED");
              }}
            />
            <ActionButton
              name="reject"
              handleClick={() => handleReject(docId)}
            />
          </>
        )}
      </div>}
    </TableRowsWrapper>
  );
};

export default TableRows;
