import styled from "styled-components";
import { dim } from "../../../../../styles/theme";


export const StyledMobileTableOfContent = styled.div`
	box-sizing: border-box;

	.mobile-table-of-contents {
		word-wrap: break-word;
		width: 100%;

		background-color: #f5f5f5;
		color: #282c3f;
		padding: ${dim._16px};
		padding-bottom: 1px;
		border-radius: ${dim._8px};
		box-sizing: border-box;
		.mobile-table-of-content-title {
			font-size: ${dim._14px};
			margin-bottom: ${dim._8px};
			font-weight: 600;
			line-height: ${dim._18px};

			cursor: pointer;
		}

		ul {
			padding: 0;

			li {
				font-size: ${dim._12px};
				font-weight: 400;
				line-height: ${dim._30px};
				a {
					font-size: ${dim._12px};

					text-decoration: none;
					cursor: pointer;

					&:hover {
						color: #007bff;
					}
				}
			}
		}
		ul {
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			padding-inline-start: 1em;
		}
	}
`;
