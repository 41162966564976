import React from 'react';
import { StyledCurrentCompany } from './CurrentCompany.Styled';
// import { useDispatch, useSelector } from 'react-redux';
// import { action } from '../../../Store/action';
import { BsCalendar3 } from 'react-icons/bs';
import { timeStampDateNewFormat } from '../../../../../utils/epochConverter';
// import TextButton from '../../../../../commonComponents/TextButton';

const CurrentCompany = ({
	company,
	selectedCompanyId,
	handleCompanySelect,
	CloseCompanyExternalId,
	currentCompany = false
}) => {
	// const dispatch = useDispatch();

	// const CloseCompanyPopupState = useSelector(
	// 	(state) => state.incomeNewReducer.CloseCompanyPopupState
	// );
	// const onHideModelClick = () => {
	// 	dispatch({
	// 		type: action.SET_STATE_POPUP,
	// 		data: {
	// 			CloseCompanyPopupState: !CloseCompanyPopupState,
	// 			CloseCompanyExternalId: company.external_id,
	// 			CloseCompanyName: company.company_details.name,
	// 			CloseCompanyStartDate: company.start_end_date_fy.start_date
	// 		}
	// 	});
	// };

	return (
		<StyledCurrentCompany
			active={selectedCompanyId === company.external_id}
			id={company.external_id}
			onClick={(e) => {
				handleCompanySelect(e);
			}}
		>
			<div className='top-section'>
				<img
					src={
						company.company_details.logo ||
						process.env.PUBLIC_URL + '/assets/images/logo_placeholder.png'
					}
					alt=''
					className='logo'
				></img>
				<div className='name'>
					<h2 className='heading'>
						{(company.company_details.brand_name &&
							company.company_details.brand_name.toLowerCase()) ||
							(company.company_details &&
								company.company_details.name.toLowerCase()) ||
							''}
					</h2>
					<p className='sub-heading'>
						{company.company_details.name.toLowerCase()}
					</p>
				</div>
			</div>
			<div className='top-section align-center'>
				<BsCalendar3 className='icon' />
				<p className='duration'>
					{timeStampDateNewFormat(company.start_end_date_fy.start_date)} -&nbsp;
					{timeStampDateNewFormat(company.start_end_date_fy.end_date)}
				</p>
			</div>
			{/* {currentCompany && (
				<div className='top-section align-right'>
					<TextButton
						style={{ fontSize: 10 }}
						onClick={(e) => {
							e.stopPropagation();
							onHideModelClick();
						}}
					>
						Not current company?
					</TextButton>
				</div>
			)} */}
		</StyledCurrentCompany>
	);
};

export default CurrentCompany;
