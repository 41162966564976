import styled from 'styled-components';
import { dim } from '../../styles/theme';


const SuccessMsgStyle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: auto;
	margin: ${dim._0px} ${dim._0px};
	padding: ${dim._32px} ${dim._0px};
	border-radius: ${dim._32px};
	box-sizing: border-box;
background:#fff;
min-width: 870.825px;
border-radius: 8.5375px;
background: rgb(255, 255, 255);
min-height: 533.594px;
justify-content: center;
	.check {
		display: flex;
		justify-content: center;
		align-items: center;
		width: ${dim._scale(60)};
		height: ${dim._scale(60)};
		background: #ffffff;
		border: ${dim._4px} solid #15be53;
		border-radius: 100%;
		img {
			width: ${dim._scale(30)};
		}
	}

	h1 {
		margin: ${dim._34px} ${dim._0px} ${dim._26px} ${dim._0px};
		padding: ${dim._0px};
		font-style: normal;
		font-weight: 600;
		font-size: ${dim._18px};
		line-height: ${dim._32px};
		letter-spacing: 0.2px;
		color: #212f45;
	}

	p {
		width: ${dim._scale(335)};
		margin: ${dim._0px} ${dim._0px} ${dim._0px} ${dim._0px};
		padding: ${dim._0px};
		font-style: normal;
		font-weight: 400;
		font-size: ${dim._14px};
		line-height: ${dim._28px};
		text-align: center;
		letter-spacing: 0.2px;
		color: #212f45;
	}
	.successContent {
		width: ${dim._scale(480)};
		color: #212f45;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
		letter-spacing: 0.2px;
		div {
			font-weight: 600;
		}
	}

	h3 {
		margin: ${dim._20px} ${dim._0px} ${dim._0px} ${dim._0px};
		padding: ${dim._0px};
		font-style: normal;
		font-weight: 500;
		font-size: ${dim._18px};
		line-height: ${dim._28px};
		text-align: center;
		letter-spacing: 0.2px;
		color: #635bef;
	}

	.navigations {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: ${dim._16px} ${dim._0px} ${dim._0px} ${dim._0px};
	}

	a {
		margin: ${dim._0px} ${dim._8px};
		padding: ${dim._8px} ${dim._28px};
		font-style: normal;
		font-weight: 500;
		font-size: ${dim._14px};
		line-height: ${dim._20px};
		text-decoration: none;
		color: #212f45;
		background: #ffffff;
		border-radius: ${dim._4px};
	}
`;

export default SuccessMsgStyle;
