import React from "react";
import { RequestDocPreviewWrapper } from "./RequestDocPreview.styled";
import { itr_documents_constant } from "../../constant";

const RequestDocPreview = ({ documentType, description, handleRemove }) => {
  return (
    <RequestDocPreviewWrapper>
      <div className="preview_content">
        <h2>{documentType}</h2>
        <p>{description}</p>
      </div>
      <img
        src={itr_documents_constant.cross_icon}
        alt=""
        onClick={handleRemove}
      />
    </RequestDocPreviewWrapper>
  );
};

export default RequestDocPreview;
