import styled from "styled-components";

export const AmountCardStyled = styled.div`
  width: 110px;

  background: #fff;
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 10%);
  border-radius: 3px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin: 0px 0 10px;
  .title {
    height: 36px;
    text-align: center;
    background: #7dd0ed;
    color: #fff;
    font-weight: 600;
    line-height: 36px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
  }
  .price {
    text-align: center;

    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    padding: 16px 0 17px 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    transform: scale(1.1) translate3d(0, 0, 0);
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);

    .title {
      background: $blueDark;
    }

    .price {
      color: $blueDark;
    }
  }
`;
