import React from 'react';
import { FileUploadStyle } from './FileUpload.styled';
import { HiOutlineUpload } from 'react-icons/hi';
import { extractValue } from '../../container/IncomeNew/Screens/SalaryIncomeDetails/util';
import { getUniqueFileName } from '../../utils/randomFileNameGenerator';
import { PreSignedUrlUpload } from '../../utils/preSignedUrl';

const FileUpload = ({ name, handleFileUpload, value }) => {
	return (
		<FileUploadStyle center={value === undefined || value.length < 1}>
			{value === undefined || value.length < 1 ? (
				<label for='fileInput'>
					<HiOutlineUpload style={{ color: 'green', fontSize: '24px' }} />
				</label>
			) : (
				<div
					className=''
					style={{
						display: 'inline-block',
						maxWidth: '90px',
						padding: '4px 0',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						wordWrap: 'break-word'
					}}
				>
					{extractValue(value)}
				</div>
			)}
			{
				<input
					acceptFileType='image/*'
					type='file'
					id='fileInput'
					name={name}
					onChange={(event) => {
						const fileName = getUniqueFileName(
							event.target.files[0],
							'capitalGain'
						);
						handleFileUpload(event, fileName);

						PreSignedUrlUpload(fileName, event.target.files[0], () => {});
					}}
				/>
			}
		</FileUploadStyle>
	);
};

export default FileUpload;
