import React, { useState, useEffect, useCallback } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./onboardAdvisor.css";
import Button from "../../commonComponents/Button";
import OnboardAuthorTagSelect from "../../components/OnboardAuthorCategory";
import UploadFile from "../../commonComponents/uploadFile";
import { getAuth } from "@firebase/auth";
import Pincode from "react-pincode";
import Alert from "../../commonComponents/Alert/Alert1";
import SocialMedia from "../../commonComponents/SocialMedia";
import BASE_URL from "../../Services/API_ENDPOINT";
import { uploadFile } from "../../utils/uploadFileUtil";
import { between } from "../../utils/betweenFn";
import EducationInput from "./components/EducationInput";
import { Editor, EditorState } from "draft-js";
import { objectEmptyValidator } from "../../utils/validate";
import {
  errorValidator,
  findUndefinedProperties,
} from "../ConsultationExperience/utils";
import { getAuthToken } from "../../Services/httpUtil";

const initialEdu = {
  institute: "",
  course: "",
  start_year: undefined,
  end_year: undefined,
  isDelete: true,
};

const OnboardAdvisor = () => {
  const [user_details, setUser_details] = useState({
    first_name: "",
    email: "",
    mobile: "",
    last_name: "",
    middle_name: "",
    image: "",
  });

  const [loader, setLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [other_qualification, setOther_qualification] = useState("");
  const [otherQualification, setotherQualification] = useState([]);
  const [qualifications, setQualifications] = useState({
    primary_qualification: "",
    other_qualification: otherQualification,
  });
  const [errorStatus, setErrorStatus] = useState(false);
  const [experience, setExperience] = useState("");
  const [no_of_assets_manage, setNo_of_assets_manage] = useState("");
  const [specialization, setSpecialization] = useState([]);
  const [ShowAlert, setShowAlert] = useState(null);
  const [SubmitMsg, setSubmitMsg] = useState(null);

  const [specialization_details, setSpecialization_details] = useState(null);
  const [Trigger, setTrigger] = useState(true);
  const [title, setTitle] = useState("");
  const [address_details, setAddress_details] = useState({});
  const [educationObj, setEducationObj] = useState([initialEdu]);
  const [brief, setBrief] = useState("");
  const [social_links, setSocial_links] = useState({
    facebook: "",
    linkedin: "",
    instagram: "",
    twitter: "",
    youtube: "",
    personal_website: "",
  });

  const [first, SetFirst] = useState({
    loading: false,
    data: [],
  });

  const [advisorInfo, setAdvisorInfo] = useState({
    work_experience: "",
    client_rating: "",
  });

  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setAdvisorInfo((state) => {
      return { ...state, [name]: value };
    });
  };

  useEffect(() => {
    if (getAuth().currentUser !== null && Trigger) {
      getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          const fetchData = async () => {
            const url = `${BASE_URL}specializations/v1/all`;
            try {
              const response = await fetch(url, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `${idToken}`,
                  Source: "cms",
                },
              });
              const items = await response.json();
              SetFirst({
                loading: true,
                data: items,
              });
            } catch (error) {
              console.log("error", error);
            }
          };

          fetchData();
        });
      setTrigger(false);
    }
  }, []);

  let tags = first.data.map((d, i) => ({
    value: `${d.specialization}`,
    label: `${d.specialization}`,
  }));

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUser_details({ ...user_details, [name]: value });
  };

  const handlePrimaryQual = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "other_qualification") {
      setOther_qualification(value);
    } else {
      setQualifications({ ...qualifications, [name]: value });
    }
    setotherQualification(other_qualification.split(","));
  };

  const handleYearExperience = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setExperience(value);
  };

  const handleAssetsManaged = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setNo_of_assets_manage(value);
  };

  const handleCategory = (value) => {
    setSpecialization(value);
    const categories = [];
    value.forEach((element) => {
      categories.push(element.label);
    });
    setSpecialization_details(categories);
  };

  const handleTitle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTitle(value);
  };

  const pincodeHandle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
  };

  const handelBio = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setBrief(value);
  };

  const [uploadAvatar, setUploadAvatar] = useState(false);
  const [originalAvatarLink, setOriginalAvatarLink] = useState("");

  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
  const handleAvatarUpload = async (e) => {
    const imageFile = e.target.files[0];
    const bucketName =
      ENVIRONMENT !== "PRODUCTION"
        ? "stage-prosperr-images"
        : "prosperr-images";
    //'prosperr-images'
    const bucketZone =
      ENVIRONMENT !== "PRODUCTION" ? "eu-west-1" : "ap-south-1";
    //eu-west-1

    try {
      // USE PRESIGNED URL
      const imageUrl = await uploadFile(bucketName, bucketZone, imageFile);
      setOriginalAvatarLink(imageUrl);
      setUser_details({
        ...user_details,
        image: imageUrl,
      });
    } catch (e) {
      console.log(e);
    }
    setUploadAvatar(true);
  };

  const handleCancelAvatarUpload = () => {
    setUploadAvatar(null);
  };

  const validate = (type, value) => {
    const errors = { ...formErrors };
    const regex = {
      email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
      mobile: /^([+]\d{2})?\d{10}$/,
    };

    if (!value) {
      errors[type] = `${type} is required`;
    } else if (regex[type] && !regex[type].test(value)) {
      errors[type] = `Not a valid ${type}`;
    } else if (value.length < 2) {
      errors[type] = `${type} cannot be less than 2 characters`;
    } else {
      delete errors[type];
    }

    setFormErrors(errors);
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    validate(name, value);
  };
  const handleOnboardAdvisor = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (!checkBtnClick()) {
      setErrorStatus(true);
    }

    getAuthToken().then(function (idToken) {
      let data = {
        user_details: user_details,
        qualifications: {
          primary_qualification: qualifications.primary_qualification,
          other_qualification: otherQualification,
        },
        ...advisorInfo,
        experience: experience,
        no_of_assets_manage: no_of_assets_manage,
        specialization_details: specialization_details,
        title: title,
        address_details: {
          city: address_details.city,
          zip_code: address_details.pincode,
        },
        educations: educationObj.map((d) => {
          return {
            ...d,
            end_year: new Date(d.end_year).getFullYear(),
            start_year: new Date(d.start_year).getFullYear(),
          };
        }),
        brief: brief,
        social_links: social_links,
      };
      fetch(`${BASE_URL}advisors/v1/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
          Source: "cms",
        },
        body: JSON.stringify(data),
      })
        .then((result) => {
          setLoader(false);
          if (
            Object.keys(formErrors).length === 0 &&
            (result.status === 201 || result.status === 200)
          ) {
            setSubmitMsg("success");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 1000);
          } else if (result.status === 409) {
            setSubmitMsg("Email or mobile number is already exsist");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 4000);
          } else if (result.status === 400) {
            result.json().then((response) => {
              setSubmitMsg(response.details[0]);
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 1000);
            });
          } else {
            setSubmitMsg("error");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 1000);
          }
        })
        .catch((e) => {
          console.log(e, "error3");
          setLoader(false);
        });
      if (Object.keys(formErrors).length !== 0) {
        setIsSubmit(true);
      }
    });
  };
  const handleEducationChange = useCallback((e, id) => {
    const { name, value } = e.target;

    const arr = (educationObj) =>
      educationObj.map((d, i) => {
        if (i == id) {
          return { ...d, [name]: value };
        }
        return d;
      });
    setEducationObj((state) => arr(state));
  }, []);
  const handleAdd = () => {
    setEducationObj((state) => [...state, initialEdu]);
  };
  const handleDelete = (id) => {
    setEducationObj((state) => state.filter((d, i) => i !== id));
  };
  const checkEducationObj = () => {
    const educationObj2 = educationObj.map((d, i) => {
      const data = {
        ...d,
        start_year: isNaN(new Date(d.start_year).getTime())
          ? d.start_year
          : new Date(d.start_year).getTime(),
        end_year: isNaN(new Date(d.end_year).getTime())
          ? d.end_year
          : new Date(d.end_year).getTime(),
      };
      const data2 = errorValidator(findUndefinedProperties(data));

      return { ...d, ...data2 };
    });
    setEducationObj(educationObj2);
  };
  const checkBtnClick = () => {
    checkEducationObj();
    return (
      !objectEmptyValidator(formErrors) &&
      between(advisorInfo?.client_rating, 0, 5) &&
      advisorInfo?.work_experience.length > 0
    );
  };

  return (
    <div className="form_container">
      <h2>Onboard Advisor</h2>
      <div className="box" autoComplete="off">
        <p>Upload Profile</p>
        <UploadFile
          fileId="thumbnail"
          acceptFileType="image/*"
          labelName=""
          icon={
            <AccountCircleIcon
              style={{
                fontSize: 80,
              }}
            />
          }
          uploadClassName="avatarUpload"
          uploaded={uploadAvatar}
          uploadLink={originalAvatarLink}
          onFileUpload={handleAvatarUpload}
          cancelUpload={handleCancelAvatarUpload}
        />
        <div className="label">
          <div className="side">First Name:&nbsp;&nbsp;</div>
          <input
            type="text"
            id="first_name"
            name="first_name"
            placeholder="*First Name"
            autoComplete="off"
            value={user_details.first_name}
            onChange={handleInput}
            onBlur={handleOnBlur}
          />
        </div>
        {!formErrors.first_name ? null : (
          <p className="error_p">{formErrors.first_name}</p>
        )}

        <div className="label">
          <div className="side">Middle Name:&nbsp;&nbsp;</div>
          <input
            type="text"
            name="middle_name"
            id="middle_name"
            placeholder="Middle Name"
            value={user_details.middle_name}
            onChange={handleInput}
            autoComplete="off"
          />
        </div>

        <div className="label">
          <div className="side">Last Name:&nbsp;&nbsp;</div>
          <input
            type="text"
            id="last_name"
            name="last_name"
            placeholder="*Last Name"
            value={user_details.lastname}
            onChange={handleInput}
            onBlur={handleOnBlur}
            autoComplete="off"
          />
        </div>

        {!formErrors.last_name ? null : (
          <p className="error_p">{formErrors.last_name}</p>
        )}

        <div className="label">
          <div className="side">Email:&nbsp;&nbsp;</div>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="*Email"
            value={user_details.email}
            onChange={handleInput}
            onBlur={handleOnBlur}
            autoComplete="off"
          />
        </div>

        {!formErrors.email ? null : (
          <p className="error_p">{formErrors.email}</p>
        )}

        <div className="label">
          <div className="side">Phone No:&nbsp;&nbsp;</div>
          <div className="phone">
            <select name="country" id="country">
              <option value="+91">+91</option>
              <option value="+1">+1</option>
              <option value="+43">+43</option>
              <option value="1-684">1-684</option>
            </select>

            <input
              type="text"
              id="phone"
              maxLength={10}
              name="mobile"
              placeholder="Phone mobile"
              value={user_details.mobile}
              onWheel={(e) => e.target.blur()}
              onChange={handleInput}
              onBlur={handleOnBlur}
              autoComplete="off"
            />
          </div>

          {!formErrors.mobile ? null : (
            <p className="error_p">{formErrors.mobile}</p>
          )}
        </div>

        {/* <div className="label">
          <div className="side">Primary Qualification:&nbsp;&nbsp;</div>
          <input
            type="text"
            id="primary_qualification"
            name="primary_qualification"
            placeholder="Primary Qualification"
            value={qualifications.primary_qualification}
            onChange={handlePrimaryQual}
            autoComplete="off"
          />
        </div> */}
        <div className="label">
          <div className="side">Education:&nbsp;&nbsp;</div>
          {educationObj.map((d, i) => {
            return (
              <EducationInput
                {...d}
                handleChange={handleEducationChange}
                handleAdd={handleAdd}
                handleDelete={handleDelete}
                id={i}
                length={educationObj.length}
              />
            );
          })}
        </div>

        {/* <div className="label">
          <div className="side">Other Qualification:&nbsp;&nbsp;</div>
          <input
            type="text"
            id="other_qualification"
            name="other_qualification"
            placeholder="Other Qualification"
            value={other_qualification}
            onChange={handlePrimaryQual}
            onBlur={handlePrimaryQual}
            autoComplete="off"
          />
        </div> */}

        <div className="label">
          <div className="side">Years of experience:&nbsp;&nbsp;</div>
          <input
            type="text"
            id="yearofexperience"
            name="yearofexperience"
            placeholder="Years of experience"
            value={experience}
            onWheel={(e) => e.target.blur()}
            onChange={handleYearExperience}
            autoComplete="off"
          />
        </div>
        <div className="label">
          <div className="side">client rating (0-5): &nbsp;&nbsp;</div>
          <input
            type="number"
            min={0}
            max={5}
            className="inputTag"
            name="client_rating"
            placeholder="client rating"
            value={advisorInfo?.client_rating}
            onWheel={(e) => e.target.blur()}
            onChange={handleInfoChange}
            onBlur={() => {
              setErrorStatus(true);
            }}
            autoComplete="off"
          />
          {errorStatus &&
            (advisorInfo?.client_rating.length < 1 ||
              !between(advisorInfo?.client_rating, 0, 5)) && (
              <p className="error">
                Rating should be between 0 to 5 <sup>*</sup>{" "}
              </p>
            )}
        </div>
        <div className="label">
          <div className="side">work experience: &nbsp;&nbsp;</div>
          {/* <input
            type="text"
            className="inputTag"
            name="work_experience"
            placeholder="work experience"
            value={advisorInfo?.work_experience}
            onWheel={(e) => e.target.blur()}
            onChange={handleInfoChange}
            autoComplete="off"
          /> */}
          <textarea
            type="text"
            className="inputTag"
            name="work_experience"
            placeholder="work experience"
            value={advisorInfo?.work_experience}
            onWheel={(e) => e.target.blur()}
            onChange={handleInfoChange}
            autoComplete="off"
          />
          {errorStatus && advisorInfo?.work_experience.length < 1 && (
            <p className="error">
              please fill the work experience <sup>*</sup>
            </p>
          )}
        </div>

        <div className="label">
          <div className="side">No. Clients served:&nbsp;&nbsp;</div>
          <input
            type="number"
            id="assetsmanaged"
            name="assetsmanaged"
            placeholder="Number of Clients served"
            onWheel={(e) => e.target.blur()}
            value={no_of_assets_manage}
            onChange={handleAssetsManaged}
            autoComplete="off"
          />
        </div>

        <div className="label" style={{ width: "600px" }}>
          <div className="side" style={{ paddingBottom: "16px" }}>
            Specialisation / Domain:&nbsp;&nbsp;
          </div>
          <OnboardAuthorTagSelect
            className="categoryTag"
            tags={tags}
            placeholder="Specialisation / Domain"
            id="specialization"
            name="specialization"
            value={specialization}
            handleInput={handleCategory}
          />
        </div>

        <div className="label">
          <div className="side">Title:&nbsp;&nbsp;</div>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Your Title"
            value={title}
            onChange={handleTitle}
            autoComplete="off"
          />
        </div>
        <div className="label">
          <div className="side">Enter Pincode to get city:&nbsp;&nbsp;</div>
          <Pincode
            onChange={pincodeHandle}
            pincodeInput={{
              width: "600px",
              height: "50px",
              border: "1px solid #e5e5e5",
              margin: "10px 0 0 0",
              padding: "20px",
              background: "rgba(11, 106, 176, 0.1)",
              boxSizing: "border-box",
              borderRadius: "4px",
            }}
            cityInput={{
              width: "600px",
              height: "50px",
              border: "1px solid #e5e5e5",
              margin: "10px 0 0 0",
              padding: "20px",
              background: "rgba(11, 106, 176, 0.1)",
              boxSizing: "border-box",
              borderRadius: "4px",
            }}
            stateContainer={{ display: "none" }}
            districtInput={{
              width: "600px",
              height: "50px",
              border: "1px solid #e5e5e5",
              margin: "10px 0 0 0",
              padding: "20px",
              background: "rgba(11, 106, 176, 0.1)",
              boxSizing: "border-box",
              borderRadius: "4px",
            }}
            areaInput={{ display: "none" }}
            invalidError="Incorrect Pincode"
            lengthError="Pincode length should be 6 digit"
            getData={(data) => setAddress_details(data)}
          />
        </div>

        <div className="label">
          <div className="side">Breif detals:&nbsp;&nbsp;</div>
          <textarea
            type="text"
            id="bio"
            name="brief"
            placeholder="Breif detals (In about 60-100 words)"
            value={brief}
            onChange={handelBio}
          />

          {/* <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            //    onEditorStateChange={onEditorStateChange}
          /> */}
        </div>

        <Alert SubmitMsg={SubmitMsg} ShowAlert={ShowAlert} />

        <SocialMedia
          social_links={social_links}
          setSocial_links={setSocial_links}
        />

        <Button
          id={"handleOnboardAdvisor"}
          // buttonClassName={
          //   checkBtnClick()
          //     ? "buttonOnboardAuthor "
          //     : " buttonOnboardAuthor disabledBtn"
          // }
          onButtonClick={loader ? () => {} : handleOnboardAdvisor}
          // onDisable={!checkBtnClick()}
          buttonName="Submit"
        />
      </div>
    </div>
  );
};

export default OnboardAdvisor;
