import React, { useEffect } from "react";
import { useState } from "react";
import { StyledCallActionForm } from "./CallActionForm.styled";
import ConsultationDocument from "../../components/ConsultationDocument";
import RadioQuestionModel from "../../components/RadioQuestionModel";
import CheckBoxComponent from "../../components/CheckBoxComponent";
import { StyledRadioQuestionModel } from "../../components/RadioQuestionModel/RadioQuestionModel.styled";
import {
  errorValidator,
  findUndefinedProperties,
  handleSubmitMom,
} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";

import SelectActionableItem from "../AdvisorDependencyInfo/SelectActionableItem";
import { modifyMetaData } from "../../../../utils/modifyMetaData";

const dependencyArr = [
  { id: 1, value: "ADVISOR", text: "Advisor", isSelected: false },
  { id: 1, value: "USER", text: "Client", isSelected: false },
  { id: 1, value: "none", text: "None", isSelected: false },
];
const nextCallArr = [
  { id: 2, value: false, text: "Yes", isSelected: false },
  { id: 2, value: true, text: "No", isSelected: false },
];

const radioArr = [
  { id: 5, value: true, text: "Yes", isSelected: false },
  { id: 5, value: false, text: "No", isSelected: false },
];

const title = "Do you need to upload any documents?";
const documentObj = {
  text: "",
  status: "",
  ownedBy: "",
  original_name: "",
  document_name: "",
  document_link: "",
  uploaded_by: "",
  uploaded_at: "",
  file: null,
};
const CallActionForm = ({
  callSummary,
  call_booking_id,
  id,
  getConsultationMom,
  setLoader,
  loader,
}) => {
  const DOCUMENT_TYPE = useSelector((state) => state.cmsReducer?.document_type);
  const call_summary_id =
    localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID) === "undefined"
      ? null
      : localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID);
  const [radioArrData, setRadioArrData] = useState(radioArr);
  const [dropDownArrData, setDropDownData] = useState(
    modifyMetaData(DOCUMENT_TYPE)
  );
  const [documentList, setDocumentList] = useState([documentObj]);
  const [dependencyArrData, setDependencyArrData] = useState(dependencyArr);
  const [nextCallArrData, setNextCallArrData] = useState(nextCallArr);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [actionableData, setActionableData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setDropDownData(modifyMetaData(DOCUMENT_TYPE));
  }, [DOCUMENT_TYPE]);

  const [searchParams] = useSearchParams();
  const [isError, setIsError] = useState({ error: false, errorArr: [] });

  const handleActionableItem = (item, type = "advisor") => {
    type === "advisor" ? setActionableData(item) : setClientData(item);
  };

  const handleDeleteActionable = (index) => {
    const newArr = actionableData;
    newArr.splice(index, 1);
    setActionableData([...newArr]);
  };
  const handleClientDataDeleteActionable = (index) => {
    const newArr = clientData;
    newArr.splice(index, 1);
    setClientData([...newArr]);
  };

  const handleDependencyinput = (arr) => {
    const newArr = dependencyArrData.map((data, i) => {
      return {
        ...data,
        isSelected: arr.includes(data.value),
      };
    });
    setDependencyArrData(newArr);
  };

  const handleDependencyChange = (value) => {
    const newArr = (dependencyArrData) => {
      return dependencyArrData.map((d, i) => {
        if (value === "none") {
          return {
            ...d,
            isSelected: d.value === value ? !d.isSelected : false,
          };
        }
        return {
          ...d,
          isSelected:
            d.value === value
              ? !d.isSelected
              : d.value === "none"
              ? false
              : d.isSelected,
        };
      });
    };

    setDependencyArrData((state) => {
      return [...newArr(state)];
    });
  };
  const handleNextCallArrChange = (index, value) => {
    const newArr = nextCallArrData.map((d, i) => {
      return {
        ...d,
        isSelected: i === index || d.value === value,
      };
    });
    setNextCallArrData(newArr);
  };
  const handleRadioChange = (index, value) => {
    const newArr = radioArrData.map((d, i) => {
      return {
        ...d,
        isSelected: i === index || d.value === value,
      };
    });
    if (index == 1 || value == false) {
      setDocumentList([documentObj]);
    }
    setRadioArrData(newArr);
  };

  const handleDropDownChange = (value, i) => {
    const newArr = dropDownArrData.map((d, i) => {
      return {
        ...d,
        isSelected: d.label === value,
      };
    });
    setDropDownData(newArr);
    handleTextChange(value, i);
  };
  const handleTextChange = (value, index) => {
    const newArr = documentList.map((d, i) => {
      if (index === i) {
        return { ...d, text: value };
      }
      return d;
    });
    setDocumentList(newArr);
  };
  const DropDownComponentProps = {
    defaultValue: "Select name",
    dataOptions: dropDownArrData,

    handleDropDownChange: isEditDisabled ? () => {} : handleDropDownChange,
  };

  const radioComponentProps = {
    radioArrData,
    title,
    isError,
    componentCategory: "is_need_to_upload_document",
    handleRadioChange: isEditDisabled ? () => {} : handleRadioChange,
  };
  const dependencyProps = {
    radioArrData: dependencyArrData,
    title: "Does anyone have dependency?",
    isError,
    componentCategory: "dependency_on",
    handleRadioChange: isEditDisabled ? () => {} : handleDependencyChange,
  };
  const consultationDocumentProps = {
    radioComponentProps,
    DropDownComponentProps,
    setDocumentList: isEditDisabled ? () => {} : setDocumentList,
    isError,
    componentCategory: "document_list",
    documentList,
    checkAddValidation,
  };
  function checkAddValidation() {
    const arrLength = documentList.length;
    if (arrLength === 0) return true;
    const arr = documentList.filter((d, i) => {
      return d.type !== "" && d.original_name !== "";
    });
    return arrLength === arr.length;
  }

  const getDependencyOn = () => {
  

    const dependencyArr = [
      { id: 1, value: "ADVISOR", text: "Advisor", isSelected: false },
      { id: 1, value: "USER", text: "Client", isSelected: false },
      { id: 1, value: "none", text: "None", isSelected: true },
    ]
  
    setDependencyArrData( dependencyArr); 
  }
  
  const handleSubmit = async () => {
    const is_need_to_upload_document = radioArrData.filter(
      (d) => d.isSelected
    )[0]?.value;
    const is_next_call_required = nextCallArrData.filter((d) => d.isSelected)[0]
      ?.value;
    const dependencyOn = dependencyArrData
      .filter((d) => d.isSelected)
      .map((d, i) => {
        return d.value;
      });

    const documentArr = checkAddValidation() ? documentList : [];

    let actionable_items = [];
    dependencyOn.includes("ADVISOR") &&
      actionable_items.push(...actionableData);
    dependencyOn.includes("USER") && actionable_items.push(...clientData);
    const userActions = dependencyOn.includes("USER") ? clientData : null;
    const obj = {
      is_need_to_upload_document,
      document_list: documentArr,
      ...(!dependencyOn.includes("none") && { action_items: actionable_items }),
      ...(userActions && { userActions }),
      ...(dependencyOn.includes("ADVISOR") && {
        advisorActions: actionableData,
      }),
      dependency_on: dependencyOn,
      is_next_call_required,

      action_item_status: "SUBMITTED",
    };
    const { document_list, ...correctCallObj } = obj;
    const objToInsert = !is_need_to_upload_document ? correctCallObj : obj;
    const { dependency_on, ...restObj } = objToInsert;
    const objNew = dependencyOn.includes("none") ? restObj : objToInsert;

    const value = {
      call_summary_id,
      call_booking_id,
      call_summary_action_items: objNew,
      written_by: id,
    };


    const errorValidation = errorValidator(
      findUndefinedProperties(value.call_summary_action_items)
    );
    setIsError(errorValidation);
    errorValidation.error === false &&
      handleSubmitMom(
        id,
        value,
        setIsEditDisabled,
        navigate,
        searchParams,
        dispatch,
        setLoader
      );
  };

  const callSummaryhandle = () => {
    setIsEditDisabled(true);
    handleRadioChange(
      null,
      callSummary?.call_summary_action_items?.is_need_to_upload_document
    );

    callSummary?.call_summary_action_items?.dependency_on !== undefined
      ? handleDependencyinput(
          callSummary?.call_summary_action_items?.dependency_on
        )
      :  getDependencyOn("none");

    callSummary?.call_summary_action_items?.is_next_call_required !==
      undefined &&
      handleNextCallArrChange(
        null,
        callSummary?.call_summary_action_items?.is_next_call_required
      );
    callSummary?.call_summary_action_items?.is_need_to_upload_document &&
      setDocumentList(callSummary?.call_summary_action_items?.document_list);

    callSummary?.call_summary_action_items?.advisor_action_items &&
      setActionableData(
        callSummary?.call_summary_action_items?.advisor_action_items
      );
    callSummary?.call_summary_action_items?.user_action_items &&
      setClientData(callSummary?.call_summary_action_items?.user_action_items);
  };

  useEffect(() => {
    callSummary?.call_summary_action_items?.action_item_status ===
      "SUBMITTED" && callSummaryhandle();
  }, [callSummary?.call_summary_action_items]);

  return (
    <StyledCallActionForm isEditDisabled={isEditDisabled}>
      <div className="formContainer" id={call_booking_id}>
        <ConsultationDocument {...consultationDocumentProps} />
        <StyledRadioQuestionModel>
          <CheckBoxComponent {...dependencyProps} />
        </StyledRadioQuestionModel>
        <RadioQuestionModel
          radioArrData={nextCallArrData}
          title={"Does the user need to next call ?"}
          handleRadioChange={
            isEditDisabled ? () => {} : handleNextCallArrChange
          }
          componentCategory={"is_next_call_required"}
          isError={isError}
        />
      </div>
      <SelectActionableItem
        handleActionableItem={isEditDisabled ? () => {} : handleActionableItem}
        isError={isError}
        dependencyArrData={dependencyArrData}
        actionableData={actionableData}
        handleDeleteActionable={
          isEditDisabled ? () => {} : handleDeleteActionable
        }
        clientData={clientData}
        handleClientDataDeleteActionable={
          isEditDisabled ? () => {} : handleClientDataDeleteActionable
        }
        isEditDisabled={isEditDisabled}
        getConsultationMom={getConsultationMom}
      />
      <div
        className={
          isEditDisabled || loader ? "greenButton disabled" : "greenButton"
        }
        onClick={() => (isEditDisabled || loader ? () => {} : handleSubmit())}
      >
        Submit call actions
      </div>
    </StyledCallActionForm>
  );
};

export default CallActionForm;
