import React, { useEffect, useState } from "react";
import { EditableRowStyle } from "./EditableRowStyle";
import moment from "moment";
import { AiOutlineCheck } from "react-icons/ai";
import { FaCross } from "react-icons/fa";
import SelectDropdownInput from "../../../../../commonComponents/SelectDropdownInput";
import InputDateWithOutLabel from "../../../../../commonComponents/InputDateWithOutLabel";
import TextInput from "../../../../../commonComponents/TextInput";
import NumberInput from "../../../../../commonComponents/NumberInput";
import FileUpload from "../../../../../commonComponents/FileUpload";

const EditableRowBusinessCrypto = ({
    items,
    editId,
    externalId,
    handleAddMoreSubmit,
    handleCancel,
    typeOptions,
    subTypeOptions,
    handleEditFormSubmit,
    placeHolders,
    handleEditClick,
    incomeType,
}) => {
    const [dropDownValue, setDropDownValue] = useState("No");
    const [inputObj, setInputObj] = useState({
        type: items.type ? items.type : typeOptions[0],
        external_id: externalId,
        subType: items.subType ? items.subType : subTypeOptions[0],
        details: items.details,
        amount: items.amount ? items.amount : "",
        endDate: items.endDate ? items.endDate : new Date().getTime(),
        startDate: items.startDate ? items.startDate : new Date().getTime(),
        totalAmount: items.totalAmount ? items.totalAmount : "",
        sellingPrice: items.sellingPrice ? items.sellingPrice : "",
        document: items.document,
        isSold: items.isSold,
    });

    const [error, setError] = useState(false);

    const handleInputChange = (e, filename) => {
        const { value, name } = e.target;
        if (filename) {
            setInputObj({ ...inputObj, [name]: filename });
        } else if (name.includes("Date")) {
            setInputObj({ ...inputObj, [name]: new Date(value).getTime() });
        } else if (name.includes("Sold")) {
            setDropDownValue(value);
            setInputObj({
                ...inputObj,
                [name]: value === "Yes" ? true : false,
            });
        } else {
            setInputObj({ ...inputObj, [name]: value });
        }
    };

    useEffect(() => {
        if (incomeType === "INCOME_FROM_HOUSE_RENT") {
            if (
                Number(inputObj.totalAmount) !== 0 &&
                !isNaN(Number(inputObj.totalAmount))
            ) {
                setInputObj({ ...inputObj, isSold: true });
                setDropDownValue("Yes");
            } else {
                setInputObj({ ...inputObj, isSold: false });
                setDropDownValue("No");
            }
        } else {
            if (
                Number(inputObj.sellingPrice) !== 0 &&
                !isNaN(Number(inputObj.sellingPrice))
            ) {
                setInputObj({ ...inputObj, isSold: true });
                setDropDownValue("Yes");
            } else {
                setInputObj({ ...inputObj, isSold: false });
                setDropDownValue("No");
            }
        }
    }, [inputObj.sellingPrice, inputObj.totalAmount]);

    return (
        <EditableRowStyle>
            <div className="rowData">
                <SelectDropdownInput
                    options={subTypeOptions}
                    name="subType"
                    value={inputObj.subType}
                    onChange={handleInputChange}
                />
            </div>

            <div className="rowData">
                <InputDateWithOutLabel
                    type="date"
                    name="startDate"
                    value={moment(inputObj.startDate).format("YYYY-MM-DD")}
                    onChange={handleInputChange}
                    id=""
                />
            </div>
            <div className="rowData">
                <InputDateWithOutLabel
                    type="date"
                    name="endDate"
                    value={moment(inputObj.endDate).format("YYYY-MM-DD")}
                    onChange={handleInputChange}
                    id=""
                />
            </div>

            <div className="rowData">
                <TextInput
                    name={"details"}
                    placeholder={placeHolders[3].head}
                    label={""}
                    value={inputObj.details}
                    handleTextInput={handleInputChange}
                    error={error}
                />
            </div>
            <div className="rowData">
                <NumberInput
                    label={""}
                    placeholder={placeHolders[4].head}
                    name={"amount"}
                    value={inputObj.amount}
                    handleNumberInput={handleInputChange}
                    error={error}
                />
            </div>
            <div className="rowData">
                {incomeType !== "DIGITAL_ASSET" ? (
                    <NumberInput
                        label={""}
                        placeholder={placeHolders[5].head}
                        name={"totalAmount"}
                        value={inputObj.totalAmount}
                        handleNumberInput={handleInputChange}
                        error={error}
                    />
                ) : (
                    <SelectDropdownInput
                        options={["Yes", "No"]}
                        name="isSold"
                        value={dropDownValue}
                        onChange={handleInputChange}
                    />
                )}
            </div>
            <div className="rowData">
                <NumberInput
                    label={""}
                    placeholder={placeHolders[6].head}
                    name={"sellingPrice"}
                    value={inputObj.sellingPrice}
                    handleNumberInput={handleInputChange}
                    error={error}
                />
            </div>
            <div
                className="rowData"
                style={{
                    display: "flex",
                    justifyContent:
                        inputObj.document === undefined ||
                        inputObj.document.length < 1
                            ? "center"
                            : "flex-start",
                    position: "relative",
                }}
            >
                <FileUpload
                    name={"document"}
                    value={inputObj.document}
                    handleFileUpload={handleInputChange}
                />

                <AiOutlineCheck
                    className="icon  submit"
                    onClick={() => {
                        if (
                            inputObj.details.length < 1 ||
                            inputObj.amount.length < 1 ||
                            inputObj.totalAmount.length < 1
                        ) {
                            setError(true);
                        } else {
                            handleEditFormSubmit(inputObj, editId, items.isNew);
                        }
                    }}
                />
                <FaCross
                    className="icon cross"
                    style={{
                        background: "#ff4136",
                        cursor: "pointer",
                    }}
                    onClick={() =>
                        handleEditClick(
                            editId,
                            false,
                            "cancel",
                            "",
                            items.isNew
                        )
                    }
                />
            </div>
        </EditableRowStyle>
    );
};

export default EditableRowBusinessCrypto;
