import React from 'react';
import AuthorDetailStyle from './authorDetail.styled';
import VerifiedChip from '../../../commonComponents/VerifiedChip';
import { shareArticle } from '../constant';
import { extractContent } from '../../../utils/textUtil';


const AuthorDetail = ({ authorData, authorType,brief,interest, path, data, isVerified }) => {

	return (
		<AuthorDetailStyle>
			{authorType === 'EXPERT' ? (
				<div className='outer'>
					<img
						src={authorData.image}
						alt={authorData.image}
						className='authorImage'
					/>
					<div className='authorInfo'>
						<h2>Author</h2>
						<div className='author'>
							<p className='authorName'>{authorData.first_name} </p>
							<VerifiedChip className={'verified'} />
			
						</div>
						<p className='brief'>{brief}</p>

						<div className='authorTags'>
							{interest?.map((data, i) => (
								<div key={i}>{data}</div>
							))}
						</div>
					</div>
				</div>
			) : (
				<>
					<div className='authorInfo'>
						<div className='author'>Author</div>
						<div className='logo'>
							<img
								src={process.env.PUBLIC_URL + '/assets/logo/prosperr-logo.svg'}
								alt='prosperrLogo'
							/>
							<div className='prosperrText'>Prosperr Team</div>
							{isVerified && authorType !== 'EXPERT' && (
								<VerifiedChip className={'verified'} />
							)}
						</div>
					</div>
					<div className='shareBtn'>
						<div className='shareTitle'>Share on</div>
						<div className='icons'>
							{shareArticle.map((d, i) => {
								return (
									<img
										className='shareIcons'
										src={process.env.PUBLIC_URL + '/assets/icons/' + d?.img}
										alt='prosperrLogo'
										onClick={() => {
											d.clickEvent(path, extractContent(data.title));
										}}
									/>
								);
							})}
						</div>
					</div>
				</>
			)}
		</AuthorDetailStyle>
	);
};

export default AuthorDetail;
