import styled from 'styled-components';
import { dim } from '../../styles/theme';

export const NumberInputStyle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: auto;
	position: relative;
	margin: 0 0 0 0;
	padding: 0;
	box-sizing: border-box;

	input {
		/* width: 120px; */
		width: 100%;
		height: 32px;
		min-width: 112.0546875px;
		margin: 0;
		padding: 4px;
		border-radius: 4px;
		border: 1px solid #cccccc;
		box-sizing: border-box;
	}

	input[type='text'] {
		color: #212121;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input:focus {
		outline: none;
	}

	input::placeholder {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 24px;
	}
`;
