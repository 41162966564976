import React, { useContext, useState } from "react";
import Popup from "reactjs-popup";
import PopUpPayment from "../../components/PopUpPayment";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import BASE_URL from "../../Services/API_ENDPOINT";
import FlexRow from "../FlexRow/FlexRow";
import TimeSlot from "../TimeSlot/TimeSlot";
import "./Popup.css";

const PopupBtn = ({
  Trigger,
  setchecked,
  setopen,
  Id,
  external_id,
  AdvisorId,
  arrayData,
  pop,
  id,
  token,
  open,
  email,
  setUserId,
  checked,
  category_type,
  category_id,
  name,
  mobile,
  handleMsg,
  innerPage = false,
  userId = "",
  actualPlanPrice,
  dueAmount,getUserDetails
}) => {
  const a = useContext(AdvisorContext);
  const idToken = token;
  const [overlay, setoverlay] = useState(false);
  return (
    <span>
      <Popup
        trigger={<span> {Trigger} </span>}
        open={open}
        onClose={() => {
          if (!overlay) {
            setopen(false);
          } else {
          }
        }}
        modal
        nested
      >
        {(close) => (
          <div className="">
            {!open ? (
              <div className="modal">
                <span className="closePop" onClick={close}>
                  &times;
                </span>
                <div className="headerPop">
                  {pop ? (
                    <b>Reschedule appointment</b>
                  ) : (
                    <b>Do you really want to cancel appointment</b>
                  )}{" "}
                </div>
                <div className="contentPop">
                  {pop ? (
                    <div className="" style={{ width: "780px" }}>
                      <TimeSlot
                        Id={Id}
                        AdvisorId={AdvisorId}
                        Close={() => close()}
                        arrayData={arrayData}
                        token={token}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "400px",
                        margin: "auto",
                      }}
                    >
                      {" "}
                      <FlexRow>
                        <span
                          className="Appointmentbtn"
                          onClick={async () => {
                            const url = `${BASE_URL}appointments/v1/cancelAppointment?appointmentId=${id}&canceledBy=${
                              JSON.parse(localStorage.getItem("user"))
                                .displayName
                            }&flag=CANCELED`;
                            const response = await fetch(url, {
                              method: "PUT",

                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: `${idToken}`,
                                Source: "cms",
                              },
                            });
                            a.setDelete(true);
                            return response;
                          }}
                        >
                          {" "}
                          Yes{" "}
                        </span>
                        <span
                          className="Appointmentbtn"
                          onClick={() => {
                            close();
                          }}
                        >
                          No
                        </span>
                      </FlexRow>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <PopUpPayment
                close={() => close()}
                setchecked={setchecked}
                setopen={setopen}
                email={email}
                setUserId={setUserId}
                setoverlay={setoverlay}
                external_id={external_id}
                name={name}
                innerPage={innerPage}
                handleMsg={handleMsg}
                mobile={mobile}
                category_id={category_id}
                category_type={category_type}
                userId={userId}
                actualPlanValue={actualPlanPrice}
                dueAmount={dueAmount}
                getUserDetails={getUserDetails}
              />
            )}
          </div>
        )}
      </Popup>
    </span>
  );
};

export default PopupBtn;
