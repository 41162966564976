import React from 'react';
import TabsStyle from './Tabs.styled';

const Tabs = ({ tabsData, selectedTab, handleSelectTab }) => {
  return (
    <TabsStyle>
      {tabsData.map((tabItems) => (
        <li
          className={
            parseInt(selectedTab) === parseInt(tabItems.id) ? 'active' : null
          }
          id={tabItems.id}
          key={tabItems.id}
          onClick={handleSelectTab}
        >
          {tabItems.name}
        </li>
      ))}
    </TabsStyle>
  );
};

export default Tabs;
