import styled from "styled-components";

export const Main = styled.div`
    background: #fff;
    margin: auto;
    height: auto;
    border-radius: 8px;
    width: 733px;
    position: relative;
    margin-top: 74px;
    
    .popContent {
        font-size: 20px;
        margin: 0 60px 0 60px;
        color: #808080;
        text-align: center;
        padding-top: 40px;
    }
    .popBox {
        background-color: #fafafa;
        border-radius: 0 0px 8px 8px;
    }
    .popButton {
        width: 50%;
        height: 50px;
        border-radius: 0 0 0 8px;
        text-align: center;
        padding-top: 14px;
        font-size: 18px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .yes {
        color: #fff;
        background: ${(props) => (!props.disabled ? "#0b6ab0" : "#91c2e6")};
        cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
    }
    .no {
        border-radius: 0 0 8px 0;
        border-top: 1px solid #d1d1d1;
        cursor: pointer;
    }
    .submit{
        width: 200px;
    }
    .closePop {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
    }
    .input {
        display: flex;
       flex-wrap: wrap;
        margin: 10px 60px 20px;
    }
    .standard-basic {
        margin: 2px 20px;
        min-width:40%;
    }
    input.checkbox {
        height: 14px;
        width: 14px;
    }
    .flex {
        display: flex;
        font-size: 14px;
        margin-left: 20px;
        align-items: center;
    }
    .errorPayment {
        font-size: 12px;
        color: red;
        text-align: center;
    }
    .dateInput {
        width: 58%;
        height: 35px;
        margin: 0;
        padding: 8px 16px;
        border-radius: 4px;
        border: 1px solid #f1f5f7;
        background: #f1f5f7;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #212f45;
        box-sizing: border-box;
    }
    .dateWrapper{
        margin-left: 20px;
    }
    .select-input {
        padding: 4px 8px;
    }
    .errorMsg{
        color:red;
        font-size:12px;
    }
    .payType{ 
        margin: 0 80px;
        padding-bottom: 10px;
        
        
    }

    .payTypeHeading{
        font-size: 16px;
    }

.payTypeDropdown {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: border-color 0.3s;
}

.paytypeHeading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}
.paytypeMsg{
    justify-content: center;
    padding: 0;
    margin: 0;
    text-align: center;
    padding: 6px;
}
.payTypeText{
    font-weight: 800;
    color:green;
    text-transform: capitalize;
}
.confirmationWrapper{
    height: 300px;
    box-sizing: border-box;
    padding: 20px;
}


.confirmationWrapper {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.confirmationWrapper p {
  margin: 10px 0;
  font-size: 1.1rem;
  line-height: 1.5;
}


.confirmationWrapper p span {
  font-weight: bold;
  color: #007bff;
}


.cautionText {
  color: #dc3545;
  font-weight: bold;
}


.popBox {
  padding: 20px;
  margin-top: 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}


.popButton {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popButton.yes {
  background: ${(props) => (!props.disabled ? "#28a745"  : "gray" )};
  color: #fff;
  cursor: ${(props) => (!props.disabled ? "pointer"  : "not-allowed" )};
}

.popButton.no {
  background-color: #dc3545;
  color: #fff;
}

.paymentTypeText{
    text-transform: capitalize;
    font-weight: 700;
}
.submitContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
`;
