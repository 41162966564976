import { Switch } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme, disabled }) => ({
	width: 40,
	height: 24,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 24
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(5px)'
		}
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(15px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor:
					theme.palette.mode === 'dark'
						? disabled
							? '#909090'
							: '#15be53'
						: disabled
						? '#909090'
						: '#15be53'
			}
		}
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 20,
		height: 20,
		borderRadius: 11,
		transition: theme.transitions.create(['width'], {
			duration: 200
		})
	},
	'& .MuiSwitch-track': {
		borderRadius: 24 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === 'dark'
				? 'rgba(255,255,255,.35)'
				: 'rgba(0,0,0,.25)',
		boxSizing: 'border-box'
	}
}));

const IosSwitch = ({ value = true, disabled = false, onChange = () => {} }) => {
	return (
		<AntSwitch
			disabled={disabled}
			checked={value}
			onChange={onChange}
			inputProps={{ 'aria-label': 'ant design' }}
		/>
	);
};

export default IosSwitch;
