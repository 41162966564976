import React from "react";
import styled from "styled-components";

const TableHeadWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  box-sizing: border-box;

  span {
    width: 33.33%;
    color: #425466;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const TableHead = () => {
  return (
    <TableHeadWrapper>
      <span>Document Name</span>
      <span>Company/Broker</span>
      <span>Document</span>
      <span>Actions</span>
    </TableHeadWrapper>
  );
};

export default TableHead;
