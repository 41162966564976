export const actions = {
  GET_SESSION_FOR_USER: "GET_SESSION_FOR_USER",
  POST_SESSION_FOR_USER: "POST_SESSION_FOR_USER",
  POST_MESSAGE_FOR_USER: "POST_MESSAGE_FOR_USER",
  SET_POST_MESSAGE_FOR_USER: "SET_POST_MESSAGE_FOR_USER",
  SET_SESSION_FOR_USER: "SET_SESSION_FOR_USER",
  SET_POST_SESSION_FOR_USER: "SET_POST_SESSION_FOR_USER",
  GET_SESSION_MESSAGE_FOR_USER: "GET_SESSION_MESSAGE_FOR_USER",
  SET_SESSION_MESSAGE_FOR_USER: "SET_SESSION_MESSAGE_FOR_USER",
  CHANGE_SHOW_SERVICE_MODAL: "CHANGE_SHOW_SERVICE_MODAL",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  CHANGE_SELECTED_ADVISOR_ID: "CHANGE_SELECTED_ADVISOR_ID",
  SET_SELECTED_CHAT: "SET_SELECTED_CHAT",
};

export function getSession(user_id, isPolling = false) {
  return {
    type: actions.GET_SESSION_FOR_USER,
    data: user_id,
    isPolling: isPolling,
  };
}
export function postSession(data, adv) {
  return {
    type: actions.POST_SESSION_FOR_USER,
    data: data,
    adv: adv,
  };
}
export function postSessionMessage(data) {
  return {
    type: actions.POST_MESSAGE_FOR_USER,
    data: data,
  };
}

export function getSessionMessage(data) {
  return {
    type: actions.GET_SESSION_MESSAGE_FOR_USER,
    data: data,
  };
}
