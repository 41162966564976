import BASE_URL from "./API_ENDPOINT";
import { getAuthToken } from "./SuperSaverClientChat";

export const getAllRentAgreement = async () => {
  const url = `${BASE_URL}rent-agreement/v1/all`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: await getAuthToken(),
      Source: "cms",
    },
  });
  const data = await response.json();
  return data;
};
export const UpdateStatusRentAgreement = async (externalId, status, payloadBody) => {
  const url = `${BASE_URL}rent-agreement/v1/update?external_id=${externalId}&status=${status}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: await getAuthToken(),
      Source: "cms",
    },
    body: JSON.stringify(payloadBody),
  });
  const data = await response.json();
  return data;
};
