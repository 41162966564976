import React, { useState } from 'react';
import {
	Container,
	NestedListViewWrapper,
	SubItem
} from './NestedListView.styled';
import { HiPlusCircle, HiMinusCircle } from 'react-icons/hi';


const NestedListView = ({ list = [],handleViewBtn }) => {
	const [activeItemId, setActiveItemId] = useState(null);

	return (
		<NestedListViewWrapper>
			{list.map((item) => {
				return (
					<div className='first-level-item'  key={item.id}>
						<tr className='first-level-rows'>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{item.subItems.length > 0 ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{item.isSelected  ? (
											<HiMinusCircle
												size={16}
												style={{ cursor: 'pointer' }}
												color={'#6D6D6D'}
												onClick={() => {
													handleViewBtn(item.id, false);
												}}/>
										) : (
											<HiPlusCircle
												size={16}
												style={{ cursor: 'pointer' }}
												color={'#6D6D6D'}
												onClick={() => {
													handleViewBtn(item.id, true);
												}}/>
										)}
									</div>
								) : (
									<></>
								)}
								&nbsp;
								<th
									style={
										item.subItems.length < 1
											? { marginLeft: '0' }
											: { marginLeft: 0 }
									}
									className='first-level-title'
								>
									{item.title}
								</th>
							</div>
							<th className='first-level-data'>{item.value}</th>
						</tr>
						<Container active={item.isSelected}>
							{item.subItems.map((subItem, index) => {
								return (
									<SubItem key={index}>
										<tr className='second-level-rows'>
											<td className='second-level-title'>{subItem.title}</td>
											<td className='second-level-data'>{subItem.value}</td>
										</tr>
									</SubItem>
								);
							})}
						</Container>
					</div>
				);
			})}
		</NestedListViewWrapper>
	);
};

export default NestedListView;
