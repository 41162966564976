import React from "react";
import { RequestedDocWrapper } from "./RequstedDoc.styled";

const RequestedDoc = ({ description, docType }) => {
  return (
    <RequestedDocWrapper>
      <h2>{docType}</h2>
      <p> | {description}</p>
    </RequestedDocWrapper>
  );
};

export default RequestedDoc;
