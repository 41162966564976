import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PrettoSlider from '../../../../../../commonComponents/PrettoSlider';
import PrefixInput from '../../../../../../commonComponents/PrefixInput';
import { dim } from '../../../../../../styles/theme';

export const SliderComponent = ({
	value = 1000000,
	label = '',
	disabled = false,
	min = { value: 0, label: '₹0 ' },
	max = { value: 10000000, label: '₹1 Cr' },
	style = {},
	onChange = (e) => {}
}) => {
	const [_value, setValue] = useState(value);

	useEffect(() => {
		disabled && setValue(value);
	}, [value]);

	return (
		<SliderStyled style={style}>
			<p className='label salary-label'>{label}</p>
			<div className='slider'>
				<div className='slider-wrapper'>
					<PrettoSlider
						disabled={disabled}
						className='ctc-slider'
						aria-label={'pretto slider'}
						min={min.value}
						max={max.value}
						value={_value}
						onChange={(e) => {
							setValue(e.target.value);
							onChange(e.target.value);
						}}
					/>
					<div className='labels'>
						<p className='label'>{min.label}</p>
						<p className='label'>{max.label}</p>
					</div>
				</div>
				<PrefixInput
					disabled={disabled}
					type='number'
					style={{ width: 140, marginLeft: 24, background: '#F6F9FC' }}
					prefix='₹'
					value={_value}
					placeholder='e.g. 50,000'
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
				/>
			</div>
		</SliderStyled>
	);
};

const SliderStyled = styled.div`
	.slider-wrapper {
		width: 80%;
		box-sizing: border-box;
	}
	.slider {
		display: flex;
		justify-content: space-between;
	}

	.labels {
		margin-top: -${dim._8px};
		display: flex;
		justify-content: space-between;
	}

	.label {
		font-size: ${dim._12px};
		margin-bottom: ${dim._8px};
	}

	.salary-label {
		margin-top: ${dim._16px};
	}

	input {
		border: 0;
		outline: 0;
	}

	.text-input {
		width: ${dim._scale(100)};
		height: ${dim._28px};
		font-style: normal;
		font-weight: 400;
		font-size: ${dim._18px};
		line-height: ${dim._24px};
		background: #f1f5f7;
		border-radius: ${dim._4px};
		border: 1px solid #bbbbbb;
		padding: ${dim._4px};
		outline-color: #898989;
		text-align: center;
		margin-left: ${dim._24px};
	}

	.MuiSlider-root {
		box-sizing: border-box;
	}

	.MuiSlider-rail {
		box-sizing: border-box;
	}

	.ctc-slider {
	}
`;
