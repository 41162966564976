import React from "react";
import {AdvisorCardWrapper} from "./index.styled";
import {forwardRef} from "react";
import TextButton from "../../../commonComponents/TextButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const AdvisorCard = ({advisor, active, onClickChats, onClickUsers}, ref) => {


  return (
    <AdvisorCardWrapper
      active={active}
      haveUnread={advisor?.unread_chat?.unread_messages > 0}

      ref={ref}
    >
      <div className="advisorDetails">
     {  advisor?.advisor_details?.image? <img
          src={advisor?.advisor_details?.image}
          alt={advisor?.advisor_details?.name}
          className="adv-image"
        />:<AccountCircleIcon
        style={{
          fontSize: 40,
        }}

        />}
        <div className="advisorDetailsWrapper">
          <h2 className="adv-name">{advisor?.advisor_details?.name}</h2>
          <div className="contents">
            <h2 className="unread-message">
              <div className="labelText">Session</div>
              { advisor?.unread_chat?.unread_sessions||0}
            </h2>
            <h2 className="unread-message">
              <div className="labelText">Chat</div>
              {advisor?.unread_chat?.unread_messages ||0}
            </h2>
          </div>
        </div>
      </div>

   
      <div className="buttonWrapper">
        <TextButton className="underline" onClick={onClickChats}>
          View All
        </TextButton>
     {  advisor?.unread_chat?.unread_sessions > 0&& <TextButton className="underline" onClick={onClickUsers}>
          Unread
        </TextButton>}
      </div>
    </AdvisorCardWrapper>
  );
};

export default forwardRef(AdvisorCard);
