import React, { useEffect, useState } from "react";
import {
  ActionableDocumentStyle,
  UploadDocumentStyle,
} from "./AdvisorUploadDocument.styled";
import TextButton from "../../../../../commonComponents/TextButton";
import { getFillName } from "../../../../../utils/randomFileNameGenerator";
import { PreSignedUrlUpload } from "../../../../../utils/preSignedUrl";
import toast from "react-hot-toast";
import DropDownComponent from "../../../components/DropdownComponet";
import { DOCUMENT_TYPE, ERROR_MESSAGE } from "../../../constant";
import { useParams } from "react-router";
import FormErrorMsgHandler from "../../../../../commonComponents/FormErrormsgHandler";
import { useSelector } from "react-redux";
import { modifyMetaData } from "../../../../../utils/modifyMetaData";

const ActionableDocuments = ({
  handleChangeActionableType,
  documentData,
  isError,
  documentObj,
  setDocumentObj,
}) => {
  const { userId } = useParams();
  const DOCUMENT_TYPE = useSelector((state) => state.cmsReducer?.document_type);
  const [dropDownArrData, setDropDownData] = useState(
    modifyMetaData(DOCUMENT_TYPE)
  );

  useEffect(() => {
    setDropDownData(modifyMetaData(DOCUMENT_TYPE));
  }, [DOCUMENT_TYPE]);

  const handleDropDownChange = (value, i, e) => {
    const newArr = dropDownArrData.map((d, i) => {
      return {
        ...d,
        isSelected: d.label === value || d.value === value,
      };
    });
    setDropDownData(newArr);
    setDocumentObj((state) => {
      return { ...state, [e.target.name]: value };
    });
    // handleTextChange(value, i);
  };
  const DropDownComponentProps = {
    defaultValue: "Select name",
    dataOptions: dropDownArrData,

    handleDropDownChange: handleDropDownChange,
  };
  const handleDeleteFile = () => {
    setDocumentObj({ document_type: "", document_name: "" });
  };
  const handleChangeAttachment = (e) => {
    const imageFile = e.target.files[0];

    const name = e.target.files[0].name;
    const lastDot = name.lastIndexOf(".");
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);

    const newFilename = getFillName(fileName, ext, "actionable", userId);

    const ReturnValueSetter = () => {
      const newObj = {
        ...documentObj,
        status: "UPLOADED",
        owned_by: "ADVISOR",
        document_name: name,
        original_name: name,
        s3link: newFilename,
        uploaded_by: "advisor",
        uploaded_at: new Date().getTime(),
        file: e.target.value,
      };
      setDocumentObj(newObj);
    };

    const preSignedUrl = PreSignedUrlUpload(
      newFilename,
      imageFile,
      userId,
      null,
      ReturnValueSetter
    );
    toast.promise(preSignedUrl, {
      loading: "Document Uploading...",
      success: "Document Uploaded",
      error: "Oops something went wrong",
    });
  };
  return (
    <ActionableDocumentStyle>
      <div className="actionBox">
        <div>
          <div style={{ paddingBottom: "8px" }}>Document Type </div>
          <DropDownComponent
            {...DropDownComponentProps}
            type={dropDownArrData?.filter((d) => d.isSelected)[0]?.value}
            name={"document_type"}
            componentCategory={"document_type"}
            isError={isError}
            defaultValue={"Select Document Type"}
          />
        </div>
        {/* {documentObj?.document_name ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              gap: "10px",
              paddingTop: documentObj?.document_name ? "34px" : "0px",
            }}
            id={documentObj.document_name}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/icons/documentPreviewImage.svg"
              }
              className="documentPreviewImg"
              alt=""
            />
            <div className="documentName">{documentObj.document_name}</div>
            <img
              style={{ width: "20px" }}
              src={
                process.env.PUBLIC_URL + "/assets/icons/documentDeleteIcon.svg"
              }
              className="documentDeleteIcon"
              alt=""
              onClick={() => {
                handleDeleteFile();
              }}
            />
          </div>
        ) : (
          <div>
            <div style={{ paddingBottom: "8px" }}>Upload Document</div>
            <UploadComponent
              handleChangeAttachment={handleChangeAttachment}
              documentObj={documentObj}
            />
          </div>
        )} */}
        {/* <div>
          <TextButton
            onClick={() => {
              handleChangeActionableType(documentObj, setDocumentObj);
            }}
            style={{ marginTop: "32px" }}
          >
            + ADD ACTION
          </TextButton>
        </div> */}
      </div>

      {isError?.error && isError.errorArr.includes("document") && (
        <FormErrorMsgHandler style={{ bottom: "-24px" }}>
          {" "}
          {ERROR_MESSAGE.document}
        </FormErrorMsgHandler>
      )}
    </ActionableDocumentStyle>
  );
};
export default ActionableDocuments;

const UploadComponent = ({ handleChangeAttachment, documentObj }) => {
  const [isUploadTitle, setUploadTitle] = useState(true);

  return (
    <>
      <label className="uploadIcon ">
        <input
          className="uploadFile"
          style={{ display: "none" }}
          type={"file"}
          accept=".jpg, .jpeg, .png, .pdf"
          name=""
          value={documentObj?.file}
          onChange={(e) => {
            handleChangeAttachment(e);
            // setUploadTitle( prev => !prev);
          }}
        />
        <img
          className="icon"
          src={process.env.PUBLIC_URL + "/assets/icons/cloud_icon.svg"}
          alt=""
        />
        <div className="iconDesc">Browse to choose a file </div>
      </label>
    </>
  );
};
