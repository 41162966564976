import Styled from "styled-components";
export const Label = Styled.div`

display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    text-align:center;
    .Datepicker{
        height: 30px;
    width: 108px;
    line-height: 1.5;
    color: rgb(102, 102, 102);
    background-color: rgb(255, 255, 255);
    background-image: none;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    }
   
`;
