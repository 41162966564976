export const months = [
	'JAN',
	'FEB',
	'MAR',
	'APR',
	'MAY',
	'JUN',
	'JUL',
	'AUG',
	'SEP',
	'OCT',
	'NOV',
	'DEC'
];
export const timeStampDateConverter = (timestamp) => {
	var a = new Date(timestamp * 1000);
	var year = a.getFullYear();
	var month = months[a.getMonth()];
	var date = a.getDate();
	var hour = a.getHours();
	var min = a.getMinutes();
	var sec = a.getSeconds();
	var time =
		date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
	return time;
};

export const timeStampDateNewFormat = (timeStamp) => {
	var a = new Date(timeStamp);
	var year = a.getFullYear();
	var month = months[a.getMonth()];
	var date = a.getDate();
	var time = `${date > 9 ? date : '0' + date} ${month} ${year
		.toString()
		.slice(2, 4)}`;
	return time;
};
