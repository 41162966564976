import { actions } from "./action";


const initialState = {
	financialYearId: '',
	financialYear: '',
	incomeArray: [],
	currentTax: 0,
	taxSlab: 0,
	deduction: 0,
};

const TaxReducer = (state = initialState, payload = {}) => {
	switch (payload.type) {
		case actions.SET_F_Y_OBJECT:
			return {
				...state,
				financialYearId: payload.data.financialYearId,
				financialYear: payload.data.financialYear
			};
		case actions.SET_INCOME:
			return {
				...state,
				incomeArray: payload.data
			};
		case actions.SET_DEDUCTION:
			return {
				...state,
				deduction: payload.data
			};
		case actions.SET_CURRENT_TAX:
			return {
				...state,
				currentTax: payload.data.currentTax,
				taxSlab: payload.data.taxSlab
			};

		

		default:
			return state;
	}
};

export { TaxReducer };
