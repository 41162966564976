import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import { dim, theme } from "../../../../styles/theme";

export const StyledManualUpdatePayslip = styled.div`
  flex: 1;
  width: 50%;
  background-color: ${colors.white};
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  .reUploadSalarySlip {
    color: black;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid ${theme.color.green_20};
    border-radius: 8px;
    padding: 0px ${dim._8px};
    background: ${theme.color.green_20};
    color: ${theme.color.white_0};
  }
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: ${dim._18px};
    line-height: ${dim._24px};
    color: #635bef;
    margin-top: ${dim._24px};
  }

  .heading {
    font-weight: 600;
    font-size: ${dim._18px};

    line-height: ${dim._24px};
    padding-bottom: ${dim._8px};
  }
  .Salary {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    color: #222;
  }

  .back-btn {
    position: absolute;
    padding: ${dim._18px};
    top: 0;
    left: 0;
    font-size: ${dim._24px};
    color: ${colors.primaryCTA};
    cursor: pointer;
  }
  .submitBtn {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }
  .loaderNew {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.2;
    z-index: 600;
    cursor: not-allowed;
    border-radius: ${dim._4px};
  }
  .loader {
    width: 100%;
    margin: ${dim._10px} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .confirm-components {
    display: flex;
    align-items: center;
  }

  .confirm-components-text {
    margin-left: ${dim._16px};
    font-size: ${dim._12px};
    padding: 0px !important;
    color: ${theme.color.blue_30};
  }

  .error {
    font-weight: 600 !important;
    color: ${theme.color.red_25};
    font-size: ${dim._14px};
  }
`;
