import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend
} from 'recharts';
import CustomTooltip from './CustomTooltip/index';

export default function Chart4({
	activeRadioButton,
	barChartData,
	isReadableFalse,
	chartWidth = 640
}) {
	const barType = () => {
		if (activeRadioButton === '1') {
			return <Bar dataKey='verified' fill='#7BA3D9' barSize={10} />;
		} else if (activeRadioButton === '2') {
			return <Bar dataKey='unverified' fill='#CC7A6C' barSize={10} />;
		} else {
			return (
				<>
					<Bar dataKey='verified' fill='#7BA3D9' barSize={10} />
					<Bar dataKey='unverified' fill='#CC7A6C' barSize={10} />
				</>
			);
		}
	};
	return (
		<BarChart
			width={chartWidth}
			height={320}
			data={barChartData}
			margin={{
				top: 20,
				right: 30,
				left: 20,
				bottom: 5
			}}
			padding={{
				top: 20,
				right: 30,
				left: 20,
				bottom: 5
			}}
			barGap={0}
		>
			<CartesianGrid strokeDasharray='1 1' vertical={false} />
			<XAxis
				dataKey='month'
				label={{
					value: 'Months',
					angle: 0,
					position: 'insideBottom',
					offset: -20,
					className: 'x-axis-label'
				}}
				domain={[0, 2]}
				tick={{ fontSize: 10, fontWeight: '400' }}
				tickFormatter={(value) => value.toString().slice(0, 3)}
			/>
			<YAxis
				label={{
					value: 'Income (in Rs.)',
					angle: -90,
					position: 'insideLeft',
					offset: 0,
					dy: 50,
					y: -0,
					offset: -10,
					className: 'y-axis-label'
				}}
				domain={[0, 'auto']}
				padding={{ top: 0 }}
				tick={{ fontSize: 10, fontWeight: '400' }}
				tickFormatter={(value) => Math.round(value / 1000) + 'k'}
			/>
			{!isReadableFalse && (
				<Tooltip
					cursor={{ fill: 'transparent' }}
					content={<CustomTooltip activeRadioButton={activeRadioButton} />}
					wrapperStyle={{ outline: 'none' }}
				/>
			)}
			<Legend
				layout='horizontal'
				align='center'
				iconSize={12}
				className='legend-text'
				wrapperStyle={{
					right: 0,
					bottom: -30,
					textTransform: 'capitalize'
				}}
			/>
			{barType()}
		</BarChart>
	);
}
