import React, { useEffect, useState } from "react";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import { Main } from "./ActivityHistory.styled";
import ConsultationHistoryContent from "../ConsultationHistoryContent";

const ActivityHistory = ({ financialYear, user_id, consultHistory }) => {
    const [consultationArr, setConsultationArr] = useState([]);

    useEffect(() => {
        handleUpdate(consultHistory);
    }, [consultHistory]);

    const handleUpdate = (value) => {
        setConsultationArr(value);
    };

    return (
        <Main>
            <div className="bar">
                <Timeline
                    sx={{
                        padding: "6px",
                        "& .MuiTimelineItem-root:before": {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {consultationArr[0] !== undefined ? (
                        consultationArr.map((d, i) => {
                            if (i < consultationArr.length - 1) {
                                return (
                                    <TimelineItem>
                                        <TimelineSeparator
                                            style={{
                                                paddingLeft: "0px",
                                                paddingRight: "0px",
                                            }}
                                        >
                                            <TimelineDot
                                                sx={{
                                                    backgroundColor: d.color,
                                                }}
                                            />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <ConsultationHistoryContent
                                                user_id={user_id}
                                                consultationArr={
                                                    consultationArr
                                                }
                                                handleUpdate={handleUpdate}
                                                data={d}
                                                index={i}
                                            />
                                        </TimelineContent>
                                    </TimelineItem>
                                );
                            } else {
                                return (
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot
                                                sx={{
                                                    backgroundColor: d.color,
                                                }}
                                            />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <ConsultationHistoryContent
                                                user_id={user_id}
                                                consultationArr={
                                                    consultationArr
                                                }
                                                handleUpdate={handleUpdate}
                                                data={d}
                                                index={i}
                                            />
                                        </TimelineContent>
                                    </TimelineItem>
                                );
                            }
                        })
                    ) : (
                        <div className="spinnerdiv">
                            No Activity for this user!
                        </div>
                    )}
                </Timeline>
            </div>
        </Main>
    );
};

export default ActivityHistory;
