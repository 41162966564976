// import { theme } from '../styles/theme';
// import { hexToRgba } from './hexToRgba';

import { theme } from "../styles/theme";
import { hexToRgba } from "./hexToRgba";

const getColorByImportance = (importance) => {
  switch (importance) {
    case "High":
      return {
        color: theme.color.green_30,
        background: hexToRgba(theme.color.green_30, 0.3),
      };
    case "low":
      return {
        color: theme.color.orange_10,
        background: theme.color.orangeLight_10,
      };

    case "normal":
      return {
        color: theme.color.blue_30,
        background: theme.color.purpleLight_10,
      };
    default:
      return {
        color: theme.color.orange_10,
        background: theme.color.orangeLight_10,
      };
  }
};

export { getColorByImportance };
