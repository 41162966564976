import React, { useState ,useEffect } from 'react';
import styled from 'styled-components';


const ConfirmationModal = ({ isOpen, onClose, onConfirm , text = 'Default',id }) => {



  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = 'unset'; 
    }

    return () => {
      document.body.style.overflow = 'unset'; 
    };
  }, [isOpen]);


  if (!isOpen) return null;


  return (
    <StyledConfirmation>
    <div className="modal-overlay">
      <div className="modal">
        <div className='container-image'>
        <img src={process.env.PUBLIC_URL + "/assets/icons/caution-icon.svg"} alt='caution'/>
        <img onClick={()=> onClose(false)} src={process.env.PUBLIC_URL + "/assets/icons/close-icon.svg"} alt='caution'/>
        </div>
        <h2>{text}</h2>
        <p className='conf-text'>Are you sure you want to {text} this article?</p>
        <div className="modal-buttons">
          <button className='can' onClick={() => onClose(false)}>Cancel</button>
          {
            text === "Publish" && <button className='btn' onClick={()=> onConfirm(id, text = "PUBLISHED" )}>Publish</button> 
          }
          {
            text === "Unpublish" && <button className='btn' onClick={()=> onConfirm(id, text = "UNPUBLISHED" )}>Unpublish</button>
          }
         
        </div>
      </div>
    </div>
    </StyledConfirmation>
  );
};

export default ConfirmationModal;


const StyledConfirmation = styled('div')`

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal {
  background-color: #fff;
  padding: 20px;
  width: 30%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.modal h2 {
  margin-top: 0;
  text-align: left;
  padding-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
  border: none;
  cursor: pointer;
  
}
.modal-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:24px;
}
.conf-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color:#4B5565;
    padding-bottom: 24px;

}
.btn{
    padding: 10px 20px ;
    border-radius: 8px;
    background-color: #05387E;
}
.can{
    padding: 10px 20px;
    border-radius: 8px;
    color:#D62419;
    font-weight: 600;
    background-color: white;
}
.container-image{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


`