import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import PopUPCms from "../../../../commonComponents/PopUPCms";
import Backdrop from "../../../../commonComponents/PopUPCms/BackDrop";
import PopUpForDocApproval from "../../../../components/PopUpForDocApproval";

const Row = ({ data, handleDeleteExemption }) => {
  const [show, setShow] = useState(false);

  const handleCross = () => {
    setShow(false);
  };

  return (
    <div className="rows">
      <div className="c1">
        <p className="r1">{data.type}</p>
      </div>
      <p className="c2 r2">
        {" "}
        {data.details.length > 0 ? data.details : "--No description--"}
      </p>
      <p className="c4 r4">{data.total_amount}</p>
      <p className="c4 r4">{data.exempted_amount}</p>
      <p className="c4 r4">{data.limit}</p>
      <p
        className="c5 r4"
        onClick={() => {
          handleDeleteExemption(data);
        }}
      >
        <AiOutlineDelete />
      </p>
      <PopUPCms show={show} handleCross={handleCross}>
        <PopUpForDocApproval
          handleCross={handleCross}
          actionName={<b>Submit</b>}
          msg={"Do You want to submit this to user it will reflect to user"}
        />
      </PopUPCms>
      <Backdrop onHideModelClick={handleCross} show={show} />
    </div>
  );
};

export default Row;
