import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import AdvisorData from "../Context/Advisor/AdvisorData";
import { globalNotificationAction } from "../container/ActiveChats/store/reducer";
import { useDispatch, useSelector } from "react-redux";
import SuperSaverDiscussionBookService, {
  advisorData,
} from "../Services/SuperSaverClientChat";
import AdminRoutes from "./RouteComponent/AdminRoutes/index.js";
import AdvisorRoutes from "./RouteComponent/AdvisorRoutes/index.js";
import AuthorRoutes from "./RouteComponent/AuthorRoutes/index.js";
import AccountantRoutes from "./RouteComponent/AccountantRoutes/index.js";
import CsRoutes from "./RouteComponent/CsRoutes/index.js";

const ProtectedRoutes = ({ userType, handleLogoutButton }) => {
  const advisorId = useSelector((state) => state.chat.advisorId);
  const lastMessageTimestamp = useSelector(
    (state) => state.globalNotifications.lastMessageTimestamp
  );
  const location = useLocation();
  const advisor = advisorData();
  const dispatch = useDispatch();
  const timerRef = useRef();
  const { pathname } = location;
  const type = JSON.parse(localStorage.getItem("type"));

  useEffect(() => {
    if (pathname.includes("active-chats")) {
      dispatch({
        type: globalNotificationAction.SET_ADVISOR_CHAT_REFRESH_STATUS,
        payload: true,
      });
    }
    reloadNotification();
    return () => clearTimeout(timerRef.current);
  }, [advisorId, lastMessageTimestamp, pathname]);

  const getActivityStatus = async (lastMessageTimestamp, id) => {
    const activityStatus =
      await SuperSaverDiscussionBookService.getAdvisorsActiveChatStatus(
        id,
        lastMessageTimestamp
      );
    dispatch({
      type: globalNotificationAction.SET_ADVISOR_CHAT_REFRESH_STATUS,
      payload: activityStatus?.is_last_activity,
    });
  };

  const reloadNotification = async () => {
    const id = userType === "ADVISOR" ? advisor.user_roll_id : advisorId;
    if (!id) {
      return;
    }
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      reloadNotification();
    }, 30000);

    lastMessageTimestamp && getActivityStatus(lastMessageTimestamp, id);
  };

  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type === null) {
      window.history.pushState("", "", "/");
      window.location.reload();
      localStorage.clear();
      alert("Please use correct credential or authorize user credential");
    }
  }, []);

  const allProtectedRoutes = () => {
    if (userType === "ADMIN") {
   
      return <AdminRoutes/>
  
    } else if (userType === "ADVISOR") {
      return  <AdvisorRoutes/>
    
     
    } 
    else if (userType === "AUTHOR") {
      return <AuthorRoutes/>

    }
    else if (userType === "OPERATION_EXECUTIVE") {
      return <CsRoutes/>

    }
  
    else if ( userType === "ACCOUNTANT") {
      return <AccountantRoutes/>

    }
  };

  return <AdvisorData>{allProtectedRoutes()}</AdvisorData>;
};

export default ProtectedRoutes;
