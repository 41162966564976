import styled from "styled-components";

export const StyledArticleEditor=styled.div`
padding:0;
    width: 100%;
    padding-bottom: 40px;
.wrapper-class {

        width: 100%;
  }
  .editor-class {
    min-height:200px;
        width: 100%;    padding-bottom: 40px;
    
  }
  .toolbar-class {
        width: 80%;
        max-width:100%;
        position: sticky;
    top: 98px;
    z-index: 2;
  }
  .rdw-image-modal-upload-option-label{
    cursor: pointer;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
h1,h2,h3,h4,h5
{
  color:'#222'
}

`