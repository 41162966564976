import React, { useEffect, useState } from "react";
import { EditableRowStyle } from "./EditableRowStyle";
import { AiOutlineCheck } from "react-icons/ai";
import { FaCross } from "react-icons/fa";
import moment from "moment";
import FileUpload from "../../../../commonComponents/FileUpload";
import SelectDropdownInput from "../../../../commonComponents/SelectDropdownInput";
import NumberInput from "../../../../commonComponents/NumberInput";
import TextInput from "../../../../commonComponents/TextInput";
import InputDateWithOutLabel from "../../../../commonComponents/InputDateWithOutLabel";

const EditableRow = ({
    items,
    editId,
    externalId,
    handleAddMoreSubmit,
    handleCancel,
    typeOptions,
    subTypeOptions,
    handleEditFormSubmit,
    handleEditClick,
    placeHolders,
}) => {
    const [inputObj, setInputObj] = useState({
        type: items.type ? items.type : typeOptions[0],
        external_id: externalId,
        subType: items.subType ? items.subType : subTypeOptions[0],
        details: items.details,
        startDate: items.startDate ? items.startDate : new Date().getTime(),
        amount: items.amount,
        sellingPrice: items.sellingPrice,
        document: items.document,
    });

    const [error, setError] = useState(false);

    const handleInputChange = (e, filename) => {
        const { value, name } = e.target;
        if (filename) {
            setInputObj({ ...inputObj, [name]: filename });
        } else if (name.includes("Date")) {
            setInputObj({ ...inputObj, [name]: new Date(value).getTime() });
        } else {
            setInputObj({ ...inputObj, [name]: value });
        }
    };
    useEffect(() => {
        if (
            Number(inputObj.sellingPrice) !== 0 &&
            !isNaN(Number(inputObj.sellingPrice))
        ) {
            setInputObj({ ...inputObj, isSold: true });
        } else {
            setInputObj({ ...inputObj, isSold: false });
        }
    }, [inputObj.sellingPrice]);

    return (
        <EditableRowStyle>
            <div className="rowData">
                <SelectDropdownInput
                    options={typeOptions}
                    name="type"
                    value={inputObj.type}
                    onChange={handleInputChange}
                />
            </div>
            <div className="rowData">
                <SelectDropdownInput
                    options={subTypeOptions}
                    name="subType"
                    value={inputObj.subType}
                    onChange={handleInputChange}
                />
            </div>
            <div className="rowData">
                <TextInput
                    name={"details"}
                    placeholder={placeHolders[2].head}
                    value={inputObj.details}
                    handleTextInput={handleInputChange}
                    error={error}
                />
            </div>
            <div className="rowData">
                <NumberInput
                    placeholder={placeHolders[3].head}
                    name={"amount"}
                    value={inputObj.amount}
                    handleNumberInput={handleInputChange}
                    error={error}
                />
            </div>
            <div className="rowData">
                <NumberInput
                    placeholder={placeHolders[4].head}
                    name={"sellingPrice"}
                    value={inputObj.sellingPrice}
                    handleNumberInput={handleInputChange}
                    error={error}
                />
            </div>
            <div className="rowData">
                <InputDateWithOutLabel
                    type="date"
                    name="startDate"
                    placeholder={placeHolders[5].head}
                    value={moment(inputObj.startDate).format("YYYY-MM-DD")}
                    onChange={handleInputChange}
                    id=""
                />
            </div>
            <div
                className="rowData"
                style={{
                    display: "flex",
                    justifyContent:
                        inputObj.document === undefined ||
                        inputObj.document.length < 1
                            ? "center"
                            : "flex-start",
                    position: "relative",
                }}
            >
                <FileUpload
                    name={"document"}
                    value={inputObj.document}
                    handleFileUpload={handleInputChange}
                />

                <AiOutlineCheck
                    className="icon  submit"
                    onClick={() => {
                        if (
                            inputObj.details.length < 1 ||
                            inputObj.amount.length < 1 ||
                            inputObj.sellingPrice.length < 1 ||
                            inputObj.startDate.length < 1
                        ) {
                            setError(true);
                        } else {
                            handleEditFormSubmit(inputObj, editId, items.isNew);
                        }
                    }}
                />
                <FaCross
                    className="icon cross"
                    style={{
                        background: "#ff4136",
                        cursor: "pointer",
                    }}
                    onClick={() =>
                        handleEditClick(
                            editId,
                            false,
                            "cancel",
                            "",
                            items.isNew
                        )
                    }
                />
            </div>
        </EditableRowStyle>
    );
};

export default EditableRow;
