import React, { useEffect, useState } from "react";
import FlexRow from "../../commonComponents/FlexRow/FlexRow";
import { updatePayment, updatePaymentNew } from "../../Services/SuperSaver";
import { getAuth } from "firebase/auth";
import { Main } from "./PopUpPayment.styled";
import { TextField } from "@mui/material";
import moment from "moment";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Button from "../../commonComponents/Button";
import { amountWithGst, getGstAmount } from "../../utils/getGstAmount";
import { getTwoDecimal } from "../../utils/numberUtils";
import { PaymentType } from "./constant";
import CustomTextField from "../../commonComponents/CustomTextField";
const DEFAULT_HEADING = "Do you want to Proceed with this operation ?";

const PopUpPayment = ({
  close,
  email,
  upgraded_plan_category_id,
  setUserId,
  setchecked,
  totalPlanAmount,
  setopen,
  setoverlay,
  category_type,
  name,
  external_id,
  mobile,
  category_id,
  handleMsg,
  innerPage = false,
  userId,
  style,
  popContentStyle,
  heading = DEFAULT_HEADING,
  handleSteps,
  total_consumed_coins_amount,
  toggleBackdrop,
  actualPlanValue,
  dueAmount,
  getUserDetails,
}) => {
  const [invoice, setInvoice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [actualSaleValue, setActualSaleValue] = useState(0);
  const [actualPlanPrice, setActualPlanPrice] = useState(actualPlanValue);
  const [baseAmount, setBaseAmount] = useState("");
  const [partiallyPaidAmount, setPartiallyPaidAmount] = useState("");
  const [productType, setProductType] = useState(undefined);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [discountObj, setDiscountObj] = useState({
    company_discount_amount: "",
    referral_discount_amount: 0,
    gst_amount: "",
    payment_date: moment().format("YYYY-MM-DD"),
  });
  const fyObj = useSelector((state) => state?.LayoutReducer?.F_Y_OBJ || 0);
  const FYOptions = useSelector((state) => state.LayoutReducer.F_Y_ARR);
  const [selectedFy, setSelectedFy] = useState({});
  const [loading, setLoading] = useState(false);
  const [coinDiscount, setCoinDiscount] = useState(0);
  
  const [paymentType, setPaymentType] = useState(PaymentType.FULL);
  const [showCautionScreen, setShowCautionScreen] = useState(false);

  const roundedOffDueAmount = amountWithGst(dueAmount);

  useEffect(() => {
    handleFYChange({
      label: fyObj.financialYear,
      value: fyObj.financialYearId,
    });
  }, [fyObj]);

  const handleFYChange = (value) => {
    setSelectedFy(value);
  };
  const handlePaymentType = (val) => {
    setPaymentType(val);
    setAmount(0);
    setPartiallyPaidAmount(0);
  };

  const handleDisabled = () => {
    if (!invoice || !amount) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  useEffect(() => {
    handleDisabled();
  }, [invoice, amount]);

  const renderArr = [
    {
      label: "Invoice No.",
      value: invoice,
      showInPartial: true,
      showInFull: true,
      fn: (e) => {
        setInvoice(e.target.value);
      },
    },
    {
      label: "Actual Plan Price(as Shown on Website)",
      value: actualPlanPrice,
      disabled: true,
      showInPartial: true,
      showInFull: true,
      fn: (e) => {},
    },
    {
      label: "Actual Sale price (GST included)",
      value: actualSaleValue,
      showInPartial: true,
      showInFull: true,
      fn: (e) => {
        const enteredAmount = e.target.value;
        if (enteredAmount > amountWithGst(actualPlanPrice)) {
          return;
        }
        setActualSaleValue(enteredAmount);

        if (paymentType === PaymentType.FULL) {
          const gst = getGstAmount(enteredAmount);
          const baseAmount = enteredAmount - gst;
          const discount = actualPlanPrice - baseAmount;
          setAmount(baseAmount);
          setBaseAmount(baseAmount);
          setDiscountObj((prevDiscountObj) => ({
            ...prevDiscountObj,
            company_discount_amount: discount?.toString(),
            gst_amount: gst,
          }));
        }
      },
    },
    {
      label: "Partially paid amount",
      value: partiallyPaidAmount,
      showInPartial: true,
      showInFull: false,
      disabled: !actualSaleValue,
      fn: (e) => {
        let enteredAmount = parseInt(e?.target?.value);

        if (enteredAmount > actualSaleValue) {
          return;
        }

        setPartiallyPaidAmount(enteredAmount);
        const gstOfPartialPayment = getGstAmount(enteredAmount);
        const gstOfActualSaleValue = getGstAmount(actualSaleValue);
        const baseAmountOfPartiallyPaidAmount =
          enteredAmount - gstOfPartialPayment;
        const baseAmountForDiscount = actualSaleValue - gstOfActualSaleValue;

        const discount = actualPlanPrice - baseAmountForDiscount;

        setBaseAmount(baseAmountForDiscount);
        setAmount(baseAmountOfPartiallyPaidAmount);
        setDiscountObj((prevDiscountObj) => ({
          ...prevDiscountObj,
          company_discount_amount: discount,
          gst_amount: gstOfPartialPayment,
        }));
      },
    },
    {
      label: "Base Amount",
      value: baseAmount,
      disabled: true,
      showInPartial: true,
      showInFull: true,
      fn: (e) => {},
    },
    {
      label: "GST",
      value: discountObj.gst_amount,
      disabled: true,
      showInPartial: true,
      showInFull: true,
      fn: (e) => {
        upgraded_plan_category_id
          ? setDiscountObj({
              ...discountObj,
              gst_amount: getTwoDecimal(e.target.value),
            })
          : setDiscountObj({ ...discountObj, gst_amount: e.target.value });
      },
    },
    {
      label: "Total Discount",
      value: amount ? discountObj?.company_discount_amount - coinDiscount : 0,
      disabled: true,
      showInPartial: true,
      showInFull: true,
      fn: (e) => {
        setDiscountObj({
          ...discountObj,
          company_discount_amount: e.target.value,
        });
      },
    },
    {
      label: innerPage ? "Coins to use" : "Referral Amount",
      value: coinDiscount,
      showInPartial: false,
      showInFull: true,
      fn: (e) => {
        setCoinDiscount(e.target.value);
        setDiscountObj({
          ...discountObj,
          referral_discount_amount: e.target.value,
        });
      },
      disabled: upgraded_plan_category_id || !amount,
    },
  ];

  const dueAmountInputs = [
    {
      label: "Invoice No.",
      value: invoice,
      showInPartial: true,
      showInFull: true,
      fn: (e) => {
        setInvoice(e.target.value);
      },
    },
    {
      label: "Due Amount ",
      value: roundedOffDueAmount,
      showInPartial: true,
      showInFull: true,
      disabled: true,
      fn: (e) => {},
    },

    {
      label: "Amount",
      value: actualSaleValue,
      showInPartial: false,
      showInFull: true,
      fn: (e) => {
        const enteredAmount = e.target.value;
        if (enteredAmount > roundedOffDueAmount) {
          return;
        }
        setActualSaleValue(enteredAmount);
        if (paymentType === PaymentType.FULL) {
          const gst = getGstAmount(enteredAmount);
          const baseAmount = enteredAmount - gst;
          setAmount(baseAmount);
          setDiscountObj((prevDiscountObj) => ({
            ...prevDiscountObj,
            gst_amount: gst,
            company_discount_amount: "0",
          }));
        }
      },
    },
    {
      label: "Partially paid amount",
      value: partiallyPaidAmount,
      showInPartial: true,
      showInFull: false,
      fn: (e) => {
        const enteredAmount = e.target.value;
        if (enteredAmount > roundedOffDueAmount) {
          return;
        }

        setPartiallyPaidAmount(enteredAmount);

        const gstOfPartialPayment = getGstAmount(enteredAmount);
        const baseAmountOfPartiallyPaidAmount =
          enteredAmount - gstOfPartialPayment;
        setAmount(baseAmountOfPartiallyPaidAmount);

        setDiscountObj((prevDiscountObj) => ({
          ...prevDiscountObj,
          gst_amount: gstOfPartialPayment,
          company_discount_amount: "0",
        }));
      },
    },
    {
      label: "GST",
      value: discountObj.gst_amount,
      disabled: true,
      showInPartial: true,
      showInFull: true,
      fn: (e) => {
        upgraded_plan_category_id
          ? setDiscountObj({
              ...discountObj,
              gst_amount: getTwoDecimal(e.target.value),
            })
          : setDiscountObj({ ...discountObj, gst_amount: e.target.value });
      },
    },
  ];

  const renderTextFields = (dataArray, filterCondition) => {
    return dataArray.filter(filterCondition).map((data, i) => (
      <CustomTextField
        index={i}
        className="standard-basic"
        label={data.label}
        data={data}
        type="number"
        variant="standard"
        handleChange={(e) => {
          data.fn(e);
        }}
      />
    ));
  };
  const sortFieldsByDisabled = (fieldsArray) => {
    return fieldsArray.sort((a, b) => {
      if (a.disabled && !b.disabled) return 1;  
      if (!a.disabled && b.disabled) return -1; 
      return 0; 
    });
  };

  const ConfirmationScreen = () => {
    return (
      <div className="confirmationWrapper">
        <div>
          <p>
            Please Note you are making{" "}
            <span className="paymentTypeText">{paymentType}</span> payment
          </p>
          {paymentType === PaymentType.FULL && (
            <div>
              <p className="cautionText">
                The amount you entered will be marked as Actual sale price and
                Full payment
              </p>
              <p>
                {" "}
                Actual Sale Value : <span>{actualSaleValue}</span>{" "}
              </p>
            </div>
          )}
          {paymentType === "partial" && (
            <div>
              <p>
                {" "}
                Actual Sale Value : <span>{actualSaleValue}</span>{" "}
              </p>
              <p>
                Partially paid Amount : <span>{partiallyPaidAmount}</span>
              </p>
            </div>
          )}
        </div>
        <div>
          {!handleSteps && (
            <div className="popBox">
              <p>Do you want to proceed?</p>
              <div className="buttonsContainer">
                <span className="popButton yes" onClick={() => handleYes()}>
                  Yes
                </span>
                <span
                  className="popButton no"
                  onClick={() => {
                    closeCautionScreen();
                  }}
                >
                  No
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleSubmit = () => {
    if (!disabled) {
      setShowCautionScreen(true);
    }
  };
  const closeCautionScreen = () => {
    setShowCautionScreen(false);
  };

  const handleYes = () => {
    setLoading(true);
    if (disabled) {
      setLoading(false);
    } else {
      !innerPage
        ? updatePayment({
            user_details: {
              name: name,
              email: email,
              mobile: mobile,
            },
            payment_details: {
              invoice_id: invoice,
              amount: amount,
              referral_discount_amount:
                discountObj?.referral_discount_amount || 0,
              company_discount_amount: discountObj?.company_discount_amount,
              gst_amount: discountObj?.gst_amount,
              payment_date: new Date(discountObj?.payment_date).getTime(),
            },
            product: productType,
            category_id: category_id,
            category_type: category_type,
            external_id: external_id,
            financial_year_id: selectedFy.value,
          })
            .then((data) => {
              if (data.code) {
                toast.error(data.message);
              } else {
                setUserId(external_id);
                handleMsg("payment status changed successfully");
                setopen(false);
                setchecked(true);
              }
            })
            .catch((e) => {
              toast.error(e.message);
            })
        : updatePaymentNew(
            {
              invoice_id: invoice,
              amount: amount,
              company_discount_amount: discountObj.company_discount_amount,
              payment_date: new Date(discountObj.payment_date).getTime(),
              gst_amount: discountObj.gst_amount,
              coin_used: discountObj.referral_discount_amount || "0",
              ...(upgraded_plan_category_id && {
                upgraded_plan_category_id,
              }),
            },
            userId
          )
            .then((data) => {
              if (data.code) {
                toast.error(data.message);
              } else {
                upgraded_plan_category_id
                  ? handleSteps()
                  : handleMsg("payment status changed successfully");
                setchecked(true);
                getUserDetails();
              }
              setLoading(false);
            })
            .catch((e) => {
              toast.error(e.message);
              setLoading(false);
            });

      close();

      setoverlay(true);
    }
  };

  useEffect(() => {
    setDiscountObj((state) => ({
      ...state,
      referral_discount_amount: total_consumed_coins_amount,
    }));
  }, [total_consumed_coins_amount]);

  return (
    <Main disabled={disabled} style={style}>
      {
        <span
          className="closePop"
          onClick={() => {
            if (toggleBackdrop) {
              toggleBackdrop();
              return;
            }
            close();
            setchecked(false);
            setopen(false);
          }}
        >
          &times;
        </span>
      }

      {showCautionScreen ? (
        <ConfirmationScreen />
      ) : (
        <>
          <div className="popContent" style={popContentStyle}>
            <b>{heading} </b>
          </div>

          {dueAmount === 0 && (
            <div className="payType">
              <p className="paytypeHeading">Select payment Type</p>

              <div className="fullOrPartialContainer">
                <select
                  className="payTypeDropdown"
                  value={paymentType}
                  onChange={(e) => handlePaymentType(e.target.value)}
                >
                  <option className="payType" value={PaymentType.FULL}>
                    Full
                  </option>
                  <option className="payType" value={PaymentType.PARTIAL}>
                    Partial
                  </option>
                </select>
              </div>
            </div>
          )}

          <div className="input">
            {dueAmount === 0 &&
              renderTextFields(
               sortFieldsByDisabled(renderArr),
                paymentType === PaymentType.FULL
                  ? (d) => d.showInFull
                  : (d) => d.showInPartial
              )}

            {dueAmount > 0 &&
              renderTextFields(
                sortFieldsByDisabled(dueAmountInputs),
                paymentType === PaymentType.FULL
                  ? (d) => d.showInFull
                  : (d) => d.showInPartial
              )}

            <div className="dateWrapper">
              <label htmlFor="date">Payment Date:</label>
              <input
                type="date"
                id="date"
                className="dateInput"
                value={discountObj.payment_date}
                max={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  setDiscountObj({
                    ...discountObj,
                    payment_date: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </>
      )}

      {!handleSteps && !showCautionScreen && (
        <div className="popBox">
          <div className="submitContainer">
            <FlexRow>
              <span
                className="popButton yes submit"
                onClick={() => (dueAmount ? handleYes() : handleSubmit())}
              >
                Submit
              </span>
            </FlexRow>
          </div>
        </div>
      )}
    </Main>
  );
};

export default PopUpPayment;
