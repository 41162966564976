import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";

export default function BasicExampleDataGrid({
  data = [],
  columns,
  handleOnCellClick,
  rowFunction,
  enablePaging = false,
  pathname = "/",
}) {
  const { id = 0 } = useParams();
  const navigate = useNavigate();
  const rows = data.map(rowFunction);
  const initalPageSize = 100;

  return (
    <div style={{ width: "100%" }} key={id}>
      {enablePaging ? (
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              pageSize: initalPageSize
            }
          }}
          onCellClick={(e) => {
            handleOnCellClick(e, navigate);
          }}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      ) : (
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              pageSize: initalPageSize
            }
          }}
          onCellClick={(e) => handleOnCellClick(e, navigate)}
        />
      )}
    </div>
  );
}
