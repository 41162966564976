import styled from 'styled-components';
import { dim } from '../../styles/theme';

export const StyledPlanCard = styled.div`
	font-size: ${dim._16px};
	line-height: ${dim._20px};
	font-weight: 400;
	color: ${({ isAdvancePlan, isSSPlanPage }) =>
		isSSPlanPage ? '#212f45' : !isAdvancePlan ? `#212f45` : `#fff`};
	display: flex;
	flex-direction: column;
	padding: ${dim._24px} ${dim._16px};

	background-color: ${({ isAdvancePlan, isSSPlanPage }) =>
		isSSPlanPage ? '#FFFFFF' : !isAdvancePlan ? '#FFFFFF' : '#223567'};

	background-image: url(${(props) => props.bgImage});
	background-size: cover;

	border: ${({ isAdvancePlan, isSSPlanPage }) =>
		isSSPlanPage
			? 'none'
			: !isAdvancePlan
			? ` 1px solid #e9e9e9`
			: ` 1px solid #1F4D8F`};
	border-radius: 12px;
	max-width: ${({ isITR }) => (isITR ? dim._scale(360) : dim._scale(370))};
	min-height: ${dim._scale(286)};
	box-sizing: border-box;
	box-shadow: ${({ isITR }) =>
		!isITR ? `2px 2px 32px 0px #0000001f` : 'none'};


	.planTitle {
		font-size: 22px;
		line-height: ${dim._20px};
		font-weight: 600;
		white-space: nowrap;
		margin-right: 211px;
	}
	.planInfo {
		display: flex;
		flex-direction: column;
		gap: ${dim._16px};
	}
	.planInfoForITR {
		display: flex;
		flex-direction: row;
		border-bottom: ${({ isAdvancePlan }) =>
			!isAdvancePlan ? ` 1px solid #e9e9e9` : ` 1px solid #1F4D8F`};
		align-items: center;
		justify-content: space-between;
		padding-bottom: 16px;
		.planTitle {
			font-size: 16px;
			line-height: ${dim._22px};
			font-weight: 600;
			margin: 0;
		}
	}
	.planIcon {
		width: ${dim._32px};
	}
	.priceInfo {
		display: flex;
		flex-direction: column;
		gap: ${dim._6px};
		align-items: flex-end;
	}
	.annualInfo {
		font-size: ${dim._12px};
		line-height: ${dim._16px};
	}
	.amount {
		color: ${({ active }) => (!active ? `#2769da` : `#fff`)};
		font-weight: 600;
	}
	.info {
		display: flex;
		gap: 12px;
		align-items: center;
	}
	.KnowMore {
		padding: ${dim._6px} ${dim._16px};
		font-size: ${dim._12px};
		line-height: ${dim._16px};
		font-weight: 400;
		border-radius: 40px;
		background-color: ${({ isAdvancePlan, isSSPlanPage }) =>
			isSSPlanPage ? '#212f45' : !isAdvancePlan ? `#212f45` : `#15BE53`};
		white-space: nowrap;
		height: ${dim._30px};
	}
	.planPoints {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: ${dim._16px};
		height: 100%;



		min-height: 204px;
		box-sizing: border-box;
		padding: ${dim._24px} ${dim._0px} ${dim._16px};
		padding-bottom: ${dim._32px};
		border-bottom: ${({ isAdvancePlan, isSSPlanPage }) =>
			isSSPlanPage
				? ` 1px solid #e9e9e9`
				: !isAdvancePlan
				? ` 1px solid #e9e9e9`
				: ` 1px solid #1F4D8F`};

		.standards {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			// gap: ${dim._16px};
			box-sizing: border-box;
		}

		.standards > p {
			margin: 0 0 0 16px;
			padding: 0;
			font-style: normal;
			font-weight: 400;
			font-size: ${dim._14px};
			line-height: 22px;
			color: ${({ isAdvancePlan, isSSPlanPage }) =>
				isSSPlanPage ? '#212121' : !isAdvancePlan ? `#212121` : `#fff`};
		}
		.tick {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 4px 0 0 0;
			padding: 0;
			width: 16px;
			height: 16px;
			border-radius: 100%;
			box-sizing: border-box;
		}

		.tick span {
			font-size: 28px;
			font-weight: 600;
			color: red;
			transform: rotate(45deg);
		}
		.getInvite {
			width: 83%;

			margin: 6px 32px 0 32px;
			padding: 16px 0;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 19px;
			color: #fff;
			background: #15be53;
			border: 1px solid #15be53;
			border-radius: 2px;
			cursor: pointer;
			box-sizing: border-box;
		}
		.knowMoreBtn {
			width: 100%;
		
			font-weight: 600;
			font-size: 14px;
			line-height: 19px;
			text-align: center;
			a {
				text-decoration: none;
				color: #15be53;
			}
			a:visited {
				color: #15be53;
			}
			a:hover {
				color: #15be53;
			}
			a:active {
				color: #15be53;
			}
		}
		.KnowMore {
			font-size: ${dim._12px};
			line-height: ${dim._16px};
			font-weight: 400;
			border-radius: 40px;
			background-color: #15be53;
			white-space: nowrap;
			height: ${dim._48px};
		}
	}
	.knowButton {
		padding: ${dim._16px} 0 ${dim._0px};
		font-size: ${dim._14px};
		line-height: ${dim._24px};
		font-weight: 600;
		text-align: center;
		cursor: pointer;
		color: ${({ isAdvancePlan ,isSSPlanPage }) => isSSPlanPage ? '#15BE53' : (isAdvancePlan ? `#ffff` : `#15BE53`)};
	}
	.planAmount {
		font-size: ${dim._16px};
		line-height: ${dim._20px};
		font-weight: 600;
		color: #15be53;
	}
	.eliteWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
	}
	.eliteHeader {
		font-family: Denton Test;
		font-size: 26px;
		font-weight: 420;
		line-height: 34.45px;
		letter-spacing: 0.04em;
		text-align: center;
		color: #ffd700;
	}
	.eliteIcon {
		flex-grow: 0;
		width: 92px;
	}
	.eliteSubHeader {
		font-size: 24px;
		font-weight: 400;
		line-height: 34px;
		letter-spacing: 0.04em;
		text-align: center;
		color: #ffd700;
		padding-top: 60px;
		padding-left: 25px;
		padding-right: 10px;
	}
`;
