const detailsConst = {
	CAPITAL_GAIN: {
		tableList: [
			{ head: 'Investment Type' },
			{ head: 'Sub Type' },
			{ head: 'Details' },
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Entry Price'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Selling Price'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Selling Date'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'center'
				},
				head: 'Document'
			}
		],
		typeOptions: ['LTCG', 'STCG'],
		subTypeOptions: ['EQUITY', 'DEBT']
	},
	DIGITAL_ASSET: {
		tableList: [
			// { head: 'Investment Type' },
			{ head: 'Sub Type' },
			{ head: 'Investment Date' },
			{ head: 'Selling Date' },
			{ head: 'Details' },
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Entry Price'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'is Sold'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Selling Price'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'center'
				},
				head: 'Document'
			}
		],
		typeOptions: ['Digital assets'],
		subTypeOptions: ['NFT', 'CRYPTO CURRENCY']
	},
	BUSINESS_AND_PROFESSION: {
		tableList: [
			// { head: 'Investment Type' },
			{ head: 'Sub Type' },
			{ head: 'Start Date' },
			{ head: 'End Date' },
			{ head: 'Details' },
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Gross receipt'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Turnover'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Expenses'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'center'
				},
				head: 'Document'
			}
		],
		typeOptions: ['Professional Income', 'Business'],
		subTypeOptions: ['INTRA DAY', 'BUSINESS', 'CONSULTANT']
	},
	INCOME_FROM_HOUSE_RENT: {
		tableList: [
			// { head: 'Type' },
			{ head: 'Sub Type' },
			{ head: 'Start Date' },
			{ head: 'End Date' },
			{ head: 'Details' },
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Rent'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Loan Interest'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'flex-start'
				},
				head: 'Property tax'
			},
			{
				style: {
					display: 'flex',
					justifyContent: 'center'
				},
				head: 'Document'
			}
		],
		typeOptions: ['Rental Income'],
		subTypeOptions: ['INCOME FROM HOUSE RENT']
	}
};

export const income_categories = [
	{
		category: 'Salary',
		path: 'salary',
		type: 'SALARY',
		icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary.svg',
		active_icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary_act.svg'
	},
	{
		category: 'Capital Gain',
		path: 'capital-gain',
		type: 'CAPITAL_GAIN',
		icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary.svg',
		active_icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary_act.svg'
	},
	{
		category: 'Business',
		path: 'business-and-profession',
		type: 'BUSINESS_AND_PROFESSION',
		icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary.svg',
		active_icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary_act.svg'
	},
	{
		category: 'Digital Asset',
		path: 'digital-asset',
		type: 'DIGITAL_ASSET',
		icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary.svg',
		active_icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary_act.svg'
	},
	{
		category: 'House Rent Income ',
		path: 'income-from-house-rent',
		type: 'INCOME_FROM_HOUSE_RENT',
		icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary.svg',
		active_icon:
			process.env.PUBLIC_URL +
			'/assets/SuperSaver/Income/Categories/salary_act.svg'
	}
];

const initialEarningSalaryComponent = [
	{
		label: 'BASIC SALARY',
		amount: 0,
		editStatus: false,
		isEditable: false
	},
	{
		label: 'HOUSE RENT ALLOWANCE',
		amount: 0,
		editStatus: false,
		isEditable: false
	},
	{
		label: 'VARIABLE PAY',
		amount: 0,
		editStatus: false,
		isEditable: true
	},

	{
		label: 'SPECIAL ALLOWANCE',
		amount: 0,
		editStatus: false,
		isEditable: true
	}
];

const salaryDetails = [
	{
		month: 'APRIL',
		breakdown: [
			{
				system_generated_amount: 200000.0,
				approved_salary: 200000.0,
				is_approved: true,
				is_editable: false,
				salary_slip: 's3://',
				extra_pay: 0.0,
				is_salary_slip_read: true,
				salary_component: [
					{
						credit: {
							basic: 100000.0,
							hra: 50000.0
						}
					},
					{
						debit: {
							pf: 200.0,
							tds: 100.0
						}
					}
				],
				salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
			},
			{
				system_generated_amount: 200000.0,
				approved_salary: 200000.0,
				is_approved: true,
				is_editable: false,
				salary_slip: 's3://',
				extra_pay: 0.0,
				is_salary_slip_read: true,
				salary_component: [
					{
						credit: {
							basic: 100000.0,
							hra: 50000.0
						}
					},
					{
						debit: {
							pf: 200.0,
							tds: 100.0
						}
					}
				]
			}
		]
	},
	{
		month: 'MAY',
		breakdown: [
			{
				system_generated_amount: 200000.0,
				approved_salary: 200000.0,
				is_approved: false,
				is_editable: false,
				salary_slip: 's3://',
				extra_pay: 0.0,
				is_salary_slip_read: true,
				salary_component: [
					{
						credit: {
							basic: 100000.0,
							hra: 50000.0
						}
					},
					{
						debit: {
							pf: 200.0,
							tds: 100.0
						}
					}
				],
				salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
			},
			{
				system_generated_amount: 200000.0,
				approved_salary: 200000.0,
				is_approved: false,
				is_editable: false,
				salary_slip: 's3://',
				extra_pay: 0.0,
				is_salary_slip_read: true,
				salary_component: [
					{
						credit: {
							basic: 100000.0,
							hra: 50000.0
						}
					},
					{
						debit: {
							pf: 200.0,
							tds: 100.0
						}
					}
				]
			}
		]
	},
	{
		month: 'June',
		breakdown: [
			{
				system_generated_amount: 200000.0,
				approved_salary: 200000.0,
				is_approved: true,
				is_editable: false,
				salary_slip: 's3://',
				extra_pay: 0.0,
				is_salary_slip_read: true,
				salary_component: [
					{
						credit: {
							basic: 100000.0,
							hra: 50000.0
						}
					},
					{
						debit: {
							pf: 200.0,
							tds: 100.0
						}
					}
				],
				salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
			},
			{
				system_generated_amount: 200000.0,
				approved_salary: 200000.0,
				is_approved: false,
				is_editable: false,
				salary_slip: 's3://',
				extra_pay: 0.0,
				is_salary_slip_read: true,
				salary_component: [
					{
						credit: {
							basic: 100000.0,
							hra: 50000.0
						}
					},
					{
						debit: {
							pf: 200.0,
							tds: 100.0
						}
					}
				]
			}
		]
	},
	{
		month: 'July',
		breakdown: [
			{
				system_generated_amount: 200000.0,
				approved_salary: 200000.0,
				is_approved: true,
				is_editable: false,
				salary_slip: 's3://',
				extra_pay: 0.0,
				is_salary_slip_read: true,
				salary_component: [
					{
						credit: {
							basic: 100000.0,
							hra: 50000.0
						}
					},
					{
						debit: {
							pf: 200.0,
							tds: 100.0
						}
					}
				],
				salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
			},
			{
				system_generated_amount: 200000.0,
				approved_salary: 200000.0,
				is_approved: true,
				is_editable: false,
				salary_slip: 's3://',
				extra_pay: 0.0,
				is_salary_slip_read: true,
				salary_component: [
					{
						credit: {
							basic: 100000.0,
							hra: 50000.0
						}
					},
					{
						debit: {
							pf: 200.0,
							tds: 100.0
						}
					}
				]
			}
		]
	},
	{
		month: 'August',
		breakdown: []
	},
	{
		month: 'September',
		breakdown: []
	},
	{
		month: 'October',
		breakdown: []
	},
	{
		month: 'November',
		breakdown: []
	},
	{
		month: 'December',
		breakdown: []
	},
	{
		month: 'January',
		breakdown: []
	},
	{
		month: 'February',
		breakdown: []
	},
	{
		month: 'March',
		breakdown: []
	}
];

export const allIncomeData = {
	user_id: 'a426f2d9-5ae0-4100-a269-7edf90a96233',
	financial_year_id: 'fhhfgffhhf',
	type: 'SALARY',
	income_details: {
		salary_income_dto_list: [
			{
				company_details: {
					name: 'HCLTech',
					tan_number: 'BFAPG3991Q',
					location: 'Bangalore',
					logo: 'image'
				},
				is_current_company: true,
				financial_year_id: 'fhhfgffhhf',
				start_end_date_fy: {
					start_date: 1659334364000,
					end_date: 1701757891000
				},
				compensation_details: {
					ctc: 1000000.0,
					fix_ctc: 800000.0,
					bonus: 100000.0,
					ctc_component: [
						{
							label: 'basic',
							is_taxable: true,
							amount: 1000000.0
						}
					]
				}
			},
			{
				company_details: {
					name: 'Prosperr.io private Limited ',
					tan_number: 'BFAPG3991Q',
					location: 'Bangalore',
					logo: 'image'
				},
				is_current_company: true,
				financial_year_id: 'fhhfgffhhf',
				start_end_date_fy: {
					start_date: 1648793564000,
					end_date: 1659334364000
				},
				compensation_details: {
					ctc: 1000000.0,
					fix_ctc: 800000.0,
					bonus: 100000.0,
					ctc_component: [
						{
							label: 'basic',
							is_taxable: true,
							amount: 1000000.0
						}
					]
				}
			}
		],
		monthly_breakdown: {
			APRIL: [
				{
					system_generated_amount: 300000.0,
					approved_salary: 400000.0,
					is_approved: true,
					is_editable: false,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: true,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 200000.0,
					approved_salary: 500000.0,
					is_approved: true,
					is_editable: false,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: true,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			MAY: [
				{
					system_generated_amount: 700000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: '',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 900000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: '',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			JUNE: [
				{
					system_generated_amount: 600000.0,
					approved_salary: 400000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: '',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 200000.0,
					approved_salary: 200000.0,
					is_approved: true,
					is_editable: true,
					salary_slip: '',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			JULY: [
				{
					system_generated_amount: 200000.0,
					approved_salary: 200000.0,
					is_approved: true,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 200000.0,
					approved_salary: 900000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			AUGUST: [
				{
					system_generated_amount: 200000.0,
					approved_salary: 200000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 200000.0,
					approved_salary: 200000.0,
					is_approved: true,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			SEPTEMBER: [
				{
					system_generated_amount: 200000.0,
					approved_salary: 200000.0,
					is_approved: true,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 200000.0,
					approved_salary: 200000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			OCTOBER: [
				{
					system_generated_amount: 200000.0,
					approved_salary: 200000.0,
					is_approved: true,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 600000.0,
					approved_salary: 200000.0,
					is_approved: true,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			NOVEMBER: [
				{
					system_generated_amount: 700000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: '',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 900000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: '',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			DECEMBER: [
				{
					system_generated_amount: 700000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: '',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 900000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: '',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			JANUARY: [
				{
					system_generated_amount: 200000.0,
					approved_salary: 200000.0,
					is_approved: true,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 200000.0,
					approved_salary: 900000.0,
					is_approved: false,
					is_editable: true,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: false,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			FEBRUARY: [
				{
					system_generated_amount: 300000.0,
					approved_salary: 400000.0,
					is_approved: true,
					is_editable: false,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: true,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 200000.0,
					approved_salary: 500000.0,
					is_approved: true,
					is_editable: false,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: true,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			],
			MARCH: [
				{
					system_generated_amount: 300000.0,
					approved_salary: 400000.0,
					is_approved: true,
					is_editable: false,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: true,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					],
					salary_income_id: '0e437767-5bc0-4e63-9fd1-7f8cefe50722'
				},
				{
					system_generated_amount: 200000.0,
					approved_salary: 500000.0,
					is_approved: true,
					is_editable: false,
					salary_slip: 's3://',
					extra_pay: 0.0,
					is_salary_slip_read: true,
					salary_component: [
						{
							credit: {
								basic: 100000.0,
								hra: 50000.0
							}
						},
						{
							debit: {
								pf: 200.0,
								tds: 100.0
							}
						}
					]
				}
			]
		}
	}
};

export const capitalGainData = {
	user_id: 'a426f2d9-5ae0-4100-a269-7edf90a96233',
	financial_year_id: 'abc',
	type: 'CAPITAL_GAIN',
	income_details: [
		{
			external_id: 'c8536ec1-7b67-455f-af93-2ef5107114c2',
			label: ' ICICI MF Scheme ICICI MF Scheme ICICI MF Scheme',
			sub_type: 'LTCG_EQUITY',
			amount_invested: 100000.0,
			investment_date: 1619270155000,
			is_sold: true,
			selling_date: 1658668555000,
			selling_amount: 300000.0,
			platform: 'Zerodha'
		},
		{
			external_id: '4dd4d930-d1bc-44b8-b123-28a46c27ad25',
			label: 'ICICI MF debt Scheme',
			sub_type: 'LTCG_DEBT',
			amount_invested: 100000.0,
			investment_date: 1619270155000,
			is_sold: true,
			selling_date: 1658668555000,
			selling_amount: 200000.0,
			platform: 'Zerodha'
		},
		{
			external_id: '4075d3f0-b962-4841-bbff-bf31e46a7053',
			label: 'ICICI MF Scheme2',
			sub_type: 'LTCG_EQUITY',
			amount_invested: 100000.0,
			investment_date: 1619270155000,
			is_sold: true,
			selling_date: 1658668555000,
			selling_amount: 200000.0,
			platform: 'Zerodha'
		},
		{
			external_id: 'f6e3442d-8e40-4b7d-bed0-42ecc0dd4361',
			label: 'ICICI MF debt Scheme2',
			sub_type: 'LTCG_DEBT',
			amount_invested: 100000.0,
			investment_date: 1619270155000,
			is_sold: true,
			selling_date: 1658668555000,
			selling_amount: 200000.0,
			platform: 'Zerodha'
		},
		{
			external_id: 'bfdf4590-f100-46c6-9e07-7eb0cc875f5f',
			label: 'HDFC MF debt Scheme',
			sub_type: 'LTCG_DEBT',
			amount_invested: 100000.0,
			investment_date: 1619270155000,
			is_sold: true,
			selling_date: 1661346955000,
			selling_amount: 200000.0,
			platform: 'ZORODHRA'
		},
		{
			external_id: '2b096d51-fe5a-4db6-a8a8-3c9772ba5f5b',
			label: 'HDFC MF debt Scheme2',
			sub_type: 'LTCG_DEBT',
			amount_invested: 100000.0,
			investment_date: 1619270155000,
			is_sold: true,
			selling_date: 1661346955000,
			selling_amount: 200000.0,
			platform: 'Grow'
		}
	]
};
const initialDeductionSalaryComponent = [
	{
		label: 'PROVIDENT FUND',
		amount: 0,
		editStatus: false,
		isEditable: false
	},
	{
		label: 'TOTAL INCOME TAX',
		amount: 0,
		editStatus: false,
		isEditable: false
	}
];

export {
	detailsConst,
	initialEarningSalaryComponent,
	initialDeductionSalaryComponent
};
