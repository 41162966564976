



const CompleteCheck = ({color}) => {
  return (

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.1681 15.8912L10.1844 15.9057L10.2015 15.9192C10.7909 16.386 11.6483 16.3476 12.1926 15.8033L17.4959 10.5L16.4353 9.43934L11.1329 14.7418L11.1167 14.7274L7.95 11.5607L6.88934 12.6213L10.0713 15.8033L10.0866 15.8186L10.1027 15.833L10.1681 15.8912ZM12 0.75C18.2132 0.75 23.25 5.7868 23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75Z" stroke={color||"white"} stroke-width="1.5"/>
</svg>
  )
}

export default CompleteCheck