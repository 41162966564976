import styled from "styled-components";
import { dim } from "../../../../../styles/theme";

export const StyledDesktopPreview = styled.div`
  height: 100%;
  overflow: scroll;
  .box {
    height: 80%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .container{
    width:100%;  
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${dim._32px};
    box-sizing: border-box;
    width: 100%;
    .title-preview {
      font-size: ${dim._48px};
      font-weight: 800;
      color: #333;
      margin-bottom: ${dim._26px};
      line-height: ${dim._60px};
      width: 99%;
      overflow-wrap: break-word;
      text-overflow: clip;
    }
  }

  .articleInfoShare,
  .shareWrapper,
  .logo {
    display: flex;
    align-items: center;
  }
  .articleInfoShare {
    gap: 30px;
    padding-bottom: ${dim._46px};
  }

  .logo {
    gap: 8px;
    align-items: center;
  }

  .publishedWrapper {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    gap: 12px;
  }

  .icons {
    display: flex;
    gap: 32px;
  }

  .publishDate {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .shareWrapper {
    gap: 15px;
  }

  .shareTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .shareIcons {
    width: 20px;
  }

  .tableWrapper {
    width: 20%;
    padding-right: ${dim._60px};
    padding-bottom: ${dim._scale(200)};
    box-sizing: border-box;
    height: fit-content;
    position: sticky;
    top: 30px;
  }

  .second-container {
    width: 100%;
  }

  .tableOfContentWrapper {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #ebebf0;

    font-size: ${dim._14px};
    line-height: ${dim._24px};
    font-weight: 400;
    gap: ${dim._10px};
    color: #425466;
    .Active {
      border-left: 3px solid #223567;
    }
    .tableOfContentText {
      padding-left: ${dim._10px};
    }
  }

  .hero-section {
    position: relative;
    overflow: hidden;
    width: 100%;
    img {
      width: 100%;
      aspect-ratio: 10/3;
      display: block;
      object-fit: cover;
      border-radius: ${dim._8px};
    }
  }

  .summary-wrapper {
    margin: ${dim._24px} 0;
    margin-top: ${dim._54px};
    padding: ${dim._16px};
    background-color: #f3f3f3;
    border-radius: ${dim._8px};

    .summary-title {
      font-size: ${dim._16px};
      padding-bottom: ${dim._12px};
      font-weight: 600;
      color: #333;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: ${dim._20px};
    }
  }

  .body-wrapper {
    display: flex;
    margin-top: ${dim._20px};
    position: relative;
    width: 100%;
    white-space: pre-wrap;
    span,
    p,
    pre {
      padding: ${dim._0px};
      margin:${dim._0px};
      display: inline-block;
      width: 100%;
      white-space: pre-wrap;
    }
    .table-of-contents {
      position: absolute;
      word-wrap: break-word;
      width: ${dim._scale(200)};
      top: ${dim._scale(10)};
      left: ${dim._scale(1080)};
      background-color: #fff;
      box-shadow: 0 0 ${dim._16px} rgba(0, 0, 0, 0.1);
      padding: ${dim._20px};
      border-radius: ${dim._8px};
      transition: right 0.3s ease-in-out;
      .table-of-content-title {
        font-size: ${dim._16px};
        margin-bottom: ${dim._12px};
        color: #333;
        cursor: pointer;
      }

      ul {
        list-style-type: none;
        padding: ${dim._0px};
        white-space: pre-wrap;
        li {
          margin-bottom: ${dim._8px};

          a {
            font-size: ${dim._14px};
            color: #555;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              color: #007bff;
            }
          }
        }
      }
    }
  }

  .body-content {
    display: inline-block;
    width: 100%;
    white-space: pre-wrap;
    width: 100%;
    overflow-wrap: break-word;
    text-overflow: clip;
    white-space: pre-wrap;
  }

  .tags-wrapper {
    margin-top: ${dim._24px};
    display: flex;
    gap: ${dim._10px};
    width: 100%;
    flex-wrap: wrap;
    .tag-name {
      white-space: pre-wrap;
      background-color: #f1f3f6;
      border-radius: ${dim._4px};
      padding: ${dim._6px} ${dim._16px};
      color: #2e4254;
      font-weight: 500;
      margin-right: ${dim._8px};
      font-size: ${dim._12px};
      line-height: ${dim._20px};
      white-space: nowrap;
    }
  }

  .tag-name {
    background-color: #f1f3f6;
    border-radius: ${dim._4px};
    padding: ${dim._6px} ${dim._16px};
    color: #2e4254;
    font-weight: 500;
    margin-right: ${dim._8px};
    font-size: ${dim._12px};
    line-height: ${dim._20px};
    white-space: nowrap;
  }
`;
