import styled from "styled-components";
import {theme} from "../../../styles/theme";

export const AdvisorCardWrapper = styled.div`
  width: 200px;
  height: 130px;
  padding: 10px;
  background-color: ${({haveUnread, active}) =>
    active
      ? 
        theme.color.greyBlue_20
      : haveUnread
      ? theme.color.red_100
      : theme.color.white_0};
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid
    ${({haveUnread, active}) =>
      active
        ? theme.color.blue_40
        : haveUnread
        ? theme.color.red_0
        : theme.color.white_0};

  .adv-image {
    height: 40px !important;
    width: 40px !important;
    border-radius: 25px;
  }
  .advisorDetails {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
  }
  .advisorDetailsWrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  h2 {
    color: #364152;
  }
  .adv-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 17.67px;
    text-align: left;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px;
    padding-bottom: 4px;
    border-bottom: 1px solid #cacaca;
  }

  .contents {
    display: flex;
    padding-top: 2px;
    gap: 8px;
    align-items: baseline;

  }
  .labelText {
    font-size: 12px;
    font-weight: 400;
    line-height: 12.97px;
    text-align: left;
  }
  .unread-session {
    font-size: 24px;
    margin: 0px;
  }

  .unread-message {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 12.97px;
    text-align: left;
    align-items: baseline;
  }
  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    font-size: 15.93px;
    font-weight: 400;
    line-height: 18.57px;
    text-align: left;
  }

  .check-mark {
    bottom: 16px;
    right: 24px;
    color: ${({active}) => (active ? theme.color.blue_20 : "transparent")};
  }

  .action-icons {
    bottom: 24px;
    right: 24px;
    display: flex;
    column-gap: 8px;
  }
  .underline {
    color: inherit !important;
    text-decoration: underline;
    font-size: 16px;
  }
`;
