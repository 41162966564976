import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { USER_TYPE } from "../../utils/constant";
import TaskCompleted from "../TaskCompleted";
import { actions } from "../../container/ConsultationExperience/store/ConsultationAction";
import ConfiramtionPopup from "../../container/ConsultationExperience/components/ConfirmationPopup";
import Backdrop from "../../components/Backdrop";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { StyledActionableStatus } from "./ActionableStatus.styled";

const ActionableStatus = (props) => {
  const [searchParams] = useSearchParams();
  const call_summary_id =
    localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID) === "undefined"
      ? null
      : localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID);
  const dispatch = useDispatch();
  const { status, dependent, title, action_item_id, getConsultationMom } =
    props;
  const [popoverStatus, setPopoverStatus] = useState(false);

  const confirmationContent = {
    title: "Have you Completed the Action?",
    context: title,
    falseText: "No",
    trueText: "Yes",
  };
  const handlePopover = () => {
    setPopoverStatus((state) => !state);
  };
  const handleSubmit = () => {
    //logic for submit
    const user_id = searchParams.get("user_id");
    const data = {
      action_item_id,
      call_summary_id,
      status: "COMPLETED",
      updated_by: "ADVISOR",
      userId: user_id,
      getConsultationMom: getConsultationMom(),
    };
    dispatch({
      type: actions.HANDLE_ACTIONABLE_STATUS,
      data,
    });
    setPopoverStatus((state) => !state);
  };
  return (
    <StyledActionableStatus>
      <TaskCompleted
        onClick={status !== "COMPLETED" ? handlePopover : () => {}}
        style={
          status === "COMPLETED"
            ? { color: "#15BE53" }
            : {
                color: "#F5821E",
                textDecoration: "underline",
                cursor: "pointer",
              }
        }
        name={status === "PENDING" ? "Mark as complete" : status}
        icon={
          status !== "COMPLETED"
            ? process.env.PUBLIC_URL + "/assets/icons/pendingIcon.svg"
            : process.env.PUBLIC_URL + "/assets/icons/completed.svg"
        }
      />
      {popoverStatus && (
        <Backdrop toggleBackdrop={handlePopover}>
          {
            <ConfiramtionPopup
              handleSubmit={handleSubmit}
              handleCancelBtn={handlePopover}
              handleNoBtn={handlePopover}
              confirmationContent={confirmationContent}
            />
          }
        </Backdrop>
      )}
    </StyledActionableStatus>
  );
};

export { ActionableStatus };
