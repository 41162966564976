import React, { useState } from "react";
import { Modal, Button, Dropdown, Space, Input } from "antd";
import {
  SessionCloseModalStyle,
  SubmitButton,
} from "./SessionCloseModal.styled";
import SuperSaverDiscussionBookService from "../../../../Services/SuperSaverClientChat";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const { TextArea } = Input;

const SessionCloseModal = ({
  advisorId,
  isModalOpen,
  cancelModal,
  selectedChat,
  showLoading,
  hideLoading,
}) => {
  const [mom, setMom] = useState("");
  const [feedback, setFeedback] = useState("");
  const sessions = useSelector((state) => state.chat.session);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const purpose = sessions[selectedChat];

  const closeSession = async () => {
    showLoading();
    try {
      const res = await SuperSaverDiscussionBookService.closeSession(
        sessionId,
        {
          closed_by: advisorId,
          feedback: {
            feedback: feedback,
            mom: mom,
          },
          query_type: purpose.query_type,
          subject: purpose.subject,
          user_id: purpose.user_id,
        }
      );
      cancelModal();
      setMom("");
      setFeedback("");
    } catch (error) {
      toast.error("Error closing session:", error);
    } finally {
      hideLoading();
    }
  };

  return (
    <Modal
      visible={isModalOpen}
      footer={null}
      width={430}
      centered
      onCancel={() => {
        cancelModal();
        setMom("");
        setFeedback("");
      }}
    >
      <SessionCloseModalStyle>
        <h2>Closing this Session?</h2>
        <TextArea
          rows={3}
          id="subject"
          name="subject"
          placeholder={"Enter MOM"}
          value={mom}
          onChange={(e) => setMom(e.target.value)}
          maxLength="500"
          style={{
            padding: "12px",
            resize: "none",
            borderRadius: "12px",
          }}
        />
        <p className="char_limit">{`${mom?.length}/500`}</p>
        <TextArea
          rows={3}
          id="subject"
          name="subject"
          placeholder={"Enter Feedback"}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          maxLength="500"
          style={{
            padding: "12px",
            resize: "none",
            borderRadius: "12px",
          }}
        />
        <p className="char_limit">{`${feedback?.length}/500`}</p>
        <SubmitButton
          disabled={mom?.trim()?.length === 0 || feedback?.length === 0}
          style={{ width: "100%" }}
          type="primary"
          onClick={closeSession}
        >
          Close Session
        </SubmitButton>
      </SessionCloseModalStyle>
    </Modal>
  );
};

export default SessionCloseModal;
