import React from 'react';

import {
	StyledRightSideSlider,
	StyledRightSideSliderChild
} from './RightSideSlider.styled';
import { AiFillCloseCircle } from 'react-icons/ai';
import { dim } from '../../styles/theme';
const RightSideSlider = ({
	showDis,
	handleHelpShow,
	children,
	newVersion = false,
	width = dim._scale(600)
}) => {
	const handleClose = (e) => {
		e.stopPropagation();
		handleHelpShow(false);
	};
	return (
		<StyledRightSideSlider isOpen={showDis}>
			{showDis && (
				<div
					className='back-drop'
					onClick={(e) => {
						handleClose(e);
					}}
				></div>
			)}
			{newVersion ? (
				<StyledRightSideSliderChild
					className='uploadModel infoContainer'
					isOpen={showDis}
					width={width}
				>
					<AiFillCloseCircle
						className='close-btn'
						onClick={(e) => {
							handleClose(e);
						}}
					/>
					{children}
				</StyledRightSideSliderChild>
			) : (
				children
			)}
		</StyledRightSideSlider>
	);
};
export default RightSideSlider;
