import styled from "styled-components";

export const SessionDocumentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  border-radius: 4px;
  overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  overscroll-behavior: unset !important;
  scroll-behavior: unset !important;

  margin: 16px;
  margin-left: 0;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 48px;
    gap: 10px;
    margin: 0;
    padding: 0 16px;
    background: #e5e5e5;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
  }

  .head p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #212f45;
  }

  .rows {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: auto;
    margin: 0 0 2px 0;
    padding: 0 16px;
    background: #ffffff;
    border-radius: 4px;
    border-bottom: 1px solid #e5e5e5;
    box-sizing: border-box;
    position: relative;
  }

  .rows p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #425466;
  }

  .c1 {
    width: 33.33%;
  }
  .c2 {
    width: 33.33%;
  }

  .c3 {
    width: 33.33%;
    text-align: center;
  }

  .c4 {
    width: auto;
  }

  .r4 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .dltbtn {
    position: absolute;
    cursor: pointer;
    right: 30px;
  }
`;
