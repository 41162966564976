import styled from "styled-components";

export const RequestDocPreviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 74px;
  /* padding: 16px;
  border-radius: 8px;
  background: #f5f4ef; */
  /* border: 1px solid; */
  box-sizing: border-box;

  .preview_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    margin: 0 16px 0 0;
    border-radius: 8px;
    background: #f5f4ef;
    box-sizing: border-box;
  }

  h2 {
    width: 30%;
    color: #323232;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    width: 70%;
    color: #323232;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
