import { ITRDocumentsAction } from "./action";

const initialState = {
  requestPopupStatus: {
    active: false,
  },
  rejectPopupStatus: {
    active: false,
    document_id: null,
  },
  confirmPopupStatus: {
    active: false,
    data: [],
  },
};

export const ITRDocumentsReducer = (state = initialState, payload = {}) => {
  switch (payload.type) {
    case ITRDocumentsAction.REQUEST_POPUP_STATUS:
      return {
        ...state,
        requestPopupStatus: payload.data,
      };
    case ITRDocumentsAction.REJECT_POPUP_STATUS:
      return {
        ...state,
        rejectPopupStatus: payload.data,
      };
    case ITRDocumentsAction.CONFIRMATION_POPUP_STATUS:
      return {
        ...state,
        confirmPopupStatus: payload.data,
      };
    default:
      return state;
  }
};
