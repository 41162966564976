const getMsDifference = (date) => new Date() - new Date(date);

const getHrDiff = (date) => Math.floor(getMsDifference(date) / 1000 / 60 / 60);
const getMinDiff = (date) =>
  Math.floor((getMsDifference(date) / 1000 / 60 / 60) * 60);

const getHoursDifference = (date) =>
  Math.floor(getMsDifference(date) / 1000 / 60 / 60) >= 2
    ? `${Math.floor(getMsDifference(date) / 1000 / 60 / 60)} hours`
    : "An hour";
const getMinDifference = (date) => {
  return Math.floor((getMsDifference(date) / 1000 / 60 / 60) * 60) >= 2
    ? `${Math.floor((getMsDifference(date) / 1000 / 60 / 60) * 60)} minutes`
    : "A minute";
};

const getDaysDifference = (date) =>
  Math.floor(getHrDiff(date) / 24) >= 2
    ? `${Math.floor(getHrDiff(date) / 24)} days`
    : "A day";

const getDifference = (date) =>
  getHrDiff(date) >= 24
    ? getDaysDifference(date)
    : getMinDiff(date) >= 60
    ? getHoursDifference(date)
    : getMinDifference(date);

export { getDifference ,getHrDiff,getMsDifference};
