import styled from "styled-components";
import {StyledFlexWrapper} from "./components/ActionWidget/ActionWidget.styled";
import {dim, theme} from "../../styles/theme";

export const StyledActionDashboard = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledCallParentWrapper = styled(StyledFlexWrapper)`
flex-direction: column;
gap: ${dim._20px};
border-radius: ${dim._8px};
padding-bottom: ${dim._24px};
background: ${theme.color.white_0};
height: 100%;
width: 100%;
align-items: flex-end;
box-sizing: border-box;

.viewMoreBtn {
  width: fit-content,
  text-decoration: underline,
  padding-right: ${dim._24px},
}
`;

export const StyledCallWrapper = styled(StyledFlexWrapper)`
  flex-direction: column;
  gap: ${dim._20px};
  background: ${theme.color.white_0};
  padding: ${dim._24px};
  height: 90%;
  overflow: scroll;
  align-items: flex-end;
  width: 100%;
  box-sizing: border-box;
  .NoDataAvailable {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledTitleWrapper = styled.div`
  .title {
    font-size: ${dim._16px};
    font-weight: 600;
    line-height: ${dim._20px};
    text-align: left;
    color: #202224;
    padding-bottom: ${dim._16px};
  }
`;
