import React, { useEffect, useState } from "react";
import {
  MOMDtails,
  MomDetailsStyle,
  StyledConsultationExperienceForm,
  TaxPlanningTitleStyle,
} from "./ConsultationExperienceForm.styled";
import BasicCallDetailsForm from "../../Models/BasicCallDetailsForm";
import CallActionForm from "../../Models/CallActionForm";
import CallSummary from "../../Models/CallSummary";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { advisorData } from "../../../../Services/SuperSaverClientChat";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import { actions } from "../../store/ConsultationAction";
import { dateFormatter } from "../../../../utils/timeUtil";

const ConsultationExperienceForm = ({ addForm = () => {} }) => {
  const [loader, setLoader] = useState(false);
  const advisorId = useSelector((state) => state.chat.advisorId);
  const navigate = useNavigate();
  const advisor = advisorData();
  const [callSummary, setCallSummary] = useState();
  const xyz = useSelector((state) => state.ConsultationReducer.momData);
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");
  const callSummaryId = useSelector(
    (state) => state.ConsultationReducer.consultationId
  );
  const call_summary_id =
    callSummaryId || localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID);
  const call_booking_id = searchParams.get("call_booking_id");
  const callTitle = searchParams.get("call_title");
  const start_time = searchParams.get("start_time");
  const userName = searchParams.get("user_name");
  const dispatch = useDispatch();
  const getConsultationMom = async () => {
    call_summary_id !== "undefined"
      ? dispatch({
          type: actions.GET_MOM_DATA,
          data: {
            call_summary_id,
          },
        })
      : dispatch({
          type: actions.SET_MOM_DATA,
          data: null,
        });
  };
  useEffect(() => {
    getConsultationMom(call_summary_id);
  }, []);
  useEffect(() => {
    call_summary_id !== "undefined"
      ? setCallSummary({ ...xyz })
      : setCallSummary();
  }, [xyz, call_summary_id]);

  const id = advisorId ? advisorId : advisor.user_roll_id;
  return (
    <StyledConsultationExperienceForm id={call_booking_id}>
      <MomDetailsStyle>
        <MOMDtails>MOM Details</MOMDtails>
        <TaxPlanningTitleStyle>
          {callTitle}| {userName} | {dateFormatter(start_time)}
        </TaxPlanningTitleStyle>
      </MomDetailsStyle>
      <BasicCallDetailsForm
        callSummary={callSummary}
        callTitle={callTitle}
        addForm={addForm}
        call_booking_id={call_booking_id}
        id={id}
        loader={loader}
        setLoader={setLoader}
      />
      <CallActionForm
        callSummary={callSummary}
        call_booking_id={call_booking_id}
        id={id}
        getConsultationMom={getConsultationMom}
        loader={loader}
        setLoader={setLoader}
      />
      <CallSummary
        callSummary={callSummary}
        call_booking_id={call_booking_id}
        id={id}
        loader={loader}
        setLoader={setLoader}
      />
    </StyledConsultationExperienceForm>
  );
};

export default ConsultationExperienceForm;
