import { getAuth } from "firebase/auth";
import { LOCAL_DB_KEYS } from "../constants/localStorage";
import BASE_URL from "./API_ENDPOINT";

// const EXPIRATION_TIME_DURATION = 56 * 60 * 1000;

const ADVISOR_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const advisorData = () => {
  return JSON.parse(localStorage.getItem(LOCAL_DB_KEYS.USER));
};

export const storageConst = {
  TOKEN_TIMESTAMP: "TOKEN_TIMESTAMP",
  AUTH_TOKEN: "AUTH_TOKEN",
};

const getAuthUser = () => {
  return new Promise((resolve, reject) => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject("Auth user not found!");
      }
    });
  });
};

export const getAuthToken = async () => {
  const user = await getAuthUser();
  const token = await user.getIdToken();
  localStorage.setItem(storageConst.TOKEN_TIMESTAMP, new Date().getTime());
  localStorage.setItem(storageConst.AUTH_TOKEN, token);
  return token;
};

export const getHeaders = async (headers = {}) => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: await getAuthToken(),
    Source: "cms",
    ...headers,
  };
};

const SuperSaverDiscussionBookService = {
  markMessageAsRead: async (data) => {
    const url = `${BASE_URL}discussion-message/v1/update-notification?requesterId=${data.requestId}&sessionId=${data.sessionId}&isRead=${data.isRead}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: await getHeaders({ userId: data.userId }),
    });
    return response;
  },
  getAdvisorsActiveChats: async (advisorId) => {
    try {
      const url = `${BASE_URL}discussion-message/v1/unread-chat-sessions?advisorId=${advisorId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: await getHeaders(),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.log(error.message);
    }
  },
  getAdvisorsActiveChatStatus: async (advisorId, lastMessageTimestamp) => {
    try {
      const url = `${BASE_URL}discussion-message/v1/${advisorId}/${lastMessageTimestamp}/last-chat-activity/internal`;
      const response = await fetch(url, {
        method: "GET",
        headers: await getHeaders(),
      });
      const res = await response.json();
      const obj = { is_last_activity: true };
      return res;
    } catch (error) {
      console.log(error.message);
    }
  },
  getAdvisorsActiveChatsNew: async (
    advisorId,
    selectedUserType = ADVISOR_TYPE.PRIMARY,
    unreadMsgFilter
  ) => {
    const baseUrl = unreadMsgFilter
      ? `${BASE_URL}discussion-message/v1/${advisorId}/assigned-users/internal?advisor_type=${selectedUserType}&filter_type=UNREAD_MESSAGE`
      : `${BASE_URL}discussion-message/v1/${advisorId}/assigned-users/internal?advisor_type=${selectedUserType}`;
    try {
      const url = baseUrl;
      const response = await fetch(url, {
        method: "GET",
        headers: await getHeaders(),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.log(error.message);
    }
  },
  getMessages: async (data) => {
    const url = `${BASE_URL}discussion-message/v1/all-chats/internal?userId=${data.receiverId}&sessionId=${data.sessionId}&attachment=&receiverId=${data.receiverId}&requesterId=${data.receiverId}`;
    const response = await fetch(url, {
      method: "GET",
      headers: await getHeaders(),
    });
    const res = await response.json();
    return res;
  },
  getSessionNotification: async (data) => {
    const url = `${BASE_URL}discussion-message/v1/notification?userId=${data.userId}&requesterId=${data.receiverId}`;
    const response = await fetch(url, {
      method: "GET",
      headers: await getHeaders({ userId: data.userId }),
    });
    const res = await response.json();
    return res;
  },
  getSession: async (user_id) => {
    const url = `${BASE_URL}discussion-message/v1/chat-sessions/internal?userId=${user_id}&requesterId=${user_id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: await getHeaders(),
    });
    const data = await response.json();
    return data;
  },
  postMessages: async (data) => {
    const url = `${BASE_URL}discussion-message/v1/internal?senderId=${data.sender_id}`;
    const response = await fetch(url, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  },
  postSession: async (adv, req) => {
    const url = `${BASE_URL}discussion-message/v1/create-session/internal?initiatedBy=${adv}`;
    const response = await fetch(url, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify(req),
    });
    const res = await response.json();
    return res;
  },
  closeSession: async (sessionId, data) => {
    const url = `${BASE_URL}discussion-message/v1/session/internal?sessionId=${sessionId}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: await getHeaders(),
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  },
};

export default SuperSaverDiscussionBookService;
