const CONSULTATION_STATUS = {
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  SCHEDULED: "SCHEDULED",
  NOT_ATTENDED: "NOT_COMPLETED",
  NOT_COMPLETED: "NOT_COMPLETED",
  NOT_STARTED: "NOT_STARTED",
  PENDING: "PENDING",
  SUBMITTED: "SUBMITTED",
};
const ERROR_MESSAGE = {
  is_correct_call: "Please confirm the call agenda",
  call_agenda: "Please select call agenda",
  is_extension_of_old_call: "Please confirm Call extension",
  discussed_keywords: "Please select discussed keywords",
  dependency_on: "Please select dependency",
  is_need_to_upload_document: "Please select document requirement",
  is_next_call_required: "Please select Next call requirement",
  document_list: "Please upload document carefully",
  document: "Please upload document carefully",
  comments: "Please add comment carefully",
  client_satisfaction_rate: "Please rate the client satisfaction",
  action_category: "Please select category",
  document_name: "please add document",
  document_type: "please specify document type",
  action_type: "please Select action type",
  text: "please also add content",
  userActions: "please add client actions",
  advisorActions: "please add Advisor actions",
  company_id: "please select Company  ",
};

const THEME_DISCUSSED = [
  { value: "TAX_PLANNING", label: "Tax Planning", isSelected: false },
  {
    value: "DECLARATION_DISCUSSION",
    label: "Declaration Discussion",
    isSelected: false,
  },
  { value: "HRA", label: "HRA", isSelected: false },
  { value: "ALLOWANCES", label: "Allowances", isSelected: false },
  { value: "CAPITAL_GAIN", label: "Capital Gain", isSelected: false },
  { value: "CONSULTANT_INCOME", label: "Consultant Income", isSelected: false },
  { value: "HOME_LOAN", label: "Home Loan", isSelected: false },
  { value: "LTA", label: "LTA", isSelected: false },
  { value: "MEDICAL_INSURANCE", label: "Medical Insurance", isSelected: false },
  { value: "DISABLE_DEPENDENT", label: "Disable Dependent", isSelected: false },
  {
    value: "SENIOR_CITIZEN_PARENT",
    label: "Senior Citizen Parent",
    isSelected: false,
  },
  {
    value: "SENIOR_CITIZEN_SELF",
    label: "Senior Citizen Self",
    isSelected: false,
  },
  { value: "TAX_SAVING", label: "Tax Saving", isSelected: false },
  {
    value: "SALARY_RESTRUCTURING",
    label: "Salary Restructuring",
    isSelected: false,
  },
  { value: "RENTAL_INCOME", label: "Rental Income", isSelected: false },
  { value: "HRMS_TOOL", label: "HRMS Tool", isSelected: false },
  { value: "ESOP", label: "ESOP", isSelected: false },
  { value: "FBP_B2C", label: "FBP B2C", isSelected: false },
  { value: "FBP_B2B", label: "FBP B2B", isSelected: false },
  { value: "ELSS", label: "ELSS", isSelected: false },
  { value: "AUDIT", label: "Audit", isSelected: false },
  { value: "GST", label: "GST", isSelected: false },
  {
    value: "FINANCIALLY_ADVISORY",
    label: "Financially Advisory",
    isSelected: false,
  },
  { value: "NPS", label: "NPS", isSelected: false },
  { value: "FOREIGN_INCOME", label: "Foreign Income", isSelected: false },
  {
    value: "CRYPTOCURRENCY_NFT",
    label: "Cryptocurrency NFT",
    isSelected: false,
  },
  { value: "TAX_NOTICE", label: "Tax Notice", isSelected: false },
  { value: "TAX_INTIMATION", label: "Tax Intimation", isSelected: false },
  { value: "OTHER", label: "Other", isSelected: false },
];

const CALL_SUMMARY_TYPE = [
  { value: "GENERAL", label: "General", isSelected: false },
  {
    value: "DECLARATION_SHEET_CREATION",
    label: "Declaration Sheet Creation",
    isSelected: false,
  },
  {
    value: "DECLARATION_SHEET_DISCUSSION",
    label: "Declaration Sheet Discussion",
    isSelected: false,
  },
  {
    value: "DECLARATION_SHEET_SUBMISSION",
    label: "Declaration Sheet Submission",
    isSelected: false,
  },
  { value: "HRA_DISCUSSION", label: "HRA Discussion", isSelected: false },
  {
    value: "HRA_PROOF_CREATION",
    label: "HRA Proof Creation",
    isSelected: false,
  },
  {
    value: "ALLOWANCES_DISCUSSION",
    label: "Allowances Discussion",
    isSelected: false,
  },
  {
    value: "ALLOWANCES_PROOF_CREATION",
    label: "Allowances Proof Creation",
    isSelected: false,
  },
  { value: "FBP_DISCUSSION", label: "FBP Discussion", isSelected: false },
  { value: "FBP_SUBMISSION", label: "FBP Submission", isSelected: false },
  {
    value: "FINANCIALLY_ADVISORY_DISCUSSION",
    label: "Financially Advisory Discussion",
    isSelected: false,
  },
  {
    value: "CAPITAL_GAIN_TAX_DISCUSSION",
    label: "Capital Gain Tax Discussion",
    isSelected: false,
  },
  {
    value: "CONSULTANT_INCOME_DISCUSSION",
    label: "Consultant Income Discussion",
    isSelected: false,
  },
  {
    value: "CONSULTANT_INCOME_DOCUMENT_CREATION",
    label: "Consultant Income Document Creation",
    isSelected: false,
  },
  { value: "AUDIT_REQUIREMENT", label: "Audit Requirement", isSelected: false },
  {
    value: "ITR_FILLING_DISCUSSION",
    label: "ITR Filing Discussion",
    isSelected: false,
  },
  { value: "ITR_FILLING", label: "ITR Filing", isSelected: false },
  {
    value: "GENERAL_DISCUSSION",
    label: "General Discussion",
    isSelected: false,
  },
  {
    value: "SALARY_RESTRUCTURING_SUGGESTION",
    label: "Salary Restructuring Suggestion",
    isSelected: false,
  },
  { value: "ESOP_DISCUSSION", label: "ESOP Discussion", isSelected: false },
  { value: "DDTA_DISCUSSION", label: "DDTA Discussion", isSelected: false },
  {
    value: "HOME_LOAN_DISCUSSION",
    label: "Home Loan Discussion",
    isSelected: false,
  },
  {
    value: "PERSONAL_LOAN_SUGGESTION",
    label: "Personal Loan Suggestion",
    isSelected: false,
  },
  {
    value: "TAX_NOTICE_PREVIOUS_YEAR",
    label: "Tax Notice (Previous Year)",
    isSelected: false,
  },
  {
    value: "TAX_NOTICE_CURRENT_YEAR",
    label: "Tax Notice (Current Year)",
    isSelected: false,
  },
  { value: "TAX_INTIMATION", label: "Tax Intimation", isSelected: false },
  { value: "OTHER", label: "Other", isSelected: false },
];
const DOCUMENT_TYPE = [
  { value: "FORM_16", label: "Form 16", isSelected: false },
  { value: "FORM_26_AS", label: "Form 26AS", isSelected: false },
  { value: "AIS", label: "AIS", isSelected: false },
  { value: "PAYSLIP", label: "Payslip", isSelected: false },
  { value: "CAPITAL_GAIN", label: "Capital Gain", isSelected: false },
  { value: "COMPUTATION", label: "Computation", isSelected: false },
  { value: "DECLARATION", label: "Declaration", isSelected: false },
  {
    value: "SALARY_RESTRUCTURING",
    label: "Salary Restructuring",
    isSelected: false,
  },
  { value: "OTHERS", label: "Others", isSelected: false },
];
const ACTION_CATEGORY = [
  {
    label: "TAX DECLARATION",
    type: "TAX_DECLARATION",
    value: "TAX_DECLARATION",
    isSelected: false,
  },
  {
    label: "SHEET PROOF",
    type: "SHEET_PROOF",
    value: "SHEET_PROOF",
    isSelected: false,
  },
  {
    label: "SUBMISSION CAPITAL GAIN",
    type: "SUBMISSION_CAPITAL_GAIN",
    value: "SUBMISSION_CAPITAL_GAIN",
    isSelected: false,
  },
  {
    label: "PROOF SUBMISSION",
    type: "PROOF_SUBMISSION",
    value: "PROOF_SUBMISSION",
    isSelected: false,
  },
  {
    label: "CAPITAL GAIN",
    type: "CAPITAL_GAIN",
    value: "CAPITAL_GAIN",
    isSelected: false,
  },
  {
    label: "ALLOWANCES",
    type: "ALLOWANCES",
    value: "ALLOWANCES",
    isSelected: false,
  },
  { label: "FBP", type: "FBP", value: "FBP", isSelected: false },
  {
    label: "FINANCE ADVISORY",
    type: "FINANCE_ADVISORY",
    value: "FINANCE_ADVISORY",
    isSelected: false,
  },
  { label: "LOANS", type: "LOANS", value: "LOANS", isSelected: false },
  {
    label: "CONSULTANT INCOME",
    type: "CONSULTANT_INCOME",
    value: "CONSULTANT_INCOME",
    isSelected: false,
  },
  {
    label: "TAX AUDIT",
    type: "TAX_AUDIT",
    value: "TAX_AUDIT",
    isSelected: false,
  },
  { label: "GST", type: "GST", value: "GST", isSelected: false },
  { label: "HUF", type: "HUF", value: "HUF", isSelected: false },
  { label: "DDTA", type: "DDTA", value: "DDTA", isSelected: false },
  {
    label: "TAX NOTICE",
    type: "TAX_NOTICE",
    value: "TAX_NOTICE",
    isSelected: false,
  },
  { label: "ESOP", type: "ESOP", value: "ESOP", isSelected: false },
  {
    label: "DIGITAL ASSET",
    type: "DIGITAL_ASSET",
    value: "DIGITAL_ASSET",
    isSelected: false,
  },
  { label: "GENERAL", type: "GENERAL", value: "GENERAL", isSelected: false },
  { label: "OTHERS", type: "OTHERS", value: "OTHERS", isSelected: false },
];

export {
  CONSULTATION_STATUS,
  ERROR_MESSAGE,
  THEME_DISCUSSED,
  DOCUMENT_TYPE,
  CALL_SUMMARY_TYPE,
  ACTION_CATEGORY,
};
