import styled from "styled-components";
import { dim } from "../../../../styles/theme";
export const DocumentPreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  max-width: ${({ uploading }) => (uploading ? "100%" : dim._scale(400))};
  padding: ${dim._16px};
  border-radius: ${dim._4px};
  background: #fff;
  cursor: pointer;
  box-sizing: border-box;

  & > p {
    width: 50%;
    overflow: hidden;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    margin: 0 ${dim._16px} 0 ${dim._16px};
    color: #212f45;
    font-size: ${dim._12px};
    font-style: normal;
    font-weight: 400;
    line-height: ${dim._22px};
    letter-spacing: 0.2px;
  }

  .download_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${dim._24px};
    height: ${dim._24px};
    border: ${dim._2px} solid #635bef;
    border-radius: 50%;
    margin-left: auto;
    box-sizing: border-box;
  }

  .cross_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${dim._24px};
    height: ${dim._24px};
    font-size: ${dim._18px};
    color: #635bef;
    transform: rotate(45deg);
    border: ${dim._2px} solid #635bef;
    border-radius: 50%;
    cursor: pointer;
    margin-left: auto;
    box-sizing: border-box;
  }
`;
