import styled from 'styled-components';
import { dim } from '../../../styles/theme';

export const IncomeCategoriesStyle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	column-gap: ${dim._24px};
`;
