import PendingActions from "../../Assets/icons/PendingActions";
import {theme} from "../../styles/theme";
import CallIcon from "../../Assets/icons/CallIcon";
import CompleteCheck from "../../Assets/icons/CompleteCheck";
import {ActionCell, CallDate, ClientEmailName} from "./components/ActionCell";
export const columns = (currentWidget, getConsultationViewData, reqObj) => {
  return [
    {
      title: "Type of Call",
      dataIndex: "callType",
      key: "callType",
      ellipsis: true,
      width: 180,
      render: (item, row) => {
        const callType = item?.replaceAll("_", " ");

        return <> {callType}</>;
      },
    },
    {
      title: "Client Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (item, row) => <ClientEmailName key={item} {...row} />,
      width: 230,
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (item, row) => <CallDate key={item} {...row} />,
      width: 240,
    },

    ...(currentWidget !== "COMPLETED_CALLS"
      ? [
          {
            title: "Action",
            dataIndex: "details",
            key: "details",
            render: (text, item) => (
              <ActionCell
                currentWidget={currentWidget}
                getConsultationViewData={getConsultationViewData}
                reqObj={reqObj}
                {...item}
              />
            ),
            width: 148,
          },
        ]
      : [
          {
            title: "Date MOM Completion",
            dataIndex: "momCompletionDate",
            key: "momCompletionDate",
            render: (item, row) => (
              <CallDate
                key={item}
                {...row}
                date={row.momCompletionDate}
                isMom={true}
              />
            ),
            width: 148,
          },
        ]),
  ];
};
export const WIDGET_ACTION_TYPE = {
  PENDING_ACTIONS: "PENDING_ACTIONS",
  COMPLETED_CALLS: "COMPLETED_CALLS",
  UPCOMING_CALLS: "UPCOMING_CALLS",
};

export const widgetArray = [
  {
    Icon: PendingActions,
    title: "Pending Actions",
    type: WIDGET_ACTION_TYPE.PENDING_ACTIONS,
    value: 20,
    themeActive: theme.color.red_50,
    themeInActive: theme.color.red_50 + "33",
    key: "action_pending",
  },
  {
    Icon: CallIcon,
    title: "Upcoming Calls",
    type: WIDGET_ACTION_TYPE.UPCOMING_CALLS,
    value: 20,
    themeActive: theme.color.yellow_0,
    themeInActive: theme.color.yellow_0 + "33",
    key: "upcoming_call",
  },
  {
    Icon: CompleteCheck,
    title: "Completed Calls",
    value: 20,
    type: WIDGET_ACTION_TYPE.COMPLETED_CALLS,
    themeActive: theme.color.green_20,
    themeInActive: theme.color.green_20 + "33",
    key: "completed_call",
  },
];

export const reqObjInitialValue = {
  client_name: null,
  start_date: null,
  end_date: null,
  action_type: null,
};
