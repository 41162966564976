import Styled from 'styled-components';
import { dim } from '../../styles/theme';


const ButtonStyle = Styled.button`

    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${dim._10px} ${dim._24px};
    border-radius: ${dim._4px};
    border: none;
    font-style: normal;
    font-weight: 600;
    word-spacing: ${dim._2px};
    color: #ffffff;
    background: #15BE53;
    cursor: pointer;
    box-sizing: border-box;

    @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.1);}
        100% { transform: scale(1); }
    }

    &:focus {
        outline: none;
    }

    &:hover {
        animation:${(props) =>
					props.pathname ===  !props.hover
						? 'none'
						: 'pulse 1s'}
         ;
        animation-timing-function: linear;
    }

    &:disabled {
        background: #15BE53;
        opacity: 0.5;
        border: none;
        cursor: no-drop;
    }

    &[disabled]:hover {
        animation: none;
        animation-timing-function: none;
    }

`;

export default ButtonStyle;
