import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { PiChatTeardropDots, PiChatTeardrop } from "react-icons/pi";

const UserCard = ({ user, sessions, messages }) => {
    return (
        <UserCardWrapper>
            <h2 className="name">{user.first_name}</h2>
            <p className="email">{user.email}</p>
            <div className="chips">
                <p className="chip">
                    {sessions}&nbsp;
                    <PiChatTeardropDots size={12} color="red" />
                </p>
                <p className="chip">
                    {messages}&nbsp;
                    <PiChatTeardrop size={12} color="red" />
                </p>
            </div>
        </UserCardWrapper>
    );
};

export default UserCard;

const UserCardWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    background-color: ${theme.color.white_0};
    border-radius: 4px;
    position: relative;

    .name {
        margin: 0px;
        font-size: 16px;
        font-weight: normal;
    }

    .chips {
        top: 8px;
        right: 16px;
        position: absolute;
        display: flex;
        column-gap: 8px;
        row-gap: 8px;
        margin: 0px;
        margin-top: 8px;
    }

    .chip {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 2px 10px;
        font-size: 12px;
        border-radius: 20px;
        margin: 0px;
        background-color: ${theme.color.red_100};
        border: 1px solid ${theme.color.red_0};
        font-weight: 600;
    }

    .email {
        font-size: 14px;
        margin: 0px;
        margin-top: 8px;
    }
`;
