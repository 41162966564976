import { getAuth } from "firebase/auth";
import React from "react";
import { toast } from "react-hot-toast";
import { HiOutlineDocumentText } from "react-icons/hi";
import { preSignedUrlDownload } from "../../../../utils/preSignedUrl";

const Row = ({ data, name, advisorId, userId }) => {
  return (
    <div className="rows">
      <div className="c1">
        <p className="r1">{data.name}</p>
      </div>
      <p className="c3 r3">{data.sender_id === advisorId.id ? "You" : name}</p>
      <div className="c4 r4">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "214px",
          }}
        >
          <HiOutlineDocumentText
            className="filePreviewIcon"
            style={{
              width: "18px",
              height: "18px",
              overflow: "hidden",
              borderRadius: "4px",
              marginRight: "12px",

              color: "#0B6aB0",
            }}
          />

          <span
            onClick={() => {
              
              getAuth().onAuthStateChanged(function (user) {
                if (user) {
                  user.getIdToken().then(function (newToken) {
                    preSignedUrlDownload(data.link, userId, newToken).then(
                      (d) => {
                        window.location.href = d;
                      }
                    ).catch((d)=>{
                      toast.error(d?d:'Oops Something went wrong')
                    });
                  }).catch((d)=>{
                    toast.error(d?d:'Oops Something went wrong')
                  });
                }
              });
            }}
            style={{
              color: "#425466",
              fontWeight: 400,
              fontSize: "12px",
              textDecoration: "underline ",
              cursor: "pointer",
            }}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
     {`${data.name}`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Row;
