import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledConsultationCommentModel = styled.div`
  background-color: #fff;
  padding: ${dim._24px} 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .title {
    color: #212f45;
    font-size: ${dim._14px};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: ${dim._16px};
  }
  .commentSection {
    width: 100%;
    display: flex;
    gap: ${dim._16px};
    align-items: center;
    box-sizing: border-box;
  }
  .commentInputBox {
    padding: 8px 0;
    border-bottom: 1px solid #c6c6c6;
  }
  .commentInput {
    border: none;
  }
  .commentInput,
  .commentInputBox,
  .commentLabel {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }
  .commentIcon {
    width: 12.8px;
    height: 15.644px;
  }
  .tagDropdown {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #c6c6c6;
    white-space: nowrap;
  }
  .tagIcon {
    width: 15.86px;
    height: 10px;
    flex-shrink: 0;
  }
  .documentDeleteIcon {
    width: ${dim._24};
    cursor: pointer;
  }
`;
