import { getAuth } from "firebase/auth";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router";
import { preSignedUrlDownload } from "./preSignedUrl";

export const incomencolumns = [
  { field: "category", headerName: "Category", flex: 1 },
  { field: "sub_category", headerName: "Sub Category", flex: 1 },
  {
    field: "income_amount",
    headerName: "Income Amount",
    flex: 1,
    align: "center",
  },
  {
    field: "view",
    headerName: "Document",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <span
            href={params.formattedValue}
            onClick={() => {
              getAuth().onAuthStateChanged(function (user) {
                if (user) {
                  user.getIdToken().then(function (newToken) {
                    preSignedUrlDownload(
                      params.formattedValue.value,
                      params.formattedValue.user,
                      newToken
                    ).then((d) => {
                      window.location.href = d;
                    }).catch((d)=>{
                      toast.error(d?d:'Oops Something went wrong')
                    });
                  }).catch((d)=>{
                    toast.error(d?d:'Oops Something went wrong')
                  });
                }
              });
            }}
            style={{
              color: "#425466",
              fontWeight: 400,
              fontSize: "12px",
              textDecoration: "underline ",
              cursor: "pointer",
            }}
          
          >
            {params.formattedValue.value.length > 65
              ? params.formattedValue.value.slice(51, 65) + "..."
              : params.formattedValue.value.slice(51)}
          </span>
        </div>
      );
    },
  },
  {
    field: "description",
    headerName: "Description ",
    flex: 1,
    align: "center",
  },
  {
    field: "duration",
    headerName: "Duration",
    flex: 1,
    align: "center",
  },
];

export const convertNumberIntoINR = (income) => {
  const convertedIncome = income.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return convertedIncome;
};
