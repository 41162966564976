
import { httpClient } from './httpUtil';


const SuperSaverPlans = {
	getAllPlans: () => {
		return httpClient.get('product-domains/v1/all', {}, {}, false);
	},
	getProducts: (type = 'ITR_FILING') => {
		return httpClient.get('product-domains/v1/all?type=' + type, {}, {}, false);
	},
	updatePlan: (userId,body) => {
		return httpClient.post(`super-saver-user/v1/${userId}/plan-upgrade`, body, {},{}, true);
	},
	
	postReferralData: (data) => {
		return httpClient.post('product-referral/v1', data, {}, {}, false);
	},
	verifyOtp: (booking_id, otp) => {
		return httpClient.get(
			`super-saver-user/v1/verify-otp?external_id=${booking_id}&otp=${otp}`,
			{},
			{},
			false
		);
	},
	generateOtp: (booking_id) => {
		return httpClient.post(
			`super-saver-user/v1/generate-otp?external_id=${booking_id}`,
			{},
			{},
			{},
			false
		);
	}
};

export default SuperSaverPlans;
