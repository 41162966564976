import React from 'react'

const ChatWidgetIcon = () => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.9546 22.1662L13.3041 23.2653C12.7242 24.2449 11.2758 24.2449 10.696 23.2653L10.0455 22.1662C9.54091 21.3138 9.28862 20.8876 8.88338 20.6518C8.47814 20.4162 7.96793 20.4074 6.94752 20.3899C5.44108 20.3638 4.49628 20.2716 3.70391 19.9434C2.23374 19.3344 1.06569 18.1664 0.456722 16.6961C-3.57629e-08 15.5936 0 14.1957 0 11.4L0 10.2C0 6.27191 -7.15258e-08 4.30784 0.884163 2.86502C1.3789 2.05768 2.05768 1.3789 2.86502 0.884163C4.30784 -7.15258e-08 6.27191 0 10.2 0L13.8001 0C17.7281 0 19.6922 -7.15258e-08 21.1351 0.884163C21.9424 1.3789 22.6212 2.05768 23.1159 2.86502C24.0001 4.30784 24.0001 6.27191 24.0001 10.2V11.4C24.0001 14.1957 24.0001 15.5936 23.5434 16.6961C22.9344 18.1664 21.7664 19.3344 20.2962 19.9434C19.5038 20.2716 18.559 20.3638 17.0525 20.3899C16.0321 20.4074 15.5219 20.4162 15.1167 20.6518C14.7115 20.8875 14.4591 21.3138 13.9546 22.1662ZM7.20003 11.7C6.70297 11.7 6.30002 12.103 6.30002 12.6C6.30002 13.0971 6.70297 13.5001 7.20003 13.5001H13.8001C14.2971 13.5001 14.7001 13.0971 14.7001 12.6C14.7001 12.103 14.2971 11.7 13.8001 11.7H7.20003ZM6.30002 8.40003C6.30002 7.90298 6.70297 7.50003 7.20003 7.50003L16.8001 7.50003C17.2971 7.50003 17.7001 7.90298 17.7001 8.40003C17.7001 8.89709 17.2971 9.30004 16.8001 9.30004H7.20003C6.70297 9.30004 6.30002 8.89709 6.30002 8.40003Z" fill="#8280FF"/>
</svg>
  )
}

export default ChatWidgetIcon