import styled from "styled-components";
import { dim } from "../../../../../../styles/theme";

export const ReadOnlyRowStyle = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: auto;
    height: auto;
    margin: 0 0 2px 0;
    padding: 10px 20px;
    background: #fff;
    box-sizing: border-box;

    div {
        width: 12.5%;
        font-size: 12px;
    }
    .editBtn {
        position: absolute;
        right: -${dim._10px};
    }
`;
