import React, { useEffect, useRef, useState } from "react";
import { MonthlySalaryViewWrapper } from "./index.styled";
import {
    getCompanyDetails,
    getCompanyWiseMonthlySalary,
    getTableData,
    getTableDataVerified,
    sortDataOnMonthName,
} from "./util";
import ManualSalarySlipUpload from "../ManualSalarySlipUpload";
import AutoSalarySlipUpload from "../AutoSalarySlipUpload";
import SalaryIncomeServices from "../../../../Services/IncomeServices";
import RightSideSlider from "../../../../commonComponents/RightSideSlider";
import { dim } from "../../../../styles/theme";
import TableView from "../../../../commonComponents/TableView";
import { modifySalaryComponentToRemoveUnusedData } from "../../../PayslipManualReview/utils";

const tableHeadings = [
    { value: "Month", sx: {} },
    { value: "System Generated Amount", sx: {} },
    { value: "Actions", sxOuter: { textAlign: "center" } },
];

const tableHeadingsVerified = [
    { value: "Month", sx: {} },
    { value: "Amount", sx: {} },
    { value: "Comments", sx: {} },
    { value: "Documents", sx: {} },
    { value: "Action", sxOuter: { textAlign: "center" } },
];

const SalaryIncomeDetails = ({
    userId,
    companyId, // external_id of dto list item
    financialYearId,
    style = {},
    verified,
}) => {
    const ref = useRef();
    const nonVerifiedRef = useRef();
    const [data, setData] = useState([]);
    const [dataVerified, setDataVerified] = useState([]);
    const [showToolTip, setShowToolTip] = useState(false);
    const [title, setTitle] = useState("");
    const [companyDetails, setCompanyDetails] = useState();
    const [propsData, setPropsData] = useState({
        month: "",
        system_generated_amount: "",
    });

    const [documentSubmit, setDocumentSubmit] = useState(false);
    const handleVerifyManually = (data) => {
        setPropsData({
            month: data.month,
            system_generated_amount: data.data.system_generated_amount,
        });
        onHideModelClick("VerifyManually");
    };

    const handleUploadPaySlip = (data) => {
        setPropsData({
            month: data.month,
            system_generated_amount: data.data.system_generated_amount,
        });
        onHideModelClick("UploadSalarySlip");
    };

    const modifySalaryComponent=(arr,type,organizationName)=>{
        arr  .filter((item)=>{
            return item.amount>0})
    }

    const handleItemActionClick = (data) => {

       
        const salaryComponent=data?.data?.salary_component||[];
        const lengthOfSalaryComponent = salaryComponent.length;
        const EarningComponent= lengthOfSalaryComponent>1?salaryComponent[0]?.credit:salaryComponent[0]?.credit;
        const DeductionComponent= lengthOfSalaryComponent>1? salaryComponent[1]?.debit:salaryComponent[0]?.debit;
        setPropsData({
            month: data.month,
            system_generated_amount: data.data.system_generated_amount,
            EarningComponent:modifySalaryComponentToRemoveUnusedData(EarningComponent),
            DeductionComponent:modifySalaryComponentToRemoveUnusedData(DeductionComponent),
        });
        onHideModelClick("View");
    };

    const onHideModelClick = (value) => {
        setShowToolTip((state) => !state);
        setDocumentSubmit(false);
        setTitle((state) => {
            if (state === value || value === undefined) {
                return "";
            } else {
                return value;
            }
        });
    };

    useEffect(() => {
        (async () => {
            await handleData();
        })();
    }, []);

    const handleData = async () => {
        const data = await SalaryIncomeServices.getSalaryIncome(
            userId,
            financialYearId
        );
        setCompanyDetails({ ...getCompanyDetails(data.data, companyId) });
        const rawData = sortDataOnMonthName(
            getCompanyWiseMonthlySalary(data.data, companyId)
        );
        const tableData = getTableData(
            rawData,
            handleVerifyManually,
            handleUploadPaySlip
        );
        setData(tableData);
        const tableDataVerified = getTableDataVerified(
            rawData,
            handleItemActionClick,
            userId
        );
        setDataVerified(tableDataVerified);
    };

    const onBreakdownChange = async (data) => {
        await handleData();
        ref.current && ref.current.scrollIntoView({ behavior: "smooth" });
    };

    useState(() => {
        if (verified) {
            setTimeout(() => {
                ref.current &&
                    ref.current.scrollIntoView({ behavior: "smooth" });
            }, 500);
        } else {
            setTimeout(() => {
                nonVerifiedRef.current &&
                    nonVerifiedRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
            }, 500);
        }
    }, [verified]);

    return (
        <div
            style={{
                minHeight: "100vh",
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                padding: "0px 24px",
                boxSizing: "border-box",
                ...style,
            }}
        >
            {companyDetails && (
                <>
                    <RightSideSlider
                        showDis={showToolTip && title === "UploadSalarySlip"}
                        handleHelpShow={onHideModelClick}
                    >
                        <AutoSalarySlipUpload
                            isOpen={showToolTip && title === "UploadSalarySlip"}
                            companyNameDefault={
                                "	" + companyDetails &&
                                companyDetails.name + "  "
                            }
                            key={3}
                            handleClose={(e) => {
                                e.stopPropagation();
                                onHideModelClick();
                            }}
                            documentSubmit={documentSubmit}
                            setDocumentSubmit={setDocumentSubmit}
                            systemGeneratedAmount={
                                propsData.system_generated_amount
                            }
                            month={propsData.month}
                            externalId={companyId}
                            onSubmit={onBreakdownChange}
                        />
                    </RightSideSlider>
                    <RightSideSlider
                        showDis={showToolTip && title === "VerifyManually"}
                        handleHelpShow={onHideModelClick}
                    >
                        <ManualSalarySlipUpload
                            key={4}
                            isOpen={showToolTip && title === "VerifyManually"}
                            companyName={
                                "	" + companyDetails &&
                                companyDetails.name + "  "
                            }
                            month={propsData.month}
                            externalId={companyId}
                            systemGeneratedAmount={
                                propsData.system_generated_amount
                            }
                            handleClose={(e) => {
                                e.stopPropagation();
                                onHideModelClick();
                            }}
                            onSubmit={onBreakdownChange}
                            viewMode={false}
                        />
                    </RightSideSlider>
                    <RightSideSlider
                        showDis={showToolTip && title === "View"}
                        handleHelpShow={onHideModelClick}
                    >
                        <ManualSalarySlipUpload
                            isOpen={showToolTip && title === "View"}
                            systemGeneratedAmount={
                                propsData.system_generated_amount
                            }
                            companyName={
                                "	" + companyDetails &&
                                companyDetails.name + "  "
                            }
                            month={propsData.month}
                            DeductionComponent={propsData.DeductionComponent}
                            EarningComponent={propsData.EarningComponent}
                            externalId={companyId}
                            key={7}
                            update={true}
                            handleClose={(e) => {
                                e.stopPropagation();
                                onHideModelClick();
                            }}
                            viewMode={true}
                        />
                    </RightSideSlider>
                </>
            )}
            <MonthlySalaryViewWrapper
                ref={nonVerifiedRef}
                style={{ scrollMarginTop: dim._90px }}
            >
                <div className="heading">Non Verified Monthly Salary Slip</div>
                <TableView
                    data={data}
                    headings={tableHeadings}
                    style={{ marginTop: dim._16px }}
                />
            </MonthlySalaryViewWrapper>

            <MonthlySalaryViewWrapper
                ref={ref}
                style={{
                    marginTop: dim._24px,
                    marginBottom: dim._24px,
                    scrollMarginTop: dim._90px,
                }}
            >
                <div className="heading">Verified Monthly Salary Slip</div>
                <TableView
                    data={dataVerified}
                    headings={tableHeadingsVerified}
                    style={{ marginTop: dim._16px }}
                />
            </MonthlySalaryViewWrapper>
        </div>
    );
};

export default SalaryIncomeDetails;
