import React from "react";
import styled from "styled-components";

const CommonButtonWrapper = styled.button`
  width: auto;
  height: auto;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #15be53;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-sizing: border-box;

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    transform: none;
  }
`;

const CommonButton = ({ btnName, handleClick, isDisabled, style }) => {
  return (
    <CommonButtonWrapper
      style={style}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {btnName}
    </CommonButtonWrapper>
  );
};

export default CommonButton;
