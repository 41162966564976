

const CallNotAttended = () => {
  return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5236 10.609L11.5236 10.609L11.1187 11.0352L10.8065 10.7386C11.1187 11.0352 11.1182 11.0357 11.1178 11.0362L11.1168 11.0372L11.1148 11.0392L11.1105 11.0437L11.1004 11.0536C11.093 11.0607 11.0844 11.0687 11.0745 11.0774C11.0546 11.0948 11.0295 11.1151 10.999 11.1368C10.9377 11.1803 10.8546 11.2291 10.7482 11.2711C10.5314 11.3566 10.2378 11.4056 9.86654 11.3453C9.1393 11.2272 8.14824 10.6975 6.80467 9.28292C5.45811 7.86522 4.96439 6.82925 4.85531 6.08477C4.79999 5.70719 4.84503 5.41153 4.92246 5.19394C4.96064 5.08665 5.0053 5.00228 5.04547 4.9395C5.06551 4.90818 5.08435 4.88237 5.10061 4.86182C5.10874 4.85155 5.11622 4.8426 5.12289 4.83493L5.13228 4.82437L5.13647 4.81981L5.13844 4.81771L5.13939 4.8167C5.13985 4.8162 5.14032 4.81572 5.50284 5.16006L5.14032 4.81572L5.39526 4.54731C5.8493 4.0693 5.90726 3.27432 5.48884 2.68252L4.36797 1.0972C4.36797 1.0972 4.36797 1.0972 4.36797 1.0972C3.85684 0.374299 2.9156 0.313799 2.37271 0.885357C2.37271 0.885357 2.37271 0.885358 2.3727 0.885358L0.977506 2.35425L0.614976 2.0099L0.977505 2.35425C0.660217 2.68829 0.479728 3.08639 0.501848 3.49848L0.00256729 3.52528L0.501849 3.49848C0.575909 4.87825 1.16884 7.97609 4.64285 11.6336C8.29596 15.4796 11.6776 15.5998 12.9965 15.4696L11.5236 10.609ZM11.5236 10.609C11.9205 10.1911 12.5389 10.1 13.0597 10.3998L14.7579 11.3773L15.0074 10.944L14.7579 11.3773C15.5779 11.8493 15.745 13.0087 15.1234 13.6631L15.486 14.0075L15.1234 13.6631L13.8607 14.9925L13.8607 14.9926M11.5236 10.609L13.8607 14.9926M13.8607 14.9926C13.5944 15.2729 13.3002 15.4396 12.9965 15.4696L13.8607 14.9926Z" stroke="#EA4336"/>
<path d="M9.22119 2.08008L14.2212 7.08008" stroke="#EA4336" stroke-linecap="round"/>
<path d="M9 6.81885L13.8187 2.00018" stroke="#EA4336" stroke-linecap="round"/>
</svg>
  )
}

export default CallNotAttended