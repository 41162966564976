const actions = {
  HANDLE_CONSULTATION_STATUS: "HANDLE_CONSULTATION_STATUS",
  GET_CONSULTATION_DATA: "GET_CONSULTATION_DATA",
  SET_CONSULTATION_DATA: "SET_CONSULTATION_DATA",
  HANDLE_ACTIONABLE_STATUS: "HANDLE_ACTIONABLE_STATUS",
  SET_MOM_DATA: "SET_MOM_DATA",
  GET_MOM_DATA: "GET_MOM_DATA",
  SET_CONSULTATION_ID: "SET_CONSULTATION_ID",
};

export { actions };
