const ClientTabsData = [
  {
    id: "chat",
    name: "Chat",
  },
  {
    id: "document",
    name: "Document",
  },
];

export const internalChatData = {
  pdf_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/itr-filing-revamp/icons/pdf_icon.svg",
  attach_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/assets/icons/chat-revamp/attach_file.svg",
  send_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/assets/icons/chat-revamp/send_msg_icon.svg",
  no_session_bg:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/assets/images/no_chat_session_bg.svg",
};

export const DROPDOWN = [
  { value: "GENERAL_QUERY", label: "General Query" },
  { value: "TAX_PLANNING", label: "Tax Planning" },
  { value: "ADVANCE_TAX", label: "Advance Tax" },
  { value: "PROOF_SUBMISSION", label: "Proof Submission" },
];

export { ClientTabsData };
