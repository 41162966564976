import styled from "styled-components";

export const IncomeTypeStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 209px;
    margin: 0;
    padding: 24px;
    position: relative;
    background: ${(props) =>
        props.verified ? "rgba(21, 190, 83, 0.08)" : "rgba(226, 89, 31, 0.08)"};
    border-radius: 4px;
    box-sizing: border-box;

    .status {
        position: absolute;
        top: 28px;
        right: 24px;
				margin: 0;
    }

    h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #212f45;
        margin: 0;
    }

    .endDate,
    .verificationStatus {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #212f45;
        opacity: 0.5;
        margin: 0;
    }

    .amount {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: ${(props) => (props.verified ? "#15be53" : "#E2591F")};
        margin: 0;
    }

    button {
        width: auto;
        height: 32px;
        margin: 0;
        padding: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #15be53;
        background: #ffffff;
        border: 1px solid #15be53;
        border-radius: 2px;
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
        box-sizing: border-box;
    }

    .message-container {
        display: flex;
        align-items: center;
    }

    .message {
        font-size: 12px;
        font-weight: 500;
        margin-left: 8px;
    }

    .alert-icon {
        height: 16px;
    }
`;
