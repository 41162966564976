import React from 'react'
import PendingAdvisorDoc from '../../../commonComponents/PendingAdvisorDoc'
import ExemptionPage from '../../../container/AssignedUsers/components/ExemptionPage'
import TaskManager from '../../../container/TaskManager'
import DeductionView from '../../../components/DeductionView'
import IncomeRoot from '../../../container/IncomeRoot'
import BusinessRentCryptoDetails from '../../../container/IncomeNew/Component/BusinessRentCryptoDetails'
import SalaryDetails from '../../../container/IncomeNew/Screens/SalaryDetails'
import ActiveChats from '../../../container/ActiveChats'
import OnboardingData from '../../../container/AssignedUsers/components/OnboardingData'
import ITRDocuments from '../../../container/ITRDocuments'
import ConsultationExperienceForm from '../../../container/ConsultationExperience/Screens/ConsultationExperienceForm'
import PayslipManualReview from '../../../container/PayslipManualReview'
import PayslipReview from '../../../container/PayslipManualReview/Screens/PayslipReview'
import ITRFilingUsers from '../../../container/ITRFilingUsers'
import ConsultationExperience from '../../../container/ConsultationExperience'
import { Route, Routes } from 'react-router'
import ActivityForUser from '../../../container/AssignedUsers/components/UserProfileForAdvisor/ActivityForUser'
import ClientChat from '../../../container/ClientChat'
import AssignedUsers from '../../../container/AssignedUsers'
import UserProfileForAdvisor from '../../../container/AssignedUsers/components/UserProfileForAdvisor'
import NotesPage from '../../../container/ConsultationExperience/Models/CallSummary/AdvisorNotes/NotesPage'
import ActionDashboard from '../../../container/ActionDashboard'
import ConsultationView from '../../../container/ActionDashboard/screens/ConsultationView'
const AdvisorRoutes = () => {
  return (
    <Routes>
    <Route
      path="/userProfileForAdvisor/deductionView"
      element={<DeductionView />}
    />
    <Route
      path="/userProfileForAdvisor/incomeView"
      element={<IncomeRoot />}
    />
    <Route
      path="/userProfile-for-advisor/user-activity"
      element={<ActivityForUser />}
    />
    <Route
      path="/userProfile-for-advisor/client-chat"
      element={<ClientChat />}
    />
    <Route
      path="/userProfile-for-advisor/itr-filing"
      element={<ITRDocuments />}
    />
    <Route
      path="/userProfile-for-advisor/onboarding-form"
      element={<OnboardingData />}
    />
    <Route path="/users" element={<AssignedUsers />} />
    <Route path="/" element={<ActionDashboard />} />
    <Route path="/consultation-view" element={<ConsultationView />} />
    <Route path="/users/:id" element={<AssignedUsers />} />
    <Route path="/active-chats" element={<ActiveChats />} />
    <Route
      path="/userProfileForAdvisor/pending-advisorDoc"
      element={<PendingAdvisorDoc type={"advisor"} />}
    />
    <Route
      path="/userProfileForAdvisor/exemption-page"
      element={<ExemptionPage type={"advisor"} />}
    />
    <Route
      path="/userProfileForAdvisor"
      element={<UserProfileForAdvisor />}
    />
    {/* New Income Routes */}
    <Route
      path="/userProfileForAdvisor/incomeView"
      element={<IncomeRoot />}
    />
    <Route
      path="/userProfileForAdvisor/incomeView/:id"
      element={<IncomeRoot />}
    />
    <Route
      path="/userProfileForAdvisor/incomeView/:id/:userId"
      element={<IncomeRoot />}
    />
    <Route
      path="/userProfileForAdvisor/incomeView/capital-gain/detail"
      element={<BusinessRentCryptoDetails />}
    />
    <Route
      path="/userProfileForAdvisor/incomeView/business-and-profession/detail"
      element={<BusinessRentCryptoDetails />}
    />
    <Route
      path="/userProfileForAdvisor/incomeView/digital-asset/detail"
      element={<BusinessRentCryptoDetails />}
    />
    <Route
      path="/userProfileForAdvisor/incomeView/income-from-house-rent/detail"
      element={<BusinessRentCryptoDetails />}
    />
    <Route
      path="/userProfileForAdvisor/incomeView/salary-breakdown"
      element={<SalaryDetails />}
    />
    <Route
      path="/consultation-experience"
      element={<ConsultationExperience />}
    />
    <Route
      path="/consultation-experience/consultation-form"
      element={<ConsultationExperienceForm />}
    />
    <Route path="/task-manager" element={<TaskManager />} />
    <Route
      path="/payslip-manual-review"
      element={<PayslipManualReview />}
    />{" "}
    <Route
      path="/payslip-manual-review/preview"
      element={<PayslipReview />}
    />
    <Route path="/itr-filing-users" element={<ITRFilingUsers />} />
    <Route path="/itr-filing-users/:id" element={<ITRFilingUsers />} />
    {/* New Income Routes */}
    <Route
      path="/userProfileForAdvisor/notes"
      element={<NotesPage />}
    />
  </Routes>
  )
}

export default AdvisorRoutes