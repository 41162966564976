import React from "react";
import { toast } from "react-hot-toast";
import { convertNumberIntoINR } from "../../../../utils/ConvertNumnerIntoIndianCurrency";
import { preSignedUrlDownload } from "../../../../utils/preSignedUrl";
import Divider from "../../../../commonComponents/Divider";
import { PayslipRead } from "../../../../Services/PayslipReadApi";

const Type = {
    USER: "USER",
    SYSTEM: "SYSTEM",
};

export const getCompanyDetails = (getAPIData, companyId) => {
    const DTOObject = getAPIData.income_details.salary_income_dto_list.find(
        (it) => {
            return it.external_id === companyId;
        }
    );
    if (DTOObject === undefined) {
        throw new Error("DTO object not found");
    }
    return DTOObject.company_details;
};

export const getCompanyWiseMonthlySalary = (getAPIData, companyId) => {
    let data = [];
    const DTOObject = getAPIData.income_details.salary_income_dto_list.find(
        (it) => {
            return it.external_id === companyId;
        }
    );

    if (DTOObject !== undefined) {
        const monthlyBreakdown = DTOObject.monthly_breakdown;
        for (const key in monthlyBreakdown) {
            if (Object.hasOwnProperty.call(monthlyBreakdown, key)) {
                const element = monthlyBreakdown[key];
                data.push({ month: key, data: element });
            }
        }
    }
    return data;
};

export const getTableDataVerified = (rawData = [], onClick, userId) => {
    return rawData
        .filter((it) => {
            return it.data.is_approved === true;
        })
        .map((it) => {
            return [
                tdDataVerified(it.month),
                tdDataVerified(convertNumberIntoINR(it.data.approved_salary)),
                tdDataVerified(
                    it.data.approved_source === Type.USER
                        ? "Approved by User"
                        : "Approved by System"
                ),
                tdDataVerified(
                    it.data.salary_slip ? (
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                                try {
                                    const {data:url} = await PayslipRead.getPresignedS3Url( it.data.salary_slip);
                                 
                                    window.open(url, "__blank");
                                } catch (error) {
                                    toast.error(
                                        error
                                            ? error
                                            : "Oops Something went wrong"
                                    );
                                }
                            }}
                        >
                            {/* {extractValue(it.data.salary_slip)}{" "}  */}
                            View Payslip
                        </span>
                    ) : (
                        "---"
                    )
                ),
                tdDataVerified(
                    it.data.is_approved ? (
                        <p
                            onClick={() => onClick(it)}
                            style={{ color: "#009900", cursor: "pointer" }}
                        >
                            {"view >>"}
                        </p>
                    ) : (
                        <p>{"upload >>"}</p>
                    ),
                    {
                        textAlign: "center",
                    },
                    () => {
                        onClick(it);
                    }
                ),
            ];
        });
};

export function extractValue(str) {
    const firstIndex = str.indexOf("_");
    if (firstIndex !== -1) {
        const secondIndex = str.indexOf("_", firstIndex + 1);
        if (secondIndex !== -1) {
            const thirdIndex = str.indexOf("_", secondIndex + 1);
            if (thirdIndex !== -1) {
                const extractedValue = str.substring(thirdIndex + 1);
                return extractedValue;
            }
        }
    }
    return null; // not enough underscores in the string
}

export const getTableData = (
    rawData = [],
    handleVerifyManually,
    handleUploadPaySlip
) => {
    return rawData
        .filter((it) => {
            return it.data.is_approved === false;
        })
        .map((it) => {
            return [
                tdData(it.month),
                tdData(convertNumberIntoINR(it.data.system_generated_amount)),
                tdData(
                    <ButtonGroup
                        item={"Verify Manually"}
                        item2={"Upload Salary Slip"}
                        onClick={() => handleVerifyManually(it)}
                        onClick2={() => handleUploadPaySlip(it)}
                    />,
                    {
                        textAlign: "center",
                    }
                ),
            ];
        });
};

const tdData = (value, sxOuter = {}, onClick = () => {}, classOuter = "") => {
    return {
        value: value,
        classOuter: classOuter,
        sxOuter: { width: "33%", ...sxOuter },
        onClick: onClick,
    };
};

const tdDataVerified = (
    value,
    sxOuter = {},
    onClick = () => {},
    classOuter = ""
) => {
    return {
        value: value,
        classOuter: classOuter,
        sxOuter: { width: "20%", ...sxOuter },
        onClick: onClick,
    };
};

export const sortDataOnMonthName = (data = []) => {
    const months = {
        JANUARY: 10,
        FEBRUARY: 11,
        MARCH: 12,
        APRIL: 1,
        MAY: 2,
        JUNE: 3,
        JULY: 4,
        AUGUST: 5,
        SEPTEMBER: 6,
        OCTOBER: 7,
        NOVEMBER: 8,
        DECEMBER: 9,
    };

    data.sort(function (a, b) {
        return months[a.month] - months[b.month];
    });
    return data;
};

export const ButtonGroup = ({
    item,
    item2,
    onClick = () => {},
    onClick2 = () => {},
}) => {
    return (
        <span style={{ display: "flex", alignItems: "center" }}>
            <p style={{ padding: "0px 8px" }}>{item}</p>
            <Divider
                width={2}
                height={20}
                style={{ backgroundColor: "#E5E5E5", margin: "0px 8px" }}
            />
            <p style={{ padding: "0px 8px" }}>{item2}</p>
        </span>
    );
};
