import { toast } from "react-hot-toast";
import { preSignedUrlDownload } from "./preSignedUrl";
// import { preSignedUrlDownload } from './preSignedUrl';
const downloadByOriginalName = (fileLink, originalName, user_id) => {
  return preSignedUrlDownload(fileLink, user_id)
    .then((d) => {
      originalName
        ? fetch(d)
            .then((response) => {
              // Extract the suggested filename from the Content-Disposition header
              const filenameHeader = response.headers.get(
                "Content-Disposition"
              );
              const suggestedFilename = filenameHeader
                ? filenameHeader.split("filename=")[1]
                : originalName;

              // Return the response along with the suggested filename
              return { response, suggestedFilename };
            })
            .then(({ response, suggestedFilename }) => {
              // Convert the response to blob
              return response.blob().then((blob) => ({
                blob,
                suggestedFilename,
              }));
            })
            .then(({ blob, suggestedFilename }) => {
              // Create a URL for the blob
              const url = URL.createObjectURL(blob);

              // Create a download link element
              const a = document.createElement("a");
              a.href = url;
              a.download = suggestedFilename;

              // Click the link to trigger the download
              a.click();

              // Clean up the URL object
              URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error("Error downloading file:", error);
            })
        : (window.location.href = d.data);
    })
    .catch((d) => {
      toast.error(d ? d : "Oops Something went wrong");
    });
};

export { downloadByOriginalName };
