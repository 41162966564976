import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../components/Login";
import SignupPage from "../components/Signup";
import Welcome from "../components/WelcomePage";
import AdvisorData from "../Context/Advisor/AdvisorData";

const UnProtectedRoutes = ({ userType, setUser, setLoggedIn,setType }) => {
    return (
        <AdvisorData>
            <Routes>
                <Route path="/welcome" element={<Welcome />} />
                <Route
                    path="/"
                    element={
                        <LoginPage
                            userType={userType}
                            setUser={setUser}
                            setType={setType}
                            setLoggedIn={setLoggedIn}
                        />
                    }
                />
                <Route
                    path="/signup"
                    element={
                        <SignupPage
                            setUser={setUser}
                            setLoggedIn={setLoggedIn}
                        />
                    }
                />
            </Routes>
        </AdvisorData>
    );
};

export default UnProtectedRoutes;
