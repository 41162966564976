import { useState } from "react";
import AdvisorContext from "./AdvisorContext";
import moment from "moment";

const AdvisorData = (props) => {
  const [table, setTable] = useState([]);
  const [tableDelete, setTableDelete] = useState([]);
  const [tf, setTf] = useState(false);

  const [visible, setVisible] = useState("false");
  const [Toggle, setToggle] = useState(false);
  const [AdvisorId, setAdvisorId] = useState(null);
  const [AdvisorUserId, setAdvisorUserId] = useState(null);
  const [editSlot, setEditSlot] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [ViewAppointmentsData, setViewAppointmentsData] = useState({
    start: 22,
    end: 22,
  });
  const [ViewSlotsData, setViewSlotsData] = useState(false);
  const [CreateSlots, setCreateSlots] = useState(false);
  const [Title, setTitle] = useState(false);
  const [ViewSlots, setViewSlots] = useState(false);
  const [AppDate, setAppDate] = useState(moment().format("YYYY-MM-DD"));
  const [Appointments, setAppointments] = useState(false);
  const [username, setUsername] = useState("jj");
  const [Date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [start_time, setstart_time] = useState(null);
  const [end_time, setend_time] = useState(null);
  const [ResetTime, setResetTime] = useState(false);
  const [UpdateAdv, setUpdateAdv] = useState(false);
  const [Token, setToken] = useState(false);
  const [ToggleMode, setToggleMode] = useState(false);
  const [getdata, setgetData] = useState(false);
  // var Token=false;
  const setAppContext = (appContext) => {
    setToken(appContext);
  };

  const deleteapi = (url, config) => {
    const Fetchfn = async () => {
      try {
        const response = await fetch(url, config);
        setTf(true);
        return response.json();
      } catch (error) {
        console.log("error", error);
      }
    };
    return Fetchfn();
  };

  return (
    <AdvisorContext.Provider
      value={{
        tf,
        setTf,
        ToggleMode,
        setToggleMode,
        UpdateAdv,
        setUpdateAdv,
        getdata,
        setgetData,
        Token,
        setAppContext,
        ResetTime,
        setResetTime,
        username,
        setUsername,
        Title,
        setTitle,
        AppDate,
        Date,
        setDate,
        start_time,
        setstart_time,
        end_time,
        setend_time,
        setAppDate,
        ViewSlots,
        setViewSlots,
        tableDelete,
        ViewAppointmentsData,
        Appointments,
        setAppointments,
        ViewSlotsData,
        setViewSlotsData,
        CreateSlots,
        setCreateSlots,
        setViewAppointmentsData,
        setTableDelete,
        Delete,
        setDelete,
        deleteapi,
        Toggle,
        setToggle,
        editSlot,
        setEditSlot,
        table,
        setTable,
        AdvisorId,
        setAdvisorId,
        AdvisorUserId,
        setAdvisorUserId,
        visible,
        setVisible,
      }}
    >
      {props.children}
    </AdvisorContext.Provider>
  );
};

export default AdvisorData;
