import React, { useEffect, useState } from "react";

import { AdvisorActionStyle } from "./AdvisorAction.styled";
import TextButton from "../../../../../commonComponents/TextButton";
import { theme, dim } from "../../../../../styles/theme";
import DropDownComponent from "../../../components/DropdownComponet";
import AdvisorSubmitProof from "../AdvisorSubmitProof";
import ActionableDocuments from "../ActionableDocuments";
import { errorValidator, findUndefinedProperties } from "../../../utils";
import FormErrorMsgHandler from "../../../../../commonComponents/FormErrormsgHandler";
import { ACTION_CATEGORY, ERROR_MESSAGE } from "../../../constant";
import { useSelector } from "react-redux";
import { modifyMetaData } from "../../../../../utils/modifyMetaData";

const adviosr_text_content = {
  advisor_dependency: "Advisor Action Item(s)",
  add_action: "+ ADD ACTION",
};

const AdvisorAction = ({
  handleAdvisorData,
  handleActionableItem,
  handleHelpShow,
  // handleDeleteActionable,
}) => {
  const ACTION_CATEGORY = useSelector(
    (state) => state.cmsReducer?.actionable_category
  );
  const dropDownData = {
    defaultValue: "Select Category",
    action_item: [
      {
        label: "Category",
        id: "type",
        errorType: "action_category",
        defaultValue: "Select Category",
        data: modifyMetaData(ACTION_CATEGORY),
      },
      {
        label: "Action",
        id: "action",
        errorType: "action_type",
        defaultValue: "Select Action",
        data: [
          {
            label: "Documents upload",
            type: "DOCUMENT_UPLOAD",
            value: "DOCUMENT_UPLOAD",
            isSelected: false,
          },
          {
            label: "Comment",
            type: "COMMENT",
            value: "COMMENT",
            isSelected: false,
          },
        ],
      },
    ],
  };
  const [document, setDocument] = useState({ type: "", action: "" });
  const [actionableData, setActionableData] = useState(
    dropDownData.action_item
  );
  const [documentObj, setDocumentObj] = useState({
    document_type: "",
    // document_name: "",
  });
  const [isError, setIsError] = useState({ error: false, errorArr: [] });
  const [isCommentOnAction, setCommentOnAction] = useState(true);
  const [comment, setComment] = useState("");
  const [actionableList, setActionableList] = useState([]);

  const { advisor_dependency } = adviosr_text_content;

  useEffect(() => {}, []);

  let card_items = [];
  const handlDropDownType = (value, index, e) => {
    const newArr = actionableData.map((d, i) => {
      if (d.id === e.target.name) {
        return {
          ...d,
          data: d.data.map((dropdownValues, index) => {
            card_items.push(dropdownValues);

            return {
              ...dropdownValues,
              isSelected:
                dropdownValues.label === value ||
                dropdownValues.value === value,
            };
          }),
        };
      } else {
        return d;
      }
    });

    setActionableData(newArr);
    setDocument({
      ...document,
      [e.target.id]: +value,
    });
  };

  const hadnleComment = (e) => {
    setComment(e.target.value);
  };
  const findActionableType = (actionableData, type) => {
    const item = actionableData.filter((d, i) => {
      return d.id === type;
    });

    return item[0].data?.filter((d) => d?.isSelected)[0]?.value;
  };

  const handleDeleteActionable = (index) => {
    const newArr = actionableList;
    newArr.splice(index, 1);
    setActionableList([...newArr]);
  };

  return (
    <AdvisorActionStyle>
      <div className="heading">{advisor_dependency}</div>
      <div
        className={
          findActionableType(actionableData, "action") !== "COMMENT" &&
          findActionableType(actionableData, "action") !== "DOCUMENT_UPLOAD"
            ? `border-bottom`
            : null
        }
      >
        <div className="dropdown-container">
          {actionableData.map((item, ind) => {
            return (
              <div key={ind}>
                <div style={{ marginBottom: "10px" }}>{item.label}</div>
                <DropDownComponent
                  dataOptions={item.data}
                  id={item.id}
                  name={item.id}
                  defaultValue={item.defaultValue}
                  type={item.data?.filter((d) => d.isSelected)[0]?.value}
                  handleDropDownChange={handlDropDownType}
                  isError={isError}
                  componentCategory={item.errorType}
                />
              </div>
            );
          })}
        </div>
      </div>
      {findActionableType(actionableData, "action") === "DOCUMENT_UPLOAD" && (
        <ActionableDocuments
          documentData={document}
          isError={isError}
          documentObj={documentObj}
          setDocumentObj={setDocumentObj}
          handleChangeActionableType={(documentObj, setDocumentObj) => {
            const newObj = {
              document: documentObj,
              action_category: findActionableType(actionableData, "type"),
              action_type: findActionableType(actionableData, "action"),
              status: "COMPLETED",
              owned_by: "ADVISOR",
            };

            const data = errorValidator(findUndefinedProperties(newObj));
            !data.error && setActionableData(dropDownData.action_item);

            setIsError(data);

            !data.error &&
              setActionableList((state) => {
                return [...state, newObj];
              });
            !data.error && setDocumentObj({ document_type: "" });
          }}
        />
      )}

      {
        <div
          className="border-bottom"
          style={{
            position: "relative",
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <textarea
                className="msg-box"
                rows="4"
                cols="77"
                placeholder="Type Details..."
                value={comment}
                type="text"
                id="name"
                onChange={hadnleComment}
              >
                {comment}
              </textarea>
            </div>
            <TextButton
              onClick={() => {
                setCommentOnAction(false);
                const newObj = {
                  text: comment.replace(/\s/g, "").length > 0 ? comment : "",
                  action_category: findActionableType(actionableData, "type"),
                  action_type: findActionableType(actionableData, "action"),
                  ...(findActionableType(actionableData, "action") ===
                    "DOCUMENT_UPLOAD" && { document: documentObj }),
                  status: "PENDING",
                  owned_by: "ADVISOR",
                };
                const data = errorValidator(findUndefinedProperties(newObj));
                !data.error && setActionableData(dropDownData.action_item);

                setIsError(data);
                !data.error && setComment("");
                !data.error &&
                  setActionableList((state) => {
                    return [...state, newObj];
                  });
                !data.error && setDocumentObj({ document_type: "" });
              }}
            >
              + ADD ACTION
            </TextButton>
          </div>
          {isError?.error && isError.errorArr.includes("text") && (
            <FormErrorMsgHandler style={{ bottom: "-4px" }}>
              {ERROR_MESSAGE.text}
            </FormErrorMsgHandler>
          )}
        </div>
      }

      {actionableList.map((item, index) => (
        <AdvisorSubmitProof
          item={item}
          index={index}
          handleDeleteActionable={handleDeleteActionable}
        />
      ))}
      <div className="button-align">
        <TextButton
          style={{
            background: theme.color.green_20,
            color: theme.color.white_0,
            padding: dim._10px,
            fontSize: dim._14px,
            width: "18%",
            letterSpacing: "2.343px",
            opacity: actionableList.length > 0 ? "1" : "0.3",
            cursor: actionableList.length > 0 ? "pointer" : "not-allowed",
          }}
          disabled={actionableList.length > 0 ? false : true}
          onClick={() => {
            handleAdvisorData(actionableList);
            // handleActionableItem(actionableList);
            handleHelpShow(true);
          }}
        >
          Save
        </TextButton>
      </div>
    </AdvisorActionStyle>
  );
};
export default AdvisorAction;
