import React, { useEffect, useState } from "react";
import { Modal, Button, Dropdown, Space, Input } from "antd";
import styled from "styled-components";
import {
  ChatStartModalContainer,
  StyledButton,
  SubmitButton,
} from "./ChatStartModal.styled";
import { DROPDOWN } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { postSession } from "../../store/action";
import { FaChevronDown } from "react-icons/fa6";
import toast from "react-hot-toast";

const { TextArea } = Input;

const ChatStartModal = ({
  isModalOpen,
  cancelModal,
  advisorData,
  setselectedChat,
  userId,
  advisorId,
  showLoading,
  hideLoading,
  onHideServiceRequestModal,
}) => {
  const dispatch = useDispatch();
  const [formValue, setformValue] = useState({
    subject: "",
    query_type: null,
  });

  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isEmpty = value.trim() === "";

    setformValue({
      ...formValue,
      [name]: isEmpty ? "" : value,
    });
  };

  const handleSubmit = async () => {
    try {
      showLoading();
      const req = {
        user_id: userId,
        subject: formValue.subject,
        query_type: formValue.query_type.value,
      };
      dispatch(postSession(req, advisorId));
      cancelModal();
      setformValue({
        subject: "",
        query_type: null,
      });
      setselectedChat(0);
    } catch (error) {
      toast.error("Error in handleSubmit:", error);
    } finally {
      setTimeout(() => {
        hideLoading();
      }, 500);
    }
  };

  const handleMenuClick = (e) => {
    const selectedOption = DROPDOWN.find((option) => option.value === e.key);
    setformValue({
      ...formValue,
      query_type: selectedOption,
    });
  };

  const menuProps = {
    items: DROPDOWN.map((item) => ({
      key: item.value,
      label: item.label,
    })),
    onClick: handleMenuClick,
  };

  return (
    <>
      <Modal
        visible={isModalOpen}
        footer={null}
        width={430}
        centered
        onCancel={() => {
          cancelModal();
          setformValue({
            subject: "",
            query_type: null,
          });
        }}
      >
        <ChatStartModalContainer>
          <h2>New Chat</h2>
          <h3>Select a purpose</h3>
          <Dropdown menu={menuProps}>
            <StyledButton>
              <Space>{formValue?.query_type?.label || "Select"} </Space>
              <FaChevronDown style={{ margin: "0 0 0 10px" }} />
            </StyledButton>
          </Dropdown>
          <h3>Please Enter your Query</h3>
          <TextArea
            rows={3}
            id="subject"
            name="subject"
            placeholder={"I have following tax query…"}
            value={formValue?.subject}
            onChange={handleChange}
            maxLength="150"
            style={{
              padding: "12px",
              resize: "none",
              borderRadius: "12px",
              borderColor: isFocused ? "#15BE53" : "#d9d9d9",
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <p className="char_limit">{`${formValue?.subject?.length}/150`}</p>
          <SubmitButton
            type="primary"
            disabled={
              formValue?.subject?.trim()?.length === 0 ||
              formValue?.query_type === null
            }
            onClick={handleSubmit}
            style={{
              height: "40px",
              marginTop: "40px",
              borderRadius: "12px",
              backgroundColor: "#15BE53",
            }}
          >
            Submit
          </SubmitButton>
        </ChatStartModalContainer>
      </Modal>
    </>
  );
};

export default ChatStartModal;
