import styled from "styled-components";

export const CapitalGainDetailsStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: 80vh;
    height: auto;
    margin: 0 0 20px 0;
    padding: 0 24px 24px 24px;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;

    .head {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        min-height: auto;
        height: auto;
        margin: 0 0 0px 0;
        padding: 10px 20px;
        background: #e5e5e5;
        border-radius: 4px 4px 0 0;
        box-sizing: border-box;
    }

    .row {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        min-height: auto;
        height: auto;
        margin: 0 0 2px 0;
        padding: 10px 20px;
        background: #fff;
        box-sizing: border-box;
    }

    .head div {
        width: 14.2%;
        font-size: 12px;
        font-weight: 600;
        color: #212f45;
        text-transform: uppercase;
    }
    .row div {
        width: 14.2%;
        font-size: 12px;
    }

    .addMoreBtn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .sticky-header {
        position: sticky;
        width: 100%;
        top: 72px;
        background-color: #f6f9fc;
        z-index: 10000;
        box-sizing: border-box;
    }

    .buttons {
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        width: 100%;
        justify-content: space-between !important;
        align-items: center;
        margin-bottom: 12px;
        margin-top: 8px;
    }

    .left-items {
        display: flex;
        align-items: center;
    }

    .heading {
        font-weight: 600;
        font-size: 16px;
        color: #212f45;
        box-sizing: border-box;
    }
`;
