


const MomIcon = () => {
  return (
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.85693 3.88467L12.5032 1.3359C15.9346 0.192109 17.7989 2.06541 16.6641 5.49679L14.1154 13.1431C12.4042 18.2856 9.59423 18.2856 7.88304 13.1431L7.1265 10.8735L4.85693 10.117C-0.285642 8.40578 -0.285642 5.60487 4.85693 3.88467Z" stroke="#15BE53" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.29761 10.4862L10.5218 7.25293" stroke="#15BE53" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default MomIcon