import React, { useEffect, useState } from 'react';
import { StyledReadPayslip } from './ReadPayslip.styled';
import TableHeading from './TableHeading';
import ReadOnlyRow from './ReadOnlyRow';
import EditableRow from './EditableRow';
import { Loader2 } from '../../../../../../commonComponents/Loader';
import { dim } from '../../../../../../styles/theme';
import TextButton from '../../../../../../commonComponents/TextButton';

const ReadPayslip = ({
	earningComponent,
	componentTitle,
	componentLoading,
	setComponent,
	totalEarning,
	totalDeduction,
	viewMode,
	style
}) => {
	const [componentsList, setComponentsList] = useState([...earningComponent]);

	useEffect(() => {
		setComponentsList([...earningComponent]);
	}, [earningComponent]);

	const handleAddMoreClick = () => {
		const newArr = componentsList;
		newArr.push({
			label: '',
			amount: '',
			editStatus: true,
			isNew: true,
			isEditable: true
		});

		setComponent([...newArr]);
	};
	const handleEditClick = (i, value, action, obj, isNew) => {
		const newArr = componentsList;
		if (action === 'edit') {
			newArr.splice(i, 1, {
				...componentsList[i],
				editStatus: value
			});
		} else if (action === 'remove') {
			newArr.splice(i, 1);
		} else if (action === 'save') {
			newArr.splice(i, 1, { ...componentsList[i], ...obj, editStatus: value });
		} else if (action === 'cancel' && isNew) {
			newArr.splice(i, 1);
		} else if (action === 'cancel' && !isNew) {
			newArr.splice(i, 1, {
				...componentsList[i],
				editStatus: value
			});
		}

		setComponent([...newArr]);
	};

	return (
		<StyledReadPayslip style={style}>
			<div className='heading'>{componentTitle}</div>
			<TableHeading
				componentName={
					totalEarning >= 0
						? 'Salary COMPONENT TYPE'
						: 'Deduction COMPONENT TYPE'
				}
			/>
			{componentLoading ? (
				<span className='loader'>
					<Loader2 />
				</span>
			) : (
				<>
					{componentsList &&
						componentsList.map((data, i) => {
							if (data.editStatus) {
								return (
									<EditableRow
										items={data}
										key={i}
										editId={i}
										handleEditClick={handleEditClick}
									/>
								);
							}
							return (
								<ReadOnlyRow
									items={data}
									key={i}
									editId={i}
									handleEditClick={handleEditClick}
									viewMode={viewMode}
								/>
							);
						})}
					{totalEarning >= 0 ? (
						<ReadOnlyRow
							style={{
								background: ' #e5e5e5',
								fontWeight: '600',
								borderRadius: `0px 0px ${dim._4px} ${dim._4px}`
							}}
							items={{ label: 'Total Earning', amount: totalEarning }}
							handleEditClick={handleEditClick}
							viewMode={true}
						/>
					) : (
						<ReadOnlyRow
							style={{
								background: ' #e5e5e5',
								fontWeight: '600',
								borderRadius: `0px 0px ${dim._4px} ${dim._4px}`
							}}
							items={{ label: 'Total Deduction', amount: totalDeduction }}
							handleEditClick={handleEditClick}
							viewMode={true}
						/>
					)}

					{!viewMode && (
						<div className='addMoreBtn'>
							<TextButton onClick={handleAddMoreClick}>
								+ Add More{' '}
								{totalEarning >= 0
									? 'Salary Component '
									: 'Deduction Component '}
							</TextButton>
							{/* <Button
								style={{ width: dim._scale(290), padding: dim._6px }}
								onButtonClick={handleAddMoreClick}
							>
								+ Add More{' '}
								{totalEarning >= 0
									? 'Salary Component '
									: 'Deduction Component '}
							</Button> */}
						</div>
					)}
				</>
			)}
		</StyledReadPayslip>
	);
};

export default ReadPayslip;
