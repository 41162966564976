import React, { useEffect, useState } from "react";
import { IncomeStyle } from "./Income.styled";
import Salary from "./MainComponent/Salary";
import IncomeCategories from "./IncomeCategories";
import SalarySidebar from "./RightSidebar/SalarySidebar";
import CapitalGain from "./MainComponent/CapitalGain/index";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Business from "./MainComponent/Business";
import Crypto from "./MainComponent/Crypto";
import HouseRent from "./MainComponent/HouseRent/index";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import SuperSaverIncomeService from "../../Services/SuperSaverIncomeService";
import { dim } from "../../styles/theme";
import LayoutHOC from "../../components/UserSideBar";
import ClientProfile from "../AssignedUsers/components/ClientProfile";

const IncomeNew = () => {
  const navigate = useNavigate();
  const param = useParams();
  const { state, pathname } = useLocation();
  const [userId, setUserId] = useState(state.user_id);
  const categoryId = param.id && param.id.toUpperCase().replaceAll("-", "_");
  const [activeCategory, setActiveCategory] = useState(categoryId);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const fy = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR);
  const [selectedCompanyData, setSelectedCompanyData] = useState();
  const [allIncomeDetails, setAllIncomeDetails] = useState({
    salary: {},
    capitalGain: {},
    business: {},
    crypto: {},
    houseRent: {},
  });

  useEffect(() => {
    setUserId(state.user_id);
  }, [state]);

  const __handleIncomeCategory = (e) => {
    const { id } = e.target;
    setActiveCategory(id);
  };

  useEffect(() => {
    if (categoryId !== undefined) {
      setActiveCategory(categoryId);
    } else {
      setActiveCategory("SALARY");
    }
  }, [categoryId]);

  const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
  
  useEffect(() => {
    (async () => {
      const category = categoryId !== undefined ? categoryId : "SALARY";

      const allIncomeData = await  SuperSaverIncomeService.getIncomeRevampData(
        category,
        userId,
        fyId
      );

      if (categoryId === "SALARY" || categoryId === undefined) {
        setAllIncomeDetails({
          ...allIncomeDetails,
          salary: allIncomeData.data,
        });
      } else if (categoryId === "CAPITAL_GAIN") {
        setAllIncomeDetails({
          ...allIncomeDetails,
          capitalGain: allIncomeData.data,
        });
      } else if (categoryId === "BUSINESS_AND_PROFESSION") {
        setAllIncomeDetails({
          ...allIncomeDetails,
          business: allIncomeData.data,
        });
      } else if (categoryId === "DIGITAL_ASSET") {
        setAllIncomeDetails({
          ...allIncomeDetails,
          crypto: allIncomeData.data,
        });
      } else if (categoryId === "INCOME_FROM_HOUSE_RENT") {
        setAllIncomeDetails({
          ...allIncomeDetails,
          houseRent: allIncomeData.data,
        });
      }
    })();
  }, [categoryId, userId],fyId);

  useEffect(() => {
    state && state.external_id
      ? setSelectedCompanyId(state && state.external_id)
      : setSelectedCompanyId(
          allIncomeDetails.salary.income_details &&
            allIncomeDetails.salary.income_details.salary_income_dto_list.filter(
              (companies, i) => companies.is_current_company
            )[0] &&
            allIncomeDetails.salary.income_details.salary_income_dto_list.filter(
              (companies, i) => companies.is_current_company
            )[0].external_id
        );
  }, [allIncomeDetails, state]);

  useEffect(() => {
    setSelectedCompanyData(
      allIncomeDetails.salary.income_details &&
        allIncomeDetails.salary.income_details.salary_income_dto_list.filter(
          (companies, i) => companies.external_id === selectedCompanyId
        )[0]
    );
  }, [selectedCompanyId, allIncomeDetails]);

  const __handleCompanySelect = (e) => {
    const { id } = e.currentTarget;
    setSelectedCompanyId(id);
  };


  const returnIncomeScreen = () => {
    const id = categoryId !== undefined ? categoryId : activeCategory;
    switch (id) {
      case "SALARY":
        return (
          <Salary
            selectedCompanyId={selectedCompanyId}
            selectedCompanyData={selectedCompanyData}
            user={state}
          />
        );
      case "CAPITAL_GAIN":
        return (
          <CapitalGain
            capitalGainData={allIncomeDetails.capitalGain}
            allIncomeDetails={allIncomeDetails}
          />
        );
      case "BUSINESS_AND_PROFESSION":
        return <Business businessData={allIncomeDetails.business} />;
      case "DIGITAL_ASSET":
        return <Crypto cryptoData={allIncomeDetails.crypto} />;
      case "INCOME_FROM_HOUSE_RENT":
        return <HouseRent houseRentData={allIncomeDetails.houseRent} />;
      default:
        return <></>;
    }
  };

  const returnIncomeSidebar = () => {
    const id = categoryId !== undefined ? categoryId : activeCategory;
    // eslint-disable-next-line default-case
    switch (id) {
      case "SALARY":
        return (
          <SalarySidebar
            allData={allIncomeDetails.salary}
            selectedCompanyId={selectedCompanyId}
            handleCompanySelect={__handleCompanySelect}
          />
        );
    }
  };

  
  return (
    <IncomeStyle>
      <div className="main">
        <ClientProfile userDetails={state} view={"Income View"} />
        <div className="heading">
          Income Details <strong>({fy})</strong>
        </div>
        <IncomeCategories
          activeCategory={activeCategory}
          handleIncomeCategory={__handleIncomeCategory}
          data={state}
        />
        {returnIncomeScreen()}
      </div>
      <div className="rightSidebar">{returnIncomeSidebar()}</div>
    </IncomeStyle>
  );
};

export default LayoutHOC(IncomeNew);
