import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledInprogressSessionList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${dim._16px};
  padding: ${dim._24px} 0;
`;
