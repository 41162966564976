import React, { useEffect, useState } from 'react'
import NotesGrid from '../NotesGrid'

import { StyledNotesPage } from './StyledNotesPage';
import { useSearchParams } from 'react-router-dom';
import { AdvisorNotesService } from '../../../../../../Services/AdvisorNotesService';
import toast from 'react-hot-toast';
import { Backdrop, CircularProgress } from '@mui/material';
import LayoutHOC from '../../../../../../components/UserSideBar';

function NotesPage() {
    const [searchParams] = useSearchParams();
    const userId = searchParams.get("user_id");
    const [notes,setNotes] = useState([]);
    const [loading,setLoading] = useState(false);

    const getUserNotes =  async () => {
      try{
        setLoading(true);
        const notes = await  AdvisorNotesService.getNotesByUserId(userId);
        setNotes(notes?.data?.notes || []);
      }catch(e){
        toast.error("Something went wrong, please try again");
      }
      finally{
        setLoading(false)
      }
    }
    useEffect(()=>{
      getUserNotes()
    },[])
  return (
    <StyledNotesPage>
      <div className='notes-page'>
        <p className="heading">Notes</p>
        {notes?.length > 0 ? (
          <div>
            <NotesGrid notes={notes} />
          </div>
        ) : (
          <p>NO NOTES AVAILABLE FOR THIS USER</p>
        )}
         <Backdrop 
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </StyledNotesPage>
  );
}

export default LayoutHOC(NotesPage)
