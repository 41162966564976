import React from "react";
import Preview from "../Preview";
import { HeaderStyled } from "./HeaderStyled";

const Header = ({
  tabsData,
  selectedTab,
  handleSelectTab,
  handleSelectedChat,
  selectedChat,
  SessionDetails,
}) => {
  return (
    <HeaderStyled>
      <div className="tabs">
        {tabsData?.map((tabItems) => (
          <li
            className={selectedTab === tabItems?.id ? "active" : null}
            id={tabItems?.id}
            key={tabItems?.id}
            onClick={handleSelectTab}
          >
            {tabItems?.name}
          </li>
        ))}
      </div>
      <div className="all_conversation">
        <h2>All Conversation </h2>
      </div>
      <Preview
        SessionDetails={SessionDetails}
        selectedChat={selectedChat}
        handleSelectedChat={handleSelectedChat}
      />
    </HeaderStyled>
  );
};

export default Header;
