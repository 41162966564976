import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import ArticleEditor from '../../../container/ArticleEditor'
import Articles from '../../../container/articles'

const AuthorRoutes = () => {
  return (
    <Routes>
    <Route path="/editor" element={<ArticleEditor />} />
    <Route path="/articles/editor/:id" element={<ArticleEditor />} />
    <Route path="/articles/:id" element={<Articles />} />
    <Route
      path="/"
      element={<Navigate to="/articles/individual-taxation" />}
    />
  </Routes>
  )
}

export default AuthorRoutes