import React from 'react';
import styled from 'styled-components';

const OutlinedButton = ({ children, onClick, disabled, style = {} }) => {
	return (
		<StyledButton disabled={disabled} style={style} onClick={onClick}>
			{children}
		</StyledButton>
	);
};

export default OutlinedButton;

const StyledButton = styled.button`
	border: 1px solid ${({ disabled }) => (disabled ? '#00000033' : '#15be53')};
	padding: 8px 12px;
	border-radius: 4px;
	background-color: #fff;
	font-weight: bold;
	color: ${({ disabled }) => (disabled ? '#00000033' : '#15be53')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	&:hover {
		background-color: ${({ disabled }) =>
			disabled ? '#00000018' : '#15be5318'};
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	}
`;
