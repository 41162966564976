import styled from "styled-components";
import TimePicker from "./TimePicker";

const StyledTimePicker = styled(TimePicker)`
    height: 30px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    & .rc-time-picker-panel-select-option-selected {
        background-color: #edeffe;
        font-weight: normal;
    }

    & .rc-time-picker-clear,
    & .rc-time-picker-clear-icon:after {
        font-size: 15px;
        position: absolute;
        right: 40px;
    }

    & .rc-time-picker-panel-select,
    & .rc-time-picker-input,
    & .rc-time-picker-panel-input {
        font-family: "Consolas", sans-serif;
        font-size: 16px;

        cursor: pointer;

        ::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .rc-time-picker-input {
        width: 160px !important;
        padding: 10px;
    }
`;

export default StyledTimePicker;
