import styled from 'styled-components';
import { dim } from '../../../../../styles/theme';

export const EditableRowStyle = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	min-height: auto;
	height: auto;
	margin: 0 0 2px 0;
	padding: 10px 20px;
	background: #fff;
	box-sizing: border-box;

	.parentPos {
		position: relative;
	}

	.icon {
		border-radius: 50%;
		position: absolute;
		border: none;
		color: #fff;
		padding: ${dim._4px};
		font-size: ${dim._18px};
		top: calc(50% - ${dim._scale(9)});
		box-sizing: border-box;
	}
	.submit {
		background: rgba(90, 250, 146, 0.8);
		margin: 0 ${dim._30px} 0 0;
		right: -${dim._20px};
		opacity: 1;
		cursor: pointer;
	}
	.cross {
		right: -${dim._16px};
	}

	.rowData {
		width: 12.5%;
		height: auto;
		padding: 0 6px 0 0px;
		font-size: 12px;
		box-sizing: border-box;
	}

	.tooltip {
		position: absolute;
		bottom: 20px;
		right: -30px;
		transform: translateX(-50%);
		font-size: 12px;
		color: #212121;
		padding: 8px;
		border-radius: 4px;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		pointer-events: none;
		white-space: nowrap;
	}

	.cancel:hover .tooltip {
		opacity: 1;
	}
`;
