import styled from "styled-components";

export const DocumentDropdownWrapper = styled.div`
  .label {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    padding-bottom: 8px;
  }
`;
