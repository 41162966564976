import React from "react";
import {extractContent} from "../../utils/textUtil";
import "./button.css";
import ButtonStyle from "./button.styled";

const Button = (props) => {

  const {
    buttonName,
    onButtonClick,
    buttonClassName,
    style,
    isEnabled,
    id,
    children,
  } = props;
  
  return (
    <button
      id={id}
      className={`btn ${buttonClassName}`}
      onClick={onButtonClick ? onButtonClick : null}
      disabled={props.onDisable}
      style={style}
    >
      {extractContent(buttonName || children)}
    </button>
  );
};
export default Button;

export const CustomizedButton = ({
  children,
  disabled,
  onClick,
  style,
  className,
  hover = true,
}) => {
  return (
    <ButtonStyle
      style={{...style}}
      className={className}
      hover={hover}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ButtonStyle>
  );
};
