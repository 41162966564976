import React from "react";
import { StyledRadioComponent } from "./RadioComponent.styled";
import FormErrorMsgHandler from "../../../../commonComponents/FormErrormsgHandler";
import { ERROR_MESSAGE } from "../../constant";
import { dim } from "../../../../styles/theme";

const RadioComponent = ({
  title,
  radioArrData,
  componentCategory,
  handleRadioChange,
  isError,
}) => {
  return (
    <StyledRadioComponent>
      <div className="title">{title}</div>
      <div className="radioModel">
        {radioArrData?.map((data, index) => {
          return (
            <label className="container" key={index}>
              {data.text}
              <input
                type="radio"
                value={data.text}
                checked={data.isSelected}
                onChange={() => {
                  handleRadioChange(index);
                }}
              />
              <span className="checkmark"></span>
            </label>
          );
        })}
        {isError?.error && isError.errorArr.includes(componentCategory) && (
          <FormErrorMsgHandler style={{ bottom: "-32px" }}>
            {" "}
            {ERROR_MESSAGE[componentCategory]}
          </FormErrorMsgHandler>
        )}
      </div>
    </StyledRadioComponent>
  );
};

export default RadioComponent;
