import React from "react";
// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import "./uploadFile.css";
import CancelIcon from "@mui/icons-material/Cancel";

const UploadFile = ({
  fileId,
  acceptFileType,
  labelName,
  uploaded,
  uploadLink,
  // uploadStyle,
  onFileUpload,
  cancelUpload,
  uploadClassName,
  icon,
  supersaver,
}) => {
  return (
    <form
      className={`uploadFile ${uploadClassName}`}
      // className={"uploadFile" + uploadClassName }
      // style={uploadStyle}
    >
      {!uploaded ? (
        <div className="uploadField">
          <input
            type="file"
            id={fileId}
            accept={acceptFileType}
            onChange={onFileUpload}
          />
          <label htmlFor={fileId}>
            <div className="icon">{icon}</div>
            {/* <img
                            id="target"
                            src={uploadLink}
                            width='100%'
                            height='100%'
                            alt=""
                        />
                        <div className="cancel">
                            <CancelIcon
                                onClick={cancelUpload}
                                style={{
                                    fontSize: 30
                                }}
                            /> </div> */}
          </label>
          {/* {!labelName === ""? null : <h3>{labelName}</h3>} */}

          {!labelName ? "" : <h3>{labelName}</h3>}
        </div>
      ) : (
        <div className="afterUpload">
          {supersaver ? (
            <img
              id="target"
              src="https://prosperr-images.s3.ap-south-1.amazonaws.com/tick_icon.png"
              alt=""
              style={{
                width: "auto",
                height: "100%",
                marginLeft: "86px",
              }}
            />
          ) : (
            <img
              id="target"
              src={uploadLink}
              width="100%"
              height="100%"
              alt=""
            />
          )}
          <div className="cancel">
            <CancelIcon
              onClick={cancelUpload}
              style={{
                fontSize: 30,
              }}
            />
          </div>
        </div>
      )}

      {/* {
                    uploadImage && (
                        <CancelIcon onClick={() => setUploadImage(null)}
                            style={{
                                fontSize: 30
                            }}
                        />
                    )
                } */}
    </form>
  );
};
export default UploadFile;
