import styled from "styled-components";

export const RequestPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  min-height: 432px;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 12px 0;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
  }

  .head h1 {
    color: #212f45;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .head img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px 0 12px 0;
  }

  .comments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  .comments > p,
  .dropdown p {
    color: #323232;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .count_desc {
    font-size: 10px;
    position: absolute;
    right: 6px;
    color: #212f45;
    font-weight: 500;
    bottom: -16px;
  }

  .doc_preview {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    margin: 24px 0;
    height: 180px;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .submit_btn {
    margin-top: auto;
  }
`;

export const AddAnotherDoc = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 24px 0 0 0;
  cursor: ${(props) => (!props.isDisabled ? "pointer" : "not-allowed")};
  transition: cursor 0.3s ease-in-out, transform 0.3s ease-in-out;

 

  span {
    color: ${(props) => (!props.isDisabled ? "#15be53" : "#cccccc")};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  &:hover {
    transform: ${(props) => (!props.isDisabled ? "scale(1.01)" : "none")};
    
  }
`;
