import styled from 'styled-components';
import { dim } from '../../../../../../../styles/theme';

export const ReadOnlyRowStyle = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	min-height: auto;
	position: relative;
	height: auto;
	margin: 0;
	padding: ${dim._10px} ${dim._20px};
	background: #fff;
	border-bottom: ${dim._1px} solid #e5e5e5;
	box-sizing: border-box;

	.parentPos {
		position: relative;
	}

	div {
		width: 50%;
		font-size: ${dim._12px};
	}
	.editBtn {
		position: absolute;
		right: ${dim._36px};
	}
	.deleteBtn {
		position: absolute;
		right: 0;
	}
`;
