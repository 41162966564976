import React, { useEffect, useState } from "react";
import { StyledConsultationExperience } from "./ConsultationExperience.styled";
import ConsultationConfirmation from "./components/ConsultationConfirmation";
import ConsultationExtension from "./components/ConsultationExtension";
import ConsultationDocument from "./components/ConsultationDocument";
import RadioQuestionModel from "./components/RadioQuestionModel";
import ConsultationCommentModel from "./components/ConsultationCommentModel";
import ConsultationRatingModel from "./components/ConsultationRatingModel";
import ConsultationDataInput from "./components/ConsultationDataInput";
import ConsultationHistory from "./Screens/ConsultationHistory";
import LayoutHOC from "../../components/UserSideBar";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./store/ConsultationAction";
import { useNavigate, useParams } from "react-router";
import BasicCallDetailsForm from "./Models/BasicCallDetailsForm";
import CallActionForm from "./Models/CallActionForm";
import CallSummary from "./Models/CallSummary";
import ConsultationExperienceForm from "./Screens/ConsultationExperienceForm";
import { useHistory, useSearchParams } from "react-router-dom";
const ConsultationExperience = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const consultationData = useSelector(
    (state) => state.ConsultationReducer.consultationData
  );
  const params = useParams();
  const { userId } = params;

  // Access query parameters
  const nameParam = searchParams.get("user_id");
  const getConsultationData = () => {
    dispatch({
      type: actions.GET_CONSULTATION_DATA,
      data: {
        userId: userId ? userId : nameParam,
      },
    });
  };

  useEffect(() => {
    getConsultationData();
  }, [userId]);

  return (
    <StyledConsultationExperience>
      <ConsultationHistory consultationData={consultationData} />
    </StyledConsultationExperience>
  );
};

export default ConsultationExperience;
