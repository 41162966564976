import styled from 'styled-components';

export const RowStyle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 0 0 12px 0;
	box-sizing: border-box;

	.tableHead {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 0 0 0;
		/* border-bottom: 1px solid #e9eaec; */
		box-sizing: border-box;
	}

	.tableData {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 12px 0;
		border-bottom: 1px solid #e9eaec;
		box-sizing: border-box;
	}

	.col-1 {
		width: 40%;
	}

	.col-2 {
		width: 40%;
	}

	.col-3 {
		width: 20%;
	}

	.head,
	.data-1 {
		font-weight: 400;
		font-size: 12px;
		color: #212f45;
	}

	.data-2,
	.data-3 {
		font-weight: 600;
		font-size: 14px;
		color: #212f45;
	}

	div.tableData:last-child p {
		font-weight: 600;
	}

	div.tableData:last-child .data-2,
	div.tableData:last-child .data-3 {
		color: ${(props) => (props.total >= 0 ? '#15be53' : '#E2591F')};
		/* color: #15be53; */
	}
`;
