import styled from "styled-components";
import { dim } from "../../styles/theme";

export const ClientChatStyled = styled.div`
  display: flex;
  height: calc(100vh - ${dim._92px}); 
  width: 100%;
  background-color: #e9f0fb;
  top: 68px;
  overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  overscroll-behavior: unset !important;
  scroll-behavior: unset !important;
  position: sticky;
  box-sizing: border-box;

  .chat_conversation_info {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    padding: 0;
    overflow: scroll;
    box-sizing: border-box;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh);
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;
