import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Row from "./Row";
import { SessionDocumentStyled } from "./SessionDocument.Styled";

const SessionDocument = ({ advisorId, name, userId }) => {
  const sessionMsg = useSelector((state) => state.chat.sessionMsg);
  const [attchments, setAttchments] = useState([]);

  const getAllAttachment = (defaultArr) => {
    let arr = [];

    for (let index = 0; index < defaultArr.length; index++) {
      const element = defaultArr[index];
      let newArr = [];

      for (let i = 0; i < element.message.attachments.length; i++) {
        const attachmentElement = element.message.attachments[i];
        newArr = [
          ...newArr,
          { ...attachmentElement, sender_id: defaultArr[index].sender_id },
        ];
      }

      arr = [...arr, ...newArr];
    }
    return arr;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const arr = sessionMsg && getAllAttachment(sessionMsg);

    setAttchments(arr);
  }, [sessionMsg]);
  return (
    <SessionDocumentStyled>
      {
        <div className="head">
          <p className="c1">Document Name</p>
          <p className="c3">Uploaded By</p>
          <p className="c3">document</p>
        </div>
      }
      {attchments.map((data, i) => (
        <Row
          key={i}
          data={data}
          advisorId={advisorId}
          name={name}
          userId={userId}
        />
      ))}

      {attchments.length === 0 && <div className="">No data available</div>}
    </SessionDocumentStyled>
  );
};

export default SessionDocument;
