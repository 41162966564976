import React from "react";
import { StyledFormErrorMsgHandler } from "./FormErrormsgHandler.styled";

const FormErrorMsgHandler = ({ children, style, className }) => {
  return (
    <StyledFormErrorMsgHandler className={className} style={style}>
      {children}
    </StyledFormErrorMsgHandler>
  );
};

export default FormErrorMsgHandler;
