import React from "react";
import styled from "styled-components";

const InputBoxWrapper = styled.textarea`
  width: 100%;
  min-height: 60px;
  resize: none;
  border-radius: 8px;
  border: 1px solid #cdd5df;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #465668;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const InputBox = ({ handleDocDescription, value, maxlength, placeholder }) => {
  return (
    <InputBoxWrapper
      placeholder={placeholder}
      value={value}
      maxLength={maxlength}
      onChange={(e) => handleDocDescription(e.target.value)}
    />
  );
};

export default InputBox;
