import React from "react";
import { StyledButtonWithUnderline } from "./ButtonWithUnderline.styled";

const ButtonWithUnderline = ({ children, onClick, style, className }) => {
  return (
    <StyledButtonWithUnderline
      onClick={onClick}
      style={style}
      className={className}
    >
      {children}
    </StyledButtonWithUnderline>
  );
};

export default ButtonWithUnderline;
