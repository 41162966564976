import styled from 'styled-components';
import { dim } from '../../../../../styles/theme';


export const StyledMobileAuthorDetails = styled.div`
{
	margin-top: ${dim._24px};
	border-top: 1px solid #bbbbbb;
	border-bottom: 1px solid #bbbbbb;


	.mobile-outer-wrapper {
		padding: ${dim._19px} ${dim._32px} ${dim._28px} ${dim._32px};
	}
	
	.mobile-authorImage {
		width: ${dim._70px};
		object-fit: cover;
	}

	.mobile-author-image-container {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: ${dim._40px};
		height: ${dim._40px};
		overflow: hidden;

		margin-top: ${dim._6px};
	}
	.mobile-first-div {
		display: flex;
		gap: ${dim._10px};
	}
	.mobile-authorName {
		font-size: ${dim._14px};
		font-weight: 700;
		line-height: ${dim._16px};
		text-align: left;
		color: #212121;
	}
	.mobile-auth {
		font-size: ${dim._14px};
		font-weight: 400;
		line-height: ${dim._24px};
		color: #555770;
	}
	.mobile-nameOfAuhtor {
		font-size: ${dim._14px};
		font-weight: 400;
		line-height: ${dim._24px};
		color: #555770;
	}
	.mobile-authorDetails {
		display: flex;
		align-items: center;
		gap: ${dim._8px};
	}
	.linkd {
		height: ${dim._24px};
		width: ${dim._24px};
	}
	.mobile-author-brief {
		font-size: ${dim._12px};
		font-weight: 400;
		line-height: ${dim._16px};
		color: #212121;
		margin-top: ${dim._14px} !important;
	}
	.mobile-tags-container {
		display: flex;
		flex-wrap: wrap;
		gap: ${dim._8px};
		margin-top: ${dim._18px};
	}
	.mobile-tag {
		text-align: center;
		box-sizing: border-box;
		background-color: #e0ecff;
		padding: ${dim._4px} ${dim._8px};
		width: ${dim._130px};
		color: #51556c;
		font-size: ${dim._12px};
		font-weight: 500;
		line-height: ${dim._20px};
		border-radius: ${dim._4px};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.mobile-outLink {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.mobile-author-detail-prosperr {
		font-size: ${dim._14px};
		font-weight: 600;
		line-height: ${dim._14px};
		color: #212121;
		padding-left:${dim._8px};
		padding-right:${dim._10px};
	}

}
`;