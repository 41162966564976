import React from 'react';
import { StyledPlanCard } from './PlanCard.styled';

import { useNavigate } from 'react-router';

const PlanCard = ({
	isActive,
	item,
	isAdvancePlan = false,
	isITR = false,handleUpdatePlan,
	isSSPlanPage = false
}) => {
	const { name, plan, category_type, path,category_id, discounted_price } = item;

	const navigate = useNavigate();

	const ElitePlanCard = () => {
		return (
			<div className='eliteWrapper'>
				<img
					className='eliteIcon'
					src={process.env.PUBLIC_URL + '/assets/icons/elitePlanCardIcon.svg'}
					alt='eliteIcon'
				/>
				<p className='eliteHeader'>Super Saver Elite Plan</p>
				<p className='eliteSubHeader'>
					Exclusive tax solutions for
					<br /> CXOs, VPs, & Directors.
				</p>
			</div>
		);
	};

	return (
		<StyledPlanCard
			active={isActive}
			isAdvancePlan={isAdvancePlan}
			isITR={isITR}
			bgImage={
				name === 'Elite Plan' &&
				process.env.PUBLIC_URL + '/assets/images/EliteCardBg.svg'
			}
			isSSPlanPage={isSSPlanPage}
		>
			{!isITR ? (
				<div className='planInfo'>
					{name === 'Elite Plan' ? null : (
						<>
							{name === 'Advanced Plan' && isSSPlanPage ? (
								<img
									src={
										process.env.PUBLIC_URL + '/assets/icons/advanceIconDark.svg'
									}
									alt=''
									className='planIcon'
								/>
							) : (
								<img
									src={
										process.env.PUBLIC_URL +
										`/assets/icons/` +
										(category_type !== 'PREMIUM'
											? category_type?.toLowerCase() || 'basic'
											: 'premiumIcon') +
										'.svg'
									}
									alt=''
									className='planIcon'
								/>
							)}
							<div className='planTitle'>{name}</div>
						</>
					)}
				</div>
			) : (
				<div className='planInfo planInfoForITR'>
					<div className='planTitle'>{plan}</div>
					<div className='planAmount'>
						{discounted_price.toLocaleString('en-IN', {
							style: 'currency',
							currency: 'INR',
							maximumFractionDigits: 0,
							minimumFractionDigits: 0
						})}
					</div>
				</div>
			)}

			<div className='planPoints'>
				{name === 'Elite Plan' ? (
					<ElitePlanCard />
				) : (
					(item?.service_details || item.serviceOffering)
						.sort(function (a, b) {
							return a.sequence - b.sequence;
						})
						.map((details, i) => (
							<div className='standards' key={i} id={i}>
								<div className='tick'>
							
									<img
										src={
											process.env.PUBLIC_URL +
											'/assets/icons/superSaver_tick.svg'
										}
										style={{ width: 14, height: 14 }}
										alt={details.name}
										loading='lazy'
									/>
								
								</div>
								<p>{details.name || details} </p>
							</div>
						))
				)}
			</div>
			<div
				className='knowButton'
				onClick={() => {
					handleUpdatePlan(category_id)
				}}
			>
			Upgrade Plan &#8594;
			</div>
		</StyledPlanCard>
	);
};

export default PlanCard;


