import styled from 'styled-components';
import { dim } from '../../../../../../styles/theme';

const StyledPayslipUpload = styled.div`
	.heading {
		font-weight: 600;
		font-size: ${dim._18px};

		line-height: ${dim._24px};
		padding-bottom: ${dim._8px};
	}
	.Salary {
		margin: 0;
		color: #222;
	}
	.documentNameElip {
		width: ${dim._scale(130)};
		overflow: hidden;
		display: inline-block;
		position: relative;
		white-space: nowrap;
		text-decoration: none;
		text-overflow: ellipsis;
	}
	.subheading {
		font-weight: 400;
		font-size: ${dim._12px};
		line-height: ${dim._16px};
		padding-bottom: ${dim._48px};
		color: #425466;
	}
	.uploadIcon {
		gap: ${dim._14px};
		width: 100%;
		height: ${dim._64px};
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ffffff;
		border: 2px dashed #635bef;
		border-radius: 4px;
		box-sizing: border-box;
		opacity: 1;
		cursor: pointer;
	}
	.uploadSuccess {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: ${dim._14px};
		width: 100%;

		height: ${dim._64px};
		position: relative;
		border-radius: ${dim._4px};
		border: ${dim._2px} dashed #89b2ff;
		box-sizing: border-box;
	}

	.uploadSuccess p {
		display: flex;
		gap: ${dim._12px};
		align-items: center;
		font-style: normal;
		font-weight: 400;
		font-size: ${dim._14px};
		line-height: ${dim._26px};
		text-align: center;
		color: #212f45;
	}

	.uploadModal {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.icon {
		margin: ${dim._16px} 0 ${dim._12px};
		width: ${dim._26px};
		box-sizing: border-box;
	}
	.iconDesc {
		font-weight: 400;
		font-size: ${dim._12px};
		line-height: ${dim._16px};

		text-align: center;
		color: #212f45;
		box-sizing: border-box;
	}
	.comment {
		display: flex;
		flex-direction: column;
		padding: ${dim._24px} 0;
		gap: ${dim._8px};
		label {
			font-weight: 300;
			font-size: ${dim._12px};
			line-height: ${dim._16px};

			color: #212f45;
		}
		textarea {
			width: 100%;
			height: ${dim._64px};
			padding: ${dim._8px} ${dim._16px};
			background: #f1f5f7;
			border-radius: ${dim._4px};
			border: 0px;
			outline: none;
			font-weight: 400;
			font-size: ${dim._12px};
			line-height: ${dim._16px};

			color: #212f45;
			box-sizing: border-box;
		}
	}
	.clcBtn {
		font-weight: 600;
		font-size: ${dim._14px};
		line-height: ${dim._18px};
		cursor: pointer;
		display: flex;
		align-items: center;
		color: #212f45;
		width: ${dim._scale(94)};
		height: ${dim._52px};
		background: #ffffff;
		border: ${dim._1px} solid #bbbbbb;
		border-radius: ${dim._2px};
		padding: ${dim._16px} ${dim._24px};
		box-sizing: border-box;
		margin-right: ${dim._24px};
	}
	.btn {
		display: flex;
	}
	.btnHandler {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: ${dim._24px};
	}
	.inputUploadText {
		width: 100%;
		height: ${dim._36px};
		margin: 0;
		padding: ${dim._8px} ${dim._16px};
		border-radius: ${dim._4px};
		border: ${dim._1px} solid #f1f5f7;
		background: #f1f5f7;
		font-weight: 500;
		font-size: ${dim._14px};
		line-height: ${dim._19px};
		color: #212f45;
		box-sizing: border-box;
		&:focus {
			outline: none;
		}
	}
`;
export { StyledPayslipUpload };
