export const getContentTypeOnExt = (ext) => {
	switch (ext) {
		case 'png':
			return 'image/png';
		case 'pdf':
			return 'application/pdf';
		case 'jpeg':
		case 'jpg':
			return 'image/jpeg';

		default:
			break;
	}
};

export const modifySalaryComponentToRemoveUnusedData=(arr)=>{
const modifiedArr=	arr && arr.filter((d)=>{
 
        const componentType = d.type;
      return componentType !== 'TOTAL_EARNING' &&
      componentType !== 'TOTAL_DEDUCTION' &&d.amount>0
          })
		  return modifiedArr;
}