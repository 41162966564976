import React from "react";
import { StyledInprogressSessionList } from "./InprogressSessionList.styled";
import PreviousCallDetails from "../../components/PreviousCallDetails";
import NoCallView from "../../components/NoCallView";

const InprogressSessionList = ({ consultationData }) => {
  if (consultationData?.incoming_calls?.length <= 0) return <NoCallView />;
  return (
    <StyledInprogressSessionList>
      {consultationData?.incoming_calls?.map((incomingCalls, index) => (
        <PreviousCallDetails
          callDetails={incomingCalls}
          key={index}
          index={index}
          sessionsIndex={index}
        />
      ))}
    </StyledInprogressSessionList>
  );
};

export default InprogressSessionList;
