import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { internalChatData } from "../../constant";
import { AttachmentStyle } from "./UploadAttachment.styled";

const UploadAttachment = ({
  handleChangeattechment,
  attachmentInput,
  id = "attachmentUpload",
}) => {
  return (
    <AttachmentStyle>
      <input
        style={{ display: "none" }}
        type={"file"}
        onChange={handleChangeattechment}
        name=""
        value={attachmentInput}
        id={id}
        multiple
      />
      <label className="uploadIcon " htmlFor={id}>
        <Tooltip title="Attach File" arrow>
          <img src={internalChatData.attach_icon} alt="up" />
        </Tooltip>
      </label>
    </AttachmentStyle>
  );
};

export default UploadAttachment;
