import styled from "styled-components";
import { dim, theme } from "../../styles/theme";

export const StyledButtonWithUnderline = styled.div`
  width: auto;
  font-size: ${dim._14px};
  font-weight: 500;
  color: ${theme.color.green_20};
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
`;
