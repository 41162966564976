import React from "react";
import { ArticleViewWrapper } from "./ArticleView.styled";
import { useEffect } from "react";
import { CMSApis } from "../../../Services/cmsServices";
import { useState } from "react";
import toast from "react-hot-toast";
import { timeUtil } from "../../../utils/timeUtil";
import ConfirmationModal from "../ConfirmationModal";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import MobilePreview from "./component/MobilePreview";
import DesktopPreview from "./component/DesktopPreview";
import { Loader2 } from "../../../commonComponents/Loader";

const ArticleView = ({
  article,
  setShowPreview,
  authors,
  setOuterContent = () => {},
  updated,
  setUpdated,
  saveDraft,
  setNextToken = () => {},
  nextToken = 0,
  isEdit,
}) => {
  const { pathname } = useLocation();
  const [content, setContent] = useState({});
  const [filteredHeadings, setFilteredHeadings] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [text, setText] = useState("");
  const jsonUser = localStorage.getItem("user");
  const user = JSON.parse(jsonUser);
  const [newAuthor, setNewAuthor] = useState({});
  const [showMobileView, setShowMobileView] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const NewAuthor =
      (article &&
        authors?.filter((d) => {
          return (
            (article?.author_details?.name &&
              article?.author_details?.name === d?.user_details?.first_name) ||
            (article?.author &&
              article?.author[0]?.id === d?.user_details?.external_id)
          );
        })[0]) ||
      {};
    setNewAuthor(NewAuthor);
  }, [authors, article]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (article?.external_id) {
        let data = await CMSApis.getArticleById(article.external_id);
        setContent(article.payload ? article : data);
      } else {
        setContent(article);
      }
      setLoading(false);
    })();
  }, [article]);

  const { month, day, year } = timeUtil.getFormattedDateMonthYear(
    content?.published_on
  );
  const date = content?.published_on ? `${month} ${day}, ${year} ` : "";

  const updateArticleStatus = async (id, status) => {
    try {
      if (!id || isEdit) {
        try {
          await saveDraft("PUBLISHED");
          setShowPreview(false);
        } catch (error) {
          setShowPreview(false);
        }

        return null;
      }
      const published_by = user.displayName;
      let res = await CMSApis.updateArticleStatus(id, status, published_by);

      const articleFromEditor = { ...content, article_status: res.status };

      setOuterContent(articleFromEditor);
      localStorage.setItem("content", JSON.stringify(articleFromEditor));
      if (status === "PUBLISHED") {
        toast.success("Published Successfully");
      } else {
        toast.success("Unpublished Successfully");
      }
      if (nextToken == 10) {
        setNextToken(0);
      } else {
        if (nextToken != 0) {
          setNextToken(nextToken - 10);
        }
      }

      setShowPreview(false);
      setUpdated(!updated);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleCancle = () => {
    setShowPreview(false);
  };

  const shareArticle = [
    {
      img: "shareFbIcon.svg",
      label: "facebook",
    },
    {
      img: "shareTwitterIcon.svg",
      label: "twitter",
    },
    {
      img: "shareLinkedinIcon.svg",
      label: "linkedin",
    },
  ];

  const generateUniqueIds = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const headings = [];

    Array.from(doc.querySelectorAll("h1, h2, h3, h4, h5, h6")).map(
      (heading, index) => {
        const headerTag = heading.tagName.toLowerCase();
        const uniqueId = `${headerTag}-heading-${index}`;
        heading.id = uniqueId;
        headings.push({ id: uniqueId, text: heading.textContent });
      }
    );

    return { updatedPayload: doc.body.innerHTML, headings };
  };

  useEffect(() => {
    const { headings } = generateUniqueIds(content.payload);
    setFilteredHeadings(headings);
  }, [content.payload]);

  const handleChange = (event) => {
    setShowMobileView(event.target.checked);
  };

  return (
    <WrapperPreview>
      {loading ? (
        <div className="loader">
          <Loader2 />
        </div>
      ) : (
        <div className="preview-wrapper">
          <div className="inner-preview-wrapper">
            <ArticleViewWrapper>
              <div className="preview-text">
                <p className="preview">Preview Article</p>
                <span className="SwitchAndClose">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showMobileView}
                        onChange={handleChange}
                      />
                    }
                    label="Mobile View"
                  />
                  <img
                    onClick={() => handleCancle()}
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/close-icon.svg"
                    }
                    className="close"
                    alt="close"
                  />
                </span>
              </div>

              {showMobileView ? (
                <MobilePreview
                  article={article}
                  content={content}
                  authorData={newAuthor}
                />
              ) : (
                <DesktopPreview
                  article={article}
                  content={content}
                  filteredHeadings={filteredHeadings}
                  newAuthor={newAuthor}
                  pathname={pathname}
                />
              )}
              <div className="buttons">
                {content.article_status === "PUBLISHED" && !isEdit && (
                  <button
                    onClick={() => {
                      setConfirmationModal(true);
                      setText("Unpublish");
                    }}
                    className="publish-btn"
                  >
                    Unpublish
                  </button>
                )}

                {(content.article_status === "UNPUBLISHED" ||
                  content.article_status === "DRAFTED" ||
                  !content.article_status ||
                  isEdit) && (
                  <button
                    onClick={() => {
                      setConfirmationModal(true);
                      setText("Publish");
                    }}
                    className="publish-btn"
                  >
                    Publish
                  </button>
                )}

                <button className="cancel-btn" onClick={() => handleCancle()}>
                  Cancel
                </button>
              </div>
              <ConfirmationModal
                isOpen={confirmationModal}
                onClose={setConfirmationModal}
                onConfirm={updateArticleStatus}
                text={text}
                id={content?.external_id}
              />
            </ArticleViewWrapper>
          </div>
        </div>
      )}
    </WrapperPreview>
  );
};

export default ArticleView;

const WrapperPreview = styled.div`
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .preview-wrapper {
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .inner-preview-wrapper {
    width: 97%;
  }

  .cont {
    width: 97%;
  }
`;
