import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledConversationHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    background: #fff;
    padding: 6px 0 6px 4px;
    color: #212121;
    border-radius: 4px 4px 0 0;
    font-weight: 600;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 8px;
    margin: 8px 8px 4px 0px;

    text-transform: capitalize;
    .chat_number {
        background-color: #e5e5e5;
        border-radius: 6px;
        text-align: center;
        padding: 2px 6px;
        font-size: 10px;
        font-weight: 600;
    }
    .time {
        display: inline-block;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        margin-right: 8px;
    }
    .status {
        display: inline-block;
        top: 4px;
        right: 4px;
        font-size: 10px;

        color: ${(props) => (props.progress ? "#15be53" : " #ff0808b5")};
        text-align: center;
        border-radius: 4px;
        padding: 2px 4px;
    }
`;

export const DialogWrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .modal-main {
        background-color: white;
        padding: 32px;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mom {
        width: 300px;
        min-height: 160px;
        padding: 8px;
        box-sizing: border-box;
    }

    .feedback {
        width: 300px;
        min-height: 40px;
        padding: 8px;
        box-sizing: border-box;
        margin-top: 16px;
    }
`;
