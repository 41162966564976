export const ArticleData = {
  data: [
    {
      id: 1,
      type: "Article",
      title: "<p>EQUITY MUTUAL FUNDS - Direct Investments in Stocks</p>",
      summary:
        "Equity Mutual Fund is a kind of mutual fund that essentially invests in shares of listed companies of the exchange market. Different options available to invest in several kinds of equity mutual funds? Large-Cap Equity Mutual Funds. Mid-Cap Equity Mutual Funds. Small-Cap Equity Mutual Funds. Large & Mid-Cap Equity Mutual Funds. Multi-Cap Equity Mutual Funds. How to invest, Returns, and Risk in equity mutual funds.",
      payload:
        "payload Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.",
      articleReferences: "Bomby Stock Exchange",
      articleUniqueIdentifier: "abcd-xyzzz-fgf",
      articleTags: "Startup",
      articleAuthor: "Dev",
      articleThumbnailImage:
        "	https://www.prosperr.io/static/media/equity_mf.fcb284f5.png",
      articleImage: "articleImage.jpg",
      tags: ["Stocks","Mutual Funds"],references: ["vdbfgfbds", "fddgd", "dfgdgd", "fdgdg"]
    ,author_id:["Swarnim Mahajan"] ,article_unique_id:"article_unique_id"},

    {
      id: 1,
      type: "Blog",
      title: "<p>Corporate Bonds - No tax breaks on interest income</p>",
      summary:
        "A bond issued by a corporation; carries no claim to ownership and pays no dividends but payments to bondholders have priority over payments to stockholders. As we know Corporate bonds are issued by corporations, but what exactly they are? What is the maturity period for these bonds? Does bonds usually offer a higher yield than government bonds but carry more risk.? How to select the best bond to invest in?",
      payload:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.",
      articleReferences: "Bomby Stock Exchange",
      articleUniqueIdentifier: "abcd-xyzzz-fgf",
      articleTags: "Startup",
      articleAuthor: "Dev",
      articleThumbnailImage:
        "https://www.prosperr.io/assets/images/article_details_page.png",
      articleImage: "articleImage.jpg",
      tags: ["Bitcoin"],references: ["vdbfgfbds", "fddgd", "dfgdgd", "fdgdg"]
    ,author_id:["Swarnim Mahajan"],article_unique_id:"article_unique_id"},

    {
      id: 1,
      type: "Article",
      title: "<p>Lorem Cumque, laborum? Harum odio repellendus tempore?</p>",
      summary:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.",
      payload:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.",
      articleReferences: "Bomby Stock Exchange",
      articleUniqueIdentifier: "abcd-xyzzz-fgf",
      articleTags: "Startup",
      articleAuthor: "Dev",
      articleThumbnailImage:
        "https://www.cas.org/sites/default/files/inline-images/covid-vaccine-review-article0321-banner.jpg",
      articleImage: "articleImage.jpg",
      tags: ["Bitcoin"],references: ["vdbfgfbds", "fddgd", "dfgdgd", "fdgdg"]
    ,author_id:["Swarnim Mahajan"] ,article_unique_id:"article_unique_id"},
    {
      id: 1,
      type: "Blog",
      title: "<p>Lorem Cumque, laborum? Harum odio repellendus tempore?</p>",
      summary:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.",
      payload:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur et non beatae repellendus facilis dolorem odit modi quos optio quod,corrupti voluptas ea nulla voluptatum quam quae! Laborum, molestiae accusamus.",
      articleReferences: "Bomby Stock Exchange",
      articleUniqueIdentifier: "abcd-xyzzz-fgf",
      articleTags: "Startup",
      articleAuthor: "Dev",
      articleThumbnailImage:
        "https://www.cas.org/sites/default/files/inline-images/covid-vaccine-review-article0321-banner.jpg",
      articleImage: "articleImage.jpg",
      tags: ["Bitcoin"],references: ["vdbfgfbds", "fddgd", "dfgdgd", "fdgdg"]
    ,author_id:["Swarnim Mahajan"] ,article_unique_id:"article_unique_id"},
  ],
article_unique_id:"article_unique_id"};
