import React from 'react';
import { TableViewWrapper } from './index.styled';

const _headings = [
	{ value: 'Header1', sx: {} },
	{ value: 'Header2', sx: {} },
	{ value: 'Header2', sx: {} },
	{ value: 'Header3', sxOuter: { textAlign: 'center' } }
];

const _data = [
	[
		{ value: 'data 1', sx: {} },
		{ value: 'Data 2', sx: {} },
		{ value: 'Data 2', sx: {} },
		{
			value: 'Data 3',
			classOuter: '',
			sxOuter: { textAlign: 'center', color: 'green' },
			onClick: () => {
				window.alert('clicked');
			}
		}
	],
	[
		{ value: 'data 1', sx: {} },
		{ value: 'Data 2', sx: {} },
		{ value: 'Data 2', sx: {} },
		{
			value: 'Data 3',
			classOuter: '',
			sxOuter: { textAlign: 'center', color: 'green' },
			onClick: () => {
				window.alert('clicked');
			}
		}
	]
];

const TableView = ({
	headings = [..._headings],
	data = [..._data],
	style = {}
}) => {
	return (
		<TableViewWrapper cellSpacing={0} style={style}>
			<tbody>
				<tr className='table-heading'>
					{headings.map((it) => {
						return (
							<td className={it.classOuter} style={it.sxOuter} key={it.value}>
								{it.value}
							</td>
						);
					})}
				</tr>

				{data.map((_it, index) => {
	
					return (
						<tr style={{ border: 1 }} className='tr-data' key={index}>
							{_it.map((it, i) => {
								return (
									<td
										className={it.classOuter}
										style={it.sxOuter}
										key={it.value}
									>
										{it.value}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</TableViewWrapper>
	);
};

export default TableView;

export const Chip = ({ children }) => {
	return <p style={{ borderRadius: 8, backgroundColor: 'gray' }}>{children}</p>;
};
