import React, {useEffect, useState} from "react";
import {
  FilterContainer,
  TableWrapper,
} from "../../screens/ConsultationView/ConsultationView.styled";
import {Table, Input, DatePicker, Space, Radio} from "antd";
import {WIDGET_ACTION_TYPE, columns} from "../../constants";
const {Search} = Input;

export const ConsultationTableView = ({
  currentWidget,
  consultationTableData,
  setReqObj,
  reqObj,
  loading,
  getConsultationViewData,
}) => {
  const [radioValue, setRadioValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState(reqObj.client_name || "");
  const dateToTimestamp = (dateString) => {
    const [year, month, day] = dateString.split("-");
    const timestamp = new Date(year, month - 1, day).getTime();
    return timestamp;
  };

  const onRadioChange = (e) => {
    const newRadioValue = e.target.value;
    setRadioValue(newRadioValue);

    handleSearch({value: newRadioValue, key: "action_type"});
  };
  const onDateChange = (date, dateString) => {
    setReqObj((state) => {
      return {
        ...state,
        start_date: dateToTimestamp(dateString),
        end_date: dateToTimestamp(dateString) + 86400000,
      };
    });
  };

  useEffect(() => {
    setCurrentPage(1);
    setRadioValue("");
  }, [currentWidget]);
  const handleSearch = ({value, key = "client_name"}) => {
    setReqObj((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    setName(reqObj?.client_name);
  }, [reqObj?.client_name]);

  return (
    <TableWrapper>
      <FilterContainer>
        <div className="title">Filter</div>
        <Space direction="horizontal">
          <Search
            placeholder="Search by client name"
            value={name}
            onChange={(e) => {
              const value = e.target.value;
              setName(value);
            }}
            onSearch={(value) => {
              handleSearch({value});
            }}
            style={{width: 220}}
            allowClear
          />

          {currentWidget !== WIDGET_ACTION_TYPE.UPCOMING_CALLS && (
            <DatePicker
              onChange={onDateChange}
              placeholder="Select schedule date"
              style={{width: 220}}
            />
          )}
        </Space>

        {currentWidget === WIDGET_ACTION_TYPE.PENDING_ACTIONS && (
          <Radio.Group onChange={onRadioChange} value={radioValue}>
            <Radio value={""}>All</Radio>
            <Radio value={"PENDING_CALL"}>Call Marking Pending</Radio>
            <Radio value={"PENDING_MOM"}>MOM Pending</Radio>
          </Radio.Group>
        )}
      </FilterContainer>

      <Table
        dataSource={consultationTableData}
        columns={columns(currentWidget, getConsultationViewData, reqObj)}
        size="large"
        loading={loading}
        pagination={{
          position: ["bottomRight"],
          showSizeChanger: false,
          current: currentPage,
          defaultCurrent: 1,
          onChange: (page) => {
            setCurrentPage(page);
          },
        }}
      />
    </TableWrapper>
  );
};
