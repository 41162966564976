
import { Main } from "../../PopUpPayment/PopUpPayment.styled";
import { dim, theme } from "../../../styles/theme";
import styled from "styled-components";

export const StyledPlanValueForm=styled(Main)`


.input {
    width: 100%;
    padding-top:${dim._38px};
    box-sizing: border-box;
}


 .standard-basic {
    width: 100%;
    box-sizing: border-box;
}
.paymentWrapper {
    font-size:${dim._18px};
    color: ${theme.color.grey_100};
    gap: ${dim._20px};
    margin-top: ${dim._10px};
    white-space: nowrap;
}
`