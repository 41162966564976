import styled from "styled-components";
import { dim, theme } from "../../../../styles/theme";
// import { dim, theme } from '../../../../styles/theme';

export const StyledCancelCallPreview = styled.div`
  display: ${({ activateCancelPopUp }) => {
    return activateCancelPopUp ? "flex" : "none";
  }};
  flex-direction: column;
  width: 100%;
  max-width: ${dim._scale(300)};
  background-color: ${theme.color.white_0};
  border-radius: ${dim._4px};
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: ${dim._10px} ${dim._10px} ${dim._4px} lightblue;
  .popupTitle {
    padding: ${dim._16px} ${dim._30px};
    font-size: ${dim._14px};
    color: ${theme.color.black_20};
    font-weight: 500;
    text-align: center;
  }
  .buttons {
    width: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;
  }
  .crossBtn {
    position: absolute;
    right: -${dim._4px};
    top: -${dim._18px};
    color: ${theme.color.red_20};
    font-size: ${dim._24px};
    cursor: pointer;
  }
`;
