import styled from "styled-components";
export const StyledEducationInput = styled.div`
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
  .CTA {
    display: flex;
    justify-content: space-between;
  }
`;
