import React from "react";
import Styled from "styled-components";

const BackdropStyle = Styled.div`
   width: 100%;
    height: 100%;
    /* background: red; */
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9000;
    opacity: 0.7;
    background-color: #000;
    cursor: pointer;
`;

const Backdrop = (props) =>
  props.show ? (
    <BackdropStyle onClick={props.onHideModelClick}></BackdropStyle>
  ) : null;

export default Backdrop;
