export const convertToPascalCase = (text = "") => {
    return text.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
    });
};

export const removeExtraSpaces = (text = "") => {
    return text.replace(/\s+/g, "");
};

export const getWordFromDOM = (el) => {
    return el?.split(/\s+/)?.length - 1;
};

export const extractContent = (s) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
};
export const  replaceAndUppercase = (text) => {
    return text.replace(/-/g, '_').toUpperCase();
}

export function capitalizeFirstLetter(str='') {
    return (str||'')[0]?.toUpperCase() + str?.slice(1).toLowerCase();
  }

export function extractTextFromHtml(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}
  export const replaceUnderscoreOnly = (s) => {
	return s.replaceAll('_', ' ');
};
  