import React from "react";
import { ChatHeaderContainer } from "./ChatHeader.styled";
import { FaPlus } from "react-icons/fa6";
import { CustomizedButton } from "../../../../commonComponents/Button";

const ChatHeader = ({ onHideServiceRequestModal, handleShowCloseSession }) => {
  return (
    <ChatHeaderContainer>
      <div className="create_new_chat">
        <CustomizedButton
          style={{ width: "auto", borderRadius: "8px" }}
          onClick={onHideServiceRequestModal}
        >
          <FaPlus /> &nbsp; Start New Chat
        </CustomizedButton>
        <CustomizedButton
          style={{
            width: "auto",
            borderRadius: "8px",
            backgroundColor: "#DA5F55",
          }}
          onClick={handleShowCloseSession}
        >
          Close Session
        </CustomizedButton>
      </div>
    </ChatHeaderContainer>
  );
};

export default ChatHeader;
