import { TextField } from "@mui/material";
import { getAuth } from "firebase/auth";
import React from "react";
import FlexRow from "../../commonComponents/FlexRow/FlexRow";
import { updateTargetTax } from "../../Services/SuperSaver";
import { PopupTargetTaxStyled } from "./PopupTargetTax.Styled";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { CustomOption, colourStyles } from "../header";
const PopupTargetTax = ({
  user_id,
  handleCross,
  targeted_tax,
  handlesetTargetedtax,selectedFy,handleDropDownChange,targetedTaxExternalId
}) => {
  const username = JSON.parse(localStorage.getItem("user")).displayName;

  const FYOptions= useSelector(state=>state.LayoutReducer.F_Y_ARR)

  

  const handleYesBtn = () => {
    const req = {
      user_id: user_id,
      targeted_tax: targeted_tax,
      targeted_tax_uploaded_by: username,
      financial_year_id:selectedFy.value,
    };
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken()
          .then(function (data) {
            updateTargetTax(data, req);
          })
          .then(() => {
            handleCross();
          });
      }
    });
  };



  return (
    <PopupTargetTaxStyled
      disabled={
        targeted_tax !== "To Be Calculated." && targeted_tax.length > 2
          ? false
          : true
      }
    >
      <span className="closePop" onClick={() => handleCross()}>
        &times;
      </span>
      <div className="popContent">
        <b>Do you want to Proceed with this operation ? </b>
      </div>
      <div className="input">
        <TextField
          className="standard-basic"
          label="Target Tax"
          type="number"
          variant="standard"
          value={targeted_tax}
          onChange={(e) => {
            handlesetTargetedtax(e.target.value);
          }}
        />

<p>{
selectedFy.value && 
(
								<Select
									options={FYOptions}
									isSearchable={false}
									value={selectedFy}
                  styles={colourStyles}
									components={{ Option: CustomOption}}
									onChange={(value) => {
										handleDropDownChange(value);
									}}
									/>
									)} </p>
      </div>
      <div className="popBox">
        <div>
          <FlexRow>
            <span
              className="popButton yes"
              onClick={() => {
                targeted_tax !== "To Be Calculated." &&
                  targeted_tax.length > 2 &&
                  handleYesBtn();
              }}
            >
              Yes
            </span>
            <span className="popButton no" onClick={() => handleCross()}>
              No
            </span>
          </FlexRow>
        </div>
      </div>
    </PopupTargetTaxStyled>
  );
};

export default PopupTargetTax;
