import React, { useEffect, useState } from "react";
import { StyledTaskManager } from "./TaskManager.styled";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { getSuperSaverUser } from "../../Services/SuperSaver";
import { getAuthToken } from "../../Services/httpUtil";
import {
  columnsForAdvisor,
} from "../../utils/superSaverUser";
import ActionFilter from "../../components/ActionFilter";
import LoaderPage from "../../commonComponents/LoaderPage";
const call_status = {
  CALL_SUMMARY_PENDING: "CALL_SUMMARY_PENDING",
  TOMORROW_CALL: "TOMORROW_CALL",
  TODAY_CALL: "TODAY_CALL",
};
const tabList = [
  {
    label: "Action Item Pending ",
    value: "ACTION_ITEM_PENDING",
  },
  {
    label: "Pending MOM",
    value: call_status.CALL_SUMMARY_PENDING,
  },
  {
    label: "Today Calls",
    value: call_status.TODAY_CALL,
  },
  {
    label: "Tomorrow Calls",
    value: call_status.TOMORROW_CALL,
  },
];
const TaskManager = () => {
  const selectedView = localStorage.getItem(
    LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE
  );
  const [Data, setData] = useState([]);
  const [value, setValue] = React.useState(
    (tabList.some((d) => d.value === selectedView) &&
      localStorage.getItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE)) ||
      "ACTION_ITEM_PENDING"
  );
  const [loader, setLoader] = useState(true);
  const email = JSON.parse(localStorage.getItem("email"));
  const type = JSON.parse(localStorage.getItem("type"));
  const [actionSelected, setActionSelected] = useState(null);
  const handleOnCellClick = (params, navigate) => {
    const user_id = params?.row.user_id;
    navigate(`/consultation-experience?user_id=${user_id}`, {
      state: { item: params.row },
    });
  };

  const tabPanelList = [
    {
      value: "ACTION_ITEM_PENDING",
      data: Data,
      columns: columnsForAdvisor,
      handleOnCellClick: handleOnCellClick,
      rowFunction: rowFunction,
      enablePaging: false,
      pathname: "/",
    },

    {
      value: call_status.TODAY_CALL,
      data: Data,
      columns: columnsForAdvisor,
      handleOnCellClick: handleOnCellClick,
      rowFunction: rowFunction,
      enablePaging: false,
      pathname: "/",
    },
    {
      value: call_status.CALL_SUMMARY_PENDING,
      data: Data,
      columns: columnsForAdvisor,
      handleOnCellClick: handleOnCellClick,
      rowFunction: rowFunction,
      enablePaging: false,
      pathname: "/",
    },
    {
      value: call_status.TOMORROW_CALL,
      data: Data,
      columns: columnsForAdvisor,
      handleOnCellClick: handleOnCellClick,
      rowFunction: rowFunction,
      enablePaging: false,
      pathname: "/",
    },
  ];

  const handleChange = (event, newValue) => {
    localStorage.setItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE, newValue);
    setValue(newValue);
  };
  function rowFunction(value, index, array) {
    const { referral_code } = value;
    return {
      id: index,
      subscription_status: value?.subscription_status
        ? value?.subscription_status
        : "",
      referral_code: referral_code,
      name: value?.user_response?.first_name,
      service_type: value?.category_type,
      targetTax: value?.targeted_tax,
      super_saver_booking_id: value?.external_id,
      service_id: value?.category_id,
      email: value?.user_response?.email,
      invite_id: value?.invite_id,
      onboard_id: value?.onboard_id,
      user_id: value?.user_id,
      activity_details: value?.activity_details,
      invitation_status: value?.invitation_status,
      advisor: value?.advisor_details
        ? value?.advisor_details?.first_name +
          " " +
          value?.advisor_details?.last_name
        : "Not-Assigned",
      advisor_details: value?.advisor_details,
      appVersion: value?.app_version,
      appVersions:
        value?.meta_data_payload && value?.meta_data_payload?.app_version,
    };
  }

const getUserDataAccordingToUserType=(users)=>{
return   users?.sort((a, b) => b?.created_at - a?.created_at)?.filter((user, i) => {

    return user;

})
}

  const getUserList = async (param, filter) => {
    setLoader(true);
    const token = await getAuthToken();
    getSuperSaverUser(token, param, filter).then(function (users) {
      setData(
        getUserDataAccordingToUserType(users)
      );
      setLoader(false);
    });
  };
  useEffect(() => {
    getUserList(value !== "ALL" && value !== "Expired" ? value : undefined);
  }, [value]);


  return loader ? (
    <LoaderPage />
  ) : (
    <StyledTaskManager>
      <TabContext value={value} variant="fullWidth">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="lab API tabs example"
          >
            {tabList.map((d, i) => {
              return <Tab key={i} sx={{ width: 200 }} {...d} />;
            })}
          </TabList>
        </Box>
        {value === "ACTION_ITEM_PENDING" && (
          <ActionFilter
            actionSelected={actionSelected}
            handleSelect={(value) => {
              setActionSelected(value);
              getUserList("ACTION_ITEM_PENDING", value);
            }}
          />
        )}
        {tabPanelList.map((d, i) => {
          return (
            <TabPanel
              key={i}
              value={d.value}
              sx={{ width: "100%", padding: 0 }}
            >
              <div className="resultS">
                <CardAddSlot>
                  <BasicExampleDataGrid {...d} />
                </CardAddSlot>
              </div>
            </TabPanel>
          );
        })}
      </TabContext>
    </StyledTaskManager>
  );
};

export default TaskManager;
