import React, { useEffect, useState } from "react";
import { ITRDocumentsContainer } from "./ITRDocuments.styled";
import Button from "../../commonComponents/Button";
import TableHead from "./TableHead";
import TableRows from "./TableRows";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkPreviousFinancialYear } from "../../utils/FinancialYearUtils";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import ITRServices from "../../Services/ITRServices";
import { PreSignedUrl } from "../../Services/preSignedUrlUploadApi";
import axios from "axios";
import ConfirmationModal from "./Components/ConfirmationModal";
import Backdrop from "../../components/Backdrop";
import RejectPopup from "./Components/RejectPopup";
import CommonButton from "./Components/CommonButton";
import RequestPopup from "./Components/RequestPopup";
import { ITRDocumentsAction } from "./store/action";
import RequestedDoc from "./Components/RequestedDoc";
import toast from "react-hot-toast";
import LoaderPage from "../../commonComponents/LoaderPage";
import { FaUserCircle } from "react-icons/fa";
import ClientProfile from '../AssignedUsers/components/ClientProfile'
import LayoutHOC from "../../components/UserSideBar";

const fy = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR);
const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
const loginData = localStorage.getItem(LOCAL_DB_KEYS.USER);
const parsedLoginData = JSON.parse(loginData);
const userRole = parsedLoginData?.user_roll; 

const ITRDocuments = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [itrDocumentsData, setItrDocumentsData] = useState(null);
  const [loader, setLoader] = useState(true);

  const requestPopupStatus = useSelector(
    (state) => state.ITRDocumentsReducer.requestPopupStatus
  );
  const rejectPopupStatus = useSelector(
    (state) => state.ITRDocumentsReducer.rejectPopupStatus
  );

  const confirmPopupStatus = useSelector(
    (state) => state.ITRDocumentsReducer.confirmPopupStatus
  );

  const dispatch = useDispatch();

  const { financialYearList } = useSelector((state) => state.cmsReducer);
  useEffect(() => {
    if (!checkPreviousFinancialYear(fyId, financialYearList)) {
      const userId = state?.user_id;
      userRole === "ADVISOR"
        ? navigate(`/userProfileForAdvisor?user_id=${userId}`, {
            state: { ...state },
          })
        : navigate(`/superSaverUserProfile?user_id=${userId}`, {
            state: { ...state },
          });
    }
    (async () => {
      await fetchDetails();
    })();
  }, []);

  const fetchDetails = async () => {
    setLoader(true);
    try {
      const itrRes = await ITRServices.getITRDetails(state.user_id);
      setItrDocumentsData(itrRes.data);
    } catch (error) {
      toast.error("Error fetching ITR details:", error);
    } finally {
      setLoader(false);
    }
  };

  const downloadFile = async (url, fileType, name) => {
    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
    const idToken = localStorage.getItem(LOCAL_DB_KEYS.AUTH_TOKEN_LS);
    let bucketName = "";
    if (ENVIRONMENT === "PRODUCTION") {
      bucketName =
        itrDocumentsData && itrDocumentsData.itr_filing_version === "1.1"
          ? "prod-supersaverclientdocs"
          : "prod-itr-filing-docs";
    } else {
      bucketName =
        itrDocumentsData && itrDocumentsData.itr_filing_version === "1.1"
          ? "stage-supersaverclientdocs"
          : "stage-itr-filing-docs";
    }

    const newFile = fileNameFromLink(url);
    const urlNew = await PreSignedUrl.downloadPreSignedUrl(
      idToken,
      state.user_id,
      newFile,
      bucketName
    );

    function download(content, fileName, contentType) {
      const a = document.createElement("a");
      const file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }

    function onDownload(content) {
      download(JSON.stringify(content), name + ".json", "text/plain");
    }

    if (fileType == "json") {
      try {
        const getJson = async () => {
          const data = await axios(urlNew);
          onDownload(data?.data);
        };
        getJson();
      } catch (error) {}
    } else {
      const link = document.createElement("a");
      link.href = urlNew;
      link.target = "_blank";
      link.click();
    }
  };

  const fileNameFromLink = (link) => {
    if (itrDocumentsData && itrDocumentsData.itr_filing_version === "1.1") {
      const fileName = link.split("/");
      fileName.shift();
      const newVersionFile = fileName.join("/");
      return newVersionFile;
    } else {
      const fileName = link.split(".");
      const newFile =
        fileName[fileName.length - 2].split("/")[1] +
        "." +
        fileName[fileName.length - 1];

      return newFile;
    }
  };

  const handleDocVerification = async (document_id, comment, status) => {
    const payload = {
      user_id: state.user_id,
      document_id: document_id,
      status: status,
      verification_message: comment,
    };
    try {
      await ITRServices.verifyITRDocs(payload);
      toast.success(`Document ${status.toLowerCase()} successfully`);
      await fetchDetails();
    } catch (error) {
      toast.error("Oops! Something went wrong!");
    }
  };

  const handleConfirmRequest = async () => {
    const fyYear = localStorage
      .getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR)
      .split("-")[0];
    const payload = {
      user_id: state.user_id,
      financial_year: fyYear,
      action_items: confirmPopupStatus.data,
    };
    try {
      await ITRServices.requestDocument(payload);
      toast.success("Request submitted successfully");
    } catch (error) {
      toast.error("Oops! Something went wrong!");
    }
    dispatch({
      type: ITRDocumentsAction.CONFIRMATION_POPUP_STATUS,
      data: {
        active: false,
        data: [],
      },
    });
    fetchDetails();
  };

  const documentStatus = {
    VERIFIED: "VERIFIED",
    NOT_REQUIRED: "NOT_REQUIRED",
    REJECTED: "REJECTED",
    REQUESTED: "REQUESTED",
    IN_REVIEW: "IN_REVIEW",
    NOT_INITIATED: "NOT_INITIATED",
  };

  const handleAddNewDoc = () => {
    dispatch({
      type: ITRDocumentsAction.REQUEST_POPUP_STATUS,
      data: {
        active: true,
      },
    });
  };

  const handleCancelConfirmation = () => {
    dispatch({
      type: ITRDocumentsAction.CONFIRMATION_POPUP_STATUS,
      data: {
        active: false,
        data: [...confirmPopupStatus.data],
      },
    });
    dispatch({
      type: ITRDocumentsAction.REQUEST_POPUP_STATUS,
      data: {
        active: true,
      },
    });
  };

  return loader ? (
    <LoaderPage />
  ) : (
    <ITRDocumentsContainer>
      <ClientProfile  userDetails={state} view="ITR Filing"/>
      <div className="top_head">
        <h1>All Documents</h1>
        <CommonButton
          btnName={"+ Request Document"}
          handleClick={handleAddNewDoc}
        />
      </div>
      <div className="table">
        <TableHead />
        {itrDocumentsData?.form16_detail?.map((item, i) => {
          return (
            <TableRows
              key={i}
              docName="Form 16"
              docId={item?.document_id}
              docLink={item?.document_link}
              docStatus={item?.verification_status}
              fileName={item?.company_detail?.name}
              password={item?.document_password}
              downloadFile={downloadFile}
              handleDocVerification={handleDocVerification}
            />
          );
        })}
        {itrDocumentsData?.form26_as_detail?.document_link && (
          <TableRows
            docName="Form 26AS"
            docId={itrDocumentsData?.form26_as_detail?.document_id}
            docLink={itrDocumentsData?.form26_as_detail?.document_link}
            docStatus={itrDocumentsData?.form26_as_detail?.verification_status}
            password={itrDocumentsData?.form26_as_detail?.document_password}
            downloadFile={downloadFile}
            handleDocVerification={handleDocVerification}
          />
        )}
        {itrDocumentsData?.ais_detail &&
          itrDocumentsData?.ais_detail?.document_link && (
            <TableRows
              docName="FORM AIS"
              docId={itrDocumentsData?.ais_detail?.document_id}
              docLink={itrDocumentsData?.ais_detail?.document_link}
              docStatus={itrDocumentsData?.ais_detail?.verification_status}
              password={itrDocumentsData?.ais_detail?.document_password}
              downloadFile={downloadFile}
              handleDocVerification={handleDocVerification}
            />
          )}
        {itrDocumentsData?.prefilled_json_detail?.document_link && (
          <TableRows
            docName="PREFILLED JSON"
            docId={itrDocumentsData?.prefilled_json_detail?.document_id}
            docLink={itrDocumentsData?.prefilled_json_detail?.document_link}
            docStatus={
              itrDocumentsData?.prefilled_json_detail?.verification_status
            }
            password={
              itrDocumentsData?.prefilled_json_detail?.document_password
            }
            downloadFile={downloadFile}
            handleDocVerification={handleDocVerification}
          />
        )}
        {itrDocumentsData?.capital_gains?.map(
          (item, i) =>
            item?.document_link && (
              <TableRows
                key={i}
                docName="Capital Gains"
                docId={item?.document_id}
                docLink={item?.document_link}
                docStatus={item?.verification_status}
                fileName={item?.broker_name}
                password={item?.document_password}
                downloadFile={downloadFile}
                handleDocVerification={handleDocVerification}
              />
            )
        )}
        {itrDocumentsData?.other_document_details?.map(
          (item, i) =>
            item.verification_status !== "REQUESTED" && (
              <TableRows
                key={i}
                docProp={"OTHER"}
                docName={item.document_type.split("_").join(" ")}
                docId={item?.document_id}
                docLink={item?.pre_signed_url_for_download}
                docStatus={item?.verification_status}
                fileName={item?.broker_name}
                password={item?.document_password}
                downloadFile={downloadFile}
                handleDocVerification={handleDocVerification}
              />
            )
        )}
      </div>
      <div className="requested_doc_list">
        <h1>Requested Document(s)</h1>
        {itrDocumentsData?.form16_detail?.map(
          (item, i) =>
            item.verification_status === "REQUESTED" && (
              <RequestedDoc
                key={i}
                description={item?.description}
                docType={item?.document_type?.split("_").join(" ")}
              />
            )
        )}
        {itrDocumentsData?.form26_as_detail &&
          itrDocumentsData?.form26_as_detail?.verification_status ===
            "REQUESTED" && (
            <RequestedDoc
              description={itrDocumentsData?.form26_as_detail?.description}
              docType={"Form 26AS"}
            />
          )}
        {itrDocumentsData?.ais_detail &&
          itrDocumentsData?.ais_detail?.verification_status === "REQUESTED" && (
            <RequestedDoc
              description={itrDocumentsData?.ais_detail?.description}
              docType={"FORM AIS"}
            />
          )}
        {itrDocumentsData?.prefilled_json_detail &&
          itrDocumentsData?.prefilled_json_detail?.verification_status ===
            "REQUESTED" && (
            <RequestedDoc
              description={itrDocumentsData?.prefilled_json_detail?.description}
              docType={"PREFILLED JSON"}
            />
          )}
        {itrDocumentsData?.capital_gains?.map(
          (item, i) =>
            item.verification_status === "REQUESTED" && (
              <RequestedDoc
                key={i}
                description={item?.description}
                docType={"Capital Gain"}
              />
            )
        )}
        {itrDocumentsData?.other_document_details?.map(
          (item, i) =>
            item.verification_status === "REQUESTED" && (
              <RequestedDoc
                key={i}
                description={item?.description}
                docType={item?.document_type.split("_").join(" ")}
              />
            )
        )}
      </div>
      {requestPopupStatus.active && (
        <Backdrop
          toggleBackdrop={() => {
            dispatch({
              type: ITRDocumentsAction.REQUEST_POPUP_STATUS,
              data: {
                active: false,
              },
            });
          }}
        >
          <RequestPopup />
        </Backdrop>
      )}
      {rejectPopupStatus.active && (
        <Backdrop
          toggleBackdrop={() => {
            dispatch({
              type: ITRDocumentsAction.REJECT_POPUP_STATUS,
              data: {
                active: false,
              },
            });
          }}
        >
          <RejectPopup handleDocVerification={handleDocVerification} />
        </Backdrop>
      )}
      {confirmPopupStatus.active && (
        <Backdrop toggleBackdrop={handleCancelConfirmation}>
          <ConfirmationModal
            handleConfirmRequest={handleConfirmRequest}
            handleCancelBtn={handleCancelConfirmation}
          />
        </Backdrop>
      )}
    </ITRDocumentsContainer>
  );
};

export default LayoutHOC(ITRDocuments);
