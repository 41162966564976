import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const MonthlySalaryViewWrapper = styled.div`
    width: ${dim._scale(926)};
    padding: ${dim._36px};
    box-sizing: border-box;
    border-radius: 4px 4px 0px 0px;
    background-color: #fff;
    margin-top: ${dim._12px};

    .heading {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
`;
