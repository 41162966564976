import styled from "styled-components";
import {theme} from "../../styles/theme";

export const DashboardWrapper = styled.div`
  height: 100%;
  display: flex;
  transition: 1s all;

  .advisors {
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 16px !important;
    row-gap: 16px !important;
    // height: 560px;
    overflow: scroll;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .users {
margin-top:24px;
    position: relative;
    padding: 0px 24px;
    min-width: 360px;
    height: 560px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    cursor: pointer;
  }

  .selected-advisor {
    position: sticky;
    top: 0px;
    background-color: ${theme.color.blue_20};
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin: 0px;
    z-index: 1000;
  }

  .selected-adv-name {
    margin: 0px;
    color: ${theme.color.white_0};
  }

  .count {
    background-color: white;
    color: ${theme.color.blue_20};
    margin: 0px;
    font-weight: 600;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .users-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .crossIcon {
    position: absolute;
    top: -12px;
    right: -12px;
    background: #fff;
    padding: 2px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    z-index: 2;
    cursor:pointer;
  }
`;
