
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";
import styled from "styled-components";
import PDFViewer from "../../../../../../commonComponents/PDFViewer";
import { StyledNoteViewer } from "./StyledNoteViewer";
import { dim } from "../../../../../../styles/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AdvisorNotesService } from "../../../../../../Services/AdvisorNotesService";
import toast from "react-hot-toast";
import { StyledCarouselCard } from "./StyledNoteViewer";

const getFilePreviewImage = (file) => {
  if (!file) return null;

  if (file.type.startsWith("image/")) {
    return "https://www.svgrepo.com/show/508699/landscape-placeholder.svg";
  }
  if (file.type === "application/pdf") {
    return "https://t3.ftcdn.net/jpg/02/26/42/06/360_F_226420649_vlXjp3JyUrnW5EHY00dvhbqkVdUfyafj.jpg";
  }

  return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/File_alt_font_awesome.svg/1024px-File_alt_font_awesome.svg.png";
};

const FileCard = ({ file, onClick }) => (
  <StyledCarouselCard onClick={() => onClick(file)}>
    <CardMedia
      component="img"
      height="70"
      image={getFilePreviewImage(file)}
      alt={file.name}
      style={{
        cursor: "pointer",
        height: "240px",
        objectFit: "contain",
      }}
    />
    <CardContent>
      <Typography variant="body2" color="textSecondary">
        {file?.name}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {file?.description?.length > 50
          ? `${file.description.slice(0, 50)}...`
          : file.description}
       </Typography>
    </CardContent>
  </StyledCarouselCard>
);

const FilePreviewModal = ({ open, onClose, file }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth style={{marginTop:'65px'}}>
    <DialogTitle>
      File Preview
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        style={{ position: "absolute", right: 15, top: 10 }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent 
    style={{height:'80vh' ,padding:'0px',marginTop:'20px', padding: "20px", textAlign: "center"}}
    >
      {file?.type?.startsWith("image/") ? (
        <img
          src={file?.file}
          alt="Preview"
          style={{ maxWidth: "100%", maxHeight: "80vh" }}
        />
      ) : (
        <PDFViewer src={file?.file} style={{ height: "70vh" }} />
      )}
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ marginTop: "10px" }}
      >
        {file?.description}
      </Typography>
    </DialogContent>
  </Dialog>
);


function NoteViewer({ header = "", content = "", uploadedFiles = [] ,advisorName,createdAt }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading,setLoading] = useState(false);

  const handleFileClick = async (file) => {
    try{
      setLoading(true);
      const s3Link = await AdvisorNotesService.getDocumentFromS3(file?.document_identifier);
      setSelectedFile({ ...file, file: s3Link?.data });
      setModalOpen(true);
    }catch(e){
      toast.error("Something went wrong, please try again");
    }finally{
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedFile(null);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(3, uploadedFiles.length),
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <StyledNoteViewer>
      <div className="note-display">
        {uploadedFiles?.length > 0 && (
          <div>
            {uploadedFiles?.length > 1 ? (
              <Slider {...sliderSettings} className="slider">
                {uploadedFiles.map((file, index) => (
                  <FileCard key={index} file={file} onClick={handleFileClick} />
                ))}
              </Slider>
            ) : (
              <FileCard file={uploadedFiles[0]} onClick={handleFileClick} />
            )}
          </div>
        )}

        <div className="note-header">
          <Typography variant="h5">{header}</Typography>
          <div className="author-details">
            <div>
            <p>Written By: {advisorName}</p>
            </div>
          
          <p>{createdAt}</p>
        </div>

        </div>

        <div
          className="note-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <FilePreviewModal
          open={modalOpen}
          onClose={handleModalClose}
          file={selectedFile}
        />
      </div>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </StyledNoteViewer>
  );
}

export default NoteViewer;
