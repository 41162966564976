import styled from "styled-components";
import { dim, theme } from "../../../../styles/theme";

const ConfiramtionModalStyle = styled.div`
  position: relative;
  background-color: ${theme.color.white_0};
  background-clip: padding-box;
  border-radius: ${dim._8px};
  box-shadow: ${dim._0px} ${dim._6px} ${dim._16px} ${dim._0px}
      rgba(0, 0, 0, 0.08),
    ${dim._0px} 3px ${dim._6px} -4px rgba(0, 0, 0, 0.12),
    0 9px 28px ${dim._8px} rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: ${dim._20px} ${dim._24px};
  width: ${dim._scale(464)};
`;

const CancelIcon = styled.div`
  display: flex;
  justify-content: right;

  .cancelIcon {
    height: 32px;
    width: 32px;
  }
`;

const ConfirmationIcon = styled.div`
  display: flex;
  justify-content: center;

  .confirmationIcon {
    height: 84px;
    width: 84px;
  }
`;

const ConfirmationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${dim._14px};
`;

const Header = styled.div`
  color: ${theme.color.black_20};
  font-size: ${dim._18px};
  font-weight: 600;
`;

const SubHeader = styled.div`
  width: 70%;
  color: ${theme.color.grey_90};
  text-align: center;
  font-size: ${dim._14px};
  margin-top: 6px;
  font-weight: 400;
`;

const ConfirmationFotter = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 48px 0;
`;

export {
  ConfiramtionModalStyle,
  CancelIcon,
  ConfirmationIcon,
  ConfirmationContent,
  Header,
  SubHeader,
  ConfirmationFotter,
};
