import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledCallActionForm = styled.div`
  width: 100%;
  /* background-color: #fff; */
  /* border-radius: 4px;
  padding: 0 32px; */
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  cursor: ${({ isEditDisabled }) =>
    isEditDisabled ? "not-allowed" : "normal"} !important;
  box-sizing: border-box;
  .cardInfo {
    display: flex;
    align-items: center;
    padding-top: ${dim._24px};
    gap: ${dim._24px};
  }
  .close-btn {
    color: #dc3545;
    font-size: 24px;
    cursor: pointer;
  }
`;
