export const generateUniqueIds = (content) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(content, 'text/html');
	const headings = [];

	Array?.from(doc.querySelectorAll('h1, h2, h3, h4, h5, h6'))?.map(
		(heading, index) => {
			const headerTag = heading.tagName.toLowerCase();
			const uniqueId = `${headerTag}-heading-${index}`;
			heading.id = uniqueId;
			headings.push({id: uniqueId, text: heading.textContent});
		}
	);

	return {updatedPayload: doc.body.innerHTML, headings};
};
