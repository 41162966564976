import {
	GetCRYPTO_CURRENCYTax,
	getTotalIncomeTaxCapitalGain
} from '../../../../utils/capitalGainTaxCalc';
import { getTaxBySalary } from '../../../../utils/taxCalc';

const FIFTY_THOUSAND = 50000;
const TWO_LAKHS_FIFTY_THOUSAND = 250000;

const incomeInputsType = {
	INCOME_FROM_HOUSE_RENT: 'INCOME_FROM_HOUSE_RENT',
	SALARIED: 'SALARIED',
	CAPITAL_GAIN: 'CAPITAL_GAIN',
	CRYPTO_CURRENCY: 'CRYPTO_CURRENCY',
	BUSINESS_PROFESSION: 'BUSINESS_PROFESSION',
	OTHER_INCOME: 'OTHER_INCOME'
};

const setIncomeAndTaxFn = ({
	incomeData,
	incomeTyddpes,
	setSalary,
	setCapitalGainIncomeArr,
	setSTCG_DEBT_TOTAL_AMOUNT,
	arrIncome,
	setCryptoCurrency,
	businessProfessionOtherIncomeObj,
	setBusinessProfessionOtherIncomeObj
}) => {
	let BusinessProfessionIncomeCurrentValue = 0;
	let OtherIncome = 0;
	const NameArray = [];

	incomeData &&
		incomeData &&
		incomeData.income_details &&
		incomeData.income_details.map((d, i) => {
			if (d.type === incomeInputsType.SALARIED) {
				setSalary(d.total_income);
				if (d.total_income > 0) {
					NameArray.push({ value: 'Salary', rank: 1 });
				}
				setBusinessProfessionOtherIncomeObj({
					...businessProfessionOtherIncomeObj,
					label: [...NameArray],
					OtherIncome: OtherIncome,
					BusinessProfessionIncome: BusinessProfessionIncomeCurrentValue
				});
			} else if (d.type === incomeInputsType.CAPITAL_GAIN) {
				setCapitalGainIncomeArr(d.type_details);
				let stcgDebt = 0;
				for (let index = 0; index < d.type_details.length; index++) {
					if (d.type_details[index].sub_type === incomeInputsType.STCG_DEBT) {
						stcgDebt = d.type_details[index].total_profit_income;

						d.type_details[index].total_profit_income &&
							setSTCG_DEBT_TOTAL_AMOUNT(
								d.type_details[index].total_profit_income
							);
					}
				}

				{
					arrIncome.some((e) => {
						return e.type == 'Capital Gain';
					})
						? console.log('error')
						: arrIncome.push({
								type: 'Capital Gain',
								tax: 4567,
								rank: 2
						  });
				}
				{
					d.total_profit_income &&
					arrIncome.some((e) => {
						return e.type == 'Capital Gain Profit';
					})
						? console.log('error')
						: arrIncome.push({
								type: 'Capital Gain Profit',
								value: d.total_profit_income,
								rank: 2
						  });
				}
				{
					d.total_loss_income &&
					arrIncome.some((e) => {
						return e.type == 'Capital Gain Loss';
					})
						? console.log('error')
						: arrIncome.push({
								type: 'Capital Gain Loss',
								value: d.total_loss_income,
								rank: 2
						  });
				}
			} else if (d.type === incomeInputsType.CRYPTO_CURRENCY) {
				setCryptoCurrency(d.total_income);
				let cryptoTax = GetCRYPTO_CURRENCYTax(d.total_income);
				arrIncome.some((e) => {
					return e.type == 'Crypto Currency';
				})
					? console.log('error')
					: arrIncome.push({
							type: 'Crypto Currency',
							tax: cryptoTax,
							value: d.total_income,
							rank: 3
					  });
			} else if (d.type === incomeInputsType.BUSINESS_PROFESSION) {
				if (d.total_income > 0) {
					NameArray.push({ value: 'Business & Profession', rank: 2 });
				}

				let total_profit = 0;

				for (let index = 0; index < d.type_details.length; index++) {
					const element =
						d.type_details[index].total_net_profit > 0
							? d.type_details[index].total_net_profit
							: 0;
					total_profit += element;
				}
				BusinessProfessionIncomeCurrentValue = total_profit;
				const newObj = {
					...businessProfessionOtherIncomeObj,
					label: [...NameArray],
					OtherIncome: OtherIncome,
					BusinessProfessionIncome: BusinessProfessionIncomeCurrentValue
				};

				setBusinessProfessionOtherIncomeObj({ ...newObj });
				arrIncome.some((e) => {
					return e.category === 'Business & Profession';
				})
					? console.log('error')
					: arrIncome.push({
							type: 'Business & Profession',
							category: 'Business & Profession',
							value: d.total_income,
							rank: 6
					  });
			} else if (d.type === 'OTHERS') {
				if (d.total_income > 0) {
					NameArray.push({ value: 'Other', rank: 3 });
				}

				OtherIncome = d.total_income;

				const newObj = {
					label: [...NameArray],
					BusinessProfessionIncome: BusinessProfessionIncomeCurrentValue,
					OtherIncome: OtherIncome
				};
				setBusinessProfessionOtherIncomeObj({
					...newObj
				});
				arrIncome.some((e) => {
					return e.category === 'Other';
				})
					? console.log('error')
					: arrIncome.push({
							type: 'Other',
							category: 'Other',
							value: d.total_income,
							rank: 8
					  });
			}
		});
};

const getUpdatedTax = (
	businessProfessionOtherIncomeObj,
	salary,
	totalDeduction,
	STCG_DEBT_TOTAL_AMOUNT,
	arrIncome,
	taxObj
) => {
	{
		arrIncome.some((e) => {
			return e.category == 'Salary';
		})
			? arrIncome
					.sort((a, b) => a.rank - b.rank)
					.splice(0, 1, {
						category: 'Salary',
						type: businessProfessionOtherIncomeObj.label
							.sort((a, b) => a.rank - b.rank)
							.map((list) => list.value)
							.join(','),
						tax:
							salary +
								businessProfessionOtherIncomeObj.BusinessProfessionIncome +
								businessProfessionOtherIncomeObj.OtherIncome >
							TWO_LAKHS_FIFTY_THOUSAND
								? taxObj.totalTax > 12500
									? taxObj.totalTax
									: 0
								: 0,
						value:
							salary +
							businessProfessionOtherIncomeObj.BusinessProfessionIncome +
							businessProfessionOtherIncomeObj.OtherIncome,
						rank: 1
					})
			: salary +
					businessProfessionOtherIncomeObj.BusinessProfessionIncome +
					businessProfessionOtherIncomeObj.OtherIncome >
					0 &&
			  arrIncome.push({
					category: 'Salary',
					type: businessProfessionOtherIncomeObj.label
						.sort((a, b) => a.rank - b.rank)
						.map((list) => list.value)
						.join(','),
					tax:
						salary +
							businessProfessionOtherIncomeObj.BusinessProfessionIncome +
							businessProfessionOtherIncomeObj.OtherIncome >
						TWO_LAKHS_FIFTY_THOUSAND
							? taxObj.totalTax > 12500
								? taxObj.totalTax
								: 0
							: 0,
					value:
						salary +
						businessProfessionOtherIncomeObj.BusinessProfessionIncome +
						businessProfessionOtherIncomeObj.OtherIncome,
					rank: 1
			  });
	}
	return arrIncome;
};

const setCapitalGainTaxFn = (
	salary,
	businessProfessionOtherIncomeObj,
	settax,
	totalDeduction,
	STCG_DEBT_TOTAL_AMOUNT,
	arrIncome,
	setTargetedTax,
	setCapitalGainTax,
	capitalGainIncomeArr,
	incomeData
) => {
	let basicExemption = TWO_LAKHS_FIFTY_THOUSAND;
	basicExemption =
		salary +
			businessProfessionOtherIncomeObj.BusinessProfessionIncome +
			businessProfessionOtherIncomeObj.OtherIncome >=
		FIFTY_THOUSAND
			? salary +
			  businessProfessionOtherIncomeObj.BusinessProfessionIncome +
			  businessProfessionOtherIncomeObj.OtherIncome -
			  FIFTY_THOUSAND -
			  basicExemption
			: salary +
			  businessProfessionOtherIncomeObj.BusinessProfessionIncome +
			  businessProfessionOtherIncomeObj.OtherIncome -
			  basicExemption;

	settax(
		getTaxBySalary(
			salary,
			totalDeduction,
			STCG_DEBT_TOTAL_AMOUNT,
			businessProfessionOtherIncomeObj.BusinessProfessionIncome +
				businessProfessionOtherIncomeObj.OtherIncome
		)
	);

	if (
		arrIncome.some((e) => {
			return e.type === 'Capital Gain';
		})
	) {
		const index = arrIncome.findIndex((x) => x.type === 'Capital Gain');
		arrIncome.splice(index, 1, {
			...arrIncome[index],
			tax: getTotalIncomeTaxCapitalGain(
				capitalGainIncomeArr,
				basicExemption < 0 && basicExemption
			)
		});
	}

	setCapitalGainTax(
		getTotalIncomeTaxCapitalGain(
			capitalGainIncomeArr,
			basicExemption < 0 && basicExemption
		)
	);
	incomeData.targeted_tax &&
		setTargetedTax({
			targeted_tax: incomeData.targeted_tax,
			date: incomeData.targeted_tax_timestamp
		});
};

export { setIncomeAndTaxFn, getUpdatedTax, setCapitalGainTaxFn };
