import React, { useEffect, useState } from "react";
import { AddButton, Wrapper } from "./index.styled";
import { SliderComponent } from "../StandAloneSlider";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { AllSalaryComponents } from "../../constants";

import Tooltip from "@mui/material/Tooltip";
import { convertNumberIntoINR } from "../../../../../../utils/ConvertNumnerIntoIndianCurrency";
import OutlinedButton from "../../../../../../commonComponents/OutlinedButton";
import PrefixInput from "../../../../../../commonComponents/PrefixInput";
import { dim } from "../../../../../../styles/theme";
import { between } from "../../../../../../utils/betweenFn";

const initialComponents = [
    { label: "Basic Salary", value: 0, isRemovable: false },
    { label: "House Rent Allowance", value: 0, isRemovable: false },
];
const SalaryComponents = ({
    salaryComponents = [],
    readonly = false,
    onboarding = false,
    min = { value: 0, label: "₹0" },
    max = { value: 5000000, label: "₹50 Lakhs" },
    diff = 0,
    componentProp = [],
    setComponentProp = () => {},
    onChange = () => {},
    style = {},
}) => {
    const [components, setComponents] = useState([]);
    const [newComponents, setNewComponents] = useState([]);
    const [other, setOther] = useState("");
    const [error, setError] = useState(false);
    const [componentTotal, setComponentTotal] = useState(0);

    useEffect(() => {
        setComponentTotal(
            components.reduce((acc, cur) => {
                return acc + +cur.value;
            }, 0)
        );
        setComponentProp([...components]);
    }, [components]);

    useEffect(() => {
        if (salaryComponents.length === 0) {
            setComponents(
                componentProp.length > 0
                    ? [...componentProp]
                    : [...initialComponents]
            );
        } else {
            setComponents([...salaryComponents]);
        }
    }, [salaryComponents]);

    const getComponentsNameArray = () => {
        return components.map((it) => {
            return it.label;
        });
    };

    const addNewComponent = () => {
        setNewComponents([
            ...newComponents,
            { createdAt: new Date().getTime(), label: "", value: 0 },
        ]);
    };

    const setComponent = (type, value, index) => {
        setNewComponents(
            newComponents.map((it, _index) => {
                return index !== _index
                    ? it
                    : type === "label"
                    ? { ...it, label: value }
                    : { ...it, value: value };
            })
        );
    };

    const closeComponent = (index) => {
        setComponents(
            components.filter((it, _index) => {
                return index !== _index;
            })
        );
    };

    const closeNewComponent = (index) => {
        setNewComponents(
            newComponents.filter((it, _index) => {
                return index !== _index;
            })
        );
    };

    useEffect(() => {
        onChange([...components]);
    }, [components]);

    const updateComponentValue = (v, index) => {
        const newComp = components.map((it, _index) => {
            return index === _index ? { ...it, value: v } : it;
        });
        setComponents(newComp);
        setError(!isComponentsComplete(newComp));
    };

    const isComponentsComplete = (newComp) => {
        const sum = newComp.reduce((acc, cur) => {
            return acc + +cur.value;
        }, 0);

        return sum === max.value;
    };

    const getMessage = (isReady = false) => {
        return `Sum of salary components must be equal to CTC to submit. (CTC:${
            max.value
        }, Components Sum:${components.reduce((acc, cur) => {
            return acc + +cur.value;
        }, 0)})`;
    };

    return (
        <Wrapper style={style} onboarding={onboarding}>
            <p className="heading">Annual Salary Distribution </p>

            <div className="">
                {!readonly && (
                    <p className={"notes success"}>
                        Note: Sum Of CTC Component Should Be Equal To Total CTC{" "}
                        {convertNumberIntoINR(max.value)}
                    </p>
                )}
            </div>

            {components.map((it, index) => {
                return (
                    <Tooltip
                        title={
                            readonly
                                ? "Can't Edit Your Annual Salary Distribution"
                                : ""
                        }
                        arrow
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width:
                                    it.isRemovable === false
                                        ? `calc(100% - ${dim._56px})`
                                        : "100%",
                            }}
                            key={it.label}
                        >
                            <SliderComponent
                                disabled={readonly}
                                value={it.amount || it.value}
                                key={index}
                                style={{ flex: 1 }}
                                label={it.label}
                                min={min}
                                max={max}
                                onChange={(v) => {
                                    updateComponentValue(v, index);
                                }}
                            />
                            {it.isRemovable !== false &&
                                components.length > 1 &&
                                !readonly && (
                                    <div
                                        className="close-btn"
                                        style={{ marginTop: dim._28px }}
                                        onClick={() => closeComponent(index)}
                                    >
                                        <AiOutlineClose />
                                    </div>
                                )}
                        </div>
                    </Tooltip>
                );
            })}
            {/* <OutlinedButton
				style={{ marginTop: 16 }}
				disabled={readonly || newComponents.length > 0}
				onClick={addNewComponent}
			>
				Add new components
			</OutlinedButton> */}

            {newComponents.length > 0 && (
                <div className="add-component-container-1">
                    <div className="label label-1">Components Type</div>
                    <div className="label label-2">Amount</div>
                </div>
            )}

            {newComponents.length > 0 && (
                <>
                    <div
                        className="add-component-container"
                        key={newComponents[0].createdAt}
                    >
                        <select
                            className="component-name"
                            onChange={(e) =>
                                setComponent("label", e.target.value, 0)
                            }
                        >
                            <option value="">Select</option>
                            {AllSalaryComponents.filter((it) => {
                                return !getComponentsNameArray().includes(it);
                            }).map((it, index) => {
                                return (
                                    <option key={index} value={it}>
                                        {it}
                                    </option>
                                );
                            })}
                        </select>
                        <PrefixInput
                            style={{
                                width: 376,
                                marginLeft: 24,
                                background: "#f1f5f7",
                                border: 0,
                            }}
                            prefix="₹"
                            value={newComponents[0].value}
                            placeholder="e.g. 50,000"
                            onChange={(e) =>
                                setComponent("value", e.target.value, 0)
                            }
                        />
                        <div
                            className="close-btn"
                            onClick={() => closeNewComponent(0)}
                        >
                            <AiOutlineClose />
                        </div>
                        <AddButton
                            disabled={
                                newComponents[0].label === "" ||
                                (newComponents[0].label === "Other" &&
                                    other === "") ||
                                isNaN(newComponents[0].value)
                            }
                            onClick={() => {
                                if (
                                    !(
                                        newComponents[0].label === "" ||
                                        isNaN(newComponents[0].value)
                                    )
                                ) {
                                    setComponents([
                                        ...components,
                                        {
                                            ...newComponents[0],
                                            label:
                                                newComponents[0].label ===
                                                "Other"
                                                    ? other
                                                    : newComponents[0].label,
                                        },
                                    ]);
                                    closeNewComponent(0);
                                }
                            }}
                        >
                            <AiOutlineCheck />
                        </AddButton>
                    </div>
                    <div>
                        {newComponents[0].label === "Other" && (
                            <input
                                className="other-input"
                                type="text"
                                value={other}
                                placeholder="Specify other"
                                onChange={(e) => setOther(e.target.value)}
                            ></input>
                        )}
                    </div>
                </>
            )}
            <div className="">
                {!readonly &&
                    error &&
                    !between(
                        componentTotal,
                        max.value * 0.95,
                        Number(max.value) * 1.05
                    ) && <p className={"notes error"}>{getMessage(error)}</p>}
            </div>
        </Wrapper>
    );
};

export default SalaryComponents;
