import { httpClient } from "./httpUtil";

const AdvisorService = {
  getAdvisors: () => {
    return httpClient.get(`advisors/v1/all/cms`);
  },
  getAdvisorUnreadChat: () => {
    return httpClient.get(`/discussion-message/v1/unread-chats`);
  },
  getAllUserByAdvisorId: (advisor_id, advisor_type, user_type, filter_by) => {
    return httpClient.get(
      `super-saver-user/v1/all/${advisor_id}/get-users/internal`,
      { advisor_type, user_type, filter_by }
    );
  },
};

export default AdvisorService;
