import { getTwoDecimal } from "./numberUtils"

export const getGstAmount=(amount)=>{
    let base = amount/1.18;
    return base*0.18;
}
export const roundNumber = (number) => {
    const decimalPlaces = 2;
    return Number(number.toFixed(decimalPlaces));
  }

export const amountWithGst = (dueAmount)=>{
    let gst = dueAmount * .18;
    let totalDueAmount = dueAmount + gst;
    return roundNumber(totalDueAmount);
}

