import React, { useEffect, useRef } from "react";
import Select from "react-select";
import styled from "styled-components";

function CustomSelect(props) {
    const {
        tags,
        placeholder,
        id,
        name,
        clear,
        onChange,
        handleOnBlur,
        defaultValue,value,isMulti=true
    } = props;
    const selectInputRef = useRef();

    useEffect(() => {
        if (clear) {
            selectInputRef.current.clearValue();
        }
    });

    return (
        <TagsSelectStyle>
            <Select
                ref={selectInputRef}
                className="select"
                isMulti={isMulti}
                placeholder={placeholder}
                options={tags}
                id={id}
                name={name}
                onChange={onChange}
                onBlur={handleOnBlur}
                defaultValue={defaultValue}
                value={value}
            />
        </TagsSelectStyle>
    );
}

export default CustomSelect;

const TagsSelectStyle = styled.div`
    .select {
        margin: 0px 0 0 02px;
        min-width: 250px;
        width: auto;
 
    }
`;
