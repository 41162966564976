import styled from "styled-components";
import { dim } from "../../styles/theme";

const TabsStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  margin: 0;
  position: sticky;
  top: 0px;
  z-index: 200;
  box-sizing: border-box;

  li {
    margin: 0 20px 0 0;
    padding: 0 0 10px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212f45;
    list-style: none;
    cursor: pointer;
    text-transform: uppercase;
  }

  .active {
    padding: 0 0 6px 0;
    color: #635bef;
    font-weight: 600;
    border-bottom: 2px solid #635bef;
  }
`;

export default TabsStyle;
