import React from 'react';
import { dim } from '../../styles/theme';
import { StyledVerifiedChip } from './VerifiedChip.styled';

function VerifiedChip() {
	return (
		<StyledVerifiedChip>
			<img
				src='https://prod-articles-images.s3.ap-south-1.amazonaws.com/verifiedChip.svg'
				alt='verified'
			/>
		</StyledVerifiedChip>
	);
}


export default VerifiedChip;
