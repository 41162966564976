import React from "react";
import { ClientActionableViewStyle } from "./ClientActionableView.styled";
import RightSideSlider from "../../../../../commonComponents/RightSideSlider";
import ClientActionableItems from "..";

const ClientActionableView = ({activateViewPopUp = true, handleHelpShow,clientActionableData}) => {

    const handleChangeData = (actionableItems) => {
      clientActionableData(actionableItems)
    }

  return (
    <ClientActionableViewStyle>
      <RightSideSlider
        width={`60%`}
        showDis={activateViewPopUp}
        newVersion={true}
        handleHelpShow={handleHelpShow}
      >
       <ClientActionableItems handleClientData = {handleChangeData} handleHelpShow= {handleHelpShow}/>
      </RightSideSlider>
    </ClientActionableViewStyle>
  );
};
export default ClientActionableView;
