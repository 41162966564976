import { LOCAL_DB_KEYS } from "../constants/localStorage";
import { httpClient } from "./httpUtil";

const SuperSaverIncomeService = {
    getIncomeData: (FYId) => {
        return httpClient.get(
            `incomes/v1/all?userId=${localStorage.getItem(
                LOCAL_DB_KEYS.USER_ID
            )}&financialYearId=${localStorage.getItem(
                LOCAL_DB_KEYS.FINANCIAL_YEAR_ID
            )}`
        );
    },
    postIncomeData: (data) => {
        return httpClient.post("incomes/v1", data);
    },
    postCompanyData: (data) => {
        return httpClient.post("/company-profile/all-detail", data);
    },
    postSalaryVerification: (data, incomeType) => {
        return httpClient.put(
            `incomes/v1/update?user_id=${localStorage.getItem(
                LOCAL_DB_KEYS.USER_ID
            )}&type=${incomeType}`,
            data
        );
    },
    deleteIncomeRow: (externalId) => {
        return httpClient.delete(
            `incomes/v1?externalId=${externalId}&userId=${localStorage.getItem(
                LOCAL_DB_KEYS.USER_ID
            )}`
        );
    },
    getIncomeRevampData: async (type, userId,fyId) => {
        const data = await httpClient.get(
            `/incomes/v1/get?user_id=${userId}&type=${type}&financial_year_id=${fyId}`,
            null,
            { userId }
        );
        return data;
    },
    postIncomeRevampData: (data, type) => {
        return httpClient.post(
            `incomes/v1/add?user_id=${localStorage.getItem(
                LOCAL_DB_KEYS.USER_ID
            )}&type=${type}`,
            data
        );
    },
    putCloseCompany: (date, external_id) => {
        const fyi = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
        return httpClient.put(
            `incomes/v1/close?financial_year_id=${fyi}&date=${date}&income_id=${external_id}`
        );
    },
    updateIncomeRevampData: (data, type) => {
        return httpClient.put(
            `incomes/v1/update?user_id=${localStorage.getItem(
                LOCAL_DB_KEYS.USER_ID
            )}&type=${type}`,
            data
        );
    },
    getDataForIntelligence: async () => {
        const userId = localStorage.getItem(LOCAL_DB_KEYS.USER_ID);
        const fyi = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
        const data = await httpClient.get(
            `/get-assessment?&financial_year_id=${fyi}`
        );
        return data;
    },
};

export default SuperSaverIncomeService;
