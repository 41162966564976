import React, { useState } from "react";
import { CapitalGainDetailsStyle } from "../../CapitalGainDetails/CapitalGainDetails.styled";
import TableHeading from "../../CapitalGainDetails/TableHeading";
import { useLocation, useNavigate } from "react-router";
import EditableRow from "../../CapitalGainDetails/EditableRow";
import ReadOnlyRow from "../../CapitalGainDetails/ReadOnlyRow";
import { detailsConst } from "../../utils/constant";
import EditableRowBusinessCrypto from "./components";
import ReadOnlyRowBusinessCrypto from "./components/ReadOnlyRow";
import Button from "../../../../commonComponents/ButtonMain";

const BusinessRentCryptoDetails = () => {
    const state = useLocation();
    const navigate = useNavigate();
    const [componentsList, setComponentsList] = useState(state.state.component);
    const incomeType = state.state.incomeType;
    const handleEditClick = (i, value, action, obj, isNew) => {
        const newArr = componentsList;
        if (action === "edit") {
            newArr.splice(i, 1, {
                ...componentsList[i],
                isEditable: value,
            });
        } else if (action === "remove") {
            newArr.splice(i, 1);
        } else if (action === "save") {
            newArr.splice(i, 1, {
                ...obj,
                isEditable: value,
            });
        } else if (action === "cancel" && isNew) {
            newArr.splice(i, 1);
        } else if (action === "cancel" && !isNew) {
            newArr.splice(i, 1, {
                ...componentsList[i],
                isEditable: value,
            });
        }

        // setComponentsList([...newArr]);
    };

    const handleAddMoreClick = () => {
        const newArr = componentsList ? componentsList : [];
        newArr.push({
            type: "",
            subType: "",
            details: "",
            amount: "",
            sellingPrice: "",
            document: "",
            isEditable: true,
            isNew: true,
        });
        setComponentsList([...newArr]);
    };

    const handleEditFormSubmit = async (obj, editId, isNew) => {};

    const getTitle = () => {
        const path = state.pathname.split("/");
        const category = path[path.length - 2];

        if (category === "capital-gain") {
            return "Capital Gain Income";
        } else if (category === "business-and-profession") {
            return "Business and Profession Income";
        } else if (category === "digital-asset") {
            return "Digital Asset Income";
        } else if (category === "income-from-house-rent") {
            return "House Rent Income";
        }
    };

    return (
        <CapitalGainDetailsStyle>
            <div className="sticky-header">
                <Button
                    style={{
                        textAlign: "center",
                        width: 100,
                        height: 32,
                        marginTop: 16,
                        marginLeft: 16,
                    }}
                    onClick={() => navigate(-1)}
                >
                    Go Back
                </Button>
                <div className="buttons">
                    <div className="heading">{getTitle()}</div>
                </div>
                <TableHeading
                    tableList={detailsConst[incomeType].tableList}
                    width={incomeType === "CAPITAL_GAIN" ? "16.5%" : "12.5%"}
                />
            </div>
            {componentsList &&
                componentsList.map((data, i) => {
                    if (data.isEditable) {
                        return incomeType === "CAPITAL_GAIN" ? (
                            <EditableRow
                                key={i}
                                editId={i}
                                externalId={data.external_id}
                                items={data}
                                typeOptions={
                                    detailsConst[incomeType].typeOptions
                                }
                                subTypeOptions={
                                    detailsConst[incomeType].subTypeOptions
                                }
                                handleEditFormSubmit={handleEditFormSubmit}
                                handleEditClick={handleEditClick}
                                placeHolders={
                                    detailsConst[incomeType].tableList
                                }
                            />
                        ) : (
                            <EditableRowBusinessCrypto
                                key={i}
                                editId={i}
                                externalId={data.external_id}
                                items={data}
                                typeOptions={
                                    detailsConst[incomeType].typeOptions
                                }
                                subTypeOptions={
                                    detailsConst[incomeType].subTypeOptions
                                }
                                handleEditFormSubmit={handleEditFormSubmit}
                                handleEditClick={handleEditClick}
                                incomeType={incomeType}
                                placeHolders={
                                    detailsConst[incomeType].tableList
                                }
                            />
                        );
                    } else {
                        return incomeType === "CAPITAL_GAIN" ? (
                            <ReadOnlyRow
                                key={i}
                                items={data}
                                editId={i}
                                externalId={data.external_id}
                                handleEditClick={handleEditClick}
                                userId={state.state.userId}
                            />
                        ) : (
                            <ReadOnlyRowBusinessCrypto
                                key={i}
                                items={data}
                                editId={i}
                                incomeType={incomeType}
                                externalId={data.external_id}
                                handleEditClick={handleEditClick}
                                userId={state.state.userId}
                            />
                        );
                    }
                })}
        </CapitalGainDetailsStyle>
    );
};

export default BusinessRentCryptoDetails;
