import React from "react";
import { toast } from "react-hot-toast";
import styled from "styled-components";

const CopyClipboardStyle = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`;

const CopyClipboard = ({ text }) => {
  return (
    <CopyClipboardStyle className="clipboard">
      <img
        onClick={(event) => {
          event.stopPropagation();
          navigator.clipboard
            .writeText(text)
            .then(() => toast.success("Copied to clipboard"))
            .catch((error) => toast.error("Failed to copy: " + error));
        }}
        src={process.env.PUBLIC_URL + "/assets/icons/copy-file-icon.svg"}
        alt="copy"
      />
    </CopyClipboardStyle>
  );
};

export default CopyClipboard;
