import styled from "styled-components";

export const ITRDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 40px 0;
  box-sizing: border-box;

  .itr_user_info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 10px 0;
    padding: 0;
    box-sizing: border-box;
  }

  .itr_user_info > h1 {
    font-size: 16px;
    color: #106AB0;
    margin: 0 0 0 10px;
  }

  .top_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 12px 0;
  }

  .top_head > h1,
  .requested_doc_list h1 {
    color: #212f45;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .requested_doc_list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 24px 0 0 0;
  }
`;
