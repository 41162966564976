import React from "react";
import { useState } from "react";
import EditBtn from "../../commonComponents/EditBtn";
import PopUPCms from "../../commonComponents/PopUPCms";
import Backdrop from "../../commonComponents/PopUPCms/BackDrop";
import PopUpForActivityUpdate from "../PopUpForActivityUpdate";
import { Main } from "./ConsultationHistoryContent.styled";

const ConsultationHistoryContent = ({
  data,
  user_id,
  index,
  consultationArr,
  handleUpdate,
}) => {
  const [show, setShow] = useState(false);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const handleCross = () => {
    setShow(false);
  };
  const onHideModelClick = () => {
    setShow(true);
  };

  return (
    <Main>
      <div className="timeDate"></div>
      <div className="activity_heading">
        <div className="head">{data.name}</div>{" "}
        {data.status !== "COMPLETED" && data.name !== "Product On-boarding" && (
          <span className="icon" onClick={onHideModelClick}>
            <EditBtn />
          </span>
        )}
      </div>
      <div className="text">
        {data.start_date &&
          data.status === "IN_PROGRESS" &&
          data.name + " activity is still not completed"}
        {data.completion_date &&
          data.status !== "IN_PROGRESS" &&
          data.name + " activity is completed"}
        {!data.start_date &&
          !data.completion_date &&
          data.name + " activity is not initiated"}
      </div>

      {data.completion_date && (
        <div className="text copleted" style={{ color: "#15BE53" }}>
          {data.content} &nbsp;
          {days[new Date(data.completion_date).getDay()]},{" "}
          {new Date(data.completion_date).getDate()}{" "}
          {months[new Date(data.completion_date).getMonth()]}’{" "}
          {new Date(data.completion_date).getFullYear()}
        </div>
      )}
      {data.start_date && data.status === "IN_PROGRESS" && (
        <div className="text copleted" style={{ color: "#e1e15d" }}>
          {data.content}&nbsp;
          {days[new Date(data.start_date).getDay()]},{" "}
          {new Date(data.start_date).getDate()}{" "}
          {months[new Date(data.start_date).getMonth()]}’{" "}
          {new Date(data.start_date).getFullYear()}
        </div>
      )}
      <PopUPCms show={show} handleCross={handleCross}>
        <PopUpForActivityUpdate
          data={data}
          user_id={user_id}
          index={index}
          consultationArr={consultationArr}
          handleCross={handleCross}
          handleUpdate={handleUpdate}
        />
      </PopUPCms>
      <Backdrop onHideModelClick={handleCross} show={show} />
    </Main>
  );
};

export default ConsultationHistoryContent;
