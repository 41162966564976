import styled from "styled-components";
import { dim } from "../../styles/theme";

export const ActiveChatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 84vh;
  box-sizing: border-box;
  padding: ${dim._12px};
  background-color: #e9f0fb;

  .sidebar {
    position: relative;
    min-width: ${dim._scale(200)};
    width: 20vw;
    background-color: #fff;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    box-sizing: border-box;
  }
  .unreadIcon {
    width: ${dim._26px};
    min-width: ${dim._26px};
    height: ${dim._26px};
    cursor: pointer;
  }
  .search-by-name {
    position: sticky;
    top: 0;
    min-height: ${dim._52px};
    width: 96%;
    padding: ${dim._6px};
    margin: ${dim._8px};
  }

  .main-view {
    width: calc(80vw - ${dim._scale(254)});
    box-sizing: border-box;
  }

  .heading-wrapper {
    display: flex;
    position: sticky;
    top: 0;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: ${dim._12px};
    min-width: 250px;
    color: #3f3f3f;
    border-bottom: 0.8px solid #e6e6e6;
    background-color: #e9f0fb;
    gap: ${dim._8px};
  }

  .list-view {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }

  .list-item {
    border-bottom: 0.8px solid #e6e6e6;
    padding: ${dim._10px};
    color: #3f3f3f;
  }

  .name-badge-container {
    width: ${dim._scale(200)};
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
  }

  .user-name {
    line-height: ${dim._14px};
    font-size: ${dim._14px};
    margin: 0;
    font-weight: bold;
  }

  .user-email {
    line-height: ${dim._14px};
    font-size: ${dim._14px};
    margin: ${dim._8px} 0 0 0;
  }

  .active-chat {
    background-color: #bbddea;
  }
  .aiGif {
    width: 50%;
    max-height: 40%;
    text-align: center;
  }
  .infoToSelectWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .infoToSelect {
    font-size: 28px;
    padding: 0;
  }
`;
