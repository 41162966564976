import styled from 'styled-components';
import { Button } from 'antd'; // Adjust the import path as needed
import { dim } from '../../../../styles/theme';
export const ChatStartModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 ${dim._16px};
	background: #fff;
	position: relative;
	box-sizing: border-box;

	& > h2 {
		margin: 0;
		padding: 0;
		color: #19191b;
		font-size: ${dim._20px};
		font-style: normal;
		font-weight: 700;
		line-height: ${dim._38px};
	}

	& > h3 {
		margin: ${dim._20px} 0 ${dim._8px} 0;
		color: #3b3d41;
		font-size: ${dim._14px};
		font-style: normal;
		font-weight: 600;
		line-height: ${dim._24px};
	}

	.char_limit {
		width: 100%;
		text-align: right;
		color: #212f45;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px; /* 150% */
		opacity: 0.7;
	}
`;

export const StyledButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${dim._40px};
	border-radius: ${dim._12px};
	border-color: #d9d9d9;
	color: black;

	&:hover {
		border-color: #15be53;
		color: black;
	}
`;

export const SubmitButton = styled(Button)`
	height: ${dim._40px};
	margin-top: ${dim._40px};
	border-radius: ${dim._12px};
	background-color: #15be53;
	color: white;

	${(props) =>
		props.disabled &&
		`
    opacity: 0.6;
    pointer-events: none;
    color: #fff !important;
  `}
`;
