import React from "react";
import { StyledConsultationConfirmation } from "../ConsultationConfirmation/ConsultationConfirmation.styled";
import RadioComponent from "../RadioComponent";
import DropDownComponent from "../DropdownComponet";
import ButtonWithUnderline from "../../../../commonComponents/ButtonWithUnderline";
import { dim } from "../../../../styles/theme";
import { PreSignedUrlUpload } from "../../../../utils/preSignedUrl";
import toast from "react-hot-toast";
import { getFillName } from "../../../../utils/randomFileNameGenerator";
import { useParams } from "react-router";
import FormErrorMsgHandler from "../../../../commonComponents/FormErrormsgHandler";
import { ERROR_MESSAGE } from "../../constant";

const documentObj = {
  text: "",
  status: "",
  owned_by: "",
  original_name: "",
  document_name: "",
  s3_link: "",
  uploaded_by: "",
  uploaded_at: "",
  file: null,
};

const ConsultationDocument = ({
  radioComponentProps,
  DropDownComponentProps,
  documentList,
  setDocumentList,
  checkAddValidation,
  isError,
  componentCategory,
}) => {
  const { userId } = useParams();
  const { radioArrData } = radioComponentProps;
  const isDocumentNeeded = radioArrData.filter((d) => d.isSelected)[0]?.value;

  const handleAddMoreDocument = () => {
    setDocumentList((state) => [...state, documentObj]);
  };
  const handleChangeAttachment = (e, index) => {
    const imageFile = e.target.files[0];

    const name = e.target.files[0].name;
    const lastDot = name.lastIndexOf(".");
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);

    const newFilename = getFillName(fileName, ext, "deduction", userId);

    const ReturnValueSetter = () => {
      const newArr = documentList.map((d, i) => {
        if (index == i) {
          return {
            ...d,
            status: "UPLOADED",
            owned_by: "ADVISOR",
            document_name: name,
            original_name: name,
            s3_link: newFilename,
            uploaded_by: "advisor",
            uploaded_at: new Date().getTime(),
            file: e.target.value,
          };
        }
        return d;
      });
      setDocumentList([...newArr]);
    };

    const preSignedUrl = PreSignedUrlUpload(
      newFilename,
      imageFile,
      userId,
      null,
      ReturnValueSetter
    );
    toast.promise(preSignedUrl, {
      loading: "Document Uploading...",
      success: "Document Uploaded",
      error: "Oops something went wrong",
    });
  };

  const handleDeleteFile = (index) => {
    const newArr = documentList;
    newArr.splice(index, 1);
    setDocumentList([...newArr]);
  };
  return (
    <StyledConsultationConfirmation>
      <RadioComponent {...radioComponentProps} />
      {isDocumentNeeded && (
        <div className="documentUpload">
          <div className="titleDocumentList"> Document List</div>
          {documentList.map((d, index) => {
            return (
              <div className="documentListBox" key={index}>
                <DropDownComponent
                  {...DropDownComponentProps}
                  type={d.type || d.text}
                  index={index}
                />

                {d.document_name ? (
                  <div className="documentPreview" id={d.document_name}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/documentPreviewImage.svg"
                      }
                      className="documentPreviewImg"
                      alt=""
                    />
                    <div className="documentName">{d.document_name}</div>
                  </div>
                ) : (
                  <div className="uploadBtn">
                    <input
                      style={{ display: "none" }}
                      type={"file"}
                      accept=".jpg, .jpeg, .png, .pdf"
                      value={d.file ? d.file : undefined}
                      onChange={(e) => {
                        handleChangeAttachment(e, index);
                      }}
                      name=""
                      id={`attachmentUpload ${index}`}
                      className="uploadIcon"
                    />
                    <label
                      className="uploadIcon "
                      htmlFor={`attachmentUpload ${index}`}
                    >
                      <img
                        className="icon"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icons/cloud_icon.svg"
                        }
                        alt=""
                      />
                      <div className="iconDesc">Browse to choose a file</div>
                    </label>
                  </div>
                )}
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/icons/documentDeleteIcon.svg"
                  }
                  className="documentDeleteIcon"
                  alt=""
                  onClick={() => {
                    handleDeleteFile(index);
                  }}
                />
              </div>
            );
          })}
          {isError?.error && isError.errorArr.includes(componentCategory) && (
            <FormErrorMsgHandler style={{ bottom: "-4px" }}>
              {" "}
              {ERROR_MESSAGE[componentCategory]}
            </FormErrorMsgHandler>
          )}
          <ButtonWithUnderline
            style={{
              fontSize: dim._12px,
              paddingTop: dim._12px,
              opacity: checkAddValidation() ? "1" : 0.5,
            }}
            onClick={() => {
              handleAddMoreDocument();
            }}
          >
            + Add New Document
          </ButtonWithUnderline>
        </div>
      )}
    </StyledConsultationConfirmation>
  );
};

export default ConsultationDocument;
