import React from 'react';
import { RadioButtonStyle } from './RadioButton.styled';

const RadioButton = ({ label, id, activeRadioButton, handleRadioBtn }) => {
	return (
		<RadioButtonStyle active={activeRadioButton === id}>
			<div className='radio' id={id} onClick={handleRadioBtn}>
				<div id={id} className='inRadio'></div>
			</div>
			<label htmlFor='Equity'>{label}</label>
		</RadioButtonStyle>
	);
};

export default RadioButton;
