import Styled from 'styled-components';
import { dim } from '../../styles/theme';

const ModalStyle = Styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: ${dim._44px};
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${({ bg }) => (bg ? bg : 'transparent')};
    z-index: 500000;
    box-sizing: border-box;

`;

export default ModalStyle;
