import Styled from "styled-components";

export const FormContainer = Styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height: auto;
margin: 0 0 30px 0;
padding: 0px 0 0 0;`
/* border:2px solid black;`; */

export const Box = Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    width: 100%;
    height: auto;
    margin: 0 0 0 0;
    padding: 20px;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    /* border: 1px solid #E5E5E5; */`;
