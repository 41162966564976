import BASE_URL from "./API_ENDPOINT";

export const uploadExemption = async (idToken, req) => {
    const url = `${BASE_URL}super-saver-user/v1/upload-exemption`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `${idToken}`,
            Source: "cms",
        },
        body: JSON.stringify(req),
    });
    const data = await response.json();
    return data;
};
export const deleteExemption = async (idToken, externalId) => {
    const url = `${BASE_URL}super-saver-user/v1/delete-exemption?externalId=${externalId}`;
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `${idToken}`,
            Source: "cms",
        },
    });
    const data = await response.json();
    return data;
};
