import React, { useState } from "react";
import { AdvisorActionableViewStyle } from "./AdvisorActionableView.styled";
import RightSideSlider from "../../../../../commonComponents/RightSideSlider/index";
import AdvisorAction from "../AdvisorAction";

const AdvisorActionableView = ({
  activateViewPopUp = true,
  handleHelpShow,
  advisorActionableData,
  handleActionableItem,
  isEditDisabled,
}) => {
  // const [cardData, setCardData] = useState('')

  const handleChangeData = (actionableItems) => {
    advisorActionableData(actionableItems);
  };

  return (
    <AdvisorActionableViewStyle>
      <RightSideSlider
        width={`60%`}
        showDis={activateViewPopUp}
        newVersion={true}
        handleHelpShow={handleHelpShow}
      >
        <AdvisorAction
          handleAdvisorData={handleChangeData}
          handleActionableItem={handleActionableItem}
          handleHelpShow={handleHelpShow}
        />
      </RightSideSlider>
    </AdvisorActionableViewStyle>
  );
};
export default AdvisorActionableView;
