import styled from "styled-components";
import { dim } from "../../styles/theme";

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${dim._10px};
  padding: ${dim._4px};
  padding-bottom: ${dim._8px};
  div {
    display: inline-block;
    padding: 4px 8px;
    width: fit-content;
    color: #7f7f7f;
    border: 2px solid #7f7f7f;
    text-align: center;
    background: white;
    opacity: 100%;
    line-height: 20px;
    cursor: pointer;
  }
  .blog {
    border-radius: 0 10px 10px 0;
  }
  .article {
    border-radius: 10px 0 0 10px;
  }
  .active-tab {
    background-color: rgba(11, 106, 176) !important;
    border: 2px solid rgba(11, 106, 176);
    color: white;
    font-weight: 600;
    opacity: 100%;
  }
`;
export default MainDiv;
