import React from "react";
import { ChatPreviewStyled } from "./ChatPreviewStyled";
import Badge from "../../../../commonComponents/badge";
import { getTimeDifference } from "../../../../utils/GetTimeDfference";

const ChatPreview = ({ active = false, msg, index, handleSelectedChat }) => {
  const convertToRunningCase = (str) => {
    return str?.toLowerCase()?.replace(/_/g, " ");
  };
  return (
    <ChatPreviewStyled
      active={active}
      progress={msg?.status === "CLOSED"}
      onClick={() => handleSelectedChat(index, msg?.external_id)}
    >
      <div className="session_info " key={msg?.unread_messages}>
        <div className="flexClass">
          <div className="query_date">
            <p className="type">{convertToRunningCase(msg?.query_type)}</p>
            <p className="time">
              {msg?.created_at && getTimeDifference(msg?.created_at)}
            </p>
          </div>
          <div className="purpose">
            <p>{msg?.subject}</p>
            {msg?.unread_messages > 0 && (
              <Badge
                key={msg?.unread_messages}
                value={msg?.unread_messages}
                size={18}
              />
            )}
            {msg?.status === "CLOSED" && (
              <Badge value="Closed" isOpen={false} />
            )}
          </div>
        </div>
      </div>
    </ChatPreviewStyled>
  );
};

export default ChatPreview;
