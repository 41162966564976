import styled from 'styled-components';
import { hexToRgba } from '../../utils/hexToRgba';
import { Theme, colors } from '../../styles/colors';
import { dim, theme } from '../../styles/theme';
const StyledRightSideSlider = styled.div`
	z-index: 910;
	width: 100vw;
	height: auto;
	position: fixed;
	top: 0;
	left: 0;
	.back-drop {
		width: 100%;
		height: 100vh;
		transition: right 0.5s;
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		transition: 1s;
		background-color: ${({ isOpen }) =>
			isOpen ? hexToRgba(Theme.color.black_0, 0.08) : 'transparent'};
		overflow: hidden;
	}
`;

const StyledRightSideSliderChild = styled.div`
	width: ${({ width }) => width};
	height: calc(100vh - ${dim._68px});
	background-color: ${colors.white};
	padding: ${dim._32px};
	position: absolute;
	transition: 0.5s;
	overflow: scroll;

	right: -${({ isOpen, width }) => (isOpen ? 0 : width)};
	top: ${dim._68px};
	box-sizing: border-box;
	.close-btn {
		position: absolute;
		padding: ${dim._28px};
		top: 0;
		right: 0;
		font-size: ${dim._24px};
		color: ${theme.color.red};
		cursor: pointer;
	}
`;
export { StyledRightSideSlider, StyledRightSideSliderChild };
