function rowFunction(value, index, registered) {
  return {
    id: index,
    name: value?.user_response?.first_name || "",
    subscription_status: value?.subscription_status
      ? value?.subscription_status
      : "",
    mobile: value?.user_response ? value?.user_response?.mobile : "",
    service_type: value?.category_type,
    super_saver_booking_id: value?.external_id,
    service_id: value?.category_id,
    email: value?.user_response ? value?.user_response?.email : "",
    invite_id: value?.invite_id,
    onboard_id: value?.onboard_id,
    user_id: value?.user_id,
    activity_details: value?.activity_details,
    referral_code: value?.referral_code,
    invitation_status: value?.invitation_status,
    advisor: value?.advisor_details
      ? value?.advisor_details?.first_name + " " + value?.advisor_details?.last_name
      : "Not-Assigned",
    advisor_details: value?.advisor_details,
    appVersion: value?.app_version,
    appVersions: value?.meta_data_payload && value?.meta_data_payload?.app_version,
    registered: registered,
  };
}

export { rowFunction };
