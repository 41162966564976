import React, {useEffect, useState} from "react";
import {StyledArticleEditor} from "./ArticleEditor.styled";
import ArticleTopbar from "./components/ArticleTopbar";
import {EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import {ContentState} from "draft-js";
import InformationComponent from "./components/InformationComponent";
import Editor from "./components/Editor";
import {useLocation, useNavigate} from "react-router";
import {CMSApis} from "../../Services/cmsServices";
import toast from "react-hot-toast";
import LoaderPage from "../../commonComponents/LoaderPage";
import ArticleView from "../articles/article-view";
import { getWordFromDOM } from "../../utils/textUtil";
import ConfirmationModal from "../articles/ConfirmationModal";

const initialContent = {
  title: "",
  summary: "",
  payload: "",
  tags: [],

  mobile_thumbnail_url: "",
  mobile_banner_url: "",
  thumbnail_url: "",
  banner_url: "",
  show_cta: false,
  is_verified: false,
  author: [],
};

const ArticleEditor = () => {
  const navigate=useNavigate();
  const location = useLocation();
  const [updated, setUpdated] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [showPreview,setShowPreview] = useState(false)
  const [authors, setAuthors] = useState([]);
  const [loader, setLoader] = useState(false);
  const [content, setContent] = useState(
    location?.state?.content !== undefined
      ? location?.state?.content
      : localStorage.getItem("content") !== null
      ?JSON.parse(localStorage.getItem("content")).external_id&&location?.state?.category?{...initialContent,article_category:location?.state?.category}:JSON.parse(localStorage.getItem("content"))
      : {...initialContent,article_category:location?.state?.category}
  );
  const jsonUser = localStorage.getItem("user");
  const user = JSON.parse(jsonUser);
  const getArticle = async (id) => {
// if(!id){
//   return null
// }
    try {
      setLoader(true)
     
      const tags = await CMSApis.getTags();
      const authors = await CMSApis.getAllAuthors();
      setTags([...tags]);
      setAuthors([...authors?.data]);
      if(!id){
        setLoader(false)
        return null
      }
      const res = await CMSApis.getArticleById(id);
     
      const newObj = {...res,author:authors?.data?.filter(d=>d?.user_details?.first_name===res?.author_details?.name).map((d) => ({author:d?.user_details?.first_name , id:  d?.user_details?.external_id})), tags: res.tags.map((d) => ({tag: d, id: d}))};

      setContent(newObj);
      setLoader(false)
    } catch (error) {
      console.log(error,'error');
      setLoader(false)
    }
  };



  const handleContentChange = (value, type) => {
    const contentObj = {
      ...content,
      [type]: value,
    };
    localStorage.setItem("content", JSON.stringify(contentObj));
    setContent(contentObj);
  };
  useEffect(() => {


      getArticle(location?.state?.content?.external_id,authors?.data);

  }, []);

  const saveDraft = async (articleStatus='DRAFTED') => {
    const newObj = {
      ...content,article_status:articleStatus,  time_to_read: Math.ceil(getWordFromDOM(content.payload) / 225),
      article_category:content.article_category.replaceAll('-','_').toUpperCase(),
      author_id:
        content?.author?.length > 0
          ? content?.author[0]?.id || undefined
          : undefined,       created_by: user.displayName,
          published_by: user.displayName,
   
      tags: content.tags.map((d) => d.id),
    };

    const errorMsg = getError(newObj);
    if (errorMsg) {
      return toast.error(errorMsg);
    }
    try {
      const res = await CMSApis.draftArticleNew(newObj);

      const newObj3 = {...res.data,author:authors?.filter(d=>d?.user_details?.first_name===res?.data?.author_details?.name).map((d) => ({author:d?.user_details?.first_name , id:  d?.user_details?.external_id})), tags: res?.data?.tags?.map((d) => ({tag: d, id: d}))};
      

      setContent(newObj3)
      localStorage.setItem("content", JSON.stringify(newObj3));
      toast.success("Article Saved successfully");
      if(articleStatus!=='DRAFTED'){
        navigate('/articles/'+content.article_category.toLowerCase().replaceAll('_','-'))
      }
      return res.data.external_id
    } catch (error) {

      toast.error("Oops something went wrong");
    }
  };

  const getError = (content) => {

    if (content?.title?.length < 30) {
      return "Title minimum length should be 30 latter ";
    }
    if (  content?.title.trim()?.length < 10) {
      return "Title minimum length should be 30 latter ";
    }
    if (content?.title?.length > 70) {
      return "Title maximum length should be 70 latter ";
    }
    if (content?.tags?.length < 1) {
      return "Tag is not mentioned";
    }
    if (content?.author?.length < 1) {
      return "Author is not mentioned";
    }
    if (!content?.banner_url) {
      return "Desktop Preview Image is not mentioned";
    }
    if (!content?.mobile_banner_url) {
      return "Mobile Preview Image is not mentioned";
    }
    if (!content?.mobile_thumbnail_url) {
      return "Mobile Thumbnail Image is not mentioned";
    }
    if (!content?.thumbnail_url) {
      return "Desktop Thumbnail Image is not mentioned";
    }
    
    if (content?.payload?.length < 100) {
      return "Body minimum length should be 100 latter";
    }

    if (content?.summary?.length < 50) {
      return "Summary minimum length should be 50 latter";
    }
    if (content?.summary?.trim()?.length < 20) {
      return "Summary minimum length should be 50 latter";
    }
    if (content?.summary?.length > 150) {
      return "Summary maximum length should be 150 latter ";
    }
    return false;
  };
const preview=async()=>{
  const newObj = {
    ...content,  time_to_read: Math.ceil(getWordFromDOM(content.payload) / 225),
    article_category:content.article_category.replaceAll('-','_').toUpperCase(),
    author_id:
      content?.author?.length > 0
        ? content?.author[0]?.id || undefined
        : undefined,       created_by: user.displayName,
        published_by: user.displayName,

    tags: content.tags.map((d) => d.id),
  };

  const errorMsg = getError(newObj);
  if (errorMsg) {
    return toast.error(errorMsg);
  }
  setShowPreview(true)
}

const updateArticleStatus = async (id, status) => {
  let newDraftId=''
  try {
    if(!id){
     return null
    }
    const  published_by=user.displayName;
    let res = await CMSApis.updateArticleStatus(id||newDraftId, status,published_by);
  
    const newObj3 = {...content,article_status:res.status};
    
 
    localStorage.setItem("content", JSON.stringify(newObj3));
    setContent(newObj3)
      toast.success("Unpublished Successfully");
    

      setConfirmationModal(false);
    setShowPreview(false);
    setUpdated(!updated);
  } catch (e) {
    setConfirmationModal(false);
    toast.error(e.message);
  }
};
const handleUnPublish=()=>{
 
   
    setConfirmationModal(true);
   

}


if(loader)return <LoaderPage/>
  return (
    <StyledArticleEditor>
      <ArticleTopbar content={content} saveDraft={saveDraft} handleUnPublish={handleUnPublish} preview={preview} />
      {tags.length > 0 && authors.length > 0 && (
        <InformationComponent
          content={content}
          tags={tags}
          setContent={setContent}
          authors={authors}
        />
      )}
      <Editor handleContentChange={handleContentChange} content={content} />
      <ConfirmationModal isOpen={confirmationModal} onClose={setConfirmationModal} onConfirm={updateArticleStatus} text={'Unpublish'} id={content?.external_id}/>
  
      {
            showPreview &&
                <div className='preview-wrapper'>
                    <div style={{width:"80%", backgroundColor:'yellow'}}>
                        <ArticleView  saveDraft={saveDraft} isEdit={true}  authors= {authors} setOuterContent={setContent} article={content} setShowPreview={setShowPreview} updated={updated} setUpdated={setUpdated} />
                    </div>
                </div>
        }
    </StyledArticleEditor>
  );
};

export default ArticleEditor;

export const htmlToDraftBlocks = (html) => {
  const blocksFromHtml = htmlToDraft(html, (nodeName, node) => {
    
    if (nodeName === "img" && node instanceof HTMLImageElement) {
      if (node.parentElement instanceof HTMLDivElement) {
        // for image with div element as a parent
        const entityConfig = {};
        const parentElement = node.parentElement;
        if (parentElement.style.float) {
          // check float style property
          entityConfig.alignment = parentElement.style.float;
        } else if (parentElement.style.textAlign) {
          // check float style property
          entityConfig.alignment = parentElement.style.textAlign;
          if (entityConfig.alignment === "center") {
            entityConfig.alignment = "none";
          }
        }
        entityConfig.src = node.getAttribute
          ? node.getAttribute("src") || node.src
          : node.src;
        entityConfig.alt = node.alt;
        entityConfig.height = node.style.height;
        entityConfig.width = node.style.width;
        return {
          type: "IMAGE",
          mutability: "MUTABLE",
          data: entityConfig,
        };
      }
    }
  });
  const {contentBlocks, entityMap} = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};
