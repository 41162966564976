import { getAuth } from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import PopUpForDocApproval from "../../components/PopUpForDocApproval";
import { updateAdvisorDocStatus } from "../../Services/AdvisorDoc";
import { preSignedUrlDownload } from "../../utils/preSignedUrl";
import PopUPCms from "../PopUPCms";
import Backdrop from "../PopUPCms/BackDrop";

const Row = ({
  type,
  data,
  docArr,
  updateDocArr,
  index,
  handleDeleteExemption,
  userId,
}) => {
  const [show, setShow] = useState(false);
  const [actionName, setActionName] = useState(null);

  const handleCross = () => {
    setShow(false);
  };
  const onHideModelClick = (action) => {
    setShow(true);
    setActionName(action);
  };
  const handleClick = (action) => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data1) {
          updateAdvisorDocStatus(data1, data.external_id, action).then(
            (newdata) => {
              let arr = docArr;
              arr.splice(index, 1, { ...newdata, rank: 2 });
              updateDocArr([...arr]);
              handleCross();
            }
          );
        });
      }
    });
  };
  const actions = () => {
    if (data.document_action === "APPROVED") {
      return <p className="approve">Approved</p>;
    }
    if (data.document_action === "REJECTED") {
      return <p className="reject">Rejected</p>;
    }
    if (type === "advisor" && data.document_action === "PENDING") {
      return <p className="waiting">Waiting for Approval...</p>;
    }
    if (type !== "advisor" && data.document_action === "PENDING") {
      return (
        <>
          <p
            onClick={() => {
              onHideModelClick("APPROVE");
            }}
          >
            Approve
          </p>
          <p
            onClick={() => {
              onHideModelClick("REJECT");
            }}
          >
            Reject
          </p>
        </>
      );
    }
  };

  return (
    <div className="rows">
      <div className="c1">
        <p className="r1">{data.name}</p>
      </div>
      <p className="c2 r2">{data.description}</p>
      <span
        className="c3 r3"
        href={data.links.links[0]}
        onClick={() => {
          getAuth().onAuthStateChanged(function (user) {
            if (user) {
              user.getIdToken().then(function (newToken) {
                preSignedUrlDownload(
                  data.links.links[0],
                  userId,
                  newToken
                ).then((d) => {
                  window.location.href = d;
                }).catch((d)=>{
                  toast.error(d?d:'Oops Something went wrong')
                });
              }).catch((d)=>{
                toast.error(d?d:'Oops Something went wrong')});
            }
          });
        }}
      >
        {`${data.links &&data.links.links[0].length>25?  data.links.links[0].slice(-25):data.links.links[0]
        }`}
      </span>
      <p className="c2 r2">{data.type && data.type.replace(/_/gi, " ")}</p>
      <div className="c4 r4">{actions()}</div>
      <div
        className="c5 r5"
        onClick={() => {
          handleDeleteExemption(data);
        }}
      >
        <AiOutlineDelete />
      </div>
      <PopUPCms show={show} handleCross={handleCross}>
        <PopUpForDocApproval
          handleCross={handleCross}
          actionName={actionName}
          name={data.name}
          docArr={docArr}
          handleClick={handleClick}
        />
      </PopUPCms>
      <Backdrop onHideModelClick={handleCross} show={show} />
    </div>
  );
};

export default Row;
