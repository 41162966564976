import styled from "styled-components";
import {dim} from "../../styles/theme";

export const StyledDivider = styled.div`
  .childNode {
    width: fit-content;
    height: fit-content;
    position: absolute;
    right: 50%;
    bottom: -${dim._12px};
    padding: ${dim._4px};
    font-size: ${dim._14px};
  }
`;
