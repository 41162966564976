import styled from 'styled-components';
import { dim } from '../../../../../../styles/theme';

export const Wrapper = styled.div`
	width: ${({ onboarding }) =>
		onboarding ? dim._scale(760) : dim._scale(926)};
	box-sizing: border-box;
	background-color: #fff;
	padding: ${dim._34px};
	border-radius: ${({ onboarding }) =>
		onboarding ? `${dim._10px}` : `${dim._4px} ${dim._4px} 0px 0px`};
	.notes {
		padding: 4px 16px;
		font-size: 14px;
		margin-top: 16px;
		display: inline-block;
	}

	.error {
		background-color: #dc354522;
		color: #450c11;
		border-left: 5px solid #dc354544;
	}

	.success {
		background-color: #2684ff22;
		color: #2684ff;
		border-left: 5px solid #2684ff;
	}

	.error {
		background-color: #dc354522;
		color: #450c11;
		border-left: 5px solid #dc354544;
	}

	.heading {
		font-size: ${dim._16px};
		line-height: ${dim._22px};
	}

	.label {
		font-size: ${dim._12px};
		margin-bottom: ${dim._8px};
	}

	.label-1 {
		width: ${dim._scale(410)};
	}

	.add-component-container-1 {
		display: flex;
		margin-top: ${dim._28px};
		align-items: center;
		box-sizing: border-box;
	}

	.add-component-container {
		display: flex;
		margin-bottom: ${dim._16px};
		align-items: center;
		box-sizing: border-box;
	}

	.component-name {
		width: ${dim._scale(376)};
		height: ${dim._32px};
		background: #f1f5f7;
		border-radius: ${dim._4px};
		border: 0px;
		outline: 0;
		padding: 0px ${dim._8px};
		box-sizing: border-box;
		/* Replace Default styling (arrow) */
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('data:image/svg+xml;utf8,<svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6.9195 6.76684C7.11422 6.95654 7.42578 6.95654 7.61493 6.76684L13.54 1" stroke="%238E8E93" stroke-miterlimit="10" stroke-linecap="round"/></svg>');
		background-repeat: no-repeat;
		background-position-y: 50%;
		background-position-x: 98%;
	}

	.close-btn {
		box-sizing: border-box;
		width: ${dim._32px};
		height: ${dim._32px};
		background: rgba(220, 53, 69, 0.1);
		border-radius: ${dim._16px};
		display: flex;
		align-items: center;
		justify-content: center;
		color: #dc3545;
		margin-left: ${dim._24px};
		border: 1px solid rgba(220, 53, 69, 0.2);

		&:hover {
			background: rgba(220, 53, 69, 0.4);
		}
	}
	.close-btn-not-allowed {
		cursor: not-allowed;
	}
	.add-btn {
		box-sizing: border-box;
		width: ${dim._32px};
		height: ${dim._32px};
		background: rgba(0, 255, 0, 0.3);
		border-radius: ${dim._16px};
		display: flex;
		align-items: center;
		justify-content: center;
		color: #15be53;
		margin-left: ${dim._24px};
		border: 1px solid rgba(0, 255, 0, 0.3);
		font-weight: 500;

		&:hover {
			background: rgba(0, 255, 0, 0.4);
		}
	}

	.pos-0 {
		position: relative;
		width: ${dim._scale(926)};
	}

	.other-input {
		background: #f1f5f7;
		border-radius: 4px;
		padding: 8px;
		border: 0;
		outline: 0;
	}
`;

export const AddButton = styled.div`
	box-sizing: border-box;
	width: ${dim._32px};
	height: ${dim._32px};
	background: ${({ disabled }) =>
		disabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.2)'};
	border-radius: ${dim._16px};
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ disabled }) => (disabled ? '#000033' : '#15be53')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	margin-left: ${dim._24px};
	border: 1px solid
		${({ disabled }) =>
			disabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.2)'};
	font-weight: 500;

	&:hover {
		background: ${({ disabled }) => !disabled && 'rgba(0, 255, 0, 0.4)'};
	}
`;
