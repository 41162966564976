import React, { useEffect, useState } from "react";
import { CMSApis } from "../../Services/cmsServices";
import { Wrapper } from "./Articles.styled";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import MyTable from "./Table";
import Cards from "./Cards";
import { DatePicker, Dropdown, message, Space } from "antd";
import CustomDropdown from "./Dropdown";
import { useParams } from "react-router-dom";
import { replaceAndUppercase } from "../../utils/textUtil";
import LoaderPage from "../../commonComponents/LoaderPage";

const Articles = () => {
  const navigate = useNavigate();
  const [allArticles, setAllArticles] = useState([]);
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [content, setContent] = useState("article");
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nextToken, setNextToken] = useState(0);
  const [backToken,setBackToken] = useState(0);
  const [publishType, setPublishType] = useState(null);
  const { RangePicker } = DatePicker;
  const [authorData, setAuthorData] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedAuthorName, setSelectedAuthorName] = useState('');
  const pageSize = 10;
  const { id } = useParams();
  const category = replaceAndUppercase(id);
  const [pageNumber,setPageNumber] = useState(0);



  const getArticle= async (nextToken) => {
    let startDate = selectedDates?.start ? selectedDates.start : null ;
    let endDate = selectedDates?.end ? selectedDates.end : null;
    try {
      setLoading(true);
      const res = await CMSApis.getArticles(
        category,
        publishType,
        nextToken,
        pageSize,
        selectedAuthor,
          startDate,
          endDate
      );
      setAllArticles(res.article_list);
      setNextToken(res.next_token);


      setBackToken(res.next_token - pageSize);
    } catch (error) {

      toast.error("Failed to fetch articles");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedAuthor(null)
    setSelectedAuthorName('')
    getArticle(0)
    setPageNumber(0)
   
  },[id]);





  useEffect(() => {
  
    getArticle(nextToken)
  }, [
    publishType,
     selectedAuthor
     ,selectedDates
     ,updated,
     ]);


  useEffect(() => {
    const getAuthors = async () => {
      try {
        const res = await CMSApis.getAuthors();
        setAuthorData(res);
      } catch (error) {
        console.log(error);
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };
    getAuthors();
  }, []);


  const editArticle = (article, status) => {
    // navigate("/editor", {
    //   state: {
    //     content: article,
    //     status: status,
    //   },
    // });
    navigate("/articles/editor/"+id, {
      state: {
        content: article,
        status: status,
      },
    });
  };

  const handlePagination = async (token) => {
   let lastToken = token;
   let startDate = selectedDates?.start ? selectedDates.start : null ;
   let endDate = selectedDates?.end ? selectedDates.end : null;
      try {
        setLoading(true)
        const res = await CMSApis.getArticles(
          category,
          publishType,
          token,
          pageSize,
          selectedAuthor,
           startDate,
          endDate
        );
        setAllArticles(res.article_list);

  
        setBackToken(lastToken - pageSize);

        setNextToken(() => res.next_token);
        setPageNumber((pageNumber)=>pageNumber+1)
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch articles");
      } finally {
        setLoading(false);
      }
    
  };
  

  const handleBack = async (backToken) =>{
    let startDate = selectedDates?.start ? selectedDates.start : null ;
    let endDate = selectedDates?.end ? selectedDates.end : null;
    try {
      setLoading(true)
        const res = await CMSApis.getArticles(
          category,
          publishType,
          backToken,
          pageSize,
          selectedAuthor,
          startDate,
         endDate
        );
        setAllArticles(res.article_list);
        setNextToken(() => res.next_token);

          if(pageNumber > 0){
            setPageNumber((pageNumber)=>pageNumber-1);
          }
          else{
            setPageNumber(0)
          }



        if(backToken > 0){
            setBackToken((token)=> token - pageSize );
        }else{
            setBackToken(0)
        }


      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch articles");
      } finally {
        setLoading(false);
      }

  }
  const handleDateChange = (dates) => {
    if (dates) {
      let startDate = new Date(dates[0])?.getTime();
      let endDate = new Date(dates[1])?.getTime();
      setSelectedDates({ start: startDate, end: endDate });
      setNextToken(0);
    }
    else{
        setSelectedDates(null);
    }
  };
  
  const disabledFutureDate = current => {
    const today = new Date();
    return current && current > today;
  };

  return (
    <Wrapper>

      <div>
        <div>
          <p className="category-name">{id?.split('-').join(" ")}</p>
        </div>

        <Cards
          category={category}
          setPublishType={setPublishType}
          setNextToken={setNextToken}
          updated ={updated}
          setPageNumber={setPageNumber}
        />
        <div className="top-buttons">
          <div className="date-picker-container">
            <RangePicker disabledDate={disabledFutureDate}  onChange={handleDateChange} />
            <CustomDropdown
              authorData={authorData}
              selectedAuthor={selectedAuthor}
              setSelectedAuthor={setSelectedAuthor}
              selectedAuthorName = {selectedAuthorName}
              setSelectedAuthorName={setSelectedAuthorName}
              setNextToken={setNextToken}
              setPageNumber={setPageNumber}
            />
          </div>

          <div className="create-btn-container">
            <button
              onClick={() => {
         
                navigate("/articles/editor/"+id, {
                  state: {category:id },
                });
              }}
              className="create-btn"
            >
              + &nbsp; &nbsp;Create new
            </button>
          </div>
        </div>
        <MyTable
          tableData={allArticles}
          editArticle={editArticle}
          updated={updated}
          setUpdated={setUpdated}
          loading={loading}
          nextToken={nextToken}
          setNextToken={setNextToken}
          pageNumber={pageNumber}
          authors={authorData}
        />
        <div className="next-btn-container">
            { backToken > -1  && nextToken != pageSize &&
                <button className="back-btn" onClick={() => handleBack(backToken)}> Back</button>
            }
        
          {nextToken > 0 && (
            <button className="next-btn" onClick={() => handlePagination(nextToken)}>Next </button>
          )}
        </div>
      </div>

    </Wrapper>
  );
};

export default Articles;
