import React, { useState, useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./onboardAuthor.css";
import Button from "../../commonComponents/Button";
import UploadFile from "../../commonComponents/uploadFile";
import SocialMedia from "../../commonComponents/SocialMedia";
import Alert1 from "../../commonComponents/Alert/Alert1";
import { getAuth } from "@firebase/auth";
import BASE_URL from "../../Services/API_ENDPOINT";
import { uploadFile } from "../../utils/uploadFileUtil";

const OnboardAuthor = () => {
    const [user_details, setUser_details] = useState({
        first_name: "",
        email: "",
        mobile: "",
        last_name: "",
        middle_name: "",
        user_type: "Author",
        image: "",
    });
    const [ShowAlert, setShowAlert] = useState(null);
    const [SubmitMsg, setSubmitMsg] = useState(null);
    const [interest, setInterest] = useState("");
    const [specialization, setSpecialization] = useState([]);
    const [brief, setBrief] = useState("");
    const [Trigger, setTrigger] = useState(true);
    const [social_links, setSocial_links] = useState({
        facebook: "",
        linkedin: "",
        instagram: "",
        twitter: "",
        youtube: "",
    });

    const [formErrors, setFormErrors] = useState({});

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setUser_details({ ...user_details, [name]: value });
    };

    const handelInterest = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setInterest(value);
    };

    const handelBio = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setBrief(value);
    };

    const [uploadAvatar, setUploadAvatar] = useState(false);
    const [originalAvatarLink, setOriginalAvatarLink] = useState("");

    const handleAvatarUpload = async (e) => {
        const imageFile = e.target.files[0];
        const bucketName = "prosperr-images";
        const bucketZone = "ap-south-1";
        try {
            // USE PRESIGNED URL
            const imageUrl = await uploadFile(
                bucketName,
                bucketZone,
                imageFile
            );
            setUser_details({
                ...user_details,
                image: imageUrl,
            });
        } catch (e) {
            console.log(e);
        }
        setUploadAvatar(true);
    };

    const [first, SetFirst] = useState({
        loading: false,
        data: [],
    });

    useEffect(() => {
        const url = `${BASE_URL}specializations/v1/all`;
        if (getAuth().currentUser !== null && Trigger) {
            getAuth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(function (idToken) {
                    const fetchData = async () => {
                        try {
                            const response = await fetch(url, {
                                method: "GET",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization: `${idToken}`,
                                    Source: "cms",
                                },
                            });
                            const items = await response.json();
                            SetFirst({
                                loading: true,
                                data: items,
                            });
                        } catch (error) {
                            console.log("error", error);
                        }
                    };

                    fetchData();
                });
            setTrigger(false);
        }
    }, []);

    const handleCancelAvatarUpload = () => {
        setUploadAvatar(null);
    };

    const validate = (type, value) => {
        const errors = { ...formErrors };
        const regex = {
            email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
            mobile: /^([+]\d{2})?\d{10}$/,
        };

        if (!value) {
            errors[type] = `${type} is required`;
        } else if (regex[type] && !regex[type].test(value)) {
            errors[type] = `Not a valid ${type}`;
        } else if (value.length < 2) {
            errors[type] = `${type} cannot be less than 2 characters`;
        } else {
            delete errors[type];
        }

        setFormErrors(errors);
    };

    const handleOnBlur = (e) => {
        const { name, value } = e.target;
        validate(name, value);
    };

    const handleOnboardAuthor = (e) => {
        e.preventDefault();

        let newSpecialization = "";
        specialization.forEach((element, index) => {
            if (index === specialization.length - 1) {
                newSpecialization += element.value;
            } else {
                newSpecialization += element.value + ", ";
            }
        });

        getAuth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
                let data = {
                    user_details: user_details,
                    interest: interest,
                    specialization: newSpecialization,
                    brief: brief,
                    social_links: social_links,
                };
                fetch(`${BASE_URL}authors/v1/`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `${idToken}`,
                        Source: "cms",
                    },
                    body: JSON.stringify(data),
                }).then((result) => {
                    if (
                        Object.keys(formErrors).length === 0 &&
                        (result.status === 201 || result.status === 200)
                    ) {
                        setSubmitMsg("success");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 1000);
                    } else if (result.status === 409) {
                        setSubmitMsg("Email or mobile number is already exist");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 4000);
                    } else if (result.status === 400) {
                        result.json().then((response) => {
                            setSubmitMsg(response.details[0]);
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                            }, 1000);
                        });
                    } else {
                        setSubmitMsg("error");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 1000);
                    }
                });
            });
    };
    return (
        <div className="form_container" autoComplete="off">
            <h2>Onboard Author</h2>
            <div className="box" onSubmit={handleOnboardAuthor}>
                <p>Upload Profile</p>
                <UploadFile
                    fileId="thumbnail"
                    acceptFileType="image/*"
                    labelName=""
                    icon={
                        <AccountCircleIcon
                            style={{
                                fontSize: 80,
                            }}
                        />
                    }
                    uploadClassName="avatarUpload"
                    uploaded={uploadAvatar}
                    uploadLink={originalAvatarLink}
                    onFileUpload={handleAvatarUpload}
                    cancelUpload={handleCancelAvatarUpload}
                />
                <div className="label">
                    <div className="side">First Name:&nbsp;&nbsp;</div>
                    <form>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="*First Name"
                            autoComplete="off"
                            value={user_details.first_name}
                            onChange={handleInput}
                            onBlur={handleOnBlur}
                        />
                    </form>
                </div>
                {!formErrors.first_name ? null : (
                    <p className="error_p">{formErrors.first_name}</p>
                )}

                <Alert1 SubmitMsg={SubmitMsg} ShowAlert={ShowAlert} />
                <div className="label">
                    <div className="side">Middle Name:&nbsp;&nbsp;</div>
                    <input
                        type="text"
                        name="middle_name"
                        id="middle_name"
                        placeholder="Middle Name"
                        value={user_details.middle_name}
                        onChange={handleInput}
                        autoComplete="off"
                    />
                </div>

                <div className="label">
                    <div className="side">Last Name:&nbsp;&nbsp;</div>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="*Last Name"
                        value={user_details.lastname}
                        onChange={handleInput}
                        onBlur={handleOnBlur}
                        autoComplete="off"
                    />
                </div>

                {!formErrors.last_name ? null : (
                    <p className="error_p">{formErrors.last_name}</p>
                )}

                <div className="label">
                    <div className="side">Email:&nbsp;&nbsp;</div>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="*Email"
                        value={user_details.email}
                        onChange={handleInput}
                        onBlur={handleOnBlur}
                        autoComplete="off"
                    />
                </div>

                {!formErrors.email ? null : (
                    <p className="error_p">{formErrors.email}</p>
                )}

                <div className="label">
                    <div className="side">Phone No:&nbsp;&nbsp;</div>
                    <div className="phone">
                        <select name="country" id="country">
                            <option value="+91">+91</option>
                            <option value="+1">+1</option>
                            <option value="+43">+43</option>
                            <option value="1-684">1-684</option>
                        </select>

                        <input
                            type="text"
                            id="phone"
                            maxLength="10"
                            name="mobile"
                            placeholder="Phone mobile"
                            value={user_details.mobile}
                            onWheel={(e) => e.target.blur()}
                            onChange={handleInput}
                            onBlur={handleOnBlur}
                            autoComplete="off"
                        />
                    </div>

                    {!formErrors.mobile ? null : (
                        <p className="error_p">{formErrors.mobile}</p>
                    )}
                </div>
                <div className="label">
                    <div className="side">Your Hobbies:&nbsp;&nbsp;</div>
                    <textarea
                        type="text"
                        id="bio"
                        name="interest"
                        placeholder="Your Hobbies"
                        value={interest}
                        onChange={handelInterest}
                    />
                </div>
                <div className="label">
                    <div className="side">Breif detals:&nbsp;&nbsp;</div>
                    <textarea
                        type="text"
                        id="bio"
                        name="brief"
                        placeholder="Breif detals (In about 60-100 words)"
                        value={brief}
                        onChange={handelBio}
                    />
                </div>

                <SocialMedia
                    social_links={social_links}
                    setSocial_links={setSocial_links}
                />

                {
                    <Button
                        buttonClassName={
                            user_details.first_name !== "" &&
                            user_details.last_name !== "" &&
                            Object.keys(formErrors).length === 0
                                ? "buttonOnboardAuthor"
                                : "btnhide"
                        }
                        onButtonClick={
                            user_details.first_name !== "" &&
                            user_details.last_name !== ""
                                ? handleOnboardAuthor
                                : null
                        }
                        buttonName="Submit"
                    />
                }
            </div>
        </div>
    );
};

export default OnboardAuthor;
