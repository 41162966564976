import React, { useContext, useEffect, useState } from "react";
import AdvisorContext from "../../../Context/Advisor/AdvisorContext";
import Alert1 from "../../Alert/Alert1";
import EditBtn from "../../EditBtn";
import EditDeletebtn from "../../EditDeletebtn/EditDeletebtn";
import FlexRow from "../../FlexRow/FlexRow";
import PopupBtn from "../../Popup/Popup";
import { Table, Tr, Td, Th } from ".././AdvisorTable.styled";
import { getAuth } from "firebase/auth";
import BASE_URL from "../../../Services/API_ENDPOINT";
import { getAuthToken } from "../../../Services/httpUtil";

const AppointmentTable = ({ Time, Id, UpdateSlot, arrayData, token }) => {
  const a = useContext(AdvisorContext);
  const [ShowAlert, setShowAlert] = useState(false);
  const [SubmitMsg, setSubmitMsg] = useState("");
  const [appointments, setAppointments] = useState([]);

  const getDateTime = (timeStamp) => {
    var date = new Date(timeStamp);

    var month = date.getMonth() + 1;
    var day = date.getDate();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;
    var str = date.getFullYear() + "-" + month + "-" + day;

    return str;
  };
  useEffect(() => {
    if (arrayData.isAppointmentsActive || a.Delete) {
      getAuthToken().then(function (idToken) {
        fetchData(idToken);
      });
      a.setDelete(false);
    }
  }, [a.Delete]);

  const fetchData = async (idToken) => {
    try {
      const response = await fetch(
        ` ${BASE_URL}appointments/v1/all?advisorId=${Id}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
            Source: "cms",
          },
        }
      );
      const data = await response.json();

      function getDateWiseTimeSlots(authorData = []) {
        let slotsWithDateTime = authorData.map((item) => {
          return {
            ...item,
            start_time: item.start_time,
            end_time: item.end_time,
            user_email: item.email,
            user_full_name: item.full_name,
            user_mobile: item.mobile,
            advisor_id: item.advisor_id,
            date_time: getDateTime(item.start_time),
            label:
              new Date(item.start_time).toLocaleTimeString("en-US") +
              "-" +
              new Date(item.end_time).toLocaleTimeString("en-US"),
            value: item.id,
          };
        });
        return slotsWithDateTime;
      }
      setAppointments(
        getDateWiseTimeSlots(data)
          .filter(
            (d) => new Date(d.start_time).getDate() >= new Date().getDate()
          )
          .sort(function (a, b) {
            return (
              new Date(a.start_time).getDate() -
              new Date(b.start_time).getDate()
            );
          })
      );
      a.setDelete(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      <Alert1 ShowAlert={ShowAlert} SubmitMsg={SubmitMsg} mid={true} />
      <Table>
        <Tr>
          <Th>User Name</Th>
          <Th>Date</Th>
          <Th>Time</Th>

          <Th> Contact</Th>

          <Th>User email</Th>
        </Tr>

        {appointments.length !== 0 ? (
          appointments
            .filter((d) => {
              return `${d.advisor_id === Id}`;
            })
            .map((d, i) => {
              return (
                <Tr flag={d.flag} appointment_status={d.appointment_status}>
                  <Td>{d.user_full_name}</Td>
                  <Td>
                    {new Date(d.start_time).getDate() +
                      "/" +
                      (new Date(d.start_time).getMonth() + 1) +
                      "/" +
                      new Date(d.start_time).getFullYear()}
                  </Td>
                  <Td>
                    <FlexRow>
                      {
                        <div className="">
                          {new Date(d.start_time).toLocaleTimeString("en-US") +
                            " - " +
                            new Date(d.end_time).toLocaleTimeString("en-US")}
                        </div>
                      }

                      {d.flag === "AVAILABLE" ? (
                        <div className="">
                          <EditDeletebtn
                            arrayData={arrayData}
                            HandleClick={(token) => {
                              a.deleteapi(
                                `${BASE_URL}timeSlots/v1/deleteTimeSlot?advisorId=${Id}&slotId=${d.id}`,
                                {
                                  method: "DELETE",

                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    Source: "cms",
                                    Authorization: `${token}`,
                                  },
                                }
                              );
                              setShowAlert(true);
                              setSubmitMsg("Slots Deleted");
                              setTimeout(() => {
                                setShowAlert(false);
                              }, 1000);
                            }}
                          />
                        </div>
                      ) : null}
                    </FlexRow>
                  </Td>{" "}
                  <Td> {d.user_mobile}</Td>
                  {
                    <Td>
                      {" "}
                      <FlexRow>
                        {" "}
                        <div className="">{d.user_email}</div>{" "}
                        {d.appointment_status !== "CANCELED" ? (
                          <span className="icon">
                            <PopupBtn
                              Id={d.appointment_id}
                              id={d.appointment_id}
                              AdvisorId={d.advisor_id}
                              arrayData={arrayData}
                              pop={false}
                              token={token}
                              Trigger={
                                <EditDeletebtn
                                  pop={false}
                                  id={d.appointment_id}
                                  arrayData={arrayData}
                                />
                              }
                            />
                            &nbsp;
                            <PopupBtn
                              Id={d.appointment_id}
                              AdvisorId={d.advisor_id}
                              arrayData={arrayData}
                              pop={true}
                              Trigger={<EditBtn />}
                              token={token}
                            />
                          </span>
                        ) : null}
                      </FlexRow>
                    </Td>
                  }
                </Tr>
              );
            })
        ) : (
          <Tr>
            {
              <td className="td" colSpan="12">
                No Appointments available
              </td>
            }
          </Tr>
        )}
      </Table>
    </div>
  );
};

export default AppointmentTable;
