import React from 'react';
import { NumberInputStyle } from './NumberInput.styled';
import FormErrormsgHandler from '../FormErrormsgHandler';

const NumberInput = ({
	id,
	label,
	placeholder,
	value = 0,
	name,
	style,
	isValid,
	handleNumberInput,
	error
}) => {
	return (
		<NumberInputStyle isValid={isValid}>
			{label && <label>{label}</label>}
			<input
				id={id}
				type='number'
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={handleNumberInput}
				onWheel={(e) => e.target.blur()}
			/>
			{error && value.length < 1 && (
				<FormErrormsgHandler className='error'>
					please write {placeholder}
				</FormErrormsgHandler>
			)}
		</NumberInputStyle>
	);
};

export default NumberInput;
