import React from "react";
import { ClientDetails } from "../../../SuperClientProfile/SuperSaverProfile.styled";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { USER_TYPE } from "../../../../utils/localStorageKeys";
import { USER_ROLE } from "../../../../constants/userRole";
const AdvisorSelection = ({
  advisorId,
  assignExpert,
  setAdvisorId,
  userId,
  handleAdvisorUpdate,
  advArr,
  title = "Advisor",
  name,
}) => {
  const userType = JSON.parse(localStorage.getItem(USER_TYPE));
  const userRole = userType?.user_roll;
  
 if(userRole == USER_ROLE.ADMIN){

 
  return (
    <>
      <div className="clientox">
        <ClientDetails className="">
          <div
            className=""
            style={{
              width: "265px",
              padding: "24px 24px 0",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel id="demo-simple-select-standard-label">
                {title}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={advisorId}
                name={name}
                onChange={(e) => {
                  handleAdvisorUpdate(e);
                }}
                label="Advisor"
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 200 },
                  },
                }}
              >
                {name === "secondary_advisor_id" && (
                  <MenuItem value={name === "secondary_advisor_id" ? null : ""}>
                    <em>None</em>
                  </MenuItem>
                )}
                {advArr.map((d, i) => (
                  <MenuItem value={d.advisorId} key={i}>
                    {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </ClientDetails>
      </div>
    </>
  );
}
return null;
};

export default AdvisorSelection;
