import styled from "styled-components";
import { dim } from "../../styles/theme";

const StyledUserSideBar = styled.div`
  width: 20%; 
  .userSidebar {
    position: static;
    width: 100%;
  }
`;
const StyledLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  height: auto;
  gap: 14px;
  width: 100%;
  .layoutContainer {
    width: 100%;
    height: calc(100vh - ${dim._92px});
    overflow: scroll;
  }
`;

export { StyledUserSideBar, StyledLayout };
