import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../commonComponents/Button";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import LoaderPage from "../../commonComponents/LoaderPage";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";

import { getSuperSaver } from "../../Services/SuperSaver";
import { Main } from "./SuperSaverBooking.styled";
import DataGridCustomTooltip from "../../commonComponents/DataGridCustomTooltip";
import { Tooltip } from "@mui/material";

const SuperSaverBooking = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [value, setValue] = React.useState(
    localStorage.getItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE) ===
      "ITR_FILING" ||
      localStorage.getItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE) ===
        "SUPER_SAVER"
      ? localStorage.getItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE) ||
          "SUPER_SAVER"
      : "ALL"
  );

  const handleChange = (event, newValue) => {
    localStorage.setItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE, newValue);
    setValue(newValue);
    navigate("/super-saver-bookings");
  };
  useEffect(() => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          getSuperSaver(data).then(function (value) {
            setData(value.sort((a, b) => b.created_at - a.created_at));
            setLoader(false);
          });
        });
      }
    });
  }, []);

  const renderDetailsButton = (params) => {
    let isAdvisorAssigned =
      params.row && params.row.advisor_details ? true : false;
    return (
      <Tooltip title="Booking appointment is disabled as of now." arrow={true} placement="left" >
        <span className="userName">
          <Button
            buttonClassName={"btn"}
            style={{
              width: "60px",
              border: !isAdvisorAssigned ? "#8c8c8c" : "",
              backgroundColor: '#ccc',
              cursor: 'default',
            }}
            buttonName={"Book"}
          />
        </span>
      </Tooltip>
    );
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "service_type",
      headerName: "Category type",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "actions",
      headerName: "Book Appointment",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: renderDetailsButton,
      showInMenu: false,
    },
  ];

  const handleOnCellClick = (params, navigate) => {
    if (params.field === "actions") {
      return;
    }
    navigate("/super-saver-bookings/users", {
      state: { item: params.row },
    });
  };

  function rowFunction(value, index, array) {
    return {
      id: index,
      name: value.full_name,
      mobile: value.mobile,
      service_type: value.category_type,
      super_saver_booking_id: value.external_id,
      service_id: value.category_id,
      email: value.email,
      invite_id: value.invite_id,
      onboard_id: value.onboard_id,
      payment_status: value.payment_status,
      user_id: value.external_id,
      advisor: value.advisor,
      activity: value.activity,
    };
  }
  return loader ? (
    <LoaderPage />
  ) : (
    <Main className="super">
      <TabContext value={value} variant="fullWidth">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="All User" value="ALL" sx={{ width: 200 }} />
            <Tab
              label="Super Saver User"
              value="SUPER_SAVER"
              sx={{ width: 200 }}
            />
            <Tab
              label="ITR Filing User"
              value="ITR_FILING"
              sx={{ width: 200 }}
            />
          </TabList>
        </Box>
        <TabPanel value="ALL" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={columns}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/super-saver-bookings/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
        <TabPanel value="ITR_FILING" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data.filter((it) => it.product === value)}
                columns={columns}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/super-saver-bookings/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
        <TabPanel value="SUPER_SAVER" sx={{ width: "100%", padding: 0 }}>
          <CardAddSlot>
            <BasicExampleDataGrid
              data={Data.filter((it) => it.product === value)}
              columns={columns}
              handleOnCellClick={handleOnCellClick}
              rowFunction={rowFunction}
              enablePaging={true}
              pathname="/super-saver-bookings/"
            />
          </CardAddSlot>
        </TabPanel>
      </TabContext>
    </Main>
  );
};

export default SuperSaverBooking;
