import { httpClient } from "./httpUtil";

const SalaryIncomeServices = {
  saveSalaryIncome: (data, user_id) => {
    return httpClient.post(
      "/incomes/v1/add?user_id=" + user_id + "&type=SALARY",
      data,
      {},
      {},
      true
    );
  },
  getSalaryIncome: (user_id, financial_year_id) => {
    return httpClient.get(
      "/incomes/v1/get?user_id=" +
        user_id +
        "&type=SALARY&financial_year_id=" +
        financial_year_id,
      {},
      { userId: user_id },
      true
    );
  },
  getCompanyDetails: () => {
    return httpClient.get("/company-profile/all", {}, {}, true);
  },
  getSalaryComponents: () => {
    return httpClient.get("/common/v1/earning-deduction", {}, {}, true);
  },
};

export default SalaryIncomeServices;
