import React, { useState } from 'react';
import { CategoryStyle } from './Category.styled';

const Category = ({ item, active, style, handleIncomeCategory }) => {
	return (
		<CategoryStyle
			id={item.type}
			active={active}
			onClick={handleIncomeCategory}
			style={style}
		>
			{active ? (
				<img id={item.type} src={item.active_icon} alt='' />
			) : (
				<img id={item.type} src={item.icon} alt='' />
			)}
			<p id={item.type}>{item.category}</p>
		</CategoryStyle>
	);
};

export default Category;
