import React from "react";
import { StyledPreviousCallDetails } from "./PreviousCallDetails.styled";
// import Chip from '../../../../common-components/Chip';
// import TextButton from '../../../../common-components/TextButton';
// import { dim } from '../../../../styles/theme';
// import { dateFormatter, getDateDifference } from '../../../../utils/timeUtil';
// import { getColorsByPriority, handleViewDetails } from '../../utils';
import { useDispatch, useSelector } from "react-redux";
import { dim, theme } from "../../../../styles/theme";
import Chip from "../../../../commonComponents/Chip";
import { getTimeZoneFromTimestamp, removeSpecialCharacter } from "../../utils";
import TextButton from "../../../../commonComponents/TextButton";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CONSULTATION_STATUS } from "../../constant";
import { getColorByImportance } from "../../../../utils/getColorByImportance";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import { actions } from "../../store/ConsultationAction";
import { dateFormatter, getDateDifference } from "../../../../utils/timeUtil";
// import PreviousCallDetailView from '../../Screens/PreviousCallDetailView';

const PreviousCallDetails = ({ callDetails, sessionsIndex, index }) => {
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");
  const consultationData = useSelector(
    (state) => state.ConsultationReducer.consultationData
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const chipStyle = {
    padding: `${dim._4px} ${dim._8px}`,
    borderRadius: dim._4px,
    fontWeight: 400,
  };

  const advisorName =
    callDetails?.advisor_first_name + " " + callDetails?.advisor_last_name;
  const callTitle = callDetails?.title;

  const addForm = () => {
    const params = new URLSearchParams();
    let call_summary_id = callDetails?.call_summary_id;
    let call_booking_id = callDetails?.call_booking_id;
    params.set("call_booking_id", call_booking_id); // Use set() instead of append()
    params.set("user_id", user_id); // Use set() instead of append()
    params.set("call_title", removeSpecialCharacter(callTitle)); // Use set() instead of append()
    params.set("start_time", callDetails?.start_time); // Use set() instead of append()
    params.set("user_name", callDetails?.userName); // Use set() instead of append()
    localStorage.setItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID, call_summary_id);
    dispatch({
      type: actions.SET_CONSULTATION_ID,
      data: call_summary_id,
    });
    dispatch({
      type: actions.SET_MOM_DATA,
      data: null,
    });
    const queryString = params.toString();
    // Use the navigate function to update the URL with the new query string
    // navigate({ state: state.state, search: queryString });
    return queryString;
    // history.push({ search: params.toString() });
  };
  const handleViewBtn = () => {
    const query = addForm();
    callDetails.CTAAction(navigate, query);
  };

  return (
    <StyledPreviousCallDetails marginTop={dim._8px} width="100%">
      <div className="firstLine">
        <div className="actions">
          {callDetails?.action_items?.map((actionable, i) => (
            <Chip
              key={i}
              style={{
                ...chipStyle,
                ...getColorByImportance(actionable.importance),
              }}
            >
              {actionable.title}
            </Chip>
          ))}
        </div>
      </div>
      <div className="secondLine">
        <div className="callInfo">
          <div className="callTitle">{callTitle} </div>
          <div className="dateCompo iconComponent">
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/calenderIcon.svg`}
              alt=""
            />
            <div>{dateFormatter(callDetails?.start_time)} </div>
          </div>
          <div className="callDuration iconComponent">
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/durationIcon.svg`}
              alt=""
            />
            <div>
              {
                getDateDifference(
                  callDetails?.start_time,
                  callDetails?.end_time
                ).minutes
              }{" "}
              Mins call
            </div>
          </div>
        </div>
        {callDetails.isCTAAvailable && (
          <div className="viewDetailsBtn">
            <TextButton
              style={
                !callDetails?.CTALabel.includes("Confirm")
                  ? { textDecoration: "underline", fontSize: dim._14px }
                  : {
                      textDecoration: "underline",
                      fontSize: dim._14px,
                      color: theme.color.red_0,
                    }
              }
              onClick={() =>
                callDetails?.CTALabel.includes("Confirm")
                  ? callDetails.CTAAction(dispatch, consultationData, index)
                  : handleViewBtn()
              }
            >
              {callDetails?.CTALabel}
            </TextButton>
          </div>
        )}
      </div>
      {/* <PreviousCallDetailView
					handleViewBtn={handleViewBtn}
					activateViewPopUp={callDetails.isDetailsViewPopUpActive}
				/> */}
    </StyledPreviousCallDetails>
  );
};
export default PreviousCallDetails;
