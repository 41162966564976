import { applyMiddleware, compose, createStore } from "redux";
import { rootReducer } from "./reducers";

import createSagaMiddleware from "redux-saga";
import sagas from "./saga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(sagas);

export { store };
