import Styled from 'styled-components';
import { dim } from '../../../styles/theme';

const AuthorDetailStyle = Styled.div`
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 40px 0 0 0;
    padding: 40px 0  0;
    box-sizing: border-box;
    padding-bottom:32px;
    border-bottom:1px solid #BBBBBB;
    border-top:1px solid #BBBBBB;

    .outer{
        margin-left:136px;
        display: flex;
        align-items: center;
    }

    .authorInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 0;
        width:100%;
        box-sizing: border-box;
    }

    .author {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        margin: 0 0 10px 0;
        padding: 0 0;
        box-sizing: border-box;
    }

    .linkdn{
        width: 24px;
        height: 24px;
        margin: 0 10px 0 0;
        cursor: pointer;
    }

    .authorImage{
        width: 138px;
        height: 138px;
        border-radius: 8px;
        overflow: hidden;
        object-fit: cover;
    }

    .authorDiv{
        
        display: flex;
        gap:43px;
        width: 100%;
    }
    .authorNameContainer{
        display: flex;
        align-items:center;
        
    }
    .authorName{
        font-size: 15px;
        font-weight: 700;
        line-height: 17px;
        text-align: left;
        color: #212121;
        
    }
  .linkedin-logo{
    padding: 0;
    margin-left:8px;
    border-left:2px solid black;
  }

  .verified{
    margin-right:8px;
    margin-left:8px;
  }

   
.brief-container{
    
}
.brief{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color:#212121;
    max-width:660px

}
.linkedin{
    padding:0;
    margin:0;
}
  

 
    .logo {
        display:flex;
        font-style: normal;
        font-weight: 700;
        font-size: ${dim._16px};
        line-height: ${dim._22px};
        text-transform: uppercase;
        text-decoration: none;
        align-items:center;
        color: #212f63;
        gap:8px;
    
    }
    .shareBtn {
        display: flex;
        flex-direction:column;
        gap:8px;
    }
    .shareTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

    }
    .icons {
        display: flex;
        gap:32px;
    }
    .shareIcons{
        // display: flex;
        width:20px;
    }



    
    .authorInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 0 40px;
        padding: 0 0;
        box-sizing: border-box;
    }

    .authorInfo h2 {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #7F7F7F;
    }

    .author {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        margin: 0 0 10px 0;
        padding: 0 0;
        box-sizing: border-box;
    }

    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        margin: 0 0 0 8px;
        padding: 0 0;
        box-sizing: border-box;
    }

    p {
        margin: 0 0 0 0;
        padding: 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #212121;
    }

    .authorTags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        margin: 20px 0 0 0;
        padding: 0 0;
        box-sizing: border-box;
    }

    .authorTags div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 130px;
        height: auto;
        margin: 0 10px 0 0;
        padding: 8px 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #51556C;
        background: #E0ECFF;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.prosperrText{
    text-transform:none;
    font-size: ${dim._16px};
    color: #212f63;
}




`;

export default AuthorDetailStyle;
