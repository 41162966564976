import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledConsultationRatingModel = styled.div`
  position: relative;
  .ratingSection {
    display: flex;
    gap: ${dim._16px};
    padding: ${dim._18px} ${dim._0px} ${dim._24px};
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
  }
  .ratingTitle {
    white-space: nowrap;
  }
  .inputRangeSlider {
    width: 100%;
  }
  .range-indicator {
    width: 100%;
  }
  input[type="range"] {
    width: 100%;
    -webkit-appearance: none;
    z-index: 10;
    height: 20px;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 6px;
    border-radius: 12px;
    z-index: 10;
    background: linear-gradient(
      to right,
      #e9564f 0%,
      #e9564f 22.2%,
      #ff9735 22.2%,
      #ff9735 66.66%,
      #15be53 66.66%,
      #15be53 100%
    );
  }

  /* Style for the thumb (slider handle) */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #4d62b9;
    height: 24px;
    width: 24px;
    border: 8px solid #fff;
    border-radius: 50%;
    outline: 4px solid #cacfea;
    position: relative;
    top: calc(-100% - 2px);
    cursor: pointer;
  }
  /* Indicator styles */
  .range-indicator {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 0 ${dim._4px};
    z-index: 1;
  }

  .range-indicator span {
    text-align: center;
  }
`;
