import styled from 'styled-components';
import { dim } from '../../../../../../../styles/theme';

export const EditableRowStyle = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	min-height: auto;
	height: auto;
	margin: 0 0 ${dim._2px} 0;
	padding: ${dim._10px} ${dim._20px};
	background: #fff;
	border-bottom: ${dim._1px} solid #e5e5e5;
	box-sizing: border-box;
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	.parentPos {
		position: relative;
	}

	.icon {
		border-radius: 50%;
		position: absolute;
		border: none;
		color: #fff;
		padding: ${dim._4px};
		font-size: ${dim._24px};
		box-sizing: border-box;
	}
	.submit {
		background: rgba(90, 250, 146, 0.8);
		margin: 0 ${dim._30px} 0 0;
		right: ${dim._20px};
		opacity: ${({ active }) => (active ? '1' : '0.8')};
		cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};
	}

	div {
		width: 50%;
		height: auto;
		padding: 0 ${dim._6px} 0 0;
		input {
			font-size: ${dim._12px};
			border: none;
			border-bottom: ${dim._1px} solid #808080;
			outline: none;
		}
	}
`;
