import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React from "react";

const YearPicker = (props) => {
  const { label, value, name, formErrors, onChange, onBlur, error, errorArr } =
    props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ display: "inline" }}>
      <DatePicker
        sx={{ border: "none", outline: "none" }}
        label={label}
        openTo="year"
        views={["year"]}
        onClose={onBlur}
        onViewChange={onBlur}
        // slotProps={{
        //   textField: {
        //     error: formErrors[name],
        //     helperText: formErrors[name],
        //   },
        // }}
        {...props}
        onChange={(e) => {
          onChange(e);
        }}
      />
      {error && errorArr.includes(name) && (
        <p className="error_p">please define {name.replaceAll("_", " ")}</p>
      )}
    </LocalizationProvider>
  );
};

export default YearPicker;
