const defaultData = [
    {
        external_id: "",
        label: "may rent",
        sub_type: "INCOME_FROM_HOUSE_RENT",
        amount: 0,
        start_date: new Date().getTime(),
        end_date: new Date().getTime(),
        municipal_tax: 0,
        is_loan: false,
        interest: 0,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
    },
];

export const getSummarizedData = (data) => {
    const newData = [...data];
    const updatedHouseRent =
        newData &&
        newData.reduce((acc, current) => {
            const existingSubTypeIndex = acc.findIndex(
                (item) => item.sub_type === current.sub_type
            );
            if (existingSubTypeIndex !== -1) {
                acc[existingSubTypeIndex].amount += current.amount;
                acc[existingSubTypeIndex].interest += current.interest;
            } else {
                acc.push(current);
            }
            return acc;
        }, []);
    return updatedHouseRent;
};

export const initializeDefaultData = (data) => {
    const res = defaultData.map((it) => {
        const obj = data && data.find((ele) => ele.sub_type === it.sub_type);
        return obj !== undefined ? obj : it;
    });
    return res;
};
