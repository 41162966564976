import styled from 'styled-components';

export const CategoryStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	flex-grow: 1;
	height: 80px;
	margin: 0 0px;
	padding: 16px;
	background-color: ${(props) => (props.active ? '#e4ebff' : '#fff')};
	border-radius: 4px;
	cursor: pointer;
	box-sizing: border-box;

	&:hover {
		background-color: #e4ebff;
		color: #635bef;
	}

	p {
		margin: 8px 0 0 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		color: ${(props) => (props.active ? '#635BEF' : '#212f45')};
		opacity: 0.5;
	}
`;
