import React from "react";
import Select from "react-select";

import Styled from "styled-components";

const TagsSelectStyle = Styled.div`

    .select1 {
      margin : 0 0 0 0;
      height: 47px;  
      width: 100%;
      box-sizing: border-box;
      border-radius: 4px; 
    }
`;

function CustomSelect(props) {
  const {
    tags,
    placeholder,
    id,
    name,
    handleInput,
    handleOnBlur,
    defaultValue,
    isMulti = true,
    style,
    style2,
    isDisabled = false,
    className,value
  } = props;

  return (
    <TagsSelectStyle style={{ ...style2 }}>
      {defaultValue !== null ? (
        <Select
          className={"select1 " + className}
          styles={{ ...style }}
          isMulti={isMulti}
          placeholder={placeholder}
          options={tags}
          id={id}
          name={name}
          value={value
          
          ||defaultValue}
          onChange={handleInput}
          onBlur={handleOnBlur}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          // defaultValue={[{ value: 'Red', label: 'Red' }, { value: 'Yellow', label: 'Yellow' }]}
        />
      ) : null}
    </TagsSelectStyle>
  );
}

export default CustomSelect;
