import { actions } from "./actions";

const initialState = {
  earningComponents: [],
  deductionComponents: [],
  payslipReviewList: [],
};

const PayslipManualReviewReducer = (state = initialState, payload = {}) => {
  switch (payload.type) {
    case actions.SET_EARNING_COMPONENTS:
      return {
        ...state,
        earningComponents: payload.data,
      };
    case actions.SET_DEDUCTION_COMPONENTS:

      return {
        ...state,
        deductionComponents: payload.data,
      };
    case actions.SET_ADD_DEDUCTION_POPUP:
      return {
        ...state,
        addDeductionPopup: payload.data,
      };
    case actions.SET_PAYSLIP_REVIEW_LIST:
      return {
        ...state,
        payslipReviewList: payload.data,
      };

    default:
      return state;
  }
};

export { PayslipManualReviewReducer };
