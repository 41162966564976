import { httpClient } from "./httpUtil";

const AdvisorDashboard={

    getAdvisorDashboardSummary: () => {
        return httpClient.get(`super-saver-user/v1/advisor-dashboard/internal`);
      },
    getConsultationViewData : (data) => {
        return httpClient.post(`appointments/v1/advisor-dashboard/call-details/internal`,data);
      },
    getLockData : (data) => {
        return httpClient.get(`super-saver-user/v1/advisor-dashboard-lock-status/internal`);
      },
};

export {AdvisorDashboard};