import { getDateDifference, getDayName, getSlashDateFormat } from "./timeUtil";

export const getTimeDifference = (timestamp) => {
  const { previousDate, days, hours, minutes } = getDateDifference(timestamp);
  if (days === 1) {
    return "Yesterday";
  } else if (days < 7 && days > 1) {
    return getDayName(previousDate);
  } else if (days >= 7) {
    return getSlashDateFormat(previousDate);
  } else if (hours >= 1) {
    return hours === 1 ? "An hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "A minute ago" : `${minutes} minutes ago`;
  } else {
    return "Now";
  }
};

export const getDayWiseTimeDifference = (timestamp) => {
  const { previousDate, days } = getDateDifference(timestamp);

  if (days === 0) {
    return "Today";
  } else if (days === 1) {
    return "Yesterday";
  } else if (days < 7) {
    return getDayName(previousDate);
  } else {
    return getSlashDateFormat(previousDate);
  }
};
