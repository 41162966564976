import React from 'react'

const RightCircleArrow = ({onClick}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none"
onClick={onClick}
style={{
  cursor:'pointer'
}}
>
  <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" stroke="#15BE53" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19 13H7M19 13L14.2 8.19995M19 13L14.2 17.8" stroke="#15BE53" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default RightCircleArrow