import styled from 'styled-components';
import { dim } from '../../../../../../styles/theme';
import { Theme } from '../../../../../../styles/colors';

export const Wrapper = styled.div`
	width: ${({ onboarding }) => (onboarding ? '100%' : dim._scale(926))};
	box-sizing: border-box;
	background-color: #fff;
	padding: ${dim._34px};
	border-radius: ${({ onboarding }) =>
		onboarding ? `${dim._10px}` : `${dim._4px} ${dim._4px} 0px 0px`};

	.sub-heading {
		font-size: ${dim._12px};
		line-height: ${dim._16px};
		margin-right: ${dim._16px};
	}

	.company-details {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo-name {
			display: flex;
			justify-content: flex-start;
			height: ${dim._64px};
			.logo {
				background-color: #f8f8f8;
				border-radius: ${dim._4px};
				margin-right: ${dim._18px};
				padding: ${dim._4px};
				box-sizing: border-box;
			}

			.name {
				font-weight: 500;
				font-size: ${dim._16px};
				line-height: ${dim._20px};
				margin-bottom: ${dim._4px};
			}
		}

		.current-status {
			display: flex;
			align-items: center;
		}
	}

	.my-custom-select {
		padding: ${dim._4px} ${dim._8px};
		font-size: ${dim._14px};
		background-color: #f1f5f7;
		box-sizing: border-box;

		width: ${dim._scale(175)};
		height: ${dim._35px};

		background: #f1f5f7;
		border-radius: ${dim._4px};
		border: 0;
		outline-color: #898989;
	}

	.dates {
		display: flex;
		margin-top: ${dim._24px};

		.start-date {
			margin-right: ${dim._16px};
		}

		.date-input {
			padding: ${dim._4px} ${dim._8px};
			font-size: ${dim._14px};
			background-color: #f1f5f7;
			box-sizing: border-box;

			width: ${dim._scale(175)};
			height: ${dim._35px};

			background: #f1f5f7;
			border-radius: ${dim._4px};
			border: 0;
			outline-color: #898989;
		}
	}
	.button-container {
		margin-top: ${dim._36px};
	}

	.label {
		font-size: ${dim._12px};
		margin-bottom: ${dim._8px};
	}

	.other-input {
		background: ${Theme.color.white_10};
		border-radius: ${dim._4px};
		padding: ${dim._10px};
		width: 100%;
		border: 0;
		outline: 0;
	}
`;
