




const PendingActions = ({color}) => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill={"none" } xmlns="http://www.w3.org/2000/svg">
<path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke={color||"white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.66724 4.02002C4.33724 4.20002 2.66724 5.44002 2.66724 10V16C2.66724 20 3.66724 22 8.66724 22H14.5018" stroke={color||"white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V13.3436" stroke={color||"white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 10.7515H13" stroke={color||"white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 14.7515H10.38" stroke={color||"white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.8254 17.75C22.8254 18.73 22.5454 19.67 22.0654 20.45C21.7854 20.93 21.4355 21.35 21.0255 21.69C20.1055 22.51 18.9054 23 17.5754 23C16.4254 23 15.3654 22.63 14.5054 22C13.9354 21.59 13.4554 21.06 13.0854 20.45C12.6054 19.67 12.3254 18.73 12.3254 17.75C12.3254 16.1 13.0854 14.61 14.2954 13.66C15.1954 12.93 16.3454 12.5 17.5754 12.5C18.8054 12.5 19.9354 12.92 20.8254 13.63C22.0454 14.59 22.8254 16.08 22.8254 17.75Z" stroke={color||"white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.5754 20.25C17.5754 18.87 18.6954 17.75 20.0754 17.75C18.6954 17.75 17.5754 16.63 17.5754 15.25C17.5754 16.63 16.4554 17.75 15.0754 17.75C16.4554 17.75 17.5754 18.87 17.5754 20.25Z" stroke={color||"white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default PendingActions