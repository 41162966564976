import React, { useEffect, useState } from "react";
import { dataToRedirect, dataToRedirectForAccountant } from "./constant";
import { StyledLayout, StyledUserSideBar } from "./UserSideBar.styled";
import SideBar from "../sideBar";
import { useLocation, useParams } from "react-router";
import { SuperSaverUserProfileApi } from "../../Services/SuperSaverUserProfileService";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { rowFunction } from "../../container/SuperSaverUser/utils";
import { useSelector } from "react-redux";
import { useQuery } from "../../utils/routeUtils";
import { USER_TYPE } from "../../utils/localStorageKeys";
import LoaderPage from "../../commonComponents/LoaderPage";

const LayoutHOC = (Element) => {
  const userType = JSON.parse(localStorage.getItem("type"));
  const userRole = userType?.user_roll;
  const UserSideBar = (props) => {
    const [modifiedData, setModifiedData] = useState(null);
    const { financialYearList } = useSelector((state) => state.cmsReducer);
    const params = useParams();
    const userIdFromQueryParam = useQuery().get("user_id") || undefined;
    const userIdFromURL = params?.userId;
    const userId = userIdFromURL !== undefined ? userIdFromURL : userIdFromQueryParam;
    const fYId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    const userType = JSON.parse(localStorage.getItem(USER_TYPE));
    const userRole = userType?.user_roll;
  
    const getUserDetails = async () => {
      const response =
        userId &&
        (await SuperSaverUserProfileApi.getUserDetailsByUserId(userId, fYId));
      const userDetails = response?.data;

      const data = rowFunction(userDetails, 1, true);
      setModifiedData(data);
    };
    useEffect(() => {
      getUserDetails();
    }, [userId]);
    if (userId === undefined) {
      return <Element item={modifiedData} userId={userId} {...props} />;
    }
    if(!modifiedData){
      return <LoaderPage/>;
    }
    return (
      <StyledLayout>
        <StyledUserSideBar>
          <SideBar
            SideBarData={dataToRedirect( 
              modifiedData,
              fYId,
              financialYearList,
              userRole 
            )}
            className={"userSidebar"}
            userType={userType.user_type}
            showAuthorSidebar={false}
          />
        </StyledUserSideBar>
        <div className="layoutContainer">
          <Element item={modifiedData} userId={userId} {...props} />
        </div>
      </StyledLayout>
    );
  };

  return UserSideBar;
};

export default LayoutHOC;
