import React from 'react';
import { StyledInputDateWithOutLabel } from './InputDateWithOutLabel.styled';

const InputDateWithOutLabel = ({ value, name, onChange }) => {
	return (
		<StyledInputDateWithOutLabel
			type='date'
			name={name}
			value={value}
			onChange={onChange}
		></StyledInputDateWithOutLabel>
	);
};

export default InputDateWithOutLabel;
