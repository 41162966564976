import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .timeDate {
    display: flex;
    /* justify-content: space-between; */
  }

  .time {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #425466;
    margin-right: 130px;
    margin-bottom: 8px;
    width: 230px;
  }
  .date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    width: 130px;
    color: #425466;
    margin-right: 197px;
  }
  .head {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: ${(props) => (props.color ? "#635BEF" : "#212f45")};
    margin: 0px 0 4px;
  }
  .activity_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #212f45;
  }
  .copleted {
    color: #15be53;
    margin-top: 8px;
  }
`;
