import React from "react";
import { extractContent } from "../../utils/textUtil";
import "./button.css";

const Button = ({ children, onClick, disabled = false, style = {} }) => {
    return (
        <button
            style={style}
            className="btn"
            onClick={onClick}
            disabled={disabled}
        >
            {extractContent(children)}
        </button>
    );
};
export default Button;
