import React from 'react';
import { StyledTaskCompleted } from './TaskCompleted.styled';

const TaskCompleted = ({
	icon = process.env.PUBLIC_URL + '/assets/icons/pendingIcon.svg',
	name = 'completed',
	style,
	onClick = () => {}
}) => {
	return (
		<StyledTaskCompleted style={style} onClick={onClick}>
			<img src={icon} alt='' className='icon' />
			<div className='name'>{name}</div>
		</StyledTaskCompleted>
	);
};

export default TaskCompleted;
