import React, { useState } from "react";
import { income_categories } from "../utils/constant";
import Category from "./Category";
import { IncomeCategoriesStyle } from "./IncomeCategories.styled";
import OtherCategory from "./Other/index";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../../../utils/routeUtils";

const IncomeCategories = ({ activeCategory, handleIncomeCategory, data }) => {
  const navigate = useNavigate();
  const userIdFromQueryParam = useQuery().get("user_id") || undefined;
  const userIdFromURL = useLocation().userId;
  const userId = (userIdFromURL !== undefined ? userIdFromURL : userIdFromQueryParam) || data.user_id; 
  const { pathname } = useLocation();
  const [otherActive, setOtherActive] = useState(false);
  const otherCategoryKey = "More Income";
  const incomeCategories =
    income_categories.length <= 4
      ? income_categories.slice(0, 4)
      : income_categories.slice(0, 3);
  return (
    <IncomeCategoriesStyle>
      {incomeCategories.map((cat, i) => (
        <Category
          key={i}
          item={cat}
          active={activeCategory === cat.type}
          activeCategory={activeCategory}
          handleIncomeCategory={(e) => {
            handleIncomeCategory(e);
            userId !== undefined
              ? navigate(
                  `/${pathname.split("/")[1]}/incomeView/${cat.path}/${userId}`,
                  {
                    state: {
                      ...data,
                      incomeType: cat.path.toUpperCase().replace(/-/g, "_"),
                    },
                  }
                )
              : navigate(`/${pathname.split("/")[1]}/incomeView/${cat.path}`, {
                  state: data,
                });
            setOtherActive(false);
          }}
        />
      ))}
      {income_categories.length > 4 && (
        <OtherCategory
          otherCategories={income_categories.slice(3)}
          active={activeCategory === otherCategoryKey}
          otherCategoryKey={otherCategoryKey}
          otherActive={otherActive}
          activeCategory={activeCategory}
          handleIncomeCategory={(e) => {
            handleIncomeCategory(e);
            setOtherActive(true);
          }}
          data={data}
          userId={userId}
        />
      )}
    </IncomeCategoriesStyle>
  );
};

export default IncomeCategories;
