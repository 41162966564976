import styled from "styled-components";
import { dim } from "../../../../styles/theme";
import { Button } from "antd";

export const SessionCloseModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${dim._16px};
  box-sizing: border-box;

  & > h2 {
    margin: 0 0 ${dim._20px} 0;
    padding: 0;
    color: #19191b;
    font-size: ${dim._20px};
    font-style: normal;
    font-weight: 700;
    line-height: ${dim._38px};
  }

  .char_limit {
    width: 100%;
    margin: 4px 0 20px 0;
    text-align: right;
    color: #212f45;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
    opacity: 0.7;
  }
`;

export const SubmitButton = styled(Button)`
  height: ${dim._40px};
  margin-top: ${dim._40px};
  border-radius: ${dim._12px};
  background-color: #da5f55;
  color: white;

  &:disabled {
    background-color: #da5f55;
    opacity: 0.6;
    cursor: not-allowed;
    color: #fff !important;
  }
`;
