import React from "react";
import { convertNumberIntoINR } from "../../utils/deductionView";
import { AmountCardStyled } from "./AmountCard.Styled";

const AmountCard = ({ title, amount }) => {
  return (
    <AmountCardStyled>
      <div className="title">{title}</div>
      <div className="price">{convertNumberIntoINR(amount)}</div>
    </AmountCardStyled>
  );
};

export default AmountCard;
