import styled from "styled-components";
import Card from '@mui/material/Card';
import { dim } from "../../../../../../styles/theme";

export const StyledNotesEditor = styled.div`
.note-editor {
  border: 1px solid #ddd;
  padding: ${dim._scale(20)};
  border-radius: ${dim._scale(10)};
  max-width: ${dim._scale(700)};
  margin: ${dim._scale(40)} auto;
  background-color: #fefefe;
  box-shadow: 0 ${dim._scale(4)} ${dim._scale(8)} rgba(0, 0, 0, 0.1);
}

.wrapperClassName{
  min-height: 250px;
}
.header-input {
  width: 100%;
  padding: ${dim._scale(10)} ${dim._scale(16)};
  margin-bottom: ${dim._scale(10)};
  font-size: ${dim._scale(16)};
  border: none;
  outline: none;
  box-shadow: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
  border-radius: ${dim._scale(4)};
  cursor: pointer;
  border: 1px solid #ddd;
}




.header-input::-webkit-outer-spin-button,
.header-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




.editor-controls {
  display: flex;
  align-items: center;
  gap: ${dim._scale(10)};
}

.editor-controls button {
  color: gray;
  border: none;
  border-radius: ${dim._scale(4)};
  cursor: pointer;
  font-size: ${dim._scale(14)};
  transition: background-color 0.3s;
  width:${dim._scale(50)};
}


.editor-container {
  min-height: ${dim._scale(250)};
  max-height: ${dim._scale(500)};
  overflow-x: scroll;
  padding: ${dim._scale(16)};
  border: 1px solid #ddd;
  border-radius: ${dim._scale(8)};
  background-color: #fff;
  margin-bottom: ${dim._scale(20)};
  position: relative;
}

.editor{
    height: 100%;
}

.DraftEditor-root {
  cursor: text;
  font-size: ${dim._scale(16)};
  line-height: 1.6;
}

.public-DraftEditorPlaceholder-root {
  color: #aaa;
  font-style: italic;
}

.description-pills{
  display:flex;
  gap:${dim._scale(10)};
  width:100%;
  background-color: red;
}
.save-button {
  padding: ${dim._scale(10)} ${dim._scale(12)};
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: ${dim._scale(4)};
  cursor: pointer;
  font-size:${dim._scale(12)};
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: #218838;
}
.note-editor {
  border: 1px solid #ddd;
  padding: ${dim._scale(20)};
  border-radius: ${dim._scale(10)};
  max-width: ${dim._scale(700)};
  margin: ${dim._scale(40)} auto;
  background-color: #fefefe;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.editor-container {
  min-height: ${dim._scale(250)};
  padding: ${dim._scale(16)};
  border: 1px solid #ddd;
  border-radius:${dim._scale(8)};
  background-color: #fff;
  margin-bottom: ${dim._scale(20)};
}

.file-preview {
  margin-bottom: ${dim._scale(20)};
}


.drawer-content {
  padding: ${dim._scale(20)};
  width: ${dim._scale(500)};
}

.drawer-content input {
  width: 100%;
}
.editor-actions{
    display: flex;
    gap:${dim._scale(10)};
    align-items: center;
   
}
.only-pdf-info{
  font-size: ${dim._scale(12)};
}
`

export const StyledCard = styled(Card)({
    position:'relative',
    borderRadius: '8px',
    marginBottom:'10px',
    height:'200px',
  });