import React from "react";
import {
  StyledActionWidget,
  WidgetActionInfo,
  WidgetDetails,
  WidgetIcon,
  WidgetInfo,
} from "./ActionWidget.styled";
import ChatWidgetIcon from "../../../../Assets/icons/ChatWidgetIcon";
import RightCircleArrow from "../../../../Assets/icons/RightCircleArrow";

const ActionWidget = ({
  title,
  pendingValue,
  pendingAction,
  actionText,
  pendingSince,
  pendingFromMoreThenOneDay,
  redirectTo,
  Icon,
}) => {
  return (
    <StyledActionWidget pendingFromMoreThenOneDay={pendingFromMoreThenOneDay}>
      <WidgetIcon>
        <Icon />
      </WidgetIcon>
      <WidgetDetails>
        <div className="widgetTitle">{title}</div>
        <WidgetInfo>
          <div className="widgetNumber">{pendingValue}</div>
          <div className="widgetInfoText">{pendingAction}</div>
        </WidgetInfo>
        <WidgetActionInfo>
          {actionText}
          <div className="widgetHighlight">{pendingSince}</div>
        </WidgetActionInfo>
      </WidgetDetails>

      <RightCircleArrow onClick={() => window.open(redirectTo, "_blank")} />
    </StyledActionWidget>
  );
};

export default ActionWidget;
