import React, { useState } from "react";
import { AddAnotherDoc, RequestPopupWrapper } from "./RequestPopup.styled";
import { itr_documents_constant } from "../../constant";
import InputBox from "../TextArea";
import CommonButton from "../CommonButton";
import RequestDocPreview from "../RequestDocPreview";
import DocumentDropdown from "../DocumentDropdown";
import { useDispatch, useSelector } from "react-redux";
import { ITRDocumentsAction } from "../../store/action";

const documentList = [
  { value: "FORM_16A", label: "Form 16" },
  { value: "PNL", label: "Capital Gain Statement" },
  {
    value: "INTEREST_CERTIFICATE_HOME_LOAN",
    label: "Interest Certificate Home Loan",
  },
  {
    value: "INTEREST_CERTIFICATE_EDUCATION_LOAN",
    label: "Interest Certificate Education Loan",
  },
  { value: "DONATION_RECEIPT", label: "Donation Receipt" },
  { value: "ESOPS", label: "ESOPs Statement" },
  { value: "RSU_STATEMENT", label: "RSU Statement" },
  { value: "TAX_PROFIT_LOSS_STATEMENT", label: "Tax Profit & Loss Statement" },
  { value: "OTHERS", label: "Others" },
];

const RequestPopup = () => {
  const [documentType, setDocumentType] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [docList, setDocList] = useState([]);
  const dispatch = useDispatch();
  const confirmPopupStatus = useSelector(
    (state) => state.ITRDocumentsReducer.confirmPopupStatus
  );

  const handleDocumentType = (docType) => {
    setDocumentType(docType);
  };

  const handleDocDescription = (desc) => {
    setDocDescription(desc);
  };

  const previewList = confirmPopupStatus?.data;

  const handleAddDoc = () => {
    const newValues = {
      ["document_type"]: documentType.value,
      ["document_name"]: documentType.label,
      ["description"]: docDescription.replace(/\n/g, " "),
    };
    let newDocList = [...docList];
    setDocList([...newDocList, newValues]);
    setDocumentType("");
    setDocDescription("");
    dispatch({
      type: ITRDocumentsAction.CONFIRMATION_POPUP_STATUS,
      data: {
        active: null,
        data: [...previewList, newValues],
      },
    });
  };

  const handleRemoveDoc = (index) => {
    const newDocList = docList.filter((_, i) => i !== index);
    setDocList(newDocList);
    dispatch({
      type: ITRDocumentsAction.CONFIRMATION_POPUP_STATUS,
      data: {
        active: null,
        data: [...newDocList],
      },
    });
  };

  const handleSubmitDocRequest = () => {
    dispatch({
      type: ITRDocumentsAction.CONFIRMATION_POPUP_STATUS,
      data: {
        active: true,
        data: [...previewList],
      },
    });
    dispatch({
      type: ITRDocumentsAction.REQUEST_POPUP_STATUS,
      data: {
        active: false,
      },
    });
  };

  const addAnotherDocDisable =
    docDescription === "" || documentType === "" || docDescription.length > 100;

  return (
    <RequestPopupWrapper>
      <div className="head">
        <h1>Request for New Document</h1>
        <img
          src={itr_documents_constant.cross_icon}
          alt=""
          onClick={() => {
            dispatch({
              type: ITRDocumentsAction.REQUEST_POPUP_STATUS,
              data: {
                active: false,
              },
            });
          }}
        />
      </div>
      <div className="dropdown">
        <p>Document Name</p>
        <DocumentDropdown
          documentList={documentList}
          documentType={documentType}
          handleDocumentName={handleDocumentType}
        />
      </div>
      <div className="comments">
        <p>Describe the Document</p>
        <InputBox
          placeholder="Description..."
          value={docDescription}
          maxlength="100"
          handleDocDescription={handleDocDescription}
        />
        <div className="count_desc">{docDescription.length}/100</div>
      </div>
      <AddAnotherDoc isDisabled={addAnotherDocDisable}>
        <span onClick={!addAnotherDocDisable ? handleAddDoc : undefined}>
          + Add Document
        </span>
      </AddAnotherDoc>
      <div className="doc_preview">
        {previewList?.map((item, i) => (
          <RequestDocPreview
            key={i}
            documentType={item.document_name}
            description={item.description}
            handleRemove={() => handleRemoveDoc(i)}
          />
        ))}
      </div>
      <div className="submit_btn">
        <CommonButton
          btnName={"Submit all documents"}
          isDisabled={previewList?.length === 0}
          handleClick={handleSubmitDocRequest}
        />
      </div>
    </RequestPopupWrapper>
  );
};

export default RequestPopup;
