import React, { useState } from "react";
import { RejectPopupWrapper } from "./RejectPopup.styled";
import Button from "../../../../commonComponents/Button";
import { itr_documents_constant } from "../../constant";
import InputBox from "../TextArea";
import CommonButton from "../CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { ITRDocumentsAction } from "../../store/action";

const RejectPopup = ({ handleDocVerification }) => {
  const [comments, setComments] = useState("");
  const dispatch = useDispatch();
  const rejectPopupStatus = useSelector(
    (state) => state.ITRDocumentsReducer.rejectPopupStatus
  );

  const handleRejectSubmit = () => {
    handleDocVerification(
      rejectPopupStatus.document_id,
      comments.replace(/\n/g, " "),
      "REJECTED"
    );
    dispatch({
      type: ITRDocumentsAction.REJECT_POPUP_STATUS,
      data: {
        active: false,
      },
    });
  };

  const handleComments = (val) => {
    setComments(val);
  };
  return (
    <RejectPopupWrapper>
      <div className="head">
        <h1>Why are you rejecting documents (Form 16)</h1>
        <img
          src={itr_documents_constant.cross_icon}
          alt=""
          onClick={() => {
            dispatch({
              type: ITRDocumentsAction.REJECT_POPUP_STATUS,
              data: {
                active: false,
              },
            });
          }}
        />
      </div>
      <div className="comments">
        <p>Comment</p>
        <InputBox
          placeholder="Enter comment.."
          maxlength="100"
          handleDocDescription={handleComments}
        />

        <div className="count_desc">{comments.length}/100</div>
      </div>
      <div className="reject_btn">
        <CommonButton
          btnName={"Reject"}
          isDisabled={comments.length === 0 || comments.length > 100}
          handleClick={handleRejectSubmit}
        />
      </div>
    </RejectPopupWrapper>
  );
};

export default RejectPopup;
