export const data = {
	companySubheading: 'Salary Slip & Compensation Details',
	currentSalaryLabel: 'Your current annual CTC',
	currentCompanyStatusLabel: 'Is this your current company?'
};

export const marks = [
	{
		value: 1000000,
		label: '₹10L'
	},
	{
		value: 10000000,
		label: '₹1Cr'
	}
];

export const ARIA_LABEL = 'pretto slider';
