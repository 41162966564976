import styled from "styled-components";
import {dim} from "../../../../styles/theme";

export const StyledFlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledWrapperWhite = styled(StyledFlexWrapper)`
  width: 100%;
  background: white;
  border-radius: ${dim._8px};
  padding: ${dim._24px};
`;
export const StyledActionWidget = styled(StyledWrapperWhite)`
  gap: ${dim._24px};
  align-items: center;
  justify-content: space-around;
  border: ${({pendingFromMoreThenOneDay}) =>
    pendingFromMoreThenOneDay ? `1px solid red` : "none"};
  background: ${({pendingFromMoreThenOneDay}) =>
    pendingFromMoreThenOneDay ? ` #ff00001c` : "#fff"};
`;
export const WidgetIcon = styled(StyledFlexWrapper)`
  background: #8280ff36;
  padding: ${dim._18px};
  border-radius: ${dim._16px};
  height: fit-content;
  justify-content: center;
  align-items: center;
`;

export const WidgetDetails = styled(StyledFlexWrapper)`
  flex-direction: column;
  gap: ${dim._8px};
  padding-right: ${dim._scale(98)};
  align-items: start;
  .widgetTitle {
    font-size: ${dim._16px};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const WidgetInfo = styled(StyledFlexWrapper)`
  gap: ${dim._8px};
  justify-content: start;
  font-size: ${dim._12px};
  font-style: normal;
  font-weight: 400;
  .widgetNumber {
    color: #212f45;
    font-size: ${dim._32px};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: ${dim._1px};
  }
`;

export const WidgetActionInfo = styled(StyledFlexWrapper)`
  font-size: ${dim._14px};
  font-style: normal;
  font-weight: 500;
  gap: ${dim._4px};
  white-space: nowrap;
  .widgetHighlight {
    opacity: 0.7;
    color: #e13f02;
  }
`;
