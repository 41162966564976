export const itr_documents_constant = {
  pdf_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/itr-filing-revamp/icons/pdf_icon.svg",
  approve_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/itr-filing-revamp/icons/verified.svg",
  reject_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/itr-filing-revamp/icons/denied.svg",
  cross_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/itr-filing-revamp/icons/cross_icon.svg",
  warning_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/itr-filing-revamp/icons/warning.svg",
  json_icon:
    "https://prosperr-desktop-ui-assets.s3.eu-west-1.amazonaws.com/assets/icons/json_icon.svg",
};
