import React from "react";
import { StyledConsultationConfirmation } from "./ConsultationConfirmation.styled";
import RadioButton from "../../../../commonComponents/RadioButton";
import RadioComponent from "../RadioComponent";
import { useState } from "react";
import DropDownComponent from "../DropdownComponet";
import { allConsultationType } from "../../../ManageAppointment/constants";

const ConsultationConfirmation = ({
  radioComponentProps,
  DropDownComponentProps,
}) => {
  const { radioArrData } = radioComponentProps;

  const isCorrectCall =
    radioArrData.filter((d) => d.isSelected)[0]?.value === false;
  const { dataOptions } = DropDownComponentProps;
  return (
    <StyledConsultationConfirmation>
      <RadioComponent {...radioComponentProps} />

      {isCorrectCall && (
        <div className="dropDown">
          <div className="dropDownHeading">
            What was the agenda of that call?
          </div>

          <DropDownComponent
            {...DropDownComponentProps}
            type={dataOptions?.filter((d) => d.isSelected)[0]?.value}
          />
        </div>
      )}
    </StyledConsultationConfirmation>
  );
};

export default ConsultationConfirmation;
