import React, { useReducer, useState } from "react";
import { StyledEducationInput } from "./EducationInput.styled";
import YearPicker from "../../../../commonComponents/YearPicker";
import Button from "../../../../commonComponents/Button";
import dayjs from "dayjs";
import { timeUtil } from "../../../../utils/timeUtil";
const initialState = {};
function tasksReducer(draft, action) {
  switch (action.type) {
    case "added": {
      draft.push({
        id: action.id,
        text: action.text,
        done: false,
      });
      break;
    }
    case "changed": {
      const index = draft.findIndex((t) => t.id === action.task.id);
      draft[index] = action.task;
      break;
    }
    case "deleted": {
      return draft.filter((t) => t.id !== action.id);
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
const EducationInput = ({
  institute,
  start_year,
  isDelete,
  end_year,
  course,
  handleChange,
  handleAdd,
  handleDelete,
  length,
  id,
  error,
  errorArr,
}) => {
  const [formErrors, setFormErrors] = useState({});

  const validate = (type, value) => {
    const errors = { ...formErrors };
    const regex = {
      email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
      mobile: /^([+]\d{2})?\d{10}$/,
    };

    if (!value) {
      errors[type] = `${type} is required`;
    } else if (regex[type] && !regex[type].test(value)) {
      errors[type] = `Not a valid ${type}`;
    } else if (value.length < 2) {
      errors[type] = `${type} cannot be less than 2 characters`;
    } else if (value == "Invalid Date") {
      errors[type] = `${type} Invalid Date`;
    } else {
      delete errors[type];
    }

    setFormErrors(errors);
  };
  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    console.count("fdf");
    validate(name, value);
  };
  return (
    <StyledEducationInput>
      <div className="label">
        <div className="side">Course : &nbsp;&nbsp;</div>
        <input
          type="text"
          className="inputTag"
          name="course"
          placeholder="Course"
          value={course}
          key={"4"}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => handleChange(e, id)}
          onKeyUp={handleOnBlur}
          onBlur={handleOnBlur}
          autoComplete="off"
        />
        {error && errorArr.includes("course") && (
          <p className="error_p">Please define course</p>
        )}
      </div>

      <div className="label">
        <div className="side">Institute : &nbsp;&nbsp;</div>
        <input
          type="text"
          className="inputTag"
          name="institute"
          placeholder="institute..."
          value={institute}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => handleChange(e, id)}
          onKeyUp={handleOnBlur}
          onBlur={handleOnBlur}
          autoComplete="off"
        />

        {error && errorArr.includes("institute") && (
          <p className="error_p">Please define institute</p>
        )}
      </div>
      <div
        className="label"
        style={{ display: "flex", gap: "12px", paddingTop: "10px" }}
      >
        <YearPicker
          label={"Start Year"}
          name={"start_year"}
          key={"sdsd" + id}
          id={"sdsd" + id}
          error={error}
          errorArr={errorArr}
          value={
            start_year ? dayjs(timeUtil.formatDate(new Date(start_year))) : null
          }
          formErrors={formErrors}
          onChange={(value) => {
            handleChange(
              {
                target: {
                  name: "start_year",
                  value: new Date(value),
                },
              },
              id
            );
            handleOnBlur({
              target: {
                name: "start_year",
                value: new Date(value),
              },
            });
          }}
          onBlur={(value) =>
            handleOnBlur({
              target: {
                name: "start_year",
                value: start_year,
              },
            })
          }
        />{" "}
        <YearPicker
          label={"End Year"}
          name={"end_year"}
          key={"end_year" + id}
          id={"end_year" + id}
          error={error}
          errorArr={errorArr}
          value={
            start_year ? dayjs(timeUtil.formatDate(new Date(end_year))) : null
          }
          formErrors={formErrors}
          onChange={(value) => {
            handleChange(
              {
                target: {
                  name: "end_year",
                  value: new Date(value),
                },
              },
              id
            );
            handleOnBlur({
              target: {
                name: "end_year",
                value: new Date(value),
              },
            });
          }}
          onBlur={(value) =>
            handleOnBlur({
              target: {
                name: "end_year",
                value: end_year,
              },
            })
          }
        />
      </div>

      <div className="CTA">
        {length - 1 === id ? (
          <Button
            style={{ width: "fit-content", padding: "10px" }}
            buttonName={"+ add more"}
            onButtonClick={(e) => {
              e.preventDefault();
              handleAdd();
            }}
          />
        ) : (
          <p></p>
        )}
        {length !== 1 && isDelete && (
          <Button
            style={{ width: "fit-content", padding: "10px" }}
            buttonName={"delete"}
            onButtonClick={(e) => {
              e.preventDefault();
              handleDelete(id);
            }}
          />
        )}
      </div>
    </StyledEducationInput>
  );
};

export default EducationInput;
