import styled from 'styled-components';
import { dim } from '../../../../../styles/theme';

export const ChartWrapper = styled.div`
	width: 100%;
	position: relative;
`;

export const ChartStyle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	width: 100%;
	margin: 0px 0 0 0;
	padding: ${dim._24px} ${dim._24px} ${dim._48px} ${dim._24px};
	opacity: ${({ isReadableFalse }) => (isReadableFalse ? '0.3' : '1')};
	background-color: #fff;
	box-sizing: border-box;

	.radioBtns {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0 0 0 0;
		background: #fff;
		box-sizing: border-box;
	}

	.x-axis-label,
	.y-axis-label {
		font-size: ${dim._14px};
		font-weight: 400;
		color: 'red';
	}
	.legend-text {
		text-transform: capitalize;
	}

	.message-container {
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		width: 100%;
	}

	.alert-icon {
		height: 16px;
	}

	.message {
		margin-left: 8px;
		font-size: 14px;
	}
`;
