import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const ReceivedMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  .msg_info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .msg_info > h3,
  .msg_info > p,
  .msg_info > span {
    color: #212f45;
    text-align: center;
    font-size: ${dim._12px};
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    line-height: normal;
  }

  .msg_info > p {
    margin: 0 ${dim._4px};
    opacity: 0.7;
  }

  .msg_info > span {
    opacity: 0.5;
  }

  .msg_detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: ${dim._6px} 0 0 0;
  }

  .msg_detail > img {
    width: ${dim._36px};
    height: ${dim._36px};
    border-radius: 50%;
    margin: 0 ${dim._16px} 0 0;
  }

  .message_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 70%;
    gap: ${dim._12px};
    margin: 0 0 0 ${dim._12px};
    padding: ${dim._12px};
    border-radius: ${dim._4px};
    background: #f5f5f5;
    box-sizing: border-box;
  }

  .message_content > p {
    width: auto;
    height: auto;
    margin: 0;
    color: #212f45;
    font-size: ${dim._12px};
    font-style: normal;
    font-weight: 400;
    line-height: ${dim._18px};
  }

  .chat_attachment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${dim._2px};
    width: 100%;
    box-sizing: border-box;
  }
`;
