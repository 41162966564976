import React, { useEffect, useState } from "react";
import IncomeTable from "../../Component/IncomeTable";
import { HouseRentStyle } from "./HouseRent.styled";
import { getSummarizedData, initializeDefaultData } from "./utils";
import { Loader2 } from "../../../../commonComponents/Loader";
import { timeStampDateNewFormat } from "../../../../utils/epochConverter";
import { useLocation } from "react-router";
import SuperSaverIncomeService from "../../../../Services/SuperSaverIncomeService";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";

const HouseRent = ({ houseRentData }) => {
    const { state, pathname } = useLocation();
    const [data, setData] = useState(null);
    const [houseRentIncome, setHouseRentIncome] = useState([]);
    const [finalHouseRentIncome, setFinalHouseRentIncome] = useState([]);
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    useEffect(() => {
        (async () => {
            const res = await SuperSaverIncomeService.getIncomeRevampData(
                "INCOME_FROM_HOUSE_RENT",
                state.user_id,
                fyId
            );
            setData(res.data);
        })();
    }, [fyId]);

    useEffect(() => {
        data && setHouseRentIncome(getSummarizedData(data.income_details));
    }, [data]);

    useEffect(() => {
        const data = initializeDefaultData(houseRentIncome);
        setFinalHouseRentIncome([...data]);
    }, [houseRentIncome]);

    const tableTitle = {
        top_a: "Amount",
        top_b: "Interest",
        side_a: "Total Proceeds",
        side_b: "Total Amount",
    };

    return (
        <>
            {data == null ? (
                <div
                    style={{
                        width: "100%",
                        height: "60vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loader2></Loader2>
                </div>
            ) : (
                <HouseRentStyle>
                    {data &&
                        finalHouseRentIncome &&
                        finalHouseRentIncome.map((data, i) => (
                            <IncomeTable
                                houseRentData={data}
                                tableTitle={tableTitle}
                                title={data.sub_type}
                                componentName={"INCOME_FROM_HOUSE_RENT"}
                                path={`/${
                                    pathname.split("/")[1]
                                }/incomeView/income-from-house-rent/detail`}
                                lastUpdatedOn={
                                    data.updated_at > data.created_at
                                        ? timeStampDateNewFormat(
                                              data.updated_at
                                          )
                                        : timeStampDateNewFormat(
                                              data.created_at
                                          )
                                }
                                col_a={data.amount}
                                col_b={data.interest}
                                total={data.amount - data.interest}
                                userId={state.user_id}
                            />
                        ))}
                </HouseRentStyle>
            )}
        </>
    );
};
export default HouseRent;
