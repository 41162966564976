export const monthlySalaryBreakdown = (inputObject) => {
	const keys = inputObject && Object.keys(inputObject);
	const values = inputObject && Object.values(inputObject);

	const allData =
		keys &&
		keys.map((month, index) => {
			return {
				month: month,
				breakdown: values[index]
			};
		});

	return allData;
};
