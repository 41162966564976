import styled from 'styled-components';

export const Tooltip = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	height: auto;
	margin: 0;
	padding: 12px 6px;
	border: 1px solid #ccc;
	background-color: #fff;
	box-sizing: border-box;

	.labels {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: auto;
		height: auto;
		margin: 0 0 10px 0;
		padding: 0;
		box-sizing: border-box;
	}

	.indicator1 {
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background-color: green;
	}
	.indicator2 {
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background-color: red;
	}

	.label {
		margin: 0 0 0 10px;
		font-size: 12px;
		font-weight: 400;
		color: #000;
	}
`;
