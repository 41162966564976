export const getContentTypeOnExt = (ext) => {
    const contentTypeMap = {
        png: 'image/png',
        pdf: 'application/pdf',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        json: 'application/json',
        csv: 'text/csv',
        xls: 'application/vnd.ms-excel',
        txt: 'text/plain',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    return contentTypeMap[ext] || 'application/octet-stream';
};