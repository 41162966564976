import React from "react";
import { ReadOnlyRowStyle } from "./ReadOnlyRow.styled";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
// import { convertNumberIntoINR } from '../../../../../../../../utils/ConvertNumnerIntoIndianCurrency';
// import { dim } from "../../../../../../../../styles/theme";
import { convertNumberIntoINR } from "../../../../../utils/ConvertNumnerIntoIndianCurrency";
import { dim } from "../../../../../styles/theme";

const ReadOnlyRow = ({ items, editId, handleEditClick, style, viewMode }) => {
  return (
    <ReadOnlyRowStyle style={style}>
      <div>{items.manualLabel ? items.manualLabel: items.label}</div>

      <div className="parentPos">
        {convertNumberIntoINR(parseInt(items.amount))}
        {!viewMode && (
          <>
            {" "}
            <FiEdit
              className="editBtn"
              style={{
                fontSize: dim._20px,
                cursor: "pointer",
                color: "#a5a5a5",
              }}
              onClick={() => handleEditClick(editId, true, "edit")}
            />
            {items.isEditable && (
              <AiOutlineDelete
                className="deleteBtn"
                style={{
                  fontSize: dim._20px,
                  cursor: "pointer",
                  color: "#a5a5a5",
                }}
                onClick={() => handleEditClick(editId, true, "remove")}
              />
            )}
          </>
        )}
      </div>
    </ReadOnlyRowStyle>
  );
};

export default ReadOnlyRow;
