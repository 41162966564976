import React, { useState } from 'react';
// import FileUpload from '../../../../../common-components/FileUpload';
// import NumberInput from '../../../../../common-components/NumberInput';
// import SelectDropdownInput from '../../../../../common-components/SelectDropdownInput';
// import TextInput from '../../../../../common-components/TextInput';
import { EditableRowStyle } from './EditableRowStyle';
import { AiOutlineCheck } from 'react-icons/ai';
import { FaCross } from 'react-icons/fa';
import { dim } from '../../../../../../../styles/theme';
// import { dim } from '../../../../../../../../../styles/theme';

const EditableRow = ({ items, handleEditClick, editId }) => {
	const [data, setData] = useState({
		label: items.label ? items.label : '',
		amount: items.amount ? items.amount : ''
	});

	const handleChange = (e) => {
		const { value, name } = e.target;
		setData({ ...data, [name]: value.toUpperCase() });
	};
	return (
		<EditableRowStyle active={data.label.length > 0 && data.amount.length > 0}>
			<div>
				<input
					type='text'
					name={'label'}
					placeholder='Component Name'
					onWheel={(e) => e.target.blur()}
					value={data.label}
					label={''}
					onChange={handleChange}
					autocomplete='off'
				/>
			</div>
			<div className='parentPos'>
				<input
					type='number'
					label={''}
					autocomplete='off'
					value={data.amount}
					onWheel={(e) => e.target.blur()}
					placeholder={'Amount'}
					name={'amount'}
					onChange={handleChange}
				/>
				<AiOutlineCheck
					className='icon  submit'
					onClick={() =>
						data.label.length > 0 && data.amount.length > 0
							? handleEditClick(editId, false, 'save', data)
							: null
					}
				/>
				{
					<FaCross
						className='icon'
						style={{
							background: '#ff4136',
							cursor: 'pointer',
							right: dim._10px
						}}
						onClick={() =>
							handleEditClick(
								editId,
								false,
								'cancel',
								'',
								items.isNew ? items.isNew : false
							)
						}
					/>
				}
			</div>
		</EditableRowStyle>
	);
};

export default EditableRow;
