import {advisorType} from "../../../constants/advisorType";

export const globalNotificationAction = {
  SET_NOTIFICATION_GLOBAL: "SET_NOTIFICATION_GLOBAL",
  SET_ADVISOR_CHAT_REFRESH_STATUS: "SET_ADVISOR_CHAT_REFRESH_STATUS",
  SET_LAST_MESSAGE_TIMESTAMP: "SET_LAST_MESSAGE_TIMESTAMP",
  SET_ACTIVE_CHAT_USER_ID: "SET_ACTIVE_CHAT_USER_ID",
  SET_FILTER_TYPE: "SET_FILTER_TYPE",
  SET_ADVISOR_TYPE: "SET_ADVISOR_TYPE",
};

const initialState = {
  notification: [],
  advisorRefreshStatus: true,
  lastMessageTimestamp: null,
  activeChatUserId: null,
  unreadMsgFilter: false,
  selectedAdvisorType: advisorType.PRIMARY,
};

export const globalNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case globalNotificationAction.SET_NOTIFICATION_GLOBAL:
      return {
        ...state,
        notification: action.payload,
      };
    case globalNotificationAction.SET_ADVISOR_CHAT_REFRESH_STATUS:

      return {
        ...state,
        advisorRefreshStatus: action.payload,
      };
    case globalNotificationAction.SET_ACTIVE_CHAT_USER_ID:
 
      return {
        ...state,
        activeChatUserId: action.payload,
      };
    case globalNotificationAction.SET_LAST_MESSAGE_TIMESTAMP:
      return {
        ...state,
        lastMessageTimestamp: action.payload,
      };
    case globalNotificationAction.SET_ADVISOR_TYPE:
      return {
        ...state,
        selectedAdvisorType: action.payload,
      };
    case globalNotificationAction.SET_FILTER_TYPE:
      return {
        ...state,
        unreadMsgFilter: action.payload,
      };

    default:
      return state;
  }
};
