import React from "react";
import { StyledConsultationHistory } from "./ConsultationHistory.styled";
import IncompleteAndCompleted from "../../Models/IncompleteAndCompleted";
import UpComingSessionList from "../../Models/UpComingSessionList";
import LayoutHOC from "../../../../components/UserSideBar";

const ConsultationHistory = ({ consultationData }) => {
  return (
    <StyledConsultationHistory>
      <UpComingSessionList upcomingSessionCalls={consultationData} />
      <IncompleteAndCompleted consultationData={consultationData} />
    </StyledConsultationHistory>
  );
};

export default LayoutHOC(ConsultationHistory);
