import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import moment from "moment";
import {toast} from "react-hot-toast";
import {updatePaymentNew} from "../../../Services/SuperSaver";
import Button from "../../../commonComponents/Button";
import {getTwoDecimal} from "../../../utils/numberUtils";
import {StyledPlanValueForm} from "./PlanValueForm.styled";

const DEFAULT_HEADING = "Do you want to Proceed with this operation ?";

const PlanValueForm = ({
  upgraded_plan_category_id,
  setChecked,
  totalPlanAmount,
  setopen,
  handleMsg,
  innerPage = false,
  userId,
  style,
  popContentStyle,
  heading = DEFAULT_HEADING,
  handleSteps,
  total_consumed_coins_amount,
  toggleBackdrop,
}) => {
  const [invoice, setInvoice] = useState("");
  const [amount, setAmount] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [discountObj, setDiscountObj] = useState({
    company_discount_amount: "",
    referral_discount_amount: "",
    gst_amount: "",
    payment_date: moment().format("YYYY-MM-DD"),
  });

  const [loading, setLoading] = useState(false);





  const handleDisable = (ele) => {
    const {name, value} = ele.target;
    const changeableValue = value;
    const alreadyChangedValue = name !== "amount" ? amount : invoice;
    if (
      changeableValue?.length > 0 &&
      alreadyChangedValue !== undefined &&
      alreadyChangedValue?.length > 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const renderArr = [
    {
      label: "Invoice No.",
      value: invoice,
      name: "invoice",
      fn: (e) => {
        setError('')
        const val = e.target.value;
        
        setInvoice(val);
        handleDisable(e);
      },
    },
    {
      label: "Amount",
      value: amount,
      name: "amount",
      fn: (e) => {
        
        const eleValue = e.target.value;
        setAmount(eleValue);
        const gst_amount =   eleValue * .18;
        const ele = {
          target: {
            name: "gst_amount",
            value: gst_amount,
          },
        };
        upgraded_plan_category_id && handleDiscountObjChange(ele);
        handleDisable(e);
      },
    },
    {
      label: "GST Amount",
      value: discountObj?.gst_amount,
      name: "gst_amount",
      fn: (ele) => {
        const {value} = ele.target;
        const modifiedVal = upgraded_plan_category_id
          ? getTwoDecimal(value)
          : value;
        handleDiscountObjChange(ele, modifiedVal);
      },
      disabled: upgraded_plan_category_id,
    },
    {
      label: "Discount Amount",
      value: discountObj?.company_discount_amount,
      name: "company_discount_amount",
      fn: (ele) => {
        handleDiscountObjChange(ele);
      },
    },
    {
      label: innerPage ? "Coins to use" : "Referral Amount",
      value: discountObj?.referral_discount_amount,
      name: "referral_discount_amount",
      fn: (ele) => {
        handleDiscountObjChange(ele);
      },
      disabled: upgraded_plan_category_id,
    },
  ];

  const handleSubmit = async () => {
    if (disabled) return null;

    if (
      Number(discountObj?.company_discount_amount) + Number(amount) !==
      +totalPlanAmount
    ) {
      setError("Amount and discount amount should be equal to Gross total");
      return null;
    }

    setError(null);
    setLoading(true);

    const paymentUpdateData = {
      invoice_id: invoice,
      amount: amount,
      company_discount_amount: discountObj?.company_discount_amount,
      payment_date: new Date(discountObj?.payment_date).getTime(),
      gst_amount: discountObj?.gst_amount,
      coin_used: discountObj?.referral_discount_amount,
      ...(upgraded_plan_category_id && {
        upgraded_plan_category_id,
      }),
    };

    try {
      const data = await updatePaymentNew(paymentUpdateData, userId);
      if (data?.code) {
        toast.error(data.message);
      } else {
        upgraded_plan_category_id
          ? handleSteps()
          : handleMsg("payment status changed successfully");
        setChecked(true);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setDiscountObj((state) => ({
      ...state,
      referral_discount_amount: total_consumed_coins_amount,
    }));
  }, [total_consumed_coins_amount]);

  const handleClose = () => {
    if (toggleBackdrop) {
      toggleBackdrop();
      return;
    }

    setChecked(false);
    setopen(false);
  };

  const handleDiscountObjChange = (ele, modifiedVal) => {
    setError('')
    const {name, value} = ele.target;
    setDiscountObj({
      ...discountObj,
      [name]: modifiedVal || value,
    });
  };

  return (
    <StyledPlanValueForm disabled={disabled} style={style}>
      <span
        className="closePop"
        role="button"
        tabindex={0}
        onClick={() => {
          handleClose();
        }}
        onKeyDown={() => {
          handleClose();
        }}
      >
        &times;
      </span>

      <div className="popContent" style={popContentStyle}>
        <b>{heading} </b>
      </div>

      <div className="input">
        {renderArr.map((data, index) => (
          <TextField
            className="standard-basic"
            label={data.label}
            type="number"
            variant="standard"
            name={data.name}
            disabled={data?.disabled}
            value={data?.value}
            onChange={(e) => {
              data.fn(e);
            }}
            key={index}
          />
        ))}

        <div
          className="flex paymentWrapper"
        >
          <label htmlFor="date">Payment Date:</label>
          <input
            type="date"
            id="date"
            className="dateInput"
            name="payment_date"
            value={discountObj.payment_date}
            max={moment().format("YYYY-MM-DD")}
            onChange={(e) => {
              handleDiscountObjChange(e);
            }}
          />
        </div>
        <p className="errorMsg">{error}</p>

        {handleSteps && (
          <Button
            style={{
              width: " 100%",
              ...(disabled && {
                opacity: 0.5,
              }),
            }}
            onDisable={disabled}
            onButtonClick={() => {
              handleSubmit();
            }}
            className="popButton yes"
          >
            {loading ? "Loading.." : "Update payment"}
          </Button>
        )}
      </div>
    </StyledPlanValueForm>
  );
};

export default PlanValueForm;
