import styled from "styled-components";
import { dim } from "../../../../styles/theme";
import { Theme } from "../../../../styles/colors";

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1px;
  width: 30%;
  height: 100%;
  margin: 0;
  z-index: 200;
  border-right: 1px solid #f1f3f6;
  border-left: 1px solid #f1f3f6;
  background-color: #f1f3f6;
  box-sizing: border-box;

  .all_conversation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: ${dim._48px};
    background-color: #fff;
    position: sticky;
    top: 0;
    padding: 0 0 0 ${dim._16px};
    z-index: 10;
    box-sizing: border-box;
  }

  .all_conversation > h2 {
    color: #323232;
    font-size: ${dim._14px};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: ${dim._48px};
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 ${dim._16px};
    box-sizing: border-box;
  }

  .tabs li {
    width: auto;
    height: 100%;
    padding: ${dim._20px} ${dim._16px} ${dim._8px} 0;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: ${dim._14px};
    line-height: ${dim._16px};
    white-space: nowrap;
    list-style: none;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    box-sizing: border-box;
  }

  .active {
    font-weight: 600;
    color: ${Theme.color.black_0};
    border-bottom: ${dim._2px} solid ${Theme.color.black_0};
  }
`;
