import React from 'react';

import SuccessMsgStyle from './Success.styled';
import { dim } from '../../styles/theme';


const Success = ({heading,subHeading, style, }) => {
	return (
		<SuccessMsgStyle style={style}>
			<div className='check'>
				<img src={process.env.PUBLIC_URL + '/assets/icons/tick2.svg'} alt='' />
			</div>
		{	heading&&<h1
				style={{
					fontSize: 20,
					maxWidth: dim._scale(574),
					whiteSpace: 'pre-wrap',
					textAlign: 'center'
				}}
			>
				{heading}
			</h1>}
		{	subHeading&&<p className='successContent'>
				{subHeading}
			</p>}
		</SuccessMsgStyle>
	);
};

export default Success;
