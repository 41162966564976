import styled from 'styled-components';

export const OtherCategoryStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	flex-grow: 1;
	height: 80px;
	margin: 0 0px;
	padding: 16px;
	position: relative;
	background-color: ${(props) =>
		props.active || props.otherActive ? '#e4ebff' : '#fff'};
	border-radius: 4px;
	cursor: pointer;
	box-sizing: border-box;

	.active {
		background-color: #ccc;
	}

	.otherLabel {
		margin: 8px 0 0 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		white-space: nowrap;
		color: ${(props) =>
			props.active || props.otherActive ? '#635BEF' : '#212f45'};
		opacity: 0.5;
	}

	.dropDown {
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		right: 40px;
	}

	.otherIncome {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: 166px;
		height: auto;
		position: absolute;
		top: 100%;
		left: 0;
		margin: 0 0px;
		padding: 0;
		background-color: #fff;
		border-radius: 4px;
		/* border: 1px solid red; */
		cursor: pointer;
		z-index: 1200;
		box-sizing: border-box;
	}

	.otherCategories {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 166px;
		height: auto;
		margin: 0 0 2px 0;
		padding: 6px 12px;
		font-weight: 500;
		font-size: 12px;
		color: #212f45;
		background-color: #fff;
		border-radius: 2px;
		border: 1px solid #e4ebff;
		cursor: pointer;
		box-sizing: border-box;
	}

	.otherCategories:hover {
		background-color: #e4ebff;
		color: #635bef;
	}
`;
