export const convertNumberIntoINR = (income) => {
    const convertedIncome = income.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
    });
    return convertedIncome;
};

export const convertTaxIntoINR = (tax) => {
    const convertedTax =
        tax &&
        tax.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        });
    return convertedTax;
};
