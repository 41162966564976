import React from 'react';
import { RowStyle } from './Row.styled';
import { convertNumberIntoINR } from '../../../../../utils/ConvertNumnerIntoIndianCurrency';

const Row = ({ tableTitle, col_a, col_b, total }) => {
	return (
		<RowStyle total={total}>
			<div className='tableData'>
				<p className='col-1 data-1'>{tableTitle.side_a}</p>
				<p className='col-2 data-2'>{convertNumberIntoINR(col_a)}</p>
				<p className='col-3 data-3'>{convertNumberIntoINR(col_b)}</p>
			</div>
			<div className='tableData'>
				<p className='col-1 data-1'>{tableTitle.side_b}</p>
				<p className='col-2 data-2'>
					{convertNumberIntoINR(Math.abs(total))}
					{total >= 0 ? ' (Profit)' : ' (Loss)'}
				</p>
				<p className='col-3 data-3'>{''}</p>
			</div>
		</RowStyle>
	);
};

export default Row;
