import styled from 'styled-components';

export const NestedListViewWrapper = styled.div`
	width: 100%;
	box-sizing: border-box;
	color: #212121;

	.first-level-item {
		width: 100%;
		margin-bottom:  8px;
		padding-bottom:  8px;
		border-bottom:  1px solid #f7f6f2;
	}

	.first-level-rows {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-size:  14px;
		align-items: center;
	}

	.first-level-title {
		display: flex;
		align-items: center;
		color: #373737;
	}
`;

export const Container = styled.div`
	height: 0px;
	transition: height 1s;
	display: ${({ active }) => (active == true ? 'block' : 'none')};
	height: ${({ active }) => (active == true ? '100%' : '0px')};
`;

export const SubItem = styled.div`
	width: calc(100% -  18px);
	margin:  8px 0px  8px  18px;
	color: #737373;

	.second-level-rows {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-size:  12px;
	}
`;
