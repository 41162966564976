import React from "react";
import PopUPCmsStyle from "./PopUPCms.styled";

const PopUPCms = ({
  show,
  children,
  top = 40,
  handleCross,
  width = 20,
  left = 108,
}) => {
  return (
    show && (
      <PopUPCmsStyle top={top} show={show} width={width} left={left}>
        {children}
      </PopUPCmsStyle>
    )
  );
};
export default PopUPCms;
