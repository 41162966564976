import styled from "styled-components";
import { theme, dim } from "../../../../../styles/theme";

const RenderAdvisorActionDataStyle = styled.div`
  width: 100%;
  background-color: ${theme.color.white_0};
  margin-top: ${dim._20px};
  border-radius: ${dim._4px};
  padding: ${dim._32px};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${dim._8px};

  .cardHeading {
    color: #212f45;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export { RenderAdvisorActionDataStyle };
