import { LOCAL_DB_KEYS } from "../constants/localStorage";
import BASE_URL from "./API_ENDPOINT";
import { getAuthToken } from "./SuperSaverClientChat";

let userName = localStorage.getItem("user");

export const getType = async () => {
    const url = `${BASE_URL}internal-user/v1/details`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token:  await getAuthToken(),
            Source: "cms",
        },
    });
   
    return  response.json();
};
export const getPendingDoc = async (idToken, userID) => {
    const url = `${BASE_URL}super-saver-user/v1/documents/all?userId=${userID}&financialYearId=${localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID)}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `${idToken}`,
            Source: "cms",
        },
    });
    const data = await response.json();
    return data;
};

export const postAdvisorDoc = async (idToken, req) => {
   
    const url = `${BASE_URL}super-saver-user/v1/documents/upload`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `${idToken}`,
            Source: "cms",
        },
        body: JSON.stringify(req),
    });
    const data = await response.json();
    return data;
};
export const updateAdvisorDocStatus = async (idToken, externalId, action) => {
    // const url = `${BASE_URL}super-saver-user/v1/documents/update?externalId=${externalId}&approvedBy=Kameshwar Maurya`;
    const url = `${BASE_URL}super-saver-user/v1/documents/update?externalId=${externalId}&approvedOrRejectedBy=${
        JSON.parse(userName).displayName
    }&documentAction=${action}`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `${idToken}`,
            Source: "cms",
        },
    });
    const data = await response.json();
    return data;
};
export default class DiscussionBook {
    static async getMessage(userId, data, token) {
        const response = await fetch(
            BASE_URL + `discussion-message/v1/all/internal?senderId=${userId}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token,
                    Source: "cms",
                },
                body: JSON.stringify(data),
            }
        );
        return response.json();
    }

    static sendMessage(data, senderId, token) {
        return fetch(
            BASE_URL + `discussion-message/v1/internal?senderId=${senderId}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    Source: "cms",
                },
                body: JSON.stringify(data),
            }
        );
    }
}

export const deleteAdvDoc = async (idToken, externalId) => {
    const url = `${BASE_URL}super-saver-user/v1/document/delete?externalId=${externalId}`;
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `${idToken}`,
            Source: "cms",
        },
    });
    const data = await response.json();
    return data;
};
