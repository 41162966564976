import styled from "styled-components";

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 0px 0 0;
    box-sizing: border-box;
    .searchFleX {
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;
        width: 100%;
        align-items: center;
        margin: 14px 0;
    }
    .heading {
        font-size: 24px;
        padding: 0;
        margin: 0px 0 20px 0;
        color: #7f7f7f;
    }
    .searchInput {
        margin: 0px;
        padding: 10px;
        height: 26px;
        width: 200px;
        font-size: 18px;
        border: 2px solid rgb(203, 205, 212);
        border-radius: 6px;
        color: black;
        outline: 0px;
        background: rgb(234, 236, 239);
        opacity: 1;
    }
    .resultS {
        width: 100%;
        box-sizing: border-box;
    }

    .MuiIconButton-sizeSmall {
        width: 40px !important;
        height: 40px !important;
    }
    .css-1s0hp0k-MuiDataGrid-columnHeadersInner {
        font-size: large;
        font-weight: 500;
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85px;
        height: 6px;
        margin: -5px 0 0 0;
        padding: 10px 18px;
        background: #0e6ab0;
        border: 1px solid #0e6ab0;
        border-radius: 4px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: #ffffff;
        cursor: pointer;
        box-sizing: border-box;
        font-family: Arial, Helvetica, sans-serif;
    }
`;
export const Table = styled.table`
    /* table { */
    .td {
        border: 1px solid #dddddd;
        min-width: 100px;
        text-align: center;
        padding: 8px;
        box-sizing: border-box;
    }
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    /* margin-top:5%; */
    /* }

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
} */
`;
export const Th = styled.th`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`;
export const Td = styled.td`
    /* display: flex; */
    /* justify-content:space-between; */
    border: 1px solid #dddddd;
    min-width: 140px;
    text-align: left;
    padding: 8px;
    box-sizing: border-box;
    /* display:flex; */
    /* justify-content:space-between; */
`;
export const Tr = styled.tr`
    .icon {
        display: inline-block;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    color: ${(props) =>
        props.flag === "BOOKED" || props.flag === "BLOCKED"
            ? "red"
            : "rgb(11, 106, 176)"};
    color: ${(props) =>
        props.appointment_status === "CANCELED"
            ? "rgba(11, 106, 176, 0.4)"
            : ""};
    /* ${(props) => (props.flag === "BOOKED" ? "red" : "black")} */
    &:nth-child(even) {
        background-color: rgba(11, 106, 176, 0.16);
    }
`;
