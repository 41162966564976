import React from "react";
import styled, { css } from "styled-components";
import { itr_documents_constant } from "../../constant";

const ActionWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(21, 190, 83, 0.2);
  background: #fff;
  color: #425466;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  img {
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
  }

  ${(props) =>
    props.approve &&
    css`
      &:hover {
        background: rgba(21, 190, 83, 0.1);
        border-color: rgba(21, 190, 83, 0.4);
      }
    `}

  ${(props) =>
    props.reject &&
    css`
      &:hover {
        background: rgba(190, 21, 21, 0.1);
        border-color: rgba(190, 21, 21, 0.4);
      }
    `}
`;

const ActionButton = ({ name, handleClick }) => {
  return (
    <ActionWrapper onClick={handleClick} approve={name === "approve"} reject={name === "reject"}>
      {name === "approve" ? (
        <>
          <img src={itr_documents_constant.approve_icon} alt="" /> Approve
        </>
      ) : (
        <>
          <img src={itr_documents_constant.reject_icon} alt="" /> Reject
        </>
      )}
    </ActionWrapper>
  );
};

export default ActionButton;
