import styled from "styled-components";

export const BackdropStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;

  .contentBackdrop {
    width: auto;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
`;
