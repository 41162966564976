import React, { useEffect, useState } from "react";
import ArticlePreview from "./ArticlePreview";
import { ArticleData } from "../../Services/savedArticleData";
import SavedArticlesDiv from "./SavedArticles.styled";

const SavedArticle = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => setSearchTerm(e.target.value);

  useEffect(() => {
    const results = ArticleData.data.filter((o) =>
      o.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm]);
  return (
    <SavedArticlesDiv className="savedArticles">
      <div className="SearchFlex">
        <div className="">
          <h2>Saved articles</h2>
        </div>
        <div>
          <input
            style={{
              margin: "0px",
              padding: "10px",
              height: "26px",
              width: "200px",
              fontSize: "18px",
              border: "2px solid rgb(203,205,212)",
              borderRadius: "6px",
              color: "black",
              outline: "0px",
              background: "rgb(234,236,239)",
              opacity: "1",
            }}
            type="text"
            value={searchTerm}
            onChange={handleChange}
            placeholder="🔍 Search Title"
          />
        </div>
      </div>

      <div className="results">
        {searchResults &&
          searchResults.map((item, i) => (
            <ArticlePreview
              type={item.type}
              references={item.references}
              tags={item.tags}
              title={item.title}
              shortDescription={item.summary}
              articleDescription={item.payload}
              thumbnail={item.articleThumbnailImage}
              author_id={item.author_id}
              article_unique_id={item.article_unique_id}
              key={i}
            />
          ))}
      </div>
    </SavedArticlesDiv>
  );
};

export default SavedArticle;
