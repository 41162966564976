import React, { useState } from "react";
import Conversation from "../Conversation";
import ConversationHeading from "../ConversationHeading";
import { ChatScreenStyled } from "./ChatScreenStyled";
import { useSelector } from "react-redux";
import MessageSendContainer from "../MessageSendContainer";
import { useParams } from "react-router";

const ChatScreen = (props) => {
  const {
    selectedChat,
    handleAttachment,
    attachment,
    userDetails,
    userId,
    advisorId,
    name,
  } = props;
  const params = useParams();
  const selectedAdvisorId = params["id"];
  const sessions = useSelector((state) => state.chat.session);
  const [docUploading, setDocUploading] = useState([]);

  const checkDocStatus = (docs) => {
    setDocUploading(docs);
  };

  return (
    <ChatScreenStyled>
      <div className="chat_conversation_components">
        <Conversation
          advisorId={advisorId}
          selectedChat={selectedChat}
          handleAttachment={handleAttachment}
          attachment={attachment}
          userDetails={userDetails}
          name={name}
          userId={userId}
        />
        {!selectedAdvisorId &&
          sessions[selectedChat] &&
          sessions[selectedChat].status === "OPEN" && (
            <MessageSendContainer
              handleAttachment={handleAttachment}
              advisorId={advisorId}
              attachment={attachment}
              userId={userId}
              checkDocStatus={checkDocStatus}
            />
          )}
      </div>
      {docUploading?.length > 0 && <div className="doc_backdrop"></div>}
    </ChatScreenStyled>
  );
};

export default ChatScreen;
