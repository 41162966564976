const handleFacebookShare = (articleUrl) => {
	const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${articleUrl}`;
	window.open(shareUrl, '_blank');
};

const handleTwitterShare = (articleUrl, headerContent) => {
	const shareText = encodeURIComponent(headerContent);
	const shareUrl = `https://twitter.com/intent/tweet?url=${articleUrl}`;
	window.open(shareUrl, '_blank');
};

const handleLinkedInShare = (articleUrl) => {
	const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${articleUrl}`;
	window.open(shareUrl, '_blank');
};

export const shareArticle = [
	{
		img: 'fbArticle.svg',
		label: 'facebook',
		clickEvent: handleFacebookShare
	},
	{
		img: 'twitterArticle.svg',
		label: 'twitter',
		clickEvent: handleTwitterShare
	},
	{
		img: 'linkedinArticle.svg',
		label: 'linkedin',
		clickEvent: handleLinkedInShare
	}
];
