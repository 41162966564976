import styled from "styled-components";

export const RejectPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 600px;
  height: 434px;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 12px 0;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
  }

  .head h1 {
    color: #212f45;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .head img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .comments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  .count_desc {
    font-size: 10px;
    position: absolute;
    right: 6px;
    color: #212f45;
    font-weight: 500;
    bottom: -16px;
  }

  .comments > p {
    color: #323232;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .reject_btn {
    margin-top: auto;
    margin-bottom: 50px;
  }
`;
