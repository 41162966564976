import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const UserProfileForAdvisorStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 84%;
  position: fixed;
  .example::-webkit-scrollbar {
    display: none;
  }
  .expireStrip{
    width: 100%;
    background-color: rgb(255 26 0 / 30%);
    color: red;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;
}
  .chat {
    margin: 0 20px 20px;
    width: 100%;
  }
`;
export const ProfileStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75%;
  height: auto;
  margin: 0 20px 20px;
  padding: 0 0 20px 0;
  box-sizing: border-box;
`;

export const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 15px 3px rgba(0, 0, 0, 0.03);
  box-sizing: border-box;

  img {
    width: 64px;
    height: 64px;
    margin: 0 0 10px 0;
  }

  h1 {
    margin: 0px 0 0 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #212f45;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 0 0;

  h2 {
    margin: 0 0 0 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #828976;
  }

  p {
    margin: 0 0 0 10px;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828976;
  }

  .lang {
    padding: 4px 8px;
    background-color: #e6e6e6;
    border-radius: 16px;
  }

  .due {
    font-weight: 600;
    color: #d64541;
  }
`;
