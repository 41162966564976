import styled from 'styled-components';

export const IncomeTableStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	height: auto;
	margin: 0 0 20px 0;
	padding: 24px;
	background: #fff;
	border-radius: 4px;
	box-sizing: border-box;

	.tableTitle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0 0 0 0;
		box-sizing: border-box;
	}

	.tableTitle p {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: #212f45;
		text-transform: capitalize;
	}

	a {
		font-weight: 600;
		font-size: 12px;
		line-height: 22px;
		color: #15be53;
		text-decoration: underline;
		cursor: pointer;
	}
	.details {
		font-weight: 600;
		font-size: 12px;
		line-height: 22px;
		color: #15be53;
		text-decoration: underline;
		cursor: pointer;
	}

	.radioBtns {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: auto;
		height: auto;
		margin: 0;
		padding: 0 0 0 0;
		background: #fff;
		box-sizing: border-box;
	}

	.updatedDate {
		margin: 8px 0 0 0;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #212f45;
		opacity: 0.5;
	}
`;
