import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import Styled from "styled-components";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import { getAuth } from "@firebase/auth";
import BASE_URL from "../../Services/API_ENDPOINT";
const AuthorSelectStyle = Styled.div`
    
    .select {
      margin: {a.Appointmets?"0px":"0 0 10px 10px"};
      min-width:250px;
      width: auto;
    }
`;
function CustomSelect({
  Status,
  AdvisorId,
  Id,
  Date1,
  Change,
  arrayData,
  clear,
  token,
  defaultValue,
  handleInput,
}) {
  const [selectedValue, setSelectedValue] = useState([]);

  const [authorData, setAuthorData] = useState(null);
  const [StartTimeData, setStartTimeData] = useState([]);

  const selectInputRef = useRef();

  useEffect(() => {
    if (clear) {
      selectInputRef.current.clearValue();
    }
  });

  const a = useContext(AdvisorContext);
  const idToken = token;
  const getDateTime = (timeStamp) => {
    var date = new Date(timeStamp);

    var month = date.getMonth() + 1;
    var day = date.getDate();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;

    var str = date.getFullYear() + "-" + month + "-" + day; //+

    return str;
  };

  useEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (authorData == null) {
        const url = `${BASE_URL}authors/v1/all`;
        getAuth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(async function (idToken) {
            const res = await fetch(url, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `${idToken}`,
                Source: "cms",
              },
            });
            const authorData = await res.json();
            setAuthorData(authorData);
          });
      }
    }
  );
  const handleChange = (e) => {
    a.setViewAppointmentsData(e.value);
    setSelectedValue(e);
  };
  useEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      const url = `${BASE_URL}timeSlots/v1/?advisorId=${AdvisorId}`;
      if (AdvisorId !== undefined) {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
            Source: "cms",
          },
        });
        const authorData = await res.json();
        function getDateWiseTimeSlots(authorData = []) {
          let slotsWithDateTime = authorData.map((item) => {
            return {
              ...item,
              start_timestamp: item.start_time,
              end_timestamp: item.end_time,
              start_time: getDateTime(item.start_time),
              end_time: getDateTime(item.end_time),
              label:
                new Date(item.start_time).toLocaleTimeString("en-US") +
                "-" +
                new Date(item.end_time).toLocaleTimeString("en-US"),
              value: item.id, //item.start_time.split(" ")[1],
              isDisabled: item.flag === "BOOKED",
            };
          });
          return slotsWithDateTime;
        }
        getDateWiseTimeSlots(authorData);
        setStartTimeData(getDateWiseTimeSlots(authorData));
      }
    },
    [a.table, a.AdvisorUserId, AdvisorId, a.setViewAppointmentsData, idToken]
  );

  const authors =
    authorData === null
      ? null
      : authorData.map((data) => ({
          value:
            data.user_details.first_name + " " + data.user_details.last_name,
          label:
            data.user_details.first_name + " " + data.user_details.last_name,
        }));
  const StartTime =
    StartTimeData === null || StartTimeData.length === 0
      ? null
      : StartTimeData.filter(
          (d) => d.start_time === Date1 && d.flag !== "BOOKED"
        ).map((data) => ({
          label: data.label,
          value: { start: data.start_timestamp, end: data.end_timestamp },
        }));

  useEffect(() => {
    setSelectedValue([]);
  }, [Date1]);

  return (
    <AuthorSelectStyle>
      {arrayData !== undefined ? (
        <Select
          value={selectedValue}
          className="select"
          onChange={(e) => handleChange(e)}
          options={StartTime}
        />
      ) : (
        <Select
          ref={selectInputRef}
          className="select"
          // value={selectedValue}
          // value={`${a.ViewAppointmentsData.start} - ${a.ViewAppointmentsData.start}`}
          onChange={clear ? null : handleInput}
          options={authors}
          defaultValue={defaultValue}
        />
      )}
    </AuthorSelectStyle>
  );
}

export default CustomSelect;
