import styled from "styled-components";

export const StyledIncompleteAndCompleted = styled.div`
  .selectorModel {
    display: flex;
    justify-content: flex-start;
    align-items: start;

    border-bottom: 1px dotted #c6c6c6;
  }
`;
