import styled from "styled-components";

export const PopUpForActivityUpdateStyled = styled.div`
  background: #fff;
  margin: auto;
  height: auto;
  border-radius: 8px;
  width: 433px;
  position: relative;
  .popContent {
    font-size: 20px;
    margin: 0 60px 0 60px;
    color: #808080;
    text-align: center;
    padding-top: 30px;
  }
  .closePop {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .input {
    display: flex;
    flex-direction: column;
    margin: 26px 60px;
    /* position: absolute; */
  }
  .cb {
    display: none;
  }

  .button {
    /* position: absolute; */
    display: block;
    width: 155px;
    height: 50px;
    /* top: 175px; */
    /* left: 150px; */
    border-radius: 25px;
    text-align: center;
    line-height: 46px;
    text-transform: uppercase;
    font-weight: 600;
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    transform: ${(props) => (props.checked ? "rotateX(180deg)" : "none")};
    box-shadow: ${(props) =>
      props.checked
        ? "4px -8px 12px 0 rgba(0, 0, 0, 0.1)"
        : "4px 8px 12px 0 rgba(0, 0, 0, 0.1)"};
  }
`;
export const Front = styled.span`
  box-sizing: border-box;
  position: absolute;
  display: block;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #34495e;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 25px;
  backface-visibility: hidden;
  transform: rotateX(0);
`;
export const Back = styled(Front)`
  z-index: 1;
  color: #0e6ab0;
  background: #d8e7f2;
  border: 2px solid #fff;
  transform: rotateX(180deg);
`;
