import React from "react";
import styled from "styled-components";

function CustomDatePicker({ value, style, onChange, min = null, max = null }) {
  return (
    <PickerWrapper style={style}>
      <input
        className="input-date"
        type="date"
        name="date"
        value={value}
        min={min}
        max={max}
        onChange={onChange}
      />
    </PickerWrapper>
  );
}

export default CustomDatePicker;

const PickerWrapper = styled.div`
  display: inline-block;
  border: 1px solid #bfbfbf;
  padding: 8.5px;
  border-radius: 4px;

  .input-date {
    border: 0;
    outline: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #787878;
  }
`;
