import { Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import LoaderPage from "../../commonComponents/LoaderPage";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import { getAllRentAgreement } from "../../Services/RentAgriment";
import { StyledRentAgreement } from "./RentAgreement.styled";
import DataGridCustomTooltip from "../../commonComponents/DataGridCustomTooltip";

const RentAgreement = () => {
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(async () => {
    try {
      const data = await getAllRentAgreement();
      setData(data);
      setLoader(false);
    } catch (e) {
      toast.error(e);
    }
  }, []);

  const AgrementColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "land_lord_pancard",
      headerName: "Land lord pancard",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "land_lord_name",
      headerName: "Land lord name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
      renderCell: (params) => (
        <DataGridCustomTooltip dataVal={params?.value} showCCB={false} />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <DataGridCustomTooltip dataVal={params?.value} showCCB={false} />
      ),
    },
  ];

  const timeStampDate = (timeStamp) => {
    var date = new Date(timeStamp);
    var yyyy = date.getFullYear();
    var mm = date.getMonth() + 1; // Months start at 0!
    var dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    date = `${yyyy}-${mm}-${dd}`;
    return date;
  };
  const getDate = (startDate, endDate) => {
    if (endDate !== undefined) {
      return `${timeStampDate(startDate)
        .split("-")
        .reverse()
        .join("/")} - ${timeStampDate(endDate).split("-").reverse().join("/")}`;
    } else {
      return `${timeStampDate(startDate).split("-").reverse().join("/")}`;
    }
  };

  function rowFunction(value, index, array) {
    return {
      id: index,
      name: value.name,
      status: value.status,
      mobile: value.mobile,
      email: value.email,
      land_lord_name: value.land_lord_details.name,
      land_lord_pancard: value.land_lord_details.pancard,
      duration: getDate(value.start_date, value.end_date),
      details: value.details,
      land_lord_details: value.land_lord_details,
      externalId: value.external_id,
    };
  }
  const handleOnCellClick = (params, navigate) => {
    if (params.field === "actions") {
      return;
    }
    navigate("/rent-agreement/details", {
      state: { item: params.row },
    });
  };

  return (
    <>
      {loader ? (
        <LoaderPage />
      ) : (
        <StyledRentAgreement>
          <div className="resultS">
            <div className=""></div>
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={AgrementColumns}
                rowFunction={rowFunction}
                handleOnCellClick={handleOnCellClick}
              />
            </CardAddSlot>
          </div>
        </StyledRentAgreement>
      )}
    </>
  );
};

export default RentAgreement;
