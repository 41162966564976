import BASE_URL from "./API_ENDPOINT";
import { httpClient } from "./httpUtil";

export const updateSlots = async (data, cntxt, idToken) => {
    const url = `${BASE_URL}appointments/v1/rescheduleAppointment`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
            Source: "cms",
        },
        body: JSON.stringify(data),
    });

    cntxt.setDelete(true);
    cntxt.setViewAppointmentsData({ start: 22, end: 22 });
    return response;
};
export const addSlot = async (
    advisor_id,
    date,
    start_time,
    end_time,
    username,
    consultation_fee,
    discounted_fee,
    percentage_off,
    cntxt,
    idToken,purpose
) => {
    const url = `${BASE_URL}timeSlots/v1/post`;
    const data = date.map((d, i) => ({
        advisor_id: advisor_id,
        start_time: new Date(`${d} ${start_time}`).getTime(),
        end_time: new Date(`${d} ${end_time}`).getTime(),
        created_by: JSON.parse(localStorage.getItem("user")).displayName,
        consultation_fee: consultation_fee,
        discounted_fee: discounted_fee,
        percentage_off: percentage_off, slot_type:purpose.value
    }));

    const config = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
            Source: "cms",
        },
        body: JSON.stringify(data),
    };
    cntxt.setTf(true);
    const response = await fetch(url, config);

    const parsedRes= await response.json()
    return parsedRes;
};

const putBlock=(data,user)=>{
    const url = `timeSlots/v1/blockSlot?slotId=${
        data.id
      }&blockedBy=${
        data.flag === "AVAILABLE" ? user.username : ""
      }&flag=${
        data.flag === "AVAILABLE" ? "BLOCKED" : "AVAILABLE"
      }`;
   return  httpClient.put(url, data, );
}

export {putBlock}
