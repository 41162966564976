import axios from "axios";
import { LOCAL_DB_KEYS } from "../constants/localStorage";
import BASE_URL from "./API_ENDPOINT";
import { getHeaders } from "./SuperSaverClientChat";

export const FileUploadService = {
    uploadFile: async (fileName, imageFile, bucketName) => {
        try {
            // collect props
            const user = JSON.parse(
                localStorage.getItem(LOCAL_DB_KEYS.USER_DETAILS)
            );

            // get presigned url
            const suffix = "";
            const pathname =
                "user-files/super-saver-client-docs/presigned-s3url-for-upload"; // "file-upload";
            const url = `${BASE_URL}${pathname}?userId=${user.id}&fileName=${fileName}&bucketName=${bucketName}&bucketSuffix=${suffix}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    ...(await getHeaders()),
                    userId: user.id,
                },
            });
            const presignedUrl = await response.text();

            // upload image
            return await axios.put(presignedUrl, imageFile);
        } catch (error) {
            return error;
        }
    },
};
