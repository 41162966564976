import React from 'react';
import { DocumentPreviewWrapper } from './DocumentPreview.styled';
import { internalChatData } from '../../constant';
import { FaArrowDownLong } from 'react-icons/fa6';
import { dim } from '../../../../styles/theme';

const DocumentPreview = ({
	handleCrossImage,
	index,
	data,
	uploading = false,
}) => {
	return (
		<DocumentPreviewWrapper uploading={uploading}>
			<img src={internalChatData.pdf_icon} alt='' />
			<p>{data?.name}</p>
			{!uploading ? (
				<div className='download_icon'>
					<FaArrowDownLong style={{ fontSize: dim._12px, color: '#635BEF' }} />
				</div>
			) : (
				<div className='cross_icon' onClick={() => handleCrossImage(index)}>
					+
				</div>
			)}
		</DocumentPreviewWrapper>
	);
};

export default DocumentPreview;
