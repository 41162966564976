import React from 'react';
import styled from 'styled-components';

const PrefixInput = ({
	prefix,
	value,
	defaultValue = '',
	type = 'text',
	placeholder = '',
	style = {},
	disabled = false,
	onChange = () => {}
}) => {
	return (
		<Wrapper style={style} className='input-box'>
			<span className='prefix'>{prefix}</span>
			<input
				onWheel={(e) => type === 'number' && e.target.blur()}
				disabled={disabled}
				type={type}
				placeholder={placeholder}
				value={value || defaultValue}
				onChange={onChange}
			/>
		</Wrapper>
	);
};

export default PrefixInput;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	height: 34px;
	background: #fff;
	border: 1px solid #a0a0a0;
	border-radius: 4px;
	padding-left: 0.5rem;
	overflow: hidden;
	box-sizing: border-box;

	.prefix {
		font-weight: 300;
		font-size: 18px;
		color: #999;
	}

	input {
		width: 80px;
		flex-grow: 1;
		font-size: 18px;
		background: transparent;
		border: none;
		outline: none;
		padding: 0.5rem;
	}

	.input-box:focus-within {
		border-color: #777;
	}
`;
