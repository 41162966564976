import React from "react";
import { ReadOnlyRowStyle } from "./ReadOnlyRow.styled";
import { FiEdit } from "react-icons/fi";
import { toast } from "react-hot-toast";
import { extractValue } from "../../Screens/SalaryIncomeDetails/util";
import { preSignedUrlDownload } from "../../../../utils/preSignedUrl";
import { timeStampDateRevers } from "../../../../utils/currentDate";

const ReadOnlyRow = ({ items, editId, handleEditClick, userId }) => {
    return (
        <ReadOnlyRowStyle>
            <div>{items.type}</div>
            <div>{items.subType}</div>
            <div>{items.details}</div>
            <div>{items.amount}</div>
            <div>{items.sellingPrice}</div>
            <div l>
                {items.startDate ? timeStampDateRevers(items.startDate) : ""}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    cursor: "pointer",
                }}
            >
                {items.document && items.document.length > 1 ? (
                    <span
                        style={{
                            textDecoration: "underline",
                            display: "inline-block",
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                        }}
                        onClick={() => {
                            preSignedUrlDownload(items.document, userId)
                                .then((d) => {
                                    window.open(d, "__blank");
                                })
                                .catch((d) => {
                                    toast.error(
                                        d ? d : "Oops Something went wrong"
                                    );
                                });
                        }}
                    >
                        {" "}
                        {extractValue(items.document)}
                    </span>
                ) : (
                    "---"
                )}

                <div className="editBtn">
                    <FiEdit
                        style={{
                            fontSize: "20px",
                            color: "green",
                            cursor: "pointer",
                        }}
                        onClick={() => handleEditClick(editId, true, "edit")}
                    />
                </div>
            </div>
        </ReadOnlyRowStyle>
    );
};

export default ReadOnlyRow;
