import styled from "styled-components";

export const SavedArticlesDiv= styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 0px 0 0;
    box-sizing: border-box;
    .SearchFlex{
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;
        width: 100%;
        align-items: center;
    }
    .savedArticles h2{
    font-size: 24px;
    padding: 0;
    margin: 0px 0 20px 0;
    color: #7F7F7F;
}


`
export default SavedArticlesDiv;