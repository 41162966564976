import { LOCAL_DB_KEYS } from "../constants/localStorage";
import { httpClient } from "./httpUtil";

const ITRServices = {
  uploadFiles: (data, userId) => {
    return httpClient.post("itr/v1/file-upload?userId=" + userId, data);
  },
  getITRDetails: (userId) => {
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    return httpClient.get(
      "itr/v1/all?userId=" + userId + "&financial_year_id=" + fyId,
      {},
      { userId: userId }
    );
  },
  verifyITRDocs: (data) => {
    return httpClient.put(`/itr/v2/verify-document`, data);
  },
  getITRFilingUsers: (status) => {
    const fyYear = localStorage
      .getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR)
      .split("-")[0];
    return httpClient.get(
      `/itr/v2/users-by-itr-status?status=${status}&financial_year=${fyYear}`,
      {},
      {}
    );
  },
  requestDocument: (data) => {
    return httpClient.post(`/itr/v2/itr-action-item`, data);
  },
};

export default ITRServices;
