export const data = [
	{
		month: 'Jan',
		verified: 4000,
		unverified: 2400,
		amt: 2400
	},
	{
		month: 'Feb',
		verified: 3000,
		unverified: 1398,
		amt: 2210
	},
	{
		month: 'Mar',
		verified: 2000,
		unverified: 9800,
		amt: 2290
	},
	{
		month: 'Apr',
		verified: 2780,
		unverified: 3908,
		amt: 2000
	},
	{
		month: 'May',
		verified: 1890,
		unverified: 4800,
		amt: 2181
	},
	{
		month: 'Jun',
		verified: 2390,
		unverified: 3800,
		amt: 2500
	},
	{
		month: 'Jul',
		verified: 3490,
		unverified: 4300,
		amt: 2100
	},
	{
		month: 'Aug',
		verified: 3490,
		unverified: 4300,
		amt: 2100
	},
	{
		month: 'Sep',
		verified: 3490,
		unverified: 4300,
		amt: 2100
	},
	{
		month: 'Oct',
		verified: 3490,
		unverified: 4300,
		amt: 2100
	},
	{
		month: 'Nov',
		verified: 3490,
		unverified: 4300,
		amt: 2100
	},
	{
		month: 'Dec',
		verified: 3490,
		unverified: 4300,
		amt: 2100
	}
];

export const data2 = [
	{
		month: 'Jan',
		verified: 3000,
		unverified: 1400,
		amt: 2400
	},
	{
		month: 'Feb',
		verified: 5000,
		unverified: 4398,
		amt: 2210
	},
	{
		month: 'Mar',
		verified: 7000,
		unverified: 1800,
		amt: 2290
	},
	{
		month: 'Apr',
		verified: 5780,
		unverified: 2908,
		amt: 2000
	},
	{
		month: 'May',
		verified: 9890,
		unverified: 5800,
		amt: 2181
	},
	{
		month: 'Jun',
		verified: 3390,
		unverified: 1800,
		amt: 2500
	},
	{
		month: 'Jul',
		verified: 3490,
		unverified: 7300,
		amt: 2100
	},
	{
		month: 'Aug',
		verified: 3490,
		unverified: 4300,
		amt: 2100
	},
	{
		month: 'Sep',
		verified: 3490,
		unverified: 4300,
		amt: 2100
	},
	{
		month: 'Oct',
		verified: 6490,
		unverified: 6300,
		amt: 2100
	},
	{
		month: 'Nov',
		verified: 1490,
		unverified: 6300,
		amt: 2100
	},
	{
		month: 'Dec',
		verified: 9490,
		unverified: 3300,
		amt: 2100
	}
];

export const dataaaa = [
	{
		month: 'APRIL',
		verified: 2780,
		unverified: 3908
	},
	{
		month: 'MAY',
		verified: 1890,
		unverified: 4800
	},
	{
		month: 'JUNE',
		verified: 2390,
		unverified: 3800
	},
	{
		month: 'JULY',
		verified: 3490,
		unverified: 4300
	},
	{
		month: 'AUGUST',
		verified: 3490,
		unverified: 4300
	},
	{
		month: 'SEPTEMBER',
		verified: 3490,
		unverified: 4300
	},
	{
		month: 'OCTOBER',
		verified: 3490,
		unverified: 4300
	},
	{
		month: 'NOVEMBER',
		verified: 3490,
		unverified: 4300
	},
	{
		month: 'DECEMBER',
		verified: 3490,
		unverified: 4300
	},
	{
		month: 'JANUARY',
		verified: 4000,
		unverified: 2400
	},
	{
		month: 'FEBRUARY',
		verified: 3000,
		unverified: 1398
	},
	{
		month: 'MARCH',
		verified: 2000,
		unverified: 9800
	}
];
