const defaultData = [
	{
		external_id: '',
		label: 'FROM GROW',
		sub_type: 'NFT',
		amount_invested: 0,
		investment_date: new Date().getTime(),
		is_sold: true,
		selling_amount: 0,
		selling_date: new Date().getTime(),
		updated_at: new Date().getTime(),
		created_at: new Date().getTime()
	},
	{
		external_id: '',
		label: 'FROM GROW',
		sub_type: 'CRYPTO_CURRENCY',
		amount_invested: 0,
		investment_date: new Date().getTime(),
		is_sold: true,
		selling_amount: 0,
		selling_date: new Date().getTime(),
		updated_at: new Date().getTime(),
		created_at: new Date().getTime()
	}
];

export const getSummarizedData = (data) => {
	const updatedCrypto =
		data &&
		data.reduce((acc, current) => {
			const existingSubTypeIndex = acc.findIndex(
				(item) => item.sub_type === current.sub_type
			);
			if (existingSubTypeIndex !== -1) {
				acc[existingSubTypeIndex].selling_amount += current.selling_amount;
				acc[existingSubTypeIndex].amount_invested += current.amount_invested;
			} else {
				acc.push(current);
			}
			return acc;
		}, []);
	return updatedCrypto;
};

export const initializeDefaultData = (data) => {
	const res = defaultData.map((it) => {
		const obj = data && data.find((ele) => ele.sub_type === it.sub_type);
		return obj !== undefined ? obj : it;
	});
	return res;
};
