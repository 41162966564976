import React from 'react';
import SelectDropdownInputStyle from './SelectDropdownInput.styled';

const SelectDropdownInput = ({
	options,
	name,
	onChange,
	value,
	defaultValue
}) => {
	return (
		<SelectDropdownInputStyle
			onChange={onChange}
			name={name}
			value={value}
			defaultValue={defaultValue}
		>
			{options.map((item, i) => (
				<option key={i} value={item} selected={i == 0}>
					{item}
				</option>
			))}
		</SelectDropdownInputStyle>
	);
};

export default SelectDropdownInput;
