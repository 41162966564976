import TimePicker from "rc-time-picker";
import styled from "styled-components";

export const ManageAppointmentWrapper = styled.div`
    height: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 32px;
width:100%;
    .main-wrapper {
        justify-content: start;
        align-items: start;
        box-sizing: border-box;
    }

    .flex-row {
        display: flex;
    }
    .whiteBg{
        background-color: #fff;
        padding:20px;
        min-width:860px;
        box-sizing: border-box;
    }

    .vertical-divider {
        height: 235px;
        background-color: #bebebe;
        width: 1px;
        margin-right: 20px;
        margin-top: 44px;
    }

    .heading {
        margin-top: -20px;
        width: 360px;
        margin-right: 20px;
    }

    .label {
        flex: 1;
        font-size: 12px;
        color: #bbbbbb;
        margin: 8px 0px;
        text-align: left;
    }

    .event-body-text {
        width: 100%;
        height: 200px;
        padding: 5px;
        box-sizing: border-box;
        margin-top: 12px;
    }

    .event-title-text {
        width: 100%;
        padding: 5px;
        height: 30px;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .api-message {
        padding: 20px 0px;
    }

    .success {
        color: green;
    }

    .error {
        color: red;
        border: 0px;
    }

    .pending {
        color: #d18e1f;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    .description-input-container {
        width: 75vw;
        width:100%;
        box-sizing: border-box;
    }
`;

export const StyledTimePicker = styled(TimePicker)`
    height: 30px;
    text-align: center;
    box-sizing: border-box;
    position: relative;

    svg {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    & .rc-time-picker-panel-select-option-selected {
        background-color: #edeffe;
        font-weight: normal;
    }

    & .rc-time-picker-clear,
    & .rc-time-picker-clear-icon:after {
        font-size: 15px;
        position: absolute;
        right: 40px;
    }

    & .rc-time-picker-panel-select,
    & .rc-time-picker-input,
    & .rc-time-picker-panel-input {
        font-family: "Consolas", sans-serif;
        font-size: 16px;

        cursor: pointer;
        ::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .rc-time-picker-input {
        width: 175px !important;
        padding: 10px;
    }
`;
