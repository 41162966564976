import React from "react";
import MainDiv from "./Toggle.styled";
import { toPascalCase } from "../../utils/ConvertoPascalCase";

const Toggle = ({
  selectedValue = "article",
  firstValue = "article",
  secondValue = "blog",
  handleSelectedValue,
}) => {
  return (
    <MainDiv>
      <div
        className={
          selectedValue &&
          selectedValue.toLowerCase() === firstValue.toLowerCase()
            ? "article active-tab"
            : "article "
        }
        onClick={() => handleSelectedValue(firstValue)}
      >
        {toPascalCase(firstValue)}
      </div>
      <div
        className={
          selectedValue &&
          selectedValue.toLowerCase() === secondValue.toLowerCase()
            ? "blog active-tab"
            : "blog"
        }
        onClick={() => handleSelectedValue(secondValue)}
      >
        {toPascalCase(secondValue)}
      </div>
    </MainDiv>
  );
};

export default Toggle;
