import React from "react";

const PDFViewer = (props) => {
  const { title, style, onClick, src } = props;
  const newlink = src + "#toolbar=0";
  return (
    <div style={style}>
      <iframe
        src={newlink}
        width="100%"
        title={title}
        type="application/pdf"
        {...props}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};
export default PDFViewer;
