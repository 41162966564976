import { React, useEffect, useLayoutEffect, useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { getFinancialYearId } from "../../Services/SuperSaver";
import { useDispatch } from "react-redux";
import { actions } from "../../container/Layout/Store/LayoutAction";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { Chip } from "@mui/material";
import { Actions } from "../../Routes/store/action";

export const colourStyles = {
  option: (styles, { data, isSelected }) => {
    return {
      ...styles,
      backgroundColor: (() => {
        if (isSelected) {
          return "#0B6AB0";
        }
        if (data.current) {
          return "#15BE53";
        }

        return "inherit";
      })(),
    };
  },
};
const { Option } = components;

export const CustomOption = (props) => (
  <Option {...props}>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>{props.label}</div>
      {props.data.current && (
        <Chip label="Current" style={{ color: "#fff" }} size="small" />
      )}
    </div>
  </Option>
);

const Header = (props) => {
  const [FYOptions, setFYOptions] = useState([]);
  const [FYArr, setFYArr] = useState([]);
  const [selectedFy, setSelectedFy] = useState({});
const [displayName, setDisplayName] = useState('')
  const dispatch = useDispatch();

  const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
  const fy = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR);
  const financialYearConverter = (startDate, endDate) => {
    return `${new Date(startDate).getFullYear()}-${new Date(
      endDate
    ).toLocaleDateString("en", {
      year: "2-digit",
    })}`;
  };


  useEffect(() => {
    const displayName =JSON?.parse(localStorage.getItem("user"))?.displayName||'';
    setDisplayName(displayName);
  }, [])
  

  useLayoutEffect(() => {
    (async () => {
     
      const financialArr = await getFinancialYearId();

      const FYArr = financialArr;

      setFYArr(FYArr || []);
      const currentFinancialYearData = FYArr?.find((it) => {
        const currentTimeStamp = new Date().getTime();
        return it.from_date < currentTimeStamp && it.to_date > currentTimeStamp;
      });

      dispatch({ type: Actions.SET_FINANCIAL_YEAR_LIST, data: FYArr });
      const options = FYArr.map((FyData, i) => {
        const year = financialYearConverter(FyData.from_date, FyData.to_date);
        const fromTime = new Date(FyData.from_date).getTime();
        const toTime = new Date(FyData.to_date).getTime();
        const currentTime = Date.now();

        return {
          label: `F.Y. ${year}`,
          value: FyData.external_id,
          current:
            currentTime >= fromTime && currentTime < toTime ? true : false,
        };
      });
      dispatch({ type: actions.SET_F_Y_ARR, data: options });
      setFYOptions(options);
      const currentFYId = {
        financialYearId: currentFinancialYearData?.external_id,
        financialYear: `F.Y. ${financialYearConverter(
          currentFinancialYearData?.from_date,
          currentFinancialYearData?.to_date
        )}`,
      };

      const currentFY = `F.Y. ${financialYearConverter(
        currentFinancialYearData?.from_date,
        currentFinancialYearData?.to_date
      )}`;
      fy === null &&
        localStorage.setItem(
          LOCAL_DB_KEYS.FINANCIAL_YEAR_ID,
          currentFinancialYearData?.external_id
        );
      fy === null &&
        localStorage.setItem(
          LOCAL_DB_KEYS.FINANCIAL_YEAR,
          `${financialYearConverter(
            currentFinancialYearData?.from_date,
            currentFinancialYearData?.to_date
          )}`
        );

      fy === null
        ? dispatch({ type: actions.SET_F_Y, data: currentFYId })
        : dispatch({
            type: actions.SET_F_Y,
            data: { financialYear: fy, financialYearId: fyId },
          });

      fy === null
        ? setSelectedFy({
            label: currentFY,
            value: currentFYId,
          })
        : setSelectedFy({
            label: `F.Y. ${fy}`,
            value: fyId,
          });
    })();
  }, [fy, fyId]);

  const handleDropDownChange = (input) => {
    const find = FYArr.find((data) => {
      return data.external_id === input.value;
    });

    dispatch({
      type: actions.SET_F_Y,
      data: {
        financialYear: financialYearConverter(find.from_date, find.to_date),
        financialYearId: find.external_id,
      },
    });

    localStorage.setItem("FINANCIAL_YEAR_ID", find.external_id);
    localStorage.setItem(
      "FINANCIAL_YEAR",
      financialYearConverter(find.from_date, find.to_date)
    );
    window.location.reload();
  };
const getUserName=()=>{
  const name= displayName&&
  displayName.charAt(0)
     .toUpperCase() +
     displayName.slice(1);
     return name;
}
  return (
    <div className="header">
      <Link to="#" className="prosperr">
        PROSPERR
      </Link>

      {props.loggedIn === true ? (
        <div className="button" style={{ gap: "12px" }}>
          <p className="useremail">
            Welcome,{" "}
            {getUserName()}
          </p>
          <div
            style={{
              width: "180px",
            }}
          >
            {selectedFy.value && (
              <Select
                options={FYOptions}
                isSearchable={false}
                value={selectedFy}
                styles={colourStyles}
                components={{ Option: CustomOption }}
                onChange={(value) => {
                  handleDropDownChange(value);
                }}
              />
            )}{" "}
          </div>
          <Link
            to="/welcome"
            className="signup button"
            onClick={props.handleLogout}
          >
            LOGOUT
          </Link>
        </div>
      ) : (
        <div className="button">
          <Link to="/" className="login" onClick={props.handleLogin}>
            LOGIN
          </Link>
          <Link to="/signup" className="signup" onClick={props.handleSignup}>
            SIGNUP
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;
