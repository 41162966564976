import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledSuccessMessage = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 104px;
  box-sizing: border-box;
  background-color: #fff;
  .success-icon {
    height: ${dim._scale(100)};
    width: ${dim._scale(100)};
  }

  .submitted-success {
    text-align: center;
    padding: ${dim._12px};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    margin: ${dim._12px} 0;
  }
`;
