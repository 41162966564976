import styled from "styled-components";
import { dim } from "../../styles/theme";

export const TextInputStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 0 0 0;
    padding: 0;
    box-sizing: border-box;

    input {
        width: 100%;
        height: ${dim._32px};
        min-width: 112.0546875px;
        margin: 0;
        padding: 4px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        box-sizing: border-box;
    }

    input[type="text"] {
        color: #212121;
    }

    input:focus {
        outline: none;
    }

    input::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: ${dim._12px};
        line-height: ${dim._24px};
    }
`;
