import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const ConversationStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${dim._16px};
  flex-grow: 1;
  overflow-y: scroll;
  position: relative;
  height: ${(props) => (props.scroll ? "100%" : props.screenHeight)};
  overflow-y: auto;
  background: #fff;
  box-sizing: border-box;

  .refranceForChatMsg {
    position: absolute;
    bottom: -${({ scrollHeight }) => (scrollHeight > 0 ? scrollHeight + "px" : 0 + "px")};
    left: 0;
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
  }

  .date > p {
    padding: ${dim._8px} ${dim._14px};
    color: #fff;
    font-size: ${dim._10px};
    font-style: normal;
    font-weight: 600;
    line-height: ${dim._14px};
    text-transform: capitalize;
    border-radius: ${dim._6px};
    background: #b4b4b4;
    box-shadow: 0px 0.7px 2.1px 0px rgba(16, 24, 40, 0.15);
  }
`;
