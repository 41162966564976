import React from "react";
import "./GoogleBtn.css";
import GoogleIcon from "./GoogleIcon";
const GoogleBtn = ({onClick}) => {
  return (
    <div
      className="gsi-material-button"
      onClick={() => {
        onClick();
      }}
    >
      <div className="gsi-material-button-state"></div>
      <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
          <GoogleIcon />
        </div>
        <span className="gsi-material-button-contents">
          Continue with Google
        </span>
        <span style={{display: "none"}}>Continue with Google</span>
      </div>
    </div>
  );
};

export default GoogleBtn;
