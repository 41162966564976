import styled from "styled-components";
import { dim } from "../../../../../../styles/theme";
import { Card } from "@mui/material";

export const StyledNoteViewer = styled.div`

.note-display {
  padding: ${dim._scale(20)};
  background-color: #f9f9f9;
  border-radius: ${dim._scale(8)};
  box-shadow: 0px ${dim._scale(4)} ${dim._scale(8)} rgba(0, 0, 0, 0.1);
  max-width: ${dim._scale(900)};
  margin: 0 auto;
}
.note-header{
    text-align: center;
    margin-top: ${dim._scale(10)};
}
.slider{
    margin-bottom: ${dim._scale(30)};
    padding: ${dim._scale(6)};
}

.note-display .MuiGrid-container {
  margin-bottom: ${dim._scale(20)};
}

.note-display .MuiCard-root {
  border-radius: ${dim._scale(8)};
  transition: transform 0.2s, box-shadow 0.2s;
}

.note-display .MuiCard-root:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.note-display .MuiCardMedia-root {
  border-radius: 8px 8px 0 0;
}

.note-display .MuiCardContent-root {
  padding: ${dim._scale(16)};
  background-color: #fff;
}

.note-display .MuiTypography-root {
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.note-display .note-content {
  background-color: #ffffff;
  padding: ${dim._scale(20)};
  border-radius: ${dim._scale(8)};
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
  font-size: ${dim._scale(16)};
  color: #444;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.note-display .MuiDialogContent-root {
  padding: ${dim._scale(20)};
  background-color: #fafafa;
}

.note-display .MuiDialogTitle-root {
  background-color: #f5f5f5;
  padding: ${dim._scale(16)};
  font-size: ${dim._scale(18)};
  font-weight: 500;
  text-align: center;
  position: relative;
}

.note-display .MuiIconButton-root {
  position: absolute;
  right: ${dim._scale(16)};
  top: ${dim._scale(16)};
  color: #999;
}

.note-display img {
  max-width: 100%;
  height: auto;
  border-radius: ${dim._scale(8)};
}

.slick-prev {
  z-index: 1;
  left: -30px; 
}

.slick-prev::before {
  content: '←'; 
  font-size: 40px;
  color: #C8A1E0;
}


.slick-next {
 
  border-radius: 50%;
  width: ${dim._scale(40)};
  height: ${dim._scale(40)};
  z-index: 1;
  right: -30px;
}

.slick-next::before {
  content: '→'; 
  font-size:${dim._scale(40)};
  color: #C8A1E0;
}


.slick-prev, .slick-next {
  margin: 0 ${dim._scale(10)};
}

.author-details{
  display: flex;
  justify-content: space-between;
}


@media (max-width: 600px) {
  .note-display {
    padding: 10px;
  }

  .note-display .MuiCard-root {
    margin-bottom: 10px;
  }

  .note-display .MuiCardContent-root {
    padding: 12px;
  }

  .note-display .note-content {
    padding: 15px;
    font-size: 14px;
  }

  .note-display .MuiDialogContent-root {
    padding: 15px;
  }

  .note-display .MuiDialogTitle-root {
    padding: 12px;
    font-size: 16px;
  }
}
`

 export const StyledCarouselCard = styled(Card)`
  border-radius: ${dim._scale(12)};
  width: 98%;
  height: ${dim._scale(320)};
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  .MuiCardMedia-root {
    border-radius: 12px 12px 0 0;
    height: ${dim._scale(140)};
  }

  .MuiCardContent-root {
    padding: ${dim._scale(12)};
    text-align: center;
    background-color: #fff;
  }

  .MuiTypography-root {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #333;
  }
`; 