import styled from "styled-components";
import { dim } from "../../styles/theme";

const SelectDropdownInputStyle = styled.select`
    width: 100%;
    height: 32px;
    min-width: ${dim._scale(105)};
    margin: 0;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #cccccc;
    box-sizing: border-box;

    & > option {
        font-size: 12px;
    }

    &:focus {
        outline: none;
    }
`;

export default SelectDropdownInputStyle;
