import React from "react";
import "./LoaderPage.css";

const LoaderPage = () => {
  return (
    <div className="row row-eq-height">
      <div className="frame">
        <div className="center">
          <div className="line line-1"></div>
          <div className="line line-2"></div>
          <div className="line line-3"></div>
          <div className="line line-4"></div>
          <div className="line line-5"></div>
          <div className="line line-6"></div>
          <div className="line line-7"></div>
          <div className="line line-8"></div>
          <div className="line line-9"></div>
          <div className="line line-10"></div>
        </div>
      </div>
    </div>
  );
};

export default LoaderPage;
