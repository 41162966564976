import React, { useRef, useEffect, useState } from "react";
import { ConversationStyled } from "./ConversationStyled";
import { useDispatch, useSelector } from "react-redux";
import SuperSaverDiscussionBookService from "../../../../Services/SuperSaverClientChat";
import { actions } from "../../store/action";
import { useIdleTimer } from "react-idle-timer";
import { useParams } from "react-router";
import SentMessage from "../SentMessage";
import { timeStampDateConverter } from "../../../../utils/currentTime";
import ReceivedMessage from "../ReceivedMessage/index";
import { getDayWiseTimeDifference } from "../../../../utils/GetTimeDfference";
import { getDateAndTime } from "../../../../utils/timeUtil";

const Conversation = ({ attachment, advisorId, userId }) => {
  const params = useParams();
  const selectedAdvisorId = params["id"];
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);
  const msg = useSelector((state) => state.chat.sessionMsg);
  const [height, setHeight] = useState(0);
  const [elapsed, setElapsed] = useState(0);
  const [lastEventTime, setLastEventTime] = useState(0);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const notification = useSelector((state) => state.chat.notification);
  let date = null;

  useEffect(() => {
    setHeight(
      messageContainerRef.current && messageContainerRef.current.scrollHeight
    );
  }, [msg]);

  useEffect(() => {
    messageContainerRef.current.scrollTo({
      top: height,
      behavior: "smooth",
    });
  }, [height]);

  /* mark as done */
  const onAction = (event) => {
    if (selectedAdvisorId) {
      return;
    }
    if (
      messageContainerRef.current.contains(event.target) &&
      elapsed - lastEventTime > 600
    ) {
      setLastEventTime(elapsed);
      if (
        notification &&
        notification?.unread_sessions > 0 &&
        notification?.unread_messages !== undefined
      ) {
        // check if sessions have unread messages
        const position = notification?.unread_messages?.findIndex((item) => {
          return item?.session_id === sessionId;
        });
        if (position >= 0) {
          markAsRead();
        }
      }
    }
  };

  const reloadNotification = async () => {
    const details =
      await SuperSaverDiscussionBookService.getSessionNotification({
        userId: userId,
        receiverId: advisorId,
      });
    dispatch({
      type: actions.UPDATE_NOTIFICATION,
      data: details,
    });
  };

  const markAsRead = async () => {
    await SuperSaverDiscussionBookService.markMessageAsRead({
      requestId: advisorId,
      sessionId: sessionId,
      isRead: true,
    });
    await reloadNotification();
  };

  const { getElapsedTime } = useIdleTimer({
    onAction,
    timeout: 10000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsed(getElapsedTime());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <ConversationStyled
      attachment={attachment?.length && attachment?.length > 0 ? true : false}
      ref={messageContainerRef}
      scroll={msg.length > 5 ? true : false}
    >
      <>
        {msg
          .sort((a, b) => a?.date_time - b?.date_time)
          .map((data, i) => {
            let newDate = date !== getDateAndTime(data?.date_time);
            if (newDate) {
              date = getDateAndTime(data?.date_time);
            }
            if (data?.sender_id === advisorId) {
              return (
                <>
                  {newDate && (
                    <div className="date">
                      <p>{getDayWiseTimeDifference(data?.date_time)}</p>
                    </div>
                  )}
                  <SentMessage
                    message={data?.message?.message}
                    time={timeStampDateConverter(data?.date_time)}
                    attachments={data?.message?.attachments}
                  />
                </>
              );
            } else {
              return (
                <>
                  {newDate && (
                    <div className="date">
                      <p>{getDayWiseTimeDifference(data?.date_time)}</p>
                    </div>
                  )}
                  <ReceivedMessage data={data} />
                </>
              );
            }
          })}
      </>

      <div className="refranceForChatMsg" ref={messagesEndRef}></div>
    </ConversationStyled>
  );
};

export default Conversation;
