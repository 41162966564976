import React, { useEffect, useState } from "react";
import { StyledIncompleteAndCompleted } from "./IncompleteAndCompleted.styled";
import Tabs from "../../../../commonComponents/Tabs";
import InprogressSessionList from "../InprogressSessionList";
import CompletedSessionList from "../CompletedSessionList";

const IncompleteAndCompleted = ({ consultationData }) => {
  const [selectedTab, setSelectedTab] = useState(
    consultationData?.incoming_calls?.length > 0
      ? callTypeData[0].id
      : callTypeData[1].id
  );

  useEffect(() => {
    setSelectedTab(
      consultationData?.incoming_calls?.length > 0
        ? callTypeData[0].id
        : callTypeData[1].id
    );
  }, [consultationData]);

  const handleSelectTab = (event) => {
    const { id } = event.target;
    setSelectedTab(+id);
  };
  const switchScreens = (selectedTab) => {
    if (selectedTab === 1) {
      return <InprogressSessionList consultationData={consultationData} />;
    }
    if (selectedTab === 2) {
      return <CompletedSessionList consultationData={consultationData} />;
    }
  };

  return (
    <StyledIncompleteAndCompleted>
      <div className="selectorModel">
        <Tabs
          tabsData={callTypeData}
          selectedTab={selectedTab}
          handleSelectTab={handleSelectTab}
        />
      </div>
      {switchScreens(selectedTab)}
    </StyledIncompleteAndCompleted>
  );
};

export default IncompleteAndCompleted;
export const callTypeData = [
  {
    id: 1,
    name: "mom pending",
  },
  {
    id: 2,
    name: "Completed",
  },
];
