import styled from "styled-components";
import { dim } from "../../styles/theme";
// import { dim } from '../../styles/theme';

export const StyledTaskCompleted = styled.div`
  display: flex;
  align-items: center;
  gap: ${dim._8px};
  padding-right: ${dim._8px};
`;
