import React, {useEffect, useState} from "react";
import {StyledWidgetWrapper} from "../../screens/ConsultationView/ConsultationView.styled";
import Widget from "../../../../commonComponents/Widget";

export const WidgetWrapper = ({
  widgetArray,
  currentWidget,
  handleWidgetSelection,
  widgetCount,
}) => {
  const [widgetData, setWidgetData] = useState([]);
  useEffect(() => {
    const widgetData = widgetArray?.map((widgetDataInfo) => {
      const dataCount = widgetCount[widgetDataInfo.key];

      return {...widgetDataInfo, dataCount};
    });
    setWidgetData(widgetData);
  }, [widgetArray, widgetCount]);

  return (
    <StyledWidgetWrapper>
      {widgetData?.map((widgetInfo, index) => (
        <Widget
          key={index}
          currentWidget={currentWidget}
          handleWidgetSelection={handleWidgetSelection}
          {...widgetInfo}
        />
      ))}
    </StyledWidgetWrapper>
  );
};
