import styled from 'styled-components';
import { dim } from '../../../../../styles/theme';

export const StyledMobileAuthorOrVerified = styled.div`
	margin-right: ${dim._4px};
	p {
		margin: ${dim._0px};
	}
	.expert {
		display: flex;
		align-items: center;
		gap: ${dim._8px};
	}
	.op {
		font-size: ${dim._14px};
		font-weight: 500;
		line-height: ${dim._20px};
		text-align: left;
		color: #282c3f;
	}
	.staff {
		display: flex;
		align-items: center;
		gap: ${dim._8px};
	}
`;
