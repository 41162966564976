export const checkPreviousFinancialYear = (
	financialYearId,
	FinancialYearList
) => {
	const currentTimeStamp = new Date().getTime();
	const financialYearData = FinancialYearList.filter((it) => {
		return it.external_id === financialYearId;
	})[0];
	if (financialYearData?.length === 0) {
		throw new Error('Financial year is not correct');
	}

	const toTime = new Date(financialYearData?.to_date)?.getTime();
	return currentTimeStamp >= toTime;
};