import React from "react";
import { IncomeTypeStyle } from "./IncomeType.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { convertNumberIntoINR } from "../../../../../utils/ConvertNumnerIntoIndianCurrency";
import OutlinedButton from "../../../../../commonComponents/OutlinedButton";

const IncomeType = ({
    verified,
    approvedAmount,
    nonApprovedAmount,
    monthStatus,
    verifiedMonths,
    selectedCompanyId,
    user,
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <IncomeTypeStyle verified={verified}>
            {verified ? (
                <img
                    src={
                        process.env.PUBLIC_URL +
                        "/assets/SuperSaver/Income/Icons/verified.svg"
                    }
                    alt="verified"
                    className="status"
                />
            ) : (
                <img
                    src={
                        process.env.PUBLIC_URL +
                        "/assets/SuperSaver/Income/Icons/non_verified.svg"
                    }
                    alt="verified"
                    className="status"
                />
            )}
            <h3>
                {verified ? "Total Verified Income" : "Non verified Income"}
            </h3>
            <>
                <p className="endDate">
                    {verifiedMonths && verifiedMonths.length !== 0
                        ? verifiedMonths &&
                          verifiedMonths.map(
                              (data, i) =>
                                  data.month.slice(0, 3) +
                                  (verifiedMonths.length - 1 !== i ? ", " : "")
                          )
                        : "No Available Months"}
                </p>
                <p className="amount">
                    {verified
                        ? (approvedAmount &&
                              convertNumberIntoINR(parseInt(approvedAmount))) ||
                          convertNumberIntoINR(0)
                        : (nonApprovedAmount &&
                              convertNumberIntoINR(
                                  parseInt(nonApprovedAmount)
                              )) ||
                          convertNumberIntoINR(0)}
                </p>
                <p className="verificationStatus">
                    {verified
                        ? selectedCompanyId === undefined
                            ? "Add your company or Select one company"
                            : approvedAmount <= 0 && nonApprovedAmount > 0
                            ? "There is no verified salary"
                            : monthStatus > 0
                            ? monthStatus + " months salary verified "
                            : "No verified monthly salary"
                        : selectedCompanyId === undefined
                        ? "Add your company or Select one company"
                        : approvedAmount > 0 && nonApprovedAmount <= 0
                        ? "No unverified salary"
                        : monthStatus > 0
                        ? monthStatus + " months salary need verification"
                        : "No unverified monthly salary"}
                </p>
                {verified ? (
                    <OutlinedButton
                        disabled={selectedCompanyId === undefined}
                        onClick={() => {
                            selectedCompanyId &&
                                navigate(
                                    `/${
                                        pathname.split("/")[1]
                                    }/incomeView/salary-breakdown`,
                                    {
                                        state: {
                                            external_id: selectedCompanyId,
                                            isNew: false,
                                            verified: verified,
                                            user: user,
                                        },
                                    }
                                );
                        }}
                    >
                        View Salary Slip
                    </OutlinedButton>
                ) : (
                    <OutlinedButton
                        disabled={selectedCompanyId === undefined}
                        onClick={() =>
                            selectedCompanyId &&
                            navigate(
                                `/${
                                    pathname.split("/")[1]
                                }/incomeView/salary-breakdown`,
                                {
                                    state: {
                                        external_id: selectedCompanyId,
                                        isNew: false,
                                        verified: verified,
                                        user: user,
                                    },
                                }
                            )
                        }
                    >
                        Verify Salary Slip
                    </OutlinedButton>
                )}
            </>
        </IncomeTypeStyle>
    );
};

export default IncomeType;
