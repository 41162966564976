export const timeStampDateConverter = (timestamp) => {
  var a = new Date(timestamp);
  var hour = a.getHours();
  var min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
  // var sec = a.getSeconds();
  var suffix = hour >= 12 ? "PM" : "AM";
  var hours = ((hour + 11) % 12) + 1;
  var time = `${hours}:${min} ${suffix}`;
  return time;
};
