import { actions } from "./LayoutAction";

const initialState = {
  F_Y_ARR: [],
  F_Y_OBJ: {},
};

const LayoutReducer = (state = initialState, payload = {}) => {
  switch (payload.type) {
    case actions.SET_F_Y_ARR:
      return { ...state, F_Y_ARR: payload.data };
    case actions.SET_F_Y:
      return { ...state, F_Y_OBJ: payload.data };

    default:
      return state;
  }
};

export { LayoutReducer };
