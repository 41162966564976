import { USER_ROLE } from "../../constants/userRole";
import { checkPreviousFinancialYear } from "../../utils/FinancialYearUtils";

const dataToRedirect = (item, fYId,financialYearList, userRole) => { 

  if(userRole === USER_ROLE.ACCOUNTANT){
    return [ {
      pathName: `superSaverUserProfile`,
      name: "User Profile",
      id: 1,
      state: { ...item, registered: true },
      active: [`/superSaverUserProfile`],
    }];
  }

  if(userRole===USER_ROLE.ADVISOR){
    return [
      {
        pathName: `userProfileForAdvisor`,
        name: "User Profile",
        id: 1,
        state: { ...item, registered: true },
        active: [`/userProfileForAdvisor`],
        inactive: [
          `/userProfileForAdvisor/incomeView`,
          `/userProfileForAdvisor/deductionView`,
          `/userProfileForAdvisor/exemption-page`,
          `/userProfileForAdvisor/pending-advisorDoc`,
          `/userProfileForAdvisor/notes`
        ]
      },
      {
        pathName: `userProfileForAdvisor/incomeView`,
        name: "View Income",
        id: 1,
        state: { ...item, registered: true },
        active: [
          `/userProfileForAdvisor/incomeView`,
          `/userProfileForAdvisor/incomeView/capital-gain`,
          `/userProfileForAdvisor/incomeView/salary`,
          `/userProfileForAdvisor/incomeView/business-and-profession`,
          `/userProfileForAdvisor/incomeView/digital-asset`,
          `/userProfileForAdvisor/incomeView/income-from-house-rent`
        ],
      },
      {
        pathName: `userProfileForAdvisor/deductionView`,
        name: "View Deduction",
        id: 1,
        state: { ...item, registered: true },
        active: [`/userProfileForAdvisor/deductionView`],
      },
      {
        pathName: `userProfileForAdvisor/exemption-page`,
        name: "Exemption Page",
        id: 1,
        state: { ...item, registered: true },
        active: [`/userProfileForAdvisor/exemption-page`],
      },
      {
        pathName: `userProfileForAdvisor/pending-advisorDoc`,
        name: "Advisor Document",
        id: 1,
        state: { ...item, registered: true },
        active: [`/userProfileForAdvisor/pending-advisorDoc`],
      },
      {
        pathName: `userProfile-for-advisor/onboarding-form`,
        name: "Onboarding Details",
        id: 1,
        state: { ...item, registered: true },
        active: [`/userProfile-for-advisor/onboarding-form`],
      },
      {
        pathName: `userProfile-for-advisor/client-chat`,
        name: "Client Chat",
        id: 1,
        state: { ...item, registered: true },
        active: [`/userProfile-for-advisor/client-chat`],
      },
      {
        pathName: `userProfile-for-advisor/user-activity`,
        name: "User Activity",
        id: 1,
        state: { ...item, registered: true },
        active: [`/userProfile-for-advisor/user-activity`],
      },
      ...( checkPreviousFinancialYear(fYId, financialYearList) ? [{
        pathName: `userProfile-for-advisor/itr-filing`,
        active: [`/userProfile-for-advisor/itr-filing`],
        id: 1,
        state: { ...item, registered: true },
        name: "ITR Filing",
      }] : []),
      {
        pathName: "userProfileForAdvisor/notes",
        name: "Notes",
        id: 12,
        state: { ...item, registered: true },
        active: [`/userProfileForAdvisor/notes`],
      },
      {
        pathName: `consultation-experience`,
        name: "Consultation Experience",
        id: 1,
        state: { ...item, registered: true },
        active: [`/consultation-experience`],
      },
    ]
  }

  
    return [
      {
        pathName: `superSaverUserProfile`,
        name: "User Profile",
        id: 1,
        state: { ...item, registered: true },
        active: [`/superSaverUserProfile`],
        inactive: [
          `/superSaverUserProfile/deductionView`,
          `/superSaverUserProfile/incomeView`,
          `/superSaverUserProfile/pending-advisorDoc`,
          `/superSaverUserProfile/exemption-page`,
        ]
      },
      {
        pathName: `superSaverUserProfile/deductionView`,
        name: "    View Deduction",
        id: 1,
        state: { ...item, registered: true },
        active: [`/superSaverUserProfile/deductionView`],
      },
      {
        pathName: `superSaverUserProfile/incomeView`,
        active: [
          `/superSaverUserProfile/incomeView`,
          `/superSaverUserProfile/incomeView/capital-gain`,
          `/superSaverUserProfile/incomeView/salary`,
          `/superSaverUserProfile/incomeView/business-and-profession`,
          `/superSaverUserProfile/incomeView/digital-asset`,
          `/superSaverUserProfile/incomeView/income-from-house-rent`
        ],
        id: 1,
        state: { ...item, registered: true },
        name: "View Income",
      },
      {
        pathName: `superSaverUserProfile/pending-advisorDoc`,
        active: [`/superSaverUserProfile/pending-advisorDoc`],
        id: 1,
        state: { ...item, registered: true },
        name: "  Advisor Document",
      },
      {
        pathName: `superSaverUserProfile/exemption-page`,
        active: [`/superSaverUserProfile/exemption-page`],
        id: 1,
        state: { ...item, registered: true },
        name: "    Exemption",
      },
      {
        pathName: `super-saver-user-profile/onboarding-form`,
        active: [`/super-saver-user-profile/onboarding-form`],
        id: 1,
        state: { ...item, registered: true },
        name: "  Onboarding Details",
      },
      {
        pathName: `manage-appointment`,
        active: [`/manage-appointment`],
        id: 1,
        state: { ...item, registered: true },
        name: " Manage Appointment",
      },
      ...( checkPreviousFinancialYear(fYId, financialYearList) ? [{
        pathName: `super-saver-user-profile/itr-filing`,
        active: [`/super-saver-user-profile/itr-filing`],
        id: 1,
        state: { ...item, registered: true },
        name: "ITR Filing",
      }] : []),
      {
        pathName: `consultation-experience`,
        active: [`/consultation-experience`],
        id: 1,
        state: { ...item, registered: true },
        name: "Consultation Experience",
      },
    ]; 
};

export { dataToRedirect };
