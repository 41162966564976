import React, { useContext, useEffect, useState } from "react";
import AdvisorProfile from "../../commonComponents/AdvisorProfile/AdvisorProfile";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import AdvisorData from "../../Context/Advisor/AdvisorData";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import BASE_URL from "../../Services/API_ENDPOINT";
import { getAuth } from "firebase/auth";
import { AddSlotsStyle } from "./AddSlots.styled";

const Addslot = ({ user }) => {
  const [dataArray, setDataArray] = useState([]);
  const [getToken, setgetToken] = useState(true);
  const a = useContext(AdvisorContext);

  useEffect(() => {
    a.setUsername(JSON.parse(localStorage.getItem("user")).displayName);
  });

  useEffect(() => {
    const url = `${BASE_URL}advisors/v1/all/cms`;
    if (getToken) {
      if (getAuth().currentUser !== null) {
        getAuth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            const fetchData = async () => {
              const response = await fetch(url, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `${idToken}`,
                  Source: "cms",
                },
              });
              var data = await response.json();

              if (response.status === 200 || response.status === 204) {
              } else {
                setTimeout(() => {}, 1000);
              }
              setDataArray(
                data.map((item) => {
                  return {
                    ...item,
                    isActive: false,
                    isSlotsActive: false,
                    isCreateSlotActive: false,
                    isAppointmentsActive: false,
                    isFeeActive: false,
                  };
                })
              );
            };
            setgetToken(false);
            fetchData();
          });
      }
    }
  });

  return (
    <AdvisorData>
      <AddSlotsStyle>
        {dataArray.map((d, i) => (
          <div className="" key={i}>
            <CardAddSlot>
              {/* {a.Token !== false ?  */}
              <AdvisorProfile
                setData={setDataArray}
                arrayData={dataArray}
                data={d}
                Id={i}
                token={a.Token !== false ? a.Token : null}
              />
              {/* : null} */}
            </CardAddSlot>
          </div>
        ))}
      </AddSlotsStyle>
    </AdvisorData>
  );
};

export default Addslot;
