import React from "react";
import {useDispatch} from "react-redux";
import {CONSULTATION_STATUS} from "../../../ConsultationExperience/constant";
import {LOCAL_DB_KEYS} from "../../../../constants/localStorage";
import {ConsultationMomService} from "../../../../Services/ConsultationMomService";
import toast from "react-hot-toast";
import {actions} from "../../../ConsultationExperience/store/ConsultationAction";
import {removeSpecialCharacter} from "../../../ConsultationExperience/utils";
import {
  changeDateFormate,
  isTimestampMoreThanRequiredHoursOld,
} from "../../../../utils/timeUtil";
import CallIcon from "../../../../Assets/icons/CallIcon";
import CallNotAttended from "../../../../Assets/icons/CallNotAttended";
import MomIcon from "../../../../Assets/icons/MomIcon";
import {
  StyledActionCta,
  StyledClientEmailName,
} from "../../screens/ConsultationView/ConsultationView.styled";
import Email from "../../../../Assets/icons/Email";
import {Tooltip} from "@mui/material";
import {timeStampDateConverter} from "../../../../utils/superSaverUser";
import {dim} from "../../../../styles/theme";

export const ActionCell = (props) => {
  const {
    date,
    meeting_url,
    actionType,
    currentWidget,
    reqObj,
    getConsultationViewData,
    call_booking_id,
    user_id,
    type,
    start_time,
    name,
    call_summary_id,
  } = props;

  const dispatch = useDispatch();
  const handleEventStatus = async ({data, status}) => {
    const getMessage = (status) => {
      switch (status) {
        case CONSULTATION_STATUS.COMPLETED:
          return "Call Status Confirmation: Are you sure you have attended the call";
        case CONSULTATION_STATUS.NOT_ATTENDED:
          return "Call Status Confirmation: Are you sure you have not attended the call";
      }
    };

    const confirm = window.confirm(getMessage(status));

    if (confirm) {
      const type = localStorage.getItem(LOCAL_DB_KEYS.USER);
      const user = JSON.parse(type);
      const UserID = user?.user_roll_id;
      const obj = {
        status,
        call_booking_id: data?.call_booking_id,
        requester_id: UserID,
      };
      try {
        const res = await ConsultationMomService.updateConsultationStatus(
          UserID,
          obj
        );
        await getConsultationViewData(
          currentWidget,
          reqObj?.client_name,
          reqObj?.start_date,
          reqObj?.end_date,
          reqObj?.action_type
        );
        toast.success("Call Status updated successfully!");
      } catch (error) {
        toast.error("Something went wrong!");
      }
    }
  };

  const handleSendMom = () => {
    const params = new URLSearchParams();
    localStorage.setItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID, call_summary_id);
    dispatch({
      type: actions.SET_CONSULTATION_ID,
      data: call_summary_id,
    });
    dispatch({
      type: actions.SET_MOM_DATA,
      data: null,
    });
    const url = "/consultation-experience/consultation-form";
    params.set("call_booking_id", call_booking_id); // Use set() instead of append()
    params.set("user_id", user_id); // Use set() instead of append()
    params.set("call_title", removeSpecialCharacter(type)); // Use set() instead of append()
    params.set("start_time", start_time); // Use set() instead of append()
    params.set("user_name", name); // Use set() instead of append()

    const queryString = params.toString();

    window.open(`${url}?${queryString}`, "_self");
  };

  const Join = () => {
    const disableMessage =
      "The button will be enabled before 2 hours of call time";
    const disabled = isTimestampMoreThanRequiredHoursOld(date);
    return (
      <CTAButton
        Icon={CallIcon}
        handleClick={() => {
          !disabled && window.open(`${meeting_url}`, "_blank");
        }}
        title={disabled ? disableMessage : "Join Call"}
        disabled={disabled}
      >
        Join Call
      </CTAButton>
    );
  };

  const CallAttended = () => {
    return (
      <CTAButton
        handleClick={() =>
          handleEventStatus({
            data: props,
            status: CONSULTATION_STATUS.COMPLETED,
          })
        }
        Icon={CallIcon}
        title={"Mark as attended "}
      >
        Call
      </CTAButton>
    );
  };

  const CallNotAttendedCta = ({color}) => {
    return (
      <CTAButton
        handleClick={() =>
          handleEventStatus({
            data: props,
            status: CONSULTATION_STATUS.NOT_ATTENDED,
          })
        }
        Icon={CallNotAttended}
        color={color}
        title={"Mark as not attended "}
      >
        Call
      </CTAButton>
    );
  };

  const MomCta = ({color}) => {
    return (
      <CTAButton
        handleClick={() => {
          handleSendMom();
        }}
        Icon={MomIcon}
        color={color}
        title={"Send MOM"}
      >
        Mom
      </CTAButton>
    );
  };

  const getRequiredCTA = (actionType, currentWidget) => {
    if (currentWidget === "PENDING_ACTIONS") {
      switch (actionType) {
        case "UNMARKED_CALL":
          return (
            <>
              <CallAttended /> <CallNotAttendedCta color={"#EA4336"} />
            </>
          );
        case "MOM_PENDING":
          return <MomCta />;
      }
    }
    if (currentWidget === "UPCOMING_CALLS") {
      return <Join />;
    }
  };

  return (
    <StyledClientEmailName>
      {getRequiredCTA(actionType, currentWidget)}
    </StyledClientEmailName>
  );
};

export const ClientEmailName = ({name, email}) => {
  return (
    <StyledClientEmailName>
      <Tooltip title={email} arrow placement="left-start">
        <span>
          <Email />
        </span>
      </Tooltip>
      {name}
    </StyledClientEmailName>
  );
};

export const CallDate = ({date, isMom = false}) => {
  return (
    <StyledClientEmailName>
      {date ? (
        changeDateFormate(date)
      ) : (
        <span
          style={{
            width: "60%",
            textAlign: "center",
          }}
        >
          -
        </span>
      )}{" "}
      {!isMom && `| ${timeStampDateConverter(date)}`}
    </StyledClientEmailName>
  );
};

const CTAButton = ({
  children,
  handleClick,
  Icon,
  color,
  title,
  disabled = false,
}) => {
  return (
    <Tooltip title={title || "title"} arrow placement="left-start">
      <StyledActionCta
        onClick={() => {
          handleClick();
        }}
        color={color || "#15BE53"}
        disabled={disabled}
      >
        <Icon
          style={{
            width: dim._16px,
            height: dim._16px,
          }}
          color={color || "#15BE53"}
        />
        {children}
      </StyledActionCta>
    </Tooltip>
  );
};
