import React from "react";
import { downloadByOriginalName } from "../../../../utils/downloadByOriginalName";

const DownloadAttachmentComponent = ({
  children,
  link,
  name,
  index,
  style,
}) => {
  return (
    <span
      style={style}
      onClick={() => {
        downloadByOriginalName(link, name);
      }}
      id={index}
    >
      {children}
    </span>
  );
};

export default DownloadAttachmentComponent;
