import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Chart4 from './chart4';
import { ChartStyle, ChartWrapper } from './Chart.styled';
import { data } from './data';
import RadioButton from '../../../../../commonComponents/RadioButton';
import { dim } from '../../../../../styles/theme';

const Chart = ({ activeRadioButton, handleRadioBtn, allData }) => {
	const ref = useRef(null);
	const [chartData, setChartData] = useState(data);
	const [chartWidth, setChartWidth] = useState(640);

	useLayoutEffect(() => {
		setChartWidth(ref.current ? ref.current.offsetWidth - 200 : 640);
	}, [ref.current]);

	useEffect(() => {
		const chartData =
			allData &&
			allData.map((arr) => {
				let modifiedArray = { month: arr.month };
				if (arr.breakdown.is_approved) {
					return {
						...modifiedArray,
						verified: arr.breakdown.approved_salary,
						unverified: 0
					};
				} else {
					return {
						...modifiedArray,
						verified: 0,
						unverified: arr.breakdown.system_generated_amount
					};
				}
			});

		chartData ? setChartData(chartData) : setChartData(data);
	}, [allData, activeRadioButton]);

	return (
		<ChartWrapper ref={ref}>
			{(chartData === data ||
				chartData === null ||
				chartData === undefined ||
				chartData.length < 1) && (
				<ChartStyle
					className=''
					style={{
						width: 'auto',
						height: 'auto',
						zIndex: '12',
						position: 'absolute',
						padding: dim._24px,
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)'
					}}
				>
					<div className='message-container'>
						<img
							src={
								process.env.PUBLIC_URL +
								'/assets/SuperSaver/Income/Icons/non_verified.svg'
							}
							alt='verified'
							className='alert-icon'
						/>
						<p className='message'>
							{' '}
							Add Income to activate the chart overview
						</p>
					</div>
				</ChartStyle>
			)}
			<ChartStyle
				isReadableFalse={
					chartData === data ||
					chartData === null ||
					chartData === undefined ||
					chartData.length < 1
				}
			>
				<div className='radioBtns'>
					<RadioButton
						id='3'
						label='All'
						activeRadioButton={activeRadioButton}
						handleRadioBtn={handleRadioBtn}
					/>
					<RadioButton
						id='1'
						label='Verified'
						activeRadioButton={activeRadioButton}
						handleRadioBtn={handleRadioBtn}
					/>
					<RadioButton
						id='2'
						label='Unverified'
						activeRadioButton={activeRadioButton}
						handleRadioBtn={handleRadioBtn}
					/>
				</div>

				<div className='chart-wrapper-outside'>
					<Chart4
						activeRadioButton={activeRadioButton}
						allData={allData}
						barChartData={chartData}
						isReadableFalse={
							chartData === data ||
							chartData === null ||
							chartData === undefined ||
							chartData.length < 1
						}
						chartWidth={chartWidth}
					/>
				</div>
			</ChartStyle>
		</ChartWrapper>
	);
};

export default Chart;
