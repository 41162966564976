import React from "react";
import { StyledRadioQuestionModel } from "./RadioQuestionModel.styled";
import RadioComponent from "../RadioComponent";

const RadioQuestionModel = (props) => {
  return (
    <StyledRadioQuestionModel>
      <RadioComponent {...props} />
    </StyledRadioQuestionModel>
  );
};

export default RadioQuestionModel;
