import React from "react";
import { StyledConsultationExtension } from "./ConsultationExtension.styled";
import RadioComponent from "../RadioComponent";
import { useState } from "react";
import { StyledConsultationConfirmation } from "../ConsultationConfirmation/ConsultationConfirmation.styled";

const ConsultationExtension = (radioComponentProps) => {
  return (
    <StyledConsultationConfirmation>
      <RadioComponent {...radioComponentProps} />
    </StyledConsultationConfirmation>
  );
};

export default ConsultationExtension;
