import styled from "styled-components";

export const RequestedDocWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  padding: 16px 24px;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;

  h2 {
    width: 25%;
    color: #212f45;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p {
    width: 75%;
    color: #323232;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
