import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const PrettoSlider = styled(Slider)({
    color: "#15BE53",
    height: 6,
    margin: 0,
    fontFamily: "Open Sans",
    "& .MuiSlider-markLabel": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontHeight: "400",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#425466",
    },
    "& .MuiSlider-thumb": {
        height: 19,
        width: 19,
        backgroundColor: "#15BE53",
        outline: "0.06px solid #15BE53",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
        border: "5px solid #fff",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
        "&:before": {
            display: "none",
        },
    },
});

export default PrettoSlider;
