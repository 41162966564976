import React, { useEffect, useState } from "react";
import { data } from "./constants";
import { Wrapper } from "./index.styled";
import { SliderComponent } from "../StandAloneSlider";
import Select, { components } from "react-select";
import Tooltip from "@mui/material/Tooltip";
import SuperSaverIncomeService from "../../../../../../Services/SuperSaverIncomeService";
import CustomModal from "../../../../../../commonComponents/CustomModal";
import Button from "../../../../../../commonComponents/Button";
import FormErrormsgHandler from "../../../../../../commonComponents/FormErrormsgHandler";
import IosSwitch from "../../../../../../commonComponents/IosSwitch";
import { LOCAL_DB_KEYS } from "../../../../../../constants/localStorage";
import { dim } from "../../../../../../styles/theme";

const Basic = ({
    details = {
        companyName: "",
        logo: "",
        isCurrentCompany: false,
        startDate: "",
        endDate: "",
        ctc: "",
        companyDetails: "",
    },
    error = false,
    onboarding = false,
    options,
    readonly = false,
    isNew = false,
    currentCompany = [],
    onChange = () => {},
    setCurrentCompany = () => {},
    other = "",
    setOther = () => {},
    setOptions,
}) => {
    const [_details, setDetails] = useState(details);
    useEffect(() => {
        onChange(_details);
    }, [_details]);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [companyPresentedAlready, setCompanyPresentedAlready] = useState({
        isPresented: false,
        companyName: "",
    });

    const formatDateForInput = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // pad with leading zero if necessary
        const day = date.getDate().toString().padStart(2, "0"); // pad with leading zero if necessary
        return `${year}-${month}-${day}`;
    };

    const dateToTimestamp = (dateString) => {
        const [year, month, day] = dateString.split("-");
        const timestamp = new Date(year, month - 1, day).getTime();
        return timestamp;
    };

    const financialYear = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR);

    const getFinancialYearEndDate = (date) => {
        const year = "20" + financialYear.slice(2, 4);
        const financialYearStart = new Date(year, 3, 1); // April 1 of current year

        const nextYear = financialYearStart.getFullYear() + 1;
        const financialYearEnd = new Date(nextYear, 2, 31); // March 31 of next year
        if (date < financialYearStart) {
            financialYearEnd.setFullYear(year); // if date is before April 1, use previous financial year
        }
        return financialYearEnd.getTime();
    };

    const getFinancialStartDate = (date) => {
        date = new Date(date);

        const year = "20" + financialYear.slice(2, 4);
        const financialStartMonth = 3;
        let financialStartYear;

        financialStartYear = year;

        const financialStartDate = new Date(
            financialStartYear,
            financialStartMonth,
            1
        );
        return financialStartDate.getTime();
    };

    const { Option } = components;

    const CustomOption = (props) => {
        return (
            <Option {...props}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>{props.label}</div>
                    {props.data &&
                        props.data.company_profile &&
                        props.data.company_profile.logo && (
                            <img
                                style={{
                                    height: ` ${dim._22px}`,
                                    width: `${dim._22px}`,
                                }}
                                src={props.data.company_profile.logo}
                                alt=""
                            />
                        )}
                </div>
            </Option>
        );
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            backgroundColor: "#f1f5f7",
            boxSizing: "border-box",
            width: "100%",
            height: "35px",
            background: "#f1f5f7",
            borderRadius: "4px",
            border: "none",
            outlineColor: "#898989",
        }),
    };

    const filterOptions = (option, searchText) => {
        const { label, data } = option;
        const searchString = `${label} ${data.name}`.toLowerCase();
        return searchString.indexOf(searchText.toLowerCase()) > -1;
    };

    const handleDropDownChange = (data) => {
        setDetails({
            ..._details,
            companyName: data.name ? data.name : data.label,
            companyDetails: data,
            logo: data.company_profile ? data.company_profile.logo : "",
        });
    };

    useEffect(() => {
        if (_details.isCurrentCompany && currentCompany.length > 0) {
            setShowConfirmDialog(true);
        }
    }, [_details.isCurrentCompany]);

    return (
        <Wrapper onboarding={onboarding}>
            {showConfirmDialog && (
                <CustomModal bg="#00000099">
                    <div className="modal">
                        <h3 className="heading">
                            Are you currently working in this company
                        </h3>
                        <p style={{ marginTop: 8 }}>
                            There is already one current company. Do you want to
                            close &nbsp;
                            {currentCompany[0]
                                ? currentCompany[0].company_details.name
                                : "that company"}
                        </p>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 24,
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onButtonClick={() => {
                                    setDetails({
                                        ..._details,
                                        isCurrentCompany: false,
                                        endDate: "",
                                    });

                                    setShowConfirmDialog(false);
                                }}
                                buttonName="Cancel"
                            ></Button>
                        </div>
                    </div>
                </CustomModal>
            )}

            <div className="company-details">
                <span className="logo-name">
                    {_details.companyName.length > 0 &&
                        _details.companyName !== "OTHER" && (
                            <img
                                className="logo"
                                src={_details.logo}
                                onError={() => {
                                    setDetails({
                                        ..._details,
                                        logo:
                                            process.env.PUBLIC_URL +
                                            "/assets/images/logo_placeholder.png",
                                    });
                                }}
                                alt={details.companyName}
                            ></img>
                        )}
                    <div
                        style={{
                            position: "relative",
                            width: dim._scale(250),
                        }}
                    >
                        {!isNew ? (
                            <h3 className="name">{_details.companyName}</h3>
                        ) : (
                            <p className="label">Select Company</p>
                        )}

                        {isNew && (
                            <>
                                <Select
                                    options={options}
                                    isSearchable={true}
                                    placeholder="Search or Select..."
                                    value={_details.companyDetails}
                                    components={{ Option: CustomOption }}
                                    styles={customStyles}
                                    filterOption={filterOptions}
                                    onChange={(value) => {
                                        handleDropDownChange(value);
                                    }}
                                />
                                {error && _details.companyName.length < 1 && (
                                    <FormErrormsgHandler className="error">
                                        please Select Company
                                    </FormErrormsgHandler>
                                )}
                            </>
                        )}

                        {!isNew && (
                            <p className="sub-heading">
                                {data.companySubheading}
                            </p>
                        )}
                    </div>
                </span>
                <Tooltip
                    title={readonly ? "Can't Edit current company" : ""}
                    placement="right-end"
                    arrow
                >
                    <div className="current-status">
                        <p className="sub-heading">
                            {data.currentCompanyStatusLabel}
                        </p>

                        <IosSwitch
                            value={_details.isCurrentCompany}
                            disabled={readonly}
                            onChange={() => {
                                if (!_details.isCurrentCompany) {
                                    setDetails({
                                        ..._details,
                                        isCurrentCompany:
                                            !_details.isCurrentCompany,
                                        endDate: dateToTimestamp(
                                            formatDateForInput(
                                                getFinancialYearEndDate(
                                                    new Date()
                                                )
                                            )
                                        ),
                                    });
                                } else {
                                    setDetails({
                                        ..._details,
                                        isCurrentCompany:
                                            !_details.isCurrentCompany,
                                    });
                                }
                            }}
                        />
                    </div>
                </Tooltip>
            </div>
            <div className="dates">
                <Tooltip title={readonly ? "Can't Edit Start Date" : ""} arrow>
                    <div
                        className="start-date"
                        style={{
                            position: "relative",
                        }}
                    >
                        <p className="label">Start Date</p>

                        <input
                            disabled={readonly}
                            className="date-input"
                            type="date"
                            min={formatDateForInput(
                                getFinancialStartDate(new Date())
                            )}
                            max={
                                _details.endDate > 0
                                    ? formatDateForInput(_details.endDate)
                                    : formatDateForInput(
                                          getFinancialYearEndDate(new Date())
                                      )
                            }
                            value={formatDateForInput(
                                _details.startDate === 0
                                    ? getFinancialStartDate(new Date())
                                    : _details.startDate
                            )}
                            onChange={(e) => {
                                if (
                                    _details.endDate.length <= 0 ||
                                    dateToTimestamp(e.target.value) <
                                        _details.endDate
                                ) {
                                    setDetails({
                                        ..._details,
                                        startDate: dateToTimestamp(
                                            e.target.value
                                        ),
                                    });
                                }
                            }}
                        />

                        {error && _details.startDate.length < 1 && (
                            <FormErrormsgHandler className="error">
                                Please Start Date
                            </FormErrormsgHandler>
                        )}
                    </div>
                </Tooltip>
                <Tooltip title={readonly ? "Can't Edit End Date" : ""} arrow>
                    <div
                        className="end-date"
                        style={{
                            position: "relative",
                        }}
                    >
                        <p className="label">End Date</p>
                        <input
                            disabled={readonly}
                            className="date-input"
                            type="date"
                            min={
                                _details.startDate > 0
                                    ? formatDateForInput(_details.startDate)
                                    : formatDateForInput(
                                          getFinancialStartDate(new Date())
                                      )
                            }
                            max={formatDateForInput(
                                getFinancialYearEndDate(new Date())
                            )}
                            value={formatDateForInput(_details.endDate)}
                            onChange={(e) => {
                                if (
                                    dateToTimestamp(e.target.value) >
                                    _details.startDate
                                ) {
                                    setDetails({
                                        ..._details,
                                        endDate: dateToTimestamp(
                                            e.target.value
                                        ),
                                    });
                                }
                            }}
                        />
                        {error && _details.endDate.length < 1 && (
                            <FormErrormsgHandler className="error">
                                Please End Date
                            </FormErrormsgHandler>
                        )}
                    </div>
                </Tooltip>
            </div>
            <Tooltip
                title={readonly ? "Can't Edit Your Current Annual CTC" : ""}
                arrow
            >
                <span
                    style={{
                        position: "relative",
                    }}
                >
                    <SliderComponent
                        disabled={readonly}
                        value={_details.ctc}
                        style={{ marginTop: 24 }}
                        onChange={(v) => setDetails({ ..._details, ctc: v })}
                        label="Your current annual CTC"
                    />
                    {error && _details.ctc.length < 1 && (
                        <FormErrormsgHandler className="error">
                            Please write your CTC
                        </FormErrormsgHandler>
                    )}
                </span>
            </Tooltip>
        </Wrapper>
    );
};

export default Basic;
