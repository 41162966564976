import styled from "styled-components";

export const PopupTargetTaxStyled = styled.div`
  background: #fff;
  margin: auto;
  height: auto;
  border-radius: 8px;
  width: 433px;
  position: relative;
  .popContent {
    font-size: 20px;
    margin: 0 60px 0 60px;
    color: #808080;
    text-align: center;
    padding-top: 60px;
  }
  .popBox {
    background-color: #fafafa;
    border-radius: 0 0px 8px 8px;
  }
  .popButton {
    width: 50%;
    height: 50px;
    border-radius: 0 0 0 8px;
    text-align: center;

    padding-top: 14px;
    font-size: 18px;
    box-sizing: border-box;
  }
  .yes {
    color: #fff;
    background: ${(props) => (!props.disabled ? "#0b6ab0" : "#91c2e6")};
    cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  }
  .no {
    border-radius: 0 0 8px 0;
    border-top: 1px solid #d1d1d1;
    cursor: pointer;
  }
  .closePop {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .input {
    display: flex;
    flex-direction: column;
    margin: 0 60px 20px;
  }
  .standard-basic {
    margin: 10px 5px;
  }
`;
