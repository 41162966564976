import React, { useEffect, useState } from 'react';
import { Wrapper } from './index.styled';
import { CiDiscount1 } from 'react-icons/ci';
import { theme } from '../../styles/theme';
import { CustomizedButton } from '../../commonComponents/Button';
import TextButton from '../../commonComponents/TextButton';
import { LoaderIcon } from 'react-hot-toast';
const STATUS = {
	OPEN: 'OPEN',
	CLOSED: 'CLOSED'
};

const GSTIN = '29AAMCP7486Q1Z2';

const PaymentBreakdown = ({ 
	planUpAndDegradationInfo,
	 initiate, error, savingAmount=()=>{} }) => {
	const [stage] = useState(STATUS.CLOSED);
	const [loading, setLoading] = useState(false);
const [details, setDetails] = useState()
	const renderActions = (status) => {
		if (status === STATUS.OPEN) {
			return (
				<CustomizedButton
					style={{ fontSize: 12 }}
					onClick={() => {
						setLoading(true);
						initiate();
					}}
				>
					{loading ? (
						<LoaderIcon style={{ height: 20, width: 20 }} />
					) : (
						'Confirm and Pay ₹' +
						details?.transaction_generator?.transaction_amount / 100
					)}
				</CustomizedButton>
			);
		} else if (status === STATUS.CLOSED) {
			return (
				<div className='open-with-condition'>
					{details?.renew_plan_discount &&
						details?.renew_plan_discount?.renew_plan_discount > 0 && (
							<div className='message-open-with-cond open-with-cond discount-box '>
								<CiDiscount1 style={{ fontSize: '32px', fontWeight: 800 }} />
								<p className='closed-text discount-box'>
									Unlock Savings: Renew Today and Get{' '}
									{details?.renew_plan_discount?.renew_plan_discount} Amount Off !
								</p>
							</div>
						)}
					<CustomizedButton
						style={{ fontSize: 12 }}
						onClick={() => {
							setLoading(true);
							initiate();
						}}
					>
						{loading ? (
							<LoaderIcon style={{ height: 20, width: 20 }} />
						) : (
							'Confirm and Pay ₹' +
							details?.transaction_generator?.transaction_amount / 100
						)}
					</CustomizedButton>
				</div>
			);
		}
	};
const getModifiedData=(obj)=>{
	const {payment,upgraded_plan_name,plan_actual_amount,plan_discounted_amount,current_plan_amount}=obj

const payment_details={
	plan_actual_amount:plan_actual_amount,
	plan_discounted_amount: plan_discounted_amount,
	due_amount:payment?.due_amount,
	discounted_amount:payment?.discounted_amount,
	amount_without_gst: payment?.payable_amount_without_gst,
	deducted_coins: payment?.total_consumed_coins,
	plan_remaining_amount: current_plan_amount,
	plan_name:upgraded_plan_name,
	product: "SUPER_SAVER",
	status: "PAYMENT_PENDING",
	coin_amount:payment?.total_consumed_coins_amount
}
const gst_details=  payment?.gst_details
const transaction_generator= {
	transaction_id: "9685ba7b7eb94dd3990df980affd2115",
	initiator_user_id: "cf228c61-1414-4aa1-8d92-925cd611f056",
	transaction_amount: payment?.total_payable_amount,
	currency_type: "RUPEES"
}
return {
	payment_details,gst_details,transaction_generator
}
}


useEffect(() => {
	setDetails(getModifiedData(planUpAndDegradationInfo) )
}, [planUpAndDegradationInfo])


	return (
		<Wrapper>
			<h3 className='heading'>Payment Details</h3>
			<hr className='hr'></hr>
			<div className='flex-row'>
				<h3 className='text-b'>
					{details?.payment_details?.product.replaceAll('_', ' ')}
				</h3>
				<h3 className='text-b'>{details?.payment_details?.plan_name}</h3>
			</div>
			<hr className='hr' style={{ marginTop: 16 }}></hr>
			<div className='flex-row'>
				<h3 className='text-b'>Plan Price</h3>
				<h3 className='text-b'>
					₹{details?.payment_details?.plan_actual_amount}
				</h3>
			</div>
			<div className='flex-row'>
				<h3 className='text-b'>Due amount</h3>
				<h3 className='text-b'>
					₹{details?.payment_details?.due_amount}
				</h3>
			</div>
	{	details?.payment_details?.plan_actual_amount -
						details?.payment_details?.plan_discounted_amount>0&&	<div className='flex-row' style={{ color: 'green' }}>
				<h3 className='text-b'>Prosperr Discount</h3>
				<h3 className='text-b'>
					- ₹
					{details?.payment_details?.plan_actual_amount -
						details?.payment_details?.plan_discounted_amount}
				</h3>
			</div>}
	
		{	details?.payment_details?.plan_remaining_amount>0&&<div className='flex-row' style={{ color: 'green' }}>
				<h3 className='text-b'>Previous Plan Paid Amount</h3>
				<h3 className='text-b'>
					- ₹
					{details?.payment_details?.plan_remaining_amount}
				</h3>
			</div>}
	
			{
				details?.renew_plan_discount?.renew_plan_discount > 0 && (
					<div className='flex-row' style={{ color: theme.color.blue_80 }}>
						<h3 className='text-b'>Prosperr Renewal Discount</h3>
						<h3 className='text-b'>
							- ₹{details?.renew_plan_discount?.renew_plan_discount}
						</h3>
					</div>
				)}

			{details?.payment_details?.deducted_coins > 0 && (
				<div className='flex-row' style={{ color: 'green' }}>
					<h3 className='text-b'>Coin Used</h3>
					<h3 className='text-b'>
						- ₹{details?.payment_details?.deducted_coins}
					</h3>
				</div>
			)}
			<hr className='hr' style={{ marginTop: 16 }}></hr>
			<div className='flex-row'>
				<h3 className='text-b'>Gross Total</h3>
				<h3 className='text-b'>
					₹{details?.payment_details?.amount_without_gst}
				</h3>
			</div>
			<div className='flex-row'>
				<h3 className='text-b'>GST @{details?.gst_details?.gst_rate}%</h3>
				<h3 className='text-b'>₹{details?.gst_details?.gst_amount}</h3>
			</div>
			<hr className='hr' style={{ marginTop: 16 }}></hr>
			<div className='flex-row'>
				<h3 className='text-b'>Service Providers GSTIN</h3>
				<h3 className='text-b'>{GSTIN}</h3>
			</div>
			<hr className='hr' style={{ marginTop: 16 }}></hr>
			<div className='flex-row'>
				<h3 className='text-b'>To Pay</h3>
				<h3 className='text-b'>
					₹{details?.transaction_generator?.transaction_amount }
				</h3>
			</div>

	
			<div className='bottom-sheet'>
				<img
					className='images-safe'
					src={process.env.PUBLIC_URL + '/assets/images/security/safe.png'}
					alt=''
				></img>
			</div>
		</Wrapper>
	);
};

export default PaymentBreakdown;
