import React from "react";
import {StyledWidget} from "./Widget.styled";
import {theme} from "../../styles/theme";

const Widget = ({
  Icon,
  title,
  dataCount,
  themeActive,
  themeInActive,
  handleWidgetSelection,
  currentWidget,
  type,
}) => {
  const currentSelected = currentWidget === type;
  return (
    <StyledWidget
      onClick={() => {
        handleWidgetSelection(type);
      }}
      themeActive={themeActive}
      themeInActive={themeInActive}
      currentSelected={currentSelected}
    >
      <Icon color={!currentSelected && theme.color.grey_130} />

      <div className="text">
        {title} ({dataCount})
      </div>

      {currentSelected && <div className="squareTilted"></div>}
    </StyledWidget>
  );
};

export default Widget;
