import React, { useState } from 'react';
import { BackdropStyled } from './Backdrop.styled';

const Backdrop = ({ children, toggleBackdrop=()=>{} }) => {
	return (
		<BackdropStyled
			showBackdrop
			onClick={(event) => {
				toggleBackdrop();
			}}
		>
			<div
				className='contentBackdrop'
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				{children}
			</div>
		</BackdropStyled>
	);
};

export default Backdrop;
