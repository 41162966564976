import axios from "axios";
import { getAuth } from "firebase/auth";
import { toast } from "react-hot-toast";
import { PreSignedUrl } from "../Services/preSignedUrlUploadApi";
import { LOCAL_DB_KEYS } from "../constants/localStorage";

const PreSignedUrlUpload = (
  fileName,
  imageFile,
  userId,
  token,
  ReturnValueSetter
) => {
  const preSignedUrl = PreSignedUrl.uploadPreSignedUrl(
    token,
    userId,
    fileName
  ).then((presignedUrl) => {
    return axios
      .put(presignedUrl, imageFile)
      .then(() => {
        ReturnValueSetter();
      })
      .catch((err) => {
        toast.error(err);
      });
  });
  return preSignedUrl;
};
const preSignedUrlDownload = async (fileName, userId) => {
  const token = localStorage.getItem(LOCAL_DB_KEYS.AUTH_TOKEN_LS);
  const preSignedUrl = PreSignedUrl.downloadPreSignedUrl(
    token,
    userId,
    fileName
  );
  toast.promise(preSignedUrl, {
    loading: "Document Downloading...",
    success: "Document Downloaded",
    error: "Oops something went wrong",
  });

  const d = await preSignedUrl;
  return d;
};

export { PreSignedUrlUpload, preSignedUrlDownload };
