import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "./container/Layout";
import "./App.css";
import AdvisorData from "./Context/Advisor/AdvisorData";
import Routing from "./Routes/Index";
import toast, { Toaster } from "react-hot-toast";
import { getMetaData } from "./Services/metaData";
import { useDispatch } from "react-redux";
import { Actions } from "./Routes/store/action";
import { useSalaryComponent } from "./container/PayslipManualReview/Hooks/useSalaryComponent";
import { USER_DATA , USER_TYPE } from "./utils/localStorageKeys";

const App = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [type, setType] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const handleLogoutButton = () => {
    setLoggedIn(false);
    setType("");
    localStorage.clear();
  };

  const handleGetMetaData = async () => {
    try {
      const metaData = await getMetaData();
      const data = await metaData?.data;
      dispatch({ type: Actions.SET_META_DATA, data });
    } catch (error) {
      toast.error(error ? error : error.message);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("loggedIn");

    if (isLoggedIn) {
      const foundUser = JSON.parse(isLoggedIn);
      setLoggedIn(foundUser);
    } else {
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const userData = JSON.parse(localStorage.getItem(USER_DATA));
      const type = JSON.parse(localStorage.getItem(USER_TYPE));
      setUser(userData);

      setType({...type,});
    }
  }, [loggedIn]);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  useLayoutEffect(() => {
    handleGetMetaData();
  }, [loggedIn]);

  useSalaryComponent();
  return (
    <AdvisorData>
      <Layout
        loggedIn={loggedIn}
        handleLogoutButton={handleLogoutButton}
        userEmail={userEmail}
        user={user}
        userType={type?.user_roll}

      >
        <Routing
          loggedIn={loggedIn}
          setUser={setUserEmail}
          setLoggedIn={handleLogin}
          handleLogoutButton={handleLogoutButton}
          setType={setType}
          userType={type?.user_roll}
        />
        <Toaster
          toastOptions={{
            style: {
              fontSize: "0.875rem",
              alignItems: "center",
              backgroundColor: "#202124",
              border: "none",
              borderRadius: "4px",
              color: "#fff",
              display: "flex",
              flexWrap: "wrap",
              fontWeight: "400",
              padding: "8px 24px",
              boxSizing: "border-box",
            },
          }}
          position="bottom-left"
        />
      </Layout>
    </AdvisorData>
  );
};

export default App;
