import React,{useState ,useEffect} from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { CMSApis } from "../../../Services/cmsServices";
import { setContext } from "redux-saga/effects";


const Cards = ({category = "INDIVIDUAL_TAXATION" , setPublishType ,setNextToken ,updated,setPageNumber}) => {
    const [categoryData, setCategoryData] = useState([]);
    const [selected,setSelected] = useState('all');
   

    useEffect( ()=>{
     (async()=>{   let res = await CMSApis.getArticleCategories();

        res.map(item => {
            if(item.category_name === category){
                setCategoryData(item)
            }
        })})()
    },[updated,category])
   

    const handleClick = (item) =>{
    
        if(item === 'all'){
            setPublishType(null);
        }else{
          setPublishType(item);
        }
        setNextToken(0);
        setPageNumber(0);
        setSelected(item)
    }
    
    const AllCards = [
        {
          id: 'all',
          title: 'All Articles',
          icon: `${process.env.PUBLIC_URL }` + '/assets/icons/all-articles-icon.svg',
          status: 'all',
          count:(categoryData?.number_of_published_articles + categoryData?.number_of_drafted_article + categoryData?.number_of_unpublished_article),
          color:'black'
        },
        {
          id: 'published',
          title: 'Published',
          icon: `${process.env.PUBLIC_URL }` + '/assets/icons/published.svg',
          status: "PUBLISHED",
          count:categoryData?.number_of_published_articles,
          color:'#27AE60'
          
        },
        {
          id: 'draft',
          title: 'Draft',
          icon: `${process.env.PUBLIC_URL }` + '/assets/icons/draft.svg',
          status: "DRAFTED",
          count:categoryData?.number_of_drafted_article,
          color:'#764FDB'

        },
        {
          id: 'unpublished',
          title: 'Unpublished',
          icon: `${process.env.PUBLIC_URL }` + '/assets/icons/unpublished.svg',
          status: "UNPUBLISHED",
          count:categoryData?.number_of_unpublished_article,
          color:'#D16B31'
        }
      ]
      
 
  return <CardContainer>
    {AllCards.map(item => (
      <Card
        key={item?.title}
        replace
        selected={selected === item?.status}
        onClick={()=> handleClick(item.status)}
        >
            
        <IconWrapper>
          <IconImage src={item?.icon} />
        </IconWrapper>
        <CountWrapper>
          <Count >{item?.count || 0}</Count>
          <StatusText color={item.color} >{item?.title}</StatusText>
        </CountWrapper>
      </Card>
    ))
    }
  </CardContainer>
}

export default Cards

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap:20px;
  padding-bottom: 24px;

`;
const Card = styled('div')`
  display: flex;
  flex:1;
  flex-direction: row;
  align-items: center;
  gap:14px;
  padding:20px 10px;
  border-radius:8px;
  text-decoration:none;
  background-color: white;
  box-sizing: border-box;
  cursor:pointer;

${({ selected }) => selected ? `
  border: 1px solid  #05387E;
` : `
  border: 1px solid #CDD5DF;
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  
  border-radius:50%;
  padding:8px;
`;
const IconImage = styled.img`
  height:38px;
  width:38px;
  object-fit: contain;
`;
const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Count = styled.span`
  color:#121926;
  font-size:26px;
  font-weight:600;
  line-height:32px;
`;
const StatusText = styled.span`
  color: ${({ color }) => color};
  font-weight:400;
  font-size:14px;
`;
