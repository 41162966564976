import { combineReducers } from "redux";
import ChatReducer from "../container/ClientChat/store/reducer";
import { globalNotificationReducer } from "../container/ActiveChats/store/reducer";
import { UserReducer } from "../container/AssignedUsers/store/reducer";
import { TaxReducer } from "../container/AssignedUsers/components/UserProfileForAdvisor/store/reducer";
import { LayoutReducer } from "../container/Layout/Store/LayoutReducer";
import { ConsultationReducer } from "../container/ConsultationExperience/store/ConsultationReducer";
import { cmsReducer } from "../Routes/store/reducer";
import { PayslipManualReviewReducer } from "../container/PayslipManualReview/store/reducer";
import { ITRDocumentsReducer } from "../container/ITRDocuments/store/reducer";

const rootReducer = combineReducers({
  chat: ChatReducer,
  globalNotifications: globalNotificationReducer,
  users: UserReducer,
  TaxReducer,
  LayoutReducer,
  ConsultationReducer,
  cmsReducer,
  PayslipManualReviewReducer,
  ITRDocumentsReducer,
});

export { rootReducer };
