import { convertToPascalCase } from "./textUtil";

const modifyMetaData = (arr) => {
  return arr?.map((item) => ({
    label: convertToPascalCase(item.replaceAll("_", " ")),
    type: item,
    value: item,
    isSelected: false,
  }));
};

export { modifyMetaData };
