import styled from 'styled-components';
import { dim } from '../../../../styles/theme';

export const SentMessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
	box-sizing: border-box;

	& > span {
		margin: 0 0 ${dim._8px} 0;
		color: #212f45;
		font-size: ${dim._12px};
		font-style: normal;
		font-weight: 400;
		line-height: ${dim._18px};
		opacity: 0.5;
	}

	.message_content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 70%;
		gap: ${dim._12px};
		padding: ${dim._12px};
		border-radius: ${dim._4px};
		background: #efeefd;
		box-sizing: border-box;
	}

	.message_content > p {
		width: auto;
		height: auto;
		margin: 0;
		color: #212f45;
		font-size: ${dim._12px};
		font-style: normal;
		font-weight: 400;
		line-height: ${dim._18px};
	}

	.chat_attachment {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: ${dim._2px};
		width: 100%;
		box-sizing: border-box;
	}
`;
