import styled from "styled-components";

export const StyledSuperClientProfile = styled.div`
    box-sizing: border-box;

    .ClientHed {
        font-size: 14px;
        font-weight: 500;
        color: #222;
        margin: 5px;
    }

    .ClientWrap {
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        border-radius: 4px;
        padding: 20px;
    }
    .clientBox {
        width: 250px;
        padding-bottom: 5px;
        border-bottom: 2px solid #808080;
        margin: 20px 34px;
        box-sizing: border-box;
        position: relative;
    }

    .copyIcon {
        /* margin-left: 80px; */
        display: none;
        position: absolute;
        right: 0;
    }
    .clipboard{
        width: 15px;
        cursor: pointer;
    }
`;
export const ClientDetails = styled.div`
    color: #7f7f7f;
    min-height: 20px;
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    &:hover .copyIcon {
        display: ${(props) => (props.activate ? "block" : "0.4")};
    }
    .select {
        width: 180px;
    }
    .data {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .invitedUser {
        opacity: ${(props) => (props.activate ? "1" : "0.4")};
        cursor: ${(props) => (props.activate ? "pointer" : "not-allowed")};
        &:hover {
            background: ${(props) => (props.activate ? " #d8e7f2" : "#0e6ab0")};
            color: ${(props) => (props.activate ? " #0e6ab0" : "#ffffff")};
        }
    }

    .cb {
        display: none;
    }

    .button {
        display: block;
        width: 100px;
        height: 50px;
        border-radius: 25px;
        text-align: center;
        line-height: 46px;
        text-transform: uppercase;
        font-weight: 600;
        transform-style: preserve-3d;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .cb:checked ~ .button {
        transform: rotateX(180deg);
        box-shadow: 4px -8px 12px 0 rgba(0, 0, 0, 0.1);
    }

.dueAmount {
  padding: 20px;

}

.dueAmountWrapper {
  display: flex;
  flex-direction: column;
  width:300px;
  box-sizing: border-box;
}

.dueAmountData {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.dueAmountData p {
  margin: 0;
  color: #333; 
  font-weight: 500;
}

.totalDue {
  font-weight: 700; 
  color: #d9534f; 
  border-bottom: none; 
}
`;

export const Front = styled.span`
    box-sizing: border-box;
    position: absolute;
    display: block;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #34495e;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 25px;
    backface-visibility: hidden;
    transform: rotateX(0);
`;
export const Back = styled(Front)`
    z-index: 1;
    color: #0e6ab0;
    background: #d8e7f2;
    border: 2px solid #fff;
    transform: rotateX(180deg);
`;
