import React, { useEffect, useState } from "react";
import IncomeTable from "../../Component/IncomeTable";
import { CryptoStyle } from "./Crypto.styled";
import { getSummarizedData, initializeDefaultData } from "./utils";
import { Loader2 } from "../../../../commonComponents/Loader";
import { timeStampDateNewFormat } from "../../../../utils/epochConverter";
import SuperSaverIncomeService from "../../../../Services/SuperSaverIncomeService";
import { useLocation } from "react-router";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";

const Crypto = ({ cryptoData }) => {
    const { state, pathname } = useLocation();
    const [data, setData] = useState(null);
    const [cryptoIncome, setCryptoIncome] = useState([]);
    const [finalCryptoIncome, setFinalCryptoIncome] = useState([]);
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    useEffect(() => {
        (async () => {
            const res = await SuperSaverIncomeService.getIncomeRevampData(
                "DIGITAL_ASSET",
                state.user_id,
                fyId
            );
            setData(res.data);
        })();
    }, [fyId]);

    useEffect(() => {
        data && setCryptoIncome(getSummarizedData(data.income_details));
    }, [data]);

    useEffect(() => {
        const res = initializeDefaultData(cryptoIncome);
        setFinalCryptoIncome([...res]);
    }, [cryptoIncome]);

    const tableTitle = {
        top_a: "Amount",
        top_b: "Selling Price",
        side_a: "Total Proceeds",
        side_b: "Total Amount",
    };

    return (
        <>
            {data == null ? (
                <div
                    style={{
                        width: "100%",
                        height: "60vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loader2></Loader2>
                </div>
            ) : (
                <CryptoStyle>
                    {finalCryptoIncome &&
                        finalCryptoIncome.map((it, i) => (
                            <IncomeTable
                                cryptoData={it}
                                tableTitle={tableTitle}
                                title={it.sub_type}
                                componentName={"DIGITAL_ASSET"}
                                path={`/${
                                    pathname.split("/")[1]
                                }/incomeView/digital-asset/detail`}
                                lastUpdatedOn={
                                    it.updated_at > it.created_at
                                        ? timeStampDateNewFormat(it.updated_at)
                                        : timeStampDateNewFormat(it.created_at)
                                }
                                col_a={it.amount_invested}
                                col_b={it.selling_amount}
                                total={it.selling_amount - it.amount_invested}
                                userId={state.user_id}
                            />
                        ))}
                </CryptoStyle>
            )}
        </>
    );
};
export default Crypto;
