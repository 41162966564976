import React from 'react'
import './welcome.css'

const Welcome = (props) => {
    return (
        <div className="welcome">
            <h1>Welcome to Prosperr CMS</h1>

            <h2>Please Login or Signup to continue..</h2>
        </div>
    )
}

export default Welcome
