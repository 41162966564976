import React from "react";
import { ReceivedMessageContainer } from "./ReceivedMessage.styled";
import { useSelector } from "react-redux";
import { objectEmptyValidator } from "../../../../utils/validate";
import { timeStampDateConverter } from "../../../../utils/currentTime";
import renderHTML from "../../../../utils/renderHTML";
import DocumentPreview from "../DocumentPreview";
import DownloadAttachmentComponent from "../DownloadAttachmentComponent";
import { FaUserCircle } from "react-icons/fa";
import { dim } from "../../../../styles/theme";

const ReceivedMessage = ({ advisorData, data }) => {
  const {
    label,
    date_time,
    sender_id,
    message: { message, attachments },
  } = data;
  const usersInSession = useSelector((state) => state.chat.usersInSession);

  return (
    <ReceivedMessageContainer>
      <div className="msg_info">
        <h3>{usersInSession[sender_id]?.first_name?.toLowerCase()}</h3>
        <span>| {timeStampDateConverter(date_time)}</span>
      </div>
      <div className="msg_detail">
        <FaUserCircle style={{ fontSize: dim._36px, color: "grey" }} />
        <div className="message_content">
          {attachments?.length > 0 && (
            <div className="chat_attachment">
              {attachments?.map((file, i) => {
                return (
                  <DownloadAttachmentComponent
                    link={file?.link}
                    name={file?.name}
                    index={i}
                    style={{ width: "100%" }}
                  >
                    <DocumentPreview index={i} data={file} />
                  </DownloadAttachmentComponent>
                );
              })}
            </div>
          )}
          {message && <p>{renderHTML(message, "", "")}</p>}
        </div>
      </div>
    </ReceivedMessageContainer>
  );
};

export default ReceivedMessage;
