import React, { useEffect, useState } from "react";
import { SalaryStyle } from "./Salary.styled";
import Chart from "./Chart/index";
import IncomeType from "./IncomeType";
import { monthlySalaryBreakdown } from "../../utils/monthlySalaryBreakdown";
import { Divider } from "@mui/material";

const months = [
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
    "JANUARY",
    "FEBRUARY",
    "MARCH",
];

const Salary = ({ selectedCompanyId, selectedCompanyData, user }) => {
    const [activeRadioButton, setActiveRadioButton] = useState("3");
    const [salaryBreakdown, setSalaryBreakdown] = useState();
    useEffect(() => {
        const salaryBreakdowns =
            selectedCompanyData &&
            selectedCompanyData.monthly_breakdown &&
            monthlySalaryBreakdown(selectedCompanyData.monthly_breakdown).sort(
                (a, b) => {
                    return months.indexOf(a.month) - months.indexOf(b.month);
                }
            );
        selectedCompanyData
            ? setSalaryBreakdown([...salaryBreakdowns])
            : setSalaryBreakdown();
    }, [selectedCompanyData]);

    const __handelRadioButton = (e, componentIndex) => {
        const { id } = e.target;
        setActiveRadioButton(id);
    };

    const approvedMonths =
        salaryBreakdown &&
        salaryBreakdown.filter((data) => data.breakdown.is_approved);
    const nonApprovedMonths =
        salaryBreakdown &&
        salaryBreakdown.filter((data) => !data.breakdown.is_approved);
    const totalApprovedSalary =
        approvedMonths &&
        approvedMonths.reduce(
            (acc, curr) => acc + curr.breakdown.approved_salary,
            0
        );

    const totalNonApprovedSalary =
        nonApprovedMonths &&
        nonApprovedMonths.reduce(
            (acc, curr) => acc + curr.breakdown.system_generated_amount,
            0
        );

    return (
        <SalaryStyle>
            <h2>
                {selectedCompanyData &&
                    selectedCompanyData.company_details.name.toLowerCase()}{" "}
                {selectedCompanyData &&
                    selectedCompanyData.is_current_company && (
                        <span>(Current Company)</span>
                    )}
            </h2>
            <div className="incomeStatus">
                <IncomeType
                    verified={true}
                    selectedCompanyId={selectedCompanyId}
                    approvedAmount={totalApprovedSalary}
                    nonApprovedAmount={totalNonApprovedSalary}
                    monthStatus={approvedMonths && approvedMonths.length}
                    verifiedMonths={approvedMonths}
                    user={user}
                />

                <IncomeType
                    verified={false}
                    selectedCompanyId={selectedCompanyId}
                    approvedAmount={totalApprovedSalary}
                    nonApprovedAmount={totalNonApprovedSalary}
                    monthStatus={nonApprovedMonths && nonApprovedMonths.length}
                    verifiedMonths={nonApprovedMonths}
                    user={user}
                />
            </div>
            <h2>Salary Overview</h2>
            <Chart
                allData={salaryBreakdown}
                activeRadioButton={activeRadioButton}
                handleRadioBtn={__handelRadioButton}
            />
        </SalaryStyle>
    );
};

export default Salary;
