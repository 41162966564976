import styled from "styled-components";

export const TableRowsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  background: #fff;
  box-sizing: border-box;

  .docs_item {
    display: flex;
    gap: 8px;
    width: 25%;
    display: flex;
    align-items: center;
  }

  .docs_item > span {
    color: #212f45;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .doc_detail span {
    font-size: 12px;
    cursor: pointer;
  }

  .doc_detail span:hover {
    text-decoration: underline;
  }

  .docs_item_btns > span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
