export const PlanDetails = [
	{
		category_id: 'ffea6705-f29c-48cd-9dc7-77ae3c906901',
		category_type: 'BASIC',
		name: 'Basic Plan',
		service_details: [
			{
				name: '3 video sessions with our Tax Advisor.',
				sequence: 3,
				service_id: 'b67c3580-8e4f-484c-8494-19b56d8313b8'
			},
			{
				name: 'Annual income is below ₹12 lakhs.',
				sequence: 4,
				service_id: 'b67c3580-8e4f-484f-8494-19b56d8313b8'
			},

			{
				name: '10X Savings Not Guaranteed.',
				sequence: 1,
				service_id: '79968253-231c-4f62-a25b-64b27222f61e'
			}
		],
		path: '/super-saver-plans/basic-plan'
	},
	{
		category_id: 'e9af575f-d5b6-4da9-8f3f-7a3ba5cc3494',
		category_type: 'ADVANCED',
		name: 'Advanced Plan',
		service_details: [
			{
				name: 'Unlimited video sessions with Advisor.',
				sequence: 2,
				service_id: 'b67c3580-8e4f-484c-8494-19b56d8313b8'
			},
			{
				name: 'Annual income is below ₹40 lakhs.',
				sequence: 3,
				service_id: 'b67c3580-8e4f-484c-8494-19b56d8313b8'
			},
			{
				name: 'Upto 10X Savings on Plan Value.',
				sequence: 1,
				service_id: '79968253-231c-4f62-a25b-64b27222f61e'
			}
		],
		path: '/super-saver-plans/advanced-plan'
	},
	{
		category_id: '8b28b208-60f4-423c-8d70-2764ebbd5938',
		category_type: 'PREMIUM',
		name: 'Premium Plan',
		service_details: [
			{
				name: 'Unlimited video sessions with Advisor.',
				sequence: 2,
				service_id: 'b67c3580-8e4f-484c-8494-19b56d8313b8'
			},
			{
				name: 'Annual income above 40 lakhs.',
				sequence: 3,
				service_id: 'b67c3580-8e4f-484c-8494-19b56d8313b8'
			},
			{
				name: 'Upto 10X Savings on Plan Value.',
				sequence: 1,
				service_id: '79968253-231c-4f62-a25b-64b27222f61e'
			}
		],
		path: '/super-saver-plans/premium-plan'
	},
	{
		category_id: 'ffea6705-f25c-48cd-9dc7-77ae3c806901',
		category_type: 'ELITE',
		name: 'Elite Plan',
		service_details: [
			{
				name: 'Salary range from 12 to 40 Lakhs.',
				sequence: 3,
				service_id: 'b67c3580-8e4f-484c-8494-19b56d8313b8'
			},
			{
				name: 'Unlimited video sessions with our CA ',
				sequence: 2,
				service_id: 'b67c3580-8e4f-484c-8494-19b56d8313b8'
			},
			{
				name: '10X Savings Guaranteed on Plan Value.',
				sequence: 1,
				service_id: '79968253-231c-4f62-a25b-64b27222f61e'
			}
		],
		path: '/super-saver-plans/elite-plan'
	}
];

export const apiObj={
    "user_id": "8906c183-05e5-4213-b2c0-78a22dba0d82",
    "upgraded_plan_category_id": "8b28b208-60f4-423c-8d70-2764ebbd5938",
    "upgraded_plan_name": "Premium Plan",
    "is_plan_upgraded": false,
    "payment": {
        "discounted_amount": 0.00,
        "total_consumed_coins": 0,
        "total_consumed_coins_amount": 0.0,
        "due_amount": 0.00,
        "gst_details": {
            "gst_rate": 18.0,
            "cgst_rate": 9.0,
            "sgst_rate": 9.0,
            "cgst_amount": 0.0,
            "sgst_amount": 0.0,
            "gst_amount": 33.0
        },
        "total_payable_amount": 1200.0,
        "plan_upgraded_amount": 2333,
        "payable_amount_without_gst": 400.0
    }
}