import styled from 'styled-components';
import { dim, theme } from '../../../../styles/theme';

export const StyledPreviousCallDetails = styled.div`
	padding: ${dim._16px} ${dim._24px};
	display: flex;
	flex-direction: column;
	gap: ${dim._8px};
	width: ${({ width }) => width};
	border-radius: ${dim._4px};
	border: ${dim._1px} solid ${theme.color.white_60};
	position: relative;
	background: ${theme.color.white_0};
	margin-top: ${({ marginTop }) => marginTop};
	box-sizing: border-box;

	img {
		width: ${dim._16px};
		height: ${dim._16px};
	}
	.upcomingTag {
		color: ${theme.color.white_0};
		font-size: ${dim._12px};
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		border-radius: ${dim._2px} ${dim._2px} ${dim._2px} 0;
		background: #4d62b9;
		padding: ${dim._4px} ${dim._8px};
		position: absolute;
		top: -${dim._16px};
		left: -${dim._8px};
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			top: ${dim._24px};
			left: 0px;
			border-top: ${dim._8px} solid #2b3259;
			border-left: ${dim._8px} solid transparent;
		}
	}
	.actions {
		display: flex;
		gap: ${dim._8px};
	}
	.firstLine {
		display: flex;
		justify-content: space-between;
		.actions {
			display: flex;
		}
	}
	.secondLine {
		display: flex;
		justify-content: space-between;
		color: ${theme.color.black_20};
		font-size: ${dim._12px};
		font-weight: 400;
		font-style: normal;
		line-height: normal;
		.callInfo {
			display: flex;
		}
		.callTitle {
			font-size: ${dim._14px};
			font-weight: 500;
			padding-right: ${dim._68px};
		}
		.dateCompo {
			padding-right: ${dim._24px};
		}
	}
	.iconComponent {
		display: flex;
		gap: ${dim._8px};
		color: ${theme.color.black_20};
		font-size: ${dim._12px};
		font-weight: 400;
		font-style: normal;
		line-height: normal;
		align-items: center;
	}
	.connecter {
		position: absolute;
		top: 50%;
		border-bottom: 1px solid #c6c6c6;
		width: 100%;
		left: -100%;
	}
	.text {
		color: #212f45;
		font-family: Open Sans;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;
