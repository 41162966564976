import React from 'react';
import { TableHeadingStyle } from './TableHeading.styled';

const TableHeading = ({ tableList, width }) => {
	return (
		<TableHeadingStyle width={width}>
			{tableList.map((d, i) => {
				if (d.style) {
					return <div style={d.style}>{d.head}</div>;
				} else {
					return <div>{d.head}</div>;
				}
			})}
		</TableHeadingStyle>
	);
};

export default TableHeading;
