import React, {useState} from "react";
import {useEffect} from "react";
import AdvisorService from "../../Services/AdvisorService";
import AdvisorCard from "./AdvisorCard";
import {DashboardWrapper} from "./index.styled";
import SuperSaverDiscussionBookService from "../../Services/SuperSaverClientChat";
import UserCard from "./UserCard";
import {useRef} from "react";
import {useNavigate} from "react-router";
import {globalNotificationAction} from "../ActiveChats/store/reducer";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import LoaderPage from "../../commonComponents/LoaderPage";
import { RxCross2 } from 'react-icons/rx';
const BasicChatDashboard = () => {
  const navigate = useNavigate();
  const selectedCard = useRef();
  const otherRef = useRef();
  const [advisors, setAdvisors] = useState([]);
  const [activeChats, setActiveChats] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingActiveChats, setLoadingActiveChats] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const advisors = await AdvisorService.getAdvisorUnreadChat();

        setAdvisors(advisors.data);
      } catch (error) {
        toast.error(error?.messages || "Oops something went wrong");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedAdvisor && selectedAdvisor.advisor_details.id) {
      (async () => {
        setLoadingActiveChats(true)
        try {
          const activeChat =
            await SuperSaverDiscussionBookService.getAdvisorsActiveChats(
              selectedAdvisor.advisor_details.id
            );
          activeChat && setActiveChats(activeChat.unread_chat_details);
          setLoadingActiveChats(false)
        } catch (error) {
          toast.error(error?.messages || "Oops something went wrong");
          setLoadingActiveChats(false)
        }
      })();
      selectedAdvisor && selectedCard && scrollIntoRef(selectedCard);
    }
  }, [selectedAdvisor, selectedCard]);

  const scrollIntoRef = (ref) => {
    setTimeout(() => {
      ref &&
        ref.current &&
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
    }, 500);
  };

  const handleOpenAdvisorChat = (it) => {
    dispatch({
      type: globalNotificationAction.SET_ADVISOR_CHAT_REFRESH_STATUS,
      payload: true,
    });
    navigate(
      "/dashboard/advisor-chat/" +
        it.advisor_details.id +
        "?name=" +
        it.advisor_details.name
    );
  };
  if (loading) {
    return <LoaderPage />;
  }
  return (
    <DashboardWrapper>
      <div className="advisors">
        {

          advisors?.unread_chats?.map((it) => {
            const isCurrentActive = activeIndex === it.advisor_details.id;
            return (
              <>
                <AdvisorCard
                  advisor={it}
                  active={isCurrentActive}
                  ref={isCurrentActive ? selectedCard : otherRef}
                  onClickUsers={(e) => {
                    setActiveIndex(
                      isCurrentActive ? null : it?.advisor_details?.id
                    );
                    setSelectedAdvisor(isCurrentActive ? null : it);
                    e.stopPropagation();
                  }}
                  onClickChats={(e) => {
                    handleOpenAdvisorChat(it);
                  }}
                />
              </>
            );
          })
        }
      </div>


      {

    selectedAdvisor&&
      
      (
        <div
          className="users"
          onClick={() => {
            handleOpenAdvisorChat(selectedAdvisor);
          }}
        >
          {selectedAdvisor && (
            <div className="selected-advisor">
              <h3 className="selected-adv-name">
                {selectedAdvisor?.advisor_details?.name}
              </h3>
              <span>


<RxCross2
          className='count'
          
          onClick={(e) =>
            {  e.stopPropagation();
              setActiveIndex(null)
              setSelectedAdvisor(null)
          }
          }
      />
              </span>
          
            </div>
          )}
          <div className="users-wrapper">
            {
                loadingActiveChats&&<div>loading...</div>
            }
            {!loadingActiveChats&&activeChats?.map((it) => {
              return (
                <>
                  <UserCard
                    user={it?.user_details}
                    sessions={it?.unread_sessions}
                    messages={it?.unread_messages}
                  />
                </>
              );
            })}
          </div>
        </div>
      )}
    </DashboardWrapper>
  );
};

export default BasicChatDashboard;
