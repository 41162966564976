import React from "react";
import { useNavigate } from "react-router";
import "./articlePreview.css";
import { ArticlePreviewDiv } from "./ArticlePreview.styled";

const ArticlePreview = (props) => {
  const navigate = useNavigate();
  const createMarkup = () => {
    return {
      __html:
        props.title.length > 55
          ? props.title.slice(0, 55) + " ..."
          : props.title,
    };
  };

  return (
    <ArticlePreviewDiv>
      <div className="relative ">
        <img
          src={props.thumbnail}
          height="280px"
          width={"300px"}
          alt={props.title}
        />
        <div className="absolute">{props.type}</div>
      </div>
      <div className="preview">
        <div className="buttons flexend">
          <p
            className="edit"
            onClick={() => {
              navigate("/", {
                state: {
                  _type: props.type,
                  title: props.title,
                  summary: props.shortDescription,
                  payload: props.articleDescription,
                  // references: props.references,
                  article_unique_id: props.article_unique_id,
                  tags: props.tags,
                  author_id: props.author_id,
                  thumbnail_url: props.thumbnail,
                  // banner_url:props.banner_url,
                  // created_by:props.created_by ,
                  // time_to_read:props.time_to_read ,
                  // word_count: props.word_count,
                  references: props.references,
                },
              });
              window.scrollTo(0, 0);
            }}
          >
            Edit
          </p>
          <p className="delete">Delete</p>
        </div>
        <h1 dangerouslySetInnerHTML={createMarkup()} />
        <p style={{ color: "#7F7F7F" }}>
          {props.shortDescription.length > 395
            ? props.shortDescription.slice(0, 395) + " ..."
            : props.shortDescription}
        </p>
        <br />
        <div className="flexRow">
          <img
            className="roundimg"
            src="https://www.prosperr.io/assets/images/advisors/auth_swarnim.jpg"
            alt="swarnim"
            height={"40px"}
            width="40px"
          />
          <div className="flexCol">
            <div className="">{props.author_id}</div>
            <div className="" style={{ color: "#7F7F7F" }}>
              Author
            </div>
          </div>
        </div>
      </div>
    </ArticlePreviewDiv>
  );
};

export default ArticlePreview;
