import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledConsultationConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dim._16px};
  padding: ${dim._24px} 0 ${dim._24px};
  border-bottom: 1px dotted #c6c6c6;
  position: relative;
  .dropDown {
    display: flex;
    flex-direction: column;
    gap: ${dim._8px};
  }
  .documentUpload {
    display: flex;
    flex-direction: column;
    gap: ${dim._8px};
    cursor: pointer;
  }
  .documentListBox {
    display: flex;
    gap: ${dim._20px};
  }
  .uploadIcon {
    display: flex;
    max-width: ${dim._scale(290)};
  }
  .uploadIcon {
    display: flex;
    gap: ${dim._8px};
    background-color: #f1f5f7;
    padding: ${dim._8px} ${dim._68px};
    color: #212f45;
    text-align: center;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .documentPreview {
    display: flex;
    gap: ${dim._8px};
    align-items: center;
    color: #425466;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    letter-spacing: 0.2px;
  }
  .documentPreviewImg {
    width: ${dim._16};
  }
  .documentDeleteIcon {
    width: ${dim._24};
    cursor: pointer;
  }
`;
