import styled from 'styled-components';
import { dim } from '../../../../styles/theme';

export const IncomeInputWrapper = styled.div`
	width: 100%;
	/* min-height: 100vh; */
	height: 100%;
	box-sizing: border-box;
	background-color: transparent;
	/* padding: ${dim._12px} ${dim._24px}; */

	.between-comp {
		width: ${dim._scale(926)};
	}

	.ask-for-components {
		display: flex;
		align-items: center;
	}

	.label {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: ${dim._14px};
		line-height: ${dim._20px};

		color: #212f45;
	}

	.ask-for-components {
		margin-top: ${dim._24px};
	}

	.label-margin {
		margin-left: ${dim._16px};
	}

	.button-container {
		margin-top: ${dim._32px};
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.modal {
		background-color: #fff;
		border-radius: 12px;
		padding: 36px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.heading {
		color: #0a2540;
	}
`;
