import React from "react";
import { useLocation } from "react-router";
import IncomeView from "../../components/IncomeView";
import IncomeNew from "../IncomeNew";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";

const getVersion = (appVersions, appVersion) => {
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    const details =
        appVersions && appVersions.find((it) => it.financial_year_id === fyId);
    return details !== undefined ? details.app_version : appVersion;
};

const IncomeRoot = () => {
    const { state } = useLocation();
    return state &&
        getVersion(state.appVersions, state.appVersion) === "1.0" ? (
        <IncomeView></IncomeView>
    ) : (
        <IncomeNew></IncomeNew>
    );
};

export default (IncomeRoot);
