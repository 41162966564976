import styled from "styled-components";
import { dim, theme } from "../../../../../styles/theme";

const AdvisorActionStyle = styled.div`
  padding: ${dim._16px} ${dim._24};
  width: 100%;
  border-radius: ${dim._4px};
  border: 1.0671875px solid ${theme.color.white_60};
  background: ${theme.color.white_0};
  margin-top: ${dim._8px};
  box-sizing: border-box;

  .heading {
    padding-bottom: ${dim._18px};
  }
  .dropdown-container {
    display: flex;
    justify-content: space-between;
    width: 70%;
    min-height: ${dim._56px};
  }
  .button-align {
    padding-top: ${dim._24px};
    display: flex;
    justify-content: center;
    width: 60%;
  }

  .msg-box {
    background: ${theme.color.white_40};
    width: 100%;
    padding: ${dim._16px} ${dim._24px};
    margin-top: ${dim._18px};
    border-radius: ${dim._4px};
    border: 1.0671875px solid #f8f8f8;
    box-sizing: border-box;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.2);
  }

  .border-bottom {
    border-bottom: 1px dotted ${theme.color.grey_110};
    padding-bottom: ${dim._24px};
  }

  `
  export {AdvisorActionStyle}