import styled from "styled-components";
import { dim, theme } from "../../../../styles/theme";

export const StyledNoCallView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${dim._20px};
  /* color: ${theme.color.grey_110}; */
  padding: ${dim._scale(100)};
`;
