import styled from "styled-components";
import { dim } from "../../../../../styles/theme";

export const StyledMobilePreview = styled.div`
  width: 375px;
  height: 90%;
  border: 1px solid #ccc; 
  border-radius: ${dim._20px};
  overflow: auto;
  box-sizing: border-box;
  background-color: #fff;
  margin: ${dim._0px} auto;
  white-space: normal;
  margin-top: ${dim._10px};

  p {
    margin: ${dim._0px} !important;
  }
  img {
    width: 100%;
    object-fit: fill;
  }

  .mobile-content-wrapper {
    padding: ${dim._16px};
    box-sizing: border-box;
    width: 375;
    overflow: scroll;
  }

  .mobile-banner-wrapper img {
    width: 100%;
    border-radius: ${dim._8px};
    aspect-ratio: 343/170;
    object-fit: cover;
  }
  .mobile-banner-wrapper {
    width: 100%;
    padding: ${dim._16px} 0;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin-top: ${dim._24px};
    min-height: ${dim._16px};
  }

  h2 {
    margin-top: ${dim._12px};
    font-size: ${dim._16px};
    min-height: ${dim._30px};
    color: #3a3a3a;
  }
  h1 {
    min-height: ${dim._30px};
  }

  .mobile-summary-wrapper {
    padding-top: ${dim._10px};
    border-radius: ${dim._4px};
    color: #282c3f;
    font-size: ${dim._14px};
    font-style: normal;
    font-weight: 600;
    line-height: ${dim._20px};
    width: 100%;
  }
  .mobile-summary {
    width: 100%;
    overflow-wrap: break-word;
  }
  .mobile-summary-wrapper p {
    margin: 0;
  }

  .mobile-title-preview {
    font-weight: 700;
    font-size: ${dim._18px};
    line-height: ${dim._30px};
    max-width: 100%;
    overflow-wrap: break-word;
    padding-top: ${dim._18px};
  }
  .mobile-title-preview::first-letter {
    text-transform: capitalize;
  }

  .mobile-title-wrapper {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-weight: bold;
    width: 100%;
    margin-top: -16px;
  }
  .mobile-tableWrapper {
    width: 100%;
  }

  .mobile-summary-title {
    margin-bottom: ${dim._8px};
    font-size: ${dim._16px};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1em;
  }
  .mobile-tags-wrapper {
    margin-top: ${dim._24px};
    display: flex;
    flex-wrap: wrap;
    gap: ${dim._8px};
    width: 100%;
    overflow: scroll;
  }

  .mobile-tag-name {
    background-color: red;
    border-radius: ${dim._20px};
    padding: ${dim._6px} ${dim._16px};
    color: #2e4254;
    white-space: nowrap;
    margin: 0 ${dim._8px} ${dim._8px} 0;
  }
  .MuiChip-label {
    font-family: "Open sans";
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${dim._scale(100)};
  }

  .mobile-body-wrapper {
    display: flex;
    margin-top: ${dim._20px};
    position: relative;
    width: 100%;
    overflow-wrap: break-word;
  }

  .mobile-body-content {
    max-width: 100%;
    list-style-position: inside;
  }

  .mobile-body-content img {
    max-width: 90%;
  }
  .mobile-container {
    width: 100%;
  }
  .mobile-tags-wrapper {
    margin-top: ${dim._24px};
    display: flex;
    gap: ${dim._10px};
    width: 100%;
    flex-wrap: wrap;
    .mobile-tag-name {
      background-color: #dce6ed;
      border-radius: ${dim._20px};
      padding: ${dim._6px} ${dim._16px};
      color: #2e4254;
      margin-right: ${dim._8px};
      font-size: ${dim._14px};
      white-space: nowrap;
    }
  }

  .mobile-info {
    font-weight: bold;
    color: #787878;
  }

  .mobile-articleInfo {
    display: flex;
    padding-top: ${dim._10px};
    font-weight: 600;
    font-size: ${dim._12px};
    line-height: ${dim._16px};
    align-items: center;
  }
  .prosperr {
    padding-left: ${dim._8px};
    padding-right: ${dim._10px};
    color: #212f63;
  }

  .mobile-dateAndReadTime {
    color: #555770;
    font-weight: 400;
  }

  .shareBtn {
    width: ${dim._32px};
    position: -webkit-sticky;
    position: sticky;
    top: ${dim._scale(114)};
    right: ${dim._10px};
    float: right;
    box-sizing: border-box;
    z-index: 1;
  }
  .mobile-summary::first-letter {
    text-transform: capitalize;
  }
  .verified {
    margin-right: ${dim._8px};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h1 {
    margin: ${dim._0px} ${dim._0px} ${dim._0px} ${dim._8px};
    padding: ${dim._0px};
    font-style: normal;
    font-weight: 700;
    font-size: ${dim._16px};
    line-height: ${dim._22px};
    text-transform: uppercase;
    color: #212f63;
  }
`;

export const Logo = styled.img`
  height: ${dim._30px};
  width: ${dim._30px};
`;

export const MuiChipLabelStyled = {
  display: "block",
  fontSize: dim._10px,
  whiteSpace: "normal",
  paddingLeft: dim._8px,
};

export const chipStyle = {
  height: "auto",
  border: "none",
  padding: "4px",
  "& .MuiChip-label": {
    ...MuiChipLabelStyled,
  },
};
