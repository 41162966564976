import React from 'react'
import ActivityHistory from '../../../../components/ActivityHistory';

const UserActivity = ({userActivities,userId}) => {
  return (
    <div className="activityWrap">
    {userActivities && (
      <ActivityHistory
        user_id={userId}
        consultHistory={userActivities
          .map((d, i) => {
            if (d.status === "NOT_INITIATED") {
              return { ...d, color: "", rank: "3" };
            } else if (d.status === "IN_PROGRESS") {
              return {
                ...d,
                color: "#e1e15d",
                rank: "2",
                content: "Activity Started on",
              };
            } else {
              return {
                ...d,
                color: "#15BE53",
                rank: "1",
                content: "Activity completed on",
              };
            }
          })
          .sort((a, b) => a.rank - b.rank)}
      />
    )}
  </div>
  )
}

export default UserActivity