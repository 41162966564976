import Styled,{keyframes} from "styled-components";

const Rotate = keyframes`
 from {
   transform: rotate(0deg);
   /* opacity: 0; */
  /* transition-delay: 10.3s;
  transition-duration: 10.2s;
  transition-property: opacity;
 transition-timing-function: ease-in; */
  /* left: 0px;
  top:100px ; */
  }
 
  to {
   transform: rotate(360deg);
    /* opacity: 1; */
  /* transition-delay: 10s; */
  /* transition-duration: 1.2s; */
  /* transition-property: opacity; */
   /* transition-timing-function: ease-out; */
   /* left:100px ;
   top:200px; */
  }
  `;

export const AlertBox = Styled.div`
height:50px ;
width:100vh;
font-size: 18px!important;
z-index: 1;
border:2px solid white ;
background:#0B6AB0 ;
position:fixed;
color:white;
  /* width:100px;
  height:100px; */
  /* background:red; */
  left:${props=>props.left?'400px':null};
    top:100px;
  border-radius:10px;
text-align:center;
align-items:center;
justify-content:center;
padding-top: 13px;
box-sizing:border-box;
animation: {Rotate} 0.4s ease-in ;

`