import React, { useState } from "react";
import TextButton from "../../../../../commonComponents/TextButton";
import { SelectActionableItemsStyle } from "./SelectActionableItemsStyle.styled";
import AdvisorActionableView from "../AdvisorActionableView";
import ClientActionableItems from "../../ClientDependencyInfo";
import ClientActionableView from "../../ClientDependencyInfo/ClientActionableView";

import FormErrorMsgHandler from "../../../../../commonComponents/FormErrormsgHandler";
import { ERROR_MESSAGE } from "../../../constant";
import { dim } from "../../../../../styles/theme";
import RenderAdvisorActionData from "../RenderAdvisorAction";
import { useParams } from "react-router";
import { USER_TYPE } from "../../../../../utils/constant";
// import { SelectActionableItemsStyle } from "./SelectActionableItemsStyle.styled";
// import AdvisorActionableView from "../SelectActionableItems";

const resData = [
  {
    type: "advisor",
    dependencyValue: "ADVISOR",
    label: "+ Advisor Action Item(s)",
    componentCategory: "advisorActions",
  },
  {
    dependencyValue: "USER",
    type: "client",
    label: "+ Client Action Item(s)",
    componentCategory: "userActions",
  },
];

const SelectActionableItem = ({
  handleActionableItem,
  isError,
  dependencyArrData,
  actionableData,
  handleDeleteActionable,
  clientData,
  handleClientDataDeleteActionable,
  isEditDisabled,
  getConsultationMom,
}) => {
  const [type, setType] = useState("");
  const advisorActionableData = (advisor_data) => {
    handleActionableItem(advisor_data, type);
  };

  const clientActionableData = (client_data) => {
    handleActionableItem(client_data, type);
  };
  const hadnleActionableData = (items) => {
    handleActionableItem(items);
  };
  const dependencyOn = dependencyArrData
    .filter((d) => d.isSelected)
    .map((d, i) => {
      return d.value;
    });

  return (
    <>
      {!isEditDisabled &&
        resData
          .filter((d) => {
            return (
              (d.dependencyValue === "ADVISOR"
                ? actionableData.length < 1
                : clientData.length < 1) &&
              dependencyOn.includes(d.dependencyValue)
            );
          })
          .map((item) => (
            <SelectActionableItemsStyle>
              <TextButton
                onClick={() => {
                  setType(item.type);
                }}
              >
                {item.label}
              </TextButton>

              {isError?.error &&
                isError?.errorArr.includes(item.componentCategory) && (
                  <FormErrorMsgHandler
                    style={{ bottom: "-32px", left: dim._24px, zIndex: 1 }}
                  >
                    {ERROR_MESSAGE[item.componentCategory]}
                  </FormErrorMsgHandler>
                )}
            </SelectActionableItemsStyle>
          ))}
      {dependencyOn.includes("ADVISOR") && actionableData.length > 0 && (
        <RenderAdvisorActionData
          data={actionableData}
          handleDeleteActionable={handleDeleteActionable}
          add_action={"Advisor actions"}
          type={USER_TYPE.ADVISOR}
          handleAddAction={() => {
            !isEditDisabled && setType("advisor");
          }}
          getConsultationMom={getConsultationMom}
          isEditDisabled={isEditDisabled}
        />
      )}
      {dependencyOn.includes("USER") && clientData.length > 0 && (
        <RenderAdvisorActionData
          data={clientData}
          handleDeleteActionable={handleClientDataDeleteActionable}
          add_action={"Client actions"}
          handleAddAction={() => {
            !isEditDisabled && setType("client");
          }}
          isEditDisabled={isEditDisabled}
        />
      )}
      {dependencyOn.includes("ADVISOR") && (
        <AdvisorActionableView
          activateViewPopUp={type === "advisor"}
          handleHelpShow={(open) => (isEditDisabled ? () => {} : setType(""))}
          advisorActionableData={advisorActionableData}
          hadnleActionableData={
            isEditDisabled ? () => {} : hadnleActionableData
          }
        />
      )}

      {dependencyOn.includes("USER") && (
        <ClientActionableView
          activateViewPopUp={type === "client"}
          handleHelpShow={(open) => (isEditDisabled ? () => {} : setType(""))}
          clientActionableData={clientActionableData}
          hadnleActionableData={
            isEditDisabled ? () => {} : hadnleActionableData
          }
        />
      )}
    </>
  );
};
export default SelectActionableItem;
