import styled from "styled-components";
import { dim, theme } from "../../../../styles/theme";

export const StyledBasicCallDetailsForm = styled.div`
  width: 100%;
  /* background-color: #fff; */
  /* border-radius: 4px; */
  /* padding: 0 32px; */
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  cursor: ${({ isEditDisabled }) =>
    isEditDisabled ? "not-allowed" : "normal"};
  box-sizing: border-box;
  .theme {
    padding: 24px 0;
  }
  .chip {
    font-size: ${dim._14px};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .activeChip {
    color: #fff;
    background-color: ${theme.color.green_20};
  }
`;
