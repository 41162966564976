import React, {useEffect, useState} from "react";
import {
  StyledActionDashboard,
  StyledCallParentWrapper,
  StyledCallWrapper,
  StyledTitleWrapper,
} from "./ActionDashboard.styled";
import ActionWidget from "./components/ActionWidget";
import {StyledFlexWrapper} from "./components/ActionWidget/ActionWidget.styled";
import {dim} from "../../styles/theme";
import {AdvisorDashboard} from "../../Services/AdvisorDashboard";
import toast from "react-hot-toast";
import LoaderPage from "../../commonComponents/LoaderPage";
import {getDifference, getHrDiff} from "../../utils/converttimestamptoday";
import PendingMomWidgetIcon from "../../Assets/icons/PendingMomWidgetIcon";
import ChatWidgetIcon from "../../Assets/icons/ChatWidgetIcon";
import ConsultationBar, {
  ConsultationBarForMarkingDue,
} from "./components/ConsultationBar";
import TextButton from "../../commonComponents/TextButton";
import {useNavigate} from "react-router";

const ActionDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);

  const getAdvisorDashboardSummary = async () => {
    try {
      setLoading(true);
      const res = await AdvisorDashboard.getAdvisorDashboardSummary();

      const {data: dashboardData} = res;
      setDashboardData(dashboardData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message || "Oops something went wrong");
    }
  };

  useEffect(() => {
    getAdvisorDashboardSummary();
  }, []);
  if (loading) return <LoaderPage />;
  const _24HourMilliseconds = 86400000;
  const chatActionObj = {
    title: "Chats Waiting",
    pendingValue: dashboardData?.unreplied_clients || 0,
    pendingAction: "Client reply pending",
    actionText: "Reply pending since ",
    pendingSince: getDifference(
      dashboardData?.oldest_unreplied_chat_date - _24HourMilliseconds
    ),
    pendingFromMoreThenOneDay:
      getHrDiff(dashboardData?.oldest_unreplied_chat_date) >= 24,
    redirectTo: "/active-chats",
    Icon: ChatWidgetIcon,
  };
  const momActionObj = {
    title: "MOM Pending",
    pendingValue: dashboardData?.pending_mom_count || 0,
    pendingAction: " Calls MOM pending",
    actionText: "MOM pending since",
    pendingSince: getDifference(
      dashboardData?.oldest_mom_pending_date - _24HourMilliseconds
    ),
    pendingFromMoreThenOneDay:
      getHrDiff(dashboardData?.oldest_mom_pending_date) >= 24,
    redirectTo: "/consultation-view",
    Icon: PendingMomWidgetIcon,
  };
  const upcomingCallDetails = dashboardData?.upcoming_call_details || [];
  const unMarkedCallDetails = dashboardData?.unmarked_call_details || [];
  return (
    <StyledActionDashboard>
      <TitleWrapper title="Task Overview">
        <WidgetWrapper
          chatActionObj={chatActionObj}
          momActionObj={momActionObj}
        />
      </TitleWrapper>
      <StyledFlexWrapper
        style={{
          gap: dim._18px,
          height: "66%",
        }}
      >
        <TitleWrapper
          style={{
            width: "50%",
            height: "100%",
          }}
          title={`Today’s consultation (${upcomingCallDetails?.length || 0}) `}
          wrapperStyle={{
            height: "100%",
          }}
        >
          <CallWrapper
            upcomingCallDetails={dashboardData?.upcoming_call_details}
          />
        </TitleWrapper>
        <TitleWrapper
          style={{
            width: "50%",
            height: "100%",
          }}
          title={`Call Marking Due (${unMarkedCallDetails?.length || 0}) `}
          wrapperStyle={{
            height: "100%",
          }}
        >
          <CallWrapper
            upcomingCallDetails={dashboardData?.unmarked_call_details}
            ConsultationBarForMarkingDue={ConsultationBarForMarkingDue}
          />
        </TitleWrapper>
      </StyledFlexWrapper>
    </StyledActionDashboard>
  );
};

export default ActionDashboard;

const WidgetWrapper = ({momActionObj, chatActionObj}) => {
  return (
    <StyledFlexWrapper
      style={{
        gap: dim._20px,
        paddingBottom: dim._24px,
      }}
    >
      <ActionWidget {...chatActionObj} />
      <ActionWidget {...momActionObj} />
    </StyledFlexWrapper>
  );
};
const CallWrapper = ({upcomingCallDetails, ConsultationBarForMarkingDue}) => {

  const navigate = useNavigate();
  const handleViewMore=()=>{

    const params = new URLSearchParams();
    !ConsultationBarForMarkingDue && params.set("upcoming_call", true); // Use set() instead of append()

    const queryString = params.toString();
    const url = "/consultation-view";
    navigate({
      pathname: url,
      search: queryString,
    });
  };
  
  return (
    <StyledCallParentWrapper>
      <StyledCallWrapper>
        {upcomingCallDetails?.length !== 0 ? (
          upcomingCallDetails?.map((callInfo) =>
            ConsultationBarForMarkingDue ? (
              <ConsultationBarForMarkingDue {...callInfo} />
            ) : (
              <ConsultationBar {...callInfo} />
            )
          )
        ) : (
          <span className="NoDataAvailable">No data available</span>
        )}
      </StyledCallWrapper>
      <TextButton
        className="viewMoreBtn"
        onClick={() => {
          handleViewMore()
        }}
      >
        View More {`>>`}
      </TextButton>
    </StyledCallParentWrapper>
  );
};
const TitleWrapper = ({title, style, children, wrapperStyle}) => {
  return (
    <StyledTitleWrapper style={style}>
      <div className="title">{title}</div>
      <div className="wrapper" style={wrapperStyle}>
        {children}
      </div>
    </StyledTitleWrapper>
  );
};
