import React from "react";
import { ClientProfileStyle } from "./ClientProfile.Styled";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaUserCircle } from "react-icons/fa";

const ClientProfile = ({ userDetails, view }) => {
  return (
    <ClientProfileStyle>
      <div className="userProf">
        <FaUserCircle size={32} color="#106AB0" />
        <h1 className="userName">{`${userDetails?.name?.toUpperCase()}`} &nbsp;</h1>
        <div className="userReferal">({userDetails?.referral_code})</div>
      </div>
    </ClientProfileStyle>
  );
};

export default ClientProfile;
