import React, { useEffect, useState } from "react";
import IncomeTable from "../../Component/IncomeTable";
import { CapitalGainStyle } from "./CapitalGain.styled";
import { getSummarizedData, initializeDefaultData } from "./utils";
import { Loader2 } from "../../../../commonComponents/Loader";
import { timeStampDateNewFormat } from "../../../../utils/epochConverter";
import SuperSaverIncomeService from "../../../../Services/SuperSaverIncomeService";
import { useLocation } from "react-router";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";

const CapitalGain = () => {
    const { state, pathname } = useLocation();
    const [data, setData] = useState(null);
    const [capitalGainIncome, setCapitalGainIncome] = useState([]);
    const [finalCapitalGainIncome, setFinalCapitalGainIncome] = useState([]);
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    useEffect(() => {
        (async () => {
            const res = await SuperSaverIncomeService.getIncomeRevampData(
                "CAPITAL_GAIN",
                state.user_id,
                fyId
            );
            setData(res.data);
        })();
    }, [fyId]);

    useEffect(() => {
        data && setCapitalGainIncome(getSummarizedData(data.income_details));
    }, [data]);

    useEffect(() => {
        const data = initializeDefaultData(capitalGainIncome);
        setFinalCapitalGainIncome([...data]);
    }, [capitalGainIncome]);

    const returnCapitalGainLabels = (subType) => {
        switch (subType) {
            case "LTCG_EQUITY":
                return "Long Term Capital Gain (Equity)";
            case "LTCG_DEBT":
                return "Long Term Capital Gain (Debt)";
            case "STCG_EQUITY":
                return "Short Term Capital Gain (Equity)";
            case "STCG_DEBT":
                return "Short Term Capital Gain (Debt)";
            default:
                return "";
        }
    };

    const tableTitle = {
        top_a: "Entry Price",
        top_b: "Selling Price",
        side_a: "Total Proceeds",
        side_b: "Total Capital Gains",
    };

    return (
        <>
            {data == null ? (
                <div
                    style={{
                        width: "100%",
                        height: "60vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loader2></Loader2>
                </div>
            ) : (
                <CapitalGainStyle>
                    {finalCapitalGainIncome &&
                        finalCapitalGainIncome.map((it, i) => (
                            <IncomeTable
                                title={returnCapitalGainLabels(it.sub_type)}
                                tableTitle={tableTitle}
                                componentName={"CAPITAL_GAIN"}
                                path={`/${
                                    pathname.split("/")[1]
                                }/incomeView/capital-gain/detail`}
                                lastUpdatedOn={
                                    it.updated_at > it.created_at
                                        ? timeStampDateNewFormat(it.updated_at)
                                        : timeStampDateNewFormat(it.created_at)
                                }
                                col_a={it.amount_invested}
                                col_b={it.selling_amount}
                                total={it.selling_amount - it.amount_invested}
                                capitalGainData={it}
                                userId={state.user_id}
                            />
                        ))}
                </CapitalGainStyle>
            )}
        </>
    );
};

export default CapitalGain;
