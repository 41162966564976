import React from 'react';
import { TextInputStyle } from './TextInput.styled';
import FormErrormsgHandler from '../FormErrormsgHandler';

const TextInput = ({
	label,
	value,
	name,
	handleTextInput,
	placeholder,
	error
}) => {
	return (
		<TextInputStyle>
			{label && <label>{label}</label>}
			<input
				type={'text'}
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={handleTextInput}
			/>
			{error && value.length < 1 && (
				<FormErrormsgHandler className='error'>
					please write {placeholder}
				</FormErrormsgHandler>
			)}
		</TextInputStyle>
	);
};

export default TextInput;
