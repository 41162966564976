import styled from "styled-components";
import { dim, theme } from "../../../../../styles/theme";

const AdvisorSubmitProofStyle = styled.div`
  padding: ${dim._16px} ${dim._24px};
  width: 90%;
  border-radius: ${dim._4px};
  border: 1.0671875px solid ${theme.color.white_60};
  box-sizing: border-box;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);

  .heading {
    padding-bottom: ${dim._18px};
  }

  .align-section {
    padding: 0 0 10px;
  }

  .document-text {
    font-size: ${dim._12px};
    font-weight: ${theme.fontWeight.weight_40};
    color: #212f45;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }

  .cross-icon {
    display: flex;
    gap: ${dim._20px};
  }
`;
export { AdvisorSubmitProofStyle };
