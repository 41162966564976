import React, { useState } from 'react';
import { StyledSalaryReadComponent } from './SalaryReadComponent.styled';

import { AiFillCloseSquare } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';
import PayslipUpload from './Components/PayslipUpload';
import ReadPayslip from './Components/ReadPayslip';
import { LOCAL_DB_KEYS } from '../../../../constants/localStorage';
import SuperSaverIncomeService from '../../../../Services/SuperSaverIncomeService';
import Button from '../../../../commonComponents/Button';
import Spinner from '../../../../commonComponents/Spinner';
import { PayslipRead } from '../../../../Services/PayslipReadApi';
import { dim } from '../../../../styles/theme';

let filePrefix;

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

if (ENVIRONMENT === 'PRODUCTION') {
	filePrefix = 'https://prod-supersaverclientdocs.s3.eu-west-1.amazonaws.com/';
} else if (ENVIRONMENT === 'LOCAL') {
	filePrefix = 'https://stage-supersaverclientdocs.s3.eu-west-1.amazonaws.com/';
} else {
	filePrefix = 'https://stage-supersaverclientdocs.s3.eu-west-1.amazonaws.com/';
}

const suffix =
	'?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYOURACCESSKEYID%2F20230220%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20230220T100000Z&X-Amz-Expires=3600&X-Amz-Signature=0a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p6q7r8s9t0u1v2w3x4y5z6a7b8c9d0e1&X-Amz-SignedHeaders=host%3Bx-amz-content-sha256%3Bx-amz-date';

const AutoSalarySlipUpload = ({
	isOpen,
	handleClose,
	externalId,
	systemGeneratedAmount,
	companyNameDefault,
	documentSubmit,
	setDocumentSubmit,
	month,
	onSubmit = (data) => {}
}) => {
	const [documentName, setDocumentName] = useState(null);
	const [componentLoading, setComponentLoading] = useState(false);
	const [retryStatus, setRetryStatus] = useState({
		status: false,
		count: 0
	});

	const [attachment, setAttachment] = useState(null);
	const [totalEarning, setTotalEarning] = useState(0);
	const [totalDeduction, setTotalDeduction] = useState(0);
	const [UploadAvatar, setUploadAvatar] = useState({
		upload: false,
		name: 'No file choosen',
		img: ''
	});
	const [parsedData, setParsedData] = useState({});
	const [earningComponent, setEarningComponent] = useState([]);
	const [companyName, setCompanyName] = useState(companyNameDefault);

	const [jobId, setJobId] = useState(null);
	const [isParsedError, setParsedError] = useState(false);

	const [deductionComponent, setDeductionComponent] = useState([]);
	const handleSetDocumentSubmit = async (value) => {
		try {
			setComponentLoading(true);
			setDocumentSubmit(value);
			const fileName = UploadAvatar.img;
			const payload = filePrefix + fileName + suffix;
			const jobId = await PayslipRead.payslipParse(payload);
			setJobId(jobId);
			try {
				const data = await PayslipRead.parseJob(jobId.data);
				setParsedData(data.data);
				setComponentLoading(false);
			} catch (error) {
				setComponentLoading(false);
				setParsedError(true);
				const retryObj = {
					status: true,
					count: retryStatus.count + 1 //retrying the api call
				};
				setRetryStatus({ ...retryObj });
				retryStatus.count >= 4
					? toast.error(
							error && error.response && error.response.data
								? error.response.data.message
								: 'Oops Something went wrong'
					  )
					: toast.loading('Retrying...', {
							duration: 1000
					  });
			}
		} catch (error) {
			retryStatus.count >= 4
				? toast.error(
						error && error.response && error.response.data
							? error.response.data.message
							: 'Oops Something went wrong'
				  )
				: toast.loading('Retrying...', {
						duration: 1000
				  });
			setComponentLoading(false);
		}
	};

	useEffect(() => {
		if (retryStatus.status && retryStatus.count <= 3) {
			setTimeout(async () => {
				await retry();
			}, 4000);
		}
	}, [retryStatus.status]);

	const retry = async () => {
		setComponentLoading(true);
		const retryObj = {
			...retryStatus,
			status: false
		};
		setRetryStatus({ ...retryObj });
		try {
			const data = await PayslipRead.parseJob(jobId.data);
			setParsedData(data.data);
			setComponentLoading(false);
			setParsedError(false);
			// toast.promise(data, {
			// 	loading: 'Retrying...',
			// 	success: 'Data parsed',
			// 	error: 'Oops something went wrong'
			// });
		} catch (error) {
			setParsedError(true);
			retryStatus.count >= 4
				? toast.error(
						error && error.response && error.response.data
							? error.response.data.message
							: 'Oops Something went wrong'
				  )
				: toast.loading('Retrying...', {
						duration: 1000
				  });
			const retryObj = {
				status: true,
				count: retryStatus.count + 1 //retrying the api call
			};
			setRetryStatus({ ...retryObj });
			setComponentLoading(false);
		}
	};

	useEffect(() => {
		setCompanyName(companyNameDefault);
		if (!documentSubmit) {
			setDeductionComponent([]);
			setEarningComponent([]);
			setRetryStatus({ status: false, count: 0 });
		}
	}, [isOpen, documentSubmit]);
	useEffect(() => {
		if (!isOpen) {
			setUploadAvatar({
				upload: false,
				name: 'No file choosen',
				img: ''
			});
			setAttachment(null);
		}
	}, [isOpen]);

	useEffect(() => {
		if (parsedData.earnings) {
			const obj = parsedData.earnings.earning_type_to_amount_map;
			const deductions = parsedData.deductions.deduction_type_to_amount_map;
			const array = Object.entries(obj);
			const deductionArray = Object.entries(deductions);

			setEarningComponent(
				array
					.filter((data) => {
						const result = data[0].toLowerCase().includes('total');
						return data[1].amount.amount_value > 0 && !result;
					})
					.map((data) => {
						const newData = {
							label: data[0].replaceAll(/_/gi, ' '),
							amount: data[1].amount.amount_value
						};
						if (
							data[0].replaceAll(/_/gi, ' ').toLowerCase().includes('basic') ||
							data[0].replaceAll(/_/gi, ' ').toLowerCase().includes('house')
						) {
							return { ...newData, editStatus: false, isEditable: false };
						} else {
							return { ...newData, editStatus: false, isEditable: true };
						}
					})
			);
			setDeductionComponent(
				deductionArray
					.filter((data) => {
						const result = data[0].toLowerCase().includes('total');
						return data[1].amount.amount_value > 0 && !result;
					})
					.map((data) => {
						const newData = {
							label: data[0].replaceAll(/_/gi, ' '),
							amount: data[1].amount.amount_value
						};
						return { ...newData, editStatus: false };
					})
			);
			setCompanyName(parsedData.organization_name);
		}
	}, [parsedData]);

	useEffect(() => {
		let totalEarning = 0;

		earningComponent.map((d, i) => {
			const result = d.label.toLowerCase().includes('total');
			if (!result) {
				totalEarning = totalEarning + Number(d.amount);
			}

			return d;
		});

		setTotalEarning(!isNaN(totalEarning) ? totalEarning : 0);
	}, [earningComponent]);
	useEffect(() => {
		let totalDeduction = 0;

		deductionComponent.map((d, i) => {
			const result = d.label.toLowerCase().includes('total');
			if (!result) {
				totalDeduction = totalDeduction + Number(d.amount);
			}

			return d;
		});

		setTotalDeduction(!isNaN(totalDeduction) ? totalDeduction : 0);
	}, [deductionComponent]);

	const incomeType = 'SALARY';

	const handleSubmit = async (e) => {
		const data = {
			external_id: externalId,
			financial_year_id: localStorage.getItem(
				LOCAL_DB_KEYS.FINANCIAL_YEAR_ID
			),
			monthly_breakdown: {
				[month]: {
					system_generated_amount: systemGeneratedAmount,
					approved_salary: totalEarning,
					is_approved: true,
					approved_source: 'SYSTEM',
					is_editable: true,
					salary_slip: UploadAvatar.img,
					extra_pay: 0,
					is_salary_slip_read: true,
					salary_component: [
						{
							credit: [...earningComponent]
						},
						{
							debit: [...deductionComponent]
						}
					],
					salary_income_id: externalId
				}
			}
		};
		try {
			const MonthSalaryBrakedown =
				await SuperSaverIncomeService.postSalaryVerification(data, incomeType);
			onSubmit(MonthSalaryBrakedown);
			setDocumentSubmit(false);
			handleClose(e);
		} catch (error) {
			toast.error(
				error && error.response && error.response.data
					? error.response.data.message
					: 'Oops Something went wrong'
			);
		}
	};

	const handleDocumentSubmit = (value) => {
		setDocumentSubmit(value);
	};

	return (
		<StyledSalaryReadComponent className='uploadModel' isOpen={isOpen}>
			<AiFillCloseSquare
				className='close-btn'
				onClick={(e) => {
					setCompanyName(companyNameDefault);
					handleDocumentSubmit(false);
					handleClose(e);
				}}
			/>
			{documentSubmit && (
				<BiArrowBack
					className='back-btn'
					onClick={() => {
						handleDocumentSubmit(false);
					}}
				/>
			)}
			{documentSubmit ? (
				<>
					<h2 className='heading'>{companyName}</h2>
					<h2 className='heading Salary'>Salary Slip Details for {month}</h2>
					<ReadPayslip
						earningComponent={earningComponent}
						setComponent={setEarningComponent}
						componentTitle={'Earning'}
						componentLoading={componentLoading}
						totalEarning={totalEarning}
						viewMode={false}
					/>
					<ReadPayslip
						style={{ marginTop: 24 }}
						earningComponent={deductionComponent}
						setComponent={setDeductionComponent}
						componentTitle={'Deduction'}
						componentLoading={componentLoading}
						totalDeduction={totalDeduction}
						viewMode={false}
					/>

					<div className='btns'>
						<div className='submitBtn'>
							<Button
								style={{
									width: '86px',
									height: '34px',
									fontWeight: '500',
									fontSize: '12px',
									lineHeight: '16px',
									position: 'relative'
								}}
								onButtonClick={(e) => {
									handleSubmit(e);
								}}
							>
								{!retryStatus.status ? (
									'Submit'
								) : (
									<>
										{' '}
										Retrying...
										<div className='loaderNew'>
											<Spinner size={dim._20px} />
										</div>
									</>
								)}
							</Button>
						</div>
						{/* {isParsedError && (
							<OutlinedButton onClick={retry} style={{ marginLeft: 24 }}>
								Retry
							</OutlinedButton>
						)} */}
					</div>
				</>
			) : (
				<PayslipUpload
					isOpen={isOpen}
					companyName={companyName}
					month={month}
					handleClose={handleClose}
					handleSetDocumentSubmit={handleSetDocumentSubmit}
					setAttachment={setAttachment}
					attachment={attachment}
					documentName={documentName}
					UploadAvatar={UploadAvatar}
					setUploadAvatar={setUploadAvatar}
					setDocumentName={setDocumentName}
				/>
			)}
		</StyledSalaryReadComponent>
	);
};
export default AutoSalarySlipUpload;
