import React from 'react'

const PendingMomWidgetIcon = () => {
  return (
<svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.587821" fill-rule="evenodd" clip-rule="evenodd" d="M6.66699 5.33333C6.66699 8.27885 9.05481 10.6667 12.0003 10.6667C14.9458 10.6667 17.3337 8.27885 17.3337 5.33333C17.3337 2.38781 14.9458 0 12.0003 0C9.05481 0 6.66699 2.38781 6.66699 5.33333ZM20 10.667C20 12.8761 21.7909 14.667 24 14.667C26.2091 14.667 28 12.8761 28 10.667C28 8.45785 26.2091 6.66699 24 6.66699C21.7909 6.66699 20 8.45785 20 10.667Z" fill="#8280FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9778 13.333C5.68255 13.333 0.517678 16.5684 0.000868912 22.9319C-0.0272823 23.2786 0.635616 23.9997 0.970003 23.9997H22.9956C23.9972 23.9997 24.0128 23.1936 23.9972 22.933C23.6065 16.3906 18.3616 13.333 11.9778 13.333ZM31.2749 24H26.1335C26.1334 20.9987 25.1417 18.2291 23.4681 16.0008C28.0104 16.0503 31.7192 18.3467 31.9983 23.2C32.0096 23.3955 31.9983 24 31.2749 24Z" fill="#8280FF"/>
</svg>
  )
}

export default PendingMomWidgetIcon