import React from "react";
import { SalarySidebarStyle } from "./SalarySidebar.styled";
import CurrentCompany from "./CurrentCompany";

const SalarySidebar = ({ allData, selectedCompanyId, handleCompanySelect }) => {
    const sortedData =
        allData &&
        allData.income_details &&
        allData.income_details.salary_income_dto_list.sort(
            (a, b) =>
                a.start_end_date_fy.start_date - b.start_end_date_fy.start_date
        );
    return (
        <SalarySidebarStyle>
            <div className="oldCompany">
                <h2>Current Company</h2>
            </div>
            {allData &&
            allData.income_details &&
            allData.income_details.salary_income_dto_list.length > 0 ? (
                <>
                    {" "}
                    {sortedData &&
                        sortedData
                            .filter(
                                (companies, i) => companies.is_current_company
                            )
                            .map((company, i) => (
                                <CurrentCompany
                                    key={i}
                                    selectedCompanyId={selectedCompanyId}
                                    company={company}
                                    handleCompanySelect={handleCompanySelect}
                                    currentCompany={true}
                                />
                            ))}
                    <div className="oldCompany">
                        <h2>Previous Company</h2>
                    </div>
                    {sortedData &&
                        sortedData
                            .filter(
                                (companies, i) => !companies.is_current_company
                            )
                            .map((company, i) => (
                                <CurrentCompany
                                    key={i}
                                    selectedCompanyId={selectedCompanyId}
                                    company={company}
                                    handleCompanySelect={handleCompanySelect}
                                    currentCompany={false}
                                />
                            ))}
                </>
            ) : (
                <></>
            )}
        </SalarySidebarStyle>
    );
};

export default SalarySidebar;
