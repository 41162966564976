import React from "react";
import {StyledEditor} from "./Editor.styled";
import {WrapperStyledForHeading} from "../InformationComponent";
import styled from "styled-components";
import CreateArticleComponent from "../CreateArticleComponent";

const Editor = ({content,handleContentChange}) => {

  return (
    <StyledEditor>
      <WrapperStyledForHeading>
        {" "}
        <div className="title">Article Content</div>
        <div className="editorWrapper">

     
        <EditorStyleWrapper>
          <label for="Short" className="labelForArticleInput" >Title  ({content?.title?.length}/70)</label>
          <textarea type="text" className="inputElement" id="Short"
          value={content?.title}
                onChange={(e)=>
                  handleContentChange(e.target.value,'title')
                }
                maxLength={70}
          />
        </EditorStyleWrapper>
        <EditorStyleWrapper>
          <label for="Short">Brief ({content?.summary?.length}/150) </label>
          <textarea type="text" className="inputElement" id="Short"
               value={content?.summary}
          onChange={(e)=>
            handleContentChange(e.target.value,'summary')
          }
          maxLength={150}
          />
        </EditorStyleWrapper>
        <EditorStyleWrapper>
          <label for="Short">Body</label>
          <CreateArticleComponent content={content?.payload} handleContentChange={handleContentChange} />
        </EditorStyleWrapper>   </div>
      </WrapperStyledForHeading>
    </StyledEditor>
  );
};

const EditorStyleWrapper = styled.div`
  .labelForArticleInput {
    color: #364152;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    box-sizing:border-box;
  }
  .inputElement {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    width: 100%;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #cdd5df;
    background: #fff;
    box-sizing: border-box;
    margin-top: 6px;
  }
 
 
`;

export default Editor;
