import styled from "styled-components";
import { dim, theme } from "../../../../../styles/theme";

const SelectActionableItemsStyle = styled.div`
  padding: ${dim._16px} ${dim._24};
  width: 100%;
  border-radius: 4.26875px;
  border: 1.0671875px solid ${theme.color.white_60};
  background: ${theme.color.white_0};
  margin-top: ${dim._8px};
  box-sizing: border-box;
  position: relative;
`;
export { SelectActionableItemsStyle };
