import styled from "styled-components";
import { colors, Theme } from "../../../../styles/colors";
import { dim } from "../../../../styles/theme";

export const ChatPreviewStyled = styled.div`
  width: 97%;
  padding: ${dim._16px};
  position: relative;
  transition: background-color 0.3s ease;
  border-radius: ${({ active }) => (active ? "0 4px 4px 0" : "0")};
  background-color: ${({ active }) => (active ? "#fff" : "#f1f3f6")};
  border-top: 1px solid ${({ active }) => (active ? "none" : "#e5e5e5")};
  margin: ${({ active }) => (active ? "0 0 0 2px" : "0")};
  cursor: pointer;
  box-sizing: border-box;

  .session_info {
    display: flex;
    flex-direction: column;
    gap: ${dim._6px};
  }

  .flexClass {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: ${dim._4px};
  }

  .query_date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  .type {
    margin: 0;
    padding: 0;
    color: ${Theme.color.black_20};
    font-size: ${dim._12px};
    font-style: normal;
    text-transform: capitalize;
    font-weight: 600;
    line-height: normal;
  }

  .time {
    margin: 0;
    padding: 0;
    color: ${Theme.color.black_20};
    text-align: right;
    font-size: ${dim._12px};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
  }

  .purpose {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .purpose > p {
    font-size: ${dim._12px};
    color: ${Theme.color.black_20};
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  .name {
    font-weight: 500;
    font-size: ${dim._12px};
    line-height: ${dim._20px};
    color: ${Theme.color.black_20};
    text-transform: capitalize;
  }

  .session_status {
    position: absolute;
    top: ${dim._4px};
    right: ${dim._4px};
    font-size: ${dim._10px};
    font-weight: 600;
    color: ${(props) => (props.progress ? "#15be53" : " #ff0808b5")};
    text-align: center;
    border-radius: ${dim._4px};
    padding: ${dim._2px} ${dim._4px};
  }

  .viewChat {
    font-size: ${dim._12px};
    cursor: pointer;
    color: ${colors.secondaryCTA};
  }

  .attachmenticon {
    display: block;
    position: absolute;
    bottom: ${dim._4px};
    right: ${dim._4px};
    width: ${dim._18px};
    min-width: ${dim._18px};
    height: ${dim._18px};
    color: ${colors.secondaryCTA};
  }
`;
