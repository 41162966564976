import styled from "styled-components";
import { dim, theme } from "../../../../../styles/theme";

export const StyledCurrentCompany = styled.div`
    width: 100%;
    background-color: ${({ active }) =>
        active ? theme.color.greyBlue_10 : theme.color.white_0};
    border-radius: ${dim._8px};
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid
        ${({ active }) =>
            active ? theme.color.greyBlue_20 : theme.color.grey_20};
    margin-bottom: ${dim._16px};
    padding: ${dim._16px};

    .top-section {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        margin-bottom: ${dim._12px};
        margin: 0;
    }

    .logo {
        height: ${dim._40px};
        background-color: ${({ active }) =>
            active ? theme.color.greyBlue_20 : theme.color.white_40};
        border-radius: ${dim._4px};
    }

    .name {
        margin-left: ${dim._16px};
    }

    .heading {
        font-size: ${dim._14px};
        line-height: ${dim._18px};
        padding: 0;
        margin: 0;
        text-transform: capitalize;
    }

    .sub-heading {
        font-size: ${dim._12px};
        color: ${theme.color.grey_15};
        margin: 0;
    }

    .duration {
        margin: ${dim._16px};
        font-size: ${dim._10px};
        color: ${theme.color.grey_15};
    }

    .icon {
        margin-left: ${dim._26px};
        color: ${theme.color.grey_30};
    }

    .align-center {
        align-items: center;
    }

    .align-right {
        justify-content: center;
        text-decoration: underline;
        text-decoration-color: ${theme.color.green_20};
        margin-bottom: 0;
    }
`;
