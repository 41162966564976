import styled from "styled-components";

export const StyledActionFilter = styled.div`
  display: flex;
  margin: 16px 0;
  gap: 8px;
  align-items: center;
  box-sizing: border-box;

  .dropdown {
    border-radius: 2px;
    background-color: #ccc;
    box-sizing: border-box;
  }
`;
