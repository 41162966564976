const defaultData = [
	{
		external_id: '',
		label: '',
		sub_type: 'STCG_DEBT',
		amount_invested: 0,
		is_sold: false,
		selling_amount: 0,
		document_link: '',
		created_at: new Date().getTime(),
		updated_at: new Date().getTime()
	},
	{
		external_id: '',
		label: '',
		sub_type: 'STCG_EQUITY',
		amount_invested: 0,
		is_sold: false,
		selling_amount: 0,
		document_link: '',
		created_at: new Date().getTime(),
		updated_at: new Date().getTime()
	},
	{
		external_id: '',
		label: '',
		sub_type: 'LTCG_DEBT',
		amount_invested: 0,
		is_sold: false,
		selling_amount: 0,
		document_link: '',
		created_at: new Date().getTime(),
		updated_at: new Date().getTime()
	},
	{
		external_id: '',
		label: '',
		sub_type: 'LTCG_EQUITY',
		amount_invested: 0,
		is_sold: false,
		selling_amount: 0,
		document_link: '',
		created_at: new Date().getTime(),
		updated_at: new Date().getTime()
	}
];

export const getSummarizedData = (data) => {
	const updatedCapitalGain =
		data &&
		data.reduce((acc, current) => {
			const existingSubTypeIndex = acc.findIndex(
				(item) => item.sub_type === current.sub_type
			);
			if (existingSubTypeIndex !== -1) {
				acc[existingSubTypeIndex].selling_amount += current.selling_amount;
				acc[existingSubTypeIndex].amount_invested += current.amount_invested;
			} else {
				acc.push(current);
			}
			return acc;
		}, []);
	return updatedCapitalGain;
};

export const initializeDefaultData = (data) => {
	const res = defaultData.map((it) => {
		const obj = data && data.find((ele) => ele.sub_type === it.sub_type);
		return obj !== undefined ? obj : it;
	});
	return res;
};
