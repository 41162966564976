import styled from "styled-components";
import {StyledFlexWrapper} from "../../components/ActionWidget/ActionWidget.styled";
import {dim, theme} from "../../../../styles/theme";

export const StyledConsultationView = styled.div``;
export const StyledWidgetWrapper = styled(StyledFlexWrapper)`
  gap: ${dim._26px};
  padding-bottom: ${dim._26px};
`;
export const StyledActionCta = styled(StyledFlexWrapper)`
  color: ${({color}) => color || theme.color.green_20};
  gap: ${dim._8px};
  padding: ${dim._8px};
  border: ${({color}) => "1px solid " + color || theme.color.green_20 + "33"};
  border-radius: ${dim._4px};
  height: fit-content;
  font-size: ${dim._12px};
  font-weight: 600;
  line-height: ${dim._16px};
  cursor: pointer;
  opacity: ${({disabled}) => {
    return disabled ? "0.3" : "1";
  }};
`;
export const StyledClientEmailName = styled(StyledFlexWrapper)`
  gap: ${dim._8px};
`;
export const TableWrapper = styled.div`
  display: flex;
  background-color: ${theme.color.white_0};
  flex-direction: column;
  border-radius: ${dim._10px};
  box-shadow: 0px ${dim._4px} ${dim._12px} -${dim._2px} #10182814;
  overflow: scroll;
  .ant-table
    .ant-table-container
    .ant-table-content
    table
    thead.ant-table-thead
    .ant-table-cell {
    background-color: #635bef1a;
    border-radius: 0;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  background-color: white;
  border-radius: ${dim._10px};
  padding: ${dim._24px};
  gap: ${dim._24px};
  align-items: center;
  .title {
    font-size: ${dim._16px};
    font-weight: 600;
    line-height: ${dim._20px};
    text-align: left;
  }
`;
