import styled from "styled-components";
import Styled from "styled-components";


export const Select= Styled.input`

/* width: 100%; */
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    height: 28px;
    cursor: text;
    font-size: 15px;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;`

    const StyledTimeSlot=styled.div`
    .purpose{
        display: flex;
    align-items: center;
    gap: 4px;
        .dropdown{
            width: 300px;
    position: relative;
    box-sizing: border-box; 
        }
    }

    
    `
    export { StyledTimeSlot};