import React from "react";
import { SELECTEDTABVALUE } from "../../config";
import ChatScreen from "../ChatScreen";
import SessionDocument from "../SessionDocument";

const ScreenConverter = (props) => {
    const {
        selectedTab,
        advisorId,
        selectedChat,
        attachment,
        handleAttachment,
        userDetails,
        userId,
        name,
    } = props;

    if (selectedTab === SELECTEDTABVALUE.chat) {
        return (
            <ChatScreen
                advisorId={advisorId}
                selectedChat={selectedChat}
                handleAttachment={handleAttachment}
                attachment={attachment}
                userDetails={userDetails}
                userId={userId}
                name={name}
            />
        );
    }
    if (selectedTab === SELECTEDTABVALUE.document) {
        return (
            <SessionDocument
                advisorId={advisorId}
                name={name}
                userId={userId}
            />
        );
    }
};

export { ScreenConverter };
