import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { UploadAdvDocFormStyled } from "../../../../commonComponents/UploadAdvDocForm/UploadAdvDocForm.styled";
import { uploadExemption } from "../../../../Services/Exemption";
import PopUPCms from "../../../../commonComponents/PopUPCms";
import PopUpForDocApproval from "../../../../components/PopUpForDocApproval";
import Backdrop from "../../../../commonComponents/PopUPCms/BackDrop";
import { useSelector } from "react-redux";
import { Chip } from "@mui/material";

const UploadEXEMPTIONForm = ({ handleCross, userId, docArr, updateDocArr }) => {
  const [documentObj, setDocumentObj] = useState({
    total_amount: "",
    exempted_amount: "",
    limit: "",
    details: "",
    type: "ALLOWANCE",
  });

  const [show, setShow] = useState(false);
  const fyObj= useSelector(state=>state.LayoutReducer.F_Y_OBJ);
  const FYOptions= useSelector(state=>state.LayoutReducer.F_Y_ARR)
      const [selectedFy, setSelectedFy] = useState({})

      useEffect(() => {
          handleFYChange({
            label:fyObj.financialYear,
            value:fyObj.financialYearId
          })
        }, [fyObj])

        const handleFYChange=(value)=>{
          

          setSelectedFy(value)
      }


  const handleCross2 = () => {
    setShow(false);
  };
  const onHideModelClick = (action) => {
    setShow(true);
  };

  let userName = localStorage.getItem("user");
  const handleSubmit = () => {
    onHideModelClick("APPROVE");
  };
  const handleClicksub = () => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          uploadExemption(data, [
            {
              user_id: userId,
              financial_year_id: selectedFy.value,
              type: documentObj.type,
              total_amount: documentObj.total_amount,
              exempted_amount: documentObj.exempted_amount,
              limit: documentObj.limit,
              details: documentObj.details,
              uploaded_by: JSON.parse(userName).displayName,
            },
          ]).then((data) => {
            let arr = docArr;
            arr = [...arr, ...data];
            updateDocArr(arr);
          });

          handleCross();
        });
      }
    });
  };

  return (
    <UploadAdvDocFormStyled
      disable={
        documentObj.total_amount.length > 1 &&
        documentObj.exempted_amount.length > 1 &&
        documentObj.details.length > 1 &&
        documentObj.limit.length > 1 &&
        parseInt(documentObj.exempted_amount) <= parseInt(documentObj.limit)
      }
    >
      <span className="closePop" onClick={handleCross}>
        &times;
      </span>
      <div className="popContent">
        <div className="" style={{ display: "flex" }}>
          <b className="popTitle"> Upload EXEMPTION </b>
        </div>
        <div className="inputdiv">
          <p className="label">Exemption Type :</p>
          <select
            className="inputUploadText details"
            name=""
            id=""
            value={documentObj.type}
            onChange={(e) =>
              setDocumentObj({ ...documentObj, type: e.target.value })
            }
          >
            <option value="ALLOWANCE">Allowance</option>
            <option value="HRA">HRA</option>
            <option value="LTA">LTA</option>
            <option value="PROFESSIONAL_TAX">Professional Tax</option>
            <option value="FOOD_ALLOWANCE"> Food Allowance</option>
            <option value="COMMUNICATION_ALLOWANCE"> Communication Allowance</option>
            <option value="LEAVE_ENCASHMENT"> Leave Encashment</option>
            <option value="OTHERS">Others</option>
          </select>
        </div>
        <div className="inputdiv">
          <p className="label">Total Amount :</p>
          <input
            type="number"
            className="inputUploadText name"
            placeholder="Enter Total Amount "
            name=""
            id=""
            value={documentObj.total_amount}
            onChange={(e) => {
              setDocumentObj({ ...documentObj, total_amount: e.target.value });
            }}
          />
        </div>
        <div className="inputdiv">
          <p className="label">Exempted Amount:</p>
          <input
            type="number"
            className="inputUploadText details"
            placeholder="Enter Exempted Amount"
            name=""
            id=""
            value={documentObj.exempted_amount}
            onChange={(e) => {
              setDocumentObj({
                ...documentObj,
                exempted_amount: e.target.value,
              });
            }}
          />
        </div>
        <div className="inputdiv">
          <p className="label">Exemption Limit :</p>
          <input
            type="number"
            className="inputUploadText details"
            placeholder="Enter Exemption Limit"
            name=""
            id=""
            value={documentObj.limit}
            onChange={(e) => {
              setDocumentObj({ ...documentObj, limit: e.target.value });
            }}
          />
        </div>{" "}
        {documentObj.limit.length !== 0 &&
          parseInt(documentObj.exempted_amount) >
            parseInt(documentObj.limit) && (
            <span
              style={{ fontSize: "11px", color: "red", textAlign: "center" }}
            >
              {" "}
              Exemption Amount  can't be greater than Exempted Limit{" "}
            </span>
          )}
        <div className="inputdiv">
          <p className="label">Exemption details:</p>
          <input
            type="text"
            className="inputUploadText details"
            placeholder="Enter Exemption details"
            name=""
            id=""
            value={documentObj.details}
            onChange={(e) => {
              setDocumentObj({ ...documentObj, details: e.target.value });
            }}
          />
        </div>
        <div className="inputdiv">
                    <p className="label">Financial Year :</p>
                    <select
                        className="inputUploadText details"
                        name=""
                        id=""
                        value={selectedFy.value}
                        onChange={(e) =>
                          { 
                            handleFYChange(FYOptions.find((d,i)=>{
                                return  e.target.value===d.value;
                                                          }) )}
                        }
                    >
                        {FYOptions.map((data,i)=>{

if(data.current){

    return  <option 
    style={{backgroundColor:'#15BE53',color:'#fff'}}
    key={i}
    value={data.value}>{data.label} &nbsp; (Current)
    
    
    </option>
}else{
    return  <option 
    key={i}
    value={data.value}>{`${data.label}`}
    
   
    </option> 
}

})}
                       
                    </select>
                </div>
        <div className="input">
          <label
            className="button"
            onClick={() => {
              documentObj.total_amount.length > 1 &&
                documentObj.exempted_amount.length > 1 &&
                documentObj.details.length > 1 &&
                documentObj.limit.length > 1 &&
                parseInt(documentObj.exempted_amount) <=
                  parseInt(documentObj.limit) &&
                handleSubmit();
            }}
          >
            Submit
          </label>
        </div>
        <PopUPCms show={show} handleCross={handleCross2}>
          <PopUpForDocApproval
            handleCross={handleCross2}
            btn={<b>Submit</b>}
            msg={"Do You want to submit this to user it will reflect to user"}
            //   docArr={docArr}
            handleClick2={() => handleClicksub()}
          />
        </PopUPCms>
        <Backdrop onHideModelClick={handleCross2} show={show} />
      </div>{" "}
    </UploadAdvDocFormStyled>
  );
};

export default UploadEXEMPTIONForm;
