import React from 'react';
import { TableHeadingStyle } from './StyledTableHeading';

const TableHeading = ({ componentName }) => {
	return (
		<TableHeadingStyle>
			<div>{componentName}</div>
			<div>Monthly amount</div>
		</TableHeadingStyle>
	);
};

export default TableHeading;
