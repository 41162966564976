import styled from 'styled-components';
import { dim } from '../../styles/theme';

export const StyledInputDateWithOutLabel = styled.input`
	width: 100%;
	height: 32px;
	margin: 0;
	padding: 4px;
	border-radius: 4px;
	border: 1px solid #cccccc;
	box-sizing: border-box;
`;
