import { actions } from "./action";

const initialState = {
    session: [],
    sessionMsg: [],
    usersInSession: {},
    sessionId: null,
    showServiceModal: false,
    notification: {},
    advisorId: null,
    selectedChat:0
};

const ChatReducer = (state = initialState, payload = {}) => {
    switch (payload.type) {
        case actions.SET_SESSION_FOR_USER:
            if (payload.data.isPolling && state.sessionId != null) {
                return {
                    ...state,
                    session: payload.data.res,
                    sessionId: state.sessionId,
                };
            } else {
                return {
                    ...state,
                    session: payload.data.res,
                    sessionId: payload.data[0]
                        ? payload.data[0].external_id
                        : "",
                };
            }

        case actions.SET_POST_SESSION_FOR_USER:
            return {
                ...state,
                session: [payload.data, ...state.session],
                sessionId: payload.data.external_id,
            };
        case actions.SET_POST_MESSAGE_FOR_USER:
            return {
                ...state,
                sessionMsg: [...state.sessionMsg, payload.data],
            };
        case actions.SET_SELECTED_CHAT:
            return {
                ...state,
                selectedChat:  payload?.data,
            };
        case actions.UPDATE_NOTIFICATION:
            return {
                ...state,
                notification: payload.data,
            };
        case actions.CHANGE_SHOW_SERVICE_MODAL:
            return {
                ...state,
                showServiceModal: !state.showServiceModal,
            };
        case actions.CHANGE_SELECTED_ADVISOR_ID:
            return {
                ...state,
                advisorId: payload.data,
            };
        case actions.SET_SESSION_MESSAGE_FOR_USER:
            return {
                ...state,
                sessionMsg:
                    payload.data.data &&
                    payload.data.data.discussion_message_response !== undefined
                        ? [...payload.data.data.discussion_message_response]
                        : [],
                usersInSession: payload.data.data.user_details,
                sessionId:
                    payload.data.data !== undefined ? payload.data.id : "",
            };
        default:
            return state;
    }
};

export default ChatReducer;
