import React from "react";
import { StyledCheckBoxComponent } from "./CheckBoxComponent.styled";
import FormErrorMsgHandler from "../../../../commonComponents/FormErrormsgHandler";
import { ERROR_MESSAGE } from "../../constant";

const CheckBoxComponent = ({
  title,
  radioArrData,
  handleRadioChange,
  isError,
  componentCategory,
}) => {
  return (
    <StyledCheckBoxComponent>
      <div className="title">{title}</div>
      <div className="radioModel">
        {radioArrData?.map((data, index) => {
          return (
            <label className="container" key={index}>
              {data.text}
              <input
                type="checkbox"
                value={data.text}
                checked={data.isSelected}
                onChange={() => {
                  handleRadioChange(data.value);
                }}
              />
              <span className="checkmark"></span>
            </label>
          );
        })}
      </div>
      {isError?.error && isError.errorArr.includes(componentCategory) && (
        <FormErrorMsgHandler style={{ bottom: "-32px" }}>
          {" "}
          {ERROR_MESSAGE[componentCategory]}
        </FormErrorMsgHandler>
      )}
    </StyledCheckBoxComponent>
  );
};

export default CheckBoxComponent;
