import React, { Component } from "react";
import moment from "moment";
import TimePicker from "../TimePicker";

class TimeSelectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
    this.props.Time(null);
  }

  handleChange = (value) => {
    this.setState({ value });
    this.props.Time(moment(value).format("HH:mm A"));
    // getDateFromFormat("2011-03-29 17:06:21","yyyy-MM-dd HH:mm:ss")
  };

  render() {
    if (this.props.removeTime) {
      this.setState({
        value: "",
      });

      this.props.RemoveTime(false);
    }

    return <TimePicker value={this.state.value} onChange={this.handleChange} />;
  }
}

export default TimeSelectForm;
