import React from "react";
import {BiEdit} from "react-icons/bi";

const EditBtn = ({HandleClick, d}) => {
  return (
    <span
      style={{
        cursor: "pointer",

        top: "2px",

        position: "relative",
        left: "5px",
        boxSizing: "boarder-Box",
      }}
      onClick={() => {
        HandleClick();
      }}
    >
      <BiEdit />
    </span>
  );
};

export default EditBtn;
