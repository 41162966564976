import styled from "styled-components";
import {dim} from "../../../../styles/theme";
import {StyledFlexWrapper} from "../ActionWidget/ActionWidget.styled";

export const StyledConsultationBar = styled(StyledFlexWrapper)`
  padding: ${dim._8px} ${dim._14px};
  background: #f4f4f4;
  border-radius: ${dim._8px};
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;

  .callDetails {
    display: flex;
    flex-direction: column;
    gap: ${dim._4px};
    color: #425466;
  }
  .callTitle {
    font-size: ${dim._12px};
    font-weight: 400;
    line-height: ${dim._16px};
  }
  .callTime {
    font-size: ${dim._14px};
    font-weight: 600;
    line-height: ${dim._18px};
  }
`;
