import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import SuperSaverDiscussionBookService from "../../Services/SuperSaverClientChat";
import { ClientChatStyled, LoaderWrapper } from "./ClientChatStyled";
import Header from "./Components/Header";
import { ScreenConverter } from "./Components/ScreenConverter";
import { ClientTabsData } from "./constant";
import { actions, getSession, getSessionMessage } from "./store/action";
import LayoutHOC from "../../components/UserSideBar";
import ChatHeader from "./Components/ChatHeader";
import ChatStartModal from "./Components/ChatStartModal/index";
import SessionCloseModal from "./Components/SessionCloseModal";
import { Loader2 } from "../../commonComponents/Loader"; 
import toast from "react-hot-toast";

const ClientChat = ({ userDetails, activeChatDetails = null }) => {
  const params = useParams();
  const advisorId = params["id"];
  const POLLING_INTERVAL = 10000;
  const state = useLocation();
  const timerRef = useRef(null);
  const notificationRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(ClientTabsData[0].id);

  const [attachment, setAttachment] = useState([]);
  const [getCount, setGetCount] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const sessionDetails = useSelector((state) => state.chat.session);
  const notification = useSelector((state) => state.chat.notification);
  const selectedChat = useSelector((state) => state.chat.selectedChat);
  const showServiceModal = useSelector((state) => state.chat.showServiceModal);
  const [showCloseSessionModal, setCloseSessionModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleShowCloseSession = () => {
    setCloseSessionModal(true);
  };
  const handleHideCloseSession = () => {
    setCloseSessionModal(false);
  };

  const onHideServiceRequestModal = () => {
    dispatch({ type: actions.CHANGE_SHOW_SERVICE_MODAL });
  };
  // create an object comprising of all required data
  const requiredInfo = {
    userId: Object.is(state?.state, null)
      ? activeChatDetails?.user_details?.external_id
      : state?.state?.user_id,
    advisorId: Object.is(state.state, null)
      ? activeChatDetails?.advisor_details?.external_id
      : state?.state?.advisor_details?.external_id,
    name: Object.is(state?.state, null)
      ? activeChatDetails?.user_details?.first_name
      : state.state.name,
    userDetails: Object.is(state?.state, null)
      ? activeChatDetails?.user_details
      : {
          first_name: state?.state?.name,
          external_id: state?.state?.user_id,
          email: state?.state?.email,
          mobile: state?.state?.mobile,
        },
    advisorDetails: Object.is(state?.state, null)
      ? activeChatDetails?.advisor_details
      : state?.state?.advisor_details,
  };

  const dispatch = useDispatch();
  const __handleSelectTab = (event) => {
    const { id } = event.target;
    setSelectedTab(id);
  };

  const pollNotification = async () => {
    const details_ =
      await SuperSaverDiscussionBookService.getSessionNotification({
        userId: requiredInfo?.userDetails?.external_id,
        receiverId: advisorId || requiredInfo?.advisorDetails?.external_id,
      });

    dispatch({
      type: actions.UPDATE_NOTIFICATION,
      data: details_,
    });
    notificationRef.current = setTimeout(() => {
      pollNotification();
    }, POLLING_INTERVAL);
  };

  const handleSelectChat = (chatNo) => {
    dispatch({
      type: actions.SET_SELECTED_CHAT,
      data: chatNo,
    });
  };

  const isSessionIdExists = (sessions, sessionId) => {
    return sessions?.some((session) => session?.session_id === sessionId);
  };

  const showLoading = () => setShowLoader(true);
  const hideLoading = () => setShowLoader(false);

  const reloadNotification = async () => {
    const details =
      await SuperSaverDiscussionBookService.getSessionNotification({
        userId: requiredInfo?.userDetails?.external_id,
        receiverId: requiredInfo?.advisorId,
      });
    dispatch({
      type: actions.UPDATE_NOTIFICATION,
      data: details,
    });
  };

  const markAsRead = async (session_id) => {
    await SuperSaverDiscussionBookService.markMessageAsRead({
      requestId: requiredInfo?.advisorId,
      sessionId: session_id,
      isRead: true,
    });
    await reloadNotification();
  };

  const handleSelectedChat = async (value, session_id) => {
    if (sessionId !== session_id) {
      setShowLoader(true);
      try {
        setSessionId(session_id);
        handleSelectChat(value);

        await dispatch(
          getSessionMessage({
            receiverId: requiredInfo?.advisorDetails?.external_id,
            sessionId: session_id,
          })
        );

        if (isSessionIdExists(notification?.unread_messages, session_id)) {
          await markAsRead(session_id);
        }
      } catch (error) {
        toast.error("Failed to load chat messages. Please try again.");
      } finally {
        setTimeout(() => {
          setShowLoader(false);
        }, 500);
      }
    }
  };

  const handleAttachment = (value) => {
    setAttachment(value);
  };

  useEffect(() => {
    if (sessionId == null || selectedChat === 0) {
      const id =
        sessionDetails[0] !== undefined ? sessionDetails[0]?.external_id : null;
      setSessionId(id);
    }
  }, [sessionDetails]);

  useEffect(() => {
    requiredInfo?.userDetails?.external_id && dispatchGetSessionMessage();
  }, [activeChatDetails, getCount, sessionId]);

  const dispatchGetSessionMessage = () => {
    dispatch(
      getSessionMessage({
        receiverId: requiredInfo?.userDetails?.external_id,
        sessionId: sessionId,
      })
    );
    // clear timeout before creating new every time
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setGetCount((prev) => prev + 1);
    }, 10000);
  };

  useEffect(() => {
    (async () => await pollNotification())();
    dispatch(getSession(requiredInfo?.userDetails?.external_id));
  }, [requiredInfo?.userDetails?.external_id]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(notificationRef.current);
    };
  }, []);

  useEffect(() => {
    dispatch(getSession(requiredInfo?.userDetails?.external_id, true));
  }, [notification]);

  return (
    <ClientChatStyled key={requiredInfo?.userDetails?.external_id}>
      {showLoader && (
        <LoaderWrapper>
          <Loader2 />
        </LoaderWrapper>
      )}
      <Header
        tabsData={ClientTabsData}
        selectedTab={selectedTab}
        handleSelectTab={__handleSelectTab}
        advisorId={requiredInfo?.advisorId}
        selectedChat={selectedChat}
        setselectedChat={handleSelectChat}
        handleSelectedChat={handleSelectedChat}
        SessionDetails={sessionDetails}
        userId={requiredInfo?.userDetails?.external_id}
      />
      <div className="chat_conversation_info">
        <ChatHeader
          onHideServiceRequestModal={onHideServiceRequestModal}
          handleShowCloseSession={handleShowCloseSession}
        />
        <ScreenConverter
          selectedTab={selectedTab}
          selectedChat={selectedChat}
          advisorId={requiredInfo?.advisorId}
          userId={requiredInfo?.userDetails?.external_id}
          userDetails={userDetails}
          attachment={attachment}
          handleSelectedChat={handleSelectedChat}
          handleAttachment={handleAttachment}
          name={requiredInfo?.userDetails?.first_name}
        />
      </div>
      <ChatStartModal
        isModalOpen={showServiceModal}
        cancelModal={onHideServiceRequestModal}
        setselectedChat={handleSelectChat}
        userId={requiredInfo?.userDetails?.external_id}
        advisorId={requiredInfo?.advisorId}
        showLoading={showLoading}
        hideLoading={hideLoading}
      />

      <SessionCloseModal
        isModalOpen={showCloseSessionModal}
        cancelModal={handleHideCloseSession}
        selectedChat={selectedChat}
        advisorId={requiredInfo?.advisorId}
        showLoading={showLoading}
        hideLoading={hideLoading}
      />
    </ClientChatStyled>
  );
};

export default LayoutHOC(ClientChat);
