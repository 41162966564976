import { put, takeEvery } from "redux-saga/effects";
import SuperSaverDiscussionBookService from "../../../Services/SuperSaverClientChat";
import { actions } from "./action";
import toast from "react-hot-toast";

function* getAllSession(userdata) {
  try {
    const res = yield SuperSaverDiscussionBookService.getSession(userdata.data);
    yield put({
      type: actions.SET_SESSION_FOR_USER,
      data: { res, isPolling: userdata.isPolling },
    });
    return res;
  } catch (e) {
    toast.error(e);
  }
}

function* postAllSession(req) {
  try {
    const res = yield SuperSaverDiscussionBookService.postSession(
      req.adv,
      req.data
    );
    yield put({ type: actions.SET_POST_SESSION_FOR_USER, data: res });
    yield put({
      type: actions.POST_MESSAGE_FOR_USER,
      data: {
        session_id: res?.external_id,
        receiver_id: req.adv,
        sender_id: res?.initiated_by,
        message: {
          message: req?.data?.subject,
          attachments: [],
        },
        attachment: "NO",
        label: "NORMAL",
      },
    });
    yield put({
      type: actions.GET_SESSION_MESSAGE_FOR_USER,
      data: {
        receiverId: req.data.user_id,
        sessionId: res.external_id,
      },
    });
    return res;
  } catch (e) {
    toast.error(e);
  }
}

function* postMessage(req) {
  try {
    const res = yield SuperSaverDiscussionBookService.postMessages(req.data);
    yield put({ type: actions.SET_POST_MESSAGE_FOR_USER, data: res });
    return res;
  } catch (e) {
    toast.error(e);
  }
}
function* getSessionMessage(req) {
  try {
    const res = yield SuperSaverDiscussionBookService.getMessages(req.data);

    yield put({
      type: actions.SET_SESSION_MESSAGE_FOR_USER,
      data: { data: res, id: req.data.sessionId },
    });
    return res;
  } catch (e) {
    toast.error(e);
  }
}

export function* watchgetAllSession() {
  yield takeEvery(actions.GET_SESSION_FOR_USER, getAllSession);
  yield takeEvery(actions.POST_SESSION_FOR_USER, postAllSession);
  yield takeEvery(actions.POST_MESSAGE_FOR_USER, postMessage);
  yield takeEvery(actions.GET_SESSION_MESSAGE_FOR_USER, getSessionMessage);
}
