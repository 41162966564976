import React, { useState } from "react";
import { OtherCategoryStyle } from "./Other.styled";
import { useLocation, useNavigate } from "react-router-dom";
import Backdrop from "../../../../commonComponents/backdrop";

const OtherCategory = ({
  otherCategories,
  activeCategory,
  active,
  otherActive,
  otherCategoryKey,
  handleIncomeCategory,
  data,
  userId,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [otherCategory, setOtherCategory] = useState(otherCategoryKey);

  const getActiveType = (category) => {
    if (category === "DIGITAL_ASSET") {
      return "Digital Asset";
    } else if (category === "INCOME_FROM_HOUSE_RENT") {
      return "House Rent Income";
    }
  };

  return (
    <OtherCategoryStyle
      id={otherCategoryKey}
      otherActive={otherActive}
      active={
        activeCategory === "DIGITAL_ASSET" ||
        activeCategory === "INCOME_FROM_HOUSE_RENT"
      }
      onClick={(e) => {
        handleIncomeCategory(e);
        setShowDropDown(!showDropDown);
      }}
    >
      {active || otherActive ? (
        <img
          id={otherCategoryKey}
          src={
            process.env.PUBLIC_URL +
            "/assets/SuperSaver/Income/Categories/salary_act.svg"
          }
          alt=""
        />
      ) : (
        <img
          id={otherCategoryKey}
          src={
            process.env.PUBLIC_URL +
            "/assets/SuperSaver/Income/Categories/salary.svg"
          }
          alt=""
        />
      )}
      <p id={otherCategoryKey} className="otherLabel">
        {activeCategory === "INCOME_FROM_HOUSE_RENT" ||
        activeCategory === "DIGITAL_ASSET"
          ? getActiveType(activeCategory)
          : "More Income"}
      </p>
      {active ? (
        <img
          id={otherCategoryKey}
          src={
            process.env.PUBLIC_URL +
            "/assets/SuperSaver/Income/Icons/drop_down_act.svg"
          }
          className="dropDown"
          alt=""
          style={{ transform: "rotate(180deg)" }}
        />
      ) : (
        <img
          id={otherCategoryKey}
          src={
            process.env.PUBLIC_URL +
            "/assets/SuperSaver/Income/Icons/drop_down.svg"
          }
          className="dropDown"
          alt=""
        />
      )}
      {showDropDown && (
        <div className="otherIncome">
          {otherCategories.map((others, i) => (
            <div
              className="otherCategories"
              key={i}
              id={others.category}
              activeCategory={activeCategory}
              onClick={(e) => {
                handleIncomeCategory(e);
                userId !== undefined
                  ? navigate(
                      `/${pathname.split("/")[1]}/incomeView/${
                        others.path
                      }/${userId}`,
                      {
                        state: {
                          ...data,
                          incomeType: others.path
                            .toUpperCase()
                            .replace(/-/g, "_"),
                        },
                      }
                    )
                  : navigate(
                      `/${pathname.split("/")[1]}/incomeView/${others.path}`,
                      {
                        state: data,
                      }
                    );
                setOtherCategory(others.category);
              }}
            >
              {others.category}
            </div>
          ))}
        </div>
      )}
      <Backdrop
        show={showDropDown}
        onHideModelClick={() => setShowDropDown(false)}
        backgroundColor={"transparent"}
      />
    </OtherCategoryStyle>
  );
};

export default OtherCategory;
