import BASE_URL from "./API_ENDPOINT";
import { getHeaders } from "./SuperSaverClientChat";

const getURLs = (advisorId, date, user_id) => {
    return {
        createEvent: BASE_URL + `appointments/v1/${user_id}/book-call`,
        cancelEvent: BASE_URL + "appointments/v1/cancel-event",
        getEvents:
            BASE_URL +
            `appointments/v1/get-event?advisorId=${advisorId}&start=${date}&end=${date}`,
    };
};

export const CalendarAPI = {
    createEvent: async (data) => {
        const response = await fetch(getURLs(null,null,data.user_id).createEvent, {
            method: "POST",
            headers: await getHeaders(),
            body: JSON.stringify(data),
        });
        return await response.json();
    },

    getEvents: async (advisorId, date) => {
        const response = await fetch(getURLs(advisorId, date).getEvents, {
            method: "GET",
            headers: await getHeaders(),
        });
        return await response.json();
    },

    cancelEvent: async (eventId, deletedBy) => {
        const response = await fetch(
            getURLs().cancelEvent +
                `?event_id=${eventId}&cancelled_by=${deletedBy}`,
            {
                method: "DELETE",
                headers: await getHeaders(),
            }
        );
        return response;
    },
};
