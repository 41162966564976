export const AllSalaryComponents = [
	'Basic Salary',
	'House Rent Allowance',
	'Dearness Allowance',
	'Conveyance Allowance',
	'Medical Allowance',
	'Special Allowance',
	'Bonus',
	'Gratuity',
	'Provident Fund (Employer Contribution)',
	'Provident Fund (Employee Contribution)',
	'Employee State Insurance',
	'Mobile/Telephone Allowance',
	'Internet Allowance',
	'Performance Bonus',
	'Sales Commission',
	'Incentives',
	'Overtime Pay',
	'Medical Insurance',
	'Car Allowance',
	'Fuel Allowance',
	'Vehicle Maintenance Allowance',
	'Other'
];
