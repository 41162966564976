import styled from "styled-components";

export const StyledInformationComponent=styled.div`
background:white;
// padding:20px 24px;
width:100%;
.actions {padding-top:16px;
  border-top  :1px solid #E3E8EF;
  width:100%;
  display:flex;
  gap:40px;
}
.articleSeoAndAuthorActions{
width:50%;
display:flex;
flex-direction:column;
gap:16px;
}
.actionWrapper{
    display:flex;
    align-items:center;
}
.actionTitle{
  display:flex;
  align-items:center;
  gap:8px;
    color: #364152;
min-width:196px;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
padding-right:14px;
svg{
  curser:pointer;
}
}
.select{
  width:250px;
}
`