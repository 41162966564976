import styled from "styled-components";

export const Wrapper = styled.div`
    box-sizing: border-box;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;

    .table{
        width : 100%;
    }
    
    .banner-wrapper img {
        border-radius: 12px;
        overflow: hidden;
    }

    .article-list {
        box-sizing: border-box;
        height: 80vh;
        width: 30%;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .active {
        background-color: #bccedc !important;
    }
    .green-border{
        border: 1px solid #33d450;
        background-color: rgba(230,255,238,0.3) !important;
    }

    .nav {
        position: sticky;
        display: flex;
        top: 0;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #e5e5e5;
        padding-bottom: 22px;
        margin-top: 12px;
    }

    .actions-buttons {
        display: flex;
        align-items: center;
    }

    p {
        margin: 0;
    }

    .article-item {
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 12px;
    padding: 16px;
    border-radius: 4px;
    width: 100%;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover {
        background-color: #f2f2f2 !important;
        transform: scale(1.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border: none !important;
        cursor: pointer;
    }
}

    .title {
        font-size: 14px;
        line-height: 20px;
        margin: 4px 0;
        font-weight: bold;
    }

    h2 {
        margin-top: 12px;
        font-size: 16px;
        font-weight: bold;
        color: #3a3a3a;
    }

    .summary {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 4px 0;
    }

    .article-preview {
        font-family: "Open Sans", sans-serif;
        box-sizing: border-box;
        margin: 0px 26px;
        width: 70%;
        line-height: 150%;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .thumbnail{
        width: 100%;
        object-fit: contain;
    }
    .loader-wrapper{
        width:100%;
        height:100vh;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .buttons-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .live{
        color: #33d450;
        border: 1px solid #33d450; border-radius: 5px;
        padding: 0px 5px;
    }
    .draft{
        color: white;
        background-color: #9bddff;
        border-radius: 5px;
        padding: 0px 5px 
    }
    .category-name{
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    text-align: left;
    color:#05387E;
    padding-bottom: 24px;
    }
    .create-btn-container{
        padding: 16px 16px 20px 16px;
        display: flex;
        align-items: center;
        gap:20px;
    }
    .create-btn-container .create-btn{
        padding: 10px 40px 10px 40px;
        background-color: #05387E;
        border-radius: 8px;
        color:white;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        border: none;
        cursor:pointer;

    }

    .ant-picker{
        padding: 8px;
        max-height: 50px;
        max-width: 250px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .top-buttons{
        display: flex;
        justify-content: space-between;
        background-color: white;
    }
    .date-picker-container{
       display: flex;
       align-items: center;
       justify-content: space-between;
       padding: 0 10px;
       width: 35%;
    }
   .next-btn-container{
    padding: 30px;
    display: flex;
    justify-content: flex-end;
   }
   .next-btn{
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    border: none;
    opacity: 0px;
    background-color: #05387E;
    color: white;
    cursor: pointer;
    }
    .back-btn{
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    margin-right: 16px;
    border: none;
    opacity: 0px;
    background-color: green;
    color: white;
    cursor: pointer;
    }
`;
