import styled from "styled-components";

export const PendingAdvisorDocStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 30px 0 0;
  padding: 0;
  /* border: 1px solid red; */
  box-sizing: border-box;

  .head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 48px;
    margin: 0;
    padding: 0 16px;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
  }

  .head p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #212f45;
  }

  .c1 {
    width: 20%;
    /* width: 156px; */
    /* border: 1px solid red; */
  }
  .c2 {
    width: 20%;
    /* width: 156px; */
    /* border: 1px solid red; */
  }

  .c3 {
    width: 20%;
    /* width: 150px; */
    /* border: 1px solid red; */
  }
  .c4 {
    width: 20%;
  }

  .c5 {
    width: auto;
    min-width: 40px;
  }

  .rows {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* height: 67px; */
    margin: 0 0 1px 0;
    padding: 15px 16px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .rows p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #425466;
    /* border: 1px solid red; */
  }

  .r1 {
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 34px;
    padding: 8px 16px;
    background: rgba(187, 187, 187, 0.16);
    border-radius: 110px; */
    /* border: 1px solid red; */
    /* text-align: center; */
    /* box-sizing: border-box; */
  }

  .r3 {
    font-size: 12px;
    text-decoration: underline;
  }
  .btnApprove {
    color: #4852b0;
    background: #fff;

    font-weight: 700;
    background: rgba(187, 187, 187, 0.16);
    border-radius: 110px;
    width: auto;
  }

  .r5 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .r4 p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66%;
    height: 32px;
    margin: 0 10px 0 0;
    padding: 2px 8px;
    font-size: 12px;
    color: #212121;
    border-radius: 12px;
    background-color: #e5e5e5;
    cursor: pointer;
  }

  .approve {
    background-color: green;
  }
`;
