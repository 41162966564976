import React from "react";
import { StyledCompletedSessionList } from "./CompletedSessionList.styled";
import PreviousCallDetails from "../../components/PreviousCallDetails";
import NoCallView from "../../components/NoCallView";

const CompletedSessionList = ({ consultationData }) => {
  if (consultationData?.completed_calls?.length <= 0) return <NoCallView />;
  return (
    <StyledCompletedSessionList>
      {consultationData?.completed_calls?.map((completedCall, index) => (
        <PreviousCallDetails
          callDetails={completedCall}
          key={index}
          index={index}
          sessionsIndex={index}
        />
      ))}
    </StyledCompletedSessionList>
  );
};

export default CompletedSessionList;
