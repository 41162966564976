import React, {useEffect, useState} from "react";
import {EditorState, convertToRaw, Modifier} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {htmlToDraftBlocks} from "../..";
import draftToHtml from "draftjs-to-html";
const CreateArticleComponent = ({content, handleContentChange}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    content && setEditorState(htmlToDraftBlocks(content));
    
  }, []);




  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const src = e.target.result;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
          "IMAGE",
          "IMMUTABLE",
          {src}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newContentState = Modifier.insertText(
          contentStateWithEntity,
          contentStateWithEntity.getSelectionAfter(),
          " ",
          null,
          entityKey
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "insert-characters"
        );
        resolve({data: {link: src}});
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="inputElement">
      <Editor
        editorState={editorState}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            alt: {present: true, mandatory: false},
          },
        }}
        onEditorStateChange={(e) => {
       
          handleContentChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent())),
            "payload"
          );

          setEditorState(e);
        }}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
    </div>
  );
};

export default CreateArticleComponent;
