import styled from "styled-components";

export const OnboardingDataStyled = styled.div`
  width: 100%;

  .clientBox {
    width: 250px;
    padding-bottom: 5px;
    border-bottom: 2px solid #808080;
    margin: 20px 34px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ClientHed {
    font-size: 14px;
    font-weight: 500;

    color: #222;
    margin: 5px;
  }

  .ClientWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    height: auto;
    box-sizing: border-box;
  }
  .data {
    font-size: 12px;
  }
  .ClientDetails {
    display: flex;
    gap: 10px;
  }
`;
