import styled from "styled-components";
import { dim } from "../../styles/theme";

export const StyledUserPlanDegradation=styled.div`

.planDetailsWrapper{
    display:flex;
    height:${dim._scale(545)};
    background:#fff;

}
.input {
    padding: 0 ${dim._scale(28)} 20px;
    margin:0 !important ;
}
`;