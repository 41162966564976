import styled from 'styled-components';
import { dim } from '../../../../styles/theme';

export const AttachmentStyle = styled.div`
	display: flex;

	.uploadIcon {
		cursor: pointer;
		margin: 0 ${dim._16px} 0 0;
	}

	.uploadIcon img {
		width: ${dim._32px};
		height: ${dim._32px};
	}
`;
