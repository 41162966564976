export const validateEmail = (email) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
};

export const validateMobile = (mobile) => {
  const pattern = /^[0]?[56789]\d{9}$/;
  return pattern.test(mobile);
};

export const validateName = (name) => {
  const pattern = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
  return pattern.test(name);
};

export const objectEmptyValidater = (obj) => {
  return Object.keys(obj).length !== 0;
};

export const isEmailValid = (str) => {
  return String(str)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isPanValid = (str = "") => {
  return str.match(/[A-Z]{5}\d{4}[A-Z]{1}/i);
};
export const objectEmptyValidator = (obj) => {
  return Object.keys(obj).length !== 0;
};
