import React, {forwardRef, useEffect, useState} from "react";
import {StyledUserPlanDegradation} from "./UserPlanDegradation.styled";
import Backdrop from "../Backdrop";
import styled from "styled-components";
import {dim} from "../../styles/theme";
import PlanCard from "../PlanCard";
import {PlanDetails} from "./constant";
import SuperSaverPlans from "../../Services/SuperSaverPlans";
import PaymentBreakdown from "../PaymentBreakdown";
import PopupBtn from "../../commonComponents/Popup/Popup";
import PopUpPayment from "../PopUpPayment";
import {useDispatch, useSelector} from "react-redux";
import {Actions} from "../../Routes/store/action";
import {LoaderIcon} from "react-hot-toast";
import Success from "../../commonComponents/Success";
import { capitalizeFirstLetter } from "../../utils/textUtil";
import { useLocation, useParams } from "react-router";
import PlanValueForm from "./PlanValueForm";
import { useQuery } from "../../utils/routeUtils";

const defaultTitle = `Super Saver Plans`;
const defaultSubTitle = `Get access to our curated plans which suits your requirement.`;
const UserPlanDegradation = ({
  toggleBackdrop,
  showPopup,
  paymentFormProps,
  category,
  userDetails,
  checked,
}) => {
  const params = useLocation();
  const userIdFromQueryParam = useQuery().get("user_id") || undefined;
  const userIdFromURL = params.userId;
  const userId = userIdFromURL !== undefined ? userIdFromURL : userIdFromQueryParam;
  const [planUpAndDegradationInfo, setPlanUpAndDegradationInfo] = useState({});
  const {planUpgradePopupSteps: step} = useSelector(
    (state) => state.cmsReducer
  );
  const dispatch = useDispatch();
  const handleSteps = (value) => {

    dispatch({
      type: Actions.SET_PLAN_UPGRADE_POPUP_STEPS,
      data: value || step + 1,
    });
  };
  const updatePlan = async (category_id) => {
    const body = {upgraded_plan_category_id: category_id};
    const res = await SuperSaverPlans.updatePlan(userId, body);
    const isPlanUpgraded = await res?.data?.is_plan_upgraded;
    if (isPlanUpgraded) {
      handleSteps(2);

      setPlanUpAndDegradationInfo(res?.data);
    } else {
      handleSteps(1);

      setPlanUpAndDegradationInfo(res?.data);
    }
    return res?.data;
  };
  const heading = `User plan successfully upgraded from \n${capitalizeFirstLetter(category)} Plan to ${planUpAndDegradationInfo?.upgraded_plan_name} `;

  return (
    <StyledUserPlanDegradation>
      {showPopup && (
        <Backdrop toggleBackdrop={toggleBackdrop}>
          {step === 0 && (
            <SSPlansForUpgrade
              isSSPlanPage={false}
              filterElitePlan={false}
              handleSteps={updatePlan}
              category={category}
              checked={checked}
            />
          )}
          {step === 1 && (
            <PlanDetailsAndForm
              {...paymentFormProps}
              handleSteps={handleSteps}
              toggleBackdrop={toggleBackdrop}
              planUpAndDegradationInfo={planUpAndDegradationInfo}
            />
          )}
          {step === 2 && <Success heading={heading} />}
        </Backdrop>
      )}
    </StyledUserPlanDegradation>
  );
};

export default UserPlanDegradation;

const SSPlansForUpgrade = forwardRef(
  (
    {
      title = defaultTitle,
      subTitle = defaultSubTitle,
      currentSelectedPlanType,
      plan,
      plans,
      isITR,
      filterElitePlan,
      bulletColor = "#3073D3",
      isSSPlanPage = false,
      handleSteps,
      category,checked
    },
    ref
  ) => {
    const [premiumServices, setPremiumServices] = useState([]);
 
    const [loading, setLoading] = useState(false);

    const filteredPlans = (plans || premiumServices).filter((d) => {


      const shouldFilterOutElite = filterElitePlan && d.name === "Elite Plan";

      return (
        (d.category_type || d.type) !== currentSelectedPlanType &&
        (plan ? plan !== d.name : true) &&
        !shouldFilterOutElite
      );
    });

    const planTitle = `Is our ${plan} not the right plan for you?`;
    const planSubtitle = `Look for our other suitable plans`;

    useEffect(() => {
      (async () => {
        setLoading(true);
        try {
          const allPlans = await SuperSaverPlans.getAllPlans();
          const SSPlans =
            allPlans.data?.filter((data) => {
              return data.name === "Super Saver";
            })[0]?.category_details || [];
          const currentPlanPrice = SSPlans?.filter((data) => {
          
            return data?.name.toLowerCase()?.includes(category.toLowerCase());
          })[0]||[];
          setPremiumServices(
            SSPlans
    .filter((data) => {
                return !data?.name
                  .toLowerCase().replaceAll('_',' ')
                  ?.includes(category.toLowerCase().replaceAll('_',' '))&&(checked?currentPlanPrice.discounted_price<data?.discounted_price:true);
              })
              .map((d, i) => {
                return {
                  ...PlanDetails.filter((data) => {
                    return data.name === d.name;
                  })[0],
                  ...d,
                };
              })
          );
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      })();
    }, []);
    return (
      <StyledSSPlans ref={ref}>
        <HeadingWrapper
          style={{
            paddingBottom: dim._48px,
          }}
        >
          <div className="title">{plan ? planTitle : title}</div>
          <div className="subTitle">{plan ? planSubtitle : subTitle}</div>
        </HeadingWrapper>
        {
          <CardsWrapper
            style={{
              minHeight: dim._scale(286),
			  justifyContent: 'center',
              ...(loading && {justifyContent: "center", alignItems: "center"}),
            }}
          >
            {loading ? (
              <LoaderIcon style={{height: 30, width: 30}} />
            ) : (
              filteredPlans
                ?.sort((a, b) => a.discounted_price - b.discounted_price)
                .map((plan, planIndex) => {
                  return (
                    <PlanCard
                      key={planIndex}
                      item={plan}
                      isITR={isITR}
                      isAdvancePlan={plan.category_type === "ADVANCED"}
                      isSSPlanPage={isSSPlanPage}
                      handleUpdatePlan={handleSteps}
                    />
                  );
                })
            )}
          </CardsWrapper>
        }
      </StyledSSPlans>
    );
  }
);
const PlanDetailsAndForm = ({
  paymentStatus,
  handleChange,
  checked,
  open,
  setopen,
  handleMsg,
  setChecked,
  userDetails,
  userId,
  handleUserId,
  handleSteps,
  planUpAndDegradationInfo,toggleBackdrop
}) => {
  return (
    <div className="planDetailsWrapper">
      <PaymentBreakdown planUpAndDegradationInfo={planUpAndDegradationInfo} />
      <PlanValueForm
     pop={false}
     checked={checked}
     upgraded_plan_category_id={
       planUpAndDegradationInfo?.upgraded_plan_category_id
     }
     total_consumed_coins_amount={   planUpAndDegradationInfo?.payment?.total_consumed_coins_amount}
     totalPlanAmount={ planUpAndDegradationInfo?.payment?.payable_amount_without_gst}
     open={open}
     setopen={setopen}
     handleMsg={handleMsg}
     setChecked={setChecked}
     email={userDetails?.user_response?.email}
     userId={userId}
     setUserId={handleUserId}
     innerPage={true}
     name={userDetails?.user_response?.first_name}
     mobile={userDetails?.user_response?.mobile}
     category_id={userDetails?.category_id}
     category_type={userDetails?.category_type}
     external_id={userDetails?.category_id}
     style={{
       width: dim._scale(370),
       margin: "0",
       height: "auto",
     }}
     popContentStyle={{
       paddingTop: dim._24px,
       margin: `0 ${dim._30px}`,
       textAlign: "start",
     }}
     heading={"Plan update form"}
     handleSteps={handleSteps}
     toggleBackdrop={toggleBackdrop}
      />
  
    </div>
  );
};

const StyledSSPlans = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f4ef;
  padding: ${dim._48px} 0;
  padding-bottom: ${dim._24px};
  width: 100%;
  color: #223567;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  color: #223567;
  border-radius:4px;
  box-sizing: border-box;
`;

const CardsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: ${({dontWrap}) => (dontWrap ? "nowrap" : "wrap")};
  gap: ${dim._24px};
  max-width: ${dim._scale(1280)};
  min-height: 305.215625px;
  box-sizing: border-box;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100;
  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    padding-bottom: 8px;
  }
  .subTitle {
    font-size: 21px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    white-space: pre-line;
  }
`;
