import styled from "styled-components";
import { dim, theme } from "../../../../../styles/theme";

const ActionableDocumentStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  padding-top: ${dim._16px};
  position: relative;
  .actionBox {
    display: flex;
    justify-content: space-between;
    /* width: 85%; */
    /* padding-top: ${dim._16px}; */
  }
  .input-class {
    background: ${theme.color.white_40};
    padding: ${dim._10px};
    width: 291px;
    border: none;
  }
  .uploadIcon {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: ${dim._10px};
    max-width: ${dim._scale(155)};
    align-content: center;
    gap: ${dim._8px};
    background-color: ${theme.color.white_40};
    padding: ${dim._10px} ${dim._76px};
    color: ${theme.color.black_20};
    text-align: center;
    font-family: Open Sans;
    font-size: ${dim._12px};
    font-style: normal;
    font-weight: ${theme.fontWeight.weight_40};
    line-height: normal;
  }

  .uploadFile {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .documentPreview {
    display: flex;
    padding-top: ${dim._10px};
    gap: ${dim._8px}
  }
  .documentName {
    font-size: ${dim._12px};
  }
`;

export { ActionableDocumentStyle };
