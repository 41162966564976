import React from "react";
import ActivityHistory from "../../../../../components/ActivityHistory";
import { useLocation } from "react-router";
import ClientProfile from "../../ClientProfile";
import { OnboardingDataStyled } from "../../OnboardingData/OnboardingDataStyled";
import LayoutHOC from "../../../../../components/UserSideBar";

const HEADING_FOR_ON_ACTIVITY_DETAILS = "Activity Details";
const ActivityForUser = () => {
    const state = useLocation();

    return (
        <OnboardingDataStyled>
            <ClientProfile
                userDetails={state.state}
                view={HEADING_FOR_ON_ACTIVITY_DETAILS}
            />
            <h2>{HEADING_FOR_ON_ACTIVITY_DETAILS}</h2>
            <ActivityHistory
                user_id={state.state.user_id}
                consultHistory={state.state.activity_details
                    .map((d, i) => {
                        if (d.status === "NOT_INITIATED") {
                            return { ...d, color: "", rank: "3" };
                        } else if (d.status === "IN_PROGRESS") {
                            return {
                                ...d,
                                color: "#e1e15d",
                                rank: "2",
                                content: "Activity Started on",
                            };
                        } else {
                            return {
                                ...d,
                                color: "#15BE53",
                                rank: "1",
                                content: "Activity completed on",
                            };
                        }
                    })
                    .sort((a, b) => a.rank - b.rank)}
            />
        </OnboardingDataStyled>
    );
};

export default LayoutHOC(ActivityForUser);
