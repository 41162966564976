import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const ChatScreenStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - ${dim._48px});
  position: relative;
  background-color: #fff;
  box-sizing: border-box;

  .chat_conversation_components {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: ${dim._12px} ${dim._24px};
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
  }

  .doc_backdrop {
    width: 100%;
    height: 100%;
    padding: 0 ${dim._20px};
    position: absolute;
    background-color: #000;
    opacity: 0.3;
    z-index: 1;
    pointer-events: none;
  }
`;
