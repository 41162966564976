import React, { useContext, useEffect, useState } from "react";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import EditDeletebtn from "../EditDeletebtn/EditDeletebtn";
import { Table, Tr, Td, Th } from "./AdvisorTable.styled";
import Alert1 from "../Alert/Alert1";
import Button from "../Button";
import FlexRow from "../FlexRow/FlexRow";
import BASE_URL from "../../Services/API_ENDPOINT";
import { putBlock } from "../../Services/Slots";
import { getAuthToken } from "../../Services/httpUtil";

const AdvisorTable = ({ Time, Id, UpdateSlot, arrayData, token }) => {
  const a = useContext(AdvisorContext);
  const [ShowAlert, setShowAlert] = useState(false);
  const [SubmitMsg, setSubmitMsg] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if ((arrayData.isCreateSlotActive || arrayData.isSlotsActive) && a.tf) {
      getAuthToken().then(function (idToken) {
        CreateSlot(idToken);
      });
    }
  }, [arrayData.isCreateSlotActive, a.tf]);
  const CreateSlot = async (token) => {
    const response = await fetch(`${BASE_URL}timeSlots/v1/?advisorId=${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
        Source: "cms",
      },
    });
    const data = await response.json();
    setAppointments(
      data.sort(function (a, b) {
        return a.start_time - b.start_time;
      })
    );
    a.setTf(false);
  };

  const handleBlockClick = async (data) => {
    setLoader(true);
    try {
      await putBlock(data, a);
      setLoader(false);
      a.setTf(true);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const handleAvalibility = (type) =>
    type === "SLOT_CREATION" ? "Demo availability" : "Advisor availability";

  return (
    <div>
      <Alert1 ShowAlert={ShowAlert} SubmitMsg={SubmitMsg} mid={true} />
      <Table>
        <Tr>
          <Th> Date</Th>
          <Th>From</Th>
          <Th>To</Th>
          <Th>Type</Th>
          <Th>Consultation fee</Th>
          <Th>status</Th>
        </Tr>

        {appointments.length !== 0 ? (
          appointments
            .filter((d) => {
              return `${d.advisor_id === Id}`;
            })
            .map((d, i) => {
              return (
                <Tr flag={d.flag} appointment_status={d.appointment_status}>
                  <Td>
                    {new Date(d.start_time).getDate() +
                      "/" +
                      (new Date(d.start_time).getMonth() + 1) +
                      "/" +
                      new Date(d.start_time).getFullYear()}
                  </Td>
                  <Td>{new Date(d.start_time).toLocaleTimeString("en-US")}</Td>
                  <Td>
                    <FlexRow>
                      {new Date(d.end_time).toLocaleTimeString("en-US")}
                    </FlexRow>
                  </Td>{" "}
                  {<Td>{handleAvalibility(d.type)}</Td>}
                  <Td>
                    {d.discounted_fee} Rs <s>{d.consultation_fee} Rs</s> (
                    {d.percentage_off}%)
                  </Td>
                  {
                    <Td>
                      <FlexRow>
                        {d.flag === "BLOCKED" || d.flag === "AVAILABLE" ? (
                          <Button
                            onButtonClick={() => {
                              if (loader) return null;
                              handleBlockClick(d);
                            }}
                            buttonClassName="Block"
                            buttonName={
                              d.flag === "AVAILABLE" ? "Block" : "Un-Block"
                            }
                          />
                        ) : (
                          "Booked"
                        )}
                        {d.flag === "AVAILABLE" ? (
                          <span className="">
                            <EditDeletebtn
                              arrayData={arrayData}
                              HandleClick={() => {
                                if (loader) return null;
                                setLoader(true);
                                getAuthToken()
                                  .then(function (idToken) {
                                    return a.deleteapi(
                                      `${BASE_URL}timeSlots/v1/deleteTimeSlot?advisorId=${Id}&slotId=${d.id}`,
                                      {
                                        method: "DELETE",

                                        headers: {
                                          Accept: "application/json",
                                          "Content-Type": "application/json",
                                          Source: "cms",
                                          Authorization: `${idToken}`,
                                        },
                                      }
                                    );
                                  })
                                  .then(() => {
                                    setLoader(false);
                                    setShowAlert(true);
                                    setSubmitMsg("Slots Deleted");
                                    setTimeout(() => {
                                      setShowAlert(false);
                                    }, 1000);
                                  })
                                  .catch((e) => {
                                    console.log(e);
                                    setLoader(false);
                                  });
                              }}
                            />
                          </span>
                        ) : null}
                      </FlexRow>
                    </Td>
                  }
                </Tr>
              );
            })
        ) : (
          <Tr>
            {
              <td className="td" colSpan="12">
                No Slots available
              </td>
            }
          </Tr>
        )}
      </Table>
    </div>
  );
};

export default AdvisorTable;
