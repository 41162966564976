import styled from "styled-components";

export const SuperSaverBtn = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  margin: 10px 0;
  padding: 10px;

  /* top: 175px; */
  /* left: 150px; */
  border-radius: 25px;
  text-align: center;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: medium;
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  color: #34495e;
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.1);
  justify-content: center;
`;
