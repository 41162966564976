import React, {useEffect, useState} from "react";
import Header from "../../components/header";
import AdvisorData from "../../Context/Advisor/AdvisorData";
import SideBar from "../../components/sideBar";
import LayoutStyle from "./layout.styled";
import {useLocation} from "react-router-dom";
import {sidebarItems} from "../../components/sideBar/constant";
import PopupCardForActionInfo from "../../commonComponents/PopupCardForActionInfo";
import {AdvisorDashboard} from "../../Services/AdvisorDashboard";
import Backdrop from "../../components/Backdrop";
import {LOCAL_DB_KEYS} from "../../constants/localStorage";
import {getHrDiff} from "../../utils/converttimestamptoday";
import toast from "react-hot-toast";
import { USER_ROLE } from "../../constants/userRole";

const Layout = (props) => {
  const {pathname} = useLocation();
  const [warningInfo, setWarningInfo] = useState({});
  const [cmsBlocked, setCmsBlocked] = useState(false);
  const {loggedIn} = props;
  const { userType } = props;

  const warningDuration=1;
  const popupHourDiff=2;

  const getLockData = async () => {
   if(! loggedIn) return null;
    try {
      const res = await AdvisorDashboard?.getLockData();
      const {data} = res;
      setWarningInfo(data);
    } catch (error) {
      const {code, message} = error?.response?.data || {
        code: 500,
        message: "Oops something went wrong!",
      };
      if (code === 803) {
        setCmsBlocked(true);
      } else {
        setCmsBlocked(false);
        toast.error(message||'Oops something went wrong!')
      }
    }
  };

  useEffect( () => {
    props.userType=== USER_ROLE.ADVISOR&&
     getLockData();
  }, [loggedIn,pathname]);

  const WarningPopup = ({warningInfo, cmsBlocked}) => {
    const [WarningVisible, setWarningVisible] = useState(false);

    const handleWarningVisibility = (warningInfo) => {
      const {
        oldest_pending_mom_days,
        oldest_unreplied_chat_days,
      } = warningInfo;
      const lastWarningTimestamp = localStorage.getItem(
        LOCAL_DB_KEYS.LAST_WARNING_TIMESTAMP
      );
      const hourDiff = lastWarningTimestamp
        ? getHrDiff(+lastWarningTimestamp)
        : 0;

 if (hourDiff >= popupHourDiff || lastWarningTimestamp === null) {
    
        if (oldest_pending_mom_days >= warningDuration || oldest_unreplied_chat_days >= warningDuration) {
          setWarningVisible(true);
        }  else {
          setWarningVisible(false);
        }
      } else {
        setWarningVisible(false);
      }
    };

    useEffect(() => {
      handleWarningVisibility(warningInfo);
    }, [warningInfo]);
    return (
      (WarningVisible || cmsBlocked) && (
        <Backdrop>
          <PopupCardForActionInfo
            cmsBlocked={cmsBlocked}
            WarningVisible={WarningVisible}
            warningInfo={warningInfo}
          />
        </Backdrop>
      )
    );
  };
  return (
    <AdvisorData>
      <LayoutStyle pathname={pathname}>
        {loggedIn && (
          <WarningPopup warningInfo={warningInfo} cmsBlocked={cmsBlocked} />
        )}
        <Header
          loggedIn={props.loggedIn}
          handleLogout={props.handleLogoutButton}
          handleLogin={props.handleLoginButton}
          handleSignup={props.handleSignupButton}
          userEmail={props.userEmail}
          user={props.user}
        />
        {props.loggedIn && (
          <SideBar
            SideBarData={sidebarItems(userType).
            sort((a,b)=>{
            return a?.id-b?.id
          })
          }
            userType={userType}
          />
        )}
        <div className="content">{props.children}</div>
      </LayoutStyle>
    </AdvisorData>
  );
};

export default Layout;
