import styled from "styled-components";
import { dim } from "../../../../../../styles/theme";

export const StyledNotesPage = styled.div`
width: 100%;
margin-top: -${dim._30px};
box-sizing: border-box;
.notes-page{
    width: 100%;
}
.heading{
    font-size: 24px;
    font-weight: 600;
    margin-left: ${dim._10px};
    box-sizing: border-box;
}
`