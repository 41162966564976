import React from 'react';
import { TableHeadStyle } from './TableHead.styled';

const TableHead = ({ tableTitle }) => {
	return (
		<TableHeadStyle>
			<div className='col-1 head'></div>
			<div className='col-2 head'>{tableTitle.top_a}</div>
			<div className='col-3 head'>{tableTitle.top_b}</div>
		</TableHeadStyle>
	);
};

export default TableHead;
