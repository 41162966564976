import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

const DeliTimePicker = ({
  className,
  hideDisabledOptions,
  onChange,
  value,
  ...rest
}) => (
  <TimePicker
    {...rest}
    className={className}
    popupClassName={className}
    showSecond={false}
    onChange={onChange}
    // hideDisabledOptions
    minuteStep={10}
    value={value}
    use12Hours
    // disabledSeconds={ (hour= 5, minute= 5) => 5[]}
    defaultOpenValue= {moment().hour(new Date().getHours).minute(0)}
  />
);

DeliTimePicker.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(moment),
};

export default DeliTimePicker;
