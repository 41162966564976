import styled from 'styled-components';
import { dim } from '../../../../../../../styles/theme';

export const TableHeadingStyle = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	min-height: auto;
	height: auto;
	margin: 0;
	padding: ${dim._10px} ${dim._20px};
	background: #e5e5e5;
	border-radius: ${dim._4px} ${dim._4px} 0 0;
	box-sizing: border-box;

	div {
		width: 50%;
		font-size: ${dim._12px};
		font-weight: 600;
		color: #212f45;
		text-transform: uppercase;
	}
`;
