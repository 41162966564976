export const colors = {
	headingColor: '#212121',
	subheadingColor: '#7f7f7f',
	primaryCTA: '#85bb65',
	secondaryCTA: '#0B6aB0',
	logoColor: '#212121',
	borderColor: '#f5f5f5',
	backgroundColor: '#fafafa',
	white: '#ffffff',
	imgBg: '#d9ebf9',
	chatPreviewActiveColor: '#0B6AB029'
};

export const Theme = {
	color: {
		black_0: '#000000',
		black_10: '#212121',
		black_20: '#212F45',
		green_10: '#85bb65',
		green_20: '#15BE53',
		blue_10: '#0B6aB0',
		blue_20: '#0A2540',
		blue_30: '#635BEF',
		blue_40: '#2769DA',
		blue_50: '#efeefd',
		white_0: '#ffffff',
		white_10: '#fafafa',
		white_20: '#f5f5f5',
		white_30: '#F6F9FC',
		white_40: '#F1F5F7',
		white_50: '#fafafa',
		grey_0: '#3b3b3b',
		grey_10: '#7f7f7f',
		grey_20: '#e5e5e5',
		grey_30: '#BBBBBB',
		grey_40: '#d9ebf9',
		grey_50: '#808080',
		red: '#ff0000',
		pink_10: '#fbbc75',
		pink_20: '#feedda',
		orange_40: '#eb5e4a',
		transparent: '#0000'

		// effects_scrim_black_50: "rgba(0, 0, 0, 0.5)",
		// effects_highlights_yellow: "#ffcc19",
		// highlights_blue: "#e5f1ff",
		// text_low_emphasis: "#a2a5b1",
		// button_hover_blue: "#5e89f7",
		// button_active_blue: "#cce4ff",
		// effects_card_highlights_yellow: "#ffcc19",
		// tomato: "#df2e2e",
		// button_disabled: "#c8c9d1",
		// text_normal: "#6d6c6f",
	}
};
