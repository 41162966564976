import React from "react";
import { StyledDropDownComponent } from "./DropDownComponent.styled";
import { dim } from "../../../../styles/theme";
import FormErrorMsgHandler from "../../../../commonComponents/FormErrormsgHandler";
import { ERROR_MESSAGE } from "../../constant";

const DropDownComponent = ({
  defaultValue,
  dataOptions,
  name = "dropdown",
  handleDropDownChange,
  width = dim._scale(290),
  index,
  type,
  isError,
  id,
  componentCategory,
  className,
}) => {
  return (
    <StyledDropDownComponent width={width}>
      <label>
        <select
          className={className}
          name={name}
          id={id}
          onChange={(e) => {
            handleDropDownChange(e.target.value, index, e);
          }}
          value={type || defaultValue}
        >
          <option value={defaultValue}>{defaultValue} </option>
          {dataOptions?.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </label>
      {isError?.error && isError.errorArr.includes(componentCategory) && (
        <FormErrorMsgHandler style={{ bottom: "-24px" }}>
          {ERROR_MESSAGE[componentCategory]}
        </FormErrorMsgHandler>
      )}
    </StyledDropDownComponent>
  );
};

export default DropDownComponent;
