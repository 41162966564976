import styled from 'styled-components';
import { dim, theme } from '../../../../../styles/theme';

const AdvisorActionableViewStyle = styled.div`
	.infoContainer {
		background-color: ${theme.color.grey_130} !important;
	}
	.documentBox {
		width: 100%;
		display: flex;
		flex: 1;
		gap: ${dim._16px};
	}
	.footer {
		margin-top: auto;
	}
`;

export {AdvisorActionableViewStyle}
