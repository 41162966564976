import styled from "styled-components";

export const StyledArticleTopbar=styled.div`
display:flex;
gap:16px;
width:100%;
align-items:center;
padding-bottom:24px;
.arrow{
    width:5%;width: 20px;
    height: 20px;
    padding:10px;
    cursor:pointer;
}
.articleDetail{
    width:75%;
    color: #05387E;

font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: 32px;
letter-spacing: -0.26px;
}
.articleInfoTitle{
    display:flex;
    gap:16px;
    padding-bottom:4px;
}
.categoryInfo{
    display:flex;
    gap:16px;
    color:#4B5565;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}
.articleCta{
    width:20%; 
    display:flex;
    gap:16px;
}
`