import React, { useEffect, useState } from "react";
import {
  chipStyle,
  LogoContainer,
  StyledMobilePreview,
} from "./MobilePreview.styled";
import renderHTML from "../../../../../utils/renderHTML";
import {
  extractContent,
  replaceUnderscoreOnly,
} from "../../../../../utils/textUtil";
import MobileAuthorDetails from "../MobileAuthorDetails";
import { getFormattedDateMonthYear } from "../../../../../utils/timeUtil";
import { Chip } from "@mui/material";
import { generateUniqueIds } from "../../../../../utils/generateUniqueIds";
import MobileTableOfContent from "../MobileTableOfContent";
import MobileAuthorOrProsperr from "../MobileVerifiedOrAuthor";

const MobilePreview = ({ article, content, authorData }) => {
  const { month, day, year } = getFormattedDateMonthYear(article?.published_on);
  const date = month && `• ${month} ${day}, ${year}`;
  const [modifiedContent, setModifiedContent] = useState("");
  const [filteredHeadings, setFilteredHeadings] = useState([]);

  useEffect(() => {
    const { updatedPayload, headings } = generateUniqueIds(content?.payload);
    setModifiedContent(updatedPayload);
    setFilteredHeadings(
      headings.filter((heading) => heading?.id && heading?.text?.length > 0)
    );
  }, [article.payload]);

  return (
    <StyledMobilePreview>
      <div className="mobile-content-wrapper">
        <div className="mobile-title-wrapper">
          {renderHTML(
            extractContent(article?.title),
            "div",
            "mobile-title-preview"
          )}
        </div>

        <div className="mobile-articleInfo">
          <div className="logo">
            <LogoContainer>
              <MobileAuthorOrProsperr
                authorCategory={article?.author_details?.author_category}
                isVerified={article?.is_verified}
              />
            </LogoContainer>
          </div>
          <div className="mobile-dateAndReadTime">{date}</div>
        </div>

        <div className="mobile-banner-wrapper">
          <img
            src={article?.mobile_banner_url}
            alt="banner_url"
            style={{ width: "100%" }}
            className="banner"
          />
        </div>

        <MobileTableOfContent
          filteredHeadings={filteredHeadings}
          modifiedContent={modifiedContent}
          setModifiedContent={setModifiedContent}
        />
        <div className="mobile-summary-wrapper">
          <div className="mobile-summary-title">Article Brief</div>
          {renderHTML(
            extractContent(article?.summary),
            "div",
            "mobile-summary"
          )}
        </div>
        <div className="mobile-body-wrapper">
          {renderHTML(content?.payload, "div", "mobile-body-content")}
        </div>
        <div className="mobile-tags-wrapper">
          {article?.tags &&
            article?.tags.map((it, i) => {
              return <Chip sx={chipStyle} label={replaceUnderscoreOnly(it)} />;
            })}
        </div>
        {
          <MobileAuthorDetails
            isVerified={article?.is_verified}
            authorData={authorData}
          />
        }
      </div>
    </StyledMobilePreview>
  );
};

export default MobilePreview;
