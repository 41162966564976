import styled from "styled-components";
import { dim } from "../../styles/theme";

const StyledConsultationExperience = styled.div`
  /* background: #fff; */
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  gap: ${dim._8px};
  width: 100%;
  color: #212f45;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
`;

export { StyledConsultationExperience };
