import React from "react";
import Chip from "../../../../../commonComponents/Chip";
import { theme, dim } from "../../../../../styles/theme";
import { AdvisorSubmitProofStyle } from "./AdvisorSubmitProof.styled.js";
import TextButton from "../../../../../commonComponents/TextButton";
import { AiFillAccountBook, AiFillCloseCircle } from "react-icons/ai";
import { ActionableStatus } from "../../../../../commonComponents/ActionableStatus";
import { USER_TYPE } from "../../../../../utils/constant";
import { downloadByOriginalName } from "../../../../../utils/downloadByOriginalName";
import { useSearchParams } from "react-router-dom";

const chipStyle = {
  padding: `${dim._4px} ${dim._8px}`,
  marginRight: dim._10px,
  borderRadius: dim._4px,
  fontWeight: theme.fontWeight.weight_40,
  fontSize: dim._12px,
  color: theme.color.blue_30,
  background: theme.color.purpleLight_10,
};

const adviosr_text_content = {
  advisor_dependency: "Advisor Action Item(s)",
  add_action: "Added Advisor Action Item(s)",
};

const AdvisorSubmitProof = ({
  item,
  type = "",
  handleDeleteActionable,
  removeItem,
  isEditDisabled,
  getConsultationMom,
  index,
}) => {
  const { add_action } = adviosr_text_content;
  const [searchParams] = useSearchParams();
  return (
    <>
      <div
        className="cardInfo"
        style={{ display: "flex", gap: `${dim._20px}`, position: "relative" }}
      >
        <AdvisorSubmitProofStyle className="comment-section">
          <div className="align-section">
            <Chip style={{ ...chipStyle }}>{item?.action_category}</Chip>
            <Chip style={{ ...chipStyle }}> {item?.action_type}</Chip>
            {item?.company_name && (
              <Chip style={{ ...chipStyle }}> {item?.company_name}</Chip>
            )}
            {item?.document?.document_name && (
              <Chip style={{ ...chipStyle }}>
                {item?.document?.document_name.length > 30
                  ? item?.document?.document_name.slice(0, 30)
                  : item?.document?.document_name}
              </Chip>
            )}
            {item?.document?.document_type && (
              <Chip style={{ ...chipStyle }}>
                {item?.document?.document_type}
              </Chip>
            )}
          </div>
          {item?.text && (
            <div className="document-text">
              <b>Actionable Info : </b>
              {item?.text}
            </div>
          )}
          {item?.document?.document_name && (
            <div
              className="document-text"
              style={{ display: "flex", gap: dim._10px }}
            >
              <b>Document Provided : </b>
              {item.document?.document_name && (
                <div
                  className="documentPreview"
                  id={item.document.document_name}
                  style={{ display: "flex", gap: dim._10px }}
                  onClick={() => {
                    const { s3link, document_name } = item.document;
                    const user_id = searchParams.get("user_id");
                    s3link &&
                      downloadByOriginalName(s3link, document_name, user_id);
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/documentPreviewImage.svg"
                    }
                    className="documentPreviewImg"
                    alt=""
                  />
                  <div className="documentName">
                    {item?.document?.document_name.length > 30
                      ? item?.document?.document_name.slice(0, 30)
                      : item?.document?.document_name}
                  </div>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/download_icon.svg"
                    }
                    alt=""
                    style={{
                      cursor: "pointer",
                    }}
                    className="documentPreviewImg"
                  />
                </div>
              )}
            </div>
          )}
        </AdvisorSubmitProofStyle>
        {isEditDisabled && (
          <ActionableStatus
            status={item.status}
            dependent={type}
            title={item.text}
            action_item_id={item.action_item_id}
            getConsultationMom={getConsultationMom}
          />
        )}
        {!isEditDisabled && (
          <AiFillCloseCircle
            style={{ marginTop: dim._24px }}
            className="close-btn"
            onClick={(e) => {
              handleDeleteActionable(index);
            }}
          />
        )}
      </div>
    </>
  );
};
export default AdvisorSubmitProof;
