import styled from "styled-components";
import { dim } from "../../styles/theme";
import { Theme } from "../../styles/colors";

export const IncomeStyle = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 0;
    box-sizing: border-box;

    .user-details {
        background-color: #fff;
        padding: 8px 16px;
        width: 100%;
        margin-bottom: 16px;
        box-sizing: border-box;
        border-radius: 4px;
        text-transform: capitalize;
        font-weight: 600;
    }

    .heading {
        font-size: ${dim._14px};
        line-height: ${dim._24px};
        align-items: center;
        color: #212f45;
        font-weight: 600;
        box-sizing: border-box;
        margin-bottom: ${dim._16px};
    }

    .main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 70%;
        height: auto;
        margin: 0;
        padding: 0 ${dim._24px};
        box-sizing: border-box;
    }

    .main h1 {
        margin: 0 0 ${dim._24px} 0;
        font-weight: 600;
        font-size: ${dim._18px};
        color: #212f45;
    }

    .categories {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .rightSidebar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 30%;
        height: calc(100vh - 68px);
        margin: 0;
        padding: 0 56px 0 0;
        position: sticky;
        top: 84px;
        overflow-y: auto;
        box-sizing: border-box;
    }
    .other-input {
        background: ${Theme.color.white_10};
        border-radius: ${dim._4px};
        padding: ${dim._10px};
        width: 100%;
        border: 0;
        outline: 0;
    }
`;
