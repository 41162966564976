import React from "react";
import styled from "styled-components";
import { dim } from "../../styles/theme";

const ChipStyled = styled.span`
  display: inline-block;
  padding: ${dim._4px} ${dim._12px};
  font-style: normal;
  font-weight: 600;
  font-size: ${dim._10px};
  line-height: ${dim._12px};
  color: rgb(33, 33, 33);
  cursor: pointer;
  background-color: rgb(230, 230, 230);
  border-radius: ${dim._20px};
`;

const Chip = ({ children, style, onClick = () => {}, className, index }) => {
  return (
    <ChipStyled
      onClick={onClick}
      key={index}
      style={style}
      className={className}
    >
      {children}
    </ChipStyled>
  );
};

export default Chip;
