import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChatPreview from "../ChatPreview";
import { PreviewStyled } from "./PreviewStyled";
const Preview = ({ handleSelectedChat, selectedChat, SessionDetails }) => {
    const notification = useSelector((state) => state.chat.notification);
    const [sessions, setSessions] = useState([...SessionDetails]);

    const getUnreadMessage = (item) => {
        const filter =
            notification &&
            notification.unread_messages &&
            notification.unread_messages.filter((element) => {
                return item.external_id === element.session_id ? true : false;
            });

        return filter && filter.length > 0 ? filter[0].unread_messages : 0;
    };

    useEffect(() => {
        if (notification !== undefined && notification.unread_messages !== 0) {
            const sessions = SessionDetails.map((item) => {
                return {
                    ...item,
                    unread_messages: getUnreadMessage(item),
                };
            });
            setSessions([...sessions]);
        }
    }, [SessionDetails, notification]);

    return (
        <PreviewStyled>
            {sessions.map((msg, i) => {
                return (
                    <ChatPreview
                        active={i === selectedChat}
                        msg={msg}
                        index={i}
                        key={i}
                        handleSelectedChat={handleSelectedChat}
                    />
                );
            })}
        </PreviewStyled>
    );
};

export default Preview;
