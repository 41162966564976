import React from "react";
import { BadgeStyle } from "./Badge.styled";

const Badge = ({ value, isOpen = true }) => {
  return (
    <BadgeStyle isOpen={isOpen}>
      <p>{value}</p>
    </BadgeStyle>
  );
};

export default Badge;
