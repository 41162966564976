import styled from "styled-components";
import { dim, theme } from "../../../../styles/theme";
// import { theme, dim } from '../../styles/theme';
const ConfiramtionPopupStyle = styled.div`
  position: relative;
  background-color: ${theme.color.white_0};
  background-clip: padding-box;
  border-radius: ${dim._8px};
  box-shadow: ${dim._0px} ${dim._6px} ${dim._16px} ${dim._0px}
      rgba(0, 0, 0, 0.08),
    ${dim._0px} 3px ${dim._6px} -4px rgba(0, 0, 0, 0.12),
    0 9px 28px ${dim._8px} rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: ${dim._20px} ${dim._24px};
  width: ${dim._scale(464)};
`;

const ConfirmationHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ConfirmationContent = styled.div`
  display: flex;
  padding-top: ${dim._14px};
  font-size: ${dim._14px};
`;

const ConfirmationFotter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${dim._16px};
`;

const ConfiramtionPopupTitle = styled.div`
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-weight: ${theme.fontWeight.weight_60};
  font-size: ${dim._16px};
  line-height: 1.5;
  word-wrap: break-word;
`;

const CancelButtonStyle = styled.button`
  margin-right: ${dim._8px};
  display: inline-block;
  font-size: ${dim._14px};
  height: ${dim._32px};
  padding: ${dim._4px} ${dim._14px};
  border-radius: ${dim._6px};
  font-weight: ${theme.fontWeight.weight_40};
  white-space: nowrap;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  line-height: 1.5714285714285714;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
`;

const OkayButtonStyle = styled.button`
  color: ${theme.color.white_0};
  display: inline-block;
  font-size: ${dim._14px};
  height: ${dim._32px};
  padding: ${dim._4px} ${dim._14px};
  border-radius: ${dim._6px};
  font-weight: ${theme.fontWeight.weight_40};
  text-align: center;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  background-color: #1677ff;
  border-color: #d9d9d9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
`;

export {
  ConfiramtionPopupStyle,
  ConfirmationHeader,
  ConfirmationContent,
  ConfirmationFotter,
  CancelButtonStyle,
  OkayButtonStyle,
  ConfiramtionPopupTitle,
};
