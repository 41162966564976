import React, { useEffect, useState } from "react";
import { IncomeInputWrapper } from "./index.styled";
import Basic from "./components/Basic";
import SalaryComponents from "./components/SalaryComponents";
import { toast } from "react-hot-toast";
import SalaryIncomeServices from "../../../../Services/IncomeServices";
import { dim } from "../../../../styles/theme";
import { Loader2 } from "../../../../commonComponents/Loader";
import Button from "../../../../commonComponents/Button";
import CustomModal from "../../../../commonComponents/CustomModal";

const AnnualCTCBreakdown = ({
    userId,
    companyId, // external_id of dto list item
    financialYearId,
    readonly = true,
}) => {
    const [step, setStep] = useState(0);
    const [basicDetails, setBasicDetails] = useState(null);
    const [salaryComponents, setSalaryComponents] = useState([]);
    const [showSalaryComponent, setShowSalaryComponent] = useState(
        salaryComponents.length > 0
    );
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [finalSalaryComponents, setFinalSalaryComponents] = useState([]);
    const [getAPIData, setGetAPIData] = useState(null);
    const [addComponent, setAddComponent] = useState(true);

    useEffect(() => {
        (async () => {
            const incomeData = await SalaryIncomeServices.getSalaryIncome(
                userId,
                financialYearId
            );
            setGetAPIData(incomeData.data);
        })();
    }, []);

    useEffect(() => {
        if (getAPIData != null) {
            (async () => {
                const data = await getCompanyDetails(getAPIData, companyId);
                setBasicDetails({
                    ...basicDetails,
                    ...data.basicDetails,
                });

                if (data.data.compensation_details.ctc_component.length > 0) {
                    readonly &&
                        setShowSalaryComponent(
                            data.data.compensation_details.ctc_component
                                .length > 0
                        );
                    readonly &&
                        setSalaryComponents([
                            ...data.data.compensation_details.ctc_component,
                        ]);
                }
            })();
        }
    }, [getAPIData, companyId]);

    const getCompanyDetails = async (getAPIData, companyId) => {
        const data = getAPIData.income_details.salary_income_dto_list.find(
            (it) => {
                return companyId === it.external_id;
            }
        );

        return {
            basicDetails: {
                companyName: data.company_details.name,
                logo: data.company_details.logo,
                isCurrentCompany: data.is_current_company, // TODO Manas true
                startDate: data.start_end_date_fy.start_date || 0,
                endDate: data.start_end_date_fy.end_date || 0,
                ctc: data.compensation_details.ctc || 0,
            },
            data: data,
        };
    };

    const onBasicDataChange = (data) => {
        setBasicDetails(data);
    };

    const onSalaryComponentsChange = (data) => {
        setFinalSalaryComponents(data);
        isSalaryComponentsComplete(data);
    };

    const isSalaryComponentsComplete = (finalSalaryComponents) => {
        const componentSum = finalSalaryComponents.reduce((total, current) => {
            return total + +current.value;
        }, 0);
        return {
            // eslint-disable-next-line eqeqeq
            status: componentSum == basicDetails.ctc, // == important
            diff: basicDetails.ctc - componentSum,
        };
    };

    const onSubmit = async () => {
        const skip = !addComponent;
        try {
            const initialData = await getCompanyDetails(getAPIData, companyId);

            const dtoData = {
                ...initialData.companyDetails,
                financial_year_id: financialYearId,
                is_current_company: basicDetails.isCurrentCompany,
                start_end_date_fy: {
                    start_date: basicDetails.startDate,
                    end_date: basicDetails.endDate,
                },
                compensation_details: {
                    ctc: basicDetails.ctc,
                    ctc_component: skip
                        ? []
                        : [
                              ...finalSalaryComponents.map((it) => {
                                  return { label: it.label, amount: it.value };
                              }),
                          ],
                },
                monthly_breakdown: {},
            };

            await SalaryIncomeServices.saveSalaryIncome(dtoData, userId);
            toast.success("CTC details added successfully");
        } catch (error) {
            toast.error(
                error && error.response && error.response.data
                    ? error.response.data.message
                    : "Oops Something went wrong"
            );
        }
    };

    return (
        <IncomeInputWrapper>
            {getAPIData == null ? (
                <div
                    className=""
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: " 100%",
                        height: `calc( 100vh - ${dim._68px})`,
                    }}
                >
                    <Loader2 />
                </div>
            ) : (
                <>
                    {basicDetails && (step === 0 || readonly) && (
                        <div className="pos-0">
                            <Basic
                                key={basicDetails.companyName}
                                details={basicDetails}
                                readonly={readonly}
                                onChange={onBasicDataChange}
                            />
                            {!readonly && (
                                <div style={{ width: dim._scale(926) }}>
                                    <Button
                                        style={{
                                            width: 112,
                                            height: 35,
                                            marginTop: 16,
                                            float: "right",
                                        }}
                                        onButtonClick={() => setStep(step + 1)}
                                        buttonName="Next &gt;&gt;&gt;"
                                    ></Button>
                                </div>
                            )}
                        </div>
                    )}
                    {basicDetails && (step === 1 || readonly) && (
                        <div>
                            <div className="between-comp">
                                <SalaryComponents
                                    style={{ marginTop: 24 }}
                                    max={{
                                        label: "₹" + basicDetails.ctc || 0,
                                        value: basicDetails.ctc || 0,
                                    }}
                                    salaryComponents={salaryComponents}
                                    readonly={readonly}
                                    onChange={onSalaryComponentsChange}
                                    diff={
                                        isSalaryComponentsComplete(
                                            finalSalaryComponents
                                        ).diff
                                    }
                                />
                            </div>
                            {!readonly && (
                                <div style={{ width: dim._scale(926) }}>
                                    <Button
                                        style={{
                                            width: 112,
                                            height: 35,
                                            marginTop: 16,
                                            float: "left",
                                        }}
                                        onButtonClick={() => setStep(step - 1)}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        isEnabled={readonly}
                                        style={{
                                            width: 112,
                                            height: 35,
                                            marginTop: 16,
                                            float: "left",
                                            marginLeft: 16,
                                        }}
                                        onButtonClick={() => {
                                            setAddComponent(true);
                                            setShowConfirmDialog(true);
                                        }}
                                    >
                                        Skip
                                    </Button>
                                    <Button
                                        isEnabled={
                                            !isSalaryComponentsComplete(
                                                finalSalaryComponents
                                            ).status
                                        }
                                        style={{
                                            width: 200,
                                            height: 35,
                                            marginTop: 16,
                                            float: "right",
                                        }}
                                        onButtonClick={() => {
                                            setAddComponent(false);
                                            setShowConfirmDialog(true);
                                        }}
                                    >
                                        Submit CTC Details
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
            {showConfirmDialog && (
                <CustomModal bg="#00000099">
                    <div className="modal">
                        <h3 className="heading">Submitting CTC Details</h3>
                        <p style={{ marginTop: 8 }}>
                            You will not be able to change it later!
                        </p>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 24,
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onButtonClick={() =>
                                    setShowConfirmDialog(false)
                                }
                                buttonName="Cancel"
                            ></Button>
                            <Button
                                onButtonClick={() => onSubmit()}
                                style={{
                                    width: 140,
                                    height: 32,
                                    marginLeft: 12,
                                }}
                                buttonName="Submit"
                            ></Button>
                        </div>
                    </div>
                </CustomModal>
            )}
        </IncomeInputWrapper>
    );
};

export default AnnualCTCBreakdown;
