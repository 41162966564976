import styled from "styled-components";
import { dim, theme } from "../../styles/theme";

export const BadgeStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: ${dim._6px} ${dim._12px};
  height: ${dim._20px};
  font-size: ${dim._12px};
  color: #fff;
  font-weight: 600;
  background-color: ${({ isOpen }) =>
    isOpen ? theme.color.green_20 : theme.color.red_40};
  border-radius: ${dim._scale(140)};
  box-sizing: border-box;
`;
