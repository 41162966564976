import React from "react";
import ProtectedRoutes from "./ProtectedRoutes";
import UnProtectedRoutes from "./UnProtectedRoutes";

const Routing = ({
  loggedIn,
  setUser,
  setLoggedIn,
  userType,
  setType,
  handleLogoutButton,
}) => {
  return loggedIn ? (
    <ProtectedRoutes
      userType={userType}
      handleLogoutButton={handleLogoutButton}
    />
  ) : (
    <UnProtectedRoutes
      userType={userType}
      setType={setType}
      setUser={setUser}
      setLoggedIn={setLoggedIn}
    />
  );
};

export default Routing;
