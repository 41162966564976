import styled from "styled-components";

export const StyledDropDownComponent = styled.div`
  position: relative;
  select {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: ${({ width }) => `${width}`};
    padding: 8px 16px;
    color: #212f45;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -ms-word-break: normal;
    word-break: normal;
    background-color: #f1f5f7;
    border: none;
    box-sizing:border-box;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  /* hide native button */
  select::-ms-expand {
    display: none;
  }
  &:after {
    content: url("data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTUgOCIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTEuMjI5OTggMS4wNDU0MUw3LjE0OTQ4IDYuODEyMjVDNy4zNDQyIDcuMDAxOTUgNy42NTU3NiA3LjAwMTk1IDcuODQ0OTEgNi44MTIyNUwxMy43NyAxLjA0NTQxIiBzdHJva2U9IiM0MjU0NjYiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+PC9zdmc+");
    left: ${({ width }) => `calc(${width} - 36px)`};
    top: 4px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
  }
`;
