import React, { useEffect, useState } from "react";
import { StyledReschedulePopup } from "./ReschedulePopup.styled";
import FlexRow from "../../../../commonComponents/FlexRow/FlexRow";
import CustomDatePicker from "../../../../commonComponents/CustomDatePicker";
import { timeUtil } from "../../../../utils/timeUtil";
import CustomSelect from "../../../../components/OnboardAuthorCategory";
import { StyledTimePicker } from "../../index.styled";
import moment from "moment";
import { AiFillCloseCircle } from "react-icons/ai";
import { isEmailValid } from "../../../../utils/validate";
import Input from "../Input";
import Button from "../../../../commonComponents/Button";
import {
  allConsultationType,
  allConsultationTypeUnregistered,
  allDurations,
  getEventDescription,
} from "../../constants";
import { convertToPascalCase } from "../../../../utils/textUtil";
import toast, { LoaderIcon } from "react-hot-toast";
import TimeSlot from "../../../../components/TimeSlot";
import { useLocation, useParams } from "react-router";
import { getadvisor } from "../../../../Services/SuperSaver";
import { CalendarAPI } from "../../../../Services/CalendarAPI";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import { ConsultationMomService } from "../../../../Services/ConsultationMomService";
import { useQuery } from "../../../../utils/routeUtils";

const getFullName = (details) => {
  let name = "";
  if (details?.first_name) {
    name += details?.first_name + " ";
  }
  if (details?.middle_name) {
    name += details?.middle_name + " ";
  }
  if (details?.last_name) {
    name += details?.last_name + " ";
  }
  return name.trim();
};
const getSummery = (type, referral_code, advisorName, userName) => {
  return type + " : " + userName + `(${referral_code})` + " <> " + advisorName;
};
const ReschedulePopup = ({
  toggleBackdrop = () => {},
  type,
  advisor,
  time,
  call_booking_id,
  bookingModelPopupStatus,
  rescheduleObj,
}) => {
  const state = useLocation();
  const params = useParams();
  const userIdFromQueryParam = useQuery().get("user_id");
  const userIdFromURL = params?.userId;
  function getUserId(userIdFromQueryParam, userIdFromURL){
    if(userIdFromURL?.userId){
      return userIdFromURL.userId;
    }
    else if(userIdFromQueryParam){
      return userIdFromQueryParam;
    }
    return {};
  }
  const { userId: UserID } = getUserId(userIdFromURL,userIdFromQueryParam);
  const [user] = useState( state?.state?.item || state?.state);
  const [advisorDetails] = useState(
    state?.state?.advisor_details || state?.state?.item?.advisor_details
  );
  const [loading, setLoading] = useState(false);

  const [durations] = useState(allDurations);
  const [advisors, setAdvisors] = useState([]);
  const getType = (type) => {
    return allConsultationType.filter((d) =>
      type ? d.value.replaceAll("_", " ") === type : d
    )[0];
  };
  const [selectedConsultationType, setSelectedConsultationType] = useState(
    state?.state?.registered
      ? getType(type)
      : allConsultationTypeUnregistered[0]
  );
  const [selectedAdvisor, setSelectedAdvisor] = useState({
    ...(advisorDetails && {
      ...advisorDetails,
      value: advisorDetails.external_id,
      label: getFullName(advisorDetails),
    }),
  });
  const [selectedDuration, setSelectedDuration] = useState(allDurations[1]);
  const date = time ? new Date(time).setSeconds(0) : new Date().setSeconds(0);
  const [startTime, setStartTime] = useState(new Date(date));
  const [endTime, setEndTime] = useState(
    new Date(
      new Date(startTime).setMinutes(
        new Date(startTime).getMinutes() + +selectedDuration.value
      )
    )
  );
  const [events, setEvents] = useState([]);
  const [apiRequest, setApiRequest] = useState({
    type: "",
    message: "",
  });
  const [createEventPayloadBody, setCreateEventPayloadBody] = useState({});
  const [step, setStep] = useState(0);
  const [attendees, setAttendees] = useState(
    state?.state?.registered ? [] : [user.email]
  );
  const [currentAttendee, setCurrentAttendee] = useState("");
  useEffect(() => {
    setEndTime(
      new Date(
        new Date(startTime).setMinutes(
          new Date(startTime).getMinutes() + selectedDuration.value
        )
      )
    );
  }, [startTime, selectedDuration]);

  useEffect(() => {
    setApiRequest({
      type: "",
      message: "",
    });
  }, [step]);

  useEffect(() => {
    (async () => {
      const res = await getadvisor();
      const advisors = res.map((item) => {
        return {
          ...item,
          value: item.user_details.external_id,
          label: item.user_details && getFullName(item.user_details),
        };
      });
      setAdvisors([...advisors]);
    })();
  }, []);
  useEffect(() => {
    advisor && setSelectedAdvisor(advisor);
  }, [advisor]);

  const isUser = () => {
    return state?.state?.registered;
  };

  useEffect(() => {
    const dataOfAdvisor = selectedAdvisor?.user_details || selectedAdvisor;
    user &&
      dataOfAdvisor &&
      setCreateEventPayloadBody({
        user_id: isUser() ? user.user_id : "",
        advisor_id: selectedAdvisor?.external_id || selectedAdvisor?.value,
        start_time: moment(startTime).valueOf(),
        end_time: moment(endTime).valueOf(),
        summary: convertToPascalCase(
          getSummery(
            selectedConsultationType.value,
            user.referral_code,
            getFullName(dataOfAdvisor),
            user.name
          )
        ),
        attendees: [...attendees].map((it) => {
          return {
            email: it,
          };
        }),

        type: selectedConsultationType?.value,

        session_name: selectedConsultationType.value,
        created_by: JSON.parse(localStorage.getItem("email")),
        description: getEventDescription(
          user.name,
          selectedConsultationType.value,
          timeUtil.getDateAndTime(new Date(startTime)).date,
          timeUtil.getDateAndTime(new Date(startTime)).time,
          call_booking_id
        ),
      });
  }, [
    endTime,
    selectedAdvisor,
    selectedAdvisor?.value,
    selectedConsultationType.value,
    startTime,
    user,
    attendees,
  ]);

  const submit = async (e) => {
    e.preventDefault();
    const _continue = window.confirm(
      (call_booking_id ? "Edit" : "Create") +
        " Event Confirmation: Are you sure you want to " +
        (call_booking_id ? "Edit" : "Create") +
        " this event?"
    );
    if (!_continue) {
      return;
    }
    // call auth api
    setApiRequest({
      type: "pending",
      message: "Please wait, Event is getting created!",
    });
    try {
      const finalPayload = {
        ...createEventPayloadBody,
        ...(call_booking_id && {
          status: "RESCHEDULE",
          previous_call_id: call_booking_id,
        }),
      };
      const res = await CalendarAPI.createEvent(finalPayload);

      if (res.code === undefined) {
        setApiRequest({
          type: "success",
          message: "Event Created Successfully!!",
        });
      } else {
        setApiRequest({
          type: "error",
          message: res.message
            ? "Error: " + res.message
            : "Something went wrong! ",
        });
      }
    } catch (e) {
      setApiRequest({
        type: "error",
        message: "Error, " + e.message,
      });
    }
  };

  useEffect(() => {
    setCurrentAttendee("");
  }, [attendees]);

  useEffect(() => {
    (async () => {
      (await selectedAdvisor?.value) &&
        getEvents(selectedAdvisor?.external_id || selectedAdvisor?.value);
    })();
  }, [selectedAdvisor, startTime, bookingModelPopupStatus, apiRequest]);

  const getEvents = async (id) => {
    setLoading(true);
    try {
      const res = await CalendarAPI.getEvents(
        id || selectedAdvisor?.external_id,
        moment(startTime).valueOf()
      );

      // process data for list
      const _events = res.map((event) => {
        return {
          ...event,
          title: event && event.summary,
          timeSlot:
            event &&
            `${timeUtil.getTime24HourFormat(
              event.start
            )} - ${timeUtil.getTime24HourFormat(event.end)}`,
        };
      });
      // set to state events
      setEvents([..._events]);
      setLoading(false);
    } catch (error) {
      toast.error("Oops something went wrong");
      setLoading(false);
    }
  };

  const deleteEvent = async (event) => {
    const confirm = window.confirm(
      "Delete Event Confirmation: Are you sure you want to delete this event?"
    );
    if (confirm) {
      setLoading(true);
      const email = localStorage.getItem(LOCAL_DB_KEYS.EMAIL);
      const obj = {
        ...event,
        status: "CANCELED",
        call_booking_id: event.call_booking_id,
        requester_id: UserID,
      };
      try {
        const res = await ConsultationMomService.updateConsultationStatus(
          UserID,
          obj,
          email
        );
        toast.success("Deleted successfully!");
        await getEvents();
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong!");
        setLoading(false);
      }
    }
  };
  const FormFillProp = {
    user,
    currentAttendee,
    getFullName,
    setStep,
    step,
    type,
    setAttendees,
    setCurrentAttendee,
    state,
    selectedConsultationType,
    setSelectedConsultationType,
    advisors,
    selectedAdvisor,
    setSelectedAdvisor,
    advisorDetails,
    isUser,
    attendees,
  };
  const DateAndTimePickerForBookingProps = {
    startTime,
    setStartTime,
    selectedDuration,
    durations,
    setSelectedDuration,
    endTime,
  };
  const EventDescriptionProps = {
    step,
    selectedAdvisor,
    setCreateEventPayloadBody,
    setStep,
    createEventPayloadBody,
    apiRequest,
    submit,
    call_booking_id,
  };

  const slotUiProps = {
    toggleBackdrop,
    deleteEvent,
    loading,
    events,
    call_booking_id,
    selectedAdvisor,
  };
  return (
    <StyledReschedulePopup>
      <h2 className="rescheduleHeading">
        {call_booking_id ? "Reschedule" : "Create"} Event
      </h2>
      <div
        className={
          step === 0
            ? "description-input-container visible"
            : "description-input-container hidden"
        }
      >
        <div className="flex-row whiteBg">
          <FormFillForBooking {...FormFillProp} />

          <div className="vertical-divider"></div>
          <DateAndTimePickerForBooking {...DateAndTimePickerForBookingProps} />
          <SlotsUi {...slotUiProps} />
        </div>
      </div>
      <EventDescription {...EventDescriptionProps} />
    </StyledReschedulePopup>
  );
};

export default ReschedulePopup;

export const FormFillForBooking = ({
  user,
  getFullName,
  currentAttendee,
  setStep,
  step,
  setAttendees,
  setCurrentAttendee,
  state,
  selectedConsultationType,
  setSelectedConsultationType,
  advisors,
  selectedAdvisor,
  setSelectedAdvisor,
  advisorDetails,
  isUser,
  type,
  attendees
}) => {
  console.log(user,'user3');
  
  return (
    <div className="heading">
      <h2 className="create-event-title">User Details</h2>
      <p className="label">Client Name</p>
      <CustomSelect
        placeholder="Select Client"
        id="user"
        name="user"
        defaultValue={
          user && {
            ...user,
            value: user?.user_id,
            label: user?.name,
          }
        }
        isMulti={false}
        isDisabled={true}
      />
      <p className="label">Consultation Type</p>
      <CustomSelect
        tags={
          state?.state?.registered
            ? allConsultationType
            : allConsultationTypeUnregistered
        }
        placeholder="Select Consultation Type"
        id="consultationType"
        name="consultationType"
        value={selectedConsultationType}
        handleInput={(item) => setSelectedConsultationType(item)}
        defaultValue={selectedConsultationType}
        isMulti={false}
      />
      <p className="label">Advisor</p>
      <CustomSelect
        tags={advisors}
        placeholder="Select Advisor"
        id="advisore"
        name="advisore"
        value={selectedAdvisor}
        handleInput={(item) => setSelectedAdvisor(item)}
        isDisabled={type}
        isMulti={false}
      />

      {isUser() && (
        <>
          <p className="label">Attendees</p>
          {attendees.map((it) => {
            return <p style={{ margin: 0 }}>{it}</p>;
          })}
          <div style={{ display: "flex" }}>
            <Input
              key={attendees.length}
              label=""
              type="search"
              value={currentAttendee}
              placeholder="Attendee Email"
              validator={isEmailValid}
              onChange={(v) => setCurrentAttendee(v)}
              validatorError="Enter valid email"
            />

            <Button
              style={{
                marginTop: 10,
                marginLeft: 10,
                width: 50,
              }}
              buttonName="Add"
              onButtonClick={() => {
                if (isEmailValid(currentAttendee)) {
                  setAttendees([...attendees, currentAttendee]);
                  setCurrentAttendee("");
                }
              }}
            />
          </div>
        </>
      )}
      <Button buttonName="NEXT" onButtonClick={() => setStep(step + 1)} />
    </div>
  );
};

export const DateAndTimePickerForBooking = ({
  startTime,
  setStartTime,
  selectedDuration,
  durations,
  setSelectedDuration,
  endTime,
}) => {
  return (
    <div className="heading">
      <h2 className="create-event-title">Date &amp; Time</h2>
      <FlexRow
        style={{
          justifyContent: "start",
          alignItem: "center",
          marginTop: "-14px",
        }}
      >
        <div>
          <p className="label">Date</p>
          <CustomDatePicker
            value={timeUtil.getTime(startTime)}
            defaultValue={timeUtil.getTime(startTime)}
            onChange={(e) => setStartTime(new Date(e.target.value))}
          />
        </div>
        <div style={{ width: "100%", marginTop: "8px" }}>
          <p className="label">Duration</p>
          <CustomSelect
            style2={{
              marginLeft: "12px",
              display: "block",
              flex: 1,
            }}
            value={selectedDuration}
            tags={durations}
            placeholder="Select Duration"
            id="duration"
            name="duration"
            handleInput={(item) => {
              setSelectedDuration(item);
            }}
            handleOnBlur={() => {}}
            defaultValue={selectedDuration}
            isMulti={false}
          />
        </div>
      </FlexRow>
      <FlexRow>
        <div style={{ flex: 1 }}>
          <p className="label">Start Time</p>
          <StyledTimePicker
            style={{
              marginLeft: "-10px",
              width: "180px",
            }}
            value={moment(startTime)}
            minuteStep={5}
            showSecond={false}
            onChange={(e) => {
              setStartTime(new Date(moment(e).format()));
            }}
            clearIcon={<AiFillCloseCircle />}
          />
        </div>
        <div style={{ flex: 1, marginTop: "0" }}>
          <p className="label" style={{ marginTop: "8px" }}>
            End Time
          </p>
          <StyledTimePicker
            style={{
              marginLeft: "-0px",
              width: "180px",
            }}
            value={moment(endTime)}
            minuteStep={5}
            showSecond={false}
            clearIcon={<AiFillCloseCircle />}
            disabled={true}
          />
        </div>
      </FlexRow>
    </div>
  );
};

export const EventDescription = ({
  step,
  selectedAdvisor,
  setCreateEventPayloadBody,
  setStep,
  createEventPayloadBody,
  apiRequest,
  call_booking_id,
  submit,
}) => {
  return (
    <div
      className={
        step === 1
          ? "description-input-container visible whiteBg "
          : "description-input-container hidden whiteBg "
      }
    >
      <h2 className="create-event-title">Event Summary & Description</h2>
      <div>
        <textarea
          key={selectedAdvisor}
          className="event-title-text"
          onChange={(e) =>
            setCreateEventPayloadBody({
              ...createEventPayloadBody,
              summary: convertToPascalCase(e.target.value),
            })
          }
          value={createEventPayloadBody && createEventPayloadBody.summary}
        ></textarea>
      </div>
      <div>
        <textarea
          key={createEventPayloadBody}
          className="event-body-text"
          onChange={(e) =>
            setCreateEventPayloadBody({
              ...createEventPayloadBody,
              description: e.target.value,
            })
          }
          value={createEventPayloadBody && createEventPayloadBody.description}
        ></textarea>
      </div>
      <div className="flex-row">
        <Button
          style={{ backgroundColor: "#b4b4b4", border: 0 }}
          buttonName="BACK"
          onButtonClick={() => setStep(step - 1)}
        />
        <Button
          onDisable={
            apiRequest.type === "success" || apiRequest.type === "pending"
          }
          buttonName={call_booking_id ? "Edit" : "CREATE"}
          style={{
            marginLeft: "20px",
            backgroundColor:
              apiRequest.type === "success" || apiRequest.type === "pending"
                ? "#b4b4b4"
                : "",
            border:
              apiRequest.type === "success" || apiRequest.type === "pending"
                ? "0px"
                : "",
          }}
          onButtonClick={submit}
        />
      </div>
      {apiRequest.message && (
        <div className={"api-message " + apiRequest.type}>
          {apiRequest.message}
        </div>
      )}
    </div>
  );
};

export const SlotsUi = ({
  toggleBackdrop,
  deleteEvent,
  loading,
  events,
  call_booking_id,
  selectedAdvisor,
  setLoading,
}) => {
  return (
    <div className="heading">
      <h2 className="create-event-title">Slots</h2>
      <div
        className="list-view"
        style={{
          marginTop: "40px",
          ...(loading && {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }),
        }}
      >
        {loading ? (
          <LoaderIcon
            style={{
              width: 36,
              height: 36,
              textAlign: "center",
            }}
          />
        ) : (
          events.map((event, index) => {
            return (
              <TimeSlot
                key={index}
                call_booking_id={call_booking_id}
                status="available"
                title={event.title}
                event={event}
                onCancelClick={() => deleteEvent(event)}
                toggleBackdrop={toggleBackdrop}
                selectedAdvisor={selectedAdvisor}
              >
                {event.timeSlot}
              </TimeSlot>
            );
          })
        )}
      </div>
    </div>
  );
};
