import React from "react";
import { SentMessageContainer } from "./SentMessage.styled";
import DocumentPreview from "../DocumentPreview/index";
import DownloadAttachmentComponent from "../DownloadAttachmentComponent/index";
import renderHTML from "../../../../utils/renderHTML";

const SentMessage = ({ message, attachments, time }) => {
  return (
    <SentMessageContainer>
      <span>{time}</span>
      <div className="message_content">
        {attachments?.length > 0 && (
          <div className="chat_attachment">
            {attachments?.map((file, i) => {
              return (
                <DownloadAttachmentComponent
                  link={file?.link}
                  name={file?.name}
                  index={i}
                  style={{ width: "100%" }}
                >
                  <DocumentPreview index={i} data={file} />
                </DownloadAttachmentComponent>
              );
            })}
          </div>
        )}
        {message && <p>{renderHTML(message, "", "")}</p>}
      </div>
    </SentMessageContainer>
  );
};

export default SentMessage;
