import styled from 'styled-components';
import { dim } from '../../../../styles/theme';

export const CapitalGainStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	height: auto;
	margin: ${dim._20px} 0 0 0;
	padding: 0 0 0 0;
	box-sizing: border-box;
`;
