import { actions } from "./ConsultationAction";

const initialState = {
  consultationData: {},
  momData: null,
  consultationId: undefined,
};

const ConsultationReducer = (state = initialState, payload = {}) => {
  switch (payload.type) {
    case actions.SET_CONSULTATION_DATA:
      return {
        ...state,
        consultationData: payload.data,
      };
    case actions.SET_MOM_DATA:
      return {
        ...state,
        momData: payload.data,
      };
    case actions.SET_CONSULTATION_ID:
      return {
        ...state,
        consultationId: payload.data,
      };

    default:
      return state;
  }
};

export { ConsultationReducer };
