import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Button from "../../../../commonComponents/Button";
import { PendingAdvisorDocStyled } from "../../../../commonComponents/PendingAdvisorDoc/PendingAdvisorDoc.styled";
import PopUPCms from "../../../../commonComponents/PopUPCms";
import Backdrop from "../../../../commonComponents/PopUPCms/BackDrop";
import { deleteExemption } from "../../../../Services/Exemption";
import { getUserDocument } from "../../../../Services/SuperSaver";
import { deleteElement } from "../../../../utils/deleteElement";
import ClientProfile from "../ClientProfile";
import UploadEXEMPTIONForm from "../UploadEXEMPTIONForm";
import Row from "./Row";
import LayoutHOC from "../../../../components/UserSideBar";

const ExemptionPage = ({ type }) => {
  const [show, setShow] = useState(false);
  const [docArr, setDocArr] = useState([]);
  const getData = (arr) => {
    const docArr = arr;
    let newArr = [];
    for (let index = 0; index < docArr.length; index++) {
      for (let i = 0; i < docArr[index].type_details.length; i++) {
        newArr = [
          ...newArr,
          {
            type: docArr[index].type,
            ...docArr[index].type_details[i],
          },
        ];
      }
    }
    return newArr;
  };

  const handleCross = () => {
    setShow(false);
  };
  const onHideModelClick = () => {
    setShow(true);
  };
  const state = useLocation();

  const updateDocArr = (value) => {
    setDocArr([...value]);
  };

  useEffect(() => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          getUserDocument(data, state.state.user_id).then((data) => {
            updateDocArr(getData(data.exemption_details.exemption_details));
          });
        });
      }
    });
  }, [state.state]);

  const handleDeleteExemption = (value) => {
    deleteElement(value, docArr, updateDocArr);
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          deleteExemption(data, value.external_id);
        });
      }
    });
  };

  return (
    <div
      className=""
      style={{
        width: "100%",
      }}
    >
      <ClientProfile userDetails={state.state} view={"Exemption Page View"} />
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h3
          className="superHeading"
          style={{ color: "#7F7F7F", margin: "10px 0 0" }}
        >
          Uploaded EXEMPTION
        </h3>
        {type === "advisor" && (
          <Button
            onButtonClick={() => onHideModelClick()}
            buttonName="New Upload +"
            buttonClassName="Block"
          />
        )}
      </div>

      <PendingAdvisorDocStyled>
        <div className="head">
          <p className="c1">Exemption Type</p>
          <p className="c2">Exemption Details</p>
          <p className="c3">Total Amount </p>
          <p className="c4">Total Exemption </p>
          <p className="c4">EXEMPTION Limit</p>
          <p className="c5">Action</p>
        </div>

        {docArr.map((d, i) => {
          return (
            <Row
              data={d}
              docArr={docArr}
              handleDeleteExemption={handleDeleteExemption}
            />
          );
        })}

        {docArr.length === 0 && (
          <p style={{ margin: "10px auto" }}>No data available</p>
        )}
      </PendingAdvisorDocStyled>
      <PopUPCms show={show} top={8} handleCross={handleCross}>
        <UploadEXEMPTIONForm
          handleCross={handleCross}
          userId={state.state.user_id}
          docArr={docArr}
          updateDocArr={updateDocArr}
        />
      </PopUPCms>

      <Backdrop onHideModelClick={handleCross} show={show} />
    </div>
  );
};

export default LayoutHOC(ExemptionPage);
