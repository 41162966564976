import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const ChatHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${dim._48px};
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 2px solid #f7f7f7;
  box-sizing: border-box;

  .create_new_chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 20px 0 0;
    padding: ${dim._4px} 0 ${dim._4px} ${dim._16px};
    box-sizing: border-box;
  }

  .chat_assistance {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
  }

  .divider {
    width: ${dim._1px};
    height: ${dim._32px};
    background-color: #e5e5e5;
  }
`;
