import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledUpComingSessionList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${dim._34px};
  padding-top: ${dim._22px};
  padding-bottom: ${dim._24px};
  padding-left: ${dim._4px};
`;
