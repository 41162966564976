import styled from "styled-components";

export const StyledEditor=styled.div`
width:100%;
border-radius: 8px;
background:#FFF;
min-height: 200px;

box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.08), 0px 1px 4px -2px rgba(16, 24, 40, 0.05);

.public-DraftEditor-content .public-DraftStyleDefault-block {
  margin: 0;
  padding: 0;
 
}
.editorWrapper{
    display: flex;
    flex-direction:column;
    gap:16px;
  }
`