import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import LoaderPage from "../../../../commonComponents/LoaderPage";
import { SuperSaverDashboardService, getOnboardingFormData } from "../../../../Services/SuperSaver";
import { ClientDetails } from "../../../SuperClientProfile/SuperSaverProfile.styled";
import ClientProfile from "../ClientProfile";
import { OnboardingDataStyled } from "./OnboardingDataStyled";
import { getAuth } from "firebase/auth";
import { getVersion } from "../../../../utils/taxUtils";
import { onboardingArray } from "./utils";
import LayoutHOC from "../../../../components/UserSideBar";

const HEADINGFORONBOARDINGFORM = "OnBoarding Form Data";
const OnboardingData = () => {
  const [surwayInfo, setSurwayInfo] = useState([]);
  const [isLoaderActive, setIsLoaderActive] = useState(true);
  const [answerDetailsArr, setAnswerDetailsArr] = useState([]);
const [newOnboardingData, setNewOnboardingData] = useState([])
  const INCOME_QUESTION_ID = "d3a670a6-d861-4903-b041-2a025e2f47e0";
  const HOUSE_RENT_QUESTION_ID = "9c4eaa56-c83f-4ec7-90f4-587af1283c29";

  const state = useLocation().state;

  const user_id = state.user_id;

  const appVersion=getVersion(state.appVersions, '1.1');

  const getOnboardingData = () => {
    try {
      getAuth().onAuthStateChanged(function (user) {
        if (user) {
          user.getIdToken().then(function (newToken) {
            getOnboardingFormData(newToken, user_id).then((d) => {
              setSurwayInfo(d.question_details);

              let arr = [];
              for (let index = 0; index < d.question_details.length; index++) {
                d.question_details[index]?.answer_details?.details?.text &&
                  arr.push(d.question_details[index]?.answer_details?.details);
              }
              setAnswerDetailsArr(arr);
              setIsLoaderActive(false)
            });
          });
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoaderActive(false)
    }
  };
  const getOnboardingDetailsByIntelligence=async()=>{
    try{const intelligenceData = await SuperSaverDashboardService.getDataForIntelligence(state.user_id);
      setNewOnboardingData( onboardingArray(intelligenceData.data));
      setIsLoaderActive(false);
    }catch(e){
      setIsLoaderActive(false);
    }

  }

  useEffect(() => {
    appVersion==='1.0'?getOnboardingData():getOnboardingDetailsByIntelligence();
    
  }, []);

  return !isLoaderActive  ? (
    <OnboardingDataStyled>
      <ClientProfile userDetails={state} view={HEADINGFORONBOARDINGFORM} />
      <h2>{HEADINGFORONBOARDINGFORM}</h2>

      {answerDetailsArr.length > 0 ||newOnboardingData.length > 0 ? (
        <div className="ClientWrap">
          { appVersion==='1.0'? surwayInfo
            .sort((a, b) => a.sequence - b.sequence)
            .map(
              (d, i) =>
                d.answer_details?.details && (
                  <div className="clientBox" key={i}>
                    {<div className="ClientHed">{d.details.text} </div>}
                    {
                      <ClientDetails className="ClientDetails">
                        {d.question_id !== INCOME_QUESTION_ID&&d.question_id!==HOUSE_RENT_QUESTION_ID ? (
                          <div className="data">
                            {d.answer_details?.details?.options
                              ?.map((d, i) => d.text)
                              .join(", ")}
                          </div>
                        ) : (
                          <div className="data">
                            {d.answer_details.details.text}
                          </div>
                        )}
                      </ClientDetails>
                    }
                  </div>
                )
            ): newOnboardingData.sort((a,b)=>a.priority-b.priority).map((d,i)=>( <div className="clientBox" key={i}>
            {<div className="ClientHed">{d.title} </div>}
            {
              <ClientDetails className="ClientDetails">
                { (
                  <div className="data">
                    {d.value}
                  </div>
                )}
              </ClientDetails>
            }
          </div>))}
        </div>
      ) : (
        <div className="">user hasn’t answed any question yet </div>
      )}
    </OnboardingDataStyled>
  ) : (
    <LoaderPage />
  );
};

export default LayoutHOC(OnboardingData);
