import styled from 'styled-components';
import { dim } from '../../styles/theme';

export const RadioButtonStyle = styled.div`
	display: flex;
	margin: 0 ${dim._16px} 0 0;

	.radio {
		display: flex;
		justify-content: center;
		align-items: center;
		width: ${dim._16px};
		height: ${dim._16px};
		margin: 0 ${dim._8px} 0 0;
		padding: auto;
		background: #ffffff;
		border: ${dim._1px} solid #bbbbbb;
		border-radius: ${dim._42px};
		cursor: pointer;
		box-sizing: border-box;
	}

	.inRadio {
		width: ${dim._8px};
		height: ${dim._8px};
		background: ${(props) => (props.active ? '#15be53' : '#bbbbbb')};
		border-radius: 100%;
	}

	input {
		margin: 0 ${dim._8px} 0 0;
	}

	label {
		font-weight: 400;
		font-size: ${dim._12px};
		line-height: ${dim._16px};
		color: #212f45;
	}
`;
