import Button from "../../commonComponents/Button";
import "./login.css";
import {getUser, login, signInWithGoogle} from "../../firebase";
import {useRef, useState} from "react";
import {getType} from "../../Services/AdvisorDoc";
import {LOCAL_DB_KEYS} from "../../constants/localStorage";
import Divider from "../../commonComponents/Divider";
import GoogleBtn from "../../commonComponents/GoogleBtn";
import {dim, theme} from "../../styles/theme";
import toast from "react-hot-toast";

const LoginPage = (props) => {
  const [loader, setLoader] = useState(false);
  const {setType} = props;
  const emailRef = useRef();
  const passwordRef = useRef();

  async function handleLogin(e) {
    setLoader(true);
    e.preventDefault();
    var email = emailRef.current.value;
    var password = passwordRef.current.value;
    let snap = "";
    if (email.length <= 6 && password.length <= 5) {
    } else {
      try {
        const userCredential = await login(email, password);
        const user = userCredential.user;
        snap = await getUser(user.uid);
        const typeOfUser = await getType();

        if (typeOfUser.code === undefined) {
          localStorage.setItem(
            LOCAL_DB_KEYS.CURRENT_USER,
            JSON.stringify(typeOfUser)
          );
          setType(typeOfUser);
          localStorage.setItem("type", JSON.stringify(typeOfUser));
          const userData = await snap.val();
          let name = userData?.displayName;
          let dataOfUser = userData;
          if(!name){
            dataOfUser = {...dataOfUser , displayName:"Guest"}
          }
          localStorage.setItem("user", JSON.stringify(dataOfUser));
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("email", JSON.stringify(email));

          props.setLoggedIn();
        } else {
          alert("Please use correct credential");
          setLoader(false);
        }
      } catch (e) {
        var error_message = e.message;
        setLoader(false);
        alert(error_message);
      }
    }
  }
  const handleValueToBeSavedAtLogin = async (userCredential) => {
    const toastId = toast.loading("Loading...");

    const user = userCredential?.user;
    const email = userCredential?.email;
    let snap = "";
    snap = await getUser(user.uid);
    const typeOfUser = await getType();
    if (typeOfUser.code === undefined) {
      localStorage.setItem(
        LOCAL_DB_KEYS.CURRENT_USER,
        JSON.stringify(typeOfUser)
      );
      setType(typeOfUser);
      localStorage.setItem("type", JSON.stringify(typeOfUser));
      const userData = await snap.val();
      userData && localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("email", JSON.stringify(email));

      props.setLoggedIn();

      toast.dismiss(toastId);
    } else {
      alert("Please use correct credential");
      setLoader(false);
      toast.dismiss(toastId);
    }
  };
  return (
    <div className="parentLogin">
      <form className="LoginForm">
        <p>Login to Prosperr CMS</p>

        <input
          type="email"
          id="first_name"
          name="email"
          placeholder="*Email"
          onChange={(e) => {
            props.setUser(e.target.value);
          }}
          ref={emailRef}
        />
        <input
          type="password"
          id="middle_name"
          name="password"
          placeholder="*Password"
          ref={passwordRef}
        />
        <Button
          buttonName={loader ? "loading..." : "Login"}
          style={{
            width: "100%",
          }}
          onButtonClick={handleLogin}
        />
        <Divider
          style={{
            background: theme.color.grey_100,
            width: "100%",
            margin: `${dim._16px} 0`,
            marginLeft: 0,
            marginRight: 0,
          }}
          width={"100%"}
          height={1}
        >
          Or
        </Divider>

        <GoogleBtn
          style={{height: "48px", width: "100% ", borderRadius: "58px"}}
          onClick={(e) => {
            signInWithGoogle(handleValueToBeSavedAtLogin);
          }}
        />
      </form>
    </div>
  );
};

export default LoginPage;
