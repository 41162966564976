import styled from "styled-components";

export const UploadAdvDocFormStyled = styled.div`
  background: #fff;
  margin: auto;
  height: auto;
  border-radius: 8px;
  width: 433px;
  position: relative;
  .popContent {
    font-size: 20px;
    margin: 0 60px 0 60px;
    color: #808080;
    padding-top: 30px;
  }
  .popTitle {
    margin: 0 auto;
    text-align: center;
    justify-content: center;
  }
  .closePop {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .input {
    display: flex;
    flex-direction: column;
    margin: 26px 60px;
    /* position: absolute; */
  }
  .cb {
    display: none;
  }

  .button {
    /* position: absolute; */
    display: block;
    width: 155px;
    height: 50px;
    /* top: 175px; */
    /* left: 150px; */
    border-radius: 25px;
    text-align: center;
    line-height: 46px;
    text-transform: uppercase;
    font-weight: 600;
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    opacity: ${(props) => (props.disable ? "1" : "0.5")};
    cursor: ${(props) => (props.disable ? "pointer" : "not-allowed")};

    transform: ${(props) => (props.checked ? "rotateX(180deg)" : "none")};
    box-shadow: ${(props) =>
      props.checked
        ? "4px -8px 12px 0 rgba(0, 0, 0, 0.1)"
        : "4px 8px 12px 0 rgba(0, 0, 0, 0.1)"};
  }
  .inputdiv {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #212f45;
    margin-bottom: 8px;
  }
  .inputUploadText {
    width: 100%;
    height: 35px;
    margin: 0;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #f1f5f7;
    background: #f1f5f7;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    color: #212f45;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  .uploadAdvisorDoc {
    width: 100%;
    height: 100px;
  }
  .icon {
    width: 50px;
  }
`;
