import styled from "styled-components";

export const StyledFormErrorMsgHandler = styled.p`
  font-size: 10px;
  line-height: 1.2em;
  font-weight: 600;
  color: #eb3432;
  white-space: nowrap;
  position: absolute;
  bottom: -14px;
  left: 0;
`;
