import styled from "styled-components";

const PopUPCmsStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${(props) => props.width + "%"};
  max-width: 1366px;
  height: auto;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 50%;
height: auto;
width: auto;
  top: 50%;
  z-index: 10200;
  transform: translate(-50%, -50%);
  transition: height 1s ease-out;
  box-sizing: border-box;

  .crossIcon {
    display: ${(props) => (props.show ? "flex" : "none")};
    font-size: 30px;
    color: #fff;
    transform: rotate(45deg);
    position: absolute;
    right: 48px;
    top: -48px;
    cursor: pointer;
  }
`;

export default PopUPCmsStyle;
