import React, { useState } from "react";

import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { DocumentDropdownWrapper } from "./DocumentDropdown.styled";

const { Option } = components;
const DocumentDropdown = ({
  handleDocumentName,
  documentType,
  documentList,
}) => {

  const CustomOption = (props) => {
    return (
      <Option {...props}>
        <CompanyOptions {...props} />
      </Option>
    );
  };
  const CompanyOptions = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>{props?.label}</div>
      </div>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      backgroundColor: "#f1f5f7",
      boxSizing: "border-box",
      width: "100%",

      height: "35px",
      background: "#f1f5f7",
      borderRadius: "4px",
      border: "none",
      outlineColor: "#898989",
    }),
  };

  const filterOptions = (option, searchText) => {
    const { label, data } = option;
    const searchString =
      `${label} ${data.name} ${data.brand_name}`.toLowerCase();
    return (
      searchString.indexOf(searchText.toLowerCase()) > -1 ||
      label === "+ Add Company" //OTHER
    );
  };
  return (
    <DocumentDropdownWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "top",
          flexDirection: "column",
          width: "220px",
        }}
      >
        <CreatableSelect
          key={"no-Brokers"}
          options={[...documentList]}
          value={documentType}
          isSearchable={true}
          placeholder="Select Document..."
          components={{ Option: CustomOption }}
          styles={customStyles}
          filterOption={filterOptions}
          onChange={(data) => {
            handleDocumentName(data);
          }}
        />
      </div>
    </DocumentDropdownWrapper>
  );
};

export default DocumentDropdown;
