import styled from "styled-components";

export const StyledCallSummary = styled.div`
  width: 100%;
  /* background-color: #fff; */
  /* border-radius: 4px;
  padding: 0 32px; */
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: ${({ isEditDisabled }) =>
    isEditDisabled ? "not-allowed" : "normal"};
    .leave-a-note-header{
      text-align: center;
    }
`;
