import React from "react";

const TextButton = ({
  children,
  disabled = false,
  onClick = () => {},
  style = {},
  className,
}) => {
  return (
    <button
      disabled={disabled}
      style={{
        color: "#15BE53",
        border: 0,
        backgroundColor: "transparent",
        cursor: "pointer",
        ...style,
      }}
      onClick={onClick}
      className={className}
    >
      {children}
    </button>
  );
};

export default TextButton;
