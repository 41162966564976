const modifyDIffrentIncomeDataFn = (incomeType, d) => {
	if (incomeType === 'INCOME_FROM_HOUSE_RENT') {
		return {
			...d,
			type: d.sub_type,
			subType: d.sub_type,
			details: d.label,
			amount: d.amount,
			sellingPrice: d.municipal_tax,
			document: d.document_link,
			totalAmount: d.interest,
			isSold: d.is_loan,
			startDate: d.start_date,
			endDate: d.end_date,
			isEditable: false
		};
	} else if (incomeType === 'BUSINESS_AND_PROFESSION') {
		return {
			...d,
			type: d.sub_type,
			subType: d.sub_type,
			details: d.label,
			amount: d.amount,
			totalAmount: d.turnover,
			sellingPrice: d.expenditure,
			document: d.document_link,
			startDate: d.start_date,
			endDate: d.end_date,
			isEditable: false
		};
	} else if (incomeType === 'CAPITAL_GAIN') {
		return {
			...d,
			type: d.sub_type.split('_')[0],
			subType: d.sub_type.split('_')[1],
			details: d.label,
			amount: d.amount_invested,
			sellingPrice: d.selling_amount,
			startDate: d.selling_date,
			document: d.document_link,
			isSold: d.is_sold,
			isEditable: false
		};
	} else if (incomeType === 'DIGITAL_ASSET') {
		return {
			...d,
			type: d.sub_type,
			subType: d.sub_type,
			details: d.label,
			amount: d.amount_invested,
			totalAmount: d.is_sold,
			sellingPrice: d.selling_amount,
			document: d.document_link,
			startDate: d.investment_date,
			isSold: d.is_sold,
			endDate: d.selling_date,
			isEditable: false
		};
	}
};

export { modifyDIffrentIncomeDataFn };
