import styled from "styled-components";

export const Main = styled.div`
  width: 100%;

  scroll-behavior: smooth;
  position: relative;
  max-height: 620px;
  height: 81vh;

  padding: 0 16px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .consMsg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .spinnerdiv {
    position: absolute;
    top: 40%;
    left: 44%;
  }

  .consMsg p {
    margin: 0 0 0 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #212f45;
  }

  p span {
    font-weight: 600;
  }
  .bar {
    margin: 0px;
  }
`;
