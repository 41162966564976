import React from "react";
import { StyledSuccessMessage } from "./SuccessMessage.styled";

const SuccessMessage = () => {
  const formData = {
    heading: "Get In Touch",
    successMessage: `Thank you for reviewing of payslip.`,
    successIcon: process.env.PUBLIC_URL + "/assets/images/succcess.png",
    submitButtonName: "Submit",
    closeDialogName: "CLOSE",
  };
  return (
    <StyledSuccessMessage>
      <img
        className="success-icon"
        src={formData.successIcon}
        alt="success"
      ></img>
      <div className="submitted-success">{formData.successMessage}</div>
      {/* <div className="text-btn" onClick={() => setShowModal(false)}>
        {formData.closeDialogName}
      </div> */}
    </StyledSuccessMessage>
  );
};

export default SuccessMessage;
