import styled from 'styled-components';

export const TableHeadStyle = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0 0 0 0;
	box-sizing: border-box;

	.col-1 {
		width: 40%;
	}

	.col-2 {
		width: 40%;
	}

	.col-3 {
		width: 20%;
	}

	div {
		font-weight: 400;
		font-size: 12px;
		color: #212f45;
	}
`;
