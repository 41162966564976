import styled from 'styled-components';
import { dim } from '../../../../styles/theme';

export const SalaryStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0 0 40px 0;
	box-sizing: border-box;

	.incomeStatus {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		column-gap: ${dim._24px};
	}

	.chart-wrapper-outside {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: ${dim._24px};
	}

	h2 {
		margin: 24px 0 16px 0;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
		color: #212f45;
		text-transform: capitalize;
	}

	h2 span {
		font-weight: 500;
	}
`;
