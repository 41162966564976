import React from 'react';
import {StyledMobileTableOfContent} from './MobileTableOfContent.styled';

const MobileTableOfContent = ({filteredHeadings}) => {
	return (
		<StyledMobileTableOfContent className='mobile-tableWrapper'>
			{filteredHeadings && filteredHeadings?.length > 0 && (
				<div className='mobile-table-of-contents'>
					<div className='mobile-table-of-content-title'>In this article</div>
					<ul>
						{filteredHeadings?.map((heading) => (
							<li
								key={heading?.id}
								onClick={() => {
									document
										.getElementById(heading?.id)
										.scrollIntoView({behavior: 'smooth'});
								}}
							>
								{heading?.text}
							</li>
						))}
					</ul>
				</div>
			)}
		</StyledMobileTableOfContent>
	);
};

export default MobileTableOfContent;
