import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
// import AdvisorContext from "../../Context/Advisor/AdvisorContext";

const EditDeletebtn = ({ HandleClick, d, Id, Style, arrayData }) => {
  // const a = useContext(AdvisorContext);

  return (
    <span
      style={{
        cursor: "pointer",
        top: "2px",
        position: "relative",
        left: Style,
        boxSizing: "boarder-Box",
      }}
    >
      {!arrayData.isAppointmentsActive ? (
        <AiOutlineDelete
          onClick={() => {
            HandleClick(d);
          }}
        />
      ) : (
        <MdOutlineCancel
          onClick={() => {
            HandleClick();
          }}
        />
      )}
    </span>
  );
};

export default EditDeletebtn;
