import React, { useState } from "react";
import styled from "styled-components";
import { dim } from "../../../styles/theme";

const Input = ({
    value,
    label,
    type = "text",
    placeholder = "Enter Value",
    explanation,
    onChange = () => {},
    validator = null,
    validatorError = "",
    style = {},
}) => {
    const [error, setError] = useState("");

    const _onChange = (str) => {
        if (validator != null && !validator(str) && str.length > 0) {
            setError(validatorError);
        } else {
            setError("");
        }
        onChange(str);
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <p
                style={{
                    marginTop: 12,
                    marginBottom: 4,
                    fontSize: 12,
                    color: "#6d6d6e",
                }}
            >
                {label}
            </p>
            <InputStyled
                style={style}
                className="input"
                type={type}
                placeholder={placeholder}
                defaultValue={value}
                onChange={(e) => _onChange(e.target.value)}
            ></InputStyled>
            <p style={{ fontSize: 12, color: "red" }}>{explanation || error}</p>
        </div>
    );
};

export default Input;

const InputStyled = styled.input`
    padding: ${dim._8px};
    width: 100%;
    box-sizing: border-box;
    outline: 0px;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    font-weight: bold;

    ::placeholder {
        font-weight: normal;
        text-transform: none;
    }

    :-ms-input-placeholder {
        font-weight: normal;
        text-transform: none;
    }

    ::-ms-input-placeholder {
        font-weight: normal;
        text-transform: none;
    }
`;
