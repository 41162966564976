const designSupportedWidth = 1280;
export const minSupportedWidth = 1024;
export const maxSupportedWidth = 1366;

export let { innerWidth } = window;
if (innerWidth < minSupportedWidth) {
  innerWidth = minSupportedWidth;
} else if (innerWidth > maxSupportedWidth) {
  innerWidth = maxSupportedWidth;
}

export const scaleFactor = innerWidth / designSupportedWidth;

export const getScaledValue = (value = 0) => {
  return `${value * scaleFactor}px`;
};
