import React, {useEffect, useState} from "react";
import {StyledInformationComponent} from "./InformationComponent.styled";
import styled from "styled-components";
import TagSelect from "../../../../components/Tags";
import UploadFileInput from "../UploadFileInput";
import {Switch} from "@mui/material";
import {FileUploadService} from "../../../../Services/FileUploadServices";
import {getUniqueFileName} from "../../../../utils/randomFileNameGenerator";
import toast from "react-hot-toast";
import {Tooltip} from "@material-ui/core";
import { aspectRatio } from "../../../../utils/ImageUtils";
const InformationComponent = ({content, setContent, authors, tags}) => {
  const [first, setfirst] = useState([]);
  
  const [arrayOfImages, setArrayOfImages] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const handleDropdownChange = (it, key = "tag", keyForArray = "tags") => {

    setContent((content) => {
      const contentObj = {
        ...content,
        [keyForArray]: it.map((_it) => {
   
          return {
            id: _it.value,
            [key]: _it.label,
          };
        }),
      };
      localStorage.setItem("content", JSON.stringify(contentObj));
      return contentObj;
    });
  };

  const handleActions = (name, value) => {
    setContent((content) => {
      const contentObj = {
        ...content,
        [name]: value,
      };
      localStorage.setItem("content", JSON.stringify(contentObj));
      return contentObj;
    });
  };

  const ListOfImage = (content) => {
    return [
      {
        label: "Desktop Thumbnail Image",
        tooltip: "Aspect ratio should be 10:7",
        aspectRatio:'10:7',
        value: content?.thumbnail_url,
        fileName: content?.thumbnail_url,
        name: "thumbnail_url",
      },
      {
        label: "Desktop Banner Image",
        tooltip: "Aspect ratio should be 10:3",
        aspectRatio:'10:3',
        value: content?.banner_url,
        fileName: content?.banner_url,
        name: "banner_url",
      },
      {
        label: "Mobile Thumbnail Image",
        tooltip: "Aspect ratio should be 2:1",
        aspectRatio:'2:1',
        value: content?.mobile_thumbnail_url,
        fileName: content?.mobile_thumbnail_url,
        name: "mobile_thumbnail_url",
      },
      {
        label: "Mobile Banner Image",
        tooltip: "Aspect ratio should be 343:170",
        aspectRatio:'343:170',
        value: content?.mobile_banner_url,
        fileName: content?.mobile_banner_url,
        name: "mobile_banner_url",
      },
    ];
  };
  const ArrayOfActionOfCardFn = ({content}) => {
    return [
      {
        label: "SEO Tag",

        component: () => {
          return (
            <TagSelect
              tags={tags?.map((it) => {
                return {label: it.tag_name.replaceAll('_',' '), value: it.tag_name};
              })}
              placeholder="Add Tags"
              value={content?.tags?.map((it) => {
                return {label: it?.tag.replaceAll('_',' '), value: it?.id};
              })}
              onChange={(it) => {
                handleDropdownChange(it);
              }}
            />
          );
        },
      },
      {
        label: "Author",
        component: () => (
          <TagSelect
            tags={authors?.map((it) => {
   
              return {
                label:`${ it.user_details.first_name} (${it.author_category.replaceAll('_',' ')})`,
                value: it.user_details.external_id,
              };
            })}
            placeholder="Add author"
            value={content?.author?.map((it) => {
      
              return {label: it?.author, value: it?.id};
            })}
            onChange={(it) => {
     
              handleDropdownChange([it], "author", "author");
            }}
            isMulti={false}
          />
        ),
      },    {
        label: "Show Verified",
       tooltip:'Verified badge which will be visible in UI',
        component: () => (
          <Switch
            checked={content?.is_verified}
            onChange={() => handleChange("is_verified", !content?.is_verified)}
            value={content?.is_verified}
          />
        ),
      },
      {
        label: "Show CTA",
        tooltip:'Button will be visible in UI for registration',
        component: () => (
          <Switch
            checked={content?.show_cta}
            onChange={() => handleChange("show_cta", !content?.show_cta)}
            value={!content?.show_cta}
          />
        ),
      },

  
    ];
  };
  const handleChange = (name, value) => {
    setContent((content) => {
      const contentObj = {
        ...content,
        [name]: value,
      };
      localStorage.setItem("content", JSON.stringify(contentObj));
      return contentObj;
    });
  };
  useEffect(() => {
    setfirst(ArrayOfActionOfCardFn({content}));
    setArrayOfImages(ListOfImage(content));
  }, [content]);

  const handleImageUpload = async (e, type,aspectRatioValue) => {



    setLoading(true);
    handleChange(type, "loading");
    const bucketName =
      process.env.REACT_APP_ARTICLE_BUCKET_NAME || "stage-articles-images";
    const zone = process.env.REACT_APP_BUCKET_ZONE || "eu-west-1";
    const file = await e.target.files[0];

    const fileName = file.name;
    if (fileName.endsWith(".svg")) {
      toast.error("SVG File is not allowed");
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = async() => {
          const imageAspectRatio = aspectRatio(img);
     
          if(imageAspectRatio!==aspectRatioValue){
            setLoading(false);
            handleChange(type, "");
            return toast.error('Aspect ration of image should be '+ aspectRatioValue)
          }

          let uniqueFileName = getUniqueFileName(file, type);
          try {
            const uploadRes = await FileUploadService.uploadFile(
              uniqueFileName,
              file,
              bucketName
            );
            // link to access image file from public bucket
            const imageUrl = `https://${bucketName}.s3.${zone}.amazonaws.com/${uniqueFileName}`;
      
            handleChange(type, imageUrl);
            setLoading(false);
          } catch (error) {
            toast.error('Oops something went wrong')
            setLoading(false);
            handleChange(type, "");
          }
        };
        img.src = e.target.result;
        
      };
      reader.readAsDataURL(file);
    }
   
  };

  return (
    <WrapperStyledForHeading>
      <StyledInformationComponent>
        <div className="title">Information</div>

        <div className="actions">
          <div className="articleSeoAndAuthorActions">
            {first.map((d, i) => {
              return (
                <div className="actionWrapper">
                  <div className="actionTitle">{d.label}
                 { d?.tooltip&&<Tooltip title={d.tooltip}>
                      <span className="userName">
                        <IButton />
                      </span>
                    </Tooltip>}
                  </div>
                  {<d.component /> }
                </div>
              );
            })}
          </div>
          <div className="articleSeoAndAuthorActions">
            {arrayOfImages.map((d, i) => {
              return (
                <div className="actionWrapper">
                  <div className="actionTitle">
                    {d.label}{" "}
                    <Tooltip title={d.tooltip}  >
                      <span className="userName">
                        <IButton />
                      </span>
                    </Tooltip>
                  </div>
                  <UploadFileInput
                    handleImageUpload={handleImageUpload}
                    name={d.name}
                    handleChange={handleChange}
                    {...d}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </StyledInformationComponent>
    </WrapperStyledForHeading>
  );
};

const IButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.21498 14.6667C11.8816 14.6667 14.8816 11.6667 14.8816 8C14.8816 4.33333 11.8816 1.33333 8.21498 1.33333C4.54831 1.33333 1.54831 4.33333 1.54831 8C1.54831 11.6667 4.54831 14.6667 8.21498 14.6667Z"
        stroke="#364152"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.21497 4.93333V8.26667"
        stroke="#364152"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.2113 10.6667H8.21729"
        stroke="#364152"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const WrapperStyledForHeading = styled.div`
  background: white;
  padding: 20px 24px;
  width: 100%;
  box-sizing: border-box;
  .title {
    width: 100%;
    border-left: 10px solid #15be53;
    padding-left: 10px;
    color: #364152;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
    background: #fff;
    box-sizing: border-box;
  }
  .userName{
    cursor:pointer;
  }
`;

export default InformationComponent;
