import React, {useEffect, useState} from "react";
import LoaderPage from "../../commonComponents/LoaderPage";
import {ActiveChatsWrapper} from "./AssignedUsers.styled";
import Badge from "../../commonComponents/badge";
import ClientChat from "../ClientChat";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../AssignedUsers/store/reducer";
import {actions} from "../ClientChat/store/action";
import {useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import {IoMailUnreadOutline, IoMailUnread} from "react-icons/io5";
import {advisorData} from "../../Services/SuperSaverClientChat";
import {globalNotificationAction} from "./store/reducer";
import {getUserData} from "./utils";
import Toggle from "../../commonComponents/Toggle/Toggle";
import {Tooltip} from "@mui/material";
import {advisorType} from "../../constants/advisorType";
import Lottie from "lottie-react";
import aiGif from './assets/grape-animation-1.json'
const ActiveChats = () => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const params = useParams();
  const advisor = advisorData();
  const advisorId = params["id"] ? params["id"] : advisor.user_roll_id;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [activeChat, setActiveChat] = useState(null);
  const globalNotifications = useSelector(
    (state) => state.globalNotifications.notification
  );
  const advisorRefreshStatus = useSelector(
    (state) => state.globalNotifications.advisorRefreshStatus
  );
  const activeChatUserId = useSelector(
    (state) => state.globalNotifications.activeChatUserId
  );
  const selectedAdvisorType = useSelector(
    (state) => state.globalNotifications.selectedAdvisorType
  );
  const unreadMsgFilter = useSelector(
    (state) => state.globalNotifications.unreadMsgFilter
  );

  const assignedUsers = useSelector((state) => state.users.users);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [recallTimer, setRecallTimer] = useState(null);
  const [isLoaderActive, setIsLoaderActive] = useState(true);
  const setUnreadMsgFilter = (value) => {
    dispatch({
      type: globalNotificationAction.SET_FILTER_TYPE,
      payload: value || !unreadMsgFilter,
    });
  };

  const setSelectedAdvisorType = (value) => {
    dispatch({type: globalNotificationAction.SET_ADVISOR_TYPE, payload: value});
  };

  useEffect(() => {
    dispatch({type: actions.CHANGE_SELECTED_ADVISOR_ID, data: advisorId});
  
    getUserData(
      advisorRefreshStatus,
      dispatch,
      setUserData,
      setLoader,
      advisorId,
      setRecallTimer,
      recallTimer,
      selectedAdvisorType,
      unreadMsgFilter,isLoaderActive,setIsLoaderActive
    );
    if (!advisorRefreshStatus) {
      clearTimeout(recallTimer);
    }
    return () => {
      dispatch({
        type: actions.CHANGE_SELECTED_ADVISOR_ID,
        data: null,
      });
    };
  }, [advisorId, advisorRefreshStatus, selectedAdvisorType, unreadMsgFilter]);
  useEffect(() => {
    dispatch({type: userActions.ADD_ASSIGNED_USERS, payload: userData});
  }, [userData]);

  useEffect(() => {
    const reconciledUser = userData.filter((it) => {
      return it?.user_details?.first_name
        .toLowerCase()
        .includes(searchKeyword.toLowerCase());
    });
    setData([...reconciledUser]);
  }, [globalNotifications, assignedUsers, unreadMsgFilter, searchKeyword]);

  const getUserDetails = (userId) => {
    const notification = globalNotifications.find((it) => {
      return it.user_details.external_id === userId;
    });
    return notification;
  };

  useEffect(() => {
    setActiveChat(
      () => {
        const activeChatId = data.filter((d, i) => {
          const userID = d?.user_response?.external_id || null;

          return userID === activeChatUserId;
        })[0];

        return activeChatId;
      }

    );
  }, [activeChatUserId, data]);

  const handleSelectChat = (chatNo) => {
    dispatch({
      type: actions.SET_SELECTED_CHAT,
      data: chatNo,
    });
  };

  const handleChatUserClick = (item, index) => {

    setActiveChat(item);
    const userID = item?.user_response?.external_id || null;
    dispatch({
      type: globalNotificationAction.SET_ACTIVE_CHAT_USER_ID,
      payload: userID,
    });
    handleSelectChat(0);
  };
  const handleFilter = () => {
    dispatch({
      type: globalNotificationAction.SET_ADVISOR_CHAT_REFRESH_STATUS,
      payload: true,
    });
    setUnreadMsgFilter();
  };

  return loader ? (
    <LoaderPage />
  ) : (
    <ActiveChatsWrapper className="super">
      <div className="sidebar">
        <div className="heading-wrapper">
          {name ? `${name}'s Clients` : "Client Chats"}{" "}
          <Toggle
            selectedValue={selectedAdvisorType}
            firstValue={advisorType.PRIMARY}
            secondValue={advisorType.SECONDARY}
            handleSelectedValue={(value) => {
              setLoader(true);
              dispatch({
                type: globalNotificationAction.SET_ADVISOR_CHAT_REFRESH_STATUS,
                payload: true,
              });
              setSelectedAdvisorType(value.toUpperCase());
            }}
          />
          {!unreadMsgFilter ? (
            <Tooltip title="Click to Show Unread Messages" placement="top">
              <span>
                {" "}
                <IoMailUnreadOutline
                  onClick={handleFilter}
                  className="unreadIcon"
                />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Click to Show All Messages" placement="top">
              <span>
                {" "}
                <IoMailUnread className="unreadIcon" onClick={handleFilter} />
              </span>
            </Tooltip>
          )}
        </div>
        <input
          className="search-by-name"
          placeholder="Search by name"
          type="search"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        ></input>
        <div className="list-view">
          {data.map((item, index) => {
            const userID = item?.user_response?.external_id || null;
            return (
              <div
                onClick={() => handleChatUserClick(item, index)}
                className={
                  ( activeChatUserId === userID )
                    ? "list-item active-chat"
                    : "list-item"
                }
                key={item.user_details.external_id}
              >
                <div className="name-badge-container">
                  <p className="user-name">{item.user_details.first_name}</p>{" "}
                  &nbsp;
                  {item.unread_messages > 0 && (
                    <Badge size={18} value={item.unread_messages} />
                  )}
                </div>
                <p className="user-email">{item.user_details.email}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="main-view">
        {activeChat ? (
          <ClientChat key={activeChatUserId} activeChatDetails={activeChat} />
        ):<div className="infoToSelectWrapper">
        
        <Lottie className='aiGif' animationData={aiGif} loop={true} />

        <h2 className="infoToSelect">
        Please select a user to see the chat
        </h2>
        </div>}
      </div>
    </ActiveChatsWrapper>
  );
};

export default ActiveChats;
