import React from "react";
import { StyledUpComingSessionList } from "./UpComingSessionList.styled";
import UpcomingCallDetails from "../../components/UpcomingCallDetails";

const UpComingSessionList = ({ upcomingSessionCalls }) => {
  if (upcomingSessionCalls?.upcoming_calls?.length <= 0) return null;
  return (
    <StyledUpComingSessionList>
      {upcomingSessionCalls?.upcoming_calls?.map((upcomingCalls, index) => (
        <UpcomingCallDetails
          key={index}
          index={index}
          upcomingCalls={upcomingCalls}
        />
      ))}
    </StyledUpComingSessionList>
  );
};

export default UpComingSessionList;
