import styled from 'styled-components';

export const SalarySidebarStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0 0;
	box-sizing: border-box;

	.oldCompany {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: auto;
		margin: 20px 0 16px 0;
		padding: 0 0;
		box-sizing: border-box;
	}

	h2 {
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
		color: #212f45;
	}
	.oldCompany p {
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		color: #15be53;
		cursor: pointer;
	}
`;
