import React, { useEffect, useState } from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { StyledSalaryReadComponent } from "../AutoSalarySlipUpload/SalaryReadComponent.styled";
import ReadPayslip from "../AutoSalarySlipUpload/Components/ReadPayslip";
import {
    initialDeductionSalaryComponent,
    initialEarningSalaryComponent,
} from "../../utils/constant";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import Button from "../../../../commonComponents/Button";
import SuperSaverIncomeService from "../../../../Services/SuperSaverIncomeService";
const ManualSalarySlipUpload = ({
    isOpen,
    handleClose,
    companyName,
    month,
    externalId,
    systemGeneratedAmount,
    update = false,
    EarningComponent,
    viewMode,
    DeductionComponent,
    onSubmit = (data) => {},
}) => {
    const [earningComponent, setEarningComponent] = useState(
        initialEarningSalaryComponent
    );
    const [deductionComponent, setDeductionComponent] = useState(
        initialDeductionSalaryComponent
    );

    const [totalEarning, setTotalEarning] = useState(0);
    const [totalDeduction, setTotalDeduction] = useState(0);

    const incomeType = "SALARY";

    const handleSubmit = async (e) => {
        const data = {
            external_id: externalId,
            financial_year_id: localStorage.getItem(
                LOCAL_DB_KEYS.FINANCIAL_YEAR_ID
            ),
            monthly_breakdown: {
                [month]: {
                    system_generated_amount: systemGeneratedAmount,
                    approved_salary: totalEarning,
                    is_approved: true,
                    approved_source: "USER",
                    is_editable: true,
                    salary_slip: "",
                    extra_pay: 0,
                    is_salary_slip_read: false,
                    salary_component: [
                        {
                            credit: [...earningComponent],
                        },
                        {
                            debit: [...deductionComponent],
                        },
                    ],
                    salary_income_id: externalId,
                },
            },
        };
        try {
            const MonthSalaryBrakedown =
                await SuperSaverIncomeService.postSalaryVerification(
                    data,
                    incomeType
                );
            onSubmit(MonthSalaryBrakedown);
            setEarningComponent(initialEarningSalaryComponent);
            setDeductionComponent(initialDeductionSalaryComponent);
            handleClose(e);
        } catch (error) {
            toast.error(
                error && error.response && error.response.data
                    ? error.response.data.message
                    : "Oops Something went wrong"
            );
        }
    };

    useEffect(() => {
        if (DeductionComponent) {
            setDeductionComponent([...DeductionComponent]);
        }
    }, [DeductionComponent]);
    useEffect(() => {
        if (EarningComponent) {
            const newArr = EarningComponent;
            setEarningComponent([...newArr]);
        }
    }, [EarningComponent]);
    useEffect(() => {
        if (isOpen && !update) {
            setEarningComponent([...initialEarningSalaryComponent]);

            setDeductionComponent([...initialDeductionSalaryComponent]);
        }
    }, [isOpen]);

    useEffect(() => {
        let total_earning = 0;

        earningComponent.map((d, i) => {
            total_earning = total_earning + Number(d.amount);

            return d;
        });

        setTotalEarning(!isNaN(total_earning) ? total_earning : 0);
    }, [earningComponent]);
    useEffect(() => {
        let total_earning = 0;

        deductionComponent.map((d, i) => {
            total_earning = total_earning + Number(d.amount);

            return d;
        });

        setTotalDeduction(!isNaN(total_earning) ? total_earning : 0);
    }, [deductionComponent]);
    return (
        <StyledSalaryReadComponent className="uploadModel" isOpen={isOpen}>
            <AiFillCloseSquare className="close-btn" onClick={handleClose} />
            <>
                <h2 className="heading">{companyName}</h2>
                <h2 className="heading Salary">
                    Salary Slip Details for {month}
                </h2>
                <ReadPayslip
                    earningComponent={earningComponent}
                    setComponent={setEarningComponent}
                    componentTitle={"Earning"}
                    componentLoading={false}
                    totalEarning={totalEarning}
                    viewMode={viewMode}
                />
                <ReadPayslip
                    style={{ marginTop: 24 }}
                    earningComponent={deductionComponent}
                    setComponent={setDeductionComponent}
                    componentTitle={"Deduction"}
                    componentLoading={false}
                    totalDeduction={totalDeduction}
                    viewMode={viewMode}
                />

                {!viewMode && (
                    <div className="submitBtn">
                        <Button
                            style={{
                                width: "86px",
                                height: "34px",
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "16px",
                            }}
                            onButtonClick={(e) => {
                                handleSubmit(e);
                            }}
                            buttonName="Submit"
                        ></Button>
                    </div>
                )}
            </>
        </StyledSalaryReadComponent>
    );
};

export default ManualSalarySlipUpload;
