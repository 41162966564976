import React from 'react';

const Spinner = ({ size = '40px', right = '0px', top = '0px' }) => {
	return (
		<div className='text-center' style={{ textAlign: 'center' }}>
			<img
				src={process.env.PUBLIC_URL + '/assets/icons/spinner2.gif'}
				style={{
					height: size,
					width: size,
					position: 'relative',
					right: right,
					top: top
				}}
				alt=''
			/>
		</div>
	);
};

export default Spinner;
