import React from 'react';
import { TextField } from "@mui/material";

function CustomTextField({ index, data, handleChange ,className ,type ,variant}) {
    return (
        <TextField
          key={index}
          className={className}
          label={data.label}
          type={type}
          variant={variant}
          disabled={data?.disabled}
          value={data.value}
          onChange={(e) => handleChange(e)}
          InputProps={{
            onWheel: (e) => e.target.blur(),
          }}
        />
      );
}

export default CustomTextField;
