import { getTaxBySalary, LS_TAX_DETAILS } from './taxCalc';

const getUserCurrentTaxSlab = () => {
	const taxDetail =
		sessionStorage.getItem(LS_TAX_DETAILS) &&
		JSON.parse(sessionStorage.getItem(LS_TAX_DETAILS));
	if (taxDetail) {
		const { salary, totalDiduction, STCG_DEBT_TOTAL_AMOUNT } =
			taxDetail && taxDetail;
		const updatedTaxDetailsForStcg = getTaxBySalary(
			salary,
			totalDiduction,
			STCG_DEBT_TOTAL_AMOUNT,
			false
		);

		return updatedTaxDetailsForStcg.taxSlab / 100;
	} else return 0;
};

const getUserPreviousCapitalGainLoss = () => {
	//to be implemented fetch user previous loss entered with year
	return 0;
};

const capitalGainTypes = {
	STCG_EQUITY: 'STCG_EQUITY',
	STCG_DEBT: 'STCG_DEBT',
	LTCG_EQUITY_NORMAL: 'LTCG_EQUITY',
	LTCG_DEBT_NORMAL: 'LTCG_DEBT',
	LTCG_EQUITY_INDENT: 'LTCG_EQUITY_INDENT',
	LTCG_DEBT_INDENT: 'LTCG_DEBT_INDENT'
};

const capitalGainTaxRate = {
	STCG_EQUITY: 0.15,

	LTCG_EQUITY_NORMAL: 0.1,
	LTCG_DEBT_NORMAL: 0.1,
	LTCG_EQUITY_INDENT: 0.2,
	LTCG_DEBT_INDENT: 0.2
};

const getCapitalGainDeduction = {};

const capitalGainDeductions = {
	STCG_EQUITY: 0,
	STCG_DEBT: 0,
	LTCG_EQUITY_NORMAL: 100000,
	LTCG_DEBT_NORMAL: 0,
	LTCG_EQUITY_INDENT: 100000,
	LTCG_DEBT_INDENT: 0
};

const capitalGainAmountType = {
	PROFIT: 'PROFIT',
	LOSS: 'LOSS'
};

const getEquivalentAmount = (taxRate, amount, amountType) => {
	if (amountType === capitalGainAmountType.PROFIT) {
		return amount * taxRate;
	} else {
		return -amount * taxRate;
	}
};

const getTaxRate = (type) => {
	if (type === capitalGainTypes.STCG_EQUITY) {
		return capitalGainTaxRate.STCG_EQUITY;
	} else if (type === capitalGainTypes.STCG_DEBT) {
		return getUserCurrentTaxSlab();
	} else if (type === capitalGainTypes.LTCG_EQUITY_NORMAL) {
		return capitalGainTaxRate.LTCG_EQUITY_NORMAL;
	} else if (type === capitalGainTypes.LTCG_DEBT_NORMAL) {
		return capitalGainTaxRate.LTCG_DEBT_NORMAL;
	} else if (type === capitalGainTypes.LTCG_EQUITY_INDENT) {
		return capitalGainTaxRate.LTCG_EQUITY_INDENT;
	} else if (type === capitalGainTypes.LTCG_DEBT_INDENT) {
		return capitalGainTaxRate.LTCG_DEBT_INDENT;
	}
};

const arrangeByRank = (capitalGainIncomeArray) => {
	let newArr = capitalGainIncomeArray.map((d, i) => {
		if (d.sub_type === capitalGainTypes.STCG_DEBT) {
			return { ...d, rank: 1 };
		} else if (d.sub_type === capitalGainTypes.STCG_EQUITY) {
			return { ...d, rank: 2 };
		} else if (d.sub_type === capitalGainTypes.LTCG_DEBT_NORMAL) {
			return { ...d, rank: 3 };
		} else if (d.sub_type === capitalGainTypes.LTCG_EQUITY_NORMAL) {
			return { ...d, rank: 4 };
		}
	});
	return newArr.sort((a, b) => a.rank - b.rank);
};

const getTotalIncomeTaxCapitalGain = (
	capitalGainIncomeArrayDefault,
	basicExemption = 0
) => {
	let longTermCapitalGainEquivalentTaxAmount = 0;
	let sortTermCapitalGainEquivalentTaxAmount = 0;
	let capitalGainIncomeArray = arrangeByRank(capitalGainIncomeArrayDefault);

	let totalEquivalentTaxAmount = 0;
	let capitalgainIncomeTaxTotal = 0;
	for (let index = 0; index < capitalGainIncomeArray.length; index++) {
		const identity = capitalGainIncomeArray[index];
		const { sub_type, sub_type_details } = identity;
		for (let i = 0; i < sub_type_details.length; i++) {
			let taxRate = 0;
			taxRate = getTaxRate(sub_type);
			const element = sub_type_details[i];

			const amount = element.income;

			const amountType = element.pnl_type;

			if (
				element.pnl_type === capitalGainAmountType.PROFIT &&
				basicExemption < 0
			) {
				basicExemption = amount + basicExemption;
			}
			if (basicExemption > 0) {
				if (sub_type.includes('STCG')) {
					sortTermCapitalGainEquivalentTaxAmount =
						sortTermCapitalGainEquivalentTaxAmount +
						getEquivalentAmount(taxRate, basicExemption, amountType);
				} else {
					longTermCapitalGainEquivalentTaxAmount =
						longTermCapitalGainEquivalentTaxAmount +
						getEquivalentAmount(taxRate, basicExemption, amountType);
				}
				basicExemption = 0;
			} else if (basicExemption == 0) {
				if (sub_type.includes('STCG')) {
					sortTermCapitalGainEquivalentTaxAmount =
						sortTermCapitalGainEquivalentTaxAmount +
						getEquivalentAmount(taxRate, amount, amountType);
				} else {
					longTermCapitalGainEquivalentTaxAmount =
						longTermCapitalGainEquivalentTaxAmount +
						getEquivalentAmount(taxRate, amount, amountType);
				}
			}
		}
	}

	if (
		longTermCapitalGainEquivalentTaxAmount >
		capitalGainDeductions.LTCG_EQUITY_NORMAL *
			capitalGainTaxRate.LTCG_EQUITY_NORMAL
	) {
		//need to verify ltcg equity normal is present
		totalEquivalentTaxAmount =
			longTermCapitalGainEquivalentTaxAmount -
			capitalGainDeductions.LTCG_EQUITY_NORMAL *
				capitalGainTaxRate.LTCG_EQUITY_NORMAL +
			sortTermCapitalGainEquivalentTaxAmount;
	} else {
		totalEquivalentTaxAmount = sortTermCapitalGainEquivalentTaxAmount;
	}

	//adding Health & Education Cess

	capitalgainIncomeTaxTotal =
		totalEquivalentTaxAmount + totalEquivalentTaxAmount * 0.04;
	if (capitalgainIncomeTaxTotal >= 1) {
		return capitalgainIncomeTaxTotal;
	} else return 0;
};

const GetCRYPTO_CURRENCYTax = (value) => {
	let Income = value;
	const CRYPTO_CURRENCYTax = Income * 0.3;
	return CRYPTO_CURRENCYTax + CRYPTO_CURRENCYTax * 0.04;
};

export { getTotalIncomeTaxCapitalGain, GetCRYPTO_CURRENCYTax };
