import styled from "styled-components";
import { Table, Tag } from "antd";
import { extractContent } from "../../../utils/textUtil";
import ArticleView from "../article-view";
import { useState } from "react";

const TableWrapper = styled.div`
  width: 100%;

  .ant-tag {
    border-radius: 16px;
    padding: 2px 10px 2px 10px;
  }

  .icon-container {
    display: flex;
    gap: 40px;
  }
  .ant-table-wrapper {
    min-height: 50vh;
  }
  .ant-table {
    font-size: 14px;
  }

  .ant-table-tbody > tr > td {
    height: 40px;
    padding: 8px;
  }
  .my-tag {
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    height: 40px;
    padding: 8px;
  }
`;

function convertDataForTable(data) {
  return data?.map((item) => {
    const author =
      item.author_details && item.author_details.name
        ? item.author_details.name
        : "Prosperr Staff";
    const updatedDate = new Date(item.updated_on);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return {
      key: item.external_id,
      title: extractContent(item.title),
      author: author,
      updatedDate: updatedDate
        .toLocaleDateString("en-US", options)
        .replace(/\bat\b/g, ""),
      status: item.article_status,
      details: item,
    };
  });
}

const MyTable = ({
  tableData,
  editArticle,
  updated,
  setUpdated,
  setNextToken,
  loading,
  nextToken,
  pageNumber,
  authors,
}) => {
  let data = convertDataForTable(tableData);
  const [article, setArticle] = useState({});
  const [showPreview, setShowPreview] = useState(false);

  const handlePreview = (article) => {
    setArticle(article);
    setShowPreview(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => {
        return pageNumber * 10 + (index + 1);
      },
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => {
        return (
          a.title.trim().charAt(0).toLowerCase() <
          b.title.trim().charAt(0).toLowerCase()
        );
      },
      ellipsis: true,
      width: "40%",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "Author",
      sorter: (a, b) => {
        return (
          a.author.trim().charAt(0).toLowerCase() <
          b.author.trim().charAt(0).toLowerCase()
        );
      },
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Updated On",
      dataIndex: "updatedDate",
      key: "updatedDate",
      sorter: (a, b) => {
        return a.details.updated_on < b.details.updated_on;
      },
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => {
        return a.status < b.status;
      },
      width: "10%",
      render: (_, { status }) => {
        let color = "";
        let text = "";
        if (status === "UNPUBLISHED") {
          text = "Unpublished";
        } else if (status === "PUBLISHED") {
          color = "green";
          text = "Published";
        } else {
          color = "purple";
          text = "Draft";
        }

        return (
          <Tag bordered={false} color={color} key={status}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (text, item) => (
        <div className="icon-container">
          <img
            onClick={() => {
              editArticle(item.details, item.status);
            }}
            className="icon"
            src={process.env.PUBLIC_URL + "/assets/icons/edit-2.svg"}
            alt="edit"
          />
          <img
            onClick={() => handlePreview(item.details)}
            className="icon"
            src={process.env.PUBLIC_URL + "/assets/icons/view-icon-eye.svg"}
            alt="view"
          />
        </div>
      ),
    },
  ];

  return (
    <TableWrapper>
      <Table
        className={"table"}
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        bordered={false}
        scroll={{ y: 500 }}
      />
      {showPreview && (
        <ArticleView
          article={article}
          authors={authors}
          setShowPreview={setShowPreview}
          updated={updated}
          nextToken={nextToken}
          setNextToken={setNextToken}
          setUpdated={setUpdated}
        />
      )}
    </TableWrapper>
  );
};

export default MyTable;
