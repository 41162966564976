import styled from "styled-components";
import { dim } from "../../../../../../styles/theme";

export const StyledNotesGrid = styled.div`
.notesSection{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.notes-container{
    display: grid;
    grid-template-columns: ${dim._scale(300)} ${dim._scale(300)} ${dim._scale(300)};
    grid-gap: ${dim._scale(10)};
    padding: ${dim._scale(5)};
    box-sizing: border-box;
}
.notesSection{
    max-height: fit-content;
}
.individual-note-card{
    position: relative;
}
.note-content{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8; 
    overflow: hidden;
    max-height: 80%; 
    max-width: 100%;
}
.individual-note{
    max-height:70vh;
}
.author-details{
    position: absolute;
    bottom: 0;
    width: 90%;
   
}
.writtenBy{
    display: flex;
    justify-content: space-between;
    font-size:${dim._scale(12)};
    
}
`;

export const StyledCard = styled.div`
.card-content-wrapper{
    display:flex;
    align-items: center;
    margin-bottom:${dim._scale(8)};
}

.card-bottom-info{
    display:flex;
    align-items:center;
    justify-content: space-between;
    box-sizing:border-box;
    width:80%;
    position:absolute;
    bottom:${dim._scale(16)};
}
`;