import React from "react";
import {
  ConfiramtionModalStyle,
  ConfirmationFotter,
  CancelIcon,
  ConfirmationIcon,
  ConfirmationContent,
  Header,
  SubHeader,
} from "./ConfirmationModal.styled";
import Button from "../../../../commonComponents/Button";
import { itr_documents_constant } from "../../constant";
import CommonButton from "../CommonButton";

const confirmation_content = {
  header: "Are you sure ?",
  subHeader:
    "Email will be send to users notifying them to upload asked documents for ITR filing.",
  cancelText: "Cancel",
  okText: "Yes",
};

const ConfirmationModal = ({
  handleConfirmRequest,
  handleCancelBtn,
  confirmationContent = confirmation_content,
  displayIcon = itr_documents_constant.warning_icon,
}) => {
  const { header, subHeader, cancelText, okText } = confirmationContent;
  return (
    <ConfiramtionModalStyle>
      <CancelIcon>
        <div style={{ cursor: "pointer" }} onClick={handleCancelBtn}>
          <img
            className="cancelIcon"
            src={itr_documents_constant.cross_icon}
            alt="cancel"
          />
        </div>
      </CancelIcon>
      <ConfirmationIcon>
        <img className="confirmationIcon" src={displayIcon} alt="warning" />
      </ConfirmationIcon>
      <ConfirmationContent>
        <Header>{header}</Header>
        <SubHeader>{subHeader}</SubHeader>
      </ConfirmationContent>
      <ConfirmationFotter>
        <CommonButton
          btnName="Cancel"
          handleClick={handleCancelBtn}
          style={{
            width: "118px",
            height: "46px",
            fontWeight: "600",
            fontSize: " 16px",
            background: "#ffffff",
            color: "#425466",
            border: "1px solid #425466",
            marginRight: "8px",
          }}
        />
        <CommonButton
          btnName="Yes"
          handleClick={() => {
            handleConfirmRequest();
          }}
          style={{
            width: "118px",
            height: "46px",
            fontWeight: "600",
            fontSize: " 16px",
            marginLeft: "8px",
          }}
        />
      </ConfirmationFotter>
    </ConfiramtionModalStyle>
  );
};

export default ConfirmationModal;
