import { FileUploadService } from "../Services/FileUploadServices";

export const uploadFile = async (bucketName, bucketZone, file) => {
    try {
        await FileUploadService.uploadFile(file.name, file, bucketName);
        return `https://${bucketName}.s3.${bucketZone}.amazonaws.com/${file.name}`;
    } catch (error) {
        throw error;
    }
};
