export const LOCAL_DB_KEYS = {
  EMAIL: "email",
  USER: "type",
  CURRENT_USER: "currentUser",
  USER_ID: "userId",
  USER_DETAILS: "user",
  AUTH_TOKEN_LS: "AUTH_TOKEN",
  FINANCIAL_YEAR: "FINANCIAL_YEAR",
  FINANCIAL_YEAR_ID: "FINANCIAL_YEAR_ID",
  SELECTED_ADVISOR_ID: "SELECTED_ADVISOR_ID",
  SELECTED_USER_VIEW_TYPE: "SELECTED_USER_VIEW_TYPE",
  CALL_SUMMARY_ID: "CALL_SUMMARY_ID",
  LAST_WARNING_TIMESTAMP: "LAST_WARNING_TIMESTAMP",
};
