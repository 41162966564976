import { getFileNameWithoutSpecialCharacter } from "./FileWithoutSpecialCharactor";

const getFillName = (filename, type, uploadType, customerUserId) => {
  const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
  const fileNameWithoutSpecialCharector =
    getFileNameWithoutSpecialCharacter(filename);
  let FileName = `${customerUserId}_${randomNumber}_${uploadType}_${fileNameWithoutSpecialCharector}.${type}`;
  return FileName;
};

export const getUniqueFileName = (imageFile, uploadType) => {
  // const imageFile = e.target.files[0];
  const name = imageFile.name;
  const lastDot = name.lastIndexOf(".");
  const fileName = name.substring(0, lastDot);
  let ext = name.substring(lastDot + 1);
  const USED_ID_LS = "userId";
  const customerUserId = localStorage.getItem(USED_ID_LS);
  const newType = ext;
  const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
  const fileNameWithoutSpecialCharector =
  getFileNameWithoutSpecialCharacter(fileName);
  let FileName = `${customerUserId}_${randomNumber}_${uploadType}_${fileNameWithoutSpecialCharector}.${newType}`;
  return FileName;
};
export const extractFilename=(value)=>{
  const arr=value?.split('_')||[];
  return arr[arr.length-1]
}

export { getFillName };
