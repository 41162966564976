import styled from 'styled-components';

export const TableViewWrapper = styled.table`
	width: 100%;
	border-radius: 8px 8px 8px 8px;
	box-sizing: border-box;
	border-collapse: collapse;

	.table-heading {
		background-color: #e5e5e5;
		border-radius: 8px 8px 0 0;
		text-transform: uppercase;
	}

	.tr-data {
		border-top: 1px solid #e5e5e5 !important;
	}

	tr td:first-child {
		border-radius: 8px 0 0 0;
	}

	tr td:last-child {
		border-radius: 0 8px 0 0;
	}

	td {
		padding: 10px 16px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}
`;
