import FlexRow from "../FlexRow/FlexRow";
import { useState, useContext } from "react";
import FlexCol from "../FlexCol/FlexCol";
import Button from "../Button";
import TimeSelectForm from "../TimeSelectForm";
import { Select, StyledTimeSlot } from "./TimeSlot.Styled";
import moment from "moment";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import Alert1 from "../Alert/Alert1";
import AuthorSelect from "../../components/AuthorSelect/index";
import { updateSlots } from "../../Services/Slots";
import BASE_URL from "../../Services/API_ENDPOINT";
import DatePicker, { getAllDatesInRange } from "react-multi-date-picker";
import DropDownSelect from "react-select";


const options = [
  { value: 'AVAILABLE_FOR_CONSULTATION', label: 'Create Availability For Advisor' },
  { value: 'SLOT_CREATION', label: 'Create Slot For Advisor Booking' },
]

const TimeSlot = ({
  Id,
  arrayData,
  AdvisorId,
  Close,
  token,
  setdateSelected,
  setEndTime,
  setStartTime,handlePurpose,purpose
}) => {
  const a = useContext(AdvisorContext);
  const value_id = Id;
  const idToken = token;
  const [Value, setValue] = useState({
    Date: moment().format("YYYY-MM-DD"),
    start_time: "",
    end_time: "",
    advisor_id: "",
  });
  const [ShowAlert, setShowAlert] = useState(false);
  const [SubmitMsg, setSubmitMsg] = useState("");

  const handleValue = (e) => {
    const url = ` ${BASE_URL}timeSlots/v1/post`;

    const fetchData = async () => {
      const data = {
        advisor_id: Value.user_details.external_id,
        start_time: new Date(`${Value.Date} ${Value.start_time}`).getTime(),
        end_time: new Date(`${Value.Date} ${Value.end_time}`).getTime(),
        created_by: JSON.parse(localStorage.getItem("user")).displayName,
        slot_type:"GENERAL_CONSULTATION"
      };
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
          Source: "cms",
        },
        body: JSON.stringify(data),
      };

      try {
        const response = await fetch(url, config);

        if (response.status === 200 || response.status === 204) {
          // setResetTime(true);
          // removeTime()
          setShowAlert(true);
          setSubmitMsg("Slot Added");
          setTimeout(() => {
            setShowAlert(false);
          }, 1000);
        } else {
          setShowAlert(true);
          setSubmitMsg("please put a valid date & time ");
          setTimeout(() => {
            setShowAlert(false);
          }, 1000);
        }

        a.setTf(true);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  };
  // const removeTime=()=>{}
  const handleEdit = (e) => {
    const data = {
      appointment_id: Id,
      start_time: a.ViewAppointmentsData.start,
      end_time: a.ViewAppointmentsData.end,
      rescheduled_by: "kameshwar maurya",
    };

    updateSlots(data, a, idToken)
      .then((res) => {
        if (res.status === 204) {
          Close();
          setShowAlert(true);
          setSubmitMsg("Appointment  rescheduled");
          setTimeout(() => {
            setShowAlert(false);
          }, 1000);
        } else {
          setShowAlert(true);
          setSubmitMsg("please put a valid date & time ");
          setTimeout(() => {
            setShowAlert(false);
          }, 1000);
        }
      })
      .then((err) => {
        console.error(err);
      });
  };

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let sec = "00";
    // const sec =00;
    let [hours, minutes] = time.split(":");

    // if (hours === "12") {
    //   hours = "00";
    // }
    if (hours === "00") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10);
    }

    return `${hours}:${minutes}:${sec}`;
  };

  const context = useContext(AdvisorContext);

  
  return (
    <StyledTimeSlot>
      <Alert1 ShowAlert={ShowAlert} SubmitMsg={SubmitMsg} mid={true} />
      <FlexCol>
        <FlexRow>
        {!arrayData.isAppointmentsActive &&(   <form className="purpose">
            <label for="Date">
              <b>Purpose: </b>
            </label>
           
                <DropDownSelect options={options} className="dropdown" 
                onChange={(e)=>{
                  handlePurpose(e)
                }}
                value={purpose}
                />
           
          </form> ) }
          <form>
            <label for="Date">
              <b>Date: </b>
            </label>
            {!arrayData.isAppointmentsActive ? (
              <DatePicker
                // className="Datepicker"
                range
                /**
                 * Keep in mind that activating this prop
                 * may cause slow rendering at
                 * big ranges of Dates
                 */
                eachDaysInRange
                id="date"
                name="date"
                Id={Id}
                min={moment().format("YYYY-MM-DD")}
                value={Value.Date}
                onChange={(dateObjects) => {
                  let allDates = getAllDatesInRange(dateObjects, true);

                  setdateSelected(getAllDatesInRange(dateObjects, true));
                  setValue({
                    ...Value,
                    Date: dateObjects,
                    advisor_id: value_id,
                  });
                  if (allDates.length < 2) return;

                  // alert("you selected \n" + allDates.join(",\n"));
                }}
              />
            ) : (
              <Select
                type="date"
                id="date"
                name="date"
                Id={Id}
                min={moment().format("YYYY-MM-DD")}
                value={Value.Date}
                onChange={(e) => {
                  setValue({
                    ...Value,
                    Date: e.target.value,
                    advisor_id: value_id,
                  });
                  context.setDate(e.target.value);
                }}
              />
            )}
          </form>
          <form action="">
            <FlexRow>
              <label htmlFor="From">
                {arrayData.isAppointmentsActive ? (
                  <b>Time Slot: </b>
                ) : (
                  <b>From: &nbsp;</b>
                )}
              </label>
              {arrayData.isAppointmentsActive ? (
                <AuthorSelect
                  arrayData={arrayData}
                  Date1={Value.Date}
                  Status="StartTime"
                  AdvisorId={AdvisorId}
                  token={idToken}
                />
              ) : (
                <TimeSelectForm
                  removeTime={a.ResetTime}
                  RemoveTime={(hh) => a.setResetTime(hh)}
                  Time={(From) => {
                    if (From !== null) {
                      setValue({
                        ...Value,
                        start_time: convertTime12to24(From),
                        advisor_id: value_id,
                      });
                      context.setstart_time(convertTime12to24(From));
                      setStartTime(convertTime12to24(From));
                    }
                  }}
                />
              )}
            </FlexRow>
          </form>
          <form action="">
            {arrayData.isAppointmentsActive ? null : (
              <FlexRow>
                <label htmlFor="to">
                  <b>To: &nbsp;</b>
                </label>

                <TimeSelectForm
                  removeTime={a.ResetTime}
                  RemoveTime={(hh) => a.setResetTime(hh)}
                  Time={(time) => {
                    if (time !== null) {
                      setValue({
                        ...Value,
                        end_time: convertTime12to24(time),
                        advisor_id: value_id,
                      });
                      context.setend_time(convertTime12to24(time));
                      setEndTime(convertTime12to24(time));
                    }
                  }}
                />
              </FlexRow>
            )}
          </form>
          {arrayData.isAppointmentsActive ? (
            <Button
              buttonClassName="btnAddSlot"
              onButtonClick={
                arrayData.isAppointmentsActive ? handleEdit : handleValue
              }
              buttonName={
                arrayData.isAppointmentsActive
                  ? "Reschedule appointment"
                  : "Add Slot"
              }
            />
          ) : null}
        </FlexRow>
      </FlexCol>
    </StyledTimeSlot>
  );
};

export default TimeSlot;
