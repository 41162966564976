import React from "react";
import { SalaryDetailsWrapper } from "./index.styled";
import { useLocation } from "react-router";
import AnnualCTCBreakdown from "../AnnualCTCBreakdown";
import SalaryIncomeDetails from "../SalaryIncomeDetails";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";

const SalaryDetails = () => {
    const { state } = useLocation();
    const companyId =
        state != null
            ? state.external_id
            : "4d71821b-efa9-4781-8815-e89f47eef820";
    const financialYearId = localStorage.getItem(
        LOCAL_DB_KEYS.FINANCIAL_YEAR_ID
    );
    return (
        <SalaryDetailsWrapper>
            {state && (
                <AnnualCTCBreakdown
                    userId={state.user.user_id}
                    companyId={companyId}
                    financialYearId={financialYearId}
                    isNew={state.isNew}
                />
            )}
            {state && (
                <SalaryIncomeDetails
                    userId={state.user.user_id}
                    companyId={companyId}
                    financialYearId={financialYearId}
                    style={{ padding: 0, margin: "24px 0px" }}
                    isNew={state.isNew}
                    verified={state.verified}
                />
            )}
        </SalaryDetailsWrapper>
    );
};

export default SalaryDetails;
