import { convertToPascalCase } from "../../utils/textUtil";

export const allDurations = [
  { value: 15, label: "15 Minutes" },
  { value: 30, label: "30 Minutes" },
  { value: 45, label: "45 Minutes" },
  { value: 60, label: "1 hour" },
];
export const allConsultationType = [
  { value: "GENERAL_QUERY", label: "General Query" },
  { value: "TAX_PLANNING", label: "Tax Planning" },
  { value: "ITR_FILING", label: "ITR Filing" },
  { value: "ADVANCE_TAX", label: "Advance Tax" },
  { value: "PROOF_SUBMISSION", label: "Proof Submission" },
  { value: "DECLARATION_SUBMISSION", label: "Declaration Submission" },
  { value: "ALLOWANCES", label: "Allowance" },
  { value: "DECLARATION_DISCUSSION", label: "Declaration Discussion" },
  { value: "HRA", label: "HRA" },
  { value: "SALARY_RESTRUCTURING", label: "Salary Restructuring" },
  { value: "TDS", label: "TDS" },
  { value: "PRODUCT_ON_BOARDING", label: "Product On Boarding" },
];
const meetingTopics = [
  { value: "TAX_PLANNING", label: "Tax Planning" },
  { value: "ITR_FILING", label: "ITR Filing" },
  { value: "SALARY_RESTRUCTURING", label: "Salary Restructuring" },
  { value: "GENERAL_QUERY", label: "General Query" },
  { value: "DECLARATION_DISCUSSION", label: "Declaration Discussion" },
  { value: "HRA", label: "HRA" },
  { value: "TDS", label: "TDS" },
  { value: "ALLOWANCES", label: "Allowances" },
  { value: "PRODUCT_ON_BOARDING", label: "Product Onboarding" },
  { value: "FINANCIAL_PLANNING", label: "Financial Planning" },
  { value: "HRMS_PORTAL", label: "HRMS Portal" },
  { value: "FBP_PLANNING_B2C", label: "FBP Planning B2C" },
  { value: "FBP_PLANNING_B2B", label: "FBP Planning B2B" },
  { value: "HRA_SUPPORT", label: "HRA Support" },
  { value: "TDS_SUPPORT", label: "TDS Support" },
];

export const allConsultationTypeUnregistered = [
  { value: "Tax Assessment Session", label: "Tax Assessment Session" },
  { value: "General Discussion", label: "General Discussion" },
];

export const getEventDescription = (
  clientName,
  consultationType,
  date,
  startTime,call_booking_id
) => {

  return `
Hi ${convertToPascalCase(clientName)},

It's a pleasure to inform you that your ${consultationType} is ${call_booking_id?`rescheduled`:`scheduled` } with the Taxation Expert as per your requested time on ${date} at ${startTime}.

Feel free to reach out to us any time at connect@prosperr.io or call us at +91 9739779797.

Regards,
Customer Success team
Prosperr.io
+91 9739779797
    `;
};

export { meetingTopics };
