import React from "react";
import {StyledPopupCardForActionInfo} from "./PopupCardForActionInfo.styled";
import {CustomizedButton} from "../Button";
import {RxCrossCircled} from "react-icons/rx";
import { savePopupActivationTimeInLocal } from "./utils";


const actionInfo = (warningInfo) => {
  const handleChatMomReply = (key = "chat") => {
    savePopupActivationTimeInLocal();
    const linkToRedirect =
      key === "chat" ? `/active-chats` : `/consultation-view`;
    window.open(linkToRedirect, "_self");
  };

  const getActionData = (warningInfo) => {
    const {
      oldest_pending_mom_days,
      oldest_unreplied_chat_days,
      remaining_pending_mom_lock_days,
      remaining_unreplied_chat_lock_days,
    } = warningInfo;

    const pendingDaysInLock =
      remaining_pending_mom_lock_days > remaining_unreplied_chat_lock_days
        ? remaining_unreplied_chat_lock_days
        : remaining_pending_mom_lock_days;

    if (oldest_pending_mom_days > 0 && oldest_unreplied_chat_days > 0) {
      return {
        description: `${oldest_unreplied_chat_days} days old chat reply and ${oldest_pending_mom_days} days MOM pending,
                      Act now or CMS access will be blocked within ${pendingDaysInLock} days.`,

        buttonList: [
          {
            buttonTitle: "Reply pending chat",
            buttonAction: () => {
              handleChatMomReply();
            },
          },
          {
            buttonTitle: "Send MOM",
            buttonAction: () => {
              handleChatMomReply("MOM");
            },
          },
        ],
      };
    } else if (
      oldest_pending_mom_days === 0 &&
      oldest_unreplied_chat_days > 0
    ) {
      return {
        description: `${oldest_unreplied_chat_days} days old Chat reply pending
  Act now or CMS access will be blocked within ${pendingDaysInLock} days.`,

        buttonList: [
          {
            buttonTitle: "Reply pending chat",
            buttonAction: () => {
              handleChatMomReply();
            },
          },
        ],
      };
    } else if (
      oldest_unreplied_chat_days === 0 &&
      oldest_pending_mom_days > 0
    ) {
      return {
        description: `${oldest_pending_mom_days} days old MOM pending
        Act now or CMS access will be blocked within ${pendingDaysInLock} days.`,

        buttonList: [
          {
            buttonTitle: "Send MOM",
            buttonAction: () => {
              handleChatMomReply("MOM");
            },
          },
        ],
      };
    }
  };

  return {
    title: "Attention required!",
    icon: process.env.PUBLIC_URL + "/assets/icons/warningIcon.svg",
    ...getActionData(warningInfo),
  };
};

const actionInfoBlock = {
  title: "Your account is locked",
  description: `Oops, Your account has been locked. For further solution reach out to your manager`,

  icon: process.env.PUBLIC_URL + "/assets/icons/lockedIcon.svg",
};

const PopupCardForActionInfo = ({WarningVisible, warningInfo}) => {
  const {title, description, icon, buttonList} = WarningVisible
    ? actionInfo(warningInfo)
    : actionInfoBlock;
  const handleCross = () => {
    savePopupActivationTimeInLocal();
    window.open("/", "_self");
  };

  return (
    <StyledPopupCardForActionInfo>
      {WarningVisible && (
        <RxCrossCircled
          className="crossIcon"
          onClick={() => {
            handleCross();
          }}
        />
      )}
      <img src={icon} alt="" className="icon" />
      <div className="title">{title}</div>
      <div className="description">{description}</div>

      {buttonList?.map((button, buttonIndex) => {
        const {buttonTitle, buttonAction} = button;
        const lastBtnActivate =
          buttonList?.length > 1 && buttonIndex === buttonList?.length - 1;

        return (
          <CustomizedButton
            key={buttonIndex}
            onClick={() => {
              buttonAction();
            }}
            className={lastBtnActivate ? " lastBtn" : "actionButton"}
          >
            {buttonTitle}
          </CustomizedButton>
        );
      })}
    </StyledPopupCardForActionInfo>
  );
};

export default PopupCardForActionInfo;
