import styled from 'styled-components';

export const FileUploadStyle = styled.div`
	display: flex;
	cursor: pointer;
	& > input {
		display: none;
	}

	img {
		width: 24px;
		cursor: pointer;
	}
`;
