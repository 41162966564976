import { httpClient } from "./httpUtil";

const ConsultationMomService = {
  updateConsultationStatus: (requester_id, data) => {
    return httpClient.put(
      `/appointments/v1/call-booking/${requester_id}/update`,
      data
    );
  },
  updateActionableStatus: (user_id, data) => {
    return httpClient.put(
      `/appointments/v1/call-summary/${user_id}/update-call-summary-action-item `,
      data
    );
  },
  getConsultationList: (user_id, advisor_id) => {
    return httpClient.get(`appointments/v1/call-history/${user_id}/details/internal`, {
      advisor_id,
    });
  },
  getConsultationMom: (call_summary_id) => {
    return httpClient.get(
      `appointments/v1/call-summary/${call_summary_id}/internal`
    );
  },
  postConsultationMom: (advisor_id, data) => {
    return httpClient.post(
      `appointments/v1/call-summary/${advisor_id}/internal`,
      data
    );
  },
};

export { ConsultationMomService };
