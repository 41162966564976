function hexToRgba(hex, opacity) {
	// Remove the # character from the beginning of the hex color
	hex = hex.replace('#', '');

	// Convert the hex color to RGB format
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export { hexToRgba };
