import { httpClient } from "./httpUtil";

const SuperSaverUserProfileApi = {
  getUserDetailsByUserId: (userId, fYId) => {
    return (
      userId &&
      httpClient.get(
        `super-saver-user/v1/${userId}/details/internal?financial_year_id=${fYId}`
      )
    );
  },
};

export { SuperSaverUserProfileApi };
