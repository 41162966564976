const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const BASE_URL_STAGE = process.env.REACT_APP_STAGING_API_ENDPOINT;
const BASE_URL_LOCAL = process.env.REACT_APP_LOCAL_API_ENDPOINT;
const BASE_URL_PROD = process.env.REACT_APP_PRODUCTION_API_ENDPOINT;

let BASE_URL = "";

if (ENVIRONMENT === "PRODUCTION") {
    BASE_URL = BASE_URL_PROD;
} else if (ENVIRONMENT === "STAGING") {
    BASE_URL = BASE_URL_STAGE;
} else {
    BASE_URL = BASE_URL_LOCAL;
}

export default BASE_URL;
