import React from "react";
import styled from "styled-components";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { theme } from "../../styles/theme";
import { Tooltip } from "@mui/material";

function TimeSlot({ status, children, title, onCancelClick,event,toggleBackdrop,call_booking_id,selectedAdvisor }) {
    const getColor = (status) => {
        switch (status) {
            case "available":
                return "#00330022";

            case "booked":
                return "#22222222";

            default:
                return "#77000022";
        }
    };

    return (
        <TimeSlotWrapper bg={getColor(status)}>
            <div>
                <p className="event-title">{title}</p>
                <p id="time-slot">{children}</p>
            </div>
         
            {    event?.call_booking_id&& !call_booking_id&&<div className="flexWrapper">
            <Tooltip title="Reschedule Event" placement="top">
                <div
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={()=>toggleBackdrop(null,{...event,selectedAdvisor})}
                > 
                <CiEdit /> 
                </div>
            </Tooltip> 
              <Tooltip title="Delete Event" placement="top">
                <div
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={onCancelClick}
                > <MdDelete color={theme.color.red_0} />
                 </div>
            </Tooltip>
                 </div>  }
               
        </TimeSlotWrapper>
    );
}

export default TimeSlot;

const TimeSlotWrapper = styled.div`
    background-color: ${({ bg }) => bg && bg};
    box-sizing: border-box;
    display: flex;
    height: 100%;
    padding: 8px 8px;
    min-width: 120px;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 8px;
    justify-content: space-between;

    #time-slot {
        margin: 0px;
        margin-top: 4px;
        padding: 0px;
        font-size: 12px;
    }

    .event-title {
        margin: 0;
        font-size: 14px;
    }
    .flexWrapper{
        display: flex;
        gap:10px;
    }
`;
