import {Actions} from "./action";

const initialData = {
  financialYearList: [],
  planUpgradePopupStatus: false,
  planUpgradePopupSteps: 0,
};

const cmsReducer = (state = initialData, payload = {}) => {

  switch (payload.type) {
    case Actions.SET_META_DATA:
      return {...state, ...payload.data};
    case Actions.SET_PLAN_UPGRADE_POPUP_STATUS:
      return {...state,planUpgradePopupStatus:payload.data};
    case Actions.SET_PLAN_UPGRADE_POPUP_STEPS:
      return {...state,planUpgradePopupSteps:payload?.data};
    case Actions.SET_FINANCIAL_YEAR_LIST:
      return {
        ...state,
        financialYearList: payload.data,
      };
    default:
      return state;
  }
};

export {cmsReducer};
