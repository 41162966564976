import React from "react";
const renderHTML = (data, tag = "div", className = "") => {
    if (tag === "p") {
        return (
            <p
                className={className}
                dangerouslySetInnerHTML={{ __html: data }}
            />
        );
    } else if (tag === "div") {
        return (
            <div
                className={className}
                dangerouslySetInnerHTML={{ __html: data }}
            />
        );
    } else if (tag === "h2") {
        return (
            <h3
                className={className}
                dangerouslySetInnerHTML={{ __html: data }}
            />
        );
    } else {
        return <div dangerouslySetInnerHTML={{ __html: data }} />;
    }
};

export default renderHTML;
