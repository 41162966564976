import styled from "styled-components";

export const Main = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    height: auto;
    gap: 4px;
    .ClientHed {
        font-size: 14px;
        font-weight: 500;

        color: #222;
        margin: 5px;
    }
.expireStrip{
    width: 100%;
    background-color: rgb(255 26 0 / 30%);
    color: red;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
}
    .ClientWrap {
        display: flex;
        flex-wrap: wrap;
        width: 66%;
        background: #fff;
        border-radius: 4px;
        height: auto;
        box-sizing: border-box;
    }
    .activityWrap {
        width: 32%;
        background: #fff;
        border-radius: 4px;
        overflow-wrap: break-word;
        margin-left: 20px;
        height: auto;
        box-sizing: border-box;
    }
    .clientBox {
        width: 250px;
        padding-bottom: 5px;
        border-bottom: 2px solid #808080;
        margin: 20px 34px;
        box-sizing: border-box;
        position: relative;
    }

    .copyIcon {
        display: none;
        position: absolute;
        right: 0;
    }
    .bord {
        width: 250px;
        padding-bottom: 5px;
        border-bottom: 2px;
        margin: 20px 34px;
        box-sizing: border-box;
        position: relative;
    }

    .btn-container {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 8px;
        padding: 16px;
        margin-bottom: 12px;
    }
`;
export const ClientDetails = styled.div`
    color: #7f7f7f;
    min-height: 20px;
    margin-left: 5px;
    display: flex;
    &:hover .copyIcon {
        display: ${(props) => (props.activate ? "block" : "0.4")};
    }
    .select {
        width: 180px;
    }
    .data {
        font-size: 12px;
    }
    .invitedUser {
        opacity: ${(props) => (props.activate ? "1" : "0.4")};
        cursor: ${(props) => (props.activate ? "pointer" : "not-allowed")};
        &:hover {
            background: ${(props) => (props.activate ? " #d8e7f2" : "#0e6ab0")};
            color: ${(props) => (props.activate ? " #0e6ab0" : "#ffffff")};
        }
    }
    .cb {
        display: none;
    }

    .button {
        display: block;
        width: 100px;
        height: 50px;
        border-radius: 25px;
        text-align: center;
        line-height: 46px;
        text-transform: uppercase;
        font-weight: 600;
        transform-style: preserve-3d;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .viewbtn {
        display: flex;
        align-items: center;
        width: auto;
        padding: 4px 10px;
        height: 50px;
        border-radius: 25px;
        text-align: center;
        line-height: 46px;
        font-weight: 600;
        transform-style: preserve-3d;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        color: #34495e;
        box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .cb:checked ~ .button {
        transform: rotateX(180deg);
        box-shadow: 4px -8px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .crp {
        cursor: pointer;
    }
`;
