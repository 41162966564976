import React, { useState } from "react";
import { FaCross } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { StyledPayslipUpload } from "./PayslipUpload.styled";
import { getFillName } from "../../../../../../utils/randomFileNameGenerator";
import { PreSignedUrlUpload } from "../../../../../../utils/preSignedUrl";
import { dim } from "../../../../../../styles/theme";
import Button from "../../../../../../commonComponents/Button";
const PayslipUpload = ({
    companyName,
    isOpen,
    handleSetDocumentSubmit,
    documentName,
    setDocumentName,
    UploadAvatar,
    setUploadAvatar,
    attachment,
    setAttachment,
    month,
}) => {
    const [fileSize, setFileSize] = useState("");
    const handleChangeAttachment = (e, setAttachment, setUploadAvatar) => {
        e.stopPropagation();
        const imageFile = e.target.files[0];
        const fileSizeInKB = imageFile.size / 1024; // size in KB
        const fileSizeInMB = imageFile.size / (1024 * 1024); // Convert bytes to MB

        if (fileSizeInKB > 1000) {
            setFileSize(Math.round(fileSizeInMB) + " MB");
        } else {
            setFileSize(Math.round(fileSizeInKB) + " KB");
        }

        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        const newFilename = getFillName(fileName, ext, "SalarySlipUpload");
        setDocumentName(`${fileName}.${ext}`);
        const ReturnValueSetter = () => {
            setAttachment(e.target.value);
            setUploadAvatar({
                name: imageFile.name,
                upload: true,
                img: `${newFilename}`,
            });
        };

        const preSignedUrl = PreSignedUrlUpload(
            newFilename,
            imageFile,
            ReturnValueSetter
        );
        toast.promise(preSignedUrl, {
            loading: "Document Uploading...",
            success: "Document Uploaded",
            error: "Oops something went wrong",
        });
    };
    return (
        <StyledPayslipUpload className="uploadModel" isOpen={isOpen}>
            <h2 className="heading">{companyName}</h2>
            <h2 className="heading Salary">Upload Salary Slip For {month}</h2>

            <p className="subheading">
                TIFF/JPG/ GIF/ PNG/ BMP/ OXPS/ PDF/ XPS/ MSG Total File Size :
                0.00 MB / 10 MB
            </p>
            <div className="uploadComponent">
                {!UploadAvatar.upload ? (
                    <div className="uploadBtn">
                        <input
                            style={{ display: "none" }}
                            type={"file"}
                            accept=".jpg, .jpeg, .png, .pdf"
                            value={attachment}
                            onChange={(e) => {
                                handleChangeAttachment(
                                    e,
                                    setAttachment,
                                    setUploadAvatar
                                );
                            }}
                            name=""
                            id="attachmentUpload"
                            className="uploadIcon"
                        />
                        <label
                            className="uploadIcon tooltip"
                            htmlFor="attachmentUpload"
                        >
                            <img
                                className="icon"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/icons/cloud_icon.png"
                                }
                                alt=""
                            />
                            <div className="iconDesc">
                                Browse to choose a file
                            </div>
                        </label>
                    </div>
                ) : (
                    <div className="uploadSuccess">
                        <img
                            className="icon"
                            src={
                                process.env.PUBLIC_URL +
                                "/assets/icons/ImagePreview.svg"
                            }
                            alt=""
                        />
                        <p>
                            <span className="documentNameElip">
                                {documentName}
                            </span>
                            <span>|</span>
                            <span
                                style={{
                                    fontWeight: 400,
                                    fontSize: dim._12px,
                                    lineHeight: dim._16px,
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#635BEF",
                                }}
                            >
                                {fileSize}
                            </span>
                        </p>
                        <FaCross
                            style={{
                                position: "absolute",
                                right: dim._32px,
                                top: dim._24px,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setUploadAvatar({
                                    upload: false,
                                    name: "No file choosen",
                                    img: "",
                                });
                                setAttachment(null);
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="comment">
                <label for="comment">Description (optional)</label>
                <textarea
                    type="text"
                    id="comment"
                    name="fav_language"
                    placeholder="Write here..."
                />
            </div>
            <div className="btnHandler">
                <Button
                    style={{
                        width: dim._scale(124),
                        fontWeight: "500",
                        fontSize: dim._12px,
                        lineHeight: dim._16px,
                    }}
                    onButtonClick={() => {
                        handleSetDocumentSubmit(true);
                    }}
                    isEnabled={!UploadAvatar.upload}
                    buttonName="Read Payslip"
                ></Button>
            </div>
        </StyledPayslipUpload>
    );
};

export default PayslipUpload;
