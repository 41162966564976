import React from "react";
import { RenderAdvisorActionDataStyle } from "./RenderAdvisorActionData.styled";
import AdvisorSubmitProof from "../AdvisorSubmitProof";
import { dim } from "../../../../../styles/theme";
import TextButton from "../../../../../commonComponents/TextButton";

const RenderAdvisorActionData = ({
  data,
  handleDeleteActionable,
  add_action,
  handleAddAction,
  isEditDisabled,
  getConsultationMom = () => {},
  type = "",
}) => {
  return (
    <RenderAdvisorActionDataStyle>
      <div
        className="cardHeading"
        style={{ margin: `${dim._20px} ${dim._0px} ${dim._0px} ${dim._0px}` }}
      >
        {add_action}
      </div>
      {data?.map((item, index) => (
        <AdvisorSubmitProof
          item={item}
          key={index}
          index={index}
          type={type}
          handleDeleteActionable={handleDeleteActionable}
          isEditDisabled={isEditDisabled}
          getConsultationMom={getConsultationMom}
        />
      ))}
      {!isEditDisabled && (
        <TextButton
          onClick={() => {
            handleAddAction();
          }}
          style={{ width: "fit-content" }}
        >
          + Add Another Action
        </TextButton>
      )}
    </RenderAdvisorActionDataStyle>
  );
};

export default RenderAdvisorActionData;
