import React from  'react'
import { Navigate, Route, Routes } from 'react-router'
import RentAgreement from '../../../container/RentAgreement'
import RentDetails from '../../../container/RentAgreement/common-components/RentDetails'
import SuperSaverUser from '../../../container/SuperSaverUser'
import SuperSaverUserProfile from '../../../container/SuperSaverUserProfile'
const AccountantRoutes = () => {
  return (
    <Routes>
    <Route
      path="/rent-agreement"
      element={<RentAgreement pageName="ImageUpload" />}
    />
    <Route
      path="/rent-agreement/details"
      element={<RentDetails pageName="ImageUpload" />}
    />
    <Route path="/super-saver-users" element={<SuperSaverUser />} />
    <Route path="/" element={<SuperSaverUser />} />
    <Route path="/:id" element={<SuperSaverUser />} />
     <Route
      path="/superSaverUserProfile"
      element={<SuperSaverUserProfile />}
    />
    </Routes>
  )
}

export default AccountantRoutes