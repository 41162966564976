import React from 'react'
import { ClientDetails } from '../../../SuperClientProfile/SuperSaverProfile.styled';
import EditBtn from '../../../../commonComponents/EditBtn';
import { MdContentCopy } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
const TargetTaxComponent = ({selectedFy,targeted_tax,show,setShow,userId,onCopyText,isCopied}) => {

    
  return (
 <>      <div className="clientBox">
 <div className="ClientHed">Targeted Tax ({selectedFy.label})</div>
 <ClientDetails
   className="ClientDetails"
   style={{ justifyContent: "space-between" }}
 >
   <div className="data">{targeted_tax}</div>
   <span
     onClick={() => {
       setShow(!show);
     }}
   >
     <EditBtn />
   </span>
   <CopyToClipboard
     className="copyIcon"
     text={userId}
     onCopy={onCopyText}
   >
     <span>{isCopied ? "Copied!" : <MdContentCopy />}</span>
   </CopyToClipboard>
 </ClientDetails>
</div></>
  )
}

export default TargetTaxComponent