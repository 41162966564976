import React, {useState} from "react";
import {
  extractFilename,
  getUniqueFileName,
} from "../../../../utils/randomFileNameGenerator";
import toast from "react-hot-toast";
import {FileUploadService} from "../../../../Services/FileUploadServices";
import styled from "styled-components";
import {Loader1, Loader2} from "../../../../commonComponents/Loader";
import {dim} from "../../../../styles/theme";

const UploadFileInput = ({name, handleImageUpload, fileName, handleChange,aspectRatio}) => {


  if (fileName === "loading")
  return (
    <StyledUploadFileInput>
      <div className="uploadLabel">
        <Loader2
          loaderStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
          style={{
            height: `${dim._16px}`,
            width: `${dim._16px}`,
          }}
        />{" "}
      </div>
    </StyledUploadFileInput>
  );

  return (
    <StyledUploadFileInput>
   
      {!fileName ? (
        <>
          <label for={"fileInput" + name} className="uploadLabel">
            Choose file
            {/* <img src={UploadSvg} className='UploadSvg' alt='UploadSvg'/> */}
            <UploadSvg className="UploadSvg" />
          </label>

          <input
        accept="image/png, image/jpeg"
            type="file"
            id={"fileInput" + name}
            className="fileUploadInput"
            name={name}
            onChange={(e) => handleImageUpload(e, name,aspectRatio)}
          />
        </>
      ) : (
        <div className="uploadLabel">
          <span>
            {extractFilename(fileName).length>15?extractFilename(fileName).slice(0,15)+'...':extractFilename(fileName)} <CheckSvg />
          </span>{" "}
          <div className="crossIcon" onClick={() => handleChange(name, "")}>
            <CrossSvg />
          </div>
        </div>
      )}
    </StyledUploadFileInput>
  );
};
const UploadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
  >
    <path
      d="M17.3924 12.5V15H6.17707V12.5H4.30786V15C4.30786 15.9167 5.14901 16.6667 6.17707 16.6667H17.3924C18.4204 16.6667 19.2616 15.9167 19.2616 15V12.5H17.3924ZM7.11168 7.5L8.42948 8.675L10.8501 6.525V13.3333H12.7193V6.525L15.1399 8.675L16.4577 7.5L11.7847 3.33333L7.11168 7.5Z"
      fill="#9AA3B2"
    />
  </svg>
);
const CheckSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
  >
    <rect width="12.5" height="12.5" rx="6.25" fill="#27AE60" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.90573 4.52314L6.01118 8.84722C5.79434 9.08795 5.44741 9.08795 5.23057 8.84722L3.28763 6.67129C3.07079 6.43057 3.07079 6.0417 3.28763 5.8009C3.50448 5.56018 3.8514 5.56018 4.06825 5.8009L5.62953 7.54165L9.13376 3.65275C9.3506 3.41203 9.69752 3.41203 9.91437 3.65275C10.1226 3.89348 10.1226 4.28242 9.90572 4.52314H9.90573Z"
      fill="white"
    />
  </svg>
);
const CrossSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
  >
    <g opacity="0.75">
      <path
        d="M5.96424 5.99998L9.2825 2.67473C9.5472 2.40947 9.5472 1.96421 9.2825 1.69895C9.01779 1.43368 8.57347 1.43368 8.30877 1.69895L4.99051 5.0242L1.67226 1.69895C1.40756 1.43368 0.963232 1.43368 0.698528 1.69895C0.433824 1.96421 0.433824 2.40947 0.698528 2.67473L4.01678 5.99998L0.698528 9.32523C0.433824 9.5905 0.433824 10.0358 0.698528 10.301C0.830876 10.4336 1.0105 10.5 1.19014 10.5C1.36978 10.5 1.53991 10.4337 1.68175 10.301L5 6.97577L8.31825 10.301C8.4506 10.4336 8.63023 10.5 8.80986 10.5C8.9895 10.5 9.15963 10.4337 9.30147 10.301C9.56618 10.0358 9.56618 9.5905 9.30147 9.32523L5.96424 5.99998Z"
        fill="#9AA3B2"
      />
    </g>
  </svg>
);
const StyledUploadFileInput = styled.div`
  width: 100%;
  min-width: 250px;
  width: auto;
  box-sizing: border-box;
  .uploadLabel {
    width: 100%;
 
    border-radius: 8px;
    border: 1px solid #cdd5df;
    background: var(--Gray-White, #fff);
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    box-sizing: border-box;
    max-width:200px;
    span {
      display: flex;
      align-items: flex-end;
      color: #095bcb;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      gap: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width:170px;
    }
  }
  .UploadSvg {
    width: 22.431px;
    height: 20px;
  }
  // #fileInput{
  //     display:none;
  // }
  .fileUploadInput {
    display: none;
  }
  .crossIcon {
    cursor: pointer;
  }
`;

export default UploadFileInput;
