export const LS_TAX_DETAILS = 'taxDetail';

const saveTaxDetailLS = (taxDetail) => {
	sessionStorage.clear();
	sessionStorage.setItem(LS_TAX_DETAILS, JSON.stringify(taxDetail));
};

export const calculateTax = (salery, tax, setNewTax) => {
	let taxableIncome = 0;
	let newTaxcal = 0;
	let diduction = 0;
	const basicSalery = salery * 0.4;

	// all taxes
	const hra = basicSalery * 0.4;
	const c80 = 150000;
	const d80_sc = 75000;

	const tta80 = 10000;
	const allowance = basicSalery * 0.0;
	const standardDeduction = 50000;

	const profTax = 2400;
	const lta = basicSalery * 0.1;
	const homeLoan = 200000;

	// other taxes, applicable only if tax > 50000
	const ccd80 = 50000;
	const ddb80 = 100000;

	//taxble income
	if (salery <= 1500000) {
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				profTax +
				lta);

		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			profTax +
			lta;
	} else if (salery >= 1500001 && salery <= 2000000) {
		let homeLoan = 150000;
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				profTax +
				lta +
				ccd80 +
				homeLoan +
				ddb80);
		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			profTax +
			lta +
			ccd80 +
			homeLoan +
			ddb80;
	} else if (salery >= 2000001 && salery <= 2500000) {
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				profTax +
				lta +
				ccd80 +
				homeLoan +
				ddb80);
		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			profTax +
			lta +
			ccd80 +
			homeLoan +
			ddb80;
	} else if (salery >= 2500001 && salery < 3000000) {
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				profTax +
				lta +
				homeLoan +
				ccd80 +
				ddb80);
		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			profTax +
			lta +
			homeLoan +
			ccd80 +
			ddb80;
	} else {
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				homeLoan +
				profTax +
				lta +
				ccd80 +
				ddb80);
		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			homeLoan +
			profTax +
			lta +
			ccd80 +
			ddb80;
	}

	// new tax calculation
	if (taxableIncome <= 250000) {
		newTaxcal = 0;
		setNewTax({
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 0,
			taxSaved: tax - newTaxcal
		});
	} else if (taxableIncome >= 250001 && taxableIncome <= 500000) {
		newTaxcal = (taxableIncome - 250000) * 0.05;
		setNewTax({
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 5,
			taxSaved: tax - newTaxcal
		});
	} else if (taxableIncome >= 500001 && taxableIncome <= 750000) {
		newTaxcal = 12500 + (taxableIncome - 500000) * 0.2;
		setNewTax({
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 20,
			taxSaved: tax - newTaxcal
		});
	} else if (taxableIncome >= 750001 && taxableIncome <= 1000000) {
		newTaxcal = 62500 + (taxableIncome - 750000) * 0.2;
		setNewTax({
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 20,
			taxSaved: tax - newTaxcal
		});
	} else if (taxableIncome >= 1000001 && taxableIncome <= 1250000) {
		newTaxcal = 112500 + (taxableIncome - 1000000) * 0.3;

		setNewTax({
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 30,
			taxSaved: tax - newTaxcal
		});
	} else if (taxableIncome >= 1250001 && taxableIncome <= 1500000) {
		newTaxcal = 187500 + (taxableIncome - 1250000) * 0.3;
		setNewTax({
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 30,
			taxSaved: tax - newTaxcal
		});
	} else if (taxableIncome > 1500000) {
		newTaxcal = 262500 + (taxableIncome - 1500000) * 0.3;
		setNewTax({
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 30,
			taxSaved: tax - newTaxcal
		});
	}
};

export const calculateTaxValue = (salery, tax) => {
	let taxableIncome = 0;
	let newTaxcal = 0;
	let diduction = 0;
	const basicSalery = salery * 0.4;

	// all taxes
	const hra = basicSalery * 0.4;
	const c80 = 150000;
	const d80_sc = 75000;

	const tta80 = 10000;
	const allowance = basicSalery * 0.0;
	const standardDeduction = 50000;

	const profTax = 2400;
	const lta = basicSalery * 0.1;
	const homeLoan = 200000;

	// other taxes, applicable only if tax > 50000
	const ccd80 = 50000;
	const g80 = 0;
	const ddb80 = 100000;

	//taxble income
	if (salery <= 2000000) {
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				profTax +
				lta);

		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			profTax +
			lta;
	} else if (salery >= 2000001 && salery <= 2500000) {
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				profTax +
				lta +
				ccd80 +
				g80 +
				homeLoan +
				ddb80);
		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			profTax +
			lta +
			ccd80 +
			g80 +
			homeLoan +
			ddb80;
	} else if (salery >= 2500001 && salery < 3000000) {
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				profTax +
				lta +
				homeLoan +
				ccd80 +
				ddb80);
		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			profTax +
			lta +
			homeLoan +
			ccd80 +
			ddb80;
	} else {
		taxableIncome =
			salery -
			(hra +
				c80 +
				d80_sc +
				tta80 +
				allowance +
				standardDeduction +
				homeLoan +
				profTax +
				lta +
				ccd80 +
				g80 +
				ddb80);
		diduction =
			hra +
			c80 +
			d80_sc +
			tta80 +
			allowance +
			standardDeduction +
			homeLoan +
			profTax +
			lta +
			ccd80 +
			g80 +
			ddb80;
	}

	if (taxableIncome <= 250000) {
		newTaxcal = 0;
		return {
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 0,
			taxSaved: tax - newTaxcal,
			salary: salery
		};
	} else if (taxableIncome >= 250001 && taxableIncome <= 500000) {
		newTaxcal = (taxableIncome - 250000) * 0.05;
		return {
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 5,
			taxSaved: tax - newTaxcal,
			salary: salery
		};
	} else if (taxableIncome >= 500001 && taxableIncome <= 750000) {
		newTaxcal = 12500 + (taxableIncome - 500000) * 0.2;
		return {
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 20,
			taxSaved: tax - newTaxcal,
			salary: salery
		};
	} else if (taxableIncome >= 750001 && taxableIncome <= 1000000) {
		newTaxcal = 62500 + (taxableIncome - 750000) * 0.2;
		return {
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 20,
			taxSaved: tax - newTaxcal,
			salary: salery
		};
	} else if (taxableIncome >= 1000001 && taxableIncome <= 1250000) {
		newTaxcal = 112500 + (taxableIncome - 1000000) * 0.3;

		return {
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 30,
			taxSaved: tax - newTaxcal,
			salary: salery
		};
	} else if (taxableIncome >= 1250001 && taxableIncome <= 1500000) {
		newTaxcal = 187500 + (taxableIncome - 1250000) * 0.3;
		return {
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 30,
			taxSaved: tax - newTaxcal,
			salary: salery
		};
	} else if (taxableIncome > 1500000) {
		newTaxcal = 262500 + (taxableIncome - 1500000) * 0.3;
		return {
			taxableIncome: taxableIncome,
			totalDiduction: diduction,
			totalTax: newTaxcal,
			taxSlab: 30,
			taxSaved: tax - newTaxcal,
			salary: salery
		};
	}
};

const standardDeduction = {
	professionalTax: 0,
	standardSalaryDeduction: 50000
	//Include any other standard deduction for salaried employee if any
};

const incomeCessAndSurcharge = (incomeTax, taxableIncome) => {
	let marginalTaxRelief = 0;
	const fiftylakhSlab = 5000000;
	let ComputedIncomeTax = incomeTax;

	if (taxableIncome > fiftylakhSlab) {
		const surcharge = incomeTax * 0.1;
		const incomeAbove50Lakhs = taxableIncome - fiftylakhSlab;
		const incomeTaxTill50Lakhs = 1312500;
		marginalTaxRelief =
			incomeTax + surcharge - incomeTaxTill50Lakhs - incomeAbove50Lakhs;

		if (marginalTaxRelief > 0) {
			ComputedIncomeTax = ComputedIncomeTax + surcharge - marginalTaxRelief;
		} else {
			ComputedIncomeTax = ComputedIncomeTax + surcharge;
		}
	}
	const healthCess = ComputedIncomeTax * 0.04;

	return ComputedIncomeTax > 12500 ? ComputedIncomeTax + healthCess : 0;
};

const getTaxableIncome = (salary, diduction, bussinessOtherIncome = 0) => {
	let salaryAfterStandardDeduction =
		salary -
		standardDeduction.professionalTax -
		standardDeduction.standardSalaryDeduction;

	if (salaryAfterStandardDeduction <= 0) {
		salaryAfterStandardDeduction = 0;
	}

	if (salaryAfterStandardDeduction + bussinessOtherIncome - diduction <= 0) {
		return 0;
	} else {
		return salaryAfterStandardDeduction + bussinessOtherIncome - diduction;
	}
};
const getTaxableIncomeSTCG_EQUITY = (
	salary,
	STCG_DEBT_TOTAL_AMOUNT,
	diduction,
	bussinessOtherIncome
) => {
	let salaryAfterStandardDeduction =
		salary -
		standardDeduction.professionalTax -
		standardDeduction.standardSalaryDeduction;
	if (salaryAfterStandardDeduction < 0) {
		salaryAfterStandardDeduction = 0;
	}

	if (
		salaryAfterStandardDeduction +
			STCG_DEBT_TOTAL_AMOUNT +
			bussinessOtherIncome -
			diduction <=
		0
	) {
		return 0;
	} else {
		return (
			salaryAfterStandardDeduction +
			STCG_DEBT_TOTAL_AMOUNT +
			bussinessOtherIncome -
			diduction
		);
	}
};

const getTaxDetailObject = (
	taxableIncome,
	diduction,
	newTaxcal,
	taxSlab,
	salary,
	STCG_DEBT_TOTAL_AMOUNT
) => {
	return {
		taxableIncome: taxableIncome,
		totalDiduction: diduction,
		totalTax: incomeCessAndSurcharge(newTaxcal, taxableIncome),
		taxSlab: taxSlab,
		salary: salary,
		STCG_DEBT_TOTAL_AMOUNT: STCG_DEBT_TOTAL_AMOUNT
	};
};

export const getTaxBySalary = (
	salary,
	diduction,
	STCG_DEBT_TOTAL_AMOUNT,
	bussinessOtherIncome = 0,
	isSetSessipnStorage = true
) => {
	let taxableIncome = isSetSessipnStorage
		? getTaxableIncome(salary, diduction, bussinessOtherIncome)
		: getTaxableIncomeSTCG_EQUITY(
				salary,
				STCG_DEBT_TOTAL_AMOUNT,
				diduction,
				bussinessOtherIncome
		  );
	let newTaxcal = 0;
	const incomeSlab1 = {
		min: 250000,
		max: 250001
	};

	const incomeSlab2 = {
		min: 250001,
		max: 500000
	};

	const incomeSlab3 = {
		min: 500001,
		max: 750000
	};

	const incomeSlab4 = {
		min: 750001,
		max: 1000000
	};

	const incomeSlab5 = {
		min: 1000001,
		max: 1250000
	};

	const incomeSlab6 = {
		min: 1250001,
		max: 1500000
	};

	const incomeSlab7 = {
		min: 1500000,
		max: 1500001
	};

	if (taxableIncome <= incomeSlab1.min) {
		newTaxcal = 0;

		const detail = getTaxDetailObject(
			taxableIncome,
			diduction,
			newTaxcal,
			0,
			salary,
			STCG_DEBT_TOTAL_AMOUNT
		);

		isSetSessipnStorage && saveTaxDetailLS(detail);
		return detail;
	} else if (
		taxableIncome >= incomeSlab2.min &&
		taxableIncome <= incomeSlab2.max
	) {
		newTaxcal = (taxableIncome - 250000) * 0.05;
		const detail = getTaxDetailObject(
			taxableIncome,
			diduction,
			newTaxcal,
			5,
			salary,
			STCG_DEBT_TOTAL_AMOUNT
		);

		isSetSessipnStorage && saveTaxDetailLS(detail);
		return detail;
	} else if (
		taxableIncome >= incomeSlab3.min &&
		taxableIncome <= incomeSlab3.max
	) {
		newTaxcal = 12500 + (taxableIncome - 500000) * 0.2;
		const detail = getTaxDetailObject(
			taxableIncome,
			diduction,
			newTaxcal,
			20,
			salary,
			STCG_DEBT_TOTAL_AMOUNT
		);

		isSetSessipnStorage && saveTaxDetailLS(detail);
		return detail;
	} else if (
		taxableIncome >= incomeSlab4.min &&
		taxableIncome <= incomeSlab4.max
	) {
		newTaxcal = 62500 + (taxableIncome - 750000) * 0.2;
		const detail = getTaxDetailObject(
			taxableIncome,
			diduction,
			newTaxcal,
			20,
			salary,
			STCG_DEBT_TOTAL_AMOUNT
		);
		isSetSessipnStorage && saveTaxDetailLS(detail);
		return detail;
	} else if (
		taxableIncome >= incomeSlab5.min &&
		taxableIncome <= incomeSlab5.max
	) {
		newTaxcal = 112500 + (taxableIncome - 1000000) * 0.3;
		const detail = getTaxDetailObject(
			taxableIncome,
			diduction,
			newTaxcal,
			30,
			salary,
			STCG_DEBT_TOTAL_AMOUNT
		);

		isSetSessipnStorage && saveTaxDetailLS(detail);
		return detail;
	} else if (
		taxableIncome >= incomeSlab6.min &&
		taxableIncome <= incomeSlab6.max
	) {
		newTaxcal = 187500 + (taxableIncome - 1250000) * 0.3;
		const detail = getTaxDetailObject(
			taxableIncome,
			diduction,
			newTaxcal,
			30,
			salary,
			STCG_DEBT_TOTAL_AMOUNT
		);

		isSetSessipnStorage && saveTaxDetailLS(detail);
		return detail;
	} else if (taxableIncome > incomeSlab7.min) {
		newTaxcal = 262500 + (taxableIncome - 1500000) * 0.3;
		const detail = getTaxDetailObject(
			taxableIncome,
			diduction,
			newTaxcal,
			30,
			salary,
			STCG_DEBT_TOTAL_AMOUNT
		);

		isSetSessipnStorage && saveTaxDetailLS(detail);
		return detail;
	}
};
