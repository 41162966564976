import React from "react";
import {StyledDivider} from "./Divider.styled";

const Divider = ({
  width = 0,
  height = 0,
  style = {},
  background = "white",
  children,
}) => {
  return (
    <StyledDivider
      style={{...style, width: width, height: height, position: "relative"}}
    >
      {children && (
        <div
          className="childNode"
          style={{
            background: background,
          }}
        >
          {children}
        </div>
      )}
    </StyledDivider>
  );
};

export default Divider;
