import React from 'react';
import {StyledMobileAuthorDetails} from './Styled.MobileAuthorDetails';
import VerifiedChip from '../../../../../commonComponents/VerifiedChip';
import { Logo } from '../MobilePreview/MobilePreview.styled';

const authors = {
	oPyadav: "O P Yadav",
	prosperrteam: "Prosperr Team"
};

function MobileAuthorDetails({isVerified, authorData}) {
	return (
		<StyledMobileAuthorDetails>
			{authorData?.author_category === 'EXPERT' ? (
				<div className='mobile-outer-wrapper'>
					<div className='mobile-inner-wrapper'>
						<div className='mobile-first-div'>
							<div className='mobile-imgCont'>
								<div className='mobile-author-image-container'>
									<img
										className='mobile-authorImage'
										src={authorData?.user_details?.image}
										alt='author'
									/>
								</div>
							</div>

							<div className='mobile-nameAndDetails'>
								<p className='mobile-auth'>Author</p>
								<div className='mobile-authorDetails'>
									<p className='mobile-authorName'>{authorData?.user_details?.first_name}</p>
									<VerifiedChip />
									<p>|</p>
									<a
										href={authorData?.social_link?.linkedin}
										target='_blank'
										className='outLink'
									>
										<img
											src={
												process.env.PUBLIC_URL +
												'/assets/icons/linkedin-logo-author.svg'
											}
											className='linkd'
											alt='linkedin'
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
					<p className='mobile-author-brief'>{authorData?.brief}</p>
					<div className='mobile-tags-container'>
						{authorData?.interest?.map((tag, i) => (
							<div className='mobile-tag' key={i}>
								{tag}
							</div>
						))}
					</div>
				</div>
			) : (
				<div className='mobile-outer-wrapper'>
					<p className='mobile-auth'>Author</p>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<Logo
							src={`https://prosperr-images.s3.ap-south-1.amazonaws.com/prosperrLogo.png`}
							alt={'prosperrIcon'}
							style={{width: '18px', height: '18px'}}
							loading='lazy'
						/>
						<div className='mobile-author-detail-prosperr'>{authors.prosperrteam}</div>
						{isVerified && <VerifiedChip />}
					</div>
				</div>
			)}
		</StyledMobileAuthorDetails>
	);
}

export default MobileAuthorDetails;
