import { useEffect } from "react";

const useDataToUpdateSalaryComponentsUpdateDeduction = (
  DeductionComponent,
  setDeductionComponent
) => {
  useEffect(() => {
    if (DeductionComponent) {
      setDeductionComponent([...DeductionComponent]);
    }
  }, [DeductionComponent]);
};
const useDataToUpdateSalaryComponentsUpdateEarning = (
  EarningComponent,
  setEarningComponent
) => {
  useEffect(() => {
    if (EarningComponent) {
      const newArr = EarningComponent;
      setEarningComponent([...newArr]);
    }
  }, [EarningComponent]);
};

export {
  useDataToUpdateSalaryComponentsUpdateDeduction,
  useDataToUpdateSalaryComponentsUpdateEarning,
};
