import React, { useState } from 'react';
import { Dropdown, Menu, Space, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

function CustomDropdown({ authorData, setSelectedAuthor, selectedAuthor ,selectedAuthorName ,setSelectedAuthorName ,setNextToken,setPageNumber }) {

  const clearFilter = () => {
    setSelectedAuthor(null);
    setSelectedAuthorName(null);
    setPageNumber(0);
  };

  const onChange = (selectedItem) => {
    setSelectedAuthor(selectedItem.key);
    setSelectedAuthorName(selectedItem.label);
    setNextToken(0);
    setPageNumber(0);
  };

  const menu = (
    <Menu onClick={({ key }) => onChange(items.find(item => item.key === key))}>
      {authorData.map(item => (
        <Menu.Item key={item.user_details.external_id}>
          {`${item.user_details.first_name} ${item.user_details.last_name || ''}`}
        </Menu.Item>
      ))}
    </Menu>
  );

  const items = authorData.map((item) => ({
    label: `${item.user_details.first_name} ${item.user_details.last_name || ''}`,
    key: item.user_details.external_id,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ border: '1px solid #CDD5DF', padding: '8px 12px 8px 12px', borderRadius: '8px', backgroundColor: 'white', width: '150px', maxHeight: '40px' }}>
      <Dropdown overlay={menu}>
        <Space>
          <p style={{ width: '120px', maxHeight: '20px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedAuthorName ? selectedAuthorName : 'Author'}</p>
          {
            selectedAuthor ? <img style={{ cursor: 'pointer',width:'13px' }} onClick={()=>clearFilter()} src={process.env.PUBLIC_URL + "/assets/icons/Cancel.svg"} alt='x'/> : <DownOutlined />
          }
        </Space>
      </Dropdown>
      
    </div>
    
    </div>
  );
}

export default CustomDropdown;
