import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const MessageSendContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 1px;
  height: auto;
  margin: ${dim._20px} 0 0 0;
  z-index: 10;
  border: ${({ isAttached }) => (!isAttached ? "1px solid #e5e5e5" : "none")};
  border-radius: ${dim._4px};
  box-sizing: border-box;

  .all_chat_attachments {
    display: flex;
    flex-direction: column;
    gap: ${dim._1px};
    width: 100%;
    z-index: 10;
    box-sizing: border-box;
  }

  .input_items {
    display: flex;
    justify-content: space-between;
    height: 100%;
    height: ${dim._64px};
    padding: ${dim._8px} ${dim._16px};
    border-radius: ${dim._4px};
    background: #fff;
    box-shadow: 0 ${dim._4px} ${dim._16px} ${dim._4px} rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
  }

  .wrapperClassName {
    height: 100%;
    width: 70%;
    display: flex;
    border: none; /* Remove the border to match the textarea */
    justify-content: flex-start;
    align-items: center;
    padding: ${dim._10px};
    box-sizing: border-box;
    border-radius: ${dim._8px};
    background-color: #fff;
    margin-right: ${dim._10px};
  }

  .editorClassName {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    font-size: ${dim._14px};
    color: #333;
    font-family: inherit;
  }

  .text-area-component {
    height: auto;
    min-height: 0;
  }

  .input_items > textarea {
    width: 70%;
    height: auto;
    padding: ${dim._10px};
    margin-right: ${dim._10px};
    border: none;
    border-radius: ${dim._8px};
    font-size: ${dim._14px};
    color: #333;
    background-color: #fff;
    resize: none;
  }

  .input_items > textarea:focus {
    outline: none;
  }

  .input_items > textarea::placeholder {
    color: #999;
    font-family: inherit;
  }

  .action_icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  .send_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${dim._40px};
    height: ${dim._40px};
    border-radius: ${dim._10px};
    background: #15be53;
    cursor: pointer;
  }

  .action_icons > img {
    width: ${dim._24px};
    height: ${dim._24px};
    cursor: pointer;
  }
`;
