import styled from "styled-components";

export const StyledReschedulePopup=styled.div`
background:#fff;
box-sizing:border-box;
padding:10px;
border-radius:8px;
h2{
 padding:0;
 margin:0;
 font-size:18px;
    box-sizing:border-box;
}
.rescheduleHeading{
    font-size:34px;
    padding-left:20px;
}
.list-view{
    max-height:500px;
    overflow:scroll;
}

`