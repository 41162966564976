import styled from "styled-components";
import { dim, theme } from "../../../../styles/theme";

export const StyledConsultationExperienceForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${dim._8px};
  box-sizing: border-box;
  .formContainer {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 32px;
    box-sizing: border-box;
  }
  .greenButton {
    border-radius: 2px;
    background: #15be53;
    color: #fff;
    text-align: right;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: auto;
    padding: 12px 24px;
    margin: 24px 40px;
    cursor: pointer;
    box-sizing: border-box;
  }
  .disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
const MomDetailsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${dim._24px};
`;
const MOMDtails = styled.div`
  color: ${theme.color.black_20};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const TaxPlanningTitleStyle = styled.div`
  color: ${theme.color.black_20};
  text-align: right;
  font-size: ${dim._12px};
  font-weight: ${theme.fontWeight.weight_40};
`;

export { MomDetailsStyle, TaxPlanningTitleStyle, MOMDtails };
