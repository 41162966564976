import React, { useState } from "react";
import { StyledConsultationCommentModel } from "./ConsultationCommentModel.styled";
import DropDownComponent from "../DropdownComponet";
import ButtonWithUnderline from "../../../../commonComponents/ButtonWithUnderline";
import { dim } from "../../../../styles/theme";
import FormErrorMsgHandler from "../../../../commonComponents/FormErrormsgHandler";
import { ERROR_MESSAGE } from "../../constant";

const ConsultationCommentModel = ({
  commentList,
  handleAddMoreDocument,
  handleCommentChange,
  dropDownProps,
  isError,
  componentCategory,
  handleDeleteFile,
  isEditDisabled,
}) => {
  return (
    <StyledConsultationCommentModel>
      <div className="title">Call Summary</div>
      {commentList.map((d, index) => (
        <div className="commentSection" key={index}>
          <DropDownComponent {...dropDownProps} index={index} type={d.type} />
          <div className="commentInputBox">
            <label htmlFor="commentInput" className="commentLabel">
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/commentIcon.svg"}
                alt=""
                className="commentIcon"
              />
              <textarea
                // type="text"
                name="text"
                className="commentInput"
                id="commentInput"
                value={d.text}
                onChange={(e) => {
                  !isEditDisabled && handleCommentChange(e, index);
                }}
              />
            </label>
          </div>
          <img
            src={
              process.env.PUBLIC_URL + "/assets/icons/documentDeleteIcon.svg"
            }
            className="documentDeleteIcon"
            alt=""
            onClick={() => {
              !isEditDisabled && handleDeleteFile(index);
            }}
          />
        </div>
      ))}
      {isError?.error && isError.errorArr.includes(componentCategory) && (
        <FormErrorMsgHandler style={{ bottom: "-4px" }}>
          {" "}
          {ERROR_MESSAGE[componentCategory]}
        </FormErrorMsgHandler>
      )}
      <ButtonWithUnderline
        style={{
          fontSize: dim._12px,
          paddingTop: dim._12px,
        }}
        onClick={() => {
          !isEditDisabled && handleAddMoreDocument();
        }}
      >
        + Add New Comment
      </ButtonWithUnderline>
    </StyledConsultationCommentModel>
  );
};

export default ConsultationCommentModel;
