import React, { useState } from "react";
import { HiDocumentDownload } from "react-icons/hi";
import { Tooltip } from "@mui/material";
import { VscCopy } from "react-icons/vsc";
import { theme } from "../../../../styles/theme";

const AddedFile = ({
  downloadFile,
  docName,
  link,
  fileName,
  docProp = null,
  password = null,
  docId,
}) => {
  const [tooltipMessage, setTooltipMessage] = useState("Copy Password");
  const renderDocs = () => {
    try {
      const docObject = JSON.parse(link);
      const passObject = JSON.parse(password);

      if (Array.isArray(docObject)) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {docObject.map((it, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  key={index}
                >
                  {passObject[index] && (
                    <Tooltip title={tooltipMessage} placement="top">
                      <div
                        className="round"
                        onClick={() => copyPassword(passObject[index])}
                      >
                        <VscCopy size={14} />
                      </div>
                    </Tooltip>
                  )}
                  <div
                    style={{
                      cursor: "pointer",
                      width: 200,
                      marginLeft: 16,
                    }}
                    id={docId}
                    onClick={() => {
                      docProp === "OTHER"
                        ? window.open(link, "_blank")
                        : downloadFile(
                            docObject[index],
                            docName === "PREFILLED JSON" ? "json" : "pdf"
                          );
                    }}
                  >
                    {getFileNameForButton(
                      docObject[index],
                      docName === "PREFILLED JSON" ? "json" : "pdf",
                      fileNameFromLink(link)
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    } catch (error) {
      return (
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              cursor: "pointer",
              width: 200,
            }}
            onClick={() => {
              docProp === "OTHER"
                ? window.open(link, "_blank")
                : downloadFile(
                    link,
                    docName === "PREFILLED JSON" ? "json" : "pdf",
                    fileNameFromLink(link)
                  );
            }}
          >
            {getFileNameForButton(link)}
          </div>
          {password && (
            <Tooltip title={tooltipMessage} placement="top">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => copyPassword(password)}
              >
                <VscCopy size={14} />
              </div>
            </Tooltip>
          )}
        </div>
      );
    }
  };

  const fileNameFromLink = (link) => {
    try {
      const fileName = link && link.split(".");
      const length = fileName.length;
      const newFile =
        fileName[length - 2].split("/")[1] + "." + fileName[length - 1];
      return newFile.toLowerCase();
    } catch (error) {
      return "";
    }
  };

  const getFileNameForButton = (link) => {
    const fileName = fileNameFromLink(link);
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "underline",
        }}
      >
        {fileName.slice(0, 16) + "..."}
      </span>
    );
  };

  const copyPassword = (password) => {
    navigator.clipboard.writeText(password);
    setTooltipMessage("Password copied!");
    setTimeout(() => {
      setTooltipMessage("Copy Password");
    }, 2000);
  };

  return renderDocs();
};

export default AddedFile;
