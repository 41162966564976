import styled from "styled-components";
import { dim } from "../../../styles/theme";

export const ArticleViewWrapper = styled.div`
  position: absolute;
  top: ${dim._80px};
  width: 80%;
  height: 80%;
  padding: ${dim._0px} ${dim._72px} ${dim._50px} ${dim._72px};
  box-sizing: border-box;
  background-color: white;
  border-radius: ${dim._14px};
  white-space: pre-wrap;

  .close {
    cursor: pointer;
    border: ${dim._2px} solid #05387e;
    border-radius: ${dim._6px};
    margin-left: ${dim._10px};
    padding: ${dim._6px};
    border-radius: ${dim._14px};
  }

  .SwitchAndClose {
    display: flex;
    align-items: center;
  }

  .articleDetails {
    display: flex;
    gap: ${dim._10px};
    width: 100%;
    height: auto;
    padding-bottom: ${dim._28px};
    border-bottom: 1px solid #bbbbbb;
  }

  .main-wrapper {
    width: 100%;
    display: flex;
  }

  .articleWrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow: scroll;
    height: fit-content;
  }

  .side-wrapper-left {
    width: 100%;
  }

  .side-wrapper-right {
    margin-left: ${dim._36px};
    margin-top: ${dim._8px};
  }

  .card-wrapper {
    margin-bottom: ${dim._24px};
  }

  .articleBanner {
    width: 100%;
    max-height: ${dim._scale(280)};
    border-radius: ${dim._8px};
  }

  h1 {
    margin: ${dim._40px} ${dim._0px} ${dim._10px} ${dim._0px};
    padding: ${dim._0px};
    font-style: normal;
    font-weight: bold;
    font-size: ${dim._32px};
    line-height: ${dim._44px};
    color: #212121;
    white-space: pre-wrap;
  }

  .articleInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    white-space: pre-wrap;
    p {
      margin: ${dim._0px};
      padding: ${dim._0px};
      font-style: normal;
      font-weight: bold;
      font-size: ${dim._16px};
      line-height: ${dim._20px};
      color: #7f7f7f;
      white-space: pre-wrap;
    }
  }

  .blogBrief {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin: ${dim._20px} ${dim._0px} ${dim._0px} ${dim._0px};
    padding: ${dim._20px};
    border-radius: ${dim._8px};
    background: rgba(11, 106, 176, 0.1);
    box-sizing: border-box;

    h2 {
      margin: ${dim._0px} ${dim._0px} ${dim._20px} ${dim._0px};
      padding: 0 0;
      font-style: normal;
      font-weight: 600;
      font-size: ${dim._20px};
      line-height: ${dim._22px};
      color: #212121;
    }

    p {
      margin: ${dim._0px};
      padding: ${dim._0px} ${dim._0px};
      font-style: normal;
      font-weight: normal;
      font-size: ${dim._16px};
      color: #212121;
    }
  }

  .tags {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: ${dim._10px} ${dim._0px};
  }

  .tags div {
    margin: ${dim._0px} ${dim._10px} ${dim._0px} ${dim._0px};
    padding: ${dim._8px} ${dim._14px};
    font-style: normal;
    font-weight: 400;
    font-size: ${dim._14px};
    color: #646464;
    line-height: ${dim._12px};
    background: #e4e4e4;
    box-sizing: border-box;
    border-radius: ${dim._16px};
  }

  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: ${dim._0px};
    margin-inline-end: ${dim._0px};
    padding-inline-start: ${dim._40px};
  }

  .articleInfoShare,
  .shareWrapper,
  .logo {
    display: flex;
    align-items: center;
  }

  .logo {
    gap: ${dim._8px};
    align-items: center;
  }

  .shareBtn {
    display: flex;
    flex-direction: column;
    gap: ${dim._8px};
  }

  .icons {
    display: flex;
    gap: ${dim._32px};
  }

  .articleDesc {
    text-overflow: ellipsis;
  }

  .info {
    margin-top: ${dim._16px};
    font-size: ${dim._14px};
    color: #555;
    .author,
    .published,
    .reading-time {
      font-weight: bold;
      color: #333;
    }
  }

  p {
    margin-bottom: ${dim._10px};
    line-height: 1.6;
    background: none !important;
  }

  .body-content {
    display: inline-block;
    width: 100%;
    white-space: pre-wrap;
    width: 100%;
    overflow-wrap: break-word;
    text-overflow: clip;
    white-space: pre-wrap;
  }

  .body-content img {
    max-width: 90%;
  }

  .container {
    width: 100%;
  }

  .tags-wrapper {
    margin-top: ${dim._24px};
    display: flex;
    gap: ${dim._10px};
    width: 100%;
    flex-wrap: wrap;
    .tag-name {
      white-space: pre-wrap;
      background-color: #f1f3f6;
      border-radius: ${dim._4px};
      padding: ${dim._6px} ${dim._16px};
      color: #2e4254;
      font-weight: 500;
      margin-right: ${dim._8px};
      font-size: ${dim._12px};
      line-height: ${dim._20px};
      white-space: nowrap;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: ${dim._12px};
    white-space: pre-wrap;
  }

  .publish-btn {
    padding: ${dim._10px} ${dim._20px} ${dim._10px} ${dim._20px};
    border-radius: ${dim._8px};
    border: none;
    opacity: ${dim._0px};
    background-color: #05387e;
    color: white;
    cursor: pointer;
  }

  .cancel-btn {
    padding: ${dim._10px} ${dim._20px} ${dim._10px} ${dim._20px};
    border-radius: ${dim._8px};
    border: none;
    opacity: ${dim._0px};
    background-color: white;
    color: #eb5146;
    cursor: pointer;
  }

  .buttons {
    padding: ${dim._30px};
    position: absolute;
    bottom: ${dim._0px};
    right: ${dim._0px};
  }

  .preview {
    font-size: ${dim._20px};
    font-weight: 600;
    line-height: ${dim._24px};
    text-align: left;
    color: #05387e;
  }

  .preview-text {
    padding: ${dim._24px};
    border-bottom: 1px solid #e3e8ef;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5%;
  }

  .MuiSwitch-thumb {
    color: #05387e;
  }
`;
