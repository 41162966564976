import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IncomeTableStyle } from "./IncomeTable.styled";
import Row from "./Row";
import TableHead from "./TableHead";
import SuperSaverIncomeService from "../../../../Services/SuperSaverIncomeService";
import { modifyDIffrentIncomeDataFn } from "../../utils/modifyDIffrentIncomeData";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";

const IncomeTable = ({
    capitalGainData,
    title,
    tableTitle,
    path,
    lastUpdatedOn,
    col_a,
    col_b,
    total,
    componentName,
    userId,
}) => {
    const navigate = useNavigate();
    const [componentsList, setComponentsList] = useState();
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    useEffect(() => {
        (async () => {
            const capitalGainData =
                await SuperSaverIncomeService.getIncomeRevampData(
                    componentName,
                    userId,
                    fyId
                );
            const arr = [...capitalGainData.data.income_details]
                .sort((a, b) => {
                    const timestampA = Math.max(a.created_at, a.updated_at);
                    const timestampB = Math.max(b.created_at, b.updated_at);
                    return timestampA - timestampB;
                })
                .reverse();

            const modifiedArr = arr.map((d, i) => {
                return modifyDIffrentIncomeDataFn(componentName, d);
            });
            setComponentsList(modifiedArr);
        })();
    }, [fyId]);

    const handleSeeDetails = () => {
        navigate(path, {
            state: {
                component: componentsList,
                table: {},
                incomeType: componentName,
                userId: userId,
            },
        });
    };
    return (
        <IncomeTableStyle>
            <div className="tableTitle">
                <p>{title.replaceAll("_", " ")}</p>
                <div className="radioBtns">
                    <p className="details" onClick={handleSeeDetails}>
                        Add/See details
                    </p>
                </div>
            </div>
            <p className="updatedDate">Last Updated on {lastUpdatedOn}</p>
            <TableHead tableTitle={tableTitle} />
            <Row
                capitalGainData={capitalGainData}
                tableTitle={tableTitle}
                col_a={col_a}
                col_b={col_b}
                total={total}
            />
        </IncomeTableStyle>
    );
};

export default IncomeTable;
