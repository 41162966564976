import styled from "styled-components";

export const SidebarStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 16%;
  height: calc(100vh - 93px);
  margin: 0px 0px 0px 0px;
  padding: 20px 0;
  position: fixed;
  top: 93px;
  left: 0;
  background: white;
  z-index: 700;
  box-sizing: border-box;

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    height: 36px;
    margin: 0 20px 10px 0;
    padding: 0 0 0 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #7f7f7f;
    cursor: pointer;
    box-sizing: border-box;
  }

  a:hover,
  .active {
    background: rgba(11, 106, 176, 0.16);
    border-radius: 0px 70px 70px 0px;
  }

  .active {
    background: rgba(11, 106, 176, 0.16);
    border-radius: 0px 70px 70px 0px;
    font-weight: 600;
    color: #0b6ab0;
  }
 
  .ant-menu-item-only-child:hover{
    background: rgba(11, 106, 176, 0.16) !important;
    border-radius: 0px 70px 70px 0px !important;
    color: #0b6ab0 !important;;
  }
`;


export const StyledArticleCategory = styled.div`
width: 100%;

.outer-container{
  width:90%;
   margin-left: 12px; 
}

.header-box{
  display:flex;
  gap: 10px;
  padding: 12px;
  max-width:100%;
  align-items: center;
  background-color: #CDF4DB;
  border-radius: 12px;
  color:#05387E;
  font-weight: 600;
}

.links-container{
  margin-left:38px;
  margin-top: 16px;
  border-left: 1px solid #E3E8EF
}


a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 92%;
    height: 44px;
    margin: 0 20px 10px 16px;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #05387E;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 600;
  }
  a:hover{
    color:white;
  }

a:hover,
  .active {
    background-color: rgba(21, 190, 83, 0.8);
    border-radius: 8px;
  }

.active {
    background: #15BE53;
    font-weight: 600;
    color: white;
    border-radius: 8px;
  }

`