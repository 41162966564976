import styled from "styled-components";

export const ArticlePreviewDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-sizing: border-box;

  .preview {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 0 20px;
    padding: 0px;
    box-sizing: border-box;
  }

  .preview h1 {
    margin: 0 0 10px 0;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 42px;
    color: #000000;
  }

  .preview p {
    margin: 0;
    padding: 0;
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 20px 0 0px 0;
    box-sizing: border-box;
  }

  .buttons p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 26px;
    margin: 0 10px 0 0px;
    padding: 0;
    background: #d8e7f2;
    border: 2px solid #d8e7f2;
    border-radius: 314px;
    color: #0e6ab0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
  }
`;
