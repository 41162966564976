import React from "react";
import { SuperSaverBtn } from "../../commonComponents/SuperSaverBtn";
import { PopUpForActivityUpdateStyled } from "../PopUpForActivityUpdate/PopUpForActivityUpdate.styled";

const PopUpForDocApproval = ({
  handleCross,
  name,
  handleClick,
  actionName,
  msg,
  btn,
  handleClick2,
}) => {
  return (
    <PopUpForActivityUpdateStyled checked={"checked"}>
      <span className="closePop" onClick={handleCross}>
        &times;
      </span>
      <div className="popContent">
        {name && (
          <b>
            Do you want to {actionName} this {name} Document ?{" "}
          </b>
        )}
        {msg && <b>{msg}</b>}
        {}
        <div className="input">
          <input type="checkbox" name="cb" id="cb" className="cb" />
          {/* <label className="button"> */}
          {actionName && (
            <SuperSaverBtn
              onClick={() => {
                actionName === "APPROVE"
                  ? handleClick("APPROVED")
                  : handleClick("REJECTED");
              }}
            >
              {actionName && actionName}
              {btn && btn}
            </SuperSaverBtn>
          )}
          {btn && (
            <SuperSaverBtn
              onClick={(e) => {
                e.preventDefault();
                handleClick2();
              }}
            >
              {btn && btn}
            </SuperSaverBtn>
          )}
        </div>
      </div>{" "}
    </PopUpForActivityUpdateStyled>
  );
};

export default PopUpForDocApproval;
