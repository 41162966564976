import React, { useState } from "react";
import { Card, CardContent, Typography, Dialog, DialogTitle, DialogContent, IconButton, Avatar, Chip  } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NoteViewer from "../NoteViewer";
import { StyledCard, StyledNotesGrid } from "./StyledNotesGrid";
import { extractTextFromHtml } from "../../../../../../utils/textUtil";
import NoteIcon from '@mui/icons-material/Note';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { timeStampDate } from "../../../../../../utils/currentDate";
import { dim } from "../../../../../../styles/theme";
import { timeStampDateNewFormat } from "../../../../../../utils/epochConverter";

function NoteCard({ note, onClick ,advisorName,createdAt}) {

  return (
    <StyledCard>
    <Card
      onClick={onClick}
      style={{
        cursor: "pointer",
        marginBottom: `${dim._scale(16)}`,
        padding:`${dim._scale(16)}`,
        borderRadius:`${dim._scale(12)}`,
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        height:`250px`,
        position:'relative'
      }}
    >
      <CardContent>
        <div className="card-content-wrapper" >
          <Avatar style={{ backgroundColor: "#007bff", marginRight: "8px" }}>
            <StickyNote2Icon />
          </Avatar>
          <Typography
            variant="h6"
            component="div"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {note?.note_title}
          </Typography>
        </div>

        <Typography
          variant="body2"
          component="div"
          style={{
            marginBottom: "16px",
            fontSize: "14px",
            lineHeight: "1.5",
            color: "#555",
            display: "-webkit-box",
            WebkitLineClamp: 6,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {extractTextFromHtml(note?.note_content)}
        </Typography>

        <div
        
          className="card-bottom-info"
        >
          <div style={{ display: "flex", alignItems: "center"  }}>
            <Avatar
              alt={note?.advisorName}
              src="path/to/avatar3.jpg"
              style={{ marginRight: "8px" }}
            />

            <Typography variant="body2" style={{ 
                color: "#555",
                maxWidth:'100px',
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",

             }}>
              {advisorName}
            </Typography>
          </div>
          <Typography variant="body2" style={{ color: "#aaa" }}>
            {createdAt}
          </Typography>
        </div>
      </CardContent>
    </Card>
    </StyledCard>
  );
}

function NotesGrid({ notes }) {
  const [selectedNote, setSelectedNote] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedNote(null);
  };
  const date = timeStampDateNewFormat(selectedNote?.created_at);
  return (
    <StyledNotesGrid>
    <div className="notesSection">
      <div className="notes-container">
        {notes?.map((item, index) => (
          <NoteCard
            key={index}
            note={item}
            advisorName={item?.advisor_name}
            createdAt={timeStampDateNewFormat(item?.created_at)}
            onClick={() => handleNoteClick(item)}
          />
        ))}
      </div>

      {selectedNote && (
        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          maxWidth="md"
          fullWidth
          style={{ marginTop: "60px"}}
        >
          <DialogTitle>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleModalClose}
              aria-label="close"
              style={{ position: "absolute", right: 15, top: 5}}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent 
          className="individual-note"
          style={{height:'80vh' ,padding:'0px',marginTop:'20px'}}
          >
            <NoteViewer
              header={selectedNote?.note_title}
              content={selectedNote?.note_content}
              uploadedFiles={selectedNote?.documents}
              createdAt={date}
              advisorName={selectedNote?.advisor_name}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
    </StyledNotesGrid>
  );
}

export default NotesGrid;
