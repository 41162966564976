import { httpClient } from "./httpUtil";

export const getMetaData = () => {
  return httpClient.get(
    `/appointments/v1/call-summary/meta-data`,
    null,
    null,
    false
  );
};
