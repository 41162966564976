import styled from 'styled-components';

export const TableHeadingStyle = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	min-height: auto;
	height: auto;
	margin: 0 0 0px 0;
	padding: 10px 20px;
	background: #e5e5e5;
	border-radius: 4px 4px 0 0;
	box-sizing: border-box;

	div {
		width: ${({ width }) => width};
		font-size: 12px;
		font-weight: 600;
		color: #212f45;
		text-transform: uppercase;
	}
`;
