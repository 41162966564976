import { getAuth } from "firebase/auth";
import React from "react";
import { useState } from "react";
import { updateActivity } from "../../Services/SuperSaver";

import {
  Back,
  Front,
  PopUpForActivityUpdateStyled,
} from "./PopUpForActivityUpdate.styled";

const PopUpForActivityUpdate = ({
  data,
  user_id,
  consultationArr,
  index,
  handleUpdate,
  handleCross,
}) => {
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(data.status);
  const handleBtn = (value) => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          updateActivity(data, value);
        });
      }
    });
  };
  return (
    <PopUpForActivityUpdateStyled checked={checked}>
      <span className="closePop" onClick={handleCross}>
        &times;
      </span>
      <div className="popContent">
        <b>Do you want to Update {data.name} Activity ? </b>
        <div className="input">
          <input type="checkbox" name="cb" id="cb" className="cb" />
          <label className="button">
            <Front
              className="front"
              onClick={() => {
                setChecked(true);
                handleBtn({
                  user_id: user_id,
                  activity_id: data.activity_id,
                  status:
                    status !== "IN_PROGRESS" ? "IN_PROGRESS" : "COMPLETED",
                });
                let arr = consultationArr;
                status !== "IN_PROGRESS" &&
                  arr.splice(index, 1, {
                    ...data,
                    status:
                      status !== "IN_PROGRESS" ? "IN_PROGRESS" : "COMPLETED",
                    start_date: new Date().getTime(),
                  });
                status === "IN_PROGRESS" &&
                  arr.splice(index, 1, {
                    ...data,
                    status: "COMPLETED",
                    completion_date: new Date().getTime(),
                  });
                handleUpdate(
                  arr
                    .map((d, i) => {
                      if (d.status === "NOT_INITIATED") {
                        return { ...d, color: "", rank: "3" };
                      } else if (d.status === "IN_PROGRESS") {
                        return {
                          ...d,
                          color: "#e1e15d",
                          rank: "2",
                          content: "Activity Started on",
                        };
                      } else {
                        return {
                          ...d,
                          color: "#15BE53",
                          rank: "1",
                          content: "Activity completed on",
                        };
                      }
                    })
                    .sort((a, b) => a.rank - b.rank)
                );
                setTimeout(() => {
                  handleCross();
                }, 1000);
              }}
            >
              {status === "IN_PROGRESS" && "complete"}
              {status === "NOT_INITIATED" && "start"}
            </Front>
            <Back
              className="back"
              onClick={() => {
                setChecked(false);
              }}
            >
              Done{" "}
            </Back>
          </label>
        </div>
      </div>{" "}
    </PopUpForActivityUpdateStyled>
  );
};

export default PopUpForActivityUpdate;
