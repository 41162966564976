export const userActions = {
    ADD_ASSIGNED_USERS: "ADD_ASSIGNED_USERS",
};

const initialState = {
    users: [],
};

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActions.ADD_ASSIGNED_USERS:
            return {
                ...state,
                users: action.payload && action.payload,
            };

        default:
            return state;
    }
};
