const defaultData = [
	{
		external_id: '',
		label: '',
		sub_type: 'INTRADAY',
		amount: 0,
		start_date: new Date().getTime(),
		end_date: new Date().getTime(),
		expenditure: 0,
		turnover: 0,
		created_at: new Date().getTime(),
		updated_at: new Date().getTime()
	},
	{
		external_id: '',
		label: '',
		sub_type: 'BUSINESS',
		amount: 0,
		start_date: new Date().getTime(),
		end_date: new Date().getTime(),
		expenditure: 0,
		turnover: 0,
		created_at: new Date().getTime(),
		updated_at: new Date().getTime()
	},
	{
		external_id: '',
		label: '',
		sub_type: 'CONSULTANT',
		amount: 0,
		start_date: new Date().getTime(),
		end_date: new Date().getTime(),
		expenditure: 0,
		turnover: 0,
		created_at: new Date().getTime(),
		updated_at: new Date().getTime()
	}
];

export const getSummarizedData = (data) => {
	const businessIncomeData =
		data &&
		data.reduce((acc, current) => {
			const existingSubTypeIndex = acc.findIndex(
				(item) => item.sub_type === current.sub_type
			);
			if (existingSubTypeIndex !== -1) {
				acc[existingSubTypeIndex].amount += current.amount;
				acc[existingSubTypeIndex].expenditure += current.expenditure;
				acc[existingSubTypeIndex].turnover += current.turnover;
			} else {
				acc.push(current);
			}
			return acc;
		}, []);
	return businessIncomeData;
};

export const initializeDefaultData = (businessIncome) => {
	const data = defaultData.map((it) => {
		const obj =
			businessIncome &&
			businessIncome.find((ele) => ele.sub_type === it.sub_type);
		return obj !== undefined ? obj : it;
	});
	return data;
};
