import React, { useEffect, useState } from "react";
import { ITRFilingUsersContainer } from "./ITRFilingUsers.styled";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { getAuth } from "firebase/auth";
import DataGridCustomTooltip from "../../commonComponents/DataGridCustomTooltip";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import Button from "../../commonComponents/Button";
import ITRServices from "../../Services/ITRServices";
import { getSlashDateFormat } from "../../utils/timeUtil";
import LoaderPage from "../../commonComponents/LoaderPage";
import toast from "react-hot-toast";

const tabList = [
  {
    label: "Ready To file",
    value: "READY_TO_FILE",
  },
  {
    label: "Document under Verification",
    value: "DOCUMENT_UNDER_VERIFICATION",
  },
  {
    label: "Additional Document Request",
    value: "DOCUMENT_REQUESTED",
  },
  {
    label: "ITR Filed",
    value: "ITR_FILED",
  },
];

const ITRFilingUsers = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const selectedView = localStorage.getItem(
    LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE
  );

  const userData = JSON.parse(localStorage.getItem("currentUser"));

  const [value, setValue] = useState(
    (tabList.some((d) => d.value === selectedView) && selectedView) ||
      "READY_TO_FILE"
  );

  const handleChange = (event, newValue) => {
    localStorage.setItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE, newValue);
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      const itrUsersRes = await ITRServices.getITRFilingUsers(value);
      const itrUsers = itrUsersRes?.data?.users_by_itr_status;
      if (userData?.user_type === "ADVISOR") {
        const advisorsItrUsers = itrUsers?.filter(
          (user) =>
            user?.advisor_details?.external_id === userData?.user_roll_id
        );
        setData(advisorsItrUsers);
      } else {
        setData(itrUsers);
      }
    } catch (error) {
      toast.error("Error fetching ITR filing users");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, [value]);

  const dataByStatus = (status) => {
    switch (status) {
      case "READY_TO_FILE":
        return {
          field: "document_verification_date",
          headerName: "Assigned Date",
          flex: 1,
          renderCell: (params) => (
            <DataGridCustomTooltip dataVal={params?.value} />
          ),
        };
      case "DOCUMENT_UNDER_VERIFICATION":
        return {
          field: "document_uploaded_at",
          headerName: "Uploaded Date",
          flex: 1,
          renderCell: (params) => (
            <DataGridCustomTooltip dataVal={params?.value} />
          ),
        };
      case "DOCUMENT_REQUESTED":
        return {
          field: "document_requested_at",
          headerName: "Requested Date",
          flex: 1,
          renderCell: (params) => (
            <DataGridCustomTooltip dataVal={params?.value} />
          ),
        };
      case "ITR_FILED":
        return {
          field: "itr_filing_date",
          headerName: "ITR Filed Date",
          flex: 1,
          renderCell: (params) => (
            <DataGridCustomTooltip dataVal={params?.value} />
          ),
        };
      default:
        return;
    }
  };

  const dateColumn = dataByStatus(value);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (
        <DataGridCustomTooltip dataVal={params?.value} toTrim={true} />
      ),
    },
    {
      field: "plan_name",
      headerName: "Plan",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "advisor_name",
      headerName: "Advisor Name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    { ...dateColumn },
  ];

  const handleOnCellClick = (params) => {
    const user_id = params?.row?.user_id;
    const name = params?.row?.name;

    if (userData.user_type === "ADVISOR") {
      navigate(`/userProfile-for-advisor/itr-filing?user_id=${user_id}`, {
        state: { user_id, name },
      });
    } else {
      navigate(`/super-saver-user-profile/itr-filing/${user_id}`, {
        state: { user_id, name },
      });
    }
  };

  function rowFunction(value, index, array) {
    return {
      id: index,
      user_id: value?.user_details?.id,
      name: value?.user_details?.name,
      email: value?.user_details?.email,
      plan_name: value?.plan_name,
      mobile: value?.mobile,
      advisor_name: value?.advisor_details?.first_name,
      itr_filing_date: getSlashDateFormat(value?.itr_filing_date),
      document_verification_date: getSlashDateFormat(
        value?.document_verification_date
      ),
      document_uploaded_at: getSlashDateFormat(value?.document_uploaded_at),
      document_requested_at: getSlashDateFormat(value?.document_requested_at),
    };
  }
  return loader ? (
    <LoaderPage />
  ) : (
    <ITRFilingUsersContainer>
      <TabContext value={value} variant="fullWidth">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabList.map((tabs, i) => (
              <Tab
                key={i}
                label={tabs.label}
                value={tabs.value}
                // sx={{ width: 200 }}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel value={value} sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={columns}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/itr-filing-users/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
      </TabContext>
    </ITRFilingUsersContainer>
  );
};

export default ITRFilingUsers;
