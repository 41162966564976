import { LOCAL_DB_KEYS } from "../constants/localStorage";
import BASE_URL from "./API_ENDPOINT";
import { getAuthToken, getHeaders } from "./SuperSaverClientChat";
import { httpClient } from "./httpUtil";

export const getSuperSaver = async (idToken) => {
  const url = `${BASE_URL}super-saver-bookings/v1/all`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      Source: "cms",
    },
  });
  const data = await response.json();
  return data;
};

export const getSuperSaverUser = async (idToken, call_status, filter_by) => {
  let url;
  if (
    call_status === "ACTION_ITEM_PENDING" &&
    filter_by &&
    filter_by !== "Select Action"
  ) {
    url = `${BASE_URL}super-saver-user/v1/all?call_status=${call_status}&filter_by=${filter_by}`;
  } else if (call_status) {
    url = `${BASE_URL}super-saver-user/v1/all?call_status=${call_status}`;
  } else {
    url = `${BASE_URL}super-saver-user/v1/all`;
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      Source: "cms",
    },
  });
  const data = await response.json();
  return data;
};

export const getadvisor = async () => {
  const url = `${BASE_URL}advisors/v1/all/cms`;
  const response = await fetch(url, {
    method: "GET",
    headers: await getHeaders(),
  });
  const data = await response.json();
  return data;
};

export const getUserDocument = async (idToken, user_id) => {
  const url = `${BASE_URL}user-deduction/v1/internal?userId=${user_id}&financialYearId=${localStorage.getItem(
    "FINANCIAL_YEAR_ID"
  )}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      userId: `${user_id}`,
      Source: "cms",
    },
  });
  const data = await response.json();
  return data;
};

export const getUserIncome = async (user_id) => {
  const url = `${BASE_URL}incomes/v1/all/internal?userId=${user_id}&financialYearId=${localStorage.getItem(
    "FINANCIAL_YEAR_ID"
  )}`;
  return httpClient.get(
    url,
    null,
    null,
    true
  );
};

export const assignAdvisor = async (idToken, req) => {
  const url = `${BASE_URL}advisors/v1/create-expert`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      Source: "cms",
    },
    body: JSON.stringify(req),
  });
  const data = await response.json();
  return data;
};

export const updatePayment = async (data) => {
  const url = `${BASE_URL}super-saver-user/v1/payment`;
  const response = await fetch(url, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
      Source: "cms",
    },
    body: JSON.stringify(data),
  });
  return await response.json();
};
export const updatePaymentNew = async (data, userId) => {

  const res= await httpClient.post(`payment_gateway/payment/${userId}/internal`,data)


  return await res?.data;
};
export const updateActivity = async (idToken, data) => {
  const url = `${BASE_URL}super-saver-user/v1`;
  const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
  const response = await fetch(url, {
    method: "PUT",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      Source: "cms",
    },
    body: JSON.stringify({ ...data, financial_year_id: fyId }),
  });
  return await response.json();
};
export const inviteUser = async (idToken, user_id) => {
  const url = `${BASE_URL}super-saver-user/v1/invite?userId=${user_id}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      Source: "cms",
    },
  });
  return response;
};
export const getFinancialYearId = async () => {
  const url = `${BASE_URL}financial-years/v1/all`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Source: "cms",
    },
  });
  return response.json();
};

export const getUserActivity = async (token, userId) => {
  let res = await fetch(
    BASE_URL + `super-saver-user/v1/activities?userId=${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        userId: userId,
        Source: "cms",
      },
    }
  );

  return res.json();
};
export const getTargetTax = async (userId) => {
  let res = await fetch(
    BASE_URL +
      `user-tax-report/v1?userId=${userId}&financialYearId=${localStorage.getItem(
        "FINANCIAL_YEAR_ID"
      )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
        userId: userId,
        Source: "cms",
      },
    }
  );

  return res.json();
};

export const updateTargetTax = async (idToken, req) => {
  const url = `${BASE_URL}super-saver-user/v1/targeted-tax`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      Source: "cms",
    },
    body: JSON.stringify(req),
  });
  return response;
};

export const getOnboardingFormData = async (idToken, user_id) => {
  const url = `${BASE_URL}survey-question/v1/all/internal?type=SUPER_SAVER&userId=${user_id}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      Source: "cms",
    },
  });
  return response.json();
};

export const SuperSaverDashboardService = {
  getFinancialYearId: () => {
    return httpClient.get("financial-years/v1/all", {}, {}, false);
  },
  getUsersActivityData: (user_id) => {
    return httpClient.get(
      `super-saver-user/v1/activities?userId=${user_id}&financial_year_id=${localStorage.getItem(
        "FINANCIAL_YEAR_ID"
      )}`,
      {},
      { userId: user_id }
    );
  },
  getTaxDetails: (user_id) => {
    return httpClient.get(
      `incomes/v1/tax?financial_year_id=${localStorage.getItem(
        "FINANCIAL_YEAR_ID"
      )}`,
      {},
      { userId: user_id }
    );
  },
  getDataForIntelligence: async (user_id) => {
    const fyi = localStorage.getItem("FINANCIAL_YEAR_ID");
    const data = await httpClient.get(
      `/user-knowledge-base/v1?&financial_year_id=${fyi}`,
      {},
      { userId: user_id }
    );
    return data;
  },
};
