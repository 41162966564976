import styled from "styled-components";

export const StyledPayslipManualReview = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  height: auto;
  margin: 0;
  padding: 0 0px 0 0;
  box-sizing: border-box;
  .resultS {
    width: 100%;
    box-sizing: border-box;
  }

  .MuiIconButton-sizeSmall {
    width: 40px !important;
    height: 40px !important;
  }
  .css-1s0hp0k-MuiDataGrid-columnHeadersInner {
    font-size: large;
    font-weight: 500;
  }
`;
