import React from 'react';
import VerifiedChip from '../../../../../commonComponents/VerifiedChip';
import {StyledMobileAuthorOrVerified} from './StyledMobileAuthorOrVerified';

const EXPERT = 'EXPERT';
function MobileAuthorOrProsperr({authorCategory, isVerified = false}) {
	return (
		<StyledMobileAuthorOrVerified>
			<div>
				{authorCategory == EXPERT ? (
					<div className='expert'>
						<p className='op'>O P Yadav</p>
						<VerifiedChip />
					</div>
				) : (
					<div className='staff'>
						<p className='op'>Prosperr Team</p>
						{isVerified && authorCategory !== 'EXPERT' && <VerifiedChip />}
					</div>
				)}
			</div>
		</StyledMobileAuthorOrVerified>
	);
}

export default MobileAuthorOrProsperr;
