import React from 'react';
import styled from 'styled-components';

const OutlinedCheckbox = ({
	value = false,
	disabled = false,
	onChange = () => {},
	style = {}
}) => {
	return (
		<Wrapper style={style}>
			<input
				disabled={disabled}
				type='checkbox'
				className='checkbox'
				checked={value}
				onChange={onChange}
			></input>
		</Wrapper>
	);
};

export default OutlinedCheckbox;

const Wrapper = styled.div`
	input[type='checkbox'] {
		appearance: none;
		background-color: #fff;
		margin: 0;
		font: inherit;
		color: #15be53;
		width: 16px;
		height: 16px;
		border: 1px solid #898989;
		border-radius: 1px;
		transform: translateY(-0.075em);
		display: grid;
		place-content: center;
		cursor: pointer;
	}

	input[type='checkbox']::before {
		content: '';
		width: 0.65em;
		height: 0.65em;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 0.5em 0.5em #15be53;
		transform-origin: bottom left;
		clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	}

	input[type='checkbox']:checked {
		border: 1px solid #15be53;
	}

	input[type='checkbox']:checked::before {
		transform: scale(1);
	}
`;
