import React from "react";
// import { theme } from '../../styles/theme';
import {
  ConfiramtionPopupStyle,
  ConfirmationFotter,
  ConfirmationHeader,
  ConfirmationContent,
  CancelButtonStyle,
  OkayButtonStyle,
  ConfiramtionPopupTitle,
} from "./style";
import { theme } from "../../../../styles/theme";
import { CONSULTATION_STATUS } from "../../constant";

const confirmation_content = {
  title: "Call Status Confirmation",
  context: "Please confirm the status of the call.",
  falseText: " Mark as Not Attended",
  trueText: "Mark as Attended",
};

const ConfiramtionPopup = ({
  handleSubmit,
  handleCancelBtn,
  handleNoBtn,
  confirmationContent = confirmation_content,
}) => {
  const { title, context, falseText, trueText } = confirmationContent;
  return (
    <ConfiramtionPopupStyle>
      <ConfirmationHeader>
        <ConfiramtionPopupTitle>{title}</ConfiramtionPopupTitle>
        <div
          style={{ color: theme.color.grey_10, cursor: "pointer" }}
          onClick={() => handleCancelBtn()}
        >
          x
        </div>
      </ConfirmationHeader>
      <ConfirmationContent>{context}</ConfirmationContent>
      <ConfirmationFotter>
        <CancelButtonStyle onClick={() => handleNoBtn()}>
          {falseText}
        </CancelButtonStyle>
        <OkayButtonStyle onClick={() => handleSubmit()}>
          {trueText}
        </OkayButtonStyle>
      </ConfirmationFotter>
    </ConfiramtionPopupStyle>
  );
};

export default ConfiramtionPopup;
