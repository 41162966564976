import { httpClient } from "./httpUtil";

const AdvisorNotesService = {
    saveNote: (advisor_id, data) => {
        return httpClient.post(
          `appointments/v1/call-summary/${advisor_id}/internal`,            
          data,{},{},true
        );
      },
    getNotesByUserId: (user_id) => {
        return httpClient.get(`appointments/v1/user/${user_id}`,{}, {},true);
    },
    getNotesByUserIdAndCallBookingId: (user_id,call_booking_id) => {
      return httpClient.get(`appointments/v1/user/${user_id}?call_booking_id=${call_booking_id}`,{}, {},true);
  },
  getDocumentFromS3 : (document_identifier , headers)=>{
    return httpClient.get(`/user-document/get-presigned-s3-url`, {
			document_identifier
		},headers);
  }
}

export {AdvisorNotesService};