import toast from "react-hot-toast";
import { ConsultationMomService } from "../../Services/ConsultationMomService";
import { theme } from "../../styles/theme";
import { hexToRgba } from "../../utils/hexToRgba";
// import { hexToRgba } from '../../utils/hexToRgba';
import { actions } from "./store/ConsultationAction";
import { CONSULTATION_STATUS } from "./constant";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";

const handleCallConfirmation = (dispatch, data) => {
  dispatch({
    type: actions.HANDLE_CONSULTATION_STATUS,
    data,
  });
};

const handleActivateCancelPopUp = (dispatch, data, index) => {
  const { upcoming_calls } = data;
  const newUpcomingCalls = upcoming_calls.map((call, callIndex) => {
    return {
      ...call,
      isCancelPopUpActive:
        callIndex === index ? !call.isCancelPopUpActive : false,
    };
  });
  dispatch({
    type: actions.SET_CONSULTATION_DATA,
    data: { ...data, upcoming_calls: newUpcomingCalls },
  });
};
const handleViewDetails = (dispatch, data, PreviousSessionsIndex, index) => {
  const { previous_calls } = data;

  const newPreviousCalls = previous_calls.map((callListData, callListIndex) => {
    const { call_list: callList } = callListData;
    const newCallList = callList.map((call, callIndex) => {
      return {
        ...call,
        isDetailsViewPopUpActive:
          PreviousSessionsIndex === callListIndex && callIndex === index
            ? !call.isDetailsViewPopUpActive
            : false,
      };
    });
    return { ...callListData, call_list: newCallList };
    // return {
    // 	...call,
    // 	isCancelPopUpActive:
    // 		callIndex === index ? !call.isCancelPopUpActive : false
    // };
  });
  dispatch({
    type: actions.SET_CONSULTATION_DATA,
    data: { ...data, previous_calls: newPreviousCalls, new: "2" },
  });
};

const handleSendMom = (navigate, queryString) => {
  const url = "/consultation-experience/consultation-form";
  navigate({
    pathname: url,
    search: queryString,
  });
};

function extractFirstValueBetweenColonAndLessThan(inputString) {
  // Regular expression to match the first value between ":" and "<"
  const regex = /:(.*?)(?=<)/;

  // Use regex to find the first match
  const match = regex.exec(inputString);

  if (match) {
    return match[1].trim(); // Extract and trim the value
  }

  return null; // Return null if no match is found
}

const handleTagForConsultation = (callDetails) => {
  const tag = [];
  if (
    callDetails.end_time > new Date().getTime() &&
    callDetails.status === CONSULTATION_STATUS.SCHEDULED
  ) {
    return tag.push({
      importance: "normal",
      title: "Advisor action required",
    });
  } else if (callDetails.status === CONSULTATION_STATUS.CANCELED) {
    tag.push({
      importance: "low",
      title: "Consultation Canceled",
    });
  } else if (callDetails.status === CONSULTATION_STATUS.NOT_COMPLETED) {
    tag.push({
      importance: "low",
      title: "Consultation Not Completed",
    });
  } else if (
    callDetails.call_summary_status === CONSULTATION_STATUS.SUBMITTED &&
    callDetails.status === CONSULTATION_STATUS.COMPLETED
  ) {
    tag.push({
      importance: "High",
      title: "Consultation Summary attached",
    });
    tag.push({
      importance: "low",
      title: "Consultation Completed",
    });
  } else if (
    (callDetails.call_summary_status === CONSULTATION_STATUS.NOT_STARTED ||
      callDetails.call_summary_status === CONSULTATION_STATUS.PENDING) &&
    callDetails.status === CONSULTATION_STATUS.COMPLETED
  ) {
    tag.push({
      importance: "normal",
      title: "Advisor action required",
    });
    tag.push({
      importance: "High",
      title: "Consultation summary pending",
    });
    tag.push({
      importance: "low",
      title: "Consultation Completed",
    });
  } else if (
    (callDetails.call_summary_status === CONSULTATION_STATUS.NOT_STARTED ||
      callDetails.call_summary_status === CONSULTATION_STATUS.PENDING) &&
    callDetails.status === CONSULTATION_STATUS.SCHEDULED
  ) {
    tag.push({
      importance: "normal",
      title: "Advisor action required",
    });
  }
  if (callDetails.action_item_status === CONSULTATION_STATUS.PENDING) {
    tag.push({
      importance: "normal",
      title: "Actionable Pending",
    });
  }
  return tag;
};
const data = JSON.parse(localStorage.getItem("type"));
const userType = data?.user_type;
const modifyConsultationData = (data) => {
  const sessionArr = data.call_session;
  const upcoming_calls = [];
  const incoming_calls = [];
  const completed_calls = [];
  for (let index = 0; index < sessionArr.length; index++) {
    const element = sessionArr[index];
    const callDetails = element.call_details[0];

    if (
      callDetails.status === "SCHEDULED"
      // &&
      // callDetails.end_time >= new Date().getTime()
    ) {
      const modData = {
        ...callDetails,
        advisor_first_name: "Gayatri",
        advisor_last_name: "Joshi",
        call_session_id: element.call_session_id,
        isCTAAvailable: true,
        CTALabel: userType !== "OPERATION" ? "Confirm Call Status" : "",
        active_time: getDateDifference(callDetails.start_time),
        CTAAction: userType !== "OPERATION" && handleActivateCancelPopUp,
        isCancelPopUpActive: false,
        userName: extractFirstValueBetweenColonAndLessThan(callDetails.title),
        call_summary_id: callDetails.call_summary_id,
        call_booking_id: callDetails.call_booking_id,
        type: "General Query",
        action_items: [],
      };
      upcoming_calls.push(modData);
    } else if (
      callDetails.call_summary_status === "SUBMITTED" ||
      callDetails.status === "CANCELED" ||
      callDetails.status === "NOT_COMPLETED"
    ) {
      const call_list = [
        {
          ...callDetails,
          advisor_first_name: "Gayatri",
          advisor_last_name: "Joshi",
          call_session_id: element.call_session_id,
          isCTAAvailable: false,
          userName: extractFirstValueBetweenColonAndLessThan(callDetails.title),
          type: callDetails.type ? callDetails.type : "General Query",
          isCTAAvailable:
            callDetails.call_summary_status === CONSULTATION_STATUS.SUBMITTED &&
            callDetails.status === CONSULTATION_STATUS.COMPLETED,
          CTALabel: "View MOM",
          CTAAction: handleSendMom,
          call_summary_id: callDetails.call_summary_id,
          call_booking_id: callDetails.call_booking_id,
          isDetailsViewPopUpActive: false,
          session_id: element.call_session_id,
          session_title: callDetails.type,
          action_items: handleTagForConsultation(callDetails)
            ? handleTagForConsultation(callDetails)
            : [],
        },
      ];

      completed_calls.push(...call_list);
    } else if (
      (callDetails.call_summary_status === "NOT_STARTED" &&
        callDetails.status !== "CANCELED") ||
      (callDetails.call_summary_status === "PENDING" &&
        callDetails.status !== "CANCELED")
      //    ||
      // (callDetails.end_time < new Date().getTime() &&
      //   callDetails.status === "SCHEDULED")
    ) {
      const call_list = [
        {
          ...callDetails,

          call_session_id: element.call_session_id,

          type: callDetails.type ? callDetails.type : "General Query",
          isCTAAvailable: true,
          userName: extractFirstValueBetweenColonAndLessThan(callDetails.title),
          CTALabel:
            userType !== "OPERATION"
              ? callDetails.status === CONSULTATION_STATUS.SCHEDULED
                ? "Confirm Call Status"
                : "Send MOM"
              : "",
          call_summary_id: callDetails.call_summary_id,
          CTAAction:
            userType !== "OPERATION"
              ? callDetails.status === CONSULTATION_STATUS.SCHEDULED
                ? handleActivateCancelPopUp
                : handleSendMom
              : () => {},

          isDetailsViewPopUpActive: false,
          session_id: element.call_session_id,
          call_booking_id: callDetails.call_booking_id,
          session_title: callDetails.type,
          action_items: handleTagForConsultation(callDetails)
            ? handleTagForConsultation(callDetails)
            : [],
        },
      ];
      const modData = {
        session_id: element.call_session_id,
        session_title: callDetails.type
          ? callDetails.type
          : "Allowances Discussion",
        call_list,
      };
      incoming_calls.push(...call_list);
    }
  }
  return { upcoming_calls, completed_calls, incoming_calls };
};
function findUndefinedProperties(obj) {
  const undefinedProperties = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === undefined) {
        undefinedProperties[key] = undefined;
      } else if (
        (Array.isArray(value) || typeof value === "object") &&
        Object.keys(value).length === 0
      ) {
        undefinedProperties[key] = value;
      } else if (value.length === 0 && typeof value !== "object") {
        undefinedProperties[key] = undefined;
      } else if (typeof value === "object" && Object.keys(value).length !== 0) {
        const objArr = Object.keys(value);
        const arr = [];
        for (let index = 0; index < objArr.length; index++) {
          const element = objArr[index];
          if (value[element].length === 0) {
            arr.push(element);
          }
        }

        if (arr.length !== 0) {
          undefinedProperties[key] = undefined;
        }
      }
    }
  }

  return undefinedProperties;
}

function errorValidator(obj) {
  const errorArr = Object.keys(obj);
  if (errorArr.length > 0) {
    return {
      error: true,
      errorArr,
    };
  } else {
    return {
      error: false,
      errorArr,
    };
  }
}
const removeSpecialCharacter = (str) => str.slice(0, str.indexOf(":")).trim();

const handleSubmitMom = async (
  id,
  value,
  setIsEditDisabled,
  navigate,
  searchParams,
  dispatch,
  setLoader
) => {
  const toastId = toast.loading("Loading...");
  setLoader(true);
  try {
    const { data } = await ConsultationMomService.postConsultationMom(
      id,
      value
    );
    toast.dismiss(toastId);
    setIsEditDisabled(true);
    let call_summary_id = data?.call_summary_id;

    const user_id = searchParams.get("user_id");

    localStorage.setItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID, call_summary_id);
    dispatch({
      type: actions.SET_CONSULTATION_ID,
      data: call_summary_id,
    });
    dispatch({
      type: actions.SET_MOM_DATA,
      data: null,
    });
    dispatch({
      type: actions.GET_CONSULTATION_DATA,
      data: {
        userId: user_id,
      },
    });
    dispatch({
      type: actions.GET_MOM_DATA,
      data: {
        call_summary_id,
      },
    });
    toast.success("data saved");
    setLoader(false);
  } catch (error) {
    setLoader(false);
    toast.dismiss(toastId);
    toast.error(
      error && error.response && error.response.data
        ? error.response.data.message
        : "Oops Something went wrong"
    );
  }
};
function getTimeZoneFromTimestamp(timestamp) {
  // Create a Date object from the timestamp
  const date = new Date(timestamp);

  // Get the time zone abbreviation without the offset
  const timeZoneAbbreviation = date.toTimeString().match(/\(([^)]+)\)/)[1];

  return timeZoneAbbreviation;
}


export function getDateDifference(timestamp) {
	const date1 = new Date(timestamp);
	const date2 = new Date();
	const timeDifference = date2 - date1; // Time difference in milliseconds
	const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
	return seconds
}

export {
  modifyConsultationData,
  handleViewDetails,
  findUndefinedProperties,
  handleSubmitMom,
  errorValidator,
  getTimeZoneFromTimestamp,
  removeSpecialCharacter,
  handleCallConfirmation,
};
