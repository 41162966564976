import React from "react";
import { Input } from "./SocialMedia.style";

const SocialMedia = ({ setSocial_links, social_links }) => {
  const handleSocialLinks = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // setSocial_links(value);
    setSocial_links({ ...social_links, [name]: value });
  };

  const arrSocial = [
    {
      nameLable: "Facebook Handle",
      name: "facebook",
      value: social_links.facebook,
      // onChange:handleSocialLinks,
      pattern: "https://www.facebook.com/.+[a-zA-z0-9._%+-/=? @]",
    },
    {
      nameLable: "Linkedin Handle",
      name: "linkedin",
      value: social_links.linkedin,
      // onChange:handleSocialLinks,
      pattern: "https://www.linkedin.com/in/.+[a-zA-z0-9._%+-/ @]",
    },
    {
      nameLable: "Instagram Handle",
      name: "instagram",
      value: social_links.instagram,
      // onChange:handleSocialLinks(),
      pattern: "https://www.instagram.com/.+[a-zA-z0-9._%+-/=? @]",
    },
    {
      nameLable: "Twitter Handle",
      name: "twitter",
      value: social_links.twitter,
      // onChange:handleSocialLinks,
      pattern: "https://twitter.com/.+[a-zA-z0-9._%+-/=? @]",
    },
    {
      nameLable: "Youtube Handle",
      name: "youtube",
      value: social_links.youtube,
      // onChange:handleSocialLinks,
      pattern:
        "https://www.youtube.com/c/.+[a-zA-z0-9._%+-/ @]||https://www.youtube.com/channel/.+[a-zA-z0-9._%+-/ @]",
    },
    //     {nameLable:"Your Website",
    //     name:"personal website",
    // value:social_links.personal_website,
    // // onChange:handleSocialLinks,
    // pattern:"https?://.+"},
  ];

  return (
    <div className="">
      {arrSocial.map((d) => {
        return (
          <div className="label">
            <div className="side">{d.nameLable}:&nbsp;&nbsp;</div>
            <Input
              type="text"
              id="social_links"
              name={d.name}
              placeholder={`Your ${d.name}`}
              value={d.value}
              onChange={handleSocialLinks}
              pattern={d.pattern}
              autoComplete="off"
            />
          </div>
        );
      })}
    </div>
  );
};

export default SocialMedia;
