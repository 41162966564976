import {initializeApp} from "firebase/app";
import {getDatabase, ref, set, get, child} from "firebase/database";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {firebaseConfig} from "./config";
import toast from "react-hot-toast";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();

export const currentUser = auth.currentUser;

export function signup(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export const getTokenId = () => {
  getAuth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      // Send token to your backend via HTTPS
      // console.log("Kuchh v vvvv", idToken);
      // return idToken;
      // ...
    })
    .catch(function (error) {
      console.log("error agya hai", error);
    });
};

export const updateUser = (displayName, id) => {
  const db = getDatabase(app);

  // Create User data
  const user_data = {
    id,
    displayName,
  };

  return set(ref(db, "users/" + id), user_data);
};

export const getUser = (uId) => {
  // const db = getDatabase(app);
  const dbRef = ref(getDatabase(app));

  return get(child(dbRef, `users/${uId}`));
};

// Google SSO

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async (handleValueToBeSavedAtLogin) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    await handleValueToBeSavedAtLogin(res);
  } catch (error) {
    toast.error(error.message);
  }
};
