import styled from "styled-components";
import { dim } from "../../../../styles/theme";
// import { dim } from '../../../../../../../styles/theme';

const StyledReadPayslip = styled.div`
  margin: 0 0 ${dim._10px};
  .heading {
    margin: ${dim._10px} 0;
    box-sizing: border-box;
  }
  .addMoreBtn {
    margin: ${dim._10px} 0;
    box-sizing: border-box;
  }
  .loader {
    width: 100%;
    margin: ${dim._10px} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
`;

export { StyledReadPayslip };
