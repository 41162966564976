import React from "react";
import { StyledConsultationRatingModel } from "./ConsultationRatingModel.styled";
import FormErrorMsgHandler from "../../../../commonComponents/FormErrormsgHandler";
import { ERROR_MESSAGE } from "../../constant";

const ConsultationRatingModel = ({
  clientSatisfaction,
  handleSatisfactionChange,
  isError,
  componentCategory,
}) => {
  return (
    <StyledConsultationRatingModel>
      <div className="ratingSection">
        <div className="ratingTitle">Rate the client satisfaction</div>
        <div className="inputRangeSlider">
          <input
            type="range"
            name="rate"
            min="1"
            max="10"
            defaultValue={0}
            value={clientSatisfaction.rate}
            onChange={(e) => {
              handleSatisfactionChange(e);
            }}
            onClick={(e) => {
              handleSatisfactionChange(e);
            }}
          />
          <div className="range-indicator">
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
            <span>6</span>
            <span>7</span>
            <span>8</span>
            <span>9</span>
            <span>10</span>
          </div>
        </div>
      </div>
      {isError?.error && isError.errorArr.includes(componentCategory) && (
        <FormErrorMsgHandler style={{ bottom: "0px" }}>
          {" "}
          {ERROR_MESSAGE[componentCategory]}
        </FormErrorMsgHandler>
      )}
    </StyledConsultationRatingModel>
  );
};

export default ConsultationRatingModel;
