import React from 'react';
import { getIntroOfPage } from '../getIntroOfPage';
import { Tooltip } from './Tooltip.styled';

const CustomTooltip = ({ active, payload, label, activeRadioButton }) => {
	const tooltipType = () => {
		if (activeRadioButton === '1') {
			return (
				<Tooltip>
					<div className='labels'>
						<div className='indicator1'></div>
						<p className='label'>Verified:{payload[0].value}</p>
					</div>
				</Tooltip>
			);
		} else if (activeRadioButton === '2') {
			return (
				<Tooltip>
					<div className='labels'>
						<div className='indicator2'></div>
						<p className='label'>Unverified:{payload[0].value}</p>
					</div>
				</Tooltip>
			);
		} else {
			return (
				<Tooltip>
					<div className='labels'>
						<div className='indicator1'></div>
						<p className='label'>Verified:{payload[0].value}</p>
					</div>
					<div className='labels'>
						<div className='indicator2'></div>
						<p className='label'>Unverified:{payload[1].value}</p>
					</div>
				</Tooltip>
			);
		}
	};
	if (active && payload && payload.length) {
		return tooltipType();
	}
	return null;
};
export default CustomTooltip;
