import styled from "styled-components";
import {StyledFlexWrapper} from "../../container/ActionDashboard/components/ActionWidget/ActionWidget.styled";
import {dim, theme} from "../../styles/theme";

export const StyledWidget = styled(StyledFlexWrapper)`
  padding: ${dim._24px};
  gap: ${dim._16px};
  position: relative;
  background: ${({themeActive, currentSelected, themeInActive}) =>
    currentSelected ? themeActive : themeInActive};
  border-radius: ${dim._8px};
  color: ${theme.color.white_0};
  justify-content: center;
  cursor: pointer;
  .text {
    color: ${({currentSelected}) =>
      currentSelected ? theme.color.white_0 : theme.color.grey_130};
  }
  .squareTilted {
    width: ${dim._14px};
    height: ${dim._14px};
    position: absolute;
    left: 50%;
    bottom: -${dim._14px};
    transform: translate(-50%, -50%) rotate(45deg);
    background: ${({themeActive, currentSelected, themeInActive}) =>
      currentSelected ? themeActive : themeInActive};
  }
`;
