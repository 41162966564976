import styled from "styled-components";
import { dim } from "../../styles/theme";

const LayoutStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 84%;
    height: calc(100vh - ${dim._92px});
    padding :20px;
    margin: 93px 0 0px 16%;
    box-sizing: border-box;
  }
`;

export default LayoutStyle;
