export const defaultCurrentDate = () => {
	var currentDate = new Date();
	var yyyy = currentDate.getFullYear();
	var mm = currentDate.getMonth() + 1; // Months start at 0!
	var dd = currentDate.getDate();
	if (dd < 10) dd = `0${dd}`;
	if (mm < 10) mm = `0${mm}`;
	var date = `${yyyy}-${mm}-${dd}`;
	return date;
};

export const timeStampDate = (timeStamp) => {
	var date = new Date(timeStamp);
	var yyyy = date.getFullYear();
	var mm = date.getMonth() + 1; // Months start at 0!
	var dd = date.getDate();
	if (dd < 10) dd = `0${dd}`;
	if (mm < 10) mm = `0${mm}`;
	var newdate = `${yyyy}-${mm}-${dd}`;
	return newdate;
};
export const timeStampDateRevers = (timeStamp) => {
	var date = new Date(timeStamp);
	var yyyy = date.getFullYear();
	var mm = date.getMonth() + 1; // Months start at 0!
	var dd = date.getDate();
	if (dd < 10) dd = `0${dd}`;
	if (mm < 10) mm = `0${mm}`;
	var newdate = `${dd}-${mm}-${yyyy}`;
	return newdate;
};
export const dateStringFromTimeStamp = (timeStamp) => {
    var date = new Date(timeStamp);
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
};