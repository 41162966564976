import { getUserDocument, getUserIncome } from "../../Services/SuperSaver";
import { getAuthToken } from "../../Services/SuperSaverClientChat";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { actions } from "../AssignedUsers/components/UserProfileForAdvisor/store/action";

const handleGetIncome = (userId,dispatch) => {
    getUserIncome(userId).then((income) => {
      if (income.income_details.length === 0) {
        dispatch({
          type: actions.SET_INCOME,
          data: {},
        });
      } else {
        let newArr = income?.income_details?.map((d, i) => {
          if (d.type == "SALARIED") {
            return {
              ...d,
              rank: 1,
            };
          } else if (d.type === "CAPITAL_GAIN") {
            return {
              ...d,
              rank: 2,
              total_income:
                (d.total_profit_income ? d.total_profit_income : 0) -
                (d.total_loss_income ? d.total_loss_income : 0),
              type_details: d.type_details.map((d, i) => {
                return {
                  ...d,
                  total_income: d.total_profit_income,
                };
              }),
            };
          } else if (d.type === "OTHERS") {
            return { ...d, rank: 4 };
          } else {
            return { ...d, rank: 3 };
          }
        });

        income = {
          ...income,
          income_details: [...newArr],
        };
        dispatch({
          type: actions.SET_INCOME,
          data: income,
        });
      }
    });
  };

  const handleGetDeduction = (userId,dispatch) => {
    getUserDocument(getAuthToken(), userId).then(
      (deduction) => {
        let totalExemption = 0;
        for (
          let index = 0;
          index < deduction.exemption_details.exemption_details.length;
          index++
        ) {
          const element = deduction.exemption_details.exemption_details[index];
          for (let index = 0; index < element.type_details.length; index++) {
            const newData = element.type_details[index];
            totalExemption = totalExemption + newData.exempted_amount;
          }
        }

        if (
          deduction.section_details.length !== 0 &&
          deduction.exemption_details.exemption_details.length !== 0
        ) {
          dispatch({
            type: actions.SET_DEDUCTION,
            data:
              deduction.section_details.reduce((total, d) => {
                if (
                  d.max_investment_limit === 0 ||
                  d.max_investment_limit === undefined
                ) {
                  return total + d.grant_total_invested_amount;
                } else if (
                  d.grant_total_invested_amount > d.max_investment_limit
                ) {
                  return total + d.max_investment_limit;
                } else {
                  return total + d.grant_total_invested_amount;
                }
              }, 0) + totalExemption,
          });
        } else {
          dispatch({
            type: actions.SET_DEDUCTION,
            data: 0,
          });
        }
        return deduction;
      }
    );
  };

  const getVersion = (appVersions, appVersion) => {

    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    const details =
      appVersions && appVersions.find((it) => it.financial_year_id === fyId);
    return details !== undefined ? details.app_version : appVersion;
  };

  const isEditableField=({data,checked})=>{
    return   data?.isEditable&&(checked?data.value!=='ELITE':true)
  }
  export {handleGetDeduction,handleGetIncome,getVersion,isEditableField }