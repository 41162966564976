import React from "react";
import Styled from "styled-components";

const BackdropStyle = Styled.div`
   width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    opacity: 0.7;
    background-color: ${(props) => props.backgroundColor};
    cursor: pointer;
`;

const Backdrop = ({ show, onHideModelClick, backgroundColor = "#000" }) =>
    show ? (
        <BackdropStyle
            onClick={onHideModelClick}
            backgroundColor={backgroundColor}
        ></BackdropStyle>
    ) : null;

export default Backdrop;
