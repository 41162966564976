



const Email = () => {
  return (
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7143 0H2.71429C1.76751 0 1 0.767512 1 1.71429V10.2857C1 11.2325 1.76751 12 2.71429 12H14.7143C15.6611 12 16.4286 11.2325 16.4286 10.2857V1.71429C16.4286 0.767512 15.6611 0 14.7143 0Z" fill="#697586"/>
    <path d="M1.8501 1.60015L7.1201 6.5601C8.02679 7.41343 9.2734 7.41343 10.1801 6.5601L15.4501 1.6001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Email