import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const StyledCheckBoxComponent = styled.div`
  position: relative;
  .title {
    color: #212f45;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .radioModel {
    display: flex;
    gap: ${dim._32px};
    padding-top: ${dim._16px};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .container {
    display: flex;
    gap: ${dim._16px};
    position: relative;
    padding-left: ${dim._28px};
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #15be53;
    font-size: 16px;
  }
  .checkmark {
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    /* border-radius: 50%; */
  }
  .container .checkmark:after {
    top: -2px;
    left: 2px;
    width: 8px;
    height: 8px;
    /* border-radius: 50%; */
    /* background: white; */
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "✓";
    position: absolute;
    display: none;
    color: #fff;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
`;
