import { useDispatch } from "react-redux";
import SalaryIncomeServices from "../../../Services/IncomeServices";
import { actions } from "../store/actions";
import { useEffect } from "react";

export const useSalaryComponent = async() => {
    const dispatch = useDispatch();
  

const setSalaryComponent = async (data) => {
    const getAllSalaryComponentData =
    await SalaryIncomeServices.getSalaryComponents();
    dispatch({
      type: actions.SET_EARNING_COMPONENTS,
      data: getAllSalaryComponentData.data.earning,
    });
    dispatch({
      type: actions.SET_DEDUCTION_COMPONENTS,
      data: getAllSalaryComponentData.data.deduction,
    });}
    useEffect(() => {
     (async()=>{
        await setSalaryComponent()
     })()
    }, [])
    
    }