import styled from "styled-components";

export const ClientProfileStyle = styled.div`
  width: 100%;
  background-color: #F4F4F4;
  border-bottom: 2px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  box-sizing: border-box;
  align-items: center;

  .userProf {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 10px 0;
    padding: 0;
    box-sizing: border-box;
    .userName {
    font-size: 16px;
    color: #106AB0;
    margin: 0 0 0 10px;
  }
  }

  .userReferal {
    color: #106AB0;
  }

  align-items: center;
`;
