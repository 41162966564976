import styled from 'styled-components';
import { dim } from '../../../../styles/theme';
import { colors } from '../../../../styles/colors';

export const StyledSalaryReadComponent = styled.div`
	width: ${dim._scale(600)};
	height: 100vh;
	background-color: ${colors.white};
	padding: ${dim._32px};
	position: absolute;
	transition: 0.5s;
	overflow: scroll;
	right: -${({ isOpen }) => (isOpen ? 0 : dim._scale(600))};
	top: 0;
	box-sizing: border-box;
	.heading {
		font-style: normal;
		font-weight: 600;
		font-size: ${dim._18px};
		line-height: ${dim._24px};
		color: #635bef;
		margin-top: ${dim._24px};
	}

	.heading {
		font-weight: 600;
		font-size: ${dim._18px};

		line-height: ${dim._24px};
		padding-bottom: ${dim._8px};
	}
	.Salary {
		margin: 0;
		color: #222;
	}
	.close-btn {
		position: absolute;
		padding: ${dim._18px};
		top: 0;
		right: 0;
		font-size: ${dim._24px};
		color: ${colors.primaryCTA};
		cursor: pointer;
	}
	.back-btn {
		position: absolute;
		padding: ${dim._18px};
		top: 0;
		left: 0;
		font-size: ${dim._24px};
		color: ${colors.primaryCTA};
		cursor: pointer;
	}
	.submitBtn {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.btns {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 16px;
	}
	.loaderNew {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		top: 0;
		background: #000;
		opacity: 0.2;
		z-index: 600;
		cursor: not-allowed;
		border-radius: ${dim._4px};
	}
`;
