import styled from "styled-components";

export const PreviewStyled = styled.div`
  width: 100%;
  height: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;
