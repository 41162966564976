import { LOCAL_DB_KEYS } from "../constants/localStorage";
import { convertNumberIntoINR } from "./ConvertNumnerIntoIndianCurrency";
const months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sept',
	'Oct',
	'Nov',
	'Dec'
];
export const getFormattedDateAllWithMonthName = (timeStamp) => {
	let date = new Date(timeStamp);
	let month = date.getMonth();
	let day = date.getDate();
	let year = date.getFullYear();
	return months[month] + ' ' + day + ' ' + year;
};


export const cookDataForNestedListView = (data) => {
	let incomes = {
		id: 0,
		title: 'Total Annual Income',
		isSelected: false,
		value: convertNumberIntoINR(data.tax_response.total_income),
		subItems: [
			data.tax_response.income_detail_tax.total_amount > 0 && {
				id: 0,
				title: 'Salaried Income',
				value: convertNumberIntoINR(
					data.tax_response.income_detail_tax.total_amount
				)
			},
			data.tax_response.digital_asset_tax.crypto_currency_tax.total_amount >
				0 && {
				id: 1,
				title: 'Crypto Income',
				value: convertNumberIntoINR(
					data.tax_response.digital_asset_tax.crypto_currency_tax.total_amount
				)
			},
			data.tax_response.digital_asset_tax.nft_tax.total_amount > 0 && {
				id: 2,
				title: 'NFT Income',
				value: convertNumberIntoINR(
					data.tax_response.digital_asset_tax.nft_tax.total_amount
				)
			},
			data.tax_response.capital_gain_tax.total_amount > 0 && {
				id: 3,
				title: 'Capital Gain Income',
				value: convertNumberIntoINR(
					data.tax_response.capital_gain_tax.total_amount
				)
			}
		]
	};
	let taxes = {
		id: 1,
		title: 'Total Annual Tax',
		isSelected: false,
		value: convertNumberIntoINR(data.tax_response.tax),
		subItems: [
			data.tax_response.income_detail_tax.tax > 0 && {
				id: 0,
				title: 'Tax on Salaried',
				value: convertNumberIntoINR(data.tax_response.income_detail_tax.tax)
			},
			data.tax_response.digital_asset_tax.crypto_currency_tax.tax > 0 && {
				id: 1,
				title: 'Tax on Crypto',
				value: convertNumberIntoINR(
					data.tax_response.digital_asset_tax.crypto_currency_tax.tax
				)
			},
			data.tax_response.digital_asset_tax.nft_tax.tax > 0 && {
				id: 2,
				title: 'Tax on NFT',
				value: convertNumberIntoINR(
					data.tax_response.digital_asset_tax.nft_tax.tax
				)
			},
			data.tax_response.capital_gain_tax.tax > 0 && {
				id: 3,
				title: 'Tax on Capital Gain',
				value: convertNumberIntoINR(data.tax_response.capital_gain_tax.tax)
			}
		]
	};
	return [{ ...incomes }, { ...taxes }];
};

export const getVersion = (appVersions, appVersion) => {
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
    const details =
        appVersions && appVersions.find((it) => it.financial_year_id === fyId);
    return details !== undefined ? details.app_version : appVersion;
};