import DataGridCustomTooltip from "../commonComponents/DataGridCustomTooltip";

export const getCurrentActivity = (value) => {
  var inProgressArr = value.activity_details.filter((d, i) => {
    return d.status === "IN_PROGRESS";
  });

  var completedArr = value.activity_details.filter((d, i) => {
    return d.status === "COMPLETED";
  });

  if (inProgressArr[0] !== undefined) {
    return inProgressArr[0].name;
  } else if (value.activity_details.length === completedArr.length) {
    return "All Activity Completed";
  } else {
    return `Not Initiated (${inProgressArr.length + completedArr.length}/${
      value.activity_details.length
    })`;
  }
};

export const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params) => (
      <DataGridCustomTooltip
        showExpiredBg={params?.row?.subscription_status === "EXPIRED"}
        dataVal={params?.value}
      />
    ),
  },
  {
    field: "mobile",
    headerName: "Mobile",
    flex: 1,
    renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
  },
  {
    field: "advisor",
    headerName: "Primary Advisor",
    flex: 1,
    renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
  },
  {
    field: "secondaryAdvisor",
    headerName: "Secondary Advisor ",
    flex: 1,
    renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
  },
  {
    field: "service_type",
    headerName: "Category type",
    flex: 1,
    renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
  },
];

export const columnsForAdvisor = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params) => (
      <DataGridCustomTooltip
        showExpiredBg={params?.row?.subscription_status === "EXPIRED"}
        dataVal={params?.value}
      />
    ),
  },
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    renderCell: (params) => (
      <DataGridCustomTooltip dataVal={params?.row?.referral_code} />
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
  },
  {
    field: "advisor",
    headerName: "Advisor Name",
    flex: 1,
    renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
  },
  {
    field: "service_type",
    headerName: "Plan Name",
    flex: 1,
    renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
  },
];

export const timeStampDateConverter = (timestamp) => {
  var a = new Date(+timestamp);
  var hour = a.getHours();
  var min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
  var suffix = hour >= 12 ? "PM" : "AM";
  var hours = ((hour + 11) % 12) + 1;
  var time = `${hours}:${min} ${suffix}`;
  return time;
};
