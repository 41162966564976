import React, { useEffect } from "react";
import { StyledPayslipManualReview } from "./PayslipManualReview.styled";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import { Tooltip } from "@material-ui/core";
import Button from "../../commonComponents/Button";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import { useNavigate } from "react-router";
import { actions } from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import { PayslipRead } from "../../Services/PayslipReadApi";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import DataGridCustomTooltip from "../../commonComponents/DataGridCustomTooltip";

const PayslipManualReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const payslipManualReviewList = useSelector(
    (state) => state.PayslipManualReviewReducer.payslipReviewList
  );

  const renderDetailsButton = (params) => {
    return (
      <Button
        buttonClassName={"btn"}
        onButtonClick={() => handleReviewClick(params)}
        style={{
          margin: 0,
          width: "146px",
          height: "32px",
        }}
        buttonName={"Review"}
      />
    );
  };
  const columns = [
    {
      field: "name",
      headerName: "Owner Name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "owner_email",
      headerName: "Owner Email",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "advisor_name",
      headerName: "Advisor Name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "actions",
      headerName: "Review Payslip",
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: renderDetailsButton,
      showInMenu: false,
    },
  ];

  function rowFunction(value, index, array) {
    return {
      id: value.document_identifier,
      userID: value?.reviewer_id,
      name: value.owner_name,
      advisor_name: value.advisor_name,
      owner_email: value.owner_email,
    };
  }

  const handleReviewClick = (param) => {
    const url = `/payslip-manual-review/preview`;
    const params = new URLSearchParams();
    const documentIdentifier = param.row.id;
    const reviewerId = param.row.userID;
    params.set("documentIdentifier", documentIdentifier);
    params.set("reviewerId", reviewerId);
    // Use set() instead of append()
    // Use set() instead of append()

    const queryString = params.toString();
    navigate({ pathname: url, search: queryString });
  };
  const { user_roll_id: userRollId } = JSON.parse(
    localStorage.getItem(LOCAL_DB_KEYS.CURRENT_USER)
  );
  const { id: operationId } = JSON.parse(
    localStorage.getItem(LOCAL_DB_KEYS.USER_DETAILS)
  );
  const { user_type: userType } = JSON.parse(
    localStorage.getItem(LOCAL_DB_KEYS.CURRENT_USER)
  );

  useEffect(() => {
    (async () => {
      const payslipManualReviewAll = await PayslipRead.payslipManualReviewAll(
        userType === "ADVISOR" ? userRollId : operationId
      );
      dispatch({
        type: actions.SET_PAYSLIP_REVIEW_LIST,
        data: payslipManualReviewAll.data,
      });
    })();
  }, []);

  return (
    <StyledPayslipManualReview>
      <div className="resultS">
        <CardAddSlot>
          <BasicExampleDataGrid
            data={payslipManualReviewList || []}
            columns={columns}
            rowFunction={rowFunction}
            handleOnCellClick={() => {}}
            enablePaging={false}
            pathname="/"
          />
        </CardAddSlot>
      </div>
    </StyledPayslipManualReview>
  );
};

export default PayslipManualReview;
